import { FieldProps } from "formik";
import { FieldWrap } from "./PreferenceField.presents";
import { PreferenceFieldProps } from "./PreferenceField.props";

export const PreferenceField: React.SFC<PreferenceFieldProps & FieldProps> = (props) => {
  const { 
    isDisabled, 
    isPanel, 
    labelText, 
    fieldName, 
    inputType, 
    fieldValue, 
    onChange, 
    valueMaxLength, 
    form ,
    isAnyValue
  } = props;
  const isChecked = form.values[fieldName] === fieldValue || (form.values[fieldName] && form.values[fieldName].includes(fieldValue));

  const onChangeHandler = (event: any) => {
    const maxLength = valueMaxLength || 2;
    const isValueLengthLimited = inputType === 'radio' || (form.values[fieldName].length < maxLength || isChecked);
    if (isValueLengthLimited || isAnyValue) {
      onChange(event)
    }
  }
  return <FieldWrap isPanel={isPanel?isPanel:false}>
    <input
      disabled={isDisabled}
      type={inputType ? inputType : 'checkbox'}
      name={fieldName}
      value={fieldValue}
      onChange={(event) => onChangeHandler(event)}
      checked={isChecked}
    />
    <label>
      {labelText}
      <span></span>
    </label>
  </FieldWrap >
}