import {
  ItemWrap,
  JobStatusWrap,
  Status,
  LastUpdated,
  UpdatedDate,
  StatusIcon,
  JobTitle,
  BlockFooter,
  OrganizationName,
  Applications,
  AllApplications,
  ActionBtn,
  BtnIcon,
  ActionBtnWrap,
  CreateJobBtn,
  MobileScreen,
  SortBtn,
  SortedBy
} from "./MobileJobList.presents";
import { JobStatus, MobileJobItemProps } from "./MobileJobList.props";
import { IconsMap, setActionsForJob } from '../../helper';
import moment from "moment-timezone";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { NewReviewers } from "../../JobsList.presents";
import { MobileSortingModal } from "../../../../common/Modals/MobileSortingModal/MobileSortingModal";
import { useState } from "react";
import { ReactComponent as SortIcon } from '../../../../../assets/img/mobileSorting.svg';
import { ActionsMenu } from "../../../../common/Modals/ActionListMenu";
import { Skeleton } from "../../../../common/Skeleton";

export const MobileJobItem = (props: MobileJobItemProps) => {
  const { jobStory } = useRootStore();
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const ActionsMenuProps = {
    handleOpenModal: props.handleOpenModal,
    handleShareJob: props.handleShareJob,
    handleEditJob: props.handleEditJob,
    activeActionBtn: props.activeActionBtn,
    isDesktop: props.isDesktop,
    handleOpenMenu: props.handleOpenMenu,
    history: props.history
  };

  const statusIcon = (jobStatus: keyof typeof JobStatus) => JobStatus[jobStatus];
  const sortedBy = props.selectedIndex !== null ? props.mobileSortList[props.selectedIndex].title : '';

  return <MobileScreen isLoading={props.isSpinnerOpen}>
    <SortBtn onClick={() => setSortModalOpen(true)}>
      <SortIcon />Sort by:<SortedBy>{sortedBy}</SortedBy>
    </SortBtn>
    {!props.isSpinnerOpen ? jobStory.jobList.map((item: any, index: number) => <ItemWrap key={index}>
      <JobStatusWrap>
        <Status>
          <StatusIcon src={IconsMap[statusIcon(item.jobStatus)]} alt="icon" />
          {item.jobStatus}
        </Status>
        <LastUpdated>
          Last updated:
          <UpdatedDate>{moment(new Date(item.updatingDate)).format('DD/MM/YY')}</UpdatedDate>
        </LastUpdated>
      </JobStatusWrap>
      <JobTitle>{item.jobTitle}</JobTitle>
      <BlockFooter>
        <OrganizationName>
          {item.organization}
        </OrganizationName>
        {item.reviewsNumber > 0 && <Applications>
          Applications:
          <AllApplications>
            {item.reviewsNumber}
          </AllApplications>
          {item.newReviewers > 0 && <NewReviewers>
            {item.newReviewers}
          </NewReviewers>}
        </Applications>}
      </BlockFooter>
      <ActionBtnWrap>
        <ActionBtn onClick={() => props.handleOpenMenu(index)}>
          <BtnIcon></BtnIcon>
        </ActionBtn>
      </ActionBtnWrap>
      {props.activeActionBtn === index && <ActionsMenu
        index={index}
        activeActionBtn={props.activeActionBtn}
        isDesktop={props.isDesktop}
        handleOpenMenu={props.handleOpenMenu}
        actionList={setActionsForJob({ jobListItem: item, ...ActionsMenuProps })} />}
    </ItemWrap>
    ) : <Skeleton isDesktop={props.isDesktop} />}
    <MobileSortingModal
      isOpen={isSortModalOpen}
      onClose={() => setSortModalOpen(false)}
      handleMobileSorting={props.handleMobileSorting}
      mobileSortList={props.mobileSortList}
      selectedIndex={props.selectedIndex}
    />
    {!props.isSpinnerOpen && <CreateJobBtn onClick={() => props.createJob()} />}
  </MobileScreen>
};