import { observer } from 'mobx-react-lite';

import { Cover, StyleForWrap, AvatarContainer, CoverWrap, OpenMediaPopupBtn, FullGalleryBtn, OrganizationIndustry, AddCoverContainer } from "./CoverSection.presents"
import {
	Content,
	BtnsWrap,
	ModalBtn,
	ModalBtnText,
	ShareIcon,
	ModalSpan,
	OrganizationName
} from './CoverSection.presents'
import { SPBaseButton } from '../../common/buttons/SPBaseButton';
import shareIcon from '../../../assets/img/share.svg';
import coverIcon from '../../../assets/img/organization/cover.svg';
import officeIcon from '../../../assets/img/organization/office.svg';
import { AvatarImage } from '../../common/Avatar/Avatar.presents';
import { CoverSectionProps } from './Media.props';
import { VideoPlayer } from '../VideoPlayer';
import { ReactComponent as PlayCircleIcon } from '../../../assets/img/jobStory/playCircle.svg';
import { ReactComponent as GalleryIcon } from '../../../assets/img/organization/gallery.svg';
import { GalleryModal } from '../Windows/Gallery';
import { useState } from 'react';
import { TextButton } from '../../common/buttons/TextButton/TextButton';
import { HashLink as Link } from 'react-router-hash-link';
import { ShareModal } from '../../JobStory/BasicComponents/ShareModal';

export const CoverSection = observer((props: CoverSectionProps) => {
	const {
		bannerUrl,
		logoUrl,
		photoGallery,
		videoGallery,
		setActiveTab,
		isDesktop,
		tabsComponentRef,
		organizationName,
		organizationIndustry,
		isAdminView
	} = props;

	const [isGalleryWindowOpen, setGalleryWindowOpen] = useState(false)
	const [isShareModalOpen, setShareShowModal] = useState(false);



	const mainVideo = videoGallery.find(item => item.isMain);
	const openGalleryTab = () => {
		setActiveTab('gallery');
		tabsComponentRef.current?.scrollIntoView();
	}
	const isGalleryExist = photoGallery.length > 0 || videoGallery.length > 0;
	return (<StyleForWrap>
		<CoverWrap isDesktop={isDesktop} >
			{/* Case when user choose "mainVideo"(show play button) */}
			{mainVideo && <OpenMediaPopupBtn>
				<PlayCircleIcon onClick={() => setGalleryWindowOpen(true)} />
			</OpenMediaPopupBtn>}
			{/* Case when user didn`t choose "mainVideo" and didn`t upload the banner img (show "Add Cover" button) */}
			{(!mainVideo && !bannerUrl && isAdminView) && <OpenMediaPopupBtn>
				<Link to='/my-organization#banner'>
					<AddCoverContainer>
						<TextButton text={"Add Cover"} />
					</AddCoverContainer>
				</Link>
			</OpenMediaPopupBtn>}
			{/* Case when user uploaded media files (show "See Full Gallery" button) */}
			{isGalleryExist && <FullGalleryBtn isDesktop={isDesktop} onClick={openGalleryTab}>
				<GalleryIcon />
				See Full Gallery
			</FullGalleryBtn>}


			{/* Show Banner image */}
			<Cover src={bannerUrl || coverIcon} />

			{/* TODO: we can show the capture from the video, if user didn`t upload the banner */}
			{(!bannerUrl && mainVideo) && <VideoPlayer videoLink={mainVideo?.url} />}
		</CoverWrap>

		<Content>
			<AvatarContainer>
				<AvatarImage showPlaceHolder={false} src={logoUrl || officeIcon} />
				<OrganizationName>
					{organizationName}
				</OrganizationName>
				{organizationIndustry &&
					<OrganizationIndustry>
						{organizationIndustry}
					</OrganizationIndustry>}
				{(!organizationIndustry && isAdminView) && <Link to='/my-organization#industry'>
					<TextButton text={"+ Add Industry"} />
				</Link>}
			</AvatarContainer>
			<BtnsWrap>
				<ModalBtn>
					<SPBaseButton width={'100%'} onClick={() => setShareShowModal(!isAdminView)}>
						<ModalBtnText>
							<ShareIcon src={shareIcon} alt="icon" />
							<ModalSpan>Share</ModalSpan>
						</ModalBtnText>
					</SPBaseButton>
				</ModalBtn>
			</BtnsWrap>
		</Content>
		{isGalleryWindowOpen && <GalleryModal isOpen={isGalleryWindowOpen} mediaFiles={[mainVideo]} onClose={() => setGalleryWindowOpen(false)} />}
		{isShareModalOpen && <ShareModal isOpen={isShareModalOpen} onClose={() => setShareShowModal(false)} organizationLogo={logoUrl || officeIcon} organizationName={organizationName} organizationIndustry={organizationIndustry} />}
	</StyleForWrap>)
})
