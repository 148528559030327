import { Field } from 'formik'
import styled from 'styled-components'
import { theme } from '../../theme'

export const Wrapper = styled('div') <{ isDesktop: boolean }>`
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  margin: ${theme.offsets.xxl};
  margin-right: 100px;
  padding: ${theme.offsets.extraMajor} ${theme.offsets.xxl} ${theme.offsets.xxl};
  height: 100%;
  flex: 1;
${(props) => !props.isDesktop && `
    margin-right: 0px;
    margin: ${theme.offsets.xxl} ${theme.offsets.lg};
    padding: ${theme.offsets.extraBig} 0 ${theme.offsets.xl};
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.xl} 0;
    }
  `}
`

export const Title = styled('p') <{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: ${theme.fontSizes.xxl};
  color: ${theme.colors.black}; 
  margin-bottom: ${theme.offsets.xxl};
  ${(props) => !props.isDesktop && `
    @media(max-width: ${theme.screens.tablet}) {
      font-size: ${theme.fontSizes.xl};
      margin-bottom: ${theme.offsets.lg};
    }
    @media(max-width: ${theme.screens.tablet}) {
      font-size: ${theme.fontSizes.lg};
    }
  `}
`
export const ButtonWrap = styled('div')<{isLoading?: boolean}>`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
  max-width: 190px;
  width: 100%;
  margin-top: ${theme.offsets.sm};
  margin-left: 0;
  margin-right: auto;
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 160px;
    margin-left: auto;
    margin-right: 0;
    button{
      font-size: 14px;
      height: 42px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 125px;
    button{
      font-size: ${theme.fontSizes.md};
      height: 38px;
    }
  }

  ${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`

export const FieldWrap = styled(Field)`
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.tablet}) {
    input{
    height: 44px;
    font-size: 14px;
  }
  }
  & label{
    margin-top: ${theme.offsets.md}; 
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
    font-size: ${theme.fontSizes.md};
  }  
}
`