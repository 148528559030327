import { Center } from '../../common/Wraps/Wraps.presents';
import { Text } from '../../common/Text';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { ReactComponent as BenefitsIcon } from "../../../assets/img/organization/benefits.svg";
import { useHistory } from 'react-router-dom';
import { WrapForLink } from '../Organization.presents';
import { HashLink as Link } from 'react-router-hash-link';

export const EmptyBenefits = (() => {
    const history = useHistory();
    const goToAdminPanel = () => {
        history.push('/my-organization')
    }
    return (
        <>
            <Center>
                <BenefitsIcon />
            </Center>

            <Text center fs={16} color='black' m='20px 100px' text={"No Benefits"} />
            <Text center fs={14} lh={1.2} m='20px 100px' text={"Add benefits and perks and increase your chances of hiring the top talent"} />

            <Center>
                <WrapForLink>
                    <Link to='/my-organization#orgBenefits'>
                        <SPWhiteButton width={'100%'} onClick={goToAdminPanel}>
                            Add Benefits
                        </SPWhiteButton>
                    </Link>
                </WrapForLink>
            </Center>
        </>
    )
})
