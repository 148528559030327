import { week } from '../../BasicComponents/MixedShift';
import { HandleSubmitType, LocationItemType } from './JobStep.props';

const createValidationForLocation = (locationValue: any) => {
  const locationErrors = [];
  let includeErrObject = false;
  for (const value of locationValue) {
    if (value.acceptRemoteWorkers.includes('anywhereFromCountry')) {
      !value.country && locationErrors.push({ country: 'Required' });
    } else {
      const middlewearObj: any = {
        country: 'Required',
        territory: 'Required',
        city: 'Required'
      }
      value.country && delete middlewearObj.country;
      value.territory && delete middlewearObj.territory;
      value.city[0] && value.city.length > 0 && delete middlewearObj.city;
      locationErrors.push(middlewearObj);
      includeErrObject = Object.keys(middlewearObj).length > 0
    }
  }

  return includeErrObject ? locationErrors : null;
}

export const validation = (values: any) => {
  const errors: any = {};
  const validationLogic = (valuesKey: any) => {
    switch (valuesKey) {
      case 'traveling':
        if (!values.traveling) {
          errors.traveling = 'Required'
        } else if (values.traveling === 'yes') {
          errors.traveling = 'Minimum one option'
        }
        break;
      case 'hiringLocations':
        const hiringLocationsErrors = createValidationForLocation(values.hiringLocations)
        if ((!values.acceptRemoteWorkersWorld || !values.acceptRemoteWorkersWorld.includes('anywhereFromWorld')) && hiringLocationsErrors) {
          errors.hiringLocations = hiringLocationsErrors
        }
        break;
      default:
    }
  }

  for (const key in values) {
    validationLogic(key)
  }
  return errors;
}

const mixshiftDataWorker = (values: any) => {
  if (values.workShift === 'mixed' && values.mixshiftData) {
    let isDataEmpty = true;
    values.mixshiftData = week.map((item, index) => {
      const shiftData = values.mixshiftData[index];
      isDataEmpty = shiftData && (shiftData.from || shiftData.to) ? false : isDataEmpty
      return {
        day: item.key,
        from: shiftData ? shiftData.from : '',
        to: shiftData ? shiftData.to : '',
      };
    });
    isDataEmpty && delete values.mixshiftData;
  } else {
    delete values.mixshiftData;
  }
}
const hiringLocationsWorker = (values: any) => {
  values.hiringLocations = values.hiringLocations.map((item: LocationItemType) => {
    const isAnyLocationInCountry = item.acceptRemoteWorkers && item.acceptRemoteWorkers.includes('anywhereFromCountry');
    return {
      country: item.country,
      territory: isAnyLocationInCountry ? '' : item.territory,
      city: isAnyLocationInCountry ? [] : item.city,
      acceptRemoteWorkers: item.acceptRemoteWorkers
    }
  })
}

export const handleSubmit = async ({ values, updatingUrl, saveAnswers }: HandleSubmitType) => {
  const valuesCopy = { ...values }
  mixshiftDataWorker(valuesCopy)
  valuesCopy.hiringLocations && hiringLocationsWorker(valuesCopy)
  const arrayWithDependents: any = []
  const stepInfo = {
    title: 'job',
    nextStep: 'description',
    done: 1
  }
  saveAnswers({ values: valuesCopy, stepInfo, arrayWithDependents, updatingUrl })
}