import { Field } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../Input";
import { SelectCom } from "../../Select";
import { LocationFieldPropsType, optionItem } from './Locations.props';
import { useEffect } from "react";
import { getPlaces, getTimezones } from "../../../../services/api/outsideResources/outsideResources.domains";
import { RemoveBtn, Title, FieldHeader, DoubleFieldWrap, FieldWrap, ErrorText, OptionalLabel } from "./Locations.presents";

export const LocationField = ({ index, remove, values, locationsArray, errors, handleChange, setFieldValue }: LocationFieldPropsType) => {
  const { t } = useTranslation();
  const country = values.locations[index] ? values.locations[index].country : '';

  const [regionList, setRegionList] = useState<Array<optionItem>>([]);
  const [coutriesList, setCoutriesList] = useState<Array<optionItem>>([]);
  const [cityList, setCityList] = useState<Array<optionItem>>([]);
  const [timeZonesOptions, setTimeZonesOptions] = useState<Array<optionItem>>([]);

  const isCityListEmpty = cityList.length === 0;
  const isCityFieldDisabled = locationsArray[index] && !locationsArray[index].territory;

  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('country');
      const countryList = placeResponse.data.data;

      const timezonesResponse = await getTimezones();
      const defaultList = { label: 'any', value: 'any' };
      let optionsList = [defaultList];
      const timezonesData = timezonesResponse.data.data;

      if (timezonesData) {
        optionsList = timezonesData.optionsList;
      }

      setCoutriesList(countryList);
      setTimeZonesOptions(optionsList);
    })()
  }, [])

  useEffect(() => {
    country && (async () => {
      setRegionList([])
      const placeResponse = await getPlaces('state', { countryCode: country });
      placeResponse.data.data && setRegionList(placeResponse.data.data);
    })()
  }, [country])

  useEffect(() => {
    (async () => {
      setCityList([])
      const placeResponse = await getPlaces('city', { countryCode: country, stateCode: locationsArray[index].territory });
      placeResponse.data.data && setCityList(placeResponse.data.data);
    })()
  }, [country, locationsArray[index].territory])

  const mainValidationCondition = (fieldName: string) => errors && errors[index] && errors[index][fieldName];

  const resetValues = () => {
    const fieldsWhichNeedReset = [
      'address',
      'suite',
      'zipCode',
      'timeZone',
      'city',
      'territory'
    ];
    setRegionList([]);
    setCityList([]);
    fieldsWhichNeedReset.forEach(item => setFieldValue(`locations.${index}.${item}`, ''));
  }

  const resetCityValue = () => {
    setCityList([]);
    setFieldValue(`locations.${index}.city`, '');
  }

  const hasItem = index > 0;

  return <div>

    {hasItem && <FieldHeader>
      <Title>Other location</Title>
      <RemoveBtn onClick={() => remove(index)}>Remove</RemoveBtn>
    </FieldHeader>}

    <Field
      labelText={t('label.country')}
      options={coutriesList}
      component={SelectCom}
      name={`locations.${index}.country`}
      onChange={resetValues}
      error={mainValidationCondition('country')}
    />
    <ErrorText>{mainValidationCondition('country') ? errors[index].country : ''}</ErrorText>

    <Field
      labelText={t('label.territory')}
      options={regionList}
      component={SelectCom}
      disabled={regionList.length <= 0}
      name={`locations.${index}.territory`}
      onChange={resetCityValue}
      error={mainValidationCondition('territory')}
    />
    <ErrorText>{mainValidationCondition('territory') ? errors[index].territory : ''}</ErrorText>

    <DoubleFieldWrap>
      <FieldWrap leftSide>
        {
          isCityListEmpty ?
            <Field
              as={Input}
              name={`locations.${index}.city`}
              onChange={handleChange}
              labelText={t('label.city')}
              disabled={isCityFieldDisabled}
              value={locationsArray[index].city}
              autoCompleteOff
              maxLength={30}
              error={mainValidationCondition('city')}
            />
            :
            <Field
              placeholder='Select the city'
              component={SelectCom}
              name={`locations.${index}.city`}
              labelText={t('label.city')}
              disabled={cityList.length <= 0}
              options={cityList}
              error={mainValidationCondition('city')}
            />
        }
        <ErrorText>{mainValidationCondition('city') ? errors[index].city : ''}</ErrorText>
      </FieldWrap>

      <FieldWrap>
        <Field
          as={Input}
          name={`locations.${index}.address`}
          value={locationsArray[index].address}
          type={'text'}
          maxLength={200}
          autoCompleteOff
          onChange={handleChange}
          labelText={<OptionalLabel>{t('label.address')}<span>{t('label.optional')}</span></OptionalLabel>}
        />
        <ErrorText>{mainValidationCondition('address') ? errors[index].address : ''}</ErrorText>
      </FieldWrap>
    </DoubleFieldWrap>

    <DoubleFieldWrap>
      <FieldWrap leftSide>
        <Field
          as={Input}
          name={`locations.${index}.suite`}
          type={'text'}
          maxLength={20}
          autoCompleteOff
          onChange={handleChange}
          value={locationsArray[index].suite}
          labelText={<OptionalLabel>{t('label.apt')}<span>{t('label.optional')}</span></OptionalLabel>}
        />
        <ErrorText>{mainValidationCondition('suite') ? errors[index].suite : ''}</ErrorText>
      </FieldWrap>

      <FieldWrap>
        <Field
          as={Input}
          name={`locations.${index}.zipCode`}
          onChange={handleChange}
          labelText={t('label.zipCode')}
          value={locationsArray[index].zipCode}
          autoCompleteOff
          maxLength={16}
          error={mainValidationCondition('zipCode')}
        />
        <ErrorText>{mainValidationCondition('zipCode') ? errors[index].zipCode : ''}</ErrorText>
      </FieldWrap>

    </DoubleFieldWrap>

    <Field
      labelText={index < 1 ? t('label.timeZone') : 'Time Zone'}
      options={timeZonesOptions}
      name={`locations.${index}.timeZone`}
      id={`locations${index}timeZone`}
      component={SelectCom}
      error={mainValidationCondition('timeZone')}
    />
    <ErrorText>{mainValidationCondition('zipCode') ? errors[index].timeZone : ''}</ErrorText>
  </div>
}