import { PropsType } from './NavBtn.props';
import { StyleForBtn } from './NavBtn.presents';
import arrowIcon from '../../../../assets/img/arrowNext.svg';
import arrowIconDark from '../../../../assets/img/arrowNextDark.svg';
import disketteIcon from '../../../../assets/img/diskette.svg';

export const NavBtn = (props: PropsType) => {

  return <div>
    <StyleForBtn
      {...props}
      onClick={() => props.callbackFunction && props.callbackFunction()}
      type={props.next ? "submit" : 'button'}>
      <span>{props.next ? 'Next Step' : props.exit ? 'Save and Continue Later' : 'Previous Step'} </span>
      <img src={props.next ? arrowIcon : props.exit ? disketteIcon : arrowIconDark} alt="img" />
    </StyleForBtn>
  </div>
}