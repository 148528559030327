import { theme } from '../../../theme/theme';
import styled from 'styled-components';

export const Wrapper = styled('div') <{ isDesktop: boolean }>`
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  margin: ${theme.offsets.xxl};
  margin-right: 100px;
  padding: ${theme.offsets.extraMajor} ${theme.offsets.xxl} ${theme.offsets.xxl};
  flex: 1;
  max-width: 1138px;
${(props) => !props.isDesktop && `
    margin: ${theme.offsets.xxl} ${theme.offsets.xxl};
    margin-right: 100px;
    padding: ${theme.offsets.extraBig} 0 ${theme.offsets.xl};
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.xl} 0;
    }
  `}
`