import { propertyForModal, Actions } from "./TableActionsModal.props";
import archiveIcon from '../../../../assets/img/archive.svg';
import deleteIcon from '../../../../assets/img/delete.svg';
import pauseIcon from '../../../../assets/img/pause.svg';
import playIcon from '../../../../assets/img/play.svg';
import unarchiveIcon from '../../../../assets/img/unarchive.svg';
import { duplicateTheJob } from "../../../../services/api/jobStore/jobStore.domains";
import duplicateIcon from '../../../../assets/img/duplicateIcon.svg';

export class TableActionsModalHelper {

  props: propertyForModal;
  jobStory: any;
  candidatesStore: any;
  setSpinnerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusChanged: Function;
  setStatusError: Function;

  constructor(
    props: propertyForModal,
    setSpinnerOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setStatusChanged: Function,
    setStatusError: Function,
    jobStory: any,
    candidatesStore: any
  ) {
    this.props = props;
    this.jobStory = jobStory;
    this.candidatesStore = candidatesStore;
    this.setSpinnerOpen = setSpinnerOpen;
    this.setStatusChanged = setStatusChanged;
    this.setStatusError = setStatusError;
  };

  handleChangeStatus = (error: string | null) => {
    const hasError = Boolean(error);
    this.setSpinnerOpen(false);
    this.setStatusChanged(!hasError);
    this.setStatusError(hasError);
  }

  async handleJobStatus() {
    this.setSpinnerOpen(true);
    const isModalForCandidateList = this.props.modalForCandidates;
    let serverResponse;

    const mainData = {
      newStatus: Actions[this.props.typeOfModal],
      jobId: this.props.jobId,
      page: this.props.activePage,
      sortData: this.props.sortData
    };

    if (isModalForCandidateList) {
      serverResponse = await this.candidatesStore.updateCandidateStatus({
        ...mainData,
        candidateStatus: this.props.activeTab,
        candidateId: this.props.candidateId
      })
    } else {
      serverResponse = await this.jobStory.updateJobStatus({
        ...mainData,
        filterByStatus: this.props.activeTab,

      })
    }
    this.handleChangeStatus(serverResponse.err);
  }

  async handleDuplicateTheJob() {
    this.setSpinnerOpen(true);
    const serverResponse = await duplicateTheJob(this.props.jobId);
    await this.handleChangeStatus(serverResponse.data.err);
    await this.props.handleSetActiveTab('all');
  }

  setModalContent() {
    const content = {
      [Actions.delete]: {
        title: 'Delete',
        previewText: `Are you sure want to delete ${this.props.modalForCandidates ? 'this candidate' : 'this job'}?`,
        completedText: `${this.props.modalForCandidates ? 'Candidate' : 'Job Story'} deleted successfully!`,
        textForBtn: 'Delete',
        icon: deleteIcon,
      },
      [Actions.archived]: {
        title: 'Archive',
        previewText: `Are you sure want to archive ${this.props.modalForCandidates ? 'this candidate' : 'this job'}?`,
        completedText: `${this.props.modalForCandidates ? 'Candidate' : 'Job Story'} archived successfully!`,
        textForBtn: 'Archive',
        icon: archiveIcon,
      },
      [Actions.active]: {
        title: this.props.oldStatus === 'archived' ? 'Unarchive' : 'Activate',
        previewText: `Are you sure want to ${this.props.oldStatus === 'archived' ? 'unarchive' : 'activate'} this ${this.props.modalForCandidates ? 'candidate' : 'job'}?`,
        completedText: `${this.props.modalForCandidates ? 'Candidate' : 'Job Story'} ${this.props.oldStatus === 'archived' ? 'unarchived' : 'activated '} successfully!`,
        textForBtn: this.props.oldStatus === 'archived' ? 'Unarchive' : 'Activate ',
        icon: this.props.oldStatus === 'archived' ? unarchiveIcon : playIcon,
      },
      [Actions.draft]: {
        title: 'Unarchive',
        previewText: 'Are you sure want to unarchive this job?',
        completedText: 'Job Story unarchived successfully!',
        textForBtn: 'Unarchive',
        icon: unarchiveIcon,
      },
      [Actions.inactive]: {
        title: 'Deactivate',
        previewText: `Are you sure want to deactivate this job?`,
        completedText: `Job Story deactivate successfully!`,
        textForBtn: 'Deactivate',
        icon: pauseIcon,
      },
      [Actions.duplicate]: {
        title: 'Duplicate',
        previewText: `Please confirm you would like to duplicate this job.`,
        completedText: `Job Story was duplicated successfully!`,
        textForBtn: 'Confirm',
        icon: duplicateIcon,
      }

    }
    return content[this.props.typeOfModal];
  }
}

