import { MenuItem } from "./ActionsMenu.presents";
import { ActionItemProps } from "./JobsList.props";

export const ActionItem = (props: ActionItemProps) => {
  const { show, callback, isDesktop, title, isDeleteBtn, isSingleElement } = props;
  return show ? <MenuItem
    isDesktop={isDesktop}
    onClick={callback}
    isSingleElement={isSingleElement ? true : false}
    isDeleteBtn={isDeleteBtn}>
    {title}
  </MenuItem> : null;
}