import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ItemBlock = styled('button')`
  display: flex;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  border: 1px solid #D1D7E3;
  align-items: center;
  padding: ${theme.offsets.lg};
  margin-bottom: ${theme.offsets.lg};
  background: ${theme.colors.white};
  cursor: pointer;
  :hover{
    border-color: hsl(0, 0%, 70%);
  }
  @media(max-width: ${theme.screens.desktop}) {
    border-radius: ${theme.variables.borderRadiusSmall};
    padding: ${theme.offsets.md};
    margin-bottom: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.lg};
}
`

export const Title = styled('p')`
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
`