import styled from "styled-components";
import { theme } from "../../../../../theme";

export const DeleteWindowButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button{
    max-width: 173px;
  }
`

export const DeleteWindowContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const DeleteWindowDescription = styled('div')`
  font-size: ${theme.fontSizes.xxl};
  margin-top: ${theme.offsets.big};
  text-align: center;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
    margin-top: ${theme.offsets.lg};
  }
`