import styled from 'styled-components'
import { theme } from '../../../theme'

export const Wrapper = styled('div')`
  padding: ${theme.offsets.xxl} ;
  max-width: 550px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img{
    margin: ${theme.offsets.xl} auto;
    width: 60%;
  }
  form{
    width: 100%;
  }
`

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.major};
  line-height: ${theme.fontSizes.extraMajor};
  text-align: center;
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.xxl};
    line-height: ${theme.fontSizes.big};
    margin-top: ${theme.offsets.lg};
  }
`

export const Description = styled('span')`
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xxl};
  margin: ${theme.offsets.xl} auto ${theme.offsets.lg};
  text-align: center;
  width: 75%;
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.fontSizes.lg};
    margin: ${theme.offsets.lg} auto;
  }
`

export const ErrorText = styled('span')`
display: block;
font-size: ${theme.fontSizes.md};
margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
height: 14px;
color: ${theme.colors.red};
`

export const ButtonsWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  button{
    width: 35%;
    @media(max-width: ${theme.screens.mobile}) {
      width: 45%;
    }
  }
`