import { Field, FieldArray, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, DefaultBanner, LabelWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { Datepicker } from "../../../../common/DatePicker";
import { Checkbox } from "../../../../common/Checkbox";
import { ReactComponent as EducationBanner } from "../../../../../assets/img/candidateListEmpty.svg";
import { SelectCom } from "../../../../common/Select";
import employmentTypesList from "../../../../../assets/staticData/employmentTypes.json";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialEducation } from "../../../../../state/stores/resumeBuilder";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { observer } from "mobx-react-lite";
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const Education = observer(({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);
  const { t } = useTranslation();

  const initialValues = !!resumeBuilderStore.education.length ? [...resumeBuilderStore.education] : [initialEducation];

  useEffect(() => {
    setShowContent(!!resumeBuilderStore.education.length);
  }, [resumeBuilderStore.education.length])

  const defaultBanner = useMemo(() => !showContent ? <DefaultBanner>
    <EducationBanner />
    <SPSecondaryButton type={'button'} width="100%" onClick={() => setShowContent(true)}>
      Add Education
    </SPSecondaryButton>
  </DefaultBanner> : null, [showContent]);

  return <CandidateFormCard
    componentRef={componentRef}
    isDesktop={isDesktop}
    hasCloseButton={showContent}
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Education'}>
    <ContentWrap>
      {defaultBanner}
      {showContent && <Formik
        enableReinitialize
        initialValues={{ education: initialValues }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            education: Yup.array()
              .of(
                Yup.object().shape({
                  institutionName: Yup.string().required(t('validation.required')),
                  degree: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveEducation(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="education">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };

                return <div>
                  {values.education.map((item: any, index: number) => {
                    const arrayErrors: any = errors.education;
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`education${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional education</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`education.${index}.institutionName`}
                          type={'text'}
                          value={values.education[index].institutionName}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Institution Name'}
                          placeholder={'e.x. University of London'}
                          error={mainValidationCondition('institutionName')} />
                        <ErrorMsg>{mainValidationCondition('institutionName') ? arrayErrors[index].institutionName : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`education.${index}.degree`}
                          type={'text'}
                          value={values.education[index].degree}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Degree'}
                          placeholder={'e.x. Bachelor of Science'}
                          error={mainValidationCondition('degree')} />
                        <ErrorMsg>{mainValidationCondition('degree') ? arrayErrors[index].degree : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`education.${index}.studyField`}
                          type={'text'}
                          value={values.education[index].studyField}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Field of Study'}
                          placeholder={'e.x. Business Administration'}
                          error={mainValidationCondition('studyField')} />
                        <ErrorMsg>{mainValidationCondition('studyField') ? arrayErrors[index].studyField : ''}</ErrorMsg>
                      </FieldWrap>

                      <MultiFieldWrap>
                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`education.${index}.startDate`}
                              as={Datepicker}
                              value={values.education[index].startDate ? new Date(values.education[index].startDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`education.${index}.startDate`, `${value}`)}
                              label={'Start Date'}
                              maxDate={new Date()}
                              showMonthYearPicker
                            />
                            <ErrorMsg>{mainValidationCondition('startDate') ? arrayErrors[index].startDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`education.${index}.endDate`}
                              as={Datepicker}
                              value={values.education[index].endDate ? new Date(values.education[index].endDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`education.${index}.endDate`, `${value}`)}
                              label={'End Date'}
                              maxDate={new Date()}
                              minDate={values.education[index].startDate && new Date(values.education[index].startDate)}
                              showMonthYearPicker
                              isDisabled={values.education[index] && values.education[index].isStillStudy}
                            />
                            <ErrorMsg>{mainValidationCondition('endDate') ? arrayErrors[index].endDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                      </MultiFieldWrap>

                      <FieldWrap>
                        <Field
                          as={Checkbox}
                          checked={values.education[index] && values.education[index].isStillStudy}
                          name={`education.${index}.isStillStudy`}
                          labelText={`I'm still studying`}
                          value={true}
                          handleChange={(value: boolean) => { onChangeHandler(value); setFieldValue(`education.${index}.endDate`, '') }}
                        />
                        <ErrorMsg>{mainValidationCondition('isStillStudy') ? arrayErrors[index].isStillStudy : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`education.${index}.description`}
                          type={'textarea'}
                          value={values.education[index].description}
                          onChange={onChangeHandler}
                          maxLength={500}
                          labelText={<LabelWrap>Description<span>Optional</span></LabelWrap>}
                          error={mainValidationCondition('description')} />
                        <ErrorMsg>{mainValidationCondition('description') ? arrayErrors[index].description : ''}</ErrorMsg>
                      </FieldWrap>
                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialEducation)}>
                      Additional education
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveEducation({ education: [] }, candidateStore.uid);
                await resumeBuilderStore.setEducation([]);
                handleReset();
                setShowContent(false);
              }}
            />}

          </form>}
      </Formik>}

    </ContentWrap>
  </CandidateFormCard>
})