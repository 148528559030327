export const ListLengthNavigation = (props: { listLength: number, activePage: number }) => {
  const { listLength, activePage } = props;
  const isSecondaryPage = activePage > 0;
  const isJobListEmpty = listLength === 0;
  const isBigListInFirstPage = listLength >= 10 && !isSecondaryPage;
  const isBigListInSecondaryPage = activePage > 0 && listLength - (activePage + 1) * 10 > 0;

  const From = isSecondaryPage ? activePage * 10 + 1 : isJobListEmpty ? 0 : 1;
  const To = isBigListInFirstPage ? activePage + 1 * 10 : isBigListInSecondaryPage ? (activePage + 1) * 10 : listLength


  return <span>{From}-{To} from {listLength}</span>
}