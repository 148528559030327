import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../../../theme';

const AVATAR_SIZE = "52px";
const AVATAR_SIZE_LARGE = "40px";


export const Avatar = styled('img')`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border-radius: 50%;
  object-fit: cover;
  background: rgba(209, 215, 227, 0.2);
  border: 1px solid ${theme.colors.lightGray};
  @media(max-width: ${theme.screens.desktop}) {
    width: ${AVATAR_SIZE_LARGE};
    height: ${AVATAR_SIZE_LARGE};
  }
`
export const Container = styled('div')`
  background: ${theme.colors.white};
`


export const Wrapper = styled('div') <{ fullscreen: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${theme.colors.white};
  padding: 18px 50px 18px ${theme.offsets.xxl};
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  ${props => props.fullscreen && `
    justify-content: space-between;
    padding: 14px 50px;
    max-width: 1720px;
    margin: 0 auto;
    width:  100%;
    z-index: 99;
    @media(max-width: ${theme.screens.desktop}) {
      max-width: 1152px;
    }
  `}
`

export const UserMenuWrap = styled('button')`
  position: relative;
  margin-left: ${theme.offsets.xxl};
  cursor: pointer;
  user-select: none;
  border: none;
  background: none;
`

export const LogoWrap = styled('a')`
  cursor: pointer;
  display: flex;
  padding-top: 0px;
  @media(max-width: ${theme.screens.desktop}) {
    svg{
      width: 106px;
      height: 40px;
    }
  }
`

export const AvatarWrap = styled('div')`
  display: flex;
  align-items: center;
`

export const UserName = styled('span')`
  margin-left: ${theme.offsets.lg};;
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  white-space: nowrap;
`

export const ArrowIcon = styled('img')<{isOpen:boolean}>`
  margin-left: ${theme.offsets.md};
  ${(props)=>props.isOpen && `
    transform: rotate(180deg);
  `}
`

export const NavigationBlock = styled('div')`
  display: flex;
`
