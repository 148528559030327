import styled from 'styled-components'
import { theme } from '../../../theme'
import { Input } from '../../common/Input'

export const FormWrap = styled('div')`
  max-width: 557px;
  width: 100%;
  form{
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding-bottom: ${theme.offsets.extraMajor};
    margin-bottom: ${theme.offsets.xxl};
    @media(max-width: ${theme.screens.mobile}) {
      padding-bottom: ${theme.offsets.xl};
      margin-bottom: ${theme.offsets.lg};
    }
  }
`

export const InputField = styled(Input)`
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.tablet}) {
    input{
    height: 44px;
    font-size: 14px;
  }
  }
  & label{
    margin-top: ${theme.offsets.md}; 
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
    font-size: ${theme.fontSizes.md};
  }  
}
`