import * as React from "react"
import { MediaModalProps } from "./GalleryModal.props"
import { useState, useEffect } from "react"
import {
  MediaModalContainer, MediaModalContent, MediaCounter, MediaModalWrapper, MediaImage, CloseButtonWrapper,
  MediaControlButton, MediaTitle
} from "./GalleryModal.presets"
import { SPCloseButton } from "../../../common/buttons/SPCloseButton/SPCloseButton";
import arrowLeft from "../../../../assets/img/arrowLeft.svg"
import arrowRight from "../../../../assets/img/arrowRight.svg"
import { VideoPlayer } from "../../VideoPlayer";

export const GalleryModal: React.FC<MediaModalProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isMultiGallery = props.mediaFiles.length > 1;

  useEffect(() => {
    setSelectedIndex(props.selectedIndex || 0)
  }, [props.selectedIndex])

  const handleNext = () => setSelectedIndex(selectedIndex < (props.mediaFiles.length - 1) ? (selectedIndex + 1) : selectedIndex);
  const handlePrev = () => setSelectedIndex(selectedIndex > 0 ? (selectedIndex - 1) : selectedIndex);

  const myRenderItem = (media: any) => {
    if (media.type === "video" || media.type === "link") {
      return <>
        <VideoPlayer videoLink={media.url} />
        <MediaTitle>{media.fileTitle || media.filename}</MediaTitle>
      </>
    } else {
      return <MediaImage src={media.url} alt={media.fileTitle || media.filename} />
    }
  }

  const renderCounter = () => {
    const counterText = `${selectedIndex + 1}/${props.mediaFiles.length}`
    return <MediaCounter>{isMultiGallery ? counterText : ''}</MediaCounter>
  }

  return (
    <MediaModalWrapper show={props.isOpen}>
      <CloseButtonWrapper>
        <SPCloseButton onClick={props.onClose} />
      </CloseButtonWrapper>
      <MediaModalWrapper.Body>
        <MediaModalContainer>
          {isMultiGallery && <MediaControlButton onClick={handlePrev}>
            <img src={arrowLeft} alt="Previous media" />
          </MediaControlButton>}
          <MediaModalContent>
            {renderCounter()}
            {myRenderItem(props.mediaFiles[selectedIndex])}
          </MediaModalContent>
          {isMultiGallery && <MediaControlButton onClick={handleNext}>
            <img src={arrowRight} alt="Next media" />
          </MediaControlButton>}
        </MediaModalContainer>


      </MediaModalWrapper.Body>
    </MediaModalWrapper>

  )
}
