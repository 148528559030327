import styled from "styled-components"
import { theme } from "../../../../theme"

export const Wrapper = styled('div')`
  max-width: 544px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 358px;
  }
`

export const Title = styled('p')`
  font-family: Recoleta;
  font-size: ${theme.fontSizes.major};
  font-weight: 500;
  margin-bottom: ${theme.offsets.lg};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
  }
`

export const Description = styled('p')`
  font-size: 18px;
  margin: 0 auto ${theme.offsets.extraMajor};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin-bottom: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 75%;
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 70%;
  }
`

export const FormWrap = styled('div')`
  margin-top: ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.extraBig};
  }
`

export const ErrorText = styled('div')`
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 10px;
`

export const ButtonWrap = styled('div')<{isLoading?: boolean}>`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
  margin-top: ${theme.offsets.lg};
  margin-bottom: ${theme.offsets.sm};

  ${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`

export const LabelText = styled('div')`
  display: flex;
  justify-content: space-between;
  span{
    color: rgba(45, 53, 61, 0.6);
  }
`