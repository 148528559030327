import { auth } from "../index";
import { signInWithEmailAndPassword, signInAnonymously, EmailAuthProvider, linkWithCredential, reauthenticateWithCredential, updatePassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset, updateEmail } from "firebase/auth";
import { onCreateAnonymous, onUpgradeUser } from "../firestore/writes";
import { onCheckUserExistsViaEmail } from "../firestore/reads";

export const onCandiateSignIn = async (email: string, password: string) => {
    try {
        const user = await signInWithEmailAndPassword(auth, email, password)
        return user
    } catch (error: unknown) {
        return error
    }
}

export const onCandiateSignUp = async (email: string, state: string) => {
    try {
        const resp = await signInAnonymously(auth)
        const userExist = await onCheckUserExistsViaEmail(email)
        if (!userExist) {
            await onCreateAnonymous(resp.user, email, state)
        } else {
            return { message: "Email exists" }
        }
        return resp
    } catch (error) {
        return error
    }

}

export const onReauthenticate = async (email: string, password: string) => {
    try {
        if (auth.currentUser && auth.currentUser.email) {
            let credential = EmailAuthProvider.credential(auth.currentUser.email, password);
            const result = await reauthenticateWithCredential(
                auth.currentUser,
                credential
            )
            return result;
        }
    } catch (error) {
        console.log(error)
        return error
    }

}

export const onUpdateEmail = async (email: string) => {
    try {
        if (auth.currentUser) {
            return await updateEmail(auth.currentUser, email);
        }
    }
    catch (error) {
        return { message: error?.code === "auth/email-already-in-use" ? `Email ${email} already exists` : "" }
    }
}


export const onSetPassword = async (email: string, password: string) => {
    try {
        if (auth.currentUser && email) {
            let credential = EmailAuthProvider.credential(email, password);
            linkWithCredential(auth.currentUser, credential).then(async function (resp) {
                await onUpgradeUser(resp.user)
            }, function (error) {
                console.log("Error upgrading anonymous account", error);
            });
        }
    } catch (error) {
        return error
    }

}

export const onUpdatePassword = async (email: string, password: string) => {
    try {
        if (auth.currentUser && email) {
            updatePassword(auth.currentUser, password).then(async function (resp) {
                return resp;
            }, function (error) {
                console.log("Error upgrading password", error);
            });
        }
    } catch (error) {
        return error
    }

}

export const onPasswordReset = async (actionCode: string, password: string) => {
    let err = null
    await verifyPasswordResetCode(auth, actionCode).then((email) => {
        confirmPasswordReset(auth, actionCode, password).then((resp) => {}).catch((error) => {
            err = "the code has expired or the password is too weak"
        });
      }).catch((error) => {
        err = "Invalid or expired code"
      });
      return err
}