import { useEffect, useState } from 'react';
import { generateSignedUrl, s3upload } from '../../../services/api/fileManagement/fileManagement.domains';
import { VideoUploadButtons } from './VideoUploadButtons/VideoUploadButtons';
import { observer } from 'mobx-react-lite';
import { VideoPreview } from './VideoPreview/VideoPreview';
import { checkFileSizeInMB, sliceFullText } from '../../../services/helper';
import { VideoGalleryProps } from './UploadGallery.props';
import { Field, FieldArray } from 'formik';
import { Input } from '../Input';
import { AddLinkButton, LinkFieldWrap } from './UploadGallery.presents';
import { SPSecondaryButton } from '../buttons/SPSecondaryButton/SPSecondaryButton';
import { v4 as uuid } from 'uuid';


export const VideoUploadGallery = observer((props: VideoGalleryProps) => {
  const { saveVideo, setVideo, deleteVideo, unsetVideo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState<Array<any>>([]);
  const [isLinkFieldOpen, setLinkFieldOpen] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  const MAX_FILE_SIZE = 200;
  const UPLOAD_FOLDER = "videos";
  const MAX_VIDEOS_COUNT = 10;

  const isUploadLimitReached = props.values.videoGallery.length >= MAX_VIDEOS_COUNT;

  useEffect(() => {
    if (props.values.videoGallery && !isLoading) {
      const mappedMedia = props.values.videoGallery.map((item: any) => {
        return { url: item.url, key: item.key, fileName: item.fileName, type: item.type, isLoading: false }
      })
      setMedia(mappedMedia)
    }
  }, [isLoading, props.values.videoGallery, props.values.videoGallery.length])


  const uploadFile = async (event: any) => {
    if (!event.target.files) return

    let file = event.target.files[0]

    if (!checkFileSizeInMB(file.size, MAX_FILE_SIZE)) return alert("Maximum file size is 150mb")

    const placeholder = {
      name: file.name.replace(/\.[^/.]+$/, ""),
      type: 'video',
      isLoading: true
    }

    setMedia([...media, placeholder])
    setIsLoading(true)

    const resp = await generateSignedUrl({ filename: file.name, filetype: file.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, file)
        const addVideoDto = {
          videoGallery: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            fileName: sliceFullText(file.name.replace(/\.[^/.]+$/, ""), 40),
            isMain: false,
            type: 'video'
          }
        }
        await saveVideo(addVideoDto)
        props.setFieldValue(`videoGallery.${media.length}`, addVideoDto.videoGallery)
        setVideo(addVideoDto.videoGallery)
        setMedia(() => ([...media, { ...addVideoDto.videoGallery, isLoading: false }]))
      } catch (error) {
        setMedia([...media])
      }
    }

    event.target.value = null; // reset input value
    setIsLoading(false)
  }


  const removeVideo = async (fileIndex: number, file: any) => {
    if (isLoading) return
    setIsLoading(true);
    const copiedVideos = [...media];
    copiedVideos[fileIndex].isLoading = true
    setMedia(copiedVideos);
    const removeFileDto = {
      fileData: {
        videoGallery: { key: file.key }
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: file.key
    }
    const resp = await deleteVideo(removeFileDto)
    unsetVideo(file.key)

    if (!resp.data.err) {
      copiedVideos.splice(fileIndex, 1);
    } else {
      copiedVideos[fileIndex].isLoading = false
    }
    props.setFieldValue('videoGallery', [...copiedVideos])
    setMedia([...copiedVideos]);
    setIsLoading(false)
  }

  const saveVideoLink = async () => {
    const fileKey = uuid();
    const addVideoDto = {
      videoGallery: {
        key: fileKey,
        url: videoLink,
        fileName: 'Video title',
        isMain: false,
        type: 'link'
      }
    }
    await saveVideo(addVideoDto);
    setVideo(addVideoDto.videoGallery);
    props.setFieldValue(`videoGallery.${media.length}`, addVideoDto.videoGallery)
    setMedia(() => ([...media, { ...addVideoDto.videoGallery, isLoading: false }]));
    setLinkFieldOpen(false);
    setVideoLink(''); // reset input value
    setIsLoading(false);
  }

  return (
    <>
      {
        <FieldArray
          name="videoGallery">
          {({ remove }) =>
            !!media.length && media.map((item: any, index: number) =>
              <VideoPreview
                url={item.url}
                key={index}
                itemIndex={index}
                onRemove={() => removeVideo(index, item)}
                isLoading={item.isLoading}
                values={props.values}
                handleChange={props.handleChange}
                setFieldValue={props.setFieldValue}
                removeFromForm={remove}
              />
            )}
        </FieldArray>

      }
      {!isUploadLimitReached && <VideoUploadButtons
        isLoading={isLoading}
        onChange={uploadFile}
        fileTypes="video/*"
        setLinkFieldOpen={setLinkFieldOpen}
        isLinkFieldOpen={isLinkFieldOpen}
      />}
      {
        isLinkFieldOpen && <LinkFieldWrap>
          <Field
            as={Input}
            type={'text'}
            value={videoLink}
            maxLength={400}
            onChange={(evant: any) => setVideoLink(evant.target.value)}
            labelText={'Link to the video'} />
          <AddLinkButton>
            <SPSecondaryButton type='button' onClick={saveVideoLink} width='100%'>
              Add
            </SPSecondaryButton>
          </AddLinkButton>
        </LinkFieldWrap>
      }

    </>
  )
})
