import { theme } from '../../../../theme';
import styled from 'styled-components';

export const OrganizationCardContainer = styled('div') <{ isDesktop: boolean, fullScreen?: boolean }>`
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  padding: ${theme.offsets.extraBig};
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.lg};
    padding: ${theme.offsets.lg};
  }
  ${props=>props.fullScreen && `
  @media(max-width: ${theme.screens.mobile}) {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }
  `}
`

export const OrganizationCardContent = styled('div') <{ isDesktop: boolean, hasTitle: boolean }>`
  margin: ${(props) => props.hasTitle ? `${theme.offsets.extraBig} auto 0` : '0 auto'};
  hr{
    border-top: 1px solid ${theme.colors.lightGray};
  }
  ${(props) => !props.isDesktop && `
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${props.hasTitle ? `${theme.offsets.xxl} auto 0` : '0 auto'};
    hr{
      margin-top: ${theme.offsets.lg};
      margin-bottom: ${theme.offsets.lg};
    }
  }
  `}
`

export const Title = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  font-weight: 500;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
`

export const TitleWrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled('button')`
  background: none;
  border: none;
`

export const Subtitle = styled('p')`
  font-size: 14px;
  color: ${theme.colors.black};
  opacity: 0.5;
  width: 100%;
  margin-top: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.desktopXXL}) {
    margin-top: ${theme.offsets.sm};
  }
`

export const SubtitleNoMg = styled(Subtitle) <{center?: boolean}> `
  margin-top: ${theme.offsets.sm};
  ${(props) => props.center && `
    text-align: center;
  `}
`

export const SubtitleTutorial = styled('div')`
  cursor: pointer;
  font-size: 14px;
  width: 90px;
  margin: ${theme.offsets.sm} auto 0;
  text-align: center;
  color: ${theme.colors.lightOrange}
`
