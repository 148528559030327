import styled from 'styled-components'
import { theme } from '../../../../theme'
import { Link } from 'react-router-dom';

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 80px;
  height: 100px;
  @media(max-width: ${theme.screens.desktop}) {
    height: 70px;
    padding: 0 ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.lg};
    height: 65px;
  }
`

export const HeaderContent = styled('div')`
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Logo = styled('img')`
  max-height: 62px;
  @media(max-width: ${theme.screens.desktop}) {
    max-height: 47px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-height: 33px;
  }
`

export const CtaContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center; 

  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }

  * + * {
    margin-left: ${theme.offsets.xxl};
    @media(max-width: ${theme.screens.tablet}) {
      margin-left: ${theme.offsets.lg};
    }
  }
`

export const HeaderText = styled('span')`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  @media(max-width: ${theme.screens.desktopLarge}) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const HeaderCta = styled(Link)`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${theme.colors.darkGray};
  border: 1px solid ${theme.colors.mediumGray};
  box-sizing: border-box;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  padding: 18px 48px;
  transition: 300ms all ease-in-out;
  &:hover {
    color: ${theme.colors.darkGray};
    border-color: ${theme.colors.darkGray};
  }
  @media(max-width: ${theme.screens.desktopLarge}) {
    font-size: 14px;
    line-height: 18px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 14px 48px;
  }
`
