import { Field } from "formik";
import { Input } from "../../Input";
import { ContactFieldProps } from './Contacts.props';
import { RemoveBtn, Title, FieldHeader, ErrorText, AvatarWrap } from "./Contacts.presents";
import { Avatar } from "../../Avatar";
import userIcon from "../../../../assets/img/avatar.svg";
import { useRootStore } from "../../../../state/stores/storeContext";
import { checkFileSizeInMB } from "../../../../services/helper";
import { generateSignedUrl, s3upload } from "../../../../services/api/fileManagement/fileManagement.domains";

export const ContactField = ({ index, remove, contactArray, errors, handleChange, setFieldValue }: ContactFieldProps) => {
  const mainValidationCondition = (fieldName: string) => errors && errors[index] && errors[index][fieldName];

  const MAX_LOGO_SIZE = 25;
  const UPLOAD_FOLDER = 'images'

  const { organizationStore } = useRootStore();

  const uploadContactLogo = async (event: any) => {
    if (!event.target.files) return

    let uploadFile = event.target.files[0];
    if (!checkFileSizeInMB(uploadFile.size, MAX_LOGO_SIZE)) {
      event.target.value = null;
      return alert(`Maximum photo size is ${MAX_LOGO_SIZE} megabytes`)
    }
    const resp = await generateSignedUrl({ filename: uploadFile.name, filetype: uploadFile.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, uploadFile)
        const addLogoDto = {
          fileData: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            fileName: uploadFile.name.replace(/\.[^/.]+$/, ""),
          },
          itemIndex: index
        }
        await organizationStore.saveContactLogo(addLogoDto)
        setFieldValue(`contactPerson.${index}.logo`, {key: resp.data.data.key, url: resp.data.data.fileUrl,})
      } catch (error) {
        console.log(error)
      }
    }
    event.target.value = null; // reset input value
  }

  const deleteContactLogo = async (props: { isRemoveOnlyLogo: boolean }) => {
    const removeFileDto = {
      itemIndex: index,
      fileKey: contactArray[index].logo ? contactArray[index].logo.key : '',
      isRemoveOnlyLogo: props.isRemoveOnlyLogo
    }

    await organizationStore.deleteContactLogo(removeFileDto);
  }

  const removeArrayItem = () => {
    deleteContactLogo({ isRemoveOnlyLogo: false });
    remove(index);
  }

  const logoUrl = contactArray[index].logo ? contactArray[index].logo.url : '';

  const validPhoneHandler = (event: any) => {
    const letters = /^[0-9()+]+$/;
    const value = event.target.value;
    if (value.match(letters) || value === '' ) {
      handleChange(event);
    }
  }

  return <div>

    <FieldHeader>
      <Title>Contact Person</Title>
      <RemoveBtn onClick={() => removeArrayItem()}>Remove</RemoveBtn>
    </FieldHeader>

    <AvatarWrap>
      <Avatar
        url={logoUrl}
        placeholder={userIcon}
        onUpload={uploadContactLogo}
        onDelete={() => deleteContactLogo({ isRemoveOnlyLogo: true })}
        btnText={'image'} />
    </AvatarWrap>

    <Field
      as={Input}
      name={`contactPerson.${index}.fullName`}
      onChange={handleChange}
      labelText={'Full Name'}
      value={contactArray[index].fullName}
      maxLength={60}
      error={mainValidationCondition('fullName')}
    />
    <ErrorText>{mainValidationCondition('fullName') ? errors[index].fullName : ''}</ErrorText>

    <Field
      as={Input}
      name={`contactPerson.${index}.position`}
      onChange={handleChange}
      labelText={'Position'}
      value={contactArray[index].position}
      maxLength={50}
      error={mainValidationCondition('position')}
    />
    <ErrorText>{mainValidationCondition('position') ? errors[index].position : ''}</ErrorText>

    <Field
      as={Input}
      name={`contactPerson.${index}.email`}
      onChange={handleChange}
      labelText={'Email'}
      value={contactArray[index].email}
      maxLength={50}
      error={mainValidationCondition('email')}
    />
    <ErrorText>{mainValidationCondition('email') ? errors[index].email : ''}</ErrorText>

    <Field
      as={Input}
      name={`contactPerson.${index}.phoneNumber`}
      onChange={validPhoneHandler}
      labelText={'Phone number:'}
      value={contactArray[index].phoneNumber}
      maxLength={18}
      error={mainValidationCondition('phoneNumber')}
    />
    <ErrorText>{mainValidationCondition('phoneNumber') ? errors[index].phoneNumber : ''}</ErrorText>

  </div>
}