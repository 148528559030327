import styled from "styled-components";
import { theme } from "../../../../theme";
import checkedIcon from '../../../../assets/img/checked.svg';

const CHECKMARK_SIZE = '24px';

export const Wrapper = styled('div') <{ width?: string, disabled?: boolean }>`
  position: relative;
  width: fit-content;
& input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
}

& input:checked ~ label span {
  border: 1px solid ${props => props.disabled ? theme.colors.lightGray : theme.colors.lightOrange};
  background-color: ${props => props.disabled ? theme.colors.lightGray : theme.colors.lightOrange};
}

& input:checked ~ label span:after {
  display: block;
  width: 100%;
  height: 100%;
}
`

export const LabelWrap = styled('label')`
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  border-radius: 100%;
  display: flex;
  width: fit-content;
  align-items: center;
`

export const Checkmark = styled('span')`
  position: relative;
  width: ${CHECKMARK_SIZE};
  height: ${CHECKMARK_SIZE};
  background: ${theme.colors.white};
  border-radius: 100%;
  border: 1px solid ${theme.colors.lightGray};
  margin-right: ${theme.offsets.md};
  :after {
    content: "";
    position: absolute;
    display: none;
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
`