import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import {CalendarIcon, CustomInput, Wrapper, Label, CloseIcon} from "./DatePicker.presents";
import { PropsType } from "./DatePicker.props";

const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <CustomInput type="button" onClick={onClick} ref={ref} hasValue={!!value}>
    {value}
    <CalendarIcon />
  </CustomInput>
));

export const Datepicker = (props: PropsType) => {
  const { value, setValue, maxDate, minDate, label, name, showMonthYearPicker, isDisabled } = props;

  return <Wrapper>
    {label && <Label>
      {label}
    </Label>}
    <DatePicker
      disabled={!!isDisabled}
      name={name}
      selected={value}
      onChange={(date: Date | null) => setValue(date)}
      showYearPicker={!showMonthYearPicker}
      showMonthYearPicker={!!showMonthYearPicker}
      customInput={<ExampleCustomInput />}
      maxDate={maxDate}
      minDate={minDate}
      dateFormat={showMonthYearPicker ? "MM/yyyy" : "yyyy" }/>
    {value && <CloseIcon onClick={() => setValue("")}/>}
  </Wrapper>
}