import 'react-app-polyfill/ie11';
import { Field, Formik } from 'formik';
import { useState } from "react";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { Input } from '../../../common/Input';
import { SPPrimaryButton } from '../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { ButtonWrap, ErrorText, Title, Wrapper, Description } from './ForgotPassword.presents';
import { SPWhiteButton } from '../../../common/buttons/SPWhiteButton/SPWhiteButton';
import { useHistory } from 'react-router-dom';
import { restoreCandidatePassword } from '../../../../services/api/auth/auth.domains';
import { ReactComponent as Spinner } from "../../../../assets/img/simpleSpinner.svg";

export const VerifyEmail = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [serverError, setServerError] = useState('')
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToSignIn = () => {
    history.push({
      pathname: "/candidate/sign-in",
      state: {
        prev: true
      }
    })
  }

  return <Wrapper>
    <Title>
      {t('forgotPassword.emailVerTitle')}
    </Title>
    <Description>
      Enter your email address and we will reset your password!
    </Description>
    <Formik
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      })}
      onSubmit={async (values, { resetForm }) => {
        setIsLoading(true);
        const payload = {
          email: values.email,
          hostname: window.location.hostname
        }
        const resp = await restoreCandidatePassword(payload)
        if (resp.data.err) {
          setServerError(resp.data.err)
        }
        else{
          history.push({
            pathname: "/candidate/sign-in",
            state: {
              helperText: "If you have an account, we’ll email you a reset link",
              prev: true
            }
          });
        }
        setIsLoading(false);
        resetForm();
      }
      }
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Field
            as={Input}
            name={'email'}
            type={'text'}
            value={values.email}
            onChange={handleChange}
            labelText={t('label.email')}
            error={!!errors.email}
          />
          <ErrorText>{errors.email}</ErrorText>

          <ButtonWrap isLoading={isLoading}>
            <SPPrimaryButton
              width='100%'
              type='submit'
              disabled={isLoading}
              onClick={() => setFirstSubmitComplete(true)}>
              {isLoading ? <Spinner /> : t('forgotPassword.resetPassword')}
            </SPPrimaryButton>
          </ButtonWrap>
          <SPWhiteButton
            width={'100%'}
            isLight
            onClick={redirectToSignIn}>
            Back
          </SPWhiteButton>
        </form>
      )}
    </Formik>
    <ErrorText>{serverError}</ErrorText>
  </Wrapper>
}