import { getPlaces } from '../../../../../services/api/outsideResources/outsideResources.domains';
import { ResponseDataProps, saveHiringLocationProps, saveLocationDataProps, setterCoordinatesProps } from '../Modals.props';

//***

// For Hiring Location

//*** 
const citiesCoordinateWorker = async ({ locationItem, coordinates, validLocation }: saveHiringLocationProps) => {
  const placeAPIResponse = await getPlaces('city', { countryCode: locationItem.country, stateCode: locationItem.territory });
  const placeData = placeAPIResponse.data.data;
  const filteredCoordinates = placeData && await placeData.filter((cityItem: any) => locationItem.city.includes(cityItem.value));

  //Get Region coordinates for the case with custom cities name
  let dataForState: ResponseDataProps | null;
  if (!placeData) {
    const statePlaceResponse = await getPlaces('state', { countryCode: locationItem.country });
    const statePlaceData = statePlaceResponse.data.data;
    dataForState = statePlaceData ? await statePlaceData.find((item: any) => locationItem.territory === item.value) : null;
  }

  //Set coordinates for every city from the list 
  locationItem.city.forEach((cityItem: any) => {
    const dataForCity = filteredCoordinates && filteredCoordinates.find((elem: any) => elem.value === cityItem);
    let cityInfo: ResponseDataProps = {
      label: `${locationItem.country}, ${locationItem.territory}, ${cityItem}`,
      value: cityItem,
      latitude: '',
      longitude: ''
    };

    const addDataToState = () => {
      validLocation.push(cityInfo);
      coordinates.push(cityInfo);
    }

    if (dataForCity) {
      cityInfo.latitude = dataForCity.latitude;
      cityInfo.longitude = dataForCity.longitude;
      addDataToState();
    } else if (!dataForCity && dataForState) {
      //Set region coordinates instead of city coordinates (case with custom city name)
      cityInfo.latitude = dataForState.latitude;
      cityInfo.longitude = dataForState.longitude;
      addDataToState();
    }
  });
}

const countryCoordinateWorker = async ({ locationItem, coordinates, validLocation }: saveHiringLocationProps) => {
  const placeAPIResponse = await getPlaces('country');
  const placeData = placeAPIResponse.data.data;
  const filteredCoordinates = await placeData.find((countryItem: any) => locationItem.country === countryItem.value);

  const countryInfo = {
    label: `${filteredCoordinates.value}`,
    latitude: filteredCoordinates.latitude,
    longitude: filteredCoordinates.longitude,
    value: filteredCoordinates.value
  }

  validLocation.push(countryInfo);
  coordinates.push(filteredCoordinates);
}

export const setCoordinatesForHiringLocation = async ({ locationsData, coordinates, validLocation, setLocationDataToState }: setterCoordinatesProps) => {
  for (const item of locationsData) {
    const isLocationAnywhereFromCountry = item.acceptRemoteWorkers.includes('anywhereFromCountry');
    if (isLocationAnywhereFromCountry) {
      await countryCoordinateWorker({ locationItem: item, coordinates, validLocation })
    } else {
      await citiesCoordinateWorker({ locationItem: item, coordinates, validLocation });
    }
    //Save all coordinates to the state
    setLocationDataToState(validLocation, coordinates);
  }
}

//***

// For Primary Location

//*** 

const savePrimaryLocationData = async ({ responseData, locationItem, coordinates, validLocation, locationType }: saveLocationDataProps) => {
  const locationData = await responseData.find((item: any) => locationItem[locationType] === item.value);
  if (locationData) {
    const cityInfo = {
      label: `${locationItem.country}, ${locationItem.territory}, ${locationItem.city}`,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
      value: locationItem.city
    }
    coordinates.push(locationData);
    validLocation.push(cityInfo);
  }
}

export const setCoordinatesForPrimaryLocation = async ({ locationsData, coordinates, validLocation, setLocationDataToState }: setterCoordinatesProps) => {
  for (const item of locationsData) {

    const mainDto = {
      locationItem: item,
      coordinates,
      validLocation,
    };

    const cityPlaceResponse = await getPlaces('city', { countryCode: item.country, stateCode: item.territory });
    const cityPlaceData = cityPlaceResponse.data.data;
    //Set city coordinates
    if (cityPlaceData) {
      const dto: saveLocationDataProps = {
        ...mainDto,
        responseData: cityPlaceData,
        locationType: 'city'
      }
      await savePrimaryLocationData(dto);
    } else {
      //Set region coordinates instead of city coordinates (case with custom city name)
      const statePlaceResponse = await getPlaces('state', { countryCode: item.country });
      const statePlaceData = statePlaceResponse.data.data;
      const dto: saveLocationDataProps = {
        ...mainDto,
        responseData: statePlaceData,
        locationType: 'territory'
      }
      await savePrimaryLocationData(dto)
    }
  }
  setLocationDataToState(validLocation, coordinates);
}
