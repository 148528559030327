import { theme } from '../../../theme/theme';
import { colors } from '../../../constants/colors';
import styled from 'styled-components';

export const StyleForWrap = styled('div')`
  width:100%;
  max-width: 1112px;
  scroll-margin-top: 120px;
  .download-btn{
    display:flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #E2E3E5;
    box-sizing: border-box;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${colors.dark};
    img{
      margin-right: 12px;
    }
  }
`
export const Subheader = styled("p")`
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.gray};
  margin: ${theme.offsets.sm} 0 0 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${theme.offsets.sm};
`

export const OverviewItemWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding-right: 20%;
`

const styleForLink = `
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  text-decoration: underline;
  text-underline-offset: 4px;
  white-space: pre-wrap;
  cursor: pointer;
  :hover{
    color: ${theme.colors.black};
    opacity: .8;
    text-decoration: underline;
  }
`

export const Link = styled("a")`
  ${styleForLink} 
`

export const LocationTitle = styled('div')`
  ${styleForLink}
  margin-right: ${theme.offsets.md};
`

export const Content = styled('div')`
  display: flex;
  @media(max-width: ${theme.screens.mobile}) { 
  flex-direction: column;
}
`

export const MainInformation = styled('div')`
  display: flex;
  justify-content: start;
  padding-right: ${theme.offsets.extraBig};
  border-bottom: 1px solid ${theme.colors.lightGray};
  margin-bottom: ${theme.offsets.extraBig};
  padding-bottom: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktop}) { 
    padding-right: 0;
  }
  @media(max-width: ${theme.screens.tablet}) { 
    margin-bottom: ${theme.offsets.xxl};
    padding-bottom: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) { 
  flex-direction: column;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
`

export const InfoBox = styled('div')`
  max-width: 25%;
  width: 100%;
  padding-right: ${theme.offsets.lg};
  word-wrap: break-word;
  @media(max-width: ${theme.screens.mobile}) { 
    max-width: 100%;
    margin-bottom: ${theme.offsets.lg};
    padding-bottom: ${theme.offsets.lg};
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding-right: 0;
  }
`

export const Subtitle = styled('div')`
  margin-bottom: ${theme.offsets.lg};
  font-size: ${theme.fontSizes.xl};
  @media(max-width: ${theme.screens.tablet}) { 
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    margin-bottom: ${theme.offsets.sm};
  }
`

export const Separator = styled('hr')`
  margin: ${theme.offsets.extraBig} 0;
  border-top: 1px solid #D1D7E3;
  @media(max-width: ${theme.screens.tablet}) { 
    margin: ${theme.offsets.xxl} 0;
  }
`

export const Description = styled('div')`
  max-width: 90%;
  @media(max-width: ${theme.screens.tablet}) { 
    max-width: 100%;
    font-size: 14px;
  }
`