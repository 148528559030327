import { theme } from './../../../../theme/theme';
import { colors } from '../../../../constants/colors';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

export const BenefitSubtitle = styled('p')`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${colors.dark};
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.lg};
    margin-bottom: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
  }
`

export const BenefitSeparator = styled('hr')`
  background: #ECECEC;
  height: 1px;
  border-top: none;
  margin-top: 24px;
  margin-bottom: 24px;
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.lg};
    margin-bottom: ${theme.offsets.lg};
  }
`

export const BenefitItemCol = styled(Col)`
  padding: 8px;
  @media(max-width: ${theme.screens.mobile}) {
    padding: 6px;
  }
`

export const BenefitItemRow = styled(Row)`
  margin-right: -23px;
  margin-left: -23px;
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: -21px;
    margin-left: -21px;
  }
`

export const BenefitItem = styled('div')`
  display:flex;
  border: 1px solid #E2E3E5;
  box-sizing: border-box;
  border-radius: 14px;
  min-height: 78px;
  align-items: center;
  font-size: 14px;
  padding: 0 ${theme.offsets.lg};
  img{
    margin-right: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.desktop}) {
    padding: 0 ${theme.offsets.md};
    min-height: 60px;
    img{
      width: 36px;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
    min-height: 52px;
    img{
      width: 28px;
    }
  }
`
