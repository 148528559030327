import { SliderItem, SliderWrapper, Wrapper, SubTitle, Title, Description, LogoWrap, LogoLink, HelperWrap } from "./Banner.presents";
import { ReactComponent as Logo } from '../../../../../assets/img/logo-white.svg';
import { HelperText } from "../../../../common/HelperText";
import { theme } from "../../../../../theme";
import { AUTOPLAY_SPEED, BannerContent, sliderSettings } from "./helper";

export const Banner = () => {
  return <Wrapper>
    <LogoWrap>
      <LogoLink to='/'>
        <Logo />
      </LogoLink>
    </LogoWrap>
    <SliderWrapper {...sliderSettings} autoplaySpeed={AUTOPLAY_SPEED}>
      {BannerContent.map((item, index) => <div key={`bannerItem${index}`}>
        <SliderItem>
          <div>{item.icon}</div>
          <SubTitle>GET FREE TOOLS TO HIRE TALENT</SubTitle>
          <Title>
            {item.title}
            <HelperWrap>
              <HelperText
                color={theme.colors.white}
                backgroundColor={'#5AAFFF'}
                text={item.helperText} />
            </HelperWrap>
          </Title>
          <Description>{item.description}
          </Description>
        </SliderItem>
      </div>)}
    </SliderWrapper>
    <div></div>
  </Wrapper>
}