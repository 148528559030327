import { useEffect, useState } from "react";
import { getDimensions } from "./helper";
import { MenuProps } from "./Menu.props";
import { MenuItem, Wrapper, DesktopMenu, MobileWrapper } from "./Menu.presents";

export const Menu = (props: MenuProps) => {
	const [activeTab, setActiveTab] = useState('mainInformation');
	const [disableHandleScroll, setDisableHandleScroll] = useState(false);

	const handleScroll = () => {
		const scrollPosition = window.scrollY + 5;
		const isScreenEnd = window.innerHeight + window.pageYOffset + 1 >= document.body.offsetHeight;
		const selected = props.menuList.find(item => {
			const ele = props.componentRef[item.key].current;
			if (ele) {
				const { offsetBottom, offsetTop } = getDimensions(ele);
				return scrollPosition > offsetTop && scrollPosition < offsetBottom;
			}
		});
	
		if (!isScreenEnd && selected && selected.key !== activeTab) {
			setActiveTab(selected.key);
		} else if (isScreenEnd) {
			setActiveTab( props.menuList[props.menuList.length - 1].key);
		}
	};
	
	const activeTabClickHandler = (key: string, setDisableHandleScroll: Function, setActiveTab: Function, componentRef: any) => {
		setDisableHandleScroll(true);
		componentRef[key].current.scrollIntoView();
		setActiveTab(key);
		setTimeout(() => setDisableHandleScroll(false), 1000);
	}

	useEffect(() => {
		if (!disableHandleScroll) {
			handleScroll()
			window.addEventListener("scroll", handleScroll);
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, [activeTab, disableHandleScroll]);

	const MenuItemRender = () => props.menuList.map((item, index) => <MenuItem
		onClick={() => activeTabClickHandler(item.key, setDisableHandleScroll, setActiveTab, props.componentRef)}
		key={index}
		isActive={activeTab === item.key}
		isDesktop={props.isDesktop}
		>
		{item.title}
	</MenuItem>)


	return <> {props.isDesktop ? <Wrapper>
		<DesktopMenu>
			{MenuItemRender()}
		</DesktopMenu>
	</Wrapper> :
		<MobileWrapper>
			{MenuItemRender()}
		</MobileWrapper>}
	</>
}
