import styled from "styled-components";
import { theme } from "../../../../../theme";

export const UploadButtonContainer = styled('button')`
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 186px;
  min-height: 52px;
  background: #F8DDC3;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  border-color: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #EA994B;
  }
  img {
    margin-left: 30px;
    width: 18px;
    height: 18px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`
