import { theme } from './../../../../theme/theme';
import { colors } from '../../../../constants/colors';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';

export const StyleForWrap = styled('div')`
  & .read-more{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0C75FF;
    border: none;
    background: none;
    padding: 0;
    margin-top: 8px;
  }
`

export const SocialLinks = styled('div')`
  display: flex;
  align-items: center;
  max-width: 200px;
  flex-wrap: wrap;
  a{
    margin-right: 25px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
`

export const CompanyLogo = styled('div')`
  background: #FFFFFF;
  border-radius: 32px;
  & img{
    object-fit: cover;
    border-radius: 32px;
    width: 96px;
    height: 96px;
  }
`

export const CompanyInfoCol = styled(Col)`
  display: flex;
  align-items: start;
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: 24px;  
  }
`

export const CompanyName = styled('p')`
  font-size: 24px;
  line-height: 28px;
  color: ${colors.dark};
  margin-left: 16px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 18px; 
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;  
  }
`

export const MainListRow = styled(Row)`
  display: flex;
  align-items: start;
  justify-content: space-between;
`
export const MainListContainer = styled(Container)`
  display: flex;
  align-items: start;
  padding-left: 0px;
  padding-right: 0px;
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;  
  }
`

export const ViewMoreBtn = styled('span')`
  font-weight: 500;
  font-size: 18px;
  color: #0C75FF;
  cursor: pointer;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px; 
    margin-bottom: ${theme.offsets.lg}; 
  }
`

export const ListTitle = styled('p')`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 8px;
  color: ${colors.dark};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.lg}; 
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;  
  }
`

export const ListSubtitle = styled('p')`
  font-size: 14px;
  line-height: 16px;
  color: rgba(63, 71, 79, 0.8);
  margin-bottom: 8px;
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.md};  
  }
`

export const ListText = styled('p')`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.dark};
  margin-bottom: 24px;
@media(max-width: ${theme.screens.desktop}) {
  font-size: 14px; 
  margin-bottom: ${theme.offsets.lg};
}
`

export const AboutCompanySeparator = styled('hr')`
  background: #ECECEC;
  height: 1px;
  border-top: none;
  margin-top: 0px;
  margin-bottom: 24px;
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: 16px;
  }
`