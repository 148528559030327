import { SchemePropsType } from './Contact.props';
import { Input } from '../../../common/Input';
import { Field } from 'formik';
import { SelectCom } from '../../../common/Select'
import { StyleForLabel, StyleForInputsPanel, StyleForTabToggle } from '../Steps.presents';
import { Checkbox } from '../../../common/Checkbox';
import { FileBtn } from '../../../common/FileBtn';
import { HelperText } from '../../../common/HelperText';
import industriesOptions from '../../../../assets/staticData/industries.json';
import sizeOption from '../../../../assets/staticData/organizationSize.json';
import { PhotoUploadStep } from '../../BasicComponents/FilesGallery/PhotoUploadStep';
import { VideoUploadStep } from '../../BasicComponents/FilesGallery/VideoUploadStep';

export const SelectComponent = (data: SchemePropsType) => {

  const organizationLogo = {
    fileId:  data.values.directEmployerLogo.id || (data.organizationData.logoId || ''),
    fileUrl: data.values.directEmployerLogo.fileUrl || (data.organizationData.logoUrl || ''),
    filename:  data.values.directEmployerLogo.filename || ''
  }

  const sortedIndustriesOptions = industriesOptions.sort((prevItem, nextItem) => {
    if (prevItem.label < nextItem.label) {
      return -1
    } else {
      return 1
    }
  })


  switch (data.type) {
    case 'main-info':
      const socailContact = ['Website', 'LinkedIn', 'Facebook', 'Twitter', 'Youtube', 'Instagram']
      return <div className="d-flex inputs-wrap justify-content-between">
          <StyleForInputsPanel>
            <Field
              as={Input}
              name={'organizationName'}
              labelText={'Organization name'}
              m={'0 0 24px'}
              onChange={data.handleChange}
              value={data.values.organizationName}
              setFieldTouched={data.setFieldTouched}
              error={data.errors && data.errors.organizationName}
            />
            <StyleForLabel htmlFor="industry">
              <div className='d-flex justify-content-between'>Industry</div>
            </StyleForLabel>
            <Field
              m={'0 0 24px'}
              className={'w-100'}
              name={'industry'}
              options={sortedIndustriesOptions}
              component={SelectCom}
              setFieldTouched={data.setFieldTouched}
              error={data.errors && data.errors.industry}
            />
            <StyleForLabel htmlFor="organizationSize">
              <div className='d-flex justify-content-between'>Organization’s size</div>
            </StyleForLabel>
            <Field
              m={'0 0 24px'}
              className={'w-100'}
              name={'organizationSize'}
              options={sizeOption}
              component={SelectCom}
              setFieldTouched={data.setFieldTouched}
              error={data.errors && data.errors.organizationSize}
            />
            <div className='d-flex flex-wrap justify-content-between'>
              {socailContact.map((item, index) => <Input
                value={data.values[`contact${item}`]}
                onChange={data.handleChange}
                key={index}
                m={'0 0 24px'}
                w={'47.5%'}
                name={'contact' + item}
                labelText={<div className='d-flex justify-content-between'>
                  <span>{item}</span>
                  <span className='optional'>optional</span>
                </div>}
              />)}
            </div>
            <div className='d-flex align-items-center'>
              <Checkbox
                value={'confidential'}
                name={'confidentialForEmployer'}
                labelText={'I would like the employer’s name to remain confidential'}
                handleChange={data.handleChange}
                checked={data.values['confidentialForEmployer'] && data.values['confidentialForEmployer'].includes('confidential')}
              />
              <HelperText autoChangePosition text={'If you choose this option, the name of the staffing agency including all other information will be invisible on the Job Story. The Job Story will show the employer as “Confidential”.'} />
            </div>
          </StyleForInputsPanel> 
          <FileBtn
            className='upload-btn'
            fileId={organizationLogo.fileId}
            text={'Upload logo'}
            name='directEmployerLogo'
            fileFolder='images'
            fileUrl={organizationLogo.fileUrl}
            filename={organizationLogo.filename}
            handleChange={data.setFieldValue}
            componentType='logoComponent'
            fileFormat='.png, .jpeg, .jpg, .heic, .raw'
          />
        </div>
    case 'contacted-for-job':
      const inputList = [{ name: 'fullName', label: 'Full Name' }, { name: 'email', label: 'Email address' }, { name: 'phone', label: 'Phone number' }]
      return <div className="d-flex inputs-wrap justify-content-between flex-column">
        {inputList.map((item, index) => <div className='d-flex flex-column align-items-start' key={index}>
          <Field
            as={Input}
            value={data.values[item.name]}
            name={item.name}
            labelText={item.label}
            m={'0 0 24px'}
            onChange={data.handleChange}
            setFieldTouched={data.setFieldTouched}
            error={data.errors && data.errors[item.name]}
          />
          <Checkbox
            m={index < inputList.length - 1 ? '0 0 28px' : '0px'}
            value={'visible'}
            name={item.name + 'Visible'}
            labelText={'Publicly visible'}
            handleChange={data.handleChange}
            checked={data.values[item.name + 'Visible'] && data.values[item.name + 'Visible'].includes('visible')}
          />
        </div>)}
      </div>
    case 'photo-gallery':
      return <PhotoUploadStep />

    case 'media-gallery':
      return <VideoUploadStep />
    default:
  }
}