import {
  Wrapper,
  Banner,
  ButtonsWrap,
  ShareBlock,
  Description,
  ShareBtnsWrap,
  ShareBtn,
  Separator,
  CopylinkContainer,
  CopylinkInput,
  CopylinkText
} from "./StatusStep.presents";
import bannerImg from '../../../../assets/img/published.svg';
import { SPHeading2 } from "../../../../theme/typography";
import { useHistory } from "react-router";
import { PropsType } from "./StatusStep.props";
import { SPLightButton } from "../../../common/buttons/SPLightButton/SPLightButton";
import { SPBaseButton } from "../../../common/buttons/SPBaseButton";
import { ReactComponent as LinkedinIcon } from './icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as TelegramIcon } from './icons/telegram.svg';
import { ReactComponent as WhatsAppIcon } from './icons/whatsapp.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { SPSecondaryButton } from "../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { useState } from "react";

export const StatusStep = (props: PropsType) => {
  const history = useHistory();
  const [isCopied, setIsCopied] = useState(false);

  const shareUrl = props.jobId ? `${window.location.host}/jobs?id=${props.jobId}` : window.location.href;

  const copyJobUrl = () => {
    navigator.clipboard.writeText(shareUrl).then(function () {
      setIsCopied(true)
    }, function (err) {
      console.error(err)
    });
  }

  const openJobPreview = () => window.open(`/jobs?id=${props.jobId}`, "_blank");
  const openJobsList = () => history.push('/my-jobs');

  return <Wrapper>
    <Banner src={bannerImg} alt="banner" />
    <SPHeading2>Your Job Story™ has been successfully published!</SPHeading2>
    <ShareBlock>
      <Description>Share this job to attract more candidates:</Description>
      <ShareBtnsWrap>
        <LinkedinShareButton url={shareUrl}>
          <ShareBtn color="#2866BC">
            <LinkedinIcon />
          </ShareBtn>
        </LinkedinShareButton>
        <FacebookShareButton url={shareUrl}>
          <ShareBtn color="#1877F2">
            <FacebookIcon />
          </ShareBtn>
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <ShareBtn color="#5AADEC">
            <TwitterIcon />
          </ShareBtn>
        </TwitterShareButton>
        <FacebookMessengerShareButton appId="" url={shareUrl}>
          <ShareBtn color="#0089FF">
            <MessengerIcon />
          </ShareBtn>
        </FacebookMessengerShareButton>
        <TelegramShareButton url={shareUrl}>
          <ShareBtn color="#28A8E9">
            <TelegramIcon />
          </ShareBtn>
        </TelegramShareButton>
        <WhatsappShareButton url={shareUrl}>
          <ShareBtn color="#02E677">
            <WhatsAppIcon />
          </ShareBtn>
        </WhatsappShareButton>
        <EmailShareButton url={shareUrl}>
          <ShareBtn color="#96B3C6">
            <EmailIcon />
          </ShareBtn>
        </EmailShareButton>
      </ShareBtnsWrap>
      <Separator>Or</Separator>
      <CopylinkText>Copy link to this job:</CopylinkText>
      <CopylinkContainer>
        <CopylinkInput type="text" value={shareUrl} disabled />
        <SPSecondaryButton onClick={copyJobUrl}>
          {isCopied ? "Copied" : "Copy"}
        </SPSecondaryButton>
      </CopylinkContainer>
    </ShareBlock>
    <ButtonsWrap>
      <SPBaseButton width="100%" onClick={openJobsList} type='button'>Back to the admin panel</SPBaseButton>
      <SPLightButton width="100%" onClick={openJobPreview} type='button'>View the job story</SPLightButton>
    </ButtonsWrap>
  </Wrapper>
}