import { theme } from './../../../theme/theme';
import { colors } from './../../../constants/colors';
import styled from 'styled-components'
import { mainStyleType } from '../../../themes/mainStylesType'
import { InputPropsType } from './Input.props'

export const StyleForInput = styled("input") <InputPropsType>`
  width:100%;
  border: 1px solid #D1D7E3;
  font-size: ${theme.fontSizes.lg};
  box-sizing: border-box;
  border-radius: 10px;
  height: 52px;
  padding: 14px;
  position: relative;
  color: ${colors.dark};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 40px;
  }
  ${(props: InputPropsType) => props.error && `
    border: 1px solid ${theme.colors.lightRed};
`}
  ${(props: InputPropsType) => props.className === 'job-experience' && `
    align-self: flex-end;
  `}
  &::placeholder{
    color: ${theme.colors.gray};
    font-size: 14px;
  }
  &:disabled{
    background-color: #fff;
    color: rgba(67, 62, 58, 0.3);
  }
  &:disabled::placeholder{
    color: rgba(67, 62, 58, 0.3);
  }
  &:hover{
    border-color: hsl(0, 0%, 70%);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button{
    appearance: none;
  } 
`

export const StyleForTextArea = styled("textarea") <InputPropsType>`
  width: 100%;
  border: 1px solid ${theme.colors.lightGray};
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px;
  position: relative;
  color: ${colors.dark};
  resize: none;
  height: ${(props: InputPropsType) => props.h ? props.h : 156}px;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  ${(props: InputPropsType) => props.error && `
    border: 1px solid ${colors.red};
`}
  &::placeholder{
    color: ${theme.colors.gray};
    font-size: 14px;
  }
  &:disabled{
    background-color: #fff;
    color: rgba(67, 62, 58, 0.3);
  }
  &:disabled::placeholder{
    color: rgba(67, 62, 58, 0.3);
  }
  &:hover{
    border-color: hsl(0, 0%, 70%);
  }
`

export const StyleForLabel = styled("label")`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:8px;
  text-transform: capitalize;
  width: 100%;
  color: ${theme.colors.black};
  & .optional{
    color: #656B72;
  }
`

export const InputWrap = styled("div")`
  width:  ${(props: InputPropsType) => props.w ? props.w : '100%'};
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
`

export const PasswordBtn = styled("span")`
  position: absolute;
  display: flex;
  cursor: pointer;
  right: 14px;
  top: 67%;
  transform: translate(0%, -50%);
`