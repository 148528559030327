import { BenefitSubtitle, BenefitSeparator, BenefitItemCol, BenefitItem, BenefitItemRow } from './JobBenefits.presents';
import { PropsType } from './JobBenefits.props';
import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { iconMap } from './JobBenefits.icons';
import { JobStoryCard } from '../../../common/Cards/JobStoryCard';

export const JobBenefits = observer(({ jobData, componentRef, bannersUrl, isBenefitsShow, benefitList }: PropsType) => {
  const { t } = useTranslation();

  return isBenefitsShow ? <JobStoryCard title={'Benefits & perks'} imageUrl={bannersUrl.benefitsBanner} componentRef={componentRef}>
    {
      benefitList.map((benefitItem, index) => {
        if (jobData.specificationStep[benefitItem.value] && jobData.specificationStep[benefitItem.value].length > 0) {
          return <>
            <BenefitSubtitle>{benefitItem.title}</BenefitSubtitle>
            <BenefitItemRow>
              <Container fluid className='d-flex flex-wrap px-0'>
                {jobData.specificationStep[benefitItem.value].map((item: string) => <BenefitItemCol md={4}>
                  <BenefitItem>
                    <img src={iconMap[benefitItem.value][item] || iconMap[benefitItem.value].defaultIcon} alt="icon" />
                    {t(`jobStory.${benefitItem.value}.${item}`, item)}
                  </BenefitItem>
                </BenefitItemCol>
                )}
              </Container>
            </BenefitItemRow>
            {index !== (benefitList.length - 1) ? <BenefitSeparator /> : ''}
          </>
        }
      })
    }
  </JobStoryCard> : null
})