// Colors variables
export enum SPColors {

  /**
   * @Black #2D353D 
   */
  black = "#2D353D",

  /**
   * @Blue #0083FF 
   */
  blue = "#0083FF",

  /**
   * @DarkBlue #0168ca 
   */
  darkBlue = "#0168ca",

    /**
   * @blueGray #0168ca 
   */
  blueGray = "#5D7A8C",

  /**
   * @White #ffffff / rgb (255 255 255)
   */
  white = "#fff",

  
  /**
   * @mediumGray #2E3E5
   */
  mediumGray = "#E2E3E5",

  /**
   * @DarkGray #3F474F
   */
  darkGray = "#3F474F",

   /**
   * @Gray #928D8A
   */
    gray = "#928D8A",
  
  /**
   * @LightGray #D1D7E3
   */
  lightGray = "#D1D7E3",

  /**
   * @LightBlue #AEC7D7
   */
  lightBlue = "#AEC7D7",

  /**
  * @LightGreen #CCDDD7
  */
  lightGreen = "#CCDDD7",

  /**
  * @LightRed #FFA999
  */
  lightRed = "#FFA999",

   /**
  * @Red #FF5F5F
  */
    red = "#FF5F5F",

  /**
  * @LightYellow #FCDD7F
  */
  lightYellow = "#FCDD7F",

  /**
  * @LightOrange #EC923C
  */
  lightOrange = "#EC923C",

  /**
   * @Transparent transparent / rgba (0 0 0 0)
   */
  transparent = "transparent",

  /**
   * @Coral #F8DDC3
   */
   coral = "#F8DDC3",
}
