import { PropsType } from './MixedShift.props'
import { StyleForMixedShift } from './MixedShift.presents'
import { SelectDayShift } from './SelectDayShift'
import { FieldArray } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'

const getTimeOption = () => {
  const itemsArray = [];
  for (let i = 0; i < 24; i++) {
    const hour = moment().hours(i);
    itemsArray.push({ value: hour.format('HH:00'), label: hour.format('hh:00 A') });
  }
  return itemsArray;
}

export const week = [
  { title: 'Sunday', key: 'Sunday' },
  { title: 'Monday', key: 'Monday' },
  { title: 'Tuesday', key: 'Tuesday' },
  { title: 'Wednesday', key: 'Wednesday' },
  { title: 'Thursday', key: 'Thursday' },
  { title: 'Friday', key: 'Friday' },
  { title: 'Saturday', key: 'Saturday' }
]

export const MixedShift = (props: PropsType) => {
  const [timeOption, setTimeOption] = useState([{ value: '', label: '' }]);
  useEffect(() => {
    setTimeOption(getTimeOption())
  }, [])
  const options = [
    { value: 'regular', label: 'Regular day shift' },
    { value: 'morning', label: 'Morning shift' },
    { value: 'afternoon', label: 'Afternoon shift' },
    { value: 'night', label: 'Night shift' }
  ]
  return <FieldArray
    name="mixshiftData">
    {({ insert, remove, push }) => (
      <StyleForMixedShift>
        {timeOption.length > 1 && week.map((item, index) => <SelectDayShift
          key={index}
          index={index}
          name={item.key}
          title={item.title}
          options={options}
          handleChange={props.handleChange}
          value={props.values && { ...props.values.find(dayItem => dayItem && dayItem.day === item.key) }}
          setFieldValue={props.setFieldValue}
          timeOption={timeOption}
        />)}
      </StyleForMixedShift>
    )}
  </FieldArray>
}