import styled from 'styled-components';
import { theme } from '../../../../../../theme';

export const MenuWrap = styled('div') <{isOpen: boolean}>`
  display: ${(props) => props.isOpen ? 'flex' : 'none'};
  position: absolute;
  flex-direction: column;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  top: 71px;
  max-width: 350px;
  right: 0px;
  z-index: 11;
  overflow: hidden;
`

export const MenuItem = styled('p') <{ isWarning?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.isWarning ? theme.colors.red : theme.colors.black};
  margin: 0 ${theme.offsets.xxl};
  padding: ${theme.offsets.xxl} 0;
  border-bottom: ${(props) => props.isWarning ? 'none' : `1px solid ${theme.colors.lightGray}`};
  svg{
    margin-right: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.lg};
    margin: 0 ${theme.offsets.xl};
    padding: ${theme.offsets.xl} 0;
    svg{
      height: 20px;
      width: 20px;
    }
  }
  position: relative;
  transition: 300ms;
  ::after{
    content: "";
    display: block;
    width: 150%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  :hover::after{
    background-color: rgba(236,146,60,0.1);
  }
`