import { Label, RemoveBtn, Wrap } from "./RemoveLabel.presents"
import { PropsType } from "./RemoveLabel.props"

export const RemoveLabel = (props: PropsType) => {
  return <Wrap>
    <Label>
      {props.labelText}
    </Label>
    <RemoveBtn onClick={() => props.callback()}>
      Remove
    </RemoveBtn>
  </Wrap>
}