import { BenefitCard, TextContent, SocNetworksCard, GalleryCard } from "./Skeleton.presents";

export const textContentRendering = ({ width, length, fieldName }: { width: string, length: number, fieldName: string }) => {
  const itemsList = new Array(length).fill('');

  return itemsList.map((item, index) => <TextContent key={`${fieldName}${index}`} width={width} />)
}

export const benefitsRendering = () => {
  const itemsList = [30, 28, 32, 27, 36, 27, 36, 25];

  return itemsList.map((item, index) => <BenefitCard key={`benefits${index}`} width={`${item}%`} />)
}

export const socNetworksRendering = () => {
  const itemsList = new Array(6).fill('');

  return itemsList.map((item, index) => <SocNetworksCard key={`socNetworks${index}`} />)
}

export const galleryRendering = () => {
  const itemsList = new Array(6).fill('');

  return itemsList.map((item, index) => <GalleryCard key={`gallery${index}`} />)
}