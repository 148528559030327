import { Field, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sendSupportMessage } from "../../../services/api/user/user.domains";
import { Input } from "../Input";
import { ModalForResults } from "../Modal/ModalForResults";
import * as Yup from 'yup';
import { ButtonsWrap, ErrorText } from "./ErrorBoundary.presents";
import { SPLightButton } from "../buttons/SPLightButton/SPLightButton";
import { SPSecondaryButton } from "../buttons/SPSecondaryButton/SPSecondaryButton";

export const ErrorForm = ({ redirectPath }: { redirectPath: string }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');
  const [isSubmitted, setSubmitted] = useState(false);
  const [isBtnDisable, setBtnDisable] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const successfulMessage = 'Thanks for reaching out. A member of our support team will contact you shortly';
  const errorMessage = 'Something went wrong. Please try again later';

  const autoСloseWindow = () => {
    setTimeout(() => {
      closeWindow();
      setBtnDisable(false);
    }, 5000)
  };

  const closeWindow = () => {
    setModalOpen(false);
    history.replace(redirectPath);
  }

  return <>
    <Formik
      initialValues={{ message: '' }}
      validateOnChange={isSubmitted}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        message: Yup.string().min(3, '')
          .min(10, t('validation.min', { characters: 10 }))
          .max(3000, t('validation.max', { characters: 3000 }))
          .required('Required')
      })}
      onSubmit={async (values) => {
        setBtnDisable(true);
        const serverResponse = await sendSupportMessage({ subjectOfSupport: 'Report a bug', message: values.message });
        setResponseResult(serverResponse.data.err ? 'error' : 'successfully');
        setModalOpen(true);
        autoСloseWindow();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className='d-flex flex-column'>
          <Field
            as={Input}
            name={'message'}
            onChange={handleChange}
            value={values.message}
            type='textarea'
            error={errors.message}
          />
          <ErrorText>{errors.message}</ErrorText>
          <ButtonsWrap>
            <SPLightButton
              width="100%"
              disabled={isBtnDisable}
              type="button"
              onClick={() => history.push(redirectPath)}>
              Back
            </SPLightButton>
            <SPSecondaryButton
              width="100%"
              disabled={isBtnDisable}
              type="submit"
              onClick={() => setSubmitted(true)}>
              Send
            </SPSecondaryButton>
          </ButtonsWrap>
        </form>
      )}
    </Formik>
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={closeWindow}
      text={responseResult === 'successfully' ? successfulMessage : errorMessage}
    />
  </>
}