import { PropsType, ShowModalData } from "./CandidatesList.props";
import { StyleForWrap, TitleWrap, Title } from './CandidatesList.presents';
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../state/stores/storeContext";
import { useEffect } from "react";
import { useState } from "react";
import { TableActionsModal } from "../../common/Modals/TableActionsModal/TableActionsModal";
import { Actions } from "../../common/Modals/TableActionsModal/TableActionsModal.props";
import { resetNewReviewers } from "../../../services/api/jobStore/jobStore.domains";
import { DesktopCandidatesList } from "./Components/Desktop/DesktopCandidatesList";
import { MobileCandidatesList } from "./Components/Mobile/MobileCandidatesList";
import { EmptyListBanner } from "./Components/EmptyListBanner";
import { mobileSortList } from "./helper";
import { Pagination } from "../../common/Pagination";
import { ListTabs } from "../../common/Tabs/ListTabs";

export const CandidatesList = observer((props: PropsType) => {
  const {isSpinnerOpen, setSpinnerOpen} = props;
  const { candidatesStore } = useRootStore();

  const [showModal, setShowModal] = useState<ShowModalData>({ isOpen: false, typeOfModal: Actions.draft, jobId: '', oldStatus: '', candidateId: '' });
  const [activeTab, setActiveTab] = useState('all');
  const [activePage, setActivePage] = useState(0);
  const [activeActionBtn, setActiveActionBtn] = useState<number | null>(null);

  const [sortData, setSortData] = useState({ type: '', value: '' });
  const [mobileSortSelectedItem, setMobileSortSelectedItem] = useState<number | null>(null);

  const handleOpenMenu = (index: number) => {
    const isMenuOpen = index === activeActionBtn;
    setActiveActionBtn(isMenuOpen ? null : index);
  };

  const handleOpenModal = (typeOfModal: Actions, item: any) => {
    setShowModal({ isOpen: true, typeOfModal, jobId: item.jobId, oldStatus: item.candidateStatus, candidateId: item._id })
  };

  const handleSortTable = async (sortType: string) => {
    let newValueOfSorting = 'up'
    setSpinnerOpen(true);
    if (sortData.type === sortType) {
      newValueOfSorting = sortData.value === 'up' ? 'down' : 'up';
    }

    setSortData({ type: sortType, value: newValueOfSorting });
    await candidatesStore.setCandidatesList(activePage, activeTab, { type: sortType, value: newValueOfSorting }, props.jobId);
    setSpinnerOpen(false);
  };

  const handleMobileSorting = async (index: number) => {
    setSpinnerOpen(true);
    const sortItem = mobileSortList[index];
    setMobileSortSelectedItem(index);
    setSortData(sortItem.data);
    await candidatesStore.setCandidatesList(activePage, activeTab, sortItem.data, props.jobId);
    setSpinnerOpen(false);
  };

  const handleSetActiveTab = async (filterValue: string) => {
    setSpinnerOpen(true);
    setActiveTab(filterValue);
    await candidatesStore.setCandidatesList(0, filterValue, sortData, props.jobId);
    setActiveActionBtn(null);
    setSpinnerOpen(false);
  }

  const navigatePage = async (direction: 'next' | 'prev') => {
    setSpinnerOpen(true);
    const pageToNavigate = direction === 'next' ? activePage + 1 : activePage - 1;
    await candidatesStore.setCandidatesList(pageToNavigate, activeTab, sortData, props.jobId);
    setActivePage(pageToNavigate);
    setSpinnerOpen(false);
  }

  useEffect(() => {
    if (candidatesStore.newReviewers) {
      resetNewReviewers(props.jobId);
      candidatesStore.resetNewReviewersFromState();
    }
  }, [candidatesStore.newReviewers]);

  const tabsForSorting = [
    { label: 'All', value: 'all', length: candidatesStore.typesLength ? candidatesStore.typesLength.allCandidates : 0 },
    { label: 'Archive', value: 'archived', length: candidatesStore.typesLength ? candidatesStore.typesLength.archivedCandidates : 0 },
  ];

  const DesktopProps = { handleSortTable, sortData, isSpinnerOpen, handleOpenModal, setActiveActionBtn, handleOpenMenu, activeActionBtn, isDesktop: props.isDesktop };
  const MobileProps = { selectedIndex: mobileSortSelectedItem, handleMobileSorting, handleOpenMenu, activeActionBtn, isDesktop: props.isDesktop, handleOpenModal, isSpinnerOpen };

  const showEmptyListBanner = !isSpinnerOpen && candidatesStore.typesLength && candidatesStore.candidatesList.length === 0;

  return <StyleForWrap isDesktop={props.isDesktop} isLoading={isSpinnerOpen}>
    <TitleWrap isDesktop={props.isDesktop}>
      <Title>Candidates</Title>
      {props.isDesktop && <Pagination
        isDesktop={props.isDesktop}
        activePage={activePage}
        navigatePage={navigatePage}
        listLength={candidatesStore.listLength}
      />}
    </TitleWrap>
    <ListTabs activeTab={activeTab} callback={handleSetActiveTab} isDesktop={props.isDesktop} tabsData={tabsForSorting} />
    {props.isDesktop ? <DesktopCandidatesList {...DesktopProps} /> : <MobileCandidatesList {...MobileProps} />}
    {!props.isDesktop && <Pagination
      isDesktop={props.isDesktop}
      activePage={activePage}
      navigatePage={navigatePage}
      listLength={candidatesStore.listLength}
    />}

    {/*Helper Components*/}

    {/*Banner for empty jobList*/}
    {showEmptyListBanner && <EmptyListBanner activeTab={activeTab} />}
    {/*Action Modal*/}
    {showModal.isOpen && <TableActionsModal
      typeOfModal={showModal.typeOfModal}
      setShowModal={setShowModal}
      showModal={showModal.isOpen}
      activePage={activePage}
      activeTab={activeTab}
      jobId={showModal.jobId}
      oldStatus={showModal.oldStatus}
      sortData={sortData}
      modalForCandidates
      candidateId={showModal.candidateId}
      handleSetActiveTab={handleSetActiveTab}
    />}
  </StyleForWrap >
})