export const MenuList = [
	{ title: 'Main Information', key: 'mainInformation' },
	{ title: 'Contact Information', key: 'contactInformation' },
	{ title: 'Social Networks', key: 'socialNetworks' },
	{ title: 'Common Company Benefits', key: 'commonBenefits' },
	{ title: 'Images', key: 'images' },
	{ title: 'Videos', key: 'videos' },
	{ title: 'Audios', key: 'audios' }
]

export const socialNetworksList = [
	'LinkedIn',
	'Facebook',
	'Twitter',
	'Instagram',
	'YouTube',
	'Reddit',
	'Whatsapp',
	'Tiktok',
	'Dribbble',
	'Behance',
	'Pinterest',
	'Snapchat'
]

export const checkPublicUrlValue=(value: string) => {
  return /[\s!#_№$%^&*()+|~=`{}\[\]:";@'<>?,.\/]/.test(value);
}