import * as React from "react"
import { ShareModalProps } from "./ShareModal.props"
import { MediaModalWrapper, ShareSection, ShareSectionTitle, ShareHeader, Title, OrganizationLogo, Subtitle, CloseButton, CopylinkInput, CopylinkContainer, ShareSectionSubTitle } from "./ShareModal.presets"
import { observer } from "mobx-react-lite";

import { ReactComponent as LinkedinIcon } from './icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from './icons/facebook.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as TelegramIcon } from './icons/telegram.svg';
import { ReactComponent as WhatsAppIcon } from './icons/whatsapp.svg';

import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { useRootStore } from "../../../../state/stores/storeContext";
import { useState } from "react";
import { SPSecondaryButton } from "../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { EmailInputField } from "./EmailInputField";

export const ResumeShareModal: React.FC<ShareModalProps> = observer((props) => {
  const [isCopied, setIsCopied] = useState(false);

  const { jobStoryBuilder } = useRootStore();
  const title = jobStoryBuilder.answers.descriptionStep.jobTitle || props.organizationName || props.title
  const organizationLogo = jobStoryBuilder.answers.contactStep.directEmployerLogo.fileUrl || props.organizationLogo
  const subtitle = jobStoryBuilder.answers.contactStep.organizationName || props.organizationIndustry
  const shareUrl = props.url ? props.url : window.location.href

  const copyJobUrl = () => {
    navigator.clipboard.writeText(shareUrl).then(function () {
      setIsCopied(true)
    }, function (err) {
      console.error(err)
    });
  }

  const renderHeader = () => {
    return (
      <>
        {organizationLogo && <OrganizationLogo src={organizationLogo} alt="Employer logo" />}
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
      </>
    )
  }

  const renderAdminHeader = () => {
    return <Title>Share with candidates</Title>
  }


  return (
    <MediaModalWrapper show={props.isOpen} onHide={props.onClose} >
      <MediaModalWrapper.Body>
        <ShareHeader isAdminMode={props.isAdminMode}>
          {props.isAdminMode ? renderAdminHeader() : renderHeader()}
          <CloseButton onClick={props.onClose}>
            <CloseIcon />
          </CloseButton>
        </ShareHeader>
        <ShareSectionTitle>Send by Email:</ShareSectionTitle>
        <ShareSectionSubTitle>Add multiple emails separated by commas</ShareSectionSubTitle>
        <EmailInputField shareUrl={shareUrl}/>

        <ShareSectionTitle>Social Networks:</ShareSectionTitle>
        <ShareSection>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon />
            LinkedIn
          </LinkedinShareButton>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon />
            Facebook
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon />
            Twitter
          </TwitterShareButton>
        </ShareSection>

        <ShareSectionTitle>Message:</ShareSectionTitle>
        <ShareSection>
          <FacebookMessengerShareButton appId="" url={shareUrl}>
            <MessengerIcon />
            Messenger
          </FacebookMessengerShareButton>
          <TelegramShareButton url={shareUrl}>
            <TelegramIcon />
            Telegram
          </TelegramShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsAppIcon />
            WhatsApp
          </WhatsappShareButton>
        </ShareSection>

        <ShareSectionTitle>Share Link</ShareSectionTitle>
        <CopylinkContainer>
          <CopylinkInput type="text" value={shareUrl} disabled />
          <SPSecondaryButton onClick={copyJobUrl}>
            {isCopied ? "Copied" : "Copy"}
          </SPSecondaryButton>
        </CopylinkContainer>
      </MediaModalWrapper.Body>
    </MediaModalWrapper>

  )
})
