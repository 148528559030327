import { Field } from "formik";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SelectCom } from "../../../common/Select";
import { StyleForRemoveBtn, StyleForTitle } from "../../Steps/Steps.presents";
import { LocationFieldPropsType } from './HiringLocationFieldArray.props';
import { Text } from '../../../common/Text';
import { useEffect } from "react";
import { Checkbox } from '../../../common/Checkbox';
import { getPlaces } from "../../../../services/api/outsideResources/outsideResources.domains";
import { Tags } from "../Tags";

export const HiringLocationField = ({ index, remove, values, errors, setFieldTouched, handleChange, disableAllFields, setFieldValue }: LocationFieldPropsType) => {
  const { t } = useTranslation();
  const locationItemValue = values.hiringLocations[index];
  const country = locationItemValue ? locationItemValue.country : '';
  const state = locationItemValue ? locationItemValue.territory : '';
  const isAnyLocation = locationItemValue && locationItemValue.acceptRemoteWorkers.includes('anywhereFromCountry');

  const [disableSecondaryFields, setDisableSecondaryFields] = useState(isAnyLocation);
  const [regionOptions, setRegionOptions] = useState<[{ label: string, value: string }] | []>([]);
  const [cityOptions, setCityOptions] = useState<[{ label: string, value: string }] | []>([]);
  const [coutriesList, setCoutriesList] = useState();

  const isFieldsDisabledByBtn = disableSecondaryFields || disableAllFields;
  const isCityListEmpty = locationItemValue && cityOptions.length === 0;
  const isStateFieldDisabled = locationItemValue && regionOptions.length === 0 || isFieldsDisabledByBtn;
  const isCityFieldDisabled = locationItemValue && !locationItemValue.territory || isFieldsDisabledByBtn;

  //Set Country Option List
  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('country');
      setCoutriesList(placeResponse.data.data);
    })()
  }, [])

  //Set Region Option List (by Country value)
  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('state', { countryCode: country });
      const newRegionOptions = placeResponse.data.data || [];
      setRegionOptions(newRegionOptions)
    })()
  }, [country])

  //Set Region Option List (by Region values)
  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('city', { countryCode: country, stateCode: state });
      setCityOptions(placeResponse.data.data || []);
    })()
  }, [state])

  const resetValues = (fieldsWhichNeedReset: Array<string>) => {
    fieldsWhichNeedReset.forEach(item => {
      const defaultValue = item === 'city' ? [] : '';
      setFieldValue(`hiringLocations.${index}.${item}`, defaultValue)
    }
    )
  }

  const anyLocationHandler = (value: any) => {
    setDisableSecondaryFields(!disableSecondaryFields);
    handleChange(value);
  }

  const mainValidationCondition = (fieldName: string) => errors && errors[index] && errors[index][fieldName];

  return <div className={index > 0 ? 'secondLocation' : ''}>
    {index > 0 ? <div className='d-flex justify-content-between align-items-center'>
      <StyleForTitle>Secondary location</StyleForTitle>
      <StyleForRemoveBtn onClick={() => remove(index)}>Remove</StyleForRemoveBtn>
    </div> : ''}
    <div className="inputs-wrap">
      <Field
        labelText={t('label.country')}
        options={coutriesList}
        component={SelectCom}
        name={`hiringLocations.${index}.country`}
        id={`hiringLocations${index}country`}
        m={'12px 0 12px'}
        onChange={() => { resetValues(['territory', 'city']) }}
        disabled={disableAllFields}
        error={mainValidationCondition('country')}
      />
      <Text text={mainValidationCondition('country') ? errors[index].country : ''} error />
      <Container className='px-0'>
        <Row className="justify-content-center">
          <Col md={6} className='pl-0'>
            <Field
              labelText={t('label.territory')}
              options={regionOptions}
              component={SelectCom}
              name={`hiringLocations.${index}.territory`}
              id={`hiringLocations${index}territory`}
              onChange={() => { resetValues(['city']) }}
              m={'8px 0 8px'}
              disabled={isStateFieldDisabled}
              error={mainValidationCondition('territory')}
            />
            <Text text={mainValidationCondition('territory') ? errors[index].territory : ''} error />
          </Col>
          <Col md={6} className='pr-0'>
            <Field
              disabled={isCityFieldDisabled}
              tags={locationItemValue ? locationItemValue.city : []}
              setTegs={(tags: Array<string>) => { setFieldValue(`hiringLocations.${index}.city`, tags) }}
              component={isCityListEmpty ? Tags : SelectCom}
              m={'8px 0 8px'}
              name={`hiringLocations.${index}.city`}
              error={mainValidationCondition('city')}
              labelText={<div><span>{t('label.city')}</span><span className='optional'> | Multiple locations</span></div>}
              placeholder='Enter cities'
              id={`hiringLocations${index}city`}
              isMulti={true}
              options={cityOptions}
            />
            <Text text={mainValidationCondition('city') ? errors[index].city : ''} error />
          </Col>
          <Checkbox
            m={'0 auto 12px 0'}
            value={'anywhereFromCountry'}
            name={`hiringLocations.${index}.acceptRemoteWorkers`}
            labelText={'Accept remote workers from anywhere in this country/region'}
            handleChange={anyLocationHandler}
            checked={isAnyLocation}
            disabled={disableAllFields}
          />
        </Row>
      </Container>
    </div>
  </div>
}