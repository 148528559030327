import { colors } from './../../../../constants/colors';
import styled from 'styled-components';
import { theme } from '../../../../theme';

export const StyleForWrap = styled('div')`
  width:100%;
  max-width: 1112px;
  margin: 0 auto;
  box-shadow: -5px 5px 20px rgba(63, 71, 79, 0.05), 5px -5px 20px rgba(63, 71, 79, 0.05);
  border-radius: 20px;
  margin: 48px auto 48px;
  padding: 32px 32px; 
  .title{
    font-family: Recoleta;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: ${colors.dark};
    margin-bottom: 24px;
  }
  .video-wrap{
    border-radius: 20px;
    overflow: hidden;
  }
  vm-player {
     vm-playback-control{
      margin: 0 auto; 
      display: flex;
      background: #fff;
      width: 56px;
      height: 56px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      vm-icon{
        width: 56px;
        height: 56px;
      }
    }
    --vm-control-scale: 1.3;
    --vm-icon-background: #fff;
    --vm-control-color: ${theme.colors.black};
    --vm-control-focus-bg: none;
    --vm-control-focus-color: ${theme.colors.black};
  }
`

export const StyleForAudioPlayer = styled('div')`
  width: 100%;
  display: flex;
  #waveform{
    flex:1;
    wave{
      border-right: none!important;
    }
  }
  .play-btn{
    background: #0083FF;
    height: 72px;
    width: 72px;
    border: none;
    border-radius: 100%;
    margin-right: 24px;
  }
`