import styled from 'styled-components';
import { theme } from '../../../../theme';

const PHOTO_SIZE_LARGE = '137px';
const PHOTO_SIZE_MEDIUM = '125px';
const PHOTO_SIZE_SMALL = '88px';

const SPINNER_SIZE = "24px";
const REMOVE_ICON_SIZE = "20px";

export const FileContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding-bottom: ${theme.offsets.xxl};
    margin-bottom: ${theme.offsets.xxl};
  }
`

export const Spinner = styled('img')`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  user-select: none;
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
`

export const PosterBlock = styled('div') <{ url?: string, isDesktop: boolean }>`
width: ${PHOTO_SIZE_LARGE};
height: ${PHOTO_SIZE_LARGE};
border-radius: ${theme.variables.borderRadiusLarge};
position: relative;
display: flex;
align-items: center;
justify-content: center;
background-color: #F6F8FA;
background-image: url('${props => props.url ? props.url : "none"}');
background-size: cover;
background-position: 50% 50%;
overflow: hidden;
video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
}
.ytp-button{
  display: none;
}
${(props) => !props.isDesktop && `
  width: ${PHOTO_SIZE_MEDIUM};
  height: ${PHOTO_SIZE_MEDIUM};
  @media(max-width: ${theme.screens.mobile}) {
    width: ${PHOTO_SIZE_SMALL};
    height: ${PHOTO_SIZE_SMALL};
  }
` }
`

export const FieldWrap = styled('div')`
  flex: 1;
  margin-left: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    margin-left: ${theme.offsets.lg};
  }

  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
    margin-left: 0;
    width: 100%;
  }
`

export const RemoveIcon = styled('button')`
  width: ${REMOVE_ICON_SIZE};
  height: ${REMOVE_ICON_SIZE};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${theme.offsets.sm};
  right: ${theme.offsets.sm};
  border-radius: 50%;
  border: 1px solid ${theme.colors.black};
  background: ${theme.colors.white};
  padding: 0;
  z-index: 3;
  img {
    width: 8px;
    height: 8px;
  }
`

export const ButtonWrap = styled('div')`
  margin-top: ${theme.offsets.lg};
`

export const VideoContainer = styled('div')`
overflow: hidden;
width: 100%;
  flex: 1;
  height: 100%;
`