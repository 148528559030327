import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { SPWhiteButton } from "../../common/buttons/SPWhiteButton/SPWhiteButton";
import { Input } from "../../common/Input";
import { ModalWindow } from "../../common/Modal";
import { ModalBtnWrap, ModalContent, ModalDescription } from "./Profile.presents";

export const ConfirmPasswordModal = ({
  setIsPasswordWindowOpen,
  isPasswordWindowOpen,
  onConfirmPassword,
  values,
  confirmPasswordError,
  isDesktop
}: {
  setIsPasswordWindowOpen: Function;
  isPasswordWindowOpen: boolean;
  onConfirmPassword: (password: string, values: any) => void;
  values: any;
  confirmPasswordError: null | string;
  isDesktop: boolean
}) => {
  const [passwordValue, setPasswordValue] = useState('');
  const history = useHistory();

  return <ModalWindow
    width={isDesktop? '700px' : '470px' }
    title="Confirm password to continue"
    closeWindow={() => setIsPasswordWindowOpen(false)}
    openWindow={isPasswordWindowOpen}>
    <ModalContent>
      <div>
        <ModalDescription>
          To update your email address you’ll need to confirm your password. <span onClick={()=>history.push('/candidate/forgot-password')}>Forgort password?</span>
        </ModalDescription>
        <Input
          name="confirmPassword"
          type={'password'}
          value={passwordValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setPasswordValue(event.target.value)}
          maxLength={50}
          labelText={'Enter your password'}
          error={!!confirmPasswordError}
        />
      </div>
      <ModalBtnWrap>
        <SPWhiteButton width="100%" type="button">Close</SPWhiteButton>
        <SPSecondaryButton width="100%" type="button" onClick={() => onConfirmPassword(passwordValue, values)}>Confirm</SPSecondaryButton>
      </ModalBtnWrap>
    </ModalContent>
  </ModalWindow>
}