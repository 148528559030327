import { useState } from "react";
import { Wrapper, Description } from "./VerificationBanner.presents";
import { VerificationModal } from "./VerificationModal";

export const VerificationBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return <Wrapper>
    <Description>
      <button onClick={()=>setIsModalOpen(true)}>
        Verify
      </button>
      your email address to enable advanced features!
    </Description>
    {isModalOpen && <VerificationModal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}/>}
  </Wrapper>
}