import { Checkbox } from "../../../common/Checkbox";
import { StyleForRemoveBtn } from "../../Steps/Steps.presents";
import languagesOptions from '../../../../assets/staticData/languages.json';
import languageLevelOptions from '../../../../assets/staticData/languageLevelOptions.json';
import { SelectCom } from "../../../common/Select";
import { FieldProps } from "./LanguagesFieldArray.props";
import { RemoveBtnWrap, ContentWrap, FieldsWrap, FieldContainer, Wrapper } from "./LanguagesField.presents";
import { useEffect, useState } from "react";

export const LanguagesField = (props: FieldProps) => {
	const { index, remove, errors, handleChange, values } = props;
	const [optionsList, setOptionsList] = useState<Array<{ label: string; value: string; disable?: boolean }>>(languagesOptions);
	const [oldItemIndex, setOldItemIndex] = useState(0);

	const mainValidationCondition = (fieldName: string, index: number) => errors && errors[index] && errors[index][fieldName];

	const isSecondItem = index > 0;
	const selectedLanguage = props.values.languages[index].language;

	const disableSelectedLanguage = () => {
		const optionsListCopy = [...optionsList];
		const existItemIndex = optionsListCopy.findIndex(element => element.value === selectedLanguage);
		if (existItemIndex >= 0) {
			//open old lenguage(which selected before)
			optionsListCopy[oldItemIndex].disable = false;
			//close new lenguage
			optionsListCopy[existItemIndex].disable = true;
			setOldItemIndex(existItemIndex);
		}
		setOptionsList(optionsListCopy)
	}

	useEffect(() => disableSelectedLanguage(), [selectedLanguage])

	return <Wrapper isSecondItem={isSecondItem}>
		{isSecondItem && <RemoveBtnWrap>
			<StyleForRemoveBtn onClick={() => remove(index)}>Remove</StyleForRemoveBtn>
		</RemoveBtnWrap>}
		<ContentWrap isSecondItem={isSecondItem}>
			<FieldsWrap>
				<FieldContainer
					labelText={'Language'}
					options={languagesOptions}
					component={SelectCom}
					name={`languages.${index}.language`}
					error={mainValidationCondition('language', index)}
				/>
				<FieldContainer
					labelText={'Level of language'}
					options={languageLevelOptions}
					component={SelectCom}
					name={`languages.${index}.level`}
					error={mainValidationCondition('level', index)}
				/>
			</FieldsWrap>
			<Checkbox
				className='d-inline-block'
				value={'yes'}
				name={`languages.${index}.isItmainLanguage`}
				labelText={'Main language'}
				handleChange={handleChange}
				checked={values.languages[index] && values.languages[index].isItmainLanguage.includes('yes')}
			/>
		</ContentWrap>
	</Wrapper>
}