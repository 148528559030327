import styled from 'styled-components'
import { theme } from '../../../../../../theme'

export const StyleForWrap = styled('div')`
label .additional{
  color: rgba(45, 53, 61, 0.6);
}
`

export const ButtonWrap = styled('div')`
  margin: ${theme.offsets.lg} 0 ${theme.offsets.md};
`