import { SidebarWrap, SidebarHeader, CloseBtn } from "./Sidebar.presents";
import { PropsType } from './Sidebar.props';
import closeIcon from '../../../assets/img/close.svg';

export const Sidebar = (props: PropsType) => {
  return <SidebarWrap isOpen={props.isOpen}>
    <SidebarHeader>
      <CloseBtn>
        <img className='close-btn' src={closeIcon} alt="close" onClick={() => props.setCloseSidebar()} />
      </CloseBtn>
    </SidebarHeader>
    {props.children}
  </SidebarWrap>
}