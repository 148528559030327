import { ReactComponent as GoogleIcon } from '../../../../../assets/img/googleIcon.svg';
import { ReactComponent as FacebookIcon } from '../../../../../assets/img/facebookIcon.svg';

export const SocialNetworksAuthMap = [
  {
    title: 'Google',
    icon: <GoogleIcon />,
    callback: () => { }
  },

  {
    title: 'Facebook',
    icon: <FacebookIcon />,
    callback: () => { }
  },
]