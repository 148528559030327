import { PageContainer, PageContent, Wrapper } from "./CandidateSignIn.presents"
import useGetDevice from "../../../../hooks/useGetDevice"
import { Banner } from "../../../../components/Auth/Candidate/BasicComponents/Banner/Banner"
import { CandidateSignIn } from "../../../../components/Auth/Candidate/SignIn/CandidateSignIn"
import { Header } from "../../../../components/Auth/BaseComponents/Header"
import { Footer } from "../../../../components/Auth/BaseComponents/Footer/Footer"
import { useRootStore } from "../../../../state/stores/storeContext"

export const CandidateSignInPage = () => {
  const isDesktop = useGetDevice();

  const { candidateStore } = useRootStore();
  return <Wrapper isDesktop={isDesktop}>

    <Banner isDesktop={isDesktop} />

    <PageContainer>
      {isDesktop && <Header isCandidateAuth={candidateStore.isAuthenticated} showLogo={false} showRegister />}
      <PageContent isDesktop={isDesktop}>
        <CandidateSignIn />
        {!isDesktop && <Footer />}
      </PageContent>
    </PageContainer>
  </Wrapper>
}
