import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import {
  Container,
  AvatarContainer,
  FullName,
  Position,
  AdditionalInfo,
  AdditionalInfoContainer,
  SocialNetworksWrap,
  SocialNetworkItem,
  ButtonWrap,
  AvatarWrap,
  VideoPlayerWrap,
  PlayerWrap,
} from "./ContactInformation.presents";
import avatarIcon from '../../../../../assets/img/avatar.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/img/resume/email.svg';
import { ReactComponent as PhoneIcon } from '../../../../../assets/img/resume/phone.svg';
import { ReactComponent as LocationIcon } from '../../../../../assets/img/resume/location.svg';
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { socialNetworksIconMap } from "./helper";
import { SPPrimaryButton } from "../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { linkHandler } from "../../../../../services/helper";
import { VideoPlayer } from "../../../BaseComponents/VideoPlayer/VideoPlayer";
import { TextButton } from "../../../../common/buttons/TextButton/TextButton";
import { ContactMeModal } from "../../../BaseComponents/ContactMeModal/ContactMeModal";
import { SignInToViewModal } from "../../../BaseComponents/SignInToViewModal/SignInToViewModal";

export const ContactInformation = observer(({
  isDesktop,
  candidate,
  resume,
  isPublic,
  isAuthenticated
}: {
  isDesktop: boolean;
  candidate: any;
  resume: any;
  isPublic?: boolean;
  isAuthenticated: boolean
}) => {
  const [fullName, setFullName] = useState("");
  const [phoneExist, setPhoneExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showContactMeModal, setShowContactMeModal] = useState(false);
  const logoUrl = resume.logo ? resume.logo.url : avatarIcon;
  const [candidateInfo, setCandidateInfo] = useState({ fullName: "", position: "", email: "", locationData: "", phone: "" })

  const emailTo = resume.personalInfo?.email || resume.personalInfo?.profileEmail;
  const isContactMeButtonDisabled = !emailTo || !isPublic;

  const renderSocialNetworks = useMemo(() => {
    return resume.socialNetworks && resume.socialNetworks.map((item: any, index: number) => {
      return item.url ? <SocialNetworkItem href={linkHandler(item.url)} key={`${index}Networks`}>
        {socialNetworksIconMap[item.title as keyof typeof socialNetworksIconMap]}
      </SocialNetworkItem> : null;
    })
  }, [])

  useEffect(() => {
    if (resume.personalInfo && resume.personalInfo.firstName) {
      setFullName(`${resume.personalInfo.firstName} ${resume.personalInfo?.lastName}`)
    }
    if (resume.personalInfo?.jobTitle) {
      setCandidateInfo(prev => {
        return { ...prev, position: resume.personalInfo.jobTitle }
      })
    }
    if (resume.personalInfo?.email || resume.personalInfo?.profileEmail) {
      setCandidateInfo(prev => {
        return { ...prev, email: resume.personalInfo.email || resume.personalInfo.profileEmail }
      })
      setEmailExist(true)
    }
    if (resume.personalInfo?.phone) {
      setCandidateInfo(prev => {
        return { ...prev, phone: resume.personalInfo.phone }
      })
      setPhoneExist(true)
    }
    if (resume.locationData) {
      setCandidateInfo(prev => {
        return {
          ...prev,
          locationData: `${resume.location?.city}, ${resume.location?.state}, ${resume.location?.country}`
        }
      })
      setShowLocation(true)
    }
  }, []);

  return <Container isDesktop={isDesktop}>
    <CandidateFormCard
      isDesktop={isDesktop}
      fullScreen
    >

      {(!isDesktop && resume.videoResume && resume.videoResume.url) && <VideoPlayerWrap>
        <PlayerWrap isResumeBuilder>
          <VideoPlayer dataSrc={resume.videoResume.url} />
        </PlayerWrap>
      </VideoPlayerWrap>}

      <AvatarWrap isDesktop={isDesktop}>
        <AvatarContainer showBorder={!resume.logo} isDesktop={isDesktop}>
          <img src={logoUrl} alt="avatar" />
        </AvatarContainer>
        {!isDesktop && <SPPrimaryButton width="100%" type="button" onClick={() => isPublic && setShowContactMeModal(true)}>
          Contact Me
        </SPPrimaryButton>}
      </AvatarWrap>

      {fullName && <FullName isDesktop={isDesktop}>
        {fullName}
      </FullName>}

      {candidateInfo.position && <Position isDesktop={isDesktop}>
        {candidateInfo.position}
      </Position>}

      {(emailExist || phoneExist || showLocation) && <AdditionalInfoContainer>

        {emailExist && <AdditionalInfo>
          <EmailIcon />
          {!showEmail ?
            <TextButton text={"Show email"} onClick={() => setShowEmail(!showEmail)} />
            :
            candidateInfo.email
          }
        </AdditionalInfo>}

        {phoneExist && <AdditionalInfo>
          <PhoneIcon />
          {!showPhone ?
            <TextButton text={"Show phone"} onClick={() => setShowPhone(!showPhone)} />
            :
            candidateInfo.phone
          }
        </AdditionalInfo>}

        {candidateInfo.locationData && <AdditionalInfo>
          <LocationIcon />
          {candidateInfo.locationData}
        </AdditionalInfo>}

      </AdditionalInfoContainer>}

      {resume.socialNetworks && resume.socialNetworks.length > 0 && <SocialNetworksWrap>
        {renderSocialNetworks}
      </SocialNetworksWrap>}

      <ButtonWrap isDesktop={isDesktop}>
        <SPPrimaryButton
          width="100%"
          type="button"
          disabled={isContactMeButtonDisabled}
          onClick={() => isPublic && setShowContactMeModal(true)}>
          Contact Me
        </SPPrimaryButton>
      </ButtonWrap>

    </CandidateFormCard>
    {showContactMeModal &&
      <ContactMeModal
        isOpen={showContactMeModal}
        onClose={() => setShowContactMeModal(false)}
        emailTo={emailTo}
      />
    }
    {isPublic && !isAuthenticated && !resume.isResumeVisible && <SignInToViewModal
      isOpen={!isAuthenticated && isPublic}
      onClose={() => { }}
      isDesktop={isDesktop}
      candidate={candidate}
      firstName={resume?.personalInfo?.firstName}
      logoUrl={logoUrl}
    />
    }
  </Container>
});
