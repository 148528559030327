import { FieldArray } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PropsType } from './Locations.props'
import { LocationField } from './LocationField'
import { SPSecondaryButton } from "../../buttons/SPSecondaryButton/SPSecondaryButton"
import { ReactComponent as PlusIcon } from '../../../../assets/img/plusBlack.svg';
import { ButtonWrap } from "./Locations.presents"

export const Locations = (props: PropsType) => {
  const { t } = useTranslation();
  const [locationsArray, setLocationsArray] = useState(props.values.locations)

  useEffect(() => {
    setLocationsArray(props.values.locations)
  }, [props.values.locations, locationsArray])

  return <div id='orgLocation'>
    <FieldArray
      name="locations">
      {({ remove, push }) => (
        <div>
          {locationsArray && locationsArray.length > 0 && locationsArray.map((item: any, index: number) => <LocationField
            key={index}
            index={index}
            remove={remove}
            values={props.values}
            errors={props.errors.locations}
            handleChange={props.handleChange}
            locationsArray={locationsArray}
            setFieldValue={props.setFieldValue}
          />)
          }
          <ButtonWrap>
            <SPSecondaryButton isLight width="auto" type={'button'} onClick={() => push({
              country: '',
              address: '',
              suite: '',
              city: '',
              territory: '',
              zipCode: '',
              timeZone: ''
            })}>
              {t('adminPanel.addLocationBtn')}
              <PlusIcon />
            </SPSecondaryButton>
          </ButtonWrap>
        </div>
      )}
    </FieldArray>
  </div>
}