import { Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "./PersonalInfo.presents";
import { SelectCom } from "../../../../common/Select";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { initialAboutCandidate } from "../../../../../state/stores/resumeBuilder";
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import questionsList from '../../../../../assets/staticData/aboutCandidate.json';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../common/Checkbox";

export const AboutCandidateBlock = ({
  isDesktop,
  setAboutCandidateOpen
}: {
  isDesktop: boolean;
  setAboutCandidateOpen: (value: boolean) => void;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { resumeBuilderStore, candidateStore } = useRootStore();
  const { t } = useTranslation();

  const initialValues = {
    aboutCandidate: !!resumeBuilderStore.aboutCandidate.length ? resumeBuilderStore.aboutCandidate : [initialAboutCandidate],
    aboutCandidateMainAnswer: resumeBuilderStore.aboutCandidateMainAnswer
  }

  return <CandidateFormCard
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    isDesktop={isDesktop}
    title={'More about you'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            aboutCandidate: Yup.array()
              .of(
                Yup.object().shape({
                  question: Yup.string().required(t('validation.required')),
                  answer: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveAboutCandidate(values, candidateStore.uid);
          
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="aboutCandidate">
              {({ remove, push }) => {
                const onAddItem = () => {
                  setShowSaveBtn(true);
                  push({ question: '', answer: '' });
                };
                return <div>
                  {values.aboutCandidate.map((item: any, index: number) => {
                    const arrayErrors: any = errors.aboutCandidate
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    const onChangeHandler = (value: any) => {
                      setShowSaveBtn(true);
                      handleChange(value);
                    };

                    const onRemoveItem = () => {
                      setShowSaveBtn(true);
                      remove(index);
                    };

                    return <div key={`aboutCandidate${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional question</ArrayItemTitle>
                        <RemoveBtn onClick={onRemoveItem}>Delete</RemoveBtn>
                      </FieldHeader>}
                      <FieldWrap>
                        <Field
                          labelText={'Question'}
                          options={questionsList}
                          component={SelectCom}
                          name={`aboutCandidate.${index}.question`}
                          onChange={() => setShowSaveBtn(true)}
                          error={mainValidationCondition('question')}
                        />
                        <ErrorMsg>{mainValidationCondition('question') ? arrayErrors[index].question : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`aboutCandidate.${index}.answer`}
                          type={'textarea'}
                          value={values.aboutCandidate[index].answer}
                          onChange={onChangeHandler}
                          maxLength={500}
                          labelText={<LabelWrap>Your interests<span>Up to 500 characters</span></LabelWrap>}
                          error={mainValidationCondition('answer')} />
                        <ErrorMsg>{mainValidationCondition('answer') ? arrayErrors[index].answer : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Checkbox}
                          checked={!!values.aboutCandidateMainAnswer && `${index}` === values.aboutCandidateMainAnswer}
                          name={'aboutCandidateMainAnswer'}
                          labelText={`Main Question`}
                          value={index}
                          handleChange={onChangeHandler}
                          type={'radio'}
                        />
                        <ErrorMsg>{errors.aboutCandidateMainAnswer}</ErrorMsg>
                      </FieldWrap>
                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={onAddItem}>
                      Add More About You
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveAboutCandidate({ aboutCandidate: [] }, candidateStore.uid);
                await resumeBuilderStore.setEmploymentHistory([]);
                handleReset();
                setAboutCandidateOpen(false);
              }}
            />}
          </form>
        }
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
}