import styled from 'styled-components';
import { theme } from '../../../../theme';

export const GalleryContainer = styled('div')`
  max-width: 1120px;
  margin: 0 auto;
  padding: ${theme.offsets.xxl};
  background: white;
  box-shadow: 5px -5px 20px 0px #3F474F0D;
  margin-bottom: ${theme.offsets.xxl};
  border-radius: ${theme.variables.borderRadiusMedium};
`

export const GalleryRow = styled.div<{offsetTop?: boolean}>`
  display: flex;
  margin-top: ${props => props.offsetTop ? "16px" : 0};
`

export const GalleryColumn = styled.div<{offsetLeft?: boolean}>`
  flex: 1;
  position: relative;
  margin-left: ${props => props.offsetLeft ? "16px" : 0};
`


export const GalleryItem = styled.div<{large?: boolean, imageUrl?: string, mediaTitle?: string}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: ${props => props.imageUrl ? `url(${ props.imageUrl })` : "#DBE9F8"};
  background-position: center;
  background-size: cover;
  min-height: ${props => props.large ? "520px" : "252px"};
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  &:before {
    position: absolute;
    top: 16px;
    left: 16px;
    content: ${props =>  props.mediaTitle ? `"${props.mediaTitle}"` : ""};
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #39434D;
  }
  @media(max-width: ${theme.screens.desktop}) {
    min-height: ${props => props.large ? "320px" : "152px"};
  }
`

export const GalleryItemMobile = styled.div<{imageUrl?: string}>`
  border-radius: ${theme.variables.borderRadiusSmall};
  background: ${props => props.imageUrl ? `url(${ props.imageUrl })` : "#DBE9F8;"};
  background-position: center;
  background-size: cover;
  min-height: 160px;
  cursor: pointer;

  & + * {
    margin-top: ${theme.offsets.lg};
  }
`

export const GalleryItemOverlay = styled('div')`
  pointer-events: none;
  border-radius: ${theme.variables.borderRadiusXLarge};
  background: #39434DCC;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
`

export const GalleryItemOverlayText = styled('span')`
  user-select: none;
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`
