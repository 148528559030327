import { useEffect, useState } from "react"
import { HeaderOfJobStoryBuilder } from "../../components/JobStoryBuilder/BasicComponents/Header"
import { StartStep } from "../../components/JobStoryBuilder/Steps/Start"
import { JobStep } from '../../components/JobStoryBuilder/Steps/Job'
import { useHistory, useLocation } from "react-router"
import { useRootStore } from "../../state/stores/storeContext"
import { observer } from "mobx-react-lite"
import { DescriptionStep } from "../../components/JobStoryBuilder/Steps/Description"
import { SpecificationStep } from "../../components/JobStoryBuilder/Steps/Specification"
import { ContactStep } from "../../components/JobStoryBuilder/Steps/Contact"
import { IsJobExistType, SendAnswersType, JobBuilderStep } from "./JobStoryBuilder.props"
import { FinishStep } from "../../components/JobStoryBuilder/Steps/Finish/FinishStep"
import { StatusStep } from "../../components/JobStoryBuilder/Steps/StatusStep"
import { StepsPreloader, PageContainer } from "./JobStoryBuilder.presents"
import { Preloader } from "../../components/common/Preloader"
import isMobile from "is-mobile"
import { Intro } from "../../components/JobStoryBuilder/Steps/Intro"
import { ErrorBoundary } from "../../components/common/ErrorBoundary/ErrorBoundary"


export const checkIsJobExist = async ({ jobStoryBuilder, jobId, history, setActiveStep, setDoneStep, steps }: IsJobExistType) => {
  const resp = await jobStoryBuilder.getJobData(jobId)
  if (resp.err) {
    history.replace(`/job-story/start`)
    setActiveStep(0)
    setDoneStep(-1)
    window.scrollTo(0, 0)
  } else {
    setActiveStep(steps.active)
    setDoneStep(steps.done)
  }
}

const toPrevStep = ({ jobId, history, setActiveStep, setDoneStep, steps, }: IsJobExistType) => {
  const allSteps: any = {
    0: 'start',
    1: 'job',
    2: 'description',
    3: 'specification',
    4: 'contact'
  }
  history.push(`/job-story/${allSteps[steps.active]}?id=${jobId}`)
  setActiveStep(steps.active)
  setDoneStep(steps.done)
  window.scrollTo(0, 0)
}

const dropdownQuestionsScheme = {
  compensation: {
    hourlyWage: false,
    commission: false,
    annualSalary: false
  },
  equityIncluded: {
    yesEquity: false
  },
  contractLength: {
    customContractLength: false
  },
  fixedBudget: {
    fixedBudgetYes: false
  },
  internshipType: {
    paidInternship: false
  },
}

export const JobStoryBuilderPage = observer(() => {
  const location = useLocation();
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const jobId = query.get("id")
  const { jobStoryBuilder } = useRootStore()


  const [activeStep, setActiveStep] = useState(0)
  const [doneStep, setDoneStep] = useState(-1)
  const [activeComponent, setActiveComponent] = useState(<StepsPreloader><Preloader /></StepsPreloader>)
  const [dropdownQuestions, openDropdownQuestions] = useState(dropdownQuestionsScheme)
  const [isDesktop, setDesktopOnly] = useState(false);

  useEffect(() => {
    setDesktopOnly(!isMobile({ tablet: true, featureDetect: true }));
  })

  useEffect(() => {
    jobStoryBuilder.resetAnswerData()
  }, [])

  useEffect(() => {
    (async () => {
      await jobStoryBuilder.getJobData(jobId)
      const answersCopy = await jobStoryBuilder.openDropdownQuestions(dropdownQuestionsScheme)
      await openDropdownQuestions(answersCopy)
    })()
  }, [jobStoryBuilder.answers, jobId, jobStoryBuilder, activeStep])

  useEffect(() => {
    if (Object.keys(jobStoryBuilder.steps).length > 0) {
      const stepsComponents: any = {
        [JobBuilderStep.start]: jobStoryBuilder.steps ? <StartStep
          jobId={jobId}
          createDependentsForStep={createDependentsForStep}
          saveAnswers={saveAnswers}
        /> : null,
        [JobBuilderStep.job]: <JobStep
          setActiveStep={setActiveStep}
          setDoneStep={setDoneStep}
          jobId={jobId}
          createDependentsForStep={createDependentsForStep}
          toPrevStep={toPrevStep}
          saveAnswers={saveAnswers}
        />,
        [JobBuilderStep.description]: <DescriptionStep
          setActiveStep={setActiveStep}
          setDoneStep={setDoneStep}
          jobId={jobId}
          createDependentsForStep={createDependentsForStep}
          toPrevStep={toPrevStep}
          saveAnswers={saveAnswers}
        />,
        [JobBuilderStep.specification]: <SpecificationStep
          setActiveStep={setActiveStep}
          setDoneStep={setDoneStep}
          jobId={jobId}
          toPrevStep={toPrevStep}
          dropdownQuestions={dropdownQuestions}
          openDropdownQuestions={openDropdownQuestions}
          saveAnswers={saveAnswers}
        />,
        [JobBuilderStep.contact]: <ContactStep
          setActiveStep={setActiveStep}
          setDoneStep={setDoneStep}
          jobId={jobId}
          createDependentsForStep={createDependentsForStep}
          saveAnswers={saveAnswers}
        />,
        [JobBuilderStep.finish]: <FinishStep
          setActiveStep={setActiveStep}
          setDoneStep={setDoneStep}
          toPrevStep={toPrevStep}
        />,
        [JobBuilderStep.status]: <StatusStep jobId={jobId} />,
        [JobBuilderStep.intro]: <Intro />
      }
      const pathName: any = location.pathname.split('/')[2];
      const activeStep = JobBuilderStep[pathName];
      setActiveComponent(stepsComponents[activeStep]);
    }
  }, [location.pathname, jobId, dropdownQuestions, openDropdownQuestions])

  const createDependentsForStep = (values: any, step: Array<any>) => {
    const dependents = new Set()
    for (const key in values) {
      const question = step.find(item => item.haveDependent && item.name === key)
      question?.answers.forEach((item: any) => {
        item.value === values[key] && item.dependent.forEach((element: any) => {
          dependents.add(element)
        });
      })
    }
    return dependents
  }

  const saveAnswers = async ({ values, stepInfo, arrayWithDependents, updatingUrl }: SendAnswersType) => {
    const doneStepName = `step${stepInfo.title[0].toUpperCase() + stepInfo.title.slice(1)}`;
    const serverResponse = await jobStoryBuilder.createNewJob({
      jobId: jobId,
      step: stepInfo.title,
      answers: jobStoryBuilder.transformValuesFromForm(values, jobStoryBuilder.steps[doneStepName]),
      dependents: arrayWithDependents
    })
    if (!serverResponse.err) {
      const nextStepUrl = `${updatingUrl}?id=${jobId || serverResponse.data.jobId}`;
      history.replace(updatingUrl ? nextStepUrl : '/my-jobs')
      setActiveStep(++stepInfo.done)
      setDoneStep(stepInfo.done)
      window.scrollTo(0, 0)
    }
  }

  return <ErrorBoundary redirectPath="/my-jobs" pageTitle="Job Story Builder">
    <PageContainer>
      <HeaderOfJobStoryBuilder
        activeStep={activeStep}
        doneStep={doneStep}
        jobId={jobId}
        setActiveStep={setActiveStep}
        setDoneStep={setDoneStep}
        isDesktop={isDesktop}
      />
      {activeComponent}
    </PageContainer>
  </ErrorBoundary>
})