import styled from "styled-components";
import { theme } from "../../../../theme";

export const ActionMenuWrap = styled('div') <{ isDesktop: boolean, isPositionUnder: boolean }>`
  position: absolute;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusMedium};
  width: 258px;
  padding: ${theme.offsets.lg};
  flex-direction: column;
  display: flex;
  z-index: 99;
  align-items: start;
  right: 0;
  ${(props) => !props.isDesktop && `
    position: fixed;
    width: 90%;
    max-width: 380px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
  `};
  ${(props) => {
    if (props.isDesktop && props.isPositionUnder) return `top: 40px;`;
    if (props.isDesktop && !props.isPositionUnder) return `bottom: 40px;`;
  }
  };
`

export const BlurBackground = styled('div') <{ isOpen: boolean, isDesktop: boolean }>`
  display: ${(props) => props.isOpen && !props.isDesktop ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
`

export const MenuItem = styled('span') <{ isDeleteBtn?: boolean, isDesktop: boolean, isSingleElement: boolean }>`
  width: 100%;
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: ${theme.offsets.lg};
  color: ${(props) => props.isDeleteBtn ? `${theme.colors.red}` : `${theme.colors.black}`};
  margin-bottom: ${(props) => props.isDeleteBtn ? '0' : `${theme.offsets.lg}`};
  padding-top: ${(props) => props.isDeleteBtn && props.isDesktop && !props.isSingleElement ? `${theme.offsets.lg}` : '0'};
  border-top: ${(props) => props.isDeleteBtn && props.isDesktop && !props.isSingleElement ? `1px solid ${theme.colors.lightGray}` : 'none'};
  &:hover {
    color: ${theme.colors.lightOrange};
  }
  ${(props) => !props.isDesktop && `
    text-align: center;
  `};
  ${(props) => !props.isDesktop && !props.isDeleteBtn && `
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding-bottom: ${theme.offsets.lg};
  `};
`