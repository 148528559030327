import styled from 'styled-components';
import { theme } from '../../../../../theme';
import actionIcon from '../../../../../assets/img/tableAction.svg';

export const MobileScreen = styled('div')<{isLoading: boolean}>`
  padding:${theme.offsets.md};
  color: ${theme.colors.black};
  ${(props)=>!props.isLoading && 'position: relative;'}
`

export const ItemWrap = styled('div')`
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  margin-bottom: ${theme.offsets.lg};
  display: flex;
  flex-direction: column;
  padding: ${theme.offsets.lg};
  font-size: 14px;
  color: ${theme.colors.black};
  position: relative;
  svg{
    margin-right: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    font-size: ${theme.fontSizes.md};
    padding: ${theme.offsets.md};
  } 
`

export const ActionBtnWrap = styled('div')`
  position: absolute;
  top: ${theme.offsets.xl};
  right: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.mobile}) { 
    top: ${theme.offsets.xs};
    right: ${theme.offsets.xs};
  } 
`

export const ActionBtn = styled('button')`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  &:hover {
    background-color: rgba(236, 146, 60, 0.1);
  }
  &:hover div:first-child {
    background-color: ${theme.colors.lightOrange};
  }
`

export const BtnIcon = styled('div')`
  mask-image: url(${actionIcon});
  height: 18px;
  width: 4px;
  background-color: ${theme.colors.darkGray};
`

export const SortBtn = styled('div')`
  margin-bottom: ${theme.offsets.xl};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    margin-bottom: ${theme.offsets.md};
} 
`

export const SortedBy = styled('span')`
  font-weight: 400;
  margin-left: ${theme.offsets.xs};
`

export const MainInfo = styled('div')`
  display:flex;
  align-items: center;
  @media(max-width: ${theme.screens.mobile}) { 
    flex-direction:column;
    align-items: flex-start;
  } 
  
  `
export const CandidateName = styled('div')`
  font-size: ${theme.fontSizes.xl};
  line-height: 22px;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.mobile}) { 
    font-size: 18px;
  } 
`
export const WorkAutorized = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.lightGray};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.md};
  margin-left: ${theme.offsets.lg};
  padding: 6px;
  border-radius: 6px;
  svg{
    margin-right: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    margin-top: ${theme.offsets.md};
    margin-left: 0;
  } 
 `
export const ContactsWrap = styled('div')`
  display:flex;
  align-items: center;
  margin-top: ${theme.offsets.xl};
  @media(max-width: ${theme.screens.mobile}) { 
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid ${theme.colors.lightGray};
    margin-top: ${theme.offsets.md};
  } 
`
export const ContactLink = styled('a')`
  color: ${theme.colors.black};
`
export const ContactItem = styled('span') <{ isFirstElem?: boolean }>`
  border-left: ${(props) => props.isFirstElem ? 'none' : `1px solid ${theme.colors.lightGray}`};
  padding:${(props) => props.isFirstElem ? `0 ${theme.offsets.sm} 0 0` : `0 ${theme.offsets.sm}`};
  font-size: 14px; 
  line-height: 20px;
  @media(max-width: ${theme.screens.mobile}) { 
    border-left: none;
    padding: 0;
    margin-top: ${theme.offsets.md};
  } 
`
export const MessageWrap = styled('div')`
  margin-top: ${theme.offsets.lg};
`
export const MessageTitle = styled('div')`
  margin-bottom: ${theme.offsets.sm};
  font-size: 14px; 
`

export const MessageText = styled('div')`
  margin-bottom: ${theme.offsets.sm};
`

export const ResumeBtnWrap = styled('div')`
  margin-top: ${theme.offsets.lg};
  border-top: 1px solid ${theme.colors.lightGray};
  display: flex;
`

export const ResumeBtn = styled('a')`
  margin-top: ${theme.offsets.lg};
  border: 1px solid ${theme.colors.lightGray};
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: ${theme.fontSizes.md};
  padding: ${theme.offsets.sm};
  border-radius: 6px;
  color: ${theme.colors.black};
  align-self: flex-start;
  svg{
    margin-right: ${theme.offsets.xs};
  }
`