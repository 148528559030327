import { observer } from 'mobx-react-lite';
import { ReactComponent as CompanyIcon } from '../../../assets/img/company.svg'
import { ReactComponent as WebsiteIcon } from '../../../assets/img/website.svg'
import { ReactComponent as LocationIcon } from '../../../assets/img/location.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/img/grayCalendar.svg'
import { Subheader as Subs, Link, LocationTitle, MainInformation, InfoBox, Subtitle, Separator, Description } from './Overview.presents';
import { TextButton } from '../../common/buttons/TextButton/TextButton';
import sizeOption from '../../../assets/staticData/organizationSize.json';
import { isSectionShowHandler, setLocationsListItem } from '../helper';
import { useState } from 'react';
import { LocationModal } from '../../JobStory/BasicComponents/Modals/LocationModal';
import { LocationType } from '../../JobStory/BasicComponents/Modals/Modals.props';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { linkHandler, sliceFullText } from '../../../services/helper';
import { OverviewProps } from './Overview.props';
import { HashLink } from 'react-router-hash-link';

export const Overview = observer(({ organizationData, foundationYear, companyLogoUrl, isDesktop, isAdminView }: OverviewProps) => {
	const initialValue = organizationData.initialValues;
	const isSectionShow = isSectionShowHandler(isAdminView || false, organizationData);
	const [openMapsWindow, setOpenMapsWindow] = useState(false);

	const organizationSizeValue = initialValue.organizationSize;
	const organizationSize = sizeOption.find(item => item.value === organizationSizeValue)?.label;

	const websiteUrl = initialValue.website;

	const locationsValue = initialValue.locations;

	const foundationYearValue = foundationYear && new Date(foundationYear);
	const fullYear = foundationYearValue && foundationYearValue.getFullYear();

	const aboutCareer = initialValue.aboutCareer;
	const aboutCompany = initialValue.aboutCompany;
	const aboutCulture = initialValue.aboutCulture;

	const showMainInformation = isSectionShow.organizationSize || isSectionShow.website || isSectionShow.location || isSectionShow.foundationYear;


	return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Main Information'}>
		{showMainInformation && <MainInformation>
			{isSectionShow.organizationSize && <InfoBox>
				<CompanyIcon />
				<Subs>Company size:</Subs>
				{
					organizationSizeValue ?
						organizationSize :
						<HashLink to='/my-organization#orgInfo'>
							<TextButton text={"+ Add Information"} />
						</HashLink>
				}
			</InfoBox>}
			{isSectionShow.website && <InfoBox>
				<WebsiteIcon />
				<Subs>Website:</Subs>
				{
					websiteUrl ?
						<Link href={linkHandler(websiteUrl)} target='_blank'>{sliceFullText(websiteUrl, 20)}</Link> :
						<HashLink to='/my-organization#orgWebsite'>
							<TextButton text={"+ Add Information"} />
						</HashLink>
				}
			</InfoBox>}
			{isSectionShow.location && <InfoBox>
				<LocationIcon />
				<Subs>Headquarters:</Subs>
				{
					!!locationsValue.length ?
						<LocationTitle onClick={() => setOpenMapsWindow(true)}>
							{setLocationsListItem(locationsValue)}
						</LocationTitle> :
						<HashLink to='/my-organization#orgLocation'>
							<TextButton text={"+ Add Information"} />
						</HashLink>
				}
			</InfoBox>}
			{isSectionShow.foundationYear && <InfoBox>
				<CalendarIcon />
				<Subs>Founded:</Subs>
				{
					fullYear ?
						fullYear :
						<HashLink to='/my-organization#orgFoundation'>
							<TextButton text={"+ Add Information"} />
						</HashLink>
				}
			</InfoBox>}
		</MainInformation>}
		{isSectionShow.aboutCompany && <>
			<Subtitle>About the company</Subtitle>
			{
				aboutCompany ?
					<Description>
						{aboutCompany}
					</Description> :
					<HashLink to='/my-organization#aboutCompany'>
						<TextButton text={"+ Add Information"} />
					</HashLink>
			}
			<Separator />
		</>}
		{isSectionShow.aboutCulture && <>
			<Subtitle>Company and culture</Subtitle>
			{
				aboutCulture ?
					<Description>
						{aboutCulture}
					</Description> :
					<HashLink to='/my-organization#aboutCulture'>
						<TextButton text={"+ Add Information"} />
					</HashLink>
			}
			<Separator />
		</>}
		{isSectionShow.aboutCareer && <>
			<Subtitle>Team and career path</Subtitle>
			{
				aboutCareer ?
					<Description>
						{aboutCareer}
					</Description> :
					<HashLink to='/my-organization#aboutCareer'>
						<TextButton text={"+ Add Information"} />
					</HashLink>
			}
		</>}
		{!!locationsValue.length && <LocationModal
			openMapsWindow={openMapsWindow}
			handleCloseMapsWindow={() => setOpenMapsWindow(false)}
			organizationName={initialValue.name}
			companyLogo={{
				fileUrl: companyLogoUrl,
			}}
			contactStep={null}
			locations={
				{ locationType: LocationType.organization, locationsData: locationsValue }
			}
		/>}
	</OrganizationPreviewCard>)

})