import { ReactComponent as ShareVideoIcon } from "../../../../../../assets/img/shareVideoIcon.svg";
import { ReactComponent as GetHiredIcon } from "../../../../../../assets/img/getHiredIcon.svg";
import { ReactComponent as CreateResumeIcon } from "../../../../../../assets/img/createResume.svg";

export const BenefitsMap = [
  {
    icon: <CreateResumeIcon />,
    title: 'Create Video Resume',
    description: 'Create your video resume. Make a video introduction to highlight your qualifications and experience.'
  },
  {
    icon: <ShareVideoIcon />,
    title: 'Share Video Resume',
    description: 'When applying to jobs, share your video resume with recruiters, hiring managers and employers.'
  },
  {
    icon: <GetHiredIcon />,
    title: 'Get Hired Quickly',
    description: 'By standing out to recruiters and hiring managers, you land a job more quickly.'
  },
]