import { Center } from '../../common/Wraps/Wraps.presents';
import { Text } from '../../common/Text';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { ReactComponent as FollowIcon } from "../../../assets/img/organization/socialNetworks.svg";
import { useHistory } from 'react-router-dom';
import { OrgProps } from '../Organization.props';
import { StyleForButtons, StyleForFollow } from './Follow.presents';
import { observer } from 'mobx-react-lite';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { HashLink as Link } from 'react-router-hash-link';
import twitterIcon from '../../../assets/img/socialMedia/twitter.svg';
import facebookIcon from '../../../assets/img/socialMedia/facebook.svg';
import linkedinIcon from '../../../assets/img/socialMedia/linkedin.svg';
import youtubeIcon from '../../../assets/img/socialMedia/youtube.svg';
import telegramIcon from '../../../assets/img/socialMedia/telegram.svg';
import instagramIcon from '../../../assets/img/socialMedia/instagram.svg';
import tiktokIcon from '../../../assets/img/socialMedia/tiktok.svg';
import redditIcon from '../../../assets/img/socialMedia/reddit.svg';
import whatsappIcon from '../../../assets/img/socialMedia/whatsapp.svg';
import dribbbleIcon from '../../../assets/img/socialMedia/dribbble.svg';
import behanceIcon from '../../../assets/img/socialMedia/behance.svg';
import pinterestIcon from '../../../assets/img/socialMedia/pinterest.svg';
import snapchatIcon from '../../../assets/img/socialMedia/snapchat.svg';
import { WrapForLink } from '../Organization.presents';
import { linkHandler } from '../../../services/helper';

interface socialMedia {
    title: string;
    link: string;
}

export const Follow = observer(({ organizationData, isDesktop }: OrgProps) => {
    const history = useHistory();
    const goToAdminPanel = () => {
        history.push('/my-organization')
    }

    const socialMediaOption = {
        'LinkedIn': linkedinIcon,
        'Twitter': twitterIcon,
        'Facebook': facebookIcon,
        'YouTube': youtubeIcon,
        'Telegram': telegramIcon,
        'Instagram': instagramIcon,
        'Reddit': redditIcon,
        'Whatsapp': whatsappIcon,
        'Tiktok': tiktokIcon,
        'Dribbble': dribbbleIcon,
        'Behance': behanceIcon,
        'Pinterest': pinterestIcon,
        'Snapchat': snapchatIcon,
    }

    return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Follow Us'}>
        {organizationData.socialNetworks && organizationData.socialNetworks.length > 0 ?
            <StyleForButtons>
                {organizationData.socialNetworks.map((item: socialMedia) => {
                    return <a target="_blank" href={linkHandler(item.link)}><StyleForFollow><img src={socialMediaOption[item.title as keyof typeof socialMediaOption]} alt="icon" width={22} /></StyleForFollow></a>
                })}
            </StyleForButtons>
            :
            <>
                <Center>
                    <FollowIcon />
                </Center>
                <Text center fs={16} color='black' m='20px 100px' text={"No Social Networks"} />
                <Text center fs={14} lh={1.2} m='20px 100px' text={"Link your social media profiles here to connect with potential candidates and boosts your talent acquisition marketing "} />
                <Center>
                    <WrapForLink>
                        <Link to='/my-organization#orgNetworks'>
                            <SPWhiteButton width={'100%'} onClick={goToAdminPanel}>
                                Add Social Networks
                            </SPWhiteButton>
                        </Link>
                    </WrapForLink>
                </Center>
            </>
        }

    </OrganizationPreviewCard>)
})