import { theme } from '../../../theme/theme';
import styled from 'styled-components';


export const StyleForDots = styled('div')`
  width:95%;
  max-width: 1680px;
  margin: 0 auto; 
  @media(max-width: ${theme.screens.mobile}) { 
  margin: 0 -${theme.offsets.lg};
  width: auto;
}   
`

export const WrapForContact = styled('div')`
display: flex;
}
`

export const StyleForContact = styled('div')`
margin: 0 ${theme.offsets.lg};
}   
`