import styled from "styled-components"
import { BaseButton } from "../SPBaseButton/SPBaseButton.presets"
import { theme } from "../../../../theme"

export const CloseButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.colors.transparent};
  border-color: ${theme.colors.mediumGray};
  color: ${theme.colors.darkGray};
  font-family: Apercu Pro;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 0 15px;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      border-color: ${theme.colors.darkGray};
    }
  }

  @media(max-width: ${theme.screens.mobile}) {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
`

export const CloseIcon = styled("img")`
  height: 13px;
  margin-right: ${theme.offsets.md};

  @media(max-width: ${theme.screens.mobile}) {
    height: 16px;
    margin-right: 0;
  }
`

export const CloseText = styled("span")`
  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }
`
