import { BrowserRouter as Router } from 'react-router-dom';
import { RootStoreProvider } from "../state/stores/storeContext";
import { Routers } from './Routers';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from '../assets/locales/en/translation.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn 
      }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

const App = () => {
  return (
    <RootStoreProvider>
      <Router>
        <Routers />
      </Router>
    </RootStoreProvider>
  );
}

export default App;
