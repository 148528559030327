import { useRootStore } from "../../../../../state/stores/storeContext";
import { Avatar } from "../../../../common/Avatar";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import buildingIcon from '../../../../../assets/img/orgAvatar.svg';
import { ErrorMsg, FieldWrap, BtnsWrap, Label, BannerWrap, DefaultURLString, PublicPreviewFieldWrap } from "./MainInfo.presents";
import { Input } from "../../../../common/Input";
import { Datepicker } from "../../../../common/DatePicker";
import { Field } from "formik";
import sizeOption from '../../../../../assets/staticData/organizationSize.json';
import industriesOptions from '../../../../../assets/staticData/industries.json';
import { SelectCom } from "../../../../common/Select";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { ReactComponent as PlusIcon } from '../../../../../assets/img/plusBlack.svg';
import { useCallback, useState } from "react";
import { RemoveLabel } from "../RemoveLabel/RemoveLabel";
import { HelperText } from "../../../../common/HelperText";
import { Banner } from "../Banner/Banner";
import { checkFileSizeInMB } from "../../../../../services/helper";
import { generateSignedUrl, s3upload } from "../../../../../services/api/fileManagement/fileManagement.domains";
import { checkUrlName } from "../../../../../services/api/organization/organization.domains";
import { PropsType } from "./MainInfo.props";
import { checkPublicUrlValue } from "../../helper";
import debounce from 'lodash.debounce';

export const MainInfoBlock = (props: PropsType) => {
  const { componentRef, isDesktop, values, errors, handleChange, setFieldValue, setFieldError, setPublicPreviewUrlError } = props;
  const { organizationStore } = useRootStore();
  const aboutCultureData = !!values.aboutCulture;
  const aboutCareerData = !!values.aboutCareer;
  const [showAboutCulture, setShowAboutCulture] = useState(aboutCultureData);
  const [showAboutCareer, setShowAboutCareer] = useState(aboutCareerData);

  const MAX_LOGO_SIZE = 25;
  const UPLOAD_FOLDER = 'images'

  const sortedIndustriesOptions = industriesOptions.sort((prevItem, nextItem) => prevItem.label < nextItem.label ? -1 : 1);

  const closeAboutCulture = () => {
    setFieldValue('aboutCulture', '');
    setShowAboutCulture(false);
  }


  const closeAboutCareer = () => {
    props.setFieldValue('aboutCareer', '');
    setShowAboutCareer(false);
  }

  const uploadOrganizationLogo = async (event: any) => {
    if (!event.target.files) return

    let uploadFile = event.target.files[0];
    if (!checkFileSizeInMB(uploadFile.size, MAX_LOGO_SIZE)) {
      event.target.value = null;
      return alert(`Maximum logo size is ${MAX_LOGO_SIZE} megabytes`)
    }
    const resp = await generateSignedUrl({ filename: uploadFile.name, filetype: uploadFile.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, uploadFile)
        const addLogoDto = {
          logo: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            fileName: uploadFile.name.replace(/\.[^/.]+$/, ""),
          }
        }
        await organizationStore.saveSingleFile(addLogoDto)
        organizationStore.setLogo(resp.data.data.key, resp.data.data.fileUrl)
      } catch (error) {
        console.log(error)
      }
    }
    event.target.value = null; // reset input value
  }

  const deleteOrganizationLogo = async (props: { isRemoveOnlyLogo: boolean }) => {
    const removeFileDto = {
      dataForUpdate: {
        logo: null
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: organizationStore.logoFileId
    }

    await organizationStore.deleteSingleFile(removeFileDto);
    organizationStore.setLogo('', '')
  }

  const { logoUrl } = organizationStore;

  const checkPublicUrlHandler = async (data: any) => {
    const newValue = data.target.value;
    const isValueNotValid = checkPublicUrlValue(newValue);
    //Check value to valid symbols
    if (!isValueNotValid) {
      //Check new url to existing
      const isUrlNameIsUniq = (await checkUrlName(newValue)).data;
      if (isUrlNameIsUniq.err) {
        setFieldError('publicPreviewUrl', isUrlNameIsUniq.err);
        setPublicPreviewUrlError(true);
      } else {
        setFieldError('publicPreviewUrl', null);
        setPublicPreviewUrlError(false)
      }
    }
  }

  const changeUrlValueHandler = (data: any) => {
    const newValue = data.target.value;
    const isValueNotValid = checkPublicUrlValue(newValue);
    if (!isValueNotValid) {
      handleChange(data);
    }
  }

  const debouncedChangeHandler = useCallback(
    debounce((data) => checkPublicUrlHandler(data), 2000)
    , []);

  return <OrganizationCard title={'Main Information'} componentRef={componentRef} isDesktop={isDesktop}>
    <Avatar
      url={logoUrl}
      placeholder={buildingIcon}
      onUpload={uploadOrganizationLogo}
      onDelete={deleteOrganizationLogo}
      btnText={'logo'}
    />
    <BannerWrap>
      <Banner
        label={<Label>Cover Image
          <HelperText text="The perfect cover photo size is 1680x560 px" />
        </Label>} />
    </BannerWrap>
    <FieldWrap>
      <Field
        as={Input}
        name={'name'}
        type={'text'}
        value={values.name}
        onChange={handleChange}
        maxLength={50}
        labelText={'Organization name'}
        error={errors.name} />
      <ErrorMsg>{errors.name}</ErrorMsg>
    </FieldWrap>

    <FieldWrap id='orgFoundation'>
      <Field
        name={'foundationYear'}
        as={Datepicker}
        value={values.foundationYear}
        setValue={(value: Date) => setFieldValue('foundationYear', value)}
        label={'Year of foundation'}
        maxDate={new Date()}
      />
      <ErrorMsg>{errors.foundationYear}</ErrorMsg>
    </FieldWrap>

    <FieldWrap id='industry'>
      <Field
        labelText={'Industry'}
        options={sortedIndustriesOptions}
        name={'industry'}
        component={SelectCom} />
      <ErrorMsg>{errors.industry}</ErrorMsg>
    </FieldWrap>

    <FieldWrap id='orgInfo'>
      <Field
        labelText={'Size of the organization'}
        options={sizeOption}
        name={'organizationSize'}
        component={SelectCom} />
      <ErrorMsg>{errors.organizationSize}</ErrorMsg>
    </FieldWrap>

    <FieldWrap id='orgWebsite'>
      <Field
        as={Input}
        name={'website'}
        type={'text'}
        maxLength={100}
        value={values.website}
        onChange={handleChange}
        labelText={'Company website'}
        error={errors.website} />
      <ErrorMsg>{errors.website}</ErrorMsg>
    </FieldWrap>

    <FieldWrap>
      <PublicPreviewFieldWrap>
        <DefaultURLString>sidepost.com/organization/</DefaultURLString>
        <Field
          as={Input}
          name={'publicPreviewUrl'}
          type={'text'}
          maxLength={100}
          value={values.publicPreviewUrl}
          onChange={(data: any) => { changeUrlValueHandler(data); debouncedChangeHandler(data) }}
          labelText={<Label>Custom URL
            <HelperText text="Create a custom URL for your public profile and share it with job candidates and social networks such as LinkedIn, Twitter and Facebook" />
          </Label>}
          error={errors.publicPreviewUrl} />
      </PublicPreviewFieldWrap>
      <ErrorMsg>{errors.publicPreviewUrl}</ErrorMsg>
    </FieldWrap>

    <FieldWrap id='aboutCompany'>
      <Field
        as={Input}
        name={'aboutCompany'}
        type={'textarea'}
        maxLength={500}
        value={values.aboutCompany}
        onChange={handleChange}
        labelText={'About the company'}
        error={errors.aboutCompany} />
      <ErrorMsg>{errors.aboutCompany}</ErrorMsg>
    </FieldWrap>

    {(showAboutCulture || aboutCultureData) && <FieldWrap id='aboutCulture'>
      <Field
        as={Input}
        name={'aboutCulture'}
        type={'textarea'}
        maxLength={500}
        value={values.aboutCulture}
        onChange={handleChange}
        labelText={<RemoveLabel labelText="About company culture" callback={closeAboutCulture} />}
        error={errors.aboutCulture} />
      <ErrorMsg>{errors.aboutCulture}</ErrorMsg>
    </FieldWrap>}

    {(showAboutCareer || aboutCareerData) && <FieldWrap id='aboutCareer'>
      <Field
        as={Input}
        name={'aboutCareer'}
        type={'textarea'}
        maxLength={500}
        value={values.aboutCareer}
        onChange={handleChange}
        labelText={<RemoveLabel labelText="About team and career path" callback={closeAboutCareer} />}
        error={errors.aboutCareer} />
      <ErrorMsg>{errors.aboutCareer}</ErrorMsg>
    </FieldWrap>}


    <BtnsWrap>
      {!showAboutCulture && !aboutCultureData && <SPSecondaryButton onClick={() => setShowAboutCulture(true)} width="auto" isLight type="button">
        <span>About company culture</span>
        <PlusIcon />
      </SPSecondaryButton>}

      {!showAboutCareer && !aboutCareerData && <SPSecondaryButton onClick={() => setShowAboutCareer(true)} width="auto" isLight type="button">
        <span>About team and career path</span>
        <PlusIcon />
      </SPSecondaryButton>}
    </BtnsWrap>

  </OrganizationCard>
}