import { BetaVersionBlock, BetaVersionTitle, BetaVersionText } from './BetaVersionCard.presents';
import { useHistory } from "react-router-dom";
import { ReactComponent as SubtractIcon } from '../../../../../../assets/img/Subtract.svg'
import { SPBaseButton } from '../../../../buttons/SPBaseButton';


export const BetaVersionCard = ({ isCandidateService }: { isCandidateService?: boolean }) => {
  const history = useHistory();

  const redirectToFeedbackPage = () => {
    history.push(isCandidateService ? '/candidate/support' : '/support');
  }

  return <BetaVersionBlock>
    <SubtractIcon />
    <BetaVersionTitle>We love to hear from you.</BetaVersionTitle>
    <BetaVersionText>Have any ideas how to make our product better? Just let us know!</BetaVersionText>
    <SPBaseButton onClick={redirectToFeedbackPage} width="100%">Give feedback</SPBaseButton>
  </BetaVersionBlock>
}
