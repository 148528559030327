import { theme } from '../../../../theme';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const MediaModalWrapper = styled(Modal)`
.modal-dialog{
  max-width: 830px;
  @media(max-width: ${theme.screens.desktopLarge}) {
    max-width: 548px;
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
}
.modal-content {
  border-radius: ${theme.variables.borderRadiusLarge};
  padding: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktopLarge}) {
    padding: ${theme.offsets.xxl} 30px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.md};
  }
  .modal-body {
    padding: 0;
    max-width: 545px;
    width: 100%;
    margin: 0 auto;
  }
}
`

export const ContactMeHeader = styled('div')`
  margin-top: ${theme.offsets.md};
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @media(max-width: ${theme.screens.desktopLarge}) {
    margin-bottom: ${theme.offsets.big};
    margin-top: 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.sm};
  }
`

export const Title = styled('h3')`
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  line-height: 22px;
  color: ${theme.colors.darkGray};
  @media(max-width: ${theme.screens.desktopLarge}) {
    font-size: 18px;
  }
`

export const CloseButton = styled('button')`
  position: absolute;
  right: 44px;
  top: 44px;
  background: transparent;
  border: none;
  svg {
    height: 14px;
    width: 14px;
  }
  @media(max-width: ${theme.screens.desktopLarge}) {
    right: 30px;
    top: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    right: ${theme.offsets.md};
    top: ${theme.offsets.xl};
  }
`

export const ButtonWrap = styled('div')`
  margin-top: ${theme.offsets.xxl};
  right: 44px;
  top: 44px;
`

export const PolicyLink = styled(Link)`
  color: ${theme.colors.blue};
`

export const PolicyText = styled('p')`
  font-size: ${theme.fontSizes.lg};
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: ${theme.offsets.lg};
`

export const FileInput = styled('div')`
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 151px;
    svg{
      margin-right: ${theme.offsets.md};
    }
    span{
      margin-bottom: -3px;
    }
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    input[type=file]::-webkit-file-upload-button {
    display: none;
  }
  }
`