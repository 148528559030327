import { IntroStep } from "../../../../components/Auth/Candidate/SignUp/Steps/Intro/IntroStep";
import { PasswordStep } from "../../../../components/Auth/Candidate/SignUp/Steps/Password/PasswordStep";
import { Preferences } from "../../../../components/Auth/Candidate/SignUp/Steps/Preferences/Preferences";
import { WelcomeStep } from "../../../../components/Auth/Candidate/SignUp/Steps/Welcome/WelcomeStep";
import { useRootStore } from "../../../../state/stores/storeContext";
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom";
import { Preloader } from "../../../../components/common/Preloader";
import { ExpertiseStep } from "../../../../components/Auth/Candidate/SignUp/Steps/Expertise/ExpertiseStep";

export const CandidateSignUpPage = observer(() => {
  const { candidateStore } = useRootStore()
  const location = useLocation()
  const { state } = location as any

  if (candidateStore.passwordSet && candidateStore.preferencesSet) {
    return <WelcomeStep />
  }
  else if (candidateStore.preferencesSet && candidateStore.isExpertiseSet && !candidateStore.passwordSet) {
    return <PasswordStep />
  }
  else if (candidateStore.isAuthenticated && !candidateStore.preferencesSet) {
    return <Preferences />
  }
  else if(candidateStore.isAuthenticated && candidateStore.preferencesSet && !candidateStore.isExpertiseSet ){
    return <ExpertiseStep/>
  }
  else {
    if (state?.newUser){
      return <Preloader isFullScreen/>
    }
    return <IntroStep />
  }
})