import { SPLightButton } from "../../../../common/buttons/SPLightButton/SPLightButton";
import { FileInput, UploadedFileField } from "../../ResumeBuilder.presents";
import { ReactComponent as DocumentIcon } from "../../../../../assets/img/Document.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/img/close.svg";
import { sliceFullText } from "../../../../../services/helper";
import { onRemoveFile } from "../../../../../services/firebase/firestore/ResumeBuilder/writes";
import { useRootStore } from "../../../../../state/stores/storeContext";
import {SubtitleNoMg} from "../../../../common/Cards/CandidateFormCard/CandidateFormCard.presents";

export const FileInputWrap = ({
  onChange,
  name,
  value,
  updateItemCallback
}: {
  onChange: Function,
  name: string,
  value: { url: string, name: string, isUploaded: boolean },
  updateItemCallback: () => void

}) => {
  const { candidateStore } = useRootStore();
  const inputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file = target.files && target.files[0];
    if (file && (file.size / (1024 * 1024) <= 5)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        onChange({
          name: file.name,
          url: reader.result
        });
      };
    } else {
      event.target.value = "";
    }
  };

  const onRemoveFileHandler = async () => {
    if (value.isUploaded) {
      await onRemoveFile(value.name, candidateStore.uid);
      await updateItemCallback();
    };
    onChange({
      name: '',
      url: '',
      isUploaded: false
    })
  };

  return !value.url ? <FileInput>
    <SPLightButton type="button" width="fit-content">
      <input onChange={inputOnChangeHandler} type="file" accept=".pdf,.doc,.docx,image/*" name={name} />
      + Attach a File
    </SPLightButton>
    <SubtitleNoMg>Accepted file types: PDF, DOC, JPG, JPEG, PNG, GIF. Maximum file size: 5 MB.
    </SubtitleNoMg>
  </FileInput> : <UploadedFileField>
    <DocumentIcon />
    <span>
      {sliceFullText(value.name, 15)}
    </span>
    <button type="button" onClick={onRemoveFileHandler}>
      <CloseIcon />
    </button>
  </UploadedFileField>
}