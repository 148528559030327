import axios from 'axios';
import { baseURl } from "../../../constants/config";

export const getRegionsFromGoogle = (charts: string) => {
  axios.defaults.withCredentials = true
  return axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${charts}&key=AIzaSyCtQ3vz4MJdwx14lwBMX9jCQYIPPG5uZtQ&types=(regions)`)
}

export const getPlaces = (placeType: 'country' | 'state' | 'city', codes?: { countryCode: string, stateCode?: string }) => {
  axios.defaults.withCredentials = true
  return axios.get(`${baseURl}/places/${placeType}/${codes?.countryCode || '_'}/${codes?.stateCode || '_'}`)
}

export const getTimezones = () => {
  axios.defaults.withCredentials = true
  return axios.get(`${baseURl}/timezones`)
}