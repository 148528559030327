import styled from "styled-components";
import { theme } from "../../../theme";
import calendar from '../../../assets/img/calendar.svg';
import arrowIcon from '../../../assets/img/arrow.svg';
import closeIcon from '../../../assets/img/close.svg';

export const Wrapper = styled('div')`
max-width: 100%;
position: relative;
  .react-datepicker-popper{
    width: 100%;
    background-color: ${theme.colors.white};
    border-radius: ${theme.variables.borderRadiusSmall};
    z-index: 10;
    margin-top: ${theme.offsets.sm};
    border: 1px solid ${theme.colors.lightGray};
    padding: ${theme.offsets.lg} ${theme.offsets.xxl};
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.md};
    }
  }
  .react-datepicker{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .react-datepicker-year-header{
    text-align: center;
    margin-bottom: ${theme.offsets.md};
  }
  .react-datepicker__year-wrapper, .react-datepicker__month-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -8%;
    @media(max-width: ${theme.screens.tablet}) {
      margin: 0 -5%;
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin: 0 ${theme.offsets.sm};
    }
  }
  .react-datepicker__month-wrapper{
    margin: 0 -3%;
    flex-direction: column;
    @media(max-width: ${theme.screens.mobile}) {
      margin: 0 ${theme.offsets.xs};
    }
  }
  .react-datepicker__year-text, .react-datepicker__month-text{
    margin-top: ${theme.offsets.sm};
    width: 56px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${theme.offsets.sm} 7% 0;
    border-radius: 6px;
    cursor: pointer;
    font-size: ${theme.fontSizes.lg};
    @media(max-width: ${theme.screens.tablet}) {
      margin: ${theme.offsets.sm} 5% 0;
      font-size: 14px;
      height: 30px;
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin: ${theme.offsets.sm} ${theme.offsets.sm} 0;
    }
    &:hover,&.react-datepicker__year-text--selected{
      color: ${theme.colors.white};
      background-color: ${theme.colors.lightOrange}; 
    }
  }
  .react-datepicker__year-text--disabled, .react-datepicker__month--disabled{
    color: ${theme.colors.lightGray};
    cursor: not-allowed;
    &:hover{
      color: ${theme.colors.lightGray};
      background-color: ${theme.colors.white};
    }
  }
  .react-datepicker__month-container{
    width: 100%;
  }
  .react-datepicker__monthPicker{
    justify-content: space-around;
  }
  .react-datepicker__navigation {
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    span{
      display: none;
    }
    ::before{
      content: '';
      display: block;
      width: 60%;
      height: 100%;
      background-image: url(${arrowIcon});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .react-datepicker__navigation--previous{
    justify-content: start;
    ::before{
    transform: rotate(90deg);
  }}
  .react-datepicker__navigation--next{
    justify-content: end;
    ::before{
      transform: rotate(-90deg);
    }
  }
  .react-datepicker__monthPicker{
    display: flex;
  }
`

export const CustomInput = styled('button')<{hasValue: boolean}>`
  border: 1px solid ${theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  height: 52px;
  width: 100%;
  padding: 0 ${theme.offsets.lg};
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: ${props=>props.hasValue ? "space-between" : "flex-end" };
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 40px;
  }
`

export const CalendarIcon = styled('div')`
  mask-image: url(${calendar});
  background-color: #AAAEB4;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 18px;
  height: 20px;
`

export const CloseIcon = styled('div')`
  cursor: pointer;
  position: absolute;
  top: 44px;
  right: 50px;
  @media(max-width: ${theme.screens.tablet}) {
    top: 40px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    top: 38px;
  }
  mask-image: url(${closeIcon});
  background-color: #AAAEB4;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 14px;
  height: 14px;
`
export const Label = styled('div')`
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.darkGray};
  margin-bottom: ${theme.offsets.sm};
`