import { SidebarContainer, StyleForSidebarLink, MenuList, LogoWrap, ContentWrap } from "./Sidebar.presents";
import bag from '../../../../../assets/img/bag.svg';
import documentIcon from '../../../../../assets/img/documentIconWhite.svg';
import organization from '../../../../../assets/img/organization.svg';
import { ReactComponent as Logo } from '../../../../../assets/img/logo-white.svg'
import { BetaVersionCard } from "./BetaVersionCard"
import { useTranslation } from "react-i18next"
import { useRootStore } from "../../../../../state/stores/storeContext"
import {HelperTextTooltipWrapper} from "../../../HelperText/HelperTextTooltipWrapper";

interface menuTypes {
  title: string,
  img: string,
  path: string,
  selectorId?: string
  helperText?: {
    title: string,
    text: string
  }
}

export const DesktopSidebar = ({ isCandidateService }: { isCandidateService?: boolean }) => {
  const { t } = useTranslation();


  const employerSidebarList: menuTypes[] = [
    { path: "my-jobs", title: t('adminPanel.tabMyJobs'), selectorId: "applications_step", img: bag },
    { path: "my-organization", title: t('adminPanel.tabOrganization'), img: organization },
  ]

  const candidateSidebarList: menuTypes[] = [
    { path: "/candidate/resume-builder", title: t('adminPanel.tabMyResume'), img: bag },
    { path: "/candidate/preferences", title: t('adminPanel.tabMyPreferences'), img: documentIcon },
  ]

  const sidebarList = isCandidateService ? candidateSidebarList : employerSidebarList;
  const logoRedirect = isCandidateService ? "/candidate/resume-builder" : "/my-jobs";

  return (
    <SidebarContainer>
      <ContentWrap>
        <LogoWrap to={logoRedirect}>
          <Logo />
        </LogoWrap>
        <MenuList>
          {
            sidebarList.map((item, index) =>
              <StyleForSidebarLink
                id={item.selectorId}
                key={index}
                activeClassName="active"
                to={item.path}>
                <img src={item.img} alt="img" />{item.title}
                {!!item?.helperText && <HelperTextTooltipWrapper text={item.helperText.text} title={item.helperText.title} autoChangePosition/>}
              </StyleForSidebarLink>)
          }
        </MenuList>
      </ContentWrap>
      <BetaVersionCard isCandidateService={isCandidateService} />
    </SidebarContainer>
  )
}
