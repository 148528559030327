import { theme } from '../../../theme/theme';
import styled from 'styled-components';

export const FieldsWrap = styled('div')`
  margin: 0 auto;
  max-width: 557px;
  width: 100%;
  padding: 0 ${theme.offsets.lg};
`

export const ErrorText = styled('span')`
display: block;
font-size: ${theme.fontSizes.md};
margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
height: 14px;
color: ${theme.colors.red};
`