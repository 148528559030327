export const linkHandler = (link: string) => {
  const isFullLink = link.includes('https:') || link.includes('http:')
  if (isFullLink) {
    return link;
  } else {
    return `//${link}`;
  }
}

export const changeFocusWhenMaxLenghtReached = (event: any, maxIndex: number) => {
  const form = event.target.form;
  let index = Array.prototype.indexOf.call(form, event.target);
  const isFieldHasMaxLength = event.target.value.length >= event.target.getAttribute("maxlength");
  const isFieldValueEmpty = event.target.value.length === 0;
  if (isFieldHasMaxLength && index < maxIndex - 1) {
    //Focus to next field
    form.elements[++index].focus();
  } else if (isFieldValueEmpty) {
    //Focus to prev field
    const newElementIndex = index > 0 ? --index : 0;
    form.elements[newElementIndex].focus();
  }
  event.preventDefault();
}
// File size in MegaBytes
export const checkFileSizeInMB = (fileSize: number, limit: number) => {
  return limit > (fileSize / 1024 / 1024)
}

export const sliceFullText = (fullText: string, maxLength: number) => {
  let updatedText = fullText;
  if (fullText.length > maxLength) updatedText = `${fullText.slice(0, maxLength)}...`
  return updatedText
}

export const checkValueToSpacesAndSymbols = (value: string) => {
  return /[\s-!$%^&*()_+|#~=`{}\[\]:";'<>?,.\/]/.test(value);
}

export const getBlobDuration = (blob: any) => {
  const tempVideoEl: HTMLVideoElement = document.createElement('video')

  const durationP = new Promise((resolve, reject) => {
    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null
          resolve(tempVideoEl.duration)
          tempVideoEl.currentTime = 0
        }
      }
      // Normal behavior
      else
        resolve(tempVideoEl.duration)
    })
    tempVideoEl.onerror = (event: any) => reject(event.target.error)
  })

  tempVideoEl.src = typeof blob === 'string'
    ? blob
    : window.URL.createObjectURL(blob)

  return durationP
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
 
  const arr = dataurl.split(',');
  //@ts-ignores
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length; 
  const u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

export const isIOSCheck = () => {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}