import { BenefitSchema } from '../../../assets/staticData/benefits/BenefitSchema';
import { MainDescriptionCard } from '../Jobs/Jobs.presents';



export const isBenefitExist = (benefitName: string, benefits: any) => {
  return benefits && benefits[benefitName] && benefits[benefitName].length > 0;
}

export const benefitsCount = (benefits: any) => {
  let count = 0
  Object.keys(benefits).forEach((category: string) => {
    if(benefits[category]&& benefits[category].length>0){
      count+=benefits[category].length
    }
  });
  return count
}

export const renderBenefitPreview = (benefits: any) => {
  const benefitArray = [];
  {benefitsCount(benefits)}

  for (const item in BenefitSchema) {
    if (isBenefitExist(item, benefits)) {
      benefitArray.push(<MainDescriptionCard color={BenefitSchema[item].color} bgColor={BenefitSchema[item].bgColor}>
        {BenefitSchema[item].icon}
        <span>
          {BenefitSchema[item].title}
        </span>
      </MainDescriptionCard>)
    }
  }

  return benefitArray;
}

