import { PropsType } from "./Header.props";
import {
  StyleForHeader,
  StyleForUserWrap,
  StyleForLogo,
  Avatar,
  BtnsWrap,
  CreateJobBtn,
  AvatarWrap,
  UserName,
  ArrowIcon,
  BtnContainer,
  OrganizationBtns,
  PreviewBtnContainer
} from './Header.presents';
import { ReactComponent as LogoIcon } from '../../../../../assets/img/logo.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/plus.svg';
import { ReactComponent as EyeIcon } from '../../../../../assets/img/eyeOpen.svg';
import arrowIcon from '../../../../../assets/img/arrow.svg';
import avatarIcon from '../../../../../assets/img/avatar.svg';
import { useRef, useState } from "react";
import { UserMenu } from "./UserMenu";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { DesktopOnlyFeatureModal } from "../../../Modals/DesktopOnlyFeatureModal";
import { SPSecondaryButton } from "../../../buttons/SPSecondaryButton/SPSecondaryButton";
import { useRootStore } from "../../../../../state/stores/storeContext";
import useOnScreen from "../../../../../hooks/useOnScreen";
import { SPLightButton } from "../../../buttons/SPLightButton/SPLightButton";
import useGetDevice from "../../../../../hooks/useGetDevice";
import { ReactComponent as PublicView } from '../../../../../assets/img/public-view.svg';


export const DesktopHeader = observer((props: PropsType) => {
  const { userStore, organizationStore } = useRootStore();
  const history = useHistory();
  const { t } = useTranslation();

  const publicPreviewUrl = organizationStore.publicPreviewUrl;

  const isDesktop = useGetDevice();

  const [isUserMenuShown, setIsUserMenuShown] = useState(false);
  const [isDesktopOnlyFeatureModalOpen, setDesktopOnlyFeatureModalOpen] = useState(false);

  const avatar = userStore?.logo?.url || avatarIcon;
  const avatarRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(avatarRef);

  const isMenuShownOnScreen = () => {
    return isVisible ? isUserMenuShown : false
  }

  const createJob = () => {
    if (!isDesktop) {
      setDesktopOnlyFeatureModalOpen(true)
    } else {
      history.push('/job-story/start')
    }
  }

  const openOrganizationPreview = () => {
    history.push('/organization-preview')
  }

  const openPublicOrganizationPreview = () => {
    window.open(`/organization/${publicPreviewUrl}`, "_blank");
  }


  const saveChangesHandler = () => {
    props.saveOrganizationChanges && props.saveOrganizationChanges();
  }

  const showCreateJobBtn = !props.previewPage && !props.isOrganizationPage;


  return <StyleForHeader previewPage={props.previewPage}>
    {props.previewPage && <StyleForLogo to={'/my-jobs'}>
      <LogoIcon />
    </StyleForLogo>}
    <BtnsWrap>

      {showCreateJobBtn && <CreateJobBtn>
        <SPSecondaryButton onClick={createJob} width={'100%'}>
          {t('adminPanel.createJobBtn')}
          <PlusIcon />
        </SPSecondaryButton>
      </CreateJobBtn>}

      {props.isOrganizationPage && <OrganizationBtns>
        {isDesktop && <>
          <PreviewBtnContainer>
            <SPLightButton disabled={!publicPreviewUrl} onClick={openPublicOrganizationPreview} width={'auto'}>
              <PublicView />
              Public View
            </SPLightButton>
          </PreviewBtnContainer>
          <PreviewBtnContainer>
            <SPLightButton onClick={openOrganizationPreview} width={'auto'}>
              <EyeIcon />
              Preview
            </SPLightButton>
          </PreviewBtnContainer>
        </>
        }
        <BtnContainer>
          <SPSecondaryButton disabled={props.isSaveBtnDisable} onClick={saveChangesHandler} width={'100%'}>
            Save Changes
          </SPSecondaryButton>
        </BtnContainer>
      </OrganizationBtns>}

      <StyleForUserWrap onClick={() => setIsUserMenuShown(!isUserMenuShown)} onBlur={() => setIsUserMenuShown(false)}>
        <AvatarWrap ref={avatarRef}>
          <Avatar
            src={avatar}
            alt="Avatar" />
          <UserName>{userStore.firstName + ' ' + userStore.lastName}</UserName>
          <ArrowIcon src={arrowIcon} alt="V" isOpen={isUserMenuShown} />
        </AvatarWrap>
        <UserMenu show={isMenuShownOnScreen()} />
      </StyleForUserWrap>
    </BtnsWrap>

    {/* Desktop only feature warning modal */}
    {isDesktopOnlyFeatureModalOpen && <DesktopOnlyFeatureModal isOpen={isDesktopOnlyFeatureModalOpen} onClose={() => setDesktopOnlyFeatureModalOpen(false)} />}
  </StyleForHeader>
})