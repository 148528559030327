import { observer } from 'mobx-react-lite';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { GalleryProps } from './Gallery.props';
import { EmptyGallery } from './EmptyGallery';
import { PhotoGallery } from './PhotoGallery';
import { VideoGallery } from './VideoGallery';


export const Gallery = observer((props: GalleryProps) => {
    const {
        photoGallery,
        videoGallery,
        isDesktop,
        isAdminView,
    } = props;

    const renderPhotoGallery = () => {
        if (photoGallery && photoGallery.length > 0) {
            return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Images'}>
                <PhotoGallery {...props} />
            </OrganizationPreviewCard>)
        }
        else {
            if (isAdminView) {
                return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Images'}>
                    <EmptyGallery />
                </OrganizationPreviewCard>)
            }
        }
    }
    return (
        <>
            {videoGallery && videoGallery.length > 0 && (
                <OrganizationPreviewCard isDesktop={isDesktop} title={'Videos'}>
                    <VideoGallery {...props} />
                </OrganizationPreviewCard>
            )}
            
            {renderPhotoGallery()}

        </>
    )
})
