import { useEffect } from "react"
import { useHistory } from "react-router"
import { Header } from "../../components/Auth/BaseComponents/Header"
import { Banner } from "../../components/Auth/Employer/SignUp/Banner"
import { SignUp } from '../../components/Auth/Employer/SignUp/SignUpWrap'
import useGetDevice from "../../hooks/useGetDevice"
import { useRootStore } from "../../state/stores/storeContext"
import { PageContainer } from "../SignIn/SignIn.presents"
import { PageWrap, Wrapper, PageContent } from "./SignUp.presents"
import { PropsType } from "./SignUpPage.props"

export const SignUpPage = (props: PropsType) => {
  const { userStore } = useRootStore();
  const history = useHistory();
  const isDesktop = useGetDevice();

  useEffect(() => {
    async function fetchData() {
      if (userStore.isAuthorizated) {
        const resData = await userStore.addUserData()
        !resData.err && history.push('/my-jobs')
      }
    }
    fetchData();
  }, [userStore.isAuthorizated, history, userStore])
  
  return <Wrapper>
    {isDesktop && <Banner />}
    <PageContent>
      <Header showLogo={!isDesktop} showLogin />
      <PageContainer>
        <PageWrap isDesktop={isDesktop}>
          <SignUp/>
        </PageWrap>
      </PageContainer>
    </PageContent>
  </Wrapper>
}