import { theme } from '../../../../theme';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';


export const ShareHeader = styled('div')<{isAdminMode?: boolean}>`
  margin-bottom: ${props => props.isAdminMode ? "20px" : "48px"};
  display: flex;
  align-items: center;
`

export const OrganizationLogo = styled('img')`
  height: 48px;
  width: 48px;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  margin-right: ${theme.offsets.md};
  object-fit: cover;
`

export const MediaModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: ${theme.variables.borderRadiusLarge};
    padding: ${theme.offsets.extraBig};


    @media(max-width: ${theme.screens.desktopLarge}) {
      padding: ${theme.offsets.xxl} 30px;
    }
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.xxl};
    }
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.md};
    }
    .modal-body {
      padding: 0;
    }
  }
`

export const ShareSection = styled('div')`
  border-bottom: 1px solid #ECECEC;
  padding-top: ${theme.offsets.xxl};
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${theme.colors.darkGray};
    margin-right: 24px;
    margin-bottom: 24px;

    svg {
      margin-right: 12px;
    }
  }
`

export const ShareSectionTitle = styled('p')`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.colors.darkGray};
`

export const Title = styled('h3')`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.colors.darkGray};
`

export const Subtitle = styled('p')`
  font-size: 14px;
  line-height: 16px;
  color: rgba(63, 71, 79, 0.8);
`

export const CloseButton = styled('button')`
  margin-left: auto;
  background: transparent;
  border: none;
  margin-bottom: 20px;
  svg {
    height: 14px;
    width: 14px;
  }
`
export const CopylinkContainer = styled('div')`
  padding-top: ${theme.offsets.xxl};
  display: flex;
  align-items: center;

  button {
    margin-left: 20px;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const CopylinkInput = styled('input')`
  padding: 14px;
  height: 44px;
  border-radius: ${theme.variables.borderRadiusSmall};
  font-size: 14px;
  line-height: 16px;
  color: #2D353D;
  border: 1px solid #D1D7E3;
  width: 100%;
  
  &:disabled {
    background: #fff;
  }
`
