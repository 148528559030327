import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { theme } from "../../../theme";
import arrowIcon from '../../../assets/img/arrow.svg';
import plusIcon from "../../../assets/img/plusBlack.svg";

export const Wrapper = styled('div')`
  display: flex;
  padding: 0 ${theme.offsets.lg};
  .progress-bar {  // this is for the bar itself
	background-color:  ${theme.colors.lightOrange};
	height: 20px;
	}
	.progress { 
	height: 10px;
	}
`

export const Container = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  position: relative;
  margin-top: ${theme.offsets.xxl};
  margin-bottom: 80px;
  margin-right: 100px;
  flex: 1;
  justify-content: flex-start;
  ${(props) => !props.isDesktop && `
    margin-top: 0;
    margin-right: 0;
    margin-bottom: ${theme.offsets.extraBig};
    flex-direction: column;
  `}
`



export const FormWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 848px;
  ${props => !props.isDesktop && `
     margin: 0 auto;
  `}
`

export const ContentWrap = styled('div')`
  max-width: 557px;
  margin: 0 auto;
`

export const MultiFieldWrap = styled('div') <{ hasWrap?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: -${theme.offsets.md};
  flex-wrap: ${props => props.hasWrap ? 'wrap' : 'nowrap'};
  ${props => props.hasWrap && `
    width: 100%;
  `}
  @media(max-width: ${theme.screens.tablet}) {
    margin: -${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.mobile}) {
    flex-wrap: wrap;
    margin: 0;
  }
`

export const MultiField = styled('div')`
  margin: ${theme.offsets.md};
  width: 100%;
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: 0;
  }
`

export const FieldLabel = styled('label')`
  font-size: 14px;
  margin-bottom: ${theme.offsets.sm};
  text-transform: capitalize;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
  } 
`

export const PhoneInputWrap = styled(PhoneInput) <{ isDesktop: boolean }>`
input { 
  border: 1px solid #D1D7E3;
  border-radius: 10px;
  height: 52px;
  padding: 18px;
  background-color: #fff!important;
  color: ${theme.colors.black};
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    font-size: 14px;
  } 
};
.PhoneInputCountry {
  max-width: ${(props) => props.isDesktop ? '122px' : '100px'};
  width: 100%;
  border: 1px solid #D1D7E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px ${theme.offsets.lg};
  justify-content: space-between;
  margin-right: ${(props) => props.isDesktop ? theme.offsets.xxl : theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 80px;
  }
  @media(max-width: ${theme.screens.mobileSmall}) {
    padding: 14px;
  }  
  } 
.PhoneInputCountrySelectArrow{
  border:none;
  content: url(${arrowIcon});
  width: 16px;
  height: 9px;
  transform: unset;
}
&.error input, &.error .PhoneInputCountry{
  border-color: ${theme.colors.lightRed};
}
`

export const AdditionalButtons = styled('div')`
display: flex;
flex-wrap: wrap;
margin: -${theme.offsets.sm};
margin-top: ${theme.offsets.xl};
  button{
    padding-left: ${theme.offsets.lg};
    padding-right: ${theme.offsets.lg};
    display: flex;
    align-items: center;
    margin: ${theme.offsets.sm};
    font-size: 14px;
    ::after{
      content: "";
      display: block;
      background: url(${plusIcon}) no-repeat center;
      background-size: contain;
      width: 20px;
      height: 20px;
      margin-left: ${theme.offsets.sm};
    }
  }
@media(max-width: ${theme.screens.mobile}) {
  button{
    padding-left: ${theme.offsets.sm};
    padding-right: ${theme.offsets.sm};
    font-size: 12px;
    ::after{
      width: 16px;
      height: 16px;
    }
  }
}   
`

export const LabelWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  span{
    color: ${theme.colors.darkGray};
    opacity: 0.6;
  }
`

export const DefaultBanner = styled('div')`
  display: flex;
  flex-direction: column;
  width: 261px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  svg{
    margin-bottom: 40px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 235px;
    svg{
      margin-bottom: ${theme.offsets.extraBig};
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 205px;
    svg{
      margin-bottom: ${theme.offsets.xxl};
    }
  }
`

export const SaveButtonWrap = styled('div')<{isLoading?: boolean, isSaved?: boolean}>`
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
  max-width: 137px;
  margin-left: auto;
  margin-right: 0;
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 93px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 105px;
    margin-top: ${theme.offsets.xxl};
  }
  ${props=>props.isSaved && `
    svg{
      margin-left: ${theme.offsets.sm}; 
    };
    div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}

  ${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`

export const FileInput = styled('div')`
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    color: ${theme.colors.lightOrange};
    padding: 0;
    height: fit-content;
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    input[type=file]::-webkit-file-upload-button {
    display: none;
  }
  }
`

export const UploadedFileField = styled('div')`
  display: flex;
  align-items: center;
  button{
    background: none;
    border: none;
    svg{
      width: 13px;
      height: 13px;
    }
  }
  span{
    display: block;
    margin: 0 ${theme.offsets.sm};
    font-size: 14px;
    line-height: 14px;
    @media(max-width: ${theme.screens.mobile}) {
      font-size: ${theme.fontSizes.md};
      line-height: ${theme.fontSizes.md};
    }
  }
`

export const OptionalBlocks = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    margin: -${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: -6px;
  }
`

export const OptionalItem = styled('div')`
  display: flex;
  align-items: center;
  width: calc(50% - ${theme.offsets.xxl});
  margin: ${theme.offsets.md};
  background-color: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  cursor: pointer;
  padding: ${theme.offsets.xl};
  svg{
    margin-right: ${theme.offsets.xl};
  }
  span{
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.lg};
    margin: ${theme.offsets.sm};
    width: calc(50% - ${theme.offsets.lg});
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.md};
    width: 100%;
    margin: 6px;
  }
`

export const HelperTextTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`