import styled from 'styled-components';
import { theme } from '../../../theme';

export const Wrapper = styled('div')`
  width: 100%;
  margin: ${theme.offsets.xxl} auto 0px;
  max-width: 606px;
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.lg} auto 0px;
  }
`

export const Title = styled('p')`
  color: ${theme.colors.black};
  font-size: 14px;
`

export const CheckboxWrap = styled('p')`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const TitleInModal = styled('p')`
  font-family: Publico;
  font-size: ${theme.fontSizes.big};
  color: ${theme.colors.black};
`

export const SubtitleInModal = styled('p')`
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.gray};
  margin-top: ${theme.offsets.sm};
`

export const CustomBtn = styled('div')`
margin-top: ${theme.offsets.xl};
button{
  width: 213px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.offsets.lg};
  font-weight: 600;
  font-size: 14px;
  }
`

export const BtnsWrap = styled('div')`
  display: flex;
  margin-top: 120px;
  button{
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.offsets.lg};
  }
`