import { colors } from '../../../constants/colors';
import { mainStyleType } from '../../../themes/mainStylesType';
import styled from 'styled-components';
import closeIcon from '../../../assets/img/close.svg'
import plusBlackIcon from '../../../assets/img/plusBlack.svg'
import { theme } from '../../../theme';

export const StyleForWrap = styled('div')`
{ 
  background: #7B9AAE;
  padding-bottom: 50px;
};
form{
  width: 848px;
  margin: 0 auto;
}
.currency-flag{
  margin-right: 8px;
}
.currency-description{
  color: #928D8A;
  margin-left: 4px;
}
`

export const StyleForTimeZone = styled('div')`

`

export const StyleForQuestion = styled('div')`
{ 
  background: #FFFFFF;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  width: 848px;
  margin-top: 40px;
  padding: 48px 40px;
  border: 2px solid #FFFFFF;
  .answer-wrap{
    margin-top: 24px;
  }
  &.error{
    border: 2px solid #FF7474;
  }
  .check-box-wrap{
    margin: 24px auto 0; 
    width:  606px;
  }
  .check-box-wrap .benefit-helper-text{
    color: ${colors.dark};
    font-size: 14px;
    line-height: 16px;
  }
  .inputs-wrap{
    width: 606px;
    margin: 38px auto 16px;
  }
  label .optional{
    color: #928D8A;
  }
  .secondLocation{
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #D1D7E3;
  }
};
`

export const StyleForSubtitle = styled('span') <mainStyleType>`
{ 
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #928D8A;
  margin: ${(props: mainStyleType) => props.m ? props.m : '0 0 12px'};
  display: flex;
  justify-content: space-between;
};
.optional{
  font-size: 16px;
  line-height: 18px;
  color: #928D8A;
}
`
export const StyleForTitle = styled('div')`
  font-family: Publico;
  font-size: 28px;
  color: ${colors.dark};
  display: flex;
  align-items: flex-start;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xl};
    margin: ${theme.offsets.md} 0;
  }
`

export const StyleForRemoveBtn = styled('span')`
{ 
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :after{
    content: '';
    display: flex;
    background-image: url(${closeIcon});
    background-repeat: no-repeat;
    background-position: center;
    width:16px; 
    height:16px;
    margin-left: 12px;
  }
};
`

export const StyleForBenefitBtn = styled('button')`
width: 213px;
  height: 52px;
  background: #F8DDC3;
  border-radius: 10px;
  border: none;
  padding: 18px 16px;
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${colors.dark};
  position: relative;
  text-align: left;
  margin-top: 20px;
  ::after{
    content: "";
    background-image: url('${plusBlackIcon}');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 20px;
    width: 20px;
    display: block; 
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0%, -50%);
  }
`

export const StyleForLabel = styled("label")`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:8px;
  text-transform: capitalize;
  width: 100%;
  color: ${colors.dark};
`

export const StyleForInputsPanel = styled('div')`
  max-width: 484px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const StyleForTabToggle = styled('div')`
  width: 412px;
  height: 50px;
  border-radius: 10px;
  margin: 40px auto 16px;
  display: flex;
  div{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D1D7E3;
    cursor: pointer;
  }
  div span{
    font-weight: 600;
    font-size: 14px;
    color: ${colors.dark}
  }
  div.active span{
    color: #fff;
  }
  div.active{
    background: #EA994B;
    border: 1px solid #EA994B;
  }
  .employer{
    border-radius: 10px 0 0 10px;
  }
  .staffing{
    border-radius: 0 10px 10px 0;
  } 
`

export const LocationWrapper = styled('div')`
  width: 606px;
  margin: 38px auto ${theme.offsets.lg};
  
  `
