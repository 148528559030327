import styled from "styled-components"
import { theme } from "../../../../../theme"

const CARD_SIZE_LARGE = '137px';
const CARD_SIZE_MEDIUM = '125px';
const CARD_SIZE_SMALL = '88px';

const ICON_SIZE_LARGE = '48px';
const ICON_SIZE_MEDIUM = '33px';
const ICON_SIZE_SMALL = '27px';

export const Wrapper = styled('div')`
  margin-top: ${theme.offsets.sm};
  display: flex;
  justify-content: center;
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
  }
`


export const UploadButton = styled('button') <{ isDesktop: boolean, isFileLoad: boolean }>`
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${CARD_SIZE_LARGE};
  width: ${CARD_SIZE_LARGE};
  border: 1px solid ${theme.colors.lightGray};
  background: #F6F8FA;
  border-radius: ${theme.variables.borderRadiusXLarge};
  font-size: 14px;
  color: #91959D;
  flex-direction: column;
  svg{
    height: ${ICON_SIZE_LARGE};
    object-fit: contain;
    margin-bottom: ${(props) => props.isFileLoad ? '0px' : theme.offsets.lg};
  }
  &:hover {
    background: #ebf0f4;
  }

  ${(props) => !props.isDesktop && `
    height: ${CARD_SIZE_MEDIUM};
    width: ${CARD_SIZE_MEDIUM};
    border-radius: ${theme.variables.borderRadiusLarge};
    font-size: ${theme.fontSizes.md};
    svg{
      height: ${ICON_SIZE_MEDIUM};
      margin-bottom: ${props.isFileLoad ? '0px' : theme.offsets.md};
    }
    @media(max-width: ${theme.screens.mobile}) {
      height: ${CARD_SIZE_SMALL};
      width: ${CARD_SIZE_SMALL};
      border-radius: ${theme.variables.borderRadiusSmallMedium};
      svg{
        height: ${ICON_SIZE_SMALL};
        margin-bottom: ${props.isFileLoad ? '0px' : theme.offsets.sm};
      }
    }   
  `}
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-family: 0;
  }
  input[type=file]::-webkit-file-upload-button {
    display: none;
}
`

export const PreloaderWrap = styled('div') <{ isFileLoad: boolean }>`
  height: ${ICON_SIZE_LARGE};
  width: ${ICON_SIZE_LARGE};
  margin-bottom: ${(props) => props.isFileLoad ? '0px' : theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}){
    height: ${ICON_SIZE_MEDIUM};
    margin-bottom: ${(props) => props.isFileLoad ? '0px' : theme.offsets.md};
  }
  @media(max-width: ${theme.screens.mobile}){
    height: ${ICON_SIZE_MEDIUM};
    margin-bottom: ${(props) => props.isFileLoad ? '0px' : theme.offsets.sm};
  }
`

export const DeleteButton = styled('button')`
  z-index: 2;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  svg {
    width: 8px;
    height: 8px;
  }
`

export const FieldWrap = styled('div')`
  flex: 1;
  margin-left: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    margin-left: ${theme.offsets.lg};
  }

  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
    margin-left: 0;
    width: 100%;
  }
`