import { theme } from "../../../../theme";
import styled from "styled-components";
import { CardinalOrientation } from "walktour";

export const TutorialTooltip = styled('div')<{isDesktop: boolean, trianglePosition: CardinalOrientation | undefined}>`
  max-width: 412px;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  background-color: ${theme.colors.white};
  padding: ${theme.offsets.xxl};
  width: 100%;
  display: flex;
  flex-direction: column;
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.lg};
    max-width: 300px;
  }
  &:before{
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${theme.colors.white};
    top: -11px;
    ${props=>props.isDesktop && props.trianglePosition !== "south" && props.trianglePosition !== "east-north" && `
      left: ${theme.offsets.extraBig};
    `}
    ${props=>props.isDesktop && props.trianglePosition === "east-north" && `
      left: -18px;
      top: ${theme.offsets.extraBig};
      transform: rotate(270deg);
    `}
    ${props=>!props.isDesktop && props.trianglePosition !== "south" && `
      right: ${theme.offsets.extraBig};
    `}
    ${props=>!props.isDesktop && props.trianglePosition === "south" && `
      right: 50%;
    `}
    }
`
export const TutorialTitle = styled('p')`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: ${theme.fontSizes.lg};
  font-family: 'Apercu Pro';
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
    margin-bottom: ${theme.fontSizes.sm};
  }
`

export const TutorialDescription = styled('p')`
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.darkGray};
  font-family: 'Apercu Pro';
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
  }
`

export const TutorialNextButton = styled('div')`
  max-width: 116px;
  width: 100%;
  button{
    height: 46px;
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
      max-width: 91px;
      button{ 
        height: 38px;
      }
  }
`

export const TutorialSkipButton = styled('span')`
  cursor: pointer;
  font-family: 'Apercu Pro';
  font-weight: 500;
  font-size: 14px;
  color: rgba(67, 62, 58, 0.5);
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
  }
`

export const TutorialButtonsWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${theme.offsets.xxl};
`