import styled from "styled-components";
import { theme } from "../../../../theme";

const MainButtonStyle = `
  width: 52px;
  height: 52px;
  border: none;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${theme.colors.white};
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    width: 44px;
    height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 40px;
    height: 40px;
  }
`

export const NavButton = styled('button')<{next?: boolean}>`
  ${MainButtonStyle}
  svg{
    ${(props)=>props.next && `
    transform: rotate(180deg);
    `}
  }
`;

export const Wrapper = styled('div')`
width: 100%;
.base-pagination-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.previous{
    margin-left: 0;
    margin-right: auto;
  }
.next{
  margin-left: auto;
  margin-right: 0;
}
.disabled svg{
  opacity: 0.5;
}
.disabled button, .disabled a{
  cursor: default;
}
.nav-item-wrap, .break{
  ${MainButtonStyle}
  color: ${theme.colors.black};
  margin: 0 ${theme.offsets.md};
  @media(max-width: ${theme.screens.desktop}) {
    margin: 0 ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.desktop}) {
    margin: 0 ${theme.offsets.xs};
  }
}
.selected .nav-item-wrap{
    background-color: ${theme.colors.blue};
    color: ${theme.colors.white};
  }
`