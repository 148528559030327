import { Checkbox } from "../Checkbox";
import { BenefitsSectionProps } from "./Benefits.props";
import { SPSecondaryButton } from "../buttons/SPSecondaryButton/SPSecondaryButton";
import { ReactComponent as PlusIcon } from '../../../assets/img/plusBlack.svg';
import { CustomBenefitsModal } from "./CustomBenefitsModal";
import { useState } from "react";
import { Wrapper, Title, CheckboxWrap, CustomBtn } from "./Benefits.present";

export const Benefits = (props: BenefitsSectionProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [benefitList, setBenefitList] = useState(props.benefitList);

  const isVolunteerBenefit = props.fieldName === 'volunteerAbout';

  return <Wrapper>
    <Title>{props.title}</Title>
    <CheckboxWrap>
      {benefitList.map((item: any, index: number) => <Checkbox
        key={index + item.value}
        labelText={item.title}
        name={props.fieldName}
        value={item.value}
        handleChange={props.handleChange}
        checked={props.initialValues[props.fieldKey || props.fieldName] && props.initialValues[props.fieldKey || props.fieldName].includes(item.value)}
        block
      />
      )}
    </CheckboxWrap>

    {props.withCustomBenefits && <div>
      <CustomBtn>
        <SPSecondaryButton onClick={() => setModalOpen(true)} width="auto" isLight type="button">
          <span>{isVolunteerBenefit ? 'Add more categories' : 'Add more benefits'}</span>
          <PlusIcon />
        </SPSecondaryButton>
      </CustomBtn>
      <CustomBenefitsModal setModalOpen={setModalOpen} isModalOpen={isModalOpen} fieldName={props.fieldName} setBenefitList={setBenefitList} benefitList={benefitList} />
    </div>}
  </Wrapper>
}