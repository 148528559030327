import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ErrorMsg } from "../../../../theme/typography";
import { SPSecondaryButton } from "../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { ReactComponent as CheckedGrey } from '../../../../assets/img/resumeBuilder/checkedGrey.svg';
import { ReactComponent as Spinner } from '../../../../assets/img/simpleSpinner.svg';
import { ShareEmailContainer, EmailButtonWrap, EmailFieldWrap } from "./ShareModal.presets";
import { sendShareResumeMessage } from "../../../../services/api/candidates/candidates.domains";
import { useRootStore } from "../../../../state/stores/storeContext";
import { Tags } from "../../../JobStoryBuilder/BasicComponents/Tags";

export const EmailInputField = ({
	shareUrl,
}: {
	shareUrl: string;
}) => {
	const { t } = useTranslation();
	const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const { resumeBuilderStore } = useRootStore();

	const initialValues = {
		email: [] as string[]
	};

	return <Formik
		enableReinitialize
		initialValues={initialValues}
		validateOnChange={isFirstSubmitComplete}
		validateOnBlur={false}
		validationSchema={
			Yup.object().shape({
				email: Yup.array()
					.of(
						Yup.string().email(t('validation.email'))
					)
					.min(1, 'Minimum 1 email')
					.max(10, 'Maximum 10 emails')
			})
		}
		onSubmit={async values => {
			setIsLoading(true);
			if (values.email.length) {
				const responses = await Promise.all(
					values.email.map(
						async (item) =>
							await sendShareResumeMessage({
								email: item,
								url: `http://${shareUrl}`,
								fullName: `${resumeBuilderStore.personalInfo.firstName} ${resumeBuilderStore.personalInfo.lastName}`
							})
					)
				);
				if (responses.length) {
					setIsSent(true);
				};
			};
			setIsLoading(false);
		}
		}
	>
		{({
			values,
			errors,
			handleSubmit,
			setFieldValue
		}) => <form onSubmit={handleSubmit}>
				<ShareEmailContainer>
					<EmailFieldWrap>
						<Field
							tags={values.email || []}
							setTegs={(tags: Array<string>) => { setFieldValue('email', tags) }}
							component={Tags}
							name={'email'}
							error={errors.email}
							placeholder={'Email, comma separated'}
							m={"0"}
						/>
						<ErrorMsg>{errors.email}</ErrorMsg>
					</EmailFieldWrap>
					<EmailButtonWrap isLoading={isLoading} isSent={isSent}>
						<SPSecondaryButton
							onClick={() => setIsFirstSubmitComplete(true)}
							type={'submit'}
							isLight={isSent}
							disabled={isSent}
						>
							{isLoading && <Spinner />}
							{isSent && <div>
								Sent <CheckedGrey />
							</div>}
							{(!isLoading && !isSent) && 'Send'}
						</SPSecondaryButton>
					</EmailButtonWrap>
				</ShareEmailContainer>
			</form>
		}
	</Formik >
};