import { JobStatus } from './Components/Mobile/MobileJobList.props';
import activeIcon from '../../../assets/img/activeStatus.svg';
import inactiveIcon from '../../../assets/img/inactiveStatus.svg';
import draftIcon from '../../../assets/img/draftStatus.svg';
import archivedIcon from '../../../assets/img/archivedStatus.svg';
import { Actions } from '../../common/Modals/TableActionsModal/TableActionsModal.props';
import { ActionsMenuType } from './JobsList.props';

export const IconsMap = {
  [JobStatus.active]: activeIcon,
  [JobStatus.archived]: archivedIcon,
  [JobStatus.inactive]: inactiveIcon,
  [JobStatus.draft]: draftIcon,
  [JobStatus.delete]: inactiveIcon,
}

export const setTabsForSorting = (typesLength?: { allJobs: number, activeJobs: number, inactiveJobs: number, draftJobs: number, archivedJobs: number }) => {
  return [
    { label: 'All', value: 'all', length: typesLength ? typesLength.allJobs : 0 },
    { label: 'Active', value: 'active', length: typesLength ? typesLength.activeJobs : 0 },
    { label: 'Inactive', value: 'inactive', length: typesLength ? typesLength.inactiveJobs : 0 },
    { label: 'Drafts', value: 'draft', length: typesLength ? typesLength.draftJobs : 0 },
    { label: 'Archive', value: 'archived', length: typesLength ? typesLength.archivedJobs : 0 },
  ]
}

export const getLastStep = (steps: any) => {
  const jobStoryScheame = [
    { stepKey: 'startStep', link: 'start' },
    { stepKey: 'jobStep', link: 'job' },
    { stepKey: 'descriptionStep', link: 'description' },
    { stepKey: 'specificationStep', link: 'specification' },
    { stepKey: 'contactStep', link: 'contact' }
  ]
  let lastStep = 'contact';
  for (const key of jobStoryScheame) {
    if (!steps[key.stepKey].answers) {
      return key.link;
    }
  }
  return lastStep;
}

export const mobileJobSortList = [
  { title: 'Job Title (A-Z)', data: { type: 'jobTitle', value: 'down' }, isChecked: false },
  { title: 'Job Title (Z-A)', data: { type: 'jobTitle', value: 'up' }, isChecked: false },
  { title: 'Created (From Newest To Oldest)', data: { type: 'creationDate', value: 'up' }, isChecked: false },
  { title: 'Created (From Oldest To Newest)', data: { type: 'creationDate', value: 'down' }, isChecked: false },
  { title: 'Update (From Newest To Oldest)', data: { type: 'updatingDate', value: 'up' }, isChecked: false },
  { title: 'Update (From Oldest To Newest)', data: { type: 'updatingDate', value: 'down' }, isChecked: false },
];

export const getSortButtonClass = (sortData: { type: string, value: string }, headSortType: string) => {
  const isSortRevert = sortData.type === headSortType && sortData.value === 'up';
  return `sort-btn ${isSortRevert ? 'revert' : ''}`;
};

export const setActionsForJob = (props: ActionsMenuType) => {
  const { jobListItem, handleOpenModal, handleShareJob, handleEditJob, history } = props;
  const openActionsForActive = jobListItem.isBuildCompleted && (jobListItem.jobStatus === 'active' || jobListItem.jobStatus === 'published');
  const openActionsForDraft = jobListItem.isBuildCompleted && (jobListItem.jobStatus === 'inactive' || jobListItem.jobStatus === 'draft');
  return [
    {
      title: 'Share',
      callback: () => handleShareJob(jobListItem._id),
      show: openActionsForActive
    },
    {
      title: 'Preview',
      callback: () => history.push(`/job-preview?id=${jobListItem._id}`),
      show: openActionsForActive
    },
    {
      title: 'Public View',
      callback: () => window.open(`/jobs?id=${jobListItem._id}`, "_blank"),
      show: openActionsForActive
    },
    {
      title: 'Deactivate',
      callback: () => handleOpenModal(Actions.inactive, jobListItem),
      show: openActionsForActive
    },
    {
      title: jobListItem.jobStatus === 'draft' ? 'Publish' : 'Activate',
      callback: () => handleOpenModal(Actions.active, jobListItem),
      show: openActionsForDraft
    },
    {
      title: 'View Candidates',
      callback: () => history.push(`/candidates?id=${jobListItem._id}`),
      show: jobListItem.isBuildCompleted
    },
    {
      title: 'Unarchive',
      callback: () => handleOpenModal(jobListItem.isBuildCompleted ? Actions.active : Actions.draft, jobListItem),
      show: jobListItem.jobStatus === 'archived'
    },
    {
      title: jobListItem.isBuildCompleted ? 'Edit' : 'Сontinue creating',
      callback: () => handleEditJob(jobListItem._id, jobListItem.steps),
      show: true
    },
    {
      title: 'Duplicate',
      callback: () => handleOpenModal(Actions.duplicate, jobListItem),
      show: true
    },
    {
      title: jobListItem.jobStatus === 'archived' ? 'Delete' : 'Archive',
      callback: () => handleOpenModal(jobListItem.jobStatus === 'archived' ? Actions.delete : Actions.archived, jobListItem),
      show: true,
      isDeleteBtn: true
    }
  ]
}