export type propertyForModal = {
  typeOfModal: Actions,
  setShowModal: Function,
  showModal: boolean,
  activePage: number,
  activeTab: string,
  jobId: string,
  oldStatus: string,
  sortData: { type: string, value: string }
  modalForCandidates?: boolean
  candidateId?: string
  handleSetActiveTab: Function
}

export enum Actions { 'delete', 'archived', 'active', 'draft', 'inactive', 'duplicate' }