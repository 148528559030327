import { ItemWrap, Title, Description, CountNumber } from "./Benefits.presents";

export const BenefitItem = ({ icon, title, description, index }: { icon: JSX.Element, title: string, description: string, index: number }) => {
  return <ItemWrap>
    
    {icon}
    <CountNumber>{`0${index + 1}`}</CountNumber>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </ItemWrap>
}