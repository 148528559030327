import { StyleFortabs, TabItem, IconWrap, Title, Count } from "./OrganizationTabs.presents";
import { IconsList } from "../../Table/Table.icon";
import { OrganizationTabsProps } from "./OrganizationTabs.props";

export const OrganizationTabs = (props: OrganizationTabsProps) => {
  const { tabsData, isDesktop, callback, activeTab } = props;
  return <StyleFortabs isDesktop={isDesktop}>
    {tabsData.map((item, index) => item.show ? <TabItem isActive={activeTab === item.value} isDesktop={isDesktop} key={index} onClick={() => callback(item.value)}>
      <IconWrap>
        <IconsList isActive={activeTab === item.value} iconType={item.value} />
      </IconWrap>
      <Title isActive={activeTab === item.value}>{item.label}</Title>
      {item?.length!==0 && item?.length && (
        <Count isActive={activeTab === item.value} >({item?.length})</Count>
      )}
    </TabItem> :
      null
    )}
  </StyleFortabs>
}