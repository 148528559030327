import { useRootStore } from "../../../../../state/stores/storeContext";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import {
  Container,
  SocialSkillsBlock,
  SummaryText,
  TechnicalSkillsBlock,
  SkillsContainer,
  TechnicalSkillsContainer,
  AboutCandidateText,
  AboutCandidateContainer,
  AboutCandidateSection,
  SkillsSection,
  ExpertisesContainer,
  ExpertisesSkillsContainer,
  ExpertisesContainerHeader,
  ExpertisesContainerHeaderIconWrapper
} from "./MainInformation.presents";
import { ReactComponent as ExpertisesIcon } from '../../../../../assets/img/resume/tickBackgroundWavy.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../../../assets/img/resume/questionMark.svg';
import { ReactComponent as EducationIcon } from '../../../../../assets/img/resume/education.svg';
import { ReactComponent as CertificationIcon } from '../../../../../assets/img/resume/certification.svg';
import { ReactComponent as CoursesIcon } from '../../../../../assets/img/resume/courses.svg';
import { ReactComponent as VolunteerIcon } from '../../../../../assets/img/resume/volunteer.svg';
import { ReactComponent as PublicationIcon } from '../../../../../assets/img/resume/publications.svg';
import { ReactComponent as PortfolioIcon } from '../../../../../assets/img/resume/portfolio.svg';
import { ReactComponent as MembershipIcon } from '../../../../../assets/img/resume/membership.svg';
import { observer } from "mobx-react-lite";
import { Subtitle, Title } from "../../Resume.presents";
import { useMemo, useState } from "react";
import { EmploymentHistorySection, MainHistorySection } from "./helper";
import { Preferences } from "./Preferences";
import { VideoPlayerWrap } from "../ContactInformation/ContactInformation.presents";
import { VideoPlayer } from "../../../BaseComponents/VideoPlayer/VideoPlayer";
import { PlayerWrap } from "../../../VideoCV/VideoCV.presents";

export type RestructuredEmploymentData = {
  name: string;
  data: Array<{
    position?: string;
    employmentType?: string;
    startDate?: string;
    endDate?: string;
    description?: string;
  }>;
};

export const MainInformation = observer(({
  isDesktop,
  resume,
  preferences,
  expertises
}: {
  isDesktop: boolean;
  resume: any,
  preferences: any,
  expertises?: { area: string; skills: string[]; }[]
}) => {
  const getRestructuredEmploymentHistory = () => {
    const restructuredData: Array<RestructuredEmploymentData> = [];
    if (resume.employmentHistory) {
      resume.employmentHistory.forEach((item: any) => {
        const currentJobIndex = restructuredData.findIndex(element => element.name === item.employerName);
        const jobData = {
          position: item.position,
          employmentType: item.employmentType,
          startDate: item.startDate,
          endDate: item.endDate,
          description: item.description,
        };
        if (currentJobIndex >= 0) {
          restructuredData[currentJobIndex].data.push(jobData);
        } else {
          restructuredData.push({
            name: item.employerName,
            data: [jobData]
          });
        }
      });
    }
    return restructuredData;
  };

  const restructuredEmploymentHistory = getRestructuredEmploymentHistory();
  const [playerWrapperWidth, setPlayerWrapperWidth] = useState<number | undefined>();

  const showSocialSkills = resume.socialSkills && resume.socialSkills.length > 0;
  const showTechnicalSkills = resume.technicalSkills && resume.technicalSkills.length > 0;
  const showAboutCandidate = resume.aboutCandidate && resume.aboutCandidate.length > 0;
  const showEducation = resume.education && resume.education.length > 0;
  const showCertification = resume.certifications && resume.certifications.length > 0;
  const showTrainings = resume.trainings && resume.trainings.length > 0;
  const showNonprofit = resume.nonprofit && resume.nonprofit.length > 0;
  const showPublication = resume.publication && resume.publication.length > 0;
  const showMembership = resume.membership && resume.membership.length > 0;
  const showPortfolio = resume.portfolio && resume.portfolio.length > 0;
  const showEmploymentHistory = restructuredEmploymentHistory.length > 0;
  const isExpertisesOnlyOther = expertises?.length === 1 && expertises[0].area === "Other";
  const showExpertises = expertises && expertises.length > 0 && !isExpertisesOnlyOther;

  const interestsText = resume.interests;
  const summaryText = resume.personalInfo && resume.personalInfo.summary;
  const showSkillsSection = showSocialSkills || showTechnicalSkills;


  const renderSocialSkills = useMemo(() => <SkillsContainer>
    {resume.socialSkills && resume.socialSkills.map((item: string, index: number) => <SocialSkillsBlock key={`${index}SocialSkills`}>
      {item}
    </SocialSkillsBlock>)}
  </SkillsContainer>, []);

  const renderTechnicalSkills = useMemo(() => <TechnicalSkillsContainer>
    {resume.technicalSkills && resume.technicalSkills.map((item: string, index: number) => <TechnicalSkillsBlock key={`${index}TechnicalSkills`}>
      {item}
    </TechnicalSkillsBlock>)}
  </TechnicalSkillsContainer>
    , []);

  const renderExpertises = useMemo(() => expertises?.map((item: any, index: number) => {
    if(item.area === "Other") return null;
    return <ExpertisesContainer key={`${index}${item.area}`}>
      <ExpertisesContainerHeader>
        <ExpertisesContainerHeaderIconWrapper>
          <ExpertisesIcon />
        </ExpertisesContainerHeaderIconWrapper>
        <Subtitle>{item.area}</Subtitle>
      </ExpertisesContainerHeader>
      <ExpertisesSkillsContainer>
        {item.skills.map((subItem: string, subIndex: number) =>
          <TechnicalSkillsBlock key={`${subIndex}${subItem}`}>
            {subItem}
          </TechnicalSkillsBlock>
        )}
      </ExpertisesSkillsContainer>
    </ExpertisesContainer>
  }
  )
    , []);

  const renderAboutCandidate = useMemo(() => {
    const aboutCandidateCopy = [...resume.aboutCandidate || ''];
    if (!!resume.aboutCandidateMainAnswer) {
      const element = aboutCandidateCopy.splice(Number(resume.aboutCandidateMainAnswer), 1)[0];
      aboutCandidateCopy.splice(0, 0, element);
    };

    return aboutCandidateCopy.map((item, index) => <AboutCandidateContainer isFirstItem={index === 0} isLastItem={(index + 1) === aboutCandidateCopy.length} key={`${index}AboutCandidate`}>
      <QuestionMarkIcon />
      <div>
        <Subtitle>
          {item?.question}
        </Subtitle>
        <AboutCandidateText>{item?.answer}</AboutCandidateText>
      </div>
    </AboutCandidateContainer>)
  }, [])


  return <Container isDesktop={isDesktop}>

    {(isDesktop && resume.videoResume && resume.videoResume.url) && <VideoPlayerWrap isDesktop>
      <PlayerWrap ref={el => {
        if (!el) return;
        setPlayerWrapperWidth(el.getBoundingClientRect().width);
      }
      } isResumeBuilder playerWrapperWidth={playerWrapperWidth}>
        <VideoPlayer dataSrc={resume.videoResume.url} />
      </PlayerWrap>
    </VideoPlayerWrap>}

    {!!summaryText && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Summary</Title>
      <SummaryText>
        {summaryText}
      </SummaryText>
    </CandidateFormCard>}

    <CandidateFormCard isDesktop={isDesktop}>
      <Title>My Job Preferences</Title>
      <Preferences preferences={preferences} />
    </CandidateFormCard>

    {showExpertises && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Remote Work Expertise</Title>
      <SkillsSection>
          {renderExpertises}
      </SkillsSection>
    </CandidateFormCard>}



    {showSkillsSection && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Skills</Title>
      <SkillsSection>
        {showTechnicalSkills && <div>
          <Subtitle>Tehcnical Skills</Subtitle>
          {renderTechnicalSkills}
        </div>}

        {showSkillsSection && <div>
          <Subtitle>Social and Business Skills</Subtitle>
          {renderSocialSkills}
        </div>}
      </SkillsSection>
    </CandidateFormCard>}

    {resume.employmentHistory && resume.employmentHistory.length > 0 && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Employment History</Title>
      {restructuredEmploymentHistory.map((item, index) => <div key={`${index}Employment`}>
        {<EmploymentHistorySection
          isFirstItem={index === 0}
          employmentItem={item}
          isLastItem={(index + 1) === restructuredEmploymentHistory.length}
        />}
      </div>)}
    </CandidateFormCard>}

    {showEducation && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Education</Title>
      {resume.education.map((item: any, index: number) => <div key={`${index}Education`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.education.length}
          icon={<EducationIcon />}
          title={item.institutionName}
          description={item.description}
          startDate={item.startDate}
          endDate={item.endDate}
          subtitle={item.degree}
          isSingleDate={false}
        />}
      </div>)}
    </CandidateFormCard>}

    {!!interestsText && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Interests</Title>
      <SummaryText>
        {interestsText}
      </SummaryText>
    </CandidateFormCard>}

    {showAboutCandidate && <CandidateFormCard isDesktop={isDesktop}>
      <Title>More about me</Title>
      <AboutCandidateSection>
        {renderAboutCandidate}
      </AboutCandidateSection>
    </CandidateFormCard>}

    {showCertification && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Licenses and Certifications</Title>
      {resume.certifications.map((item: any, index: number) => <div key={`${index}Certification`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.certifications.length}
          icon={<CertificationIcon />}
          title={item.title}
          description={item.description}
          startDate={item.date}
          subtitle={item.institution}
          isSingleDate={true}
          file={item.file}
          fileDownloadLabel={"License/Certification"}
        />}
      </div>)}
    </CandidateFormCard>}

    {showTrainings && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Trainings and Courses</Title>
      {resume.trainings.map((item: any, index: number) => <div key={`${index}Trainings`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.trainings.length}
          icon={<CoursesIcon />}
          title={item.title}
          description={item.description}
          startDate={item.startDate}
          endDate={item.endDate}
          subtitle={item.name}
          isSingleDate={false}
        />}
      </div>)}
    </CandidateFormCard>}

    {showNonprofit && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Volunteer Experience</Title>
      {resume.nonprofit.map((item: any, index: number) => <div key={`${index}Nonprofit`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.nonprofit.length}
          icon={<VolunteerIcon />}
          title={item.employer}
          description={item.description}
          startDate={item.startDate}
          endDate={item.endDate}
          subtitle={item.position}
          isSingleDate={false}
        />}
      </div>)}
    </CandidateFormCard>}

    {showPublication && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Publications</Title>
      {resume.publication.map((item: any, index: number) => <div key={`${index}Publication`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.publication.length}
          icon={<PublicationIcon />}
          title={item.title}
          description={item.description}
          startDate={item.date}
          subtitle={item.institution}
          isSingleDate={true}
        />}
      </div>)}
    </CandidateFormCard>}

    {showPortfolio && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Portfolio</Title>
      {resume.portfolio.map((item: any, index: number) => <div key={`${index}Portfolio`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.portfolio.length}
          icon={<PortfolioIcon />}
          title={item.title}
          description={item.description}
          isSingleDate={false}
          file={item.file}
          fileDownloadLabel={"Portfolio"}
        />}
      </div>)}
    </CandidateFormCard>}

    {resume.membership && resume.membership.length > 0 && <CandidateFormCard isDesktop={isDesktop}>
      <Title>Membership</Title>
      {resume.membership.map((item: any, index: number) => <div key={`${index}Membership`}>
        {<MainHistorySection
          isFirstItem={index === 0}
          isLastItem={(index + 1) === resume.membership.length}
          icon={<MembershipIcon />}
          title={item.organizationName}
          description={item.description}
          startDate={item.startDate}
          endDate={item.endDate}
          subtitle={item.position}
          isSingleDate={false}
        />}
      </div>)}
    </CandidateFormCard>}
  </Container>
});
