import { StyleForModal } from '../Introduction/Introduction.presents';
import { PropsErrorWindowType } from './Modals.props';
import closeIcon from '../../../../assets/img/close.svg';
import errorBanner from '../../../../assets/img/jobStory/error-banner.svg';

export const ErrorModal = (props: PropsErrorWindowType) => {
  return <StyleForModal show={props.openWindow} onHide={props.handleCloseWindow} centered className='pl-0'>
    <img className='close-btn' src={closeIcon} alt="close" onClick={() => props.handleCloseWindow()} />
    <div className='error-content'>
      <img src={errorBanner} alt="banner" />
      <p className='title'>{props.title}</p>
      <p className='text'>{props.text}</p>
    </div>
  </StyleForModal>
}