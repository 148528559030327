import {
  MediaModalWrapper,
  ContactMeHeader,
  Title,
  CloseButton,
  ButtonWrap,
  PolicyLink,
  PolicyText,
} from "./ContactMeModal.presents";
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';
import { ErrorMsg, FieldWrap } from "../../../../theme/typography";
import { Field, Formik } from "formik";
import { Input } from "../../../common/Input";
import * as Yup from 'yup';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { sendContactMeMessage } from "../../../../services/api/candidates/candidates.domains";
import { useRootStore } from "../../../../state/stores/storeContext";

export const ContactMeModal = ({
  isOpen,
  onClose,
  emailTo,
}: {
  isOpen: boolean;
  onClose: () => void;
  emailTo: string;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { resumeBuilderStore, candidateStore } = useRootStore();
  return <MediaModalWrapper centered show={isOpen} onHide={onClose} >
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
    <MediaModalWrapper.Body>
      <ContactMeHeader>
        <Title>Contact Me</Title>
      </ContactMeHeader>
      <Formik
        enableReinitialize
        initialValues={{
          fullName: '',
          email: '',
          message: '',
        }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email(t('validation.email')).required(t('validation.required')),
            fullName: Yup.string().required(t('validation.required')),
            message: Yup.string().required(t('validation.required')),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          await sendContactMeMessage({
            fullName: values.fullName,
            firstName: resumeBuilderStore.personalInfo.firstName,
            emailTo,
            emailFrom: values.email,
            message: values.message
          });
          setIsLoading(false);
          onClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => {
          return <form onSubmit={handleSubmit}>
            <FieldWrap>
              <Field
                as={Input}
                name={'fullName'}
                type={'text'}
                value={values.fullName}
                onChange={handleChange}
                maxLength={50}
                labelText={'Full Name'}
                error={errors.fullName} />
              <ErrorMsg>{errors.fullName}</ErrorMsg>
            </FieldWrap>


            <FieldWrap>
              <Field
                as={Input}
                name={'email'}
                type={'text'}
                value={values.email}
                onChange={handleChange}
                maxLength={50}
                labelText={'Email Address'}
                error={errors.email} />
              <ErrorMsg>{errors.email}</ErrorMsg>
            </FieldWrap>

            <FieldWrap>
              <Field
                as={Input}
                name={'message'}
                type={'textarea'}
                value={values.message}
                onChange={handleChange}
                maxLength={500}
                labelText={'Your Message'}
                error={errors.message} />
              <ErrorMsg>{errors.message}</ErrorMsg>
            </FieldWrap>

            <ButtonWrap>
              <SPPrimaryButton width="100%" type="submit" disabled={isLoading} onClick={()=>setIsFirstSubmitComplete(true)}>
                Send Message
              </SPPrimaryButton>
            </ButtonWrap>
          </form>
        }}
      </Formik>

      <PolicyText>
        By sending, I agree to Sidepost's
        <PolicyLink
          to={{ pathname: 'https://sidepost.com/terms-of-service' }}
          target="_blank">
          {` Terms of Service`}
        </PolicyLink>
        {` and `}
        <PolicyLink
          to={{ pathname: 'https://sidepost.com/privacy-policy' }}
          target="_blank">
          Privacy Policy
        </PolicyLink>
      </PolicyText>

    </MediaModalWrapper.Body>
  </MediaModalWrapper>
};