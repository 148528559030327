import { PropsType } from "./FourthStep.props";
import { Text } from "../../../../../common/Text";
import { Title } from "../../../../../common/Title";
import { useState } from "react";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import { useTranslation } from "react-i18next";
import { StepWrap, TextDescription, HelperText } from "./FourthStep.presents";
import { ButtonWrap } from "../FirstStep/FirstStep.presents";
import { SPBaseButton } from "../../../../../common/buttons/SPBaseButton";
import { SPLightButton } from "../../../../../common/buttons/SPLightButton/SPLightButton";
import { BackButtonWrap } from "../SecondStep/SecondStep.presents";
import { resendEmailCode } from "../../../../../../services/api/employerSignUp/employerSignUp.domains";

export const FourthStep = (props: PropsType) => {
  const [serverError, setServerError] = useState('');
  const [serverResponse, setServerResponse] = useState('');
  const { t } = useTranslation()

  const { userStore } = useRootStore()
  const resendConfirmation = async () => {
    const dataFromServer = await resendEmailCode({ verificationId: userStore.verificationId, email: userStore.email })
    setServerError(dataFromServer.data.err)
    if (dataFromServer.data.err) {
      setServerError(dataFromServer.data.err);
    }else{
      setServerResponse('Confirmation is resent')
    }
  }

  return <StepWrap>
    <Title text={t('signUp.fourthStepTitle')} />
    <TextDescription>{t('signUp.fourthStepText', { email: userStore.email })}</TextDescription>
    <ButtonWrap>
      <SPBaseButton onClick={() => { resendConfirmation() }} width={'100%'} type="submit">{t('signUp.resendEmail')}</SPBaseButton>
      <BackButtonWrap>
        <SPLightButton width={'100%'} type="button" onClick={() => props.previousStep()}>Back</SPLightButton>
      </BackButtonWrap>
    </ButtonWrap>
    {serverError && <HelperText isError>{serverError}</HelperText>}
    {serverResponse && <HelperText>{serverResponse}</HelperText>}
  </StepWrap>
}