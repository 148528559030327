import { theme } from '../../../theme/theme';
import styled from 'styled-components';


export const StyleForWrap = styled('div')`
  width:95%;
  max-width: 1680px;
  margin: 0 auto; 
  @media(max-width: ${theme.screens.mobile}) { 
  margin: 0 -${theme.offsets.lg};
  width: auto;
}   
`

export const Content = styled('div')`
  max-width: 1680px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding:  ${theme.offsets.lg} ;
`

export const StyleForFollow = styled('div')`
box-sizing: border-box;
display: flex;
justify-content: center;
width: 52px;
height: 52px;
border: 1px solid #E2E3E5;
border-radius: 120px;
margin-right: ${theme.offsets.md};
margin-bottom: ${theme.offsets.md};
`

export const StyleForButtons = styled('div')`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`