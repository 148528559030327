import { Wrapper, VideoPreview, TimerWrapper, InfoBlock, ModalWrap, CloseWindowBtn, WindowTitle, TutorialInfoBlock } from './VideoRecord.presents';
//@ts-ignore
import VideoRecorder from 'react-video-recorder';
import { Actions } from './CustomActions';
import { Timer } from './Timer';
import { useEffect, useRef, useState } from 'react';
import { getBlobDuration } from '../../../../services/helper';
import { SPPrimaryButton } from '../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg'
import useGetDevice from '../../../../hooks/useGetDevice';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { v4 as uuid } from 'uuid';
import { SPSecondaryButton } from '../../../common/buttons/SPSecondaryButton/SPSecondaryButton';

export const VideoRecord = (
  {
    isResumeBuilder,
    isWindowOpen,
    isSimplifiedTutorial,
    setIsWindowOpen,
    setUploadedFile,
  }: {
    isResumeBuilder?: boolean,
    isSimplifiedTutorial?: boolean,
    isWindowOpen: boolean,
    setIsWindowOpen: Function,
    setUploadedFile: Function
  }) => {
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [videoFile, setVideoFile] = useState<any>(null);
  const [videoDuration, setVideoDuration] = useState('');
  const [isTutorialSkipped, setIsTutorialSkipped] = useState(false);
  const isDesktop = useGetDevice();

  const recordingRef: any = useRef(null);

  useEffect(() => {
    (async () => {
      if (videoFile) {
        const duration: any = await getBlobDuration(videoFile);
        const videoDuration = Math.floor(duration);
        const durationString = videoDuration < 10 ? '0' + videoDuration : videoDuration;
        setVideoDuration(`00:${durationString}`);
      }
    })()
  }, [videoFile])

  const saveVideoHandler = () => {
    setUploadedFile(videoFile);
    setIsWindowOpen(false);
  }

  return (<ModalWrap
    show={isWindowOpen}
    onHide={() => setIsWindowOpen(false)}
    fullscreen={!isDesktop} >

    <CloseWindowBtn isDesktop={isDesktop} onClick={() => setIsWindowOpen(false)}>
      <CloseIcon />
    </CloseWindowBtn>
    <WindowTitle isDesktop={isDesktop}>{isTutorialSkipped ? 'Record video' : 'Tutorial video'}</WindowTitle>

    <Wrapper>
      <VideoPreview isDesktop={isDesktop}>
        {/* Tutorial Block */}
        {!isTutorialSkipped && <VideoPlayer dataSrc='https://sidepost-app-files-dev.s3.amazonaws.com/videos/Video+Resume+Tutorial.mp4#t=0.001' />}

        {/* Recording Block */}
        {isTutorialSkipped && <VideoRecorder
          ref={recordingRef}
          replayVideoAutoplayAndLoopOff
          isReplayVideoMuted={false}
          constraints={{
            audio: true,
            video: true
          }}
          onRecordingComplete={async (videoBlob: any) => {
            setIsVideoRecording(false);
            videoBlob.name = uuid() + ".webm";
            setVideoFile(videoBlob);
          }}
          countdownTime={0}
          showReplayControls
          isOnInitially
          timeLimit={60000}
          onStartRecording={() => setIsVideoRecording(true)}
          onStopReplaying={() => setVideoFile(null)}
          renderActions={(r: any) => <Actions
            {...r}
            isDesktop={isDesktop}
            recordingRef={recordingRef}
            saveVideoHandler={saveVideoHandler}
            isResumeBuilder={isResumeBuilder}
          />}
        />}
      </VideoPreview>

      {/* Recording Actions */}
      {isTutorialSkipped && <InfoBlock isDesktop={isDesktop} isResumeBuilder={isResumeBuilder}>
        {isVideoRecording && <Timer timeLimit={60} />}
        {videoFile && <TimerWrapper> {videoDuration} </TimerWrapper>}
        {isDesktop && <SPPrimaryButton type='button' onClick={saveVideoHandler} disabled={!videoFile} width='100%'>
          Save video
        </SPPrimaryButton>}
      </InfoBlock>}

      {!isSimplifiedTutorial && !isTutorialSkipped && <TutorialInfoBlock isDesktop={isDesktop}>
        {isResumeBuilder ? <SPSecondaryButton type='button' onClick={() => setIsTutorialSkipped(true)} width='100%'>
          Start recording
        </SPSecondaryButton>
          : <SPPrimaryButton type='button' onClick={() => setIsTutorialSkipped(true)} width='100%'>
            Start recording
          </SPPrimaryButton>}
      </TutorialInfoBlock>}


    </Wrapper>
  </ModalWrap>
  )
}
