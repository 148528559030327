import * as React from "react"
import { DesktopOnlyFeatureModalProps } from "./DesktopOnlyFeatureModal.props"
import { MediaModalWrapper, ModalHeader, CloseButton, Content, Text } from "./DesktopOnlyFeatureModal.presets"
import { observer } from "mobx-react-lite";

import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';
import { ReactComponent as InfoImage } from './images/error.svg';
import { SPSecondaryButton } from "../../buttons/SPSecondaryButton/SPSecondaryButton";

export const DesktopOnlyFeatureModal: React.FC<DesktopOnlyFeatureModalProps> = observer((props) => {

  return (
    <MediaModalWrapper show={props.isOpen} onHide={props.onClose} centered>
      <MediaModalWrapper.Body>  
        <ModalHeader>
          <CloseButton onClick={props.onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <Content>
          <InfoImage />
          <Text>This feature is currently not available on mobile version. Please login via desktop website to post jobs. Thank you!</Text>
          <SPSecondaryButton onClick={props.onClose}>OK</SPSecondaryButton> 
        </Content>
      </MediaModalWrapper.Body>
    </MediaModalWrapper>
  )
})
