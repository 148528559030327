import { observer } from 'mobx-react-lite';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { OrgProps } from '../Organization.props';
import { BenefitItem, BenefitItemCol, BenefitItemRow, BenefitSeparator, BenefitSubtitle } from '../../JobStory/BasicComponents/JobBenefits/JobBenefits.presents';
import { Container } from 'react-bootstrap';
import { iconMap } from '../../JobStory/BasicComponents/JobBenefits/JobBenefits.icons';
import { useTranslation } from 'react-i18next';
import { benefitsCount } from '../BenefitsPreview/helper';
import { EmptyBenefits } from './EmptyBenefits';

const benefitsGroups = {
    'healthcare': {tile:'Healthcare and wellness insurance benefits:', icon:'healthcareOffered'},
    'wellbeingPerks': {tile:'Healthcare and wellness savings:',icon:'savingsOffered'},
    'retirement': {tile:'Retirement savings benefits:',icon:'retirementSavingsOffered'},
    'vacation': {tile:'Vacation and paid leave benefits:',icon:'vacationOffered'},
    'learning': {tile:'Learning opportunities or career development benefits:',icon:'learningOpportunitiesOffered'},
    'travel': {tile:'Office, commute and travel benefits and perks:',icon:'officeOffered'},
    'retirementUS': {tile:'Retirement savings benefits:',icon:'retirementSavingsOffered'},
}

export const Benefits = observer(({ organizationData, isDesktop }: OrgProps) => {
   

    return (<OrganizationPreviewCard isDesktop={isDesktop}>
        {benefitsCount(organizationData.benefits) > 0 ?
            <>
                {
                    Object.keys(benefitsGroups).map((category, index) => {
                        {
                            if (organizationData.benefits[category] && organizationData.benefits[category].length > 0) {
                                return (
                                    <>
                                        {index!==0&&(
                                            <BenefitSeparator />
                                        )}
                                        <BenefitsGroup
                                            title={benefitsGroups[category as keyof typeof benefitsGroups].tile}
                                            category={category}
                                            benefits={organizationData.benefits[category]} />
                                    </>)
                            }
                        }
                    })

                }
            </>
            :
            <EmptyBenefits />
        }
    </OrganizationPreviewCard>)
})


interface BenefitsGroupProps {
    title?: string
    category?: string
    benefits?: any

}


const BenefitsGroup = ({ title, category, benefits }: BenefitsGroupProps) => {
    const { t } = useTranslation();
    return <>
        <BenefitSubtitle>{title}</BenefitSubtitle>
        <BenefitItemRow>
            <Container fluid className='d-flex flex-wrap'>

                {benefits.map((benefit: string) => {
                    {
                        return <>
                            <BenefitItemCol md={4}>
                                <BenefitItem>
                                    <img src={iconMap[benefitsGroups[category as keyof typeof benefitsGroups].icon][benefit]} alt="icon" />
                                    {t(`jobStory.${benefitsGroups[category as keyof typeof benefitsGroups].icon}.${benefit}`, benefit)}
                                </BenefitItem>
                            </BenefitItemCol>
                        </>
                    }
                })
                }
            </Container>
        </BenefitItemRow>
    </>
}
