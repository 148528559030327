import { Field, FieldArray, Formik } from "formik";
import { ChangeEvent, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, LabelWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialReferences } from "../../../../../state/stores/resumeBuilder";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const References = ({
  isDesktop,
  closeBlockHandler,
  componentRef
}: {
  isDesktop: boolean;
  closeBlockHandler: () => void;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  const initialValues = { references: !!resumeBuilderStore.references.length ? resumeBuilderStore.references : [initialReferences] };

  return <CandidateFormCard
    isDesktop={isDesktop}
    hasCloseButton
    componentRef={componentRef}
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'References'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            references: Yup.array()
              .of(
                Yup.object().shape({
                  fullName: Yup.string().required(t('validation.required')),
                  email: Yup.string().email(t('validation.email')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveReferences(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="references">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const validPhoneHandler = (event: ChangeEvent<HTMLInputElement>) => {
                  const letters = /^[0-9()+]+$/;
                  const value = event.target.value;
                  if (value.match(letters) || value === '') {
                    onChangeHandler(event);
                  }
                }

                return <div>
                  {values.references.map((item: any, index: number) => {
                    const arrayErrors: any = errors.references;
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`references${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional reference</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`references.${index}.fullName`}
                          type={'text'}
                          value={values.references[index].fullName}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Reference’s Full Name'}
                          error={mainValidationCondition('fullName')} />
                        <ErrorMsg>{mainValidationCondition('fullName') ? arrayErrors[index].fullName : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`references.${index}.company`}
                          type={'text'}
                          value={values.references[index].company}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={<LabelWrap>Company<span>optional</span></LabelWrap>}
                          error={mainValidationCondition('company')} />
                        <ErrorMsg>{mainValidationCondition('company') ? arrayErrors[index].company : ''}</ErrorMsg>
                      </FieldWrap>

                      <MultiFieldWrap>
                        <MultiField>
                          <FieldWrap>
                            <Field
                              as={Input}
                              name={`references.${index}.email`}
                              type={'text'}
                              value={values.references[index].email}
                              onChange={onChangeHandler}
                              maxLength={50}
                              labelText={'Email Address'}
                              error={mainValidationCondition('email')} />
                            <ErrorMsg>{mainValidationCondition('email') ? arrayErrors[index].email : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                        <MultiField>
                          <FieldWrap>
                            <Field
                              as={Input}
                              name={`references.${index}.phone`}
                              type={'text'}
                              value={values.references[index].phone}
                              onChange={validPhoneHandler}
                              maxLength={18}
                              labelText={<LabelWrap>Phone Number<span>optional</span></LabelWrap>}
                              error={mainValidationCondition('phone')} />
                            <ErrorMsg>{mainValidationCondition('phone') ? arrayErrors[index].phone : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                      </MultiFieldWrap>

                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialReferences)}>
                      Add Another Reference
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveReferences({ references: [] }, candidateStore.uid);
                await resumeBuilderStore.setReferences([]);
                handleReset();
                closeBlockHandler();
              }}
            />}
          </form>}
      </Formik>

    </ContentWrap>
  </CandidateFormCard>
}