import styled from "styled-components"
import { BaseButton } from "../SPBaseButton/SPBaseButton.presets"
import { theme } from "../../../../theme"

export const WhiteButton = styled(BaseButton)`
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.lightGray};
  color: ${theme.colors.blue};
  ${(props)=>props.isLight&&`
    border: none;
  `}

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.9;
    }
  }

  :disabled {
    cursor: not-allowed;
    color: ${theme.colors.lightGray};
    background: ${theme.colors.lightOrange};
    border-color: ${theme.colors.lightOrange};
  }

  :focus {
    /* border: 2px outset black; */
  }
`
