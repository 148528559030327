
import styled from 'styled-components';
import { theme } from '../../../theme';

export const PhotosList = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

export const PhotoItemContainer = styled('div')`
  padding: ${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 2px
  }
`

export const LinkFieldWrap = styled('div')`
  display: flex;
  align-items: flex-end;
  max-width: 558px;
  width: 100%;
  margin-top: ${theme.offsets.big};
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`

export const AddLinkButton = styled('div')`
  max-width: 93px;
  width: 100%;
  margin-left: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    margin-left: 0;
    margin-top: ${theme.offsets.lg};
  }
`