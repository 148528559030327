import { FieldArray } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleForAddCityBtn } from "../../../AdminPanel/Organization/Organization.presents"
import { PropsType } from './HiringLocationFieldArray.props'
import { HiringLocationField } from './HiringLocationField'
import { Checkbox } from '../../../common/Checkbox'
import { ButtonWrap, Wrapper } from "./HiringLocationField.presents"

export const HiringLocationFieldArray = (props: PropsType) => {
  const { t } = useTranslation();
  const [locationsArray, setLocationsArray] = useState(props.values.hiringLocations)
  const [disableAllFields, setDisableAllFields] = useState(false)

  useEffect(() => {
    setLocationsArray(props.values.hiringLocations)
  }, [props.values.hiringLocations, locationsArray])

  useEffect(() => {
    props.values.acceptRemoteWorkersWorld && setDisableAllFields(props.values.acceptRemoteWorkersWorld.includes('anywhereFromWorld'))
  }, [props.values.acceptRemoteWorkersWorld])




  return <FieldArray
    name="hiringLocations">
    {({ remove, push }) => (
      <div>
        {locationsArray && locationsArray.length > 0 && locationsArray.map((item: any, index: number) => <HiringLocationField
          key={index}
          index={index}
          remove={remove}
          touched={props.touched}
          values={props.values}
          errors={props.errors}
          handleChange={props.handleChange}
          locationsArray={locationsArray}
          setFieldTouched={props.setFieldTouched}
          disableAllFields={disableAllFields}
          setFieldValue={props.setFieldValue}
        />)
        }
        <Wrapper className='d-flex flex-column hiring-location'>
          <ButtonWrap>
            <StyleForAddCityBtn disabled={disableAllFields} type={'button'} onClick={() => push({
              country: 'US',
              territory: '',
              city: [],
              acceptRemoteWorkers: ['']
            })
            }>{t('adminPanel.addLocationBtn')}</StyleForAddCityBtn>
          </ButtonWrap>
          <Checkbox
            className='d-inline-block'
            m={'24px auto 0 0'}
            value={'anywhereFromWorld'}
            name={'acceptRemoteWorkersWorld'}
            labelText={'Accept remote workers from anywhere in the world'}
            handleChange={props.handleChange}
            checked={props.values.acceptRemoteWorkersWorld && props.values.acceptRemoteWorkersWorld.includes('anywhereFromWorld')}
          />
        </Wrapper>
      </div>
    )}
  </FieldArray>
}