import styled from "styled-components";
import { theme } from "../../../../theme";

export const SignUpMobileLink = styled('p')`
  margin-top: 60px;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.darkGray};
  text-align: center;
  a {
    padding-left: 8px;
    font-weight: 500;
    color: ${theme.colors.blue};
  } 
`