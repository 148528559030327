import { StyleForWrap, Content, Center } from '../../common/Wraps/Wraps.presents';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { ReactComponent as ContactsIcon } from "../../../assets/img/organization/contacts.svg";
import  contact  from "../../../assets/img/contactPlaceholder.svg";
import { Subheader } from '../../common/Title';
import { Text } from '../../common/Text';
import { ContactImage } from '../../common/Avatar/Avatar.presents';
import { TextButton } from '../../common/buttons/TextButton/TextButton';
import { useState } from 'react';
import { OrgProps } from '../Organization.props';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./contacts.css";
import { StyleForContact, WrapForContact } from './Contacts.presents';
import { observer } from 'mobx-react-lite';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { WrapForLink } from '../Organization.presents';
import { HashLink as Link } from 'react-router-hash-link';


export const Contacts = observer(({ organizationData, isDesktop }: OrgProps) => {
    const history = useHistory();
    const goToAdminPanel = () => {
        history.push('/my-organization')
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slick-dots slick-thumb"
    };

    return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Contacts'}>

        {organizationData.contactPerson && organizationData.contactPerson.length > 0 ?
            <>
                <Slider {...settings}>
                    {organizationData.contactPerson.map((person: any) => {

                        return <Contact
                            image={person.logo?.url?person.logo?.url:contact}
                            name={person.fullName}
                            position={person.position}
                            email={person.email}
                            phone={person.phoneNumber}
                            isDesktop={isDesktop}
                        />
                    })}
                </Slider>
            </>
            :
            <>
                <Center>
                    <ContactsIcon />
                </Center>
                <Text center fs={16} color='black' m='20px 100px' text={"No Contacts"} />
                <Text center fs={14} lh={1.2} m='20px 100px' text={"Add one or more people as contact persons to increase the level of trust to your company"} />
                <Center>
                    <WrapForLink>
                        <Link to='/my-organization#orgContacts'>
                            <SPWhiteButton width={'100%'} onClick={goToAdminPanel}>
                                Add Contacts
                            </SPWhiteButton>
                        </Link>
                    </WrapForLink>
                </Center>
            </>
        }
    </OrganizationPreviewCard>)
})


interface ContactProps {
    image?: string
    name?: string
    position?: string
    email?: string
    phone?: string
    isDesktop?: boolean
}

const Contact = ({ name, image, position, email, phone, isDesktop }: ContactProps) => {
    const [showEmail, setShowEmail] = useState(false)
    const [showPhone, setShowPhone] = useState(false)
    return (
        <>
            <WrapForContact>
                <ContactImage src={image} />
                <StyleForContact>
                    <Text fs={isDesktop ? 22 : 16} text={name} />
                    <Text fs={isDesktop ? 16 : 12} color="rgba(57, 67, 77, 0.7)" text={position} />
                </StyleForContact>
            </WrapForContact>
            <hr />
            <Text fs={isDesktop ? 14 : 12} color='#3F474F' text={"Email:"} />
            <TextButton text={showEmail ? "Hide" : "Show"} onClick={() => setShowEmail(!showEmail)} />
            {showEmail && (
                <Text fs={isDesktop ? 16 : 14} text={email} />
            )}
            {phone && (
                <>
                    <hr />
                    <Text fs={isDesktop ? 14 : 12} color='#3F474F' text={"Phone:"} />
                    <TextButton text={showPhone ? "Hide" : "Show"} onClick={() => setShowPhone(!showPhone)} />
                    {showPhone && (
                        <Text fs={isDesktop ? 16 : 14} text={phone} />
                    )}
                </>
            )}
        </>
    )
}