import { theme } from '../../../../theme';
import styled from 'styled-components';

export const OrganizationCardContainer = styled('div')<{isDesktop:boolean}>`
  width: 100%;
  max-width: 848px;
  margin-left: 0;
  margin-right: auto;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  margin-bottom:  ${theme.offsets.extraBig};
  padding: ${theme.offsets.extraBig} ${theme.offsets.extraBig} ${theme.offsets.extraMajor};
  scroll-margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg};
    padding: ${theme.offsets.big} ${theme.offsets.big} ${theme.offsets.extraMajor};
    scroll-margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.md};
    padding: ${theme.offsets.md} ${theme.offsets.md} ${theme.offsets.xxl};
    scroll-margin-top: ${theme.offsets.md};
  }
`

export const OrganizationCardContent = styled('div')<{isDesktop:boolean, width?: string}>`
  max-width: ${(props)=>props.width && props.isDesktop ? props.width : '557px'};
  margin: ${theme.offsets.extraBig} auto 0;
  ${(props)=>!props.isDesktop &&`
  max-width: ${props.width ? props.width : '496px'};
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.major} auto 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} auto 0;
  }
  `}
`

export const Title = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  font-weight: 500;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.lg};
  }
`