import { Field, Formik } from "formik";
import { useState } from "react";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { Input } from "../../../../common/Input";
import { ContentWrap, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const InterestsBlock = ({
  isDesktop,
  setInterestsOpen
}: {
  isDesktop: boolean;
  setInterestsOpen: (value: boolean) => void;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  const { resumeBuilderStore, candidateStore } = useRootStore();

  const cardSubtitle = "When writing about your interests, try to be specific and focus on their unique aspects to attract the hiring managers.";

  return <CandidateFormCard
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    isDesktop={isDesktop}
    title={'Interests'}
    subtitle={cardSubtitle}
  >
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={{ interests: resumeBuilderStore.interests }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            interests: Yup.string().required(t('validation.required')),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveInterests(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleReset
        }) => {
          const onChangeHandler = (value: any) => {
            setShowSaveBtn(true);
            handleChange(value);
          };

          return <form onSubmit={handleSubmit}>
            <FieldWrap>
              <Field
                as={Input}
                name={'interests'}
                type={'textarea'}
                value={values.interests}
                onChange={onChangeHandler}
                maxLength={600}
                labelText={<LabelWrap>Your interests<span>Up to 600 characters</span></LabelWrap>}
                error={errors.interests} />
              <ErrorMsg>{errors.interests}</ErrorMsg>
            </FieldWrap>
            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveInterests({ interests: "" }, candidateStore.uid);
                await resumeBuilderStore.setInterests("");
                handleReset();
                setInterestsOpen(false);
              }}
            />}
          </form>
        }}
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
}