import { StyleForTable } from "../../../../common/Table/Table.presents";
import sortIcon from '../../../../../assets/img/sortIcon.svg';
import { TableMessage } from "../../../../common/Table/HelperComponents/Table.message";
import { useRootStore } from "../../../../../state/stores/storeContext";
import moment from "moment-timezone";
import { DesktopCandidatesListProps } from "../../CandidatesList.props";
import { IconsList } from "../../../../common/Table/Table.icon";
import { ActionsMenu } from "../../../../common/Modals/ActionListMenu";
import { setActionsForCandidates } from "../../helper";
import { Skeleton } from "../../../../common/Skeleton";
import { useState } from "react";


export const DesktopCandidatesList = (props: DesktopCandidatesListProps) => {
  const { handleSortTable, sortData, isSpinnerOpen, handleOpenModal, setActiveActionBtn, handleOpenMenu, activeActionBtn, isDesktop } = props;
  const { candidatesStore } = useRootStore();

  const [isPositionUnder, setPositionUnder] = useState(false);

  const menuPositionhandler = (evant: React.MouseEvent<HTMLElement>, index: number) => {
    //For two last items
    const BOTTOM_LIST_PADDING = 150;
    const windowHeight = window.innerHeight;
    const menuPosition = evant.clientY + BOTTOM_LIST_PADDING;
    //set position
    setPositionUnder(windowHeight > menuPosition);
    //Open menu
    handleOpenMenu(index)
  }

  return <>
    <StyleForTable>
      <thead>
        <tr>
          <th>Name <button onClick={() => handleSortTable('firstName')} className={`sort-btn ${sortData.type === 'firstName' && sortData.value === 'up' && 'revert'}`}><img src={sortIcon} alt="V" /></button></th>
          <th>Job </th>
          <th>Date <button onClick={() => handleSortTable('responseDate')} className={`sort-btn ${sortData.type === 'responseDate' && sortData.value === 'up' && 'revert'}`}><img src={sortIcon} alt="V" /></button></th>
          <th>Contacts</th>
          <th>Message</th>
          <th>Resume</th>
          <th>Authorization</th>
          <th>Actions</th>
        </tr>
      </thead>
      {!isSpinnerOpen && <tbody>
        {candidatesStore.candidatesList.map((item: any, index: number) => <tr key={index}>
          <td>
            <p>{item.firstName}</p>
            <p className='candidate-list-item'>{item.lastName}</p>
          </td>
          <td>{candidatesStore.jobTitle}</td>
          <td>
            <p>{moment(new Date(item.applyingAt)).format('DD/MM/YY')}</p>
            <p className='candidate-list-item'>{moment(new Date(item.applyingAt)).format('hh:mm a')}</p>
          </td>
          <td>
            <p>{item.email}</p>
            <p className='candidate-list-item'>{item.phone}</p>
            {item.website && <p className='candidate-list-item'><a href={item.website}>website</a></p>}
            {item.linkedIn && <p className='candidate-list-item'><a href={item.linkedIn}>linkedIn</a></p>}
          </td>
          <td>
            <TableMessage message={item.message ? item.message : 'No attached message'} />
          </td>
          <td>{item.resume ? <a className='download-resume' href={item.resume}><IconsList isActive={false} iconType={'downloadResume'} /> Download Resume</a> : 'No resume requested'}</td>
          <td>{item.hasVisa ? 'Yes' : 'No'}</td>
          <td >
            <button
              className={`action-btn ${activeActionBtn === index && 'active'}`}
              onClick={(evant) => menuPositionhandler(evant, index)}
              onBlur={() => setActiveActionBtn(null)}
            >
              <div className='icon'></div>
              {activeActionBtn === index && <ActionsMenu
                index={index}
                activeActionBtn={activeActionBtn}
                isDesktop={isDesktop}
                handleOpenMenu={handleOpenMenu}
                isPositionUnder={isPositionUnder}
                actionList={setActionsForCandidates({ candidateItem: item, handleOpenModal })} />}
            </button>
          </td>
        </tr>
        )}
      </tbody>}
    </StyleForTable>
    {isSpinnerOpen && <Skeleton isDesktop={isDesktop} />}
  </>
}