import styled from 'styled-components'
import { theme } from '../../../../../../theme'

export const BetaVersionBlock = styled('div')`
  border-radius: ${theme.variables.borderRadiusLarge};
  background-color: rgba(255, 255, 255, 0.2);
  color: ${theme.colors.white};
  padding: ${theme.offsets.xxl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  bottom: ${theme.offsets.lg};
  button{
    border: none;
  }
`
export const BetaVersionTitle = styled('div')`
  font-weight: 500;
  font-size: 18px;
  margin-top: ${theme.offsets.xxl};
`
export const BetaVersionText = styled('div')`
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  margin: ${theme.offsets.lg} 0 ${theme.offsets.xxl};
`