import styled from "styled-components"
import { theme } from "../../../../theme"
import { StyledProps } from "./SPBaseButton.props"

export const BaseButton = styled.button<StyledProps>`
  border-width: 1px;
  border-radius: ${theme.variables.borderRadiusSmall};
  border-style: solid;
  border-color: ${theme.colors.lightGray};
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  padding: ${props => props.width ? 0 : `${theme.offsets.md} ${theme.offsets.extraMajor}`};
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align:top;
  -webkit-appearance:none;
  user-select:none;
  outline: 0;
  cursor: pointer;
  width: ${props => props.width ? props.width : "auto"};
  height: 53px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  transition: all 0.3s ease-in-out;
  @media(max-width: ${theme.screens.desktop}) {
    height: 44px;
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    height: 40px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: ${props => props.mobileHeight ? props.mobileHeight : "34px"}
  }

  :disabled {
    background-color: ${theme.colors.white};
    color: ${theme.colors.darkGray};
    border-color: ${theme.colors.darkGray};
    cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};
  }
`
