import { CardinalOrientation, Walktour, WalktourLogic } from 'walktour'
import { useRootStore } from '../../../../state/stores/storeContext';
import { SPSecondaryButton } from '../../../common/buttons/SPSecondaryButton/SPSecondaryButton';
import { steps } from './helper';
import {
  TutorialButtonsWrap,
  TutorialDescription,
  TutorialNextButton,
  TutorialSkipButton,
  TutorialTitle,
  TutorialTooltip
} from './Tutorial.presents';

export const Tutorial = ({
  isTutorialStart,
  setIsTutorialStart,
  isDesktop
}: {
  isTutorialStart: boolean;
  setIsTutorialStart: (value: boolean) => void;
  isDesktop: boolean;
}) => {
  const { candidateStore } = useRootStore();
  const onFinishTutorial = async () => {
    await candidateStore.changeTutorialProgress(true);
    setIsTutorialStart(false);
  }

  return <Walktour
    transition='0.75s'
    isOpen={isTutorialStart}
    steps={steps(isDesktop)}
    tooltipSeparation={26}
    orientationPreferences={isDesktop ? [CardinalOrientation.SOUTHWEST] : [CardinalOrientation.SOUTHEAST]}
    customTooltipRenderer={(tourLogic?: WalktourLogic) => {
      const nextStepHandler = () => {
        if (tourLogic && tourLogic.stepIndex + 1 === tourLogic?.allSteps.length) {
          onFinishTutorial();
        } else {
            tourLogic?.next();
        }
      };
      return <TutorialTooltip isDesktop={isDesktop} trianglePosition={tourLogic?.tooltipPosition?.orientation}>
        <TutorialTitle>
          {tourLogic?.stepContent.title}
        </TutorialTitle>
        <TutorialDescription>
          {tourLogic?.stepContent.description}
        </TutorialDescription>
        <TutorialButtonsWrap>
          <TutorialSkipButton onClick={onFinishTutorial}>
            {tourLogic?.stepContent.closeLabel}
          </TutorialSkipButton>
          <TutorialNextButton>
            <SPSecondaryButton type="button" width="100%" onClick={nextStepHandler}>
              {tourLogic?.stepContent.nextLabel}
            </SPSecondaryButton>
          </TutorialNextButton>
        </TutorialButtonsWrap>
      </TutorialTooltip>
    }}
  />
}
