import { FileBtnPropsType } from './FileBtn.props';
import { FileBtnWrap, StyleForLabel, StyleForFileBtn, StyleForLogoWrap } from './FileBtn.presents';
import { useRootStore } from '../../../state/stores/storeContext';
import { useState } from 'react';
import closeIcon from '../../../assets/img/close.svg';
import spinnerIcon from '../../../assets/img/spinner.svg';
import { useEffect } from 'react';
import { deleteFile } from '../../../services/api/jobStoreBuilder/jobStoreBuilder.domains';
import logoIcon from '../../../assets/img/bagLogo.svg';

export const FileBtn = (props: FileBtnPropsType) => {
  const isItLogo = props.componentType === 'logoComponent'
  const { jobStoryBuilder } = useRootStore()
  const [uploadedFile, setUploadedFile] = useState<{ filename: string, fileUrl: string }>({ filename: '', fileUrl: '' })
  const [openSpinner, setOpenSpinner] = useState(false)

  useEffect(() => {
    (() => { setUploadedFile({ filename: props.filename || '', fileUrl: props.fileUrl || '' }) })()
  }, [props.filename, props.fileUrl])

  const uploadFile = async (evant: any) => {
    setOpenSpinner(true)
    const responsiveData = await jobStoryBuilder.uploadFileToJob(evant.target.files[0], props.fileFolder)
    if (responsiveData.data) {
      setOpenSpinner(false)
      setUploadedFile({ filename: responsiveData.data.filename, fileUrl: responsiveData.data.url })
      props.handleChange(props.name, { id: responsiveData.data._id, filename: evant.target.files[0].name, fileUrl: responsiveData.data.url })
    }
  }

  const removeFile = async () => {
    setOpenSpinner(true)
    setUploadedFile({ filename: '', fileUrl: '' })
    const responsiveData = await deleteFile(props.fileId, props.fileFolder)
    if (!responsiveData.data.err) {
      setOpenSpinner(false)
      props.handleChange(props.name, {})
      setUploadedFile({ filename: '', fileUrl: '' })
    }
  }

  const defaultContent = () => <div className='d-flex w-100 align-items-center justify-content-between file-content'>{props.text}
    {props.icon ? <img src={props.icon} alt="icon" /> : ''}</div>

  const uploadedContent = () => <div className='d-flex w-100 align-items-center justify-content-between'>
    <span className='media-title'>{props.uploadedIcon ? <img src={props.uploadedIcon} alt="icon" /> : ''}{uploadedFile.filename}</span>
    <button type='button' onClick={() => removeFile()} className='delete-file'><img src={closeIcon} alt="delete" /></button>
  </div>

  return <div>
    {isItLogo && <StyleForLogoWrap>
      <span className='title'>Logo</span>
      <div className='photo-block'>
        <img className={openSpinner ? 'spinner-logo' : uploadedFile.fileUrl ? 'logo-img' : ''} src={openSpinner ? spinnerIcon : uploadedFile.fileUrl ? uploadedFile.fileUrl : logoIcon} alt="logo" />
        {uploadedFile.fileUrl && <button type='button' onClick={() => removeFile()} className='delete-file'><img src={closeIcon} alt="delete" /></button>}
        <StyleForFileBtn disabled={uploadedFile.filename ? true : false} type='file' {...props} onChange={uploadFile} accept={props.fileFormat} />
      </div>
    </StyleForLogoWrap>}
    <FileBtnWrap {...props} >
      <StyleForLabel className={uploadedFile.filename ? 'uploaded' : ''} {...props} htmlFor={props.name} >
        {uploadedFile.filename && !isItLogo ? uploadedContent() : openSpinner && !isItLogo ? <img className='spinner' src={spinnerIcon} alt="spinner" /> : defaultContent()}
        <StyleForFileBtn disabled={uploadedFile.filename ? true : false} type='file' {...props} onChange={uploadFile} accept={props.fileFormat}/>
      </StyleForLabel>
    </FileBtnWrap>
  </div>
}