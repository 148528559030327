import { useHistory } from "react-router-dom";
import { StyleForMenuWrap, MenuItem } from "./UserMenu.presents";
import { PropsType } from "./UserMenu.props";
import exitIcon from '../../../../../../assets/img/exit.svg';
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import user from '../../../../../../assets/img/user.svg';
import setting from '../../../../../../assets/img/gearIcon.svg';
import support from '../../../../../../assets/img/support.svg';

export const UserMenu = (props: PropsType) => {
  const history = useHistory();
  const { userStore } = useRootStore();
  const { t } = useTranslation();

  const exitFromWebsite = async () => {
    const resData = await userStore.logoutUser();
    if (!resData.err) {
      history.push('/sign-in');
    }
  }

  const menuList = [
    { path: "/profile", title: t('adminPanel.tabProfile'), img: user },
    { path: "/settings", title: t('adminPanel.tabSettings'), img: setting },
    { path: "/support", title: t('adminPanel.tabSupport'), img: support }
  ]

  return <StyleForMenuWrap show={props.show}>
    {menuList.map((item, index) =>
      <MenuItem
        key={index}
        onClick={() => history.push(item.path)}>
        <img src={item.img} alt="img" />{item.title}
      </MenuItem>
    )}
    <MenuItem isWarning onClick={() => exitFromWebsite()}>
      <img src={exitIcon} alt="img" />
      {t('adminPanel.exit')}
    </MenuItem>
  </StyleForMenuWrap>
}