import ReactGA from 'react-ga4';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//TODO: move to .env
const GA_KEY = 'G-S3BCFBZS9K'

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize([{
      trackingId: GA_KEY
    }]);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send("pageview");
    }
  }, [initialized, location]);
};

export default usePageTracking;

// use customEvent to send google analytics custom events
export const customEvent = (category:string, action:string) => {
  ReactGA.event({
    category: category,
    action: action,
  });
}