import { theme } from '../../../theme/theme';
import styled from 'styled-components';

export const SidebarWrap = styled('div') <{ isOpen: boolean }>`
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0;
  z-index: 9999;
  transition: all 0.5s ease 0s;
  background: ${theme.colors.white};
  box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  transform: ${(props) => props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
  flex-direction: column;
`

export const SidebarHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: ${theme.offsets.xxl};
  top: ${theme.offsets.xxl};
`

export const CloseBtn = styled('button')`
  border: none;
  background: none; 
`



