import { Wrap, MenuItem, Icon, Title, Logo, Content, ExitBtn, ListBox } from "./MobileSidebar.presents";
import JobListIcon from '../../../../../assets/img/bag.svg';
import OrganizationIcon from '../.././../../../assets/img/organization.svg';
import ProfileIcon from '../../../../../assets/img/Profile.svg';
import SettingIcon from '../.././../../../assets/img/SettingWhite.svg';
import FeedbackIcon from '../../../../../assets/img/ChatWhite.svg';
import DocumentIcon from '../../../../../assets/img/documentIconWhite.svg';
import BagIcon from '../../../../../assets/img/bag.svg';
import LogoIcon from '../../../../../assets/img/logo-white.svg';
import LogoutIcon from '../../../../../assets/img/Logout.svg';
import { useHistory } from "react-router-dom";
import { useRootStore } from "../../../../../state/stores/storeContext";
import {HelperTextTooltipWrapper} from "../../../HelperText/HelperTextTooltipWrapper";

interface menuTypes {
  title: string,
  icon: string,
  path: string,
  selectorId?: string
  helperText?: {
    title: string,
    text: string
  }
}

const employerMenuList: menuTypes[] = [
  { title: 'My jobs', icon: JobListIcon, path: '/my-jobs' },
  { title: 'Organization', icon: OrganizationIcon, path: '/my-organization' },
  { title: 'Profile', icon: ProfileIcon, path: '/profile' },
  { title: 'Settings', icon: SettingIcon, path: '/settings' },
  { title: 'Support & Feedback', icon: FeedbackIcon, path: '/support' }
];

const candidateMenuList: menuTypes[] = [
  { title: 'Profile', icon: ProfileIcon, path: '/candidate/profile' },
  { title: 'My Resume', icon: BagIcon, path: "/candidate/resume-builder"},
  { title: 'My Preferences', icon: DocumentIcon, path: "/candidate/preferences"},
];

export const MobileSidebar = (props: { isOpen: boolean, closeMenu: () => void, isCandidateService?: boolean }) => {
  const { isOpen, closeMenu } = props;
  const history = useHistory();
  const { userStore, candidateStore, resumeBuilderStore } = useRootStore();

  const menuList = props.isCandidateService ? candidateMenuList : employerMenuList;

  const logoutHandler = async () => {
    if (props.isCandidateService) {
      candidateStore.signOutUser();
      resumeBuilderStore.clearResumeBuilder();
      history.push('/candidate/sign-in');
    } else {
      await userStore.logoutUser();
      history.push('/sign-in');
    }
  }

  return <Wrap isOpen={isOpen}>
    <Content>
      <ListBox>
        <Logo src={LogoIcon} alt='logo' />
        {menuList.map((item, index) => {
          return <MenuItem to={item.path} key={index} id={item?.selectorId} onClick={closeMenu}>
            <Icon src={item.icon} alt='icon' />
            <Title>{item.title}</Title>
            {!!item?.helperText && <HelperTextTooltipWrapper text={item.helperText.text} title={item.helperText.title} autoChangePosition/>}
          </MenuItem>
        })}
      </ListBox>
      <ExitBtn onClick={() => logoutHandler()}>
        <Icon src={LogoutIcon} alt='icon' />
        Log Out
      </ExitBtn>
    </Content>
  </Wrap>
}

