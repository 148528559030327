import styled from 'styled-components'
import { theme } from '../../../../../../theme'
import { styleWrapperType } from './UserMenu.props'


export const StyleForMenuWrap = styled('div') <styleWrapperType>`
  display: ${(props) => props.show ? 'flex' : 'none'};
  position: absolute;
  flex-direction: column;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  top: 71px;
  max-width: 350px;
  right: 0px;
  z-index: 11;
`

export const MenuItem = styled('p') <{ isWarning?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.isWarning ? theme.colors.red : theme.colors.black};
  margin: 0 ${theme.offsets.xxl};
  padding: ${theme.offsets.xxl} 0;
  border-bottom: ${(props) => props.isWarning ? 'none' : `1px solid ${theme.colors.lightGray}`};
  img{
    margin-right: ${theme.offsets.sm};
  }
`