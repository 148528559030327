import { RadioBtn } from '../../BasicComponents/RadioBtn'
import { Checkbox } from '../../../common/Checkbox'
import { MixedShift } from '../../BasicComponents/MixedShift'
import { ActiveIcon } from './JobStep.icons'
import { SchemePropsType } from './JobStep.props'
import { HiringLocationFieldArray } from '../../BasicComponents/HiringLocationFieldArray/HiringLocationFieldArray'

export const SelectComponent = (data: SchemePropsType) => {

  const openBlock = (value: string, name: string) => {
    if (value === 'mixed') {
      data.setOpenMixedShift(true)
    } else if (name === 'workShift' && value !== 'mixed') {
      data.setOpenMixedShift(false)
    }
  }

  switch (data.type) {
    case 'radio':
    case 'radio-long':
      return <div className="d-flex flex-wrap justify-content-center answer-wrap">
        {data.item.answers.map((answer: any, index: number) => <div className='d-flex' key={index + 'answer' + answer.value} >
          <RadioBtn
            onClick={() => openBlock(answer.value, data.item.name)}
            long={data.type === 'radio-long' ? true : false}
            text={answer.title}
            subtitle={answer.subtitle ? answer.subtitle : ''}
            activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[data.item.name] ? true : false} />}
            name={data.item.name}
            value={answer.value}
            checked={answer.value === data.values[data.item.name] ? true : false}
            handleChange={data.handleChange}
            setFieldTouched={data.setFieldTouched}
          />
        </div>
        )}
        {data.openMixedShift && data.item.name === 'workShift' ? <MixedShift handleChange={data.handleChange} values={data.values.mixshiftData} setFieldValue={data.setFieldValue} /> : ''}
      </div>
    case 'check-box':
      return <div className='d-flex flex-column align-items-start check-box-wrap'>
        <div className="d-flex flex-wrap justify-content-start">
          {data.item.answers.map((answer: any, index: number) => <Checkbox
            key={index + 'answer' + answer.value}
            labelText={answer.title}
            name={data.item.name}
            value={answer.value}
            handleChange={data.handleChange}
            checked={data.values[data.item.name] && data.values[data.item.name].includes(answer.value)}
            type='radio'
            setFieldValue={data.setFieldValue}
            block
          />
          )}
        </div>
        {data.item.name === 'weeklyInfo' && <div>
          <Checkbox
            m={'14px 0 26px'}
            labelText={'Can be done over the weekends'}
            name={'canDoneOver'}
            value={'weekends'}
            handleChange={data.handleChange}
            checked={data.values.canDoneOver.includes('weekends')}
          />
          <Checkbox
            m={'0'}
            labelText={'Can be done after business hours'}
            name={'canDoneOver'}
            value={'businessHours'}
            handleChange={data.handleChange}
            checked={data.values.canDoneOver.includes('businessHours')}
          />
        </div>
        }
      </div>
    case 'traveling':
      return <div>
        <div className='d-flex justify-content-center answer-wrap'>
          <RadioBtn
            onClick={() => data.setOpenTravelingBlock(true)}
            text={'Yes'}
            activeIcon={<ActiveIcon name='yes' checked={data.values[data.item.name] && data.values[data.item.name] !== 'no' ? true : false} />}
            name={data.item.name}
            value={'yes'}
            checked={data.values[data.item.name] && data.values[data.item.name] !== 'no' ? true : false}
            handleChange={data.handleChange}
            setFieldTouched={data.setFieldTouched}
          />
          <RadioBtn
            onClick={() => data.setOpenTravelingBlock(false)}
            text={'No'}
            activeIcon={<ActiveIcon name='no' checked={data.values[data.item.name] === 'no' ? true : false} />}
            name={data.item.name}
            value={'no'}
            checked={data.values[data.item.name] === 'no' ? true : false}
            handleChange={data.handleChange}
            setFieldTouched={data.setFieldTouched}
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {data.openTravelingBlock && data.item.answers.map((answer: any, index: number) => <div className='d-flex' key={index + 'answer' + answer.value}>
            <RadioBtn
              onClick={() => openBlock(answer.value, data.item.name)}
              text={answer.title}
              subtitle={answer.subtitle ? answer.subtitle : ''}
              activeIcon={<ActiveIcon name={answer.value} checked={data.values[data.item.name] && data.values[data.item.name].includes(answer.value)} />}
              name={data.item.name}
              value={answer.value}
              handleChange={data.handleChange}
              checked={data.values[data.item.name] && data.values[data.item.name].includes(answer.value)}
              type="checkbox"
              setFieldTouched={data.setFieldTouched}
            />
          </div>
          )}
        </div>
      </div>
    case 'locations':
      return <HiringLocationFieldArray
        setFieldTouched={data.setFieldTouched}
        values={data.values}
        handleChange={data.handleChange}
        errors={data.errors.hiringLocations}
        touched={data.touched.hiringLocations}
        setFieldValue={data.setFieldValue}
      />
    default:
  }
}