import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled('div')`
	max-width: 266px;
	margin-right: ${theme.offsets.xxl};
	width: 100%;
	top: ${theme.offsets.xxl};
`

export const Section = styled('div')`
	background-color:  ${theme.colors.white};
	border-radius: 20px;
	position: sticky;
	top: 20px;
`

export const Header = styled('div')`
	border-radius: 20px;
	padding:  ${theme.offsets.lg} ${theme.offsets.lg};
	background: ${theme.colors.white};
`

export const ProgressBarText = styled('div')`
	display: flex;
	justify-content: space-between;
	padding:  ${theme.offsets.md} 0 ${theme.offsets.md} 0;
`

export const DesktopResumeBuilderMenu = styled('span') <{ isColored: boolean}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 266px;
	padding: ${theme.offsets.sm};
	background: ${(props)=>props.isColored ? "#ec923c1a" : theme.colors.white};
	border-radius: ${(props)=>props.isColored ? '0px' : '20px'};
`

const ActiveItemStyle = `
	background-color: rgba(236, 146, 60, 0.1);
	color: ${theme.colors.lightOrange};
`

export const ResumeBuilderMenuItem = styled('span') <{ isActive: boolean, isDesktop: boolean }>`
	display: flex;
	justify-content: space-between;	
	color: ${(props)=>props.isActive ? theme.colors.lightOrange : theme.colors.black};
	font-size: ${theme.fontSizes.lg};
	width: 100%;
	padding: ${theme.offsets.lg};
	border-radius: 10px;
	font-weight: 500;
	margin-bottom: 2px;
	text-align: start;
	cursor: pointer;
	transition: 300ms;
	&:hover{
		${ActiveItemStyle}
	}
	${(props) => !props.isDesktop && `
		font-size: 14px;
		padding: ${theme.offsets.md} ${theme.offsets.lg};
		border-bottom: 2px solid ${theme.colors.white};
		white-space: nowrap;
		border-radius: 0;
		margin-bottom: 0;
		font-weight: 300;
		${props.isActive && `
		border-bottom: 2px solid ${theme.colors.lightOrange};
	`}
	`}	
`

export const ResumeBuilderMenuTitle = styled('span') <{ isComplete: boolean, isDesktop: boolean }>`
	font-size: ${theme.fontSizes.lg};
	color: ${theme.colors.black};
	width: 100%;
	padding: ${theme.offsets.lg};
	border-radius: 10px;
	font-weight: 500;
	margin-bottom: 2px;
	text-align: start;
	cursor: pointer;
	transition: 300ms;
	&:hover{
		${ActiveItemStyle}
	}
	${(props) => !props.isDesktop && `
		font-size: 14px;
		padding: ${theme.offsets.md} ${theme.offsets.lg};
		border-bottom: 2px solid ${theme.colors.white};
		white-space: nowrap;
		border-radius: 0;
		margin-bottom: 0;
		font-weight: 300;
		${props.isComplete && `
		border-bottom: 2px solid ${theme.colors.lightOrange};
	`}
	`}	
`

export const MobileWrapper = styled('div')`
	margin-left: -${theme.offsets.lg};
	margin-right: -${theme.offsets.lg};
	width: 100vw;
	background: ${theme.colors.white};
	display: flex;
	justify-content: start;
	overflow-x: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	position: sticky;
	top: 0;
	margin-bottom: ${theme.offsets.lg};
	z-index: 10;
	@media(max-width: ${theme.screens.mobile}) {
		margin-bottom: ${theme.offsets.md};
  }
	&::-webkit-scrollbar {
		display: none;
	}
`

export const MobileResumeBuilderMenuItem = styled('span') <{ isActive: boolean }>`
	font-size: 14px;
	color: ${theme.colors.black};
	padding: ${theme.offsets.md} ${theme.offsets.lg};
	transition: 3ms;
	border-bottom: 2px solid ${theme.colors.white};
	white-space: nowrap;
	${(props) => props.isActive && `
		color: ${theme.colors.lightOrange};
		border-bottom: 2px solid ${theme.colors.lightOrange};
	`}
`