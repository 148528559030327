import { useState } from "react";
import { JobStory } from "../../components/JobStory";
import { JobStoryHeader } from "../../components/JobStory/BasicComponents/Header";
import { PageWrap } from './JobStory.present'

export const JobStoryPage = () => {
  const [openApplyWindow, setOpenApplyWindow] = useState(false);

  return <PageWrap>
    <JobStoryHeader setOpenApplyWindow={setOpenApplyWindow} />
    <JobStory openApplyWindow={openApplyWindow} setOpenApplyWindow={setOpenApplyWindow} preview={false} />
  </PageWrap>
}