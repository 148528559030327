export const EntryIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 29H10V38L5.5 37L6.5 29Z" fill="#F8DDC3" />
    <path d="M50.5 38L47 38L47 29L51.5 30L50.5 38Z" fill="#F8DDC3" />
    <path d="M18.5 10.5L26.5 8.5L32.5 8L38 10.5L42.5 14L46.5 19.5L47.5 26L47 39L46 43L42 47.5L32.5 50H23L15.5 47.5L11 44.5L9.5 36.5L10.5 19.5L18.5 10.5Z" fill="white" />
    <path d="M18.2819 35.9409C19.3564 35.9409 20.2275 35.0728 20.2275 34.0021C20.2275 32.9313 19.3564 32.0633 18.2819 32.0633C17.2073 32.0633 16.3362 32.9313 16.3362 34.0021C16.3362 35.0728 17.2073 35.9409 18.2819 35.9409Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M38.718 35.9409C39.7925 35.9409 40.6636 35.0728 40.6636 34.0021C40.6636 32.9313 39.7925 32.0633 38.718 32.0633C37.6435 32.0633 36.7724 32.9313 36.7724 34.0021C36.7724 35.0728 37.6435 35.9409 38.718 35.9409Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M53 33.1793C53 30.3709 50.9571 28.0307 48.2747 27.5525V26.7051C48.2747 15.8396 39.4038 7 28.5001 7C17.5962 7 8.7253 15.8396 8.7253 26.7051V27.5525C6.04285 28.0309 4 30.3711 4 33.1793C4 36.0119 6.07805 38.3686 8.79455 38.8185C9.17689 43.16 11.1702 46.3669 14.7375 48.3626C17.9099 50.1373 22.4115 51 28.5001 51C34.5885 51 39.0902 50.1372 42.2626 48.3626C45.8298 46.3669 47.8231 43.1601 48.2054 38.8185C50.922 38.3687 53 36.0119 53 33.1793ZM6.02564 33.1793C6.02564 31.4894 7.16911 30.0616 8.72518 29.6211V36.7375C7.16911 36.2971 6.02564 34.8693 6.02564 33.1793ZM46.2491 37.2302C46.2491 41.6627 44.6207 44.7284 41.2709 46.6024C38.4093 48.2034 34.232 48.9815 28.5001 48.9815C22.7681 48.9815 18.5907 48.2034 15.7292 46.6024C12.3793 44.7284 10.7509 41.6627 10.7509 37.2302V26.7051C10.7509 18.7183 16.0917 11.9523 23.4008 9.76358C28.9691 9.83372 31.9347 13.8392 31.9347 16.7395C31.9347 18.9609 30.1211 20.7682 27.8917 20.7682C26.2201 20.7682 24.86 19.4129 24.86 17.7471C24.86 16.5258 25.8571 15.5321 27.0829 15.5321C27.6422 15.5321 28.0957 15.0802 28.0957 14.5228C28.0957 13.9655 27.6422 13.5136 27.0829 13.5136C24.7402 13.5136 22.8344 15.4127 22.8344 17.7471C22.8344 20.526 25.1031 22.7867 27.8917 22.7867C31.238 22.7867 33.9603 20.0739 33.9603 16.7395C33.9603 14.6447 32.904 12.3909 31.1349 10.7105C30.4272 10.0383 29.622 9.47329 28.7434 9.02165C38.4183 9.1521 46.2491 17.0338 46.2491 26.7051V37.2302ZM48.2747 36.7377V29.6212C49.8309 30.0617 50.9744 31.4896 50.9744 33.1794C50.9744 34.8693 49.8308 36.2971 48.2747 36.7377Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M32.417 36.7818C30.2573 38.9342 26.7427 38.9342 24.583 36.7818C24.1874 36.3877 23.5462 36.3877 23.1506 36.7818C22.7551 37.1759 22.7551 37.815 23.1506 38.2091C24.6254 39.6787 26.5628 40.4136 28.5001 40.4136C30.4373 40.4136 32.3746 39.6787 33.8495 38.2091C34.245 37.815 34.245 37.1759 33.8495 36.7818C33.4538 36.3877 32.8126 36.3877 32.417 36.7818Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
