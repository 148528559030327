import { UploadButtonContainer } from './PhotoUploadButton.presets'
import plusBlack from '../../../../assets/img/plusBlack.svg';
import useGetDevice from '../../../../hooks/useGetDevice';

interface UploadButtonProps {
  text: string
  onChange: any
  multiple?: boolean
  isLoading?: boolean
  fileTypes?: string
  icon?: string
  showButton: boolean
}

export const PhotoUploadButton = (props: UploadButtonProps) => {
  const btnIcon = props.icon || plusBlack;
  const isDesktop = useGetDevice();
  return (
    <UploadButtonContainer
      type="button"
      disabled={props.isLoading}
      showButton={props.showButton}
      isDesktop={isDesktop}>
      {props.text}
      <img src={btnIcon} alt="Upload photos" />
      <input
        multiple={props.multiple}
        disabled={props.isLoading}
        type="file"
        onChange={props.onChange}
        accept={props.fileTypes} />
    </UploadButtonContainer>
  )
}
