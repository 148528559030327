import {
  ItemWrap,
  ActionBtn,
  BtnIcon,
  ActionBtnWrap,
  MobileScreen,
  SortBtn,
  SortedBy,
  MainInfo,
  CandidateName,
  WorkAutorized,
  ContactsWrap,
  ContactLink,
  ContactItem,
  MessageWrap,
  MessageTitle,
  MessageText,
  ResumeBtnWrap,
  ResumeBtn
} from "./MobileCandidatesList.presents";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { MobileSortingModal } from "../../../../common/Modals/MobileSortingModal/MobileSortingModal";
import { useState } from "react";
import { ReactComponent as SortIcon } from '../../../../../assets/img/mobileSorting.svg';
import { ReactComponent as WorkAutorizedIcon } from '../../../../../assets/img/TickSquare.svg';
import { ReactComponent as NedWorkAutorizedIcon } from '../../../../../assets/img/TickSquare.svg';
import { ReactComponent as MailIcon } from '../../../../../assets/img/messageGrey.svg';
import { ReactComponent as DocumentIcon } from '../../../../../assets/img/Document.svg';
import { ReactComponent as PhoneIcon } from '../../../../../assets/img/phone.svg';
import { ReactComponent as WebsiteIcon } from '../../../../../assets/img/website.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../assets/img/linkedin.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/img/download.svg';
import { mobileSortList, setActionsForCandidates } from "../../helper";
import { MobileCandidatesListProps } from "./MobileCandidatesList.props";
import { ActionsMenu } from "../../../../common/Modals/ActionListMenu";
import { Skeleton } from "../../../../common/Skeleton";

export const MobileCandidatesList = (props: MobileCandidatesListProps) => {
  const { selectedIndex, handleMobileSorting, handleOpenMenu, handleOpenModal, activeActionBtn, isDesktop, isSpinnerOpen } = props;
  const { candidatesStore } = useRootStore();
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const sortedBy = selectedIndex !== null ? mobileSortList[selectedIndex].title : '';

  return <MobileScreen isLoading={isSpinnerOpen}>
    <SortBtn onClick={() => setSortModalOpen(true)}>
      <SortIcon />Sort by:<SortedBy>{sortedBy}</SortedBy>
    </SortBtn>
    {!isSpinnerOpen ? candidatesStore.candidatesList.map((item: any, index: number) => <ItemWrap key={index}>
      <MainInfo>
        <CandidateName>{item.firstName} {item.lastName}</CandidateName>
        <WorkAutorized>
          {item.hasVisa ? <WorkAutorizedIcon /> : <NedWorkAutorizedIcon />}
          {item.hasVisa ? 'Autorized for work' : 'Need work autorization'}
        </WorkAutorized>
      </MainInfo>
      <ContactsWrap>
        <ContactItem isFirstElem><MailIcon />{item.email}</ContactItem>
        <ContactItem><PhoneIcon />{item.phone}</ContactItem>
        {item.website && <ContactItem>
          <ContactLink href={item.website} target="_blank">
            <WebsiteIcon />
            {item.website}
          </ContactLink>
        </ContactItem>}
        {item.linkedIn && <ContactItem>
          <ContactLink href={item.linkedIn} target="_blank">
            <LinkedinIcon />
            LinkedIn
          </ContactLink>
        </ContactItem>}
      </ContactsWrap>
      {item.message && <MessageWrap>
        <MessageTitle>
          <DocumentIcon />
          Message
        </MessageTitle>
        <MessageText>
          {item.message}
        </MessageText>
      </MessageWrap>}
      {item.resume && <ResumeBtnWrap>
        <ResumeBtn href={item.resume}>
          <DownloadIcon />
          Download Resume
        </ResumeBtn>
      </ResumeBtnWrap>}
      <ActionBtnWrap>
        <ActionBtn onClick={() => handleOpenMenu(index)}>
          <BtnIcon></BtnIcon>
        </ActionBtn>
      </ActionBtnWrap>
      {activeActionBtn === index && <ActionsMenu
        index={index}
        activeActionBtn={activeActionBtn}
        isDesktop={isDesktop}
        handleOpenMenu={handleOpenMenu}
        actionList={setActionsForCandidates({ candidateItem: item, handleOpenModal })} />}
    </ItemWrap>
    ) : <Skeleton isDesktop={props.isDesktop} />}
    {isSortModalOpen && <MobileSortingModal
      isOpen={isSortModalOpen}
      onClose={() => setSortModalOpen(false)}
      handleMobileSorting={handleMobileSorting}
      mobileSortList={mobileSortList}
      selectedIndex={selectedIndex}
    />}
  </MobileScreen>
};