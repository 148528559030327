import * as React from "react"
import { BaseButton } from "./SPBaseButton.presets"
import { SPBaseButtonProps } from "./SPBaseButton.props"

export const SPBaseButton: React.FC<SPBaseButtonProps> = (props) => {
  return(
    <BaseButton
      data-id={props.testID || "dg-button"}
      type={props.type}
      onClick={props.onClick}
      width={props.width}
      mobileHeight={props.mobileHeight}
      tabIndex={0}
      disabled={props.disabled}>
        {props.children}
    </BaseButton>
  )
}
