import styled from 'styled-components';
import { theme } from '../../../theme';

export const WrapForAudioSection = styled('div')<{isDesktop:boolean}>`
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  background: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin-bottom:  ${theme.offsets.xl};
  padding: ${theme.offsets.xl} ${theme.offsets.xl} ${theme.offsets.xl};
  scroll-margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    border-radius: 0px 0px 14px 14px ;
    margin-top: 1px;
    margin-bottom: ${theme.offsets.lg};
    scroll-margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.md};
    scroll-margin-top: ${theme.offsets.md};
  }
`
export const WrapForRecording = styled('div')`

  display: flex;

`

export const StyleForDuration = styled('p')`
font-family: Apercu Pro;
font-style: normal;
font-weight: normal;
color: rgba(63, 71, 79, 0.7); 
display: flex;
margin: auto;
align-items:center;
justify-content:center;

`

export const StyleForAudioPlayer = styled('div')`
  width: 100%;
  display: flex;
  #waveform{
    flex:1;
    wave{
      border-right: none!important;
    }
  }
  .play-btn{
    background: #0083FF;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 100%;
    margin-right: 24px;
  }
`