import { PropsType } from './SelectDayShift.props';
import { StyleForSelectDayShift } from './SelectDayShift.presents';
import { SelectCom } from '../../../../common/Select';
import { Checkbox } from '../../../../common/Checkbox';
import { useState } from 'react';
import { Field } from 'formik';
import { useEffect } from 'react';

export const SelectDayShift = (props: PropsType) => {
  const [disactive, setDisactive] = useState(props.value && (props.value.from || props.value.to) ? false : true)
  const [timeOptionTo, setTimeOptionTo] = useState(props.timeOption)

  const valueFrom = props.value ? props.value.from : '';

  useEffect(() => {
    if (props.value && props.value.from) {
      let indexFrom = props.timeOption.findIndex(item => item.value === props.value.from);
      const indexTo = props.timeOption.findIndex(item => item.value === props.value.to);
      setTimeOptionTo([...props.timeOption.slice(indexFrom + 1)]);
      //Next option after "From" field value 
      const updatedValue = props.timeOption[++indexFrom].value;
      const updatingFieldName = `mixshiftData.${props.index}.to`;

      //Set bigger "To" that "From"
      if (props.value.to && indexFrom >= indexTo) props.setFieldValue(updatingFieldName, updatedValue);
    }
  }, [valueFrom])

  return <StyleForSelectDayShift>
    <div className='d-flex align-items-center px-0 justify'>
      <Checkbox type="radio"
        name={`mixshiftData.${props.index}.day`}
        labelText={props.title}
        value={props.name}
        handleChange={props.handleChange}
        checked={!disactive}
        clickCallback={() => {
          setDisactive(!disactive)
          props.setFieldValue(`mixshiftData.${props.index}.part`, '')
          props.setFieldValue(`mixshiftData.${props.index}.from`, '')
          props.setFieldValue(`mixshiftData.${props.index}.to`, '')
        }}
      />
    </div>
    <div className='d-flex'>
      <div className='select-wrap'>
        <Field
          name={`mixshiftData.${props.index}.from`}
          options={props.timeOption.slice(0, -1)}
          component={SelectCom}
          disabled={disactive}
          placeholder={'From'}
        />
      </div>
      <div className='select-wrap'>
        <Field
          name={`mixshiftData.${props.index}.to`}
          options={timeOptionTo}
          component={SelectCom}
          disabled={disactive}
          placeholder={'Till'}
        />
      </div>
    </div>
  </StyleForSelectDayShift>
}