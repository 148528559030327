import styled from 'styled-components'

export const StyleForSelectDayShift = styled('div')`
{
  display: flex;
  align-items: center;
  padding:16px 0;
  border-bottom: 1px solid #EEF1F5;
  justify-content: space-between;
  .select-wrap{
    width: 204px;
    margin-left:24px;
  }
  .input-wrap{
    margin-left:14px;
    width: 123px;
  }
};
`