import * as Yup from 'yup';
import { HandleSubmitType } from './Contact.props';

export const handleSubmit = async ({ values, updatingUrl, saveAnswers }: HandleSubmitType) => {
  const valuesCopy = { ...values }
  const arrayWithDependents: any = []
  const stepInfo = {
    title: 'contact',
    nextStep: 'finish',
    done: 5
  }
  saveAnswers({ values: valuesCopy, stepInfo, arrayWithDependents, updatingUrl })
}

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validation = (values: any) => {
  const errors: any = {};
  const validationLogic = (valuesKey: any) => {
    switch (valuesKey) {
      case 'fullName':
      case 'phone':
        if (!values[valuesKey].trim()) {
          errors[valuesKey] = 'Required'
          errors.contactedForJob = 'Required'
        }
        break;
      case 'email':
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required'
          errors.contactedForJob = 'Required'
        } else if (values[valuesKey] && !validateEmail(values[valuesKey])) {
          errors.email = 'Please enter your email in format: name@mail.com'
          errors.contactedForJob = 'Please enter your email in format: name@mail.com'
        }
        break;
      case 'organizationName':
      case 'industry':
        if (!values[valuesKey].trim()) {
          errors[valuesKey] = 'Required'
          errors.mainInfo = 'Required'
        }
        break;
      case 'organizationSize':
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required'
          errors.mainInfo = 'Required'
        }
        break;

      default:
    }
  }
  for (const key in values) {
    validationLogic(key)
  }
  return errors
}

export const validationScheme = (t: any) => Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .required(t('validation.required'))
    .min(3, t('validation.min', { characters: 3 }))
    .max(20, t('validation.max', { characters: 20 })),
  email: Yup.string()
    .required(t('validation.required'))
    .email(t('validation.email')).required(t('validation.required')),
  phone: Yup.string()
    .trim()
    .required(t('validation.required')),
})