import { FieldArray } from "formik"
import { contactsArrayProps } from './Contacts.props'
import { ContactField } from './ContactField'
import { SPSecondaryButton } from "../../buttons/SPSecondaryButton/SPSecondaryButton"
import { ReactComponent as PlusIcon } from '../../../../assets/img/plusBlack.svg';
import { ButtonWrap } from "./Contacts.presents";

export const Contacts = (props: contactsArrayProps) => {
  return <div id='orgContacts'>
    <FieldArray
      name="contactPerson">
      {({ remove, push }) => (
        <div>
          {props.values.contactPerson.length > 0 && props.values.contactPerson.map((item: any, index: number) => <ContactField
            key={index}
            index={index}
            remove={remove}
            values={props.values}
            errors={props.errors.contactPerson}
            handleChange={props.handleChange}
            contactArray={props.values.contactPerson}
            setFieldValue={props.setFieldValue}
          />)
          }
          {props.values.contactPerson && props.values.contactPerson.length < 3 && (
            <ButtonWrap>
              <SPSecondaryButton isLight type={'button'} onClick={() => push({
                logo: {
                  url: '',
                  key: ''
                },
                fullName: '',
                position: '',
                email: '',
                phoneNumber: ''
              })}>
                Contact person
                <PlusIcon />
              </SPSecondaryButton>
            </ButtonWrap>)
          }   
        </div>
      )}
    </FieldArray>
  </div>
}