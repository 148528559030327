import { RestorePasswordDto } from './../../../../../server/src/types/restorePassword.dto';
import axios from 'axios'
import { SignInDto, SignUpDto, EmailDto, EmailResetPasswordDto } from './auth.type';
import { baseURl } from "../../../constants/config";

export const signIn = (data: SignInDto) => {
  return axios.post(`${baseURl}/auth/login`, data, { withCredentials: true })
}

export const verifyEmail = (data: EmailDto) => {
  return axios.post(`${baseURl}/auth/forgot_password`, data)
}


export const restorePassword = (data: RestorePasswordDto) => {
  return axios.post(`${baseURl}/auth/forgot_password/restore`, data)
}

export const restoreCandidatePassword = (data: EmailResetPasswordDto) => {
  return axios.post(`${baseURl}/auth/candidate_forgot_password`, data)
}

export const signUp = (data: SignUpDto) => {
  return axios.post(`${baseURl}/user/sign_up/${data.step}`, data)
}

export const logout = () => {
  return axios.post(`${baseURl}/auth/log-out`)
}

