import { ModalWrap, BtsWrap } from "./TableActionsModal.presents";
import closeIcon from '../../../../assets/img/close.svg';
import spinnerIcon from '../../../../assets/img/spinner.svg';
import { useState } from "react";
import successfully from '../../../../assets/img/successfully.svg';
import errorIcon from '../../../../assets/img/errorResult.svg'
import { Actions, propertyForModal } from './TableActionsModal.props';
import { TableActionsModalHelper } from "./helper";
import { useRootStore } from "../../../../state/stores/storeContext";



export const TableActionsModal = (props: propertyForModal) => {
  const { jobStory, candidatesStore } = useRootStore();
  const [isSpinnerOpen, setSpinnerOpen] = useState(false);
  const [isStatusChanged, setStatusChanged] = useState(false);
  const [isStatusError, setStatusError] = useState(false);

  const showHandlerBtn = !isSpinnerOpen && !isStatusChanged && !isStatusError;

  const modalHelper = new TableActionsModalHelper(props, setSpinnerOpen, setStatusChanged, setStatusError, jobStory, candidatesStore);
  const modalContent = modalHelper.setModalContent();
  const callbackHandler = () => props.typeOfModal === Actions.duplicate ? modalHelper.handleDuplicateTheJob() : modalHelper.handleJobStatus();

  const handleCloseModal = () => {
    props.setShowModal({ isOpen: false, typeOfModal: null, jobId: '', oldJobStatus: '', candidateId: '' });
    setStatusChanged(false);
    setStatusError(false);
  }

  return props.typeOfModal !== null ? <ModalWrap show={props.showModal} onHide={handleCloseModal} centered className='pl-0'>
    <img className='close-btn' src={closeIcon} alt="close" onClick={() => handleCloseModal()} />
    <p className='title'>{modalContent.title}</p>
    {isSpinnerOpen ? // loading
      <div className='content'>
        <img src={spinnerIcon} alt="spinner" className='spinner' />
        <p>Please wait</p>
      </div> :
      isStatusChanged ? // successfull result
        <div className='content'>
          <img src={successfully} alt="successfully" />
          <p>{modalContent.completedText}</p>
        </div> :
        isStatusError ? // error result
          <div className='content'>
            <img src={errorIcon} alt="successfully" />
            <p>{modalContent.completedText}</p>
          </div> :
          // default
          < div className='content'>
            <img src={modalContent.icon} alt="icon" />
            {modalContent.previewText}
          </div>}
    <BtsWrap>
      <button type={'button'} className='close-btn' onClick={() => handleCloseModal()}>{isStatusChanged ? 'Close' : 'Cancel'}</button>
      {showHandlerBtn && <button type={'button'} onClick={callbackHandler} className='add-btn'>{modalContent.textForBtn}</button>}
    </BtsWrap>
  </ModalWrap > : null
}