import { theme } from './../../../../theme/theme';
import { Modal } from 'react-bootstrap';
import { colors } from './../../../../constants/colors';
import styled from 'styled-components';
import playBtnIcon from '../../../../assets/img/jobStory/playBtn.svg';
import mapPointIcon from '../../../../assets/img/jobStory/mapPoint.svg';
import actionIcon from '../../../../assets/img/tableAction.svg'
import { KindOfJobTypes } from './Introduction.props';

export const StyleForWrap = styled('div')`
  width:100%;
  max-width: 1680px;
  margin: 0 auto; 
  @media(max-width: ${theme.screens.mobile}) { 
  margin: 0 -${theme.offsets.lg};
  width: auto;
}   
`

export const GreetingText = styled('p')`
font-size: 16px;
line-height: 20px;
color: rgba(45, 53, 61, 0.7);
@media(max-width: ${theme.screens.desktop}) {
  font-size: 14px;
}
@media(max-width: ${theme.screens.tablet}) {
  font-size: ${theme.fontSizes.md};
}
`
export const OrgName = styled('span')`
text-transform: capitalize;
`


export const BannerImage = styled('img')`
width:100%;
object-fit: contain;
border-radius: 14px;
margin-bottom: -64px;
@media(max-width: ${theme.screens.desktop}) {
  margin-bottom: -${theme.offsets.extraBig};
}
@media(max-width: ${theme.screens.tablet}) {
  margin-bottom: 0;
  border-radius: 0;
}
`

export const MainInfo = styled('div')`
width: 100%;
max-width: 1112px;
background: #FFFFFF;
box-shadow: 10px 10px 20px rgba(63, 71, 79, 0.07);
border-radius: 14px;
margin: auto;
padding: 28px 32px 32px; 
z-index:2;
position: relative;
@media(max-width: ${theme.screens.desktop}) {
  padding: ${theme.offsets.xxl};
}
@media(max-width: ${theme.screens.tablet}) {
  border-radius: 0;
}
@media(max-width: ${theme.screens.mobile}) {
  padding: ${theme.offsets.lg}; 
}
`

export const CompanyLogo = styled('div')`
background: #FFFFFF;
border-radius: 32px;
position: absolute;
top:-64px;
& img {
  padding:8px;
  border-radius: 32px;
  width: 96px;
  height: 96px;
  object-fit: cover;
  @media(max-width: ${theme.screens.tablet}) {
    border-radius: ${theme.variables.borderRadiusXLarge};
    width: 76px;
    height: 76px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    border-radius: ${theme.variables.borderRadiusSmallMedium};
    width: 56px;
    height: 56px;
    padding:${theme.offsets.xs};
  }
}
@media(max-width: ${theme.screens.tablet}) {
  top:-34px;
  border-radius: ${theme.variables.borderRadiusXLarge};
}
@media(max-width: ${theme.screens.mobile}) {
  top:-34px;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
}
`


export const JobTrailerBtn = styled('div')`
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #2D353D;
position: relative;
cursor: pointer;
&::before{
  content: '';
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  background-image: url(${playBtnIcon});
  top: 50%;
  right: calc(100% + 10px);
  transform: translate(0%, -50%);
}
`

export const ActionBtn = styled('button')`
width: 32px;
height: 32px;
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
position: relative;
border: none;
background: none;
margin-right: 0;
margin-left: auto;
@media(max-width: ${theme.screens.tablet}) { 
  width: 24px;
  height: 24px;
}
& .menu{
  position: absolute;
  background: #FFFFFF;
  width: 127px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 18px;
  flex-direction: column;
  display: none;
  z-index: 999;
  top: 40px;
  align-items: start;
  @media(max-width: ${theme.screens.tablet}) { 
    right: 0;
}
  
}
& .menu.open{
  display: flex;
}
& .menu .menu-item{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #FF7474;
  display: flex;
  img{
    margin-right: 6px;
  }
}

& .menu .menu-item.delete-btn{
  margin-bottom: 0px;
  margin-top: 16px;
  color: #FF6666;
}
& .icon{
  mask-image: url(${actionIcon});
  height: 18px;
  width: 4px;
  background-color: #433E3A;
}
&:hover, &.active{
  background-color: #E6EDF6;
  fill: #0083FF;
}
&:hover .icon, &.active .icon{
  background-color: #0083FF;
}
`

export const JobKinds = styled('div')`
display: flex;
@media(max-width: ${theme.screens.tablet}) { 
  margin-top: ${theme.offsets.lg};
}
`


export const TitleBlock = styled('div')`
margin-top: 8px;
display: flex;
align-items: center;
@media(max-width: ${theme.screens.tablet}) { 
  flex-wrap: wrap;
}

`

export const JobPosition = styled('p')`
font-family: Recoleta;
font-size: 36px;
line-height: 44px;
color: ${colors.dark};
&:first-letter{
text-transform: capitalize;
}
@media(max-width: ${theme.screens.desktop}) {
  font-size: ${theme.fontSizes.xxl};
  line-height: ${theme.fontSizes.extraBig};
}
@media(max-width: ${theme.screens.tablet}) {
  width: 100%;
}
@media(max-width: ${theme.screens.mobile}) {
  font-size: ${theme.fontSizes.xl};
  line-height: ${theme.fontSizes.xxl};
}
`

export const KindOfJob = styled('p') <{ type?: KindOfJobTypes }>`
  margin-left: 16px;
  border-radius: 6px;
  padding: 4px 8px 4px 4px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  height:32px;
  align-items: center;
img{
  margin-right: 8px;
  width: 24px;
  height: 24px;
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: ${theme.offsets.xs};
    width: ${theme.offsets.xl};
    height: ${theme.offsets.xl};
}
}
&:first-letter{
  text-transform: capitalize;
}
  border: 1px solid #DFE1E2;
${(props) => props.type === KindOfJobTypes.now && `
  background: #0083FF;
  border: 1px solid #0083FF;
  color: #FFFFFF;`}
${(props) => props.type === KindOfJobTypes.hiring && `
  background: #FF7474;
  border: 1px solid #FF7474;
  color: #FFFFFF;`}
@media(max-width: ${theme.screens.tablet}) { 
  margin-right: ${theme.offsets.md};
  margin-left: 0;
}
@media(max-width: ${theme.screens.mobile}) {
  font-size: ${theme.fontSizes.md};
}
`

export const InfoList = styled('div')`
font-size: 16px;
line-height: 20px;
letter-spacing: -0.01em;
color: rgba(45, 53, 61, 0.7);
padding: 23px 0;
border-top: 1px solid rgba(45, 53, 61, 0.15);
border-bottom: 1px solid rgba(45, 53, 61, 0.15);
margin-top: 24px;
flex-wrap: wrap;
display: flex;
align-items: center;
@media(max-width: ${theme.screens.tablet}) { 
  padding: ${theme.offsets.lg} 0;
  margin-top: ${theme.offsets.lg};
}
`

export const ListItem = styled('p') <{ isModal?: boolean, withPoint?: boolean }>`
display: flex;
align-items: center;
${(props) => props.withPoint && `
&::after{
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #6C7277;
  margin: 0 12px;
}
`}

${(props) => props.isModal && `
text-decoration: underline;
cursor: pointer;
`}
@media(max-width: ${theme.screens.desktop}) {
  font-size: 14px;
}
`

export const JobTrailer = styled('p')`
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.dark};
  @media(max-width: ${theme.screens.tablet}) {
  font-size: 14px;
  margin-top: ${theme.offsets.lg};
}
`

export const StyleForModal = styled(Modal) <{ map: boolean }>`
padding-right: 15px;
    ${(props) => props.map && `
    padding-right: 0px;
    `} 
  .modal-dialog{
    max-width: 703px; 
    ${(props) => props.map && `
      min-width:100%;
      min-height: 100%;
      margin: 0;
      
    `} 
    h1{
      text-align: left;
    }
  }

  .error-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    margin-top: 40px;
    .title{
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.01em;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    .text{
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }

  .modal-content{
    padding:40px;
    min-height: 351px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 14px;
    ${(props) => props.map && `
      min-width:100%;
      min-height: 100vh;
      margin: 0;
      padding: 0px;
      height: 100vh;
      border-radius: 0px;
      @media(max-width: ${theme.screens.tablet}) {
        height: auto;
    }
    `}
    .container-map, .row-map{
      height: 100%;
      @media(max-width: ${theme.screens.tablet}) {
        height: auto;
    }
    }
  };
  
  .company-logo{
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 28px;
    color: ${colors.dark};
    margin-top: 42px;
    & img{
      border-radius: 12px;
      width: 72px;
      height: 72px;
      margin-right: 16px;

    }
  }
  .main-info{
    display: flex;
    margin-top: 40px;
    max-width: 830px;
    
    .view-btn{
      font-weight: 500;
      font-size: 18px;
      color: #0C75FF;
      cursor: pointer;
    }
    .subtitle{
      font-size: 18px;
      line-height: 24px;
      color: rgba(63, 71, 79, 0.8);
      margin-bottom: 8px;
    }
    .second-item{
      margin-top: 24px;
    }
    .text{
      font-size: 24px;
      line-height: 28px;
      color: ${colors.dark};
    }
    .social-links{
      display: flex;
      align-items: center;
      max-width: 200px;
      flex-wrap: wrap;
     a{
      margin-right: 25px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }
    }
  }
  img.close-btn{
    position: absolute;
    right: 40px;
    top: 45px;
    cursor: pointer;
  }
  .title{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: ${colors.dark};
    margin-bottom:24px;
  }
  .day-row{
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ECECEC;
    padding: 24px 0;
    & .day{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #39434D;
    }
  }
  .day-row:last-of-type{
    border-bottom: none;
  }
  .timing{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #928D8A;
    & .text{
      margin: 0 5px;
    }
  }
  .sites{
    margin-right: 20%;
  }
  .point{
    cursor: pointer;
  }
  .location-wrap{
    margin-top: 40px;
    .location-item.active .location-title::before{
      content: '';
      width: 21px;
      height: 25px;
      display: block;
      background-image: url(${mapPointIcon});
      background-size: cover;
    }
    .location-item{
      cursor: pointer;
      padding: 24px 0;
      border-top: 1px solid #ECECEC;
      .location-title{
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 24px;
        color: rgba(63, 71, 79, 0.8);
      }
      .location-value{
        margin-top: 8px;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
`