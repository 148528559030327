import closeIcon from '../../../../../assets/img/close.svg'
import fileIcon from '../../../../../assets/img/documentFile.svg'
import spinnerIcon from '../../../../../assets/img/spinner.svg'
import { sliceFullText } from '../../../../../services/helper'
import { FileContainer, Spinner, Media, MediaName, MediaIcon, RemoveIcon } from './FilePreview.presets'

interface FilePreviewProps {
  name: string
  onRemove: any
  isLoading?: boolean
}

export const FilePreview = (props: FilePreviewProps) => {
  const MEXT_TITLE_LENGTH = 15;
  const icon = fileIcon;

  return (
    <FileContainer>
      <Media>
        {
          props.isLoading
            ? <Spinner src={spinnerIcon} />
            : <MediaIcon src={icon} />
        }
        <MediaName>{sliceFullText(props.name, MEXT_TITLE_LENGTH)}</MediaName>
        {
          !props.isLoading
            ? <RemoveIcon type="button" onClick={props.onRemove}><img src={closeIcon} alt="" /></RemoveIcon>
            : null
        }
      </Media>

    </FileContainer>
  )
}
