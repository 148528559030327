import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { PageTypes } from "../../../pages/Candidates/Candidates.props";
import {
  StyleForWrap,
  NavigationBlock,
  TitleWrap,
  Title,
  BackToListBtn,
  TabsWrap,
  TabItem,
  ListLengthBlock,
  ItemIcon,
  PreviewBtns
} from './Navigation.presents';
import { PropsType } from "./Navigation.props";
import { ReactComponent as ArrowIcon } from '../../../assets/img/arrowNextDark.svg';
import previewIcon from '../../../assets/img/eyeOpen.svg';
import candidateIcon from '../../../assets/img/twoPeople.svg';
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { ThemeBtn, ThemeBtnText, ThemeBtnWrap } from "../../JobStoryBuilder/BasicComponents/Header/Header.presents";

export const Navigation = observer((props: PropsType) => {
  const history = useHistory();

  const changePageHandler = (path: string, activeTab: PageTypes) => {
    history.push(`${path}?id=${props.jobId}`);
    props.setActiveTab(activeTab);
  }

  const isTabActive = (pageType: PageTypes) => {
    return props.activeTab === pageType
  }

  const directToJobBuilder = () => {
    history.push(`/job-story/start?id=${props.jobId}`)
  }

  return <StyleForWrap>
    <NavigationBlock>
      <TitleWrap>
        <BackToListBtn to='/my-jobs'><ArrowIcon /> Back to the list of jobs</BackToListBtn>
        <Title>{props.jobTitle}</Title>
      </TitleWrap>
      <TabsWrap>
        <TabItem isActive={props.activeTab === PageTypes.jobPreview}
          onClick={() => changePageHandler('/job-preview', PageTypes.jobPreview)}>
          <ItemIcon isActive={isTabActive(PageTypes.jobPreview)} imgUrl={previewIcon} />
          Preview
        </TabItem>
        <TabItem isActive={isTabActive(PageTypes.candidates)}
          onClick={() => changePageHandler('/candidates', PageTypes.candidates)}>
          <ItemIcon isActive={isTabActive(PageTypes.candidates)} imgUrl={candidateIcon} />
          Candidates
          <ListLengthBlock>{props.listLength}</ListLengthBlock>
        </TabItem>
      </TabsWrap>
      {props.isPreviewPage && <PreviewBtns>
        <ThemeBtnWrap onClick={() => props.setThemeMenuOpen(true)}>
          <ThemeBtn />
          <ThemeBtnText>Edit style</ThemeBtnText>
        </ThemeBtnWrap>
        <SPSecondaryButton type="button" onClick={directToJobBuilder}>Edit job story</SPSecondaryButton>
      </PreviewBtns>}
    </NavigationBlock>
  </StyleForWrap>
})