export const CalendarIcon = ({ active }: { active?: boolean }) => {


  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M33 0C33.5523 0 34 0.447715 34 1V8C34 8.55228 33.5523 9 33 9C32.4477 9 32 8.55228 32 8V1C32 0.447715 32.4477 0 33 0Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M15 0C15.5523 0 16 0.447715 16 1V8C16 8.55228 15.5523 9 15 9C14.4477 9 14 8.55228 14 8V1C14 0.447715 14.4477 0 15 0Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M7.04819 5.44L13.6265 4H33.3614C35.7904 4 40.1084 5.44 41.9639 5.92L44.494 8.8L45 12.16V16H11.0964H3L4.01205 9.76L7.04819 5.44Z" fill="#F8DDC3" />
    <path d="M3 35.2197V16H44.9999V35.3921C44.9999 42.2484 40.7416 45.9999 33.8677 45.9999H14.1322C7.27997 45.9999 3 42.1837 3 35.2197Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.31594 5.98934C7.47775 3.98127 10.5319 3 14.1322 3H33.8893C37.4996 3 40.5548 3.98083 42.712 5.9903C44.8799 8.00966 46.0109 10.9467 45.9999 14.5869V35.3922C45.9999 39.0328 44.8632 41.9742 42.6937 43.9984C40.5345 46.0129 37.4796 47 33.8677 47H14.1322C10.5261 47 7.4697 45.9939 5.30889 43.9474C3.13992 41.8933 2 38.9109 2 35.2197V14.5862C2 10.9441 3.14316 8.00762 5.31594 5.98934ZM6.6771 7.4547C4.99682 9.01549 4 11.3722 4 14.5862V35.2197C4 38.4926 5.00007 40.9004 6.68415 42.4953C8.3764 44.098 10.8862 45 14.1322 45H33.8677C37.1296 45 39.6409 44.1113 41.3293 42.536C43.0075 40.9703 43.9999 38.6079 43.9999 35.3922V14.5862L43.9999 14.5831C44.0101 11.3682 43.0227 9.01299 41.3488 7.45375C39.6638 5.88416 37.1529 5 33.8893 5H14.1322C10.8803 5 8.36835 5.88372 6.6771 7.4547Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 16C2 15.4477 2.44772 15 3 15H45C45.5523 15 46 15.4477 46 16C46 16.5523 45.5523 17 45 17H3C2.44772 17 2 16.5523 2 16Z" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="13.5" cy="24.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="13.5" cy="33.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="24" cy="24.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="24" cy="33.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="34.5" cy="24.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M56 42C56 50.2843 49.2843 57 41 57C32.7157 57 26 50.2843 26 42C26 33.7157 32.7157 27 41 27C49.2843 27 56 33.7157 56 42Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M41 55C48.1797 55 54 49.1797 54 42C54 34.8203 48.1797 29 41 29C33.8203 29 28 34.8203 28 42C28 49.1797 33.8203 55 41 55ZM41 57C49.2843 57 56 50.2843 56 42C56 33.7157 49.2843 27 41 27C32.7157 27 26 33.7157 26 42C26 50.2843 32.7157 57 41 57Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.3116 40C35.8453 40 35 40.9465 35 41.7303V45.2697C35 46.0535 35.8453 47 37.3116 47H44.6884C46.1547 47 47 46.0535 47 45.2697V41.7303C47 40.9465 46.1547 40 44.6884 40H37.3116ZM33 41.7303C33 39.4982 35.1191 38 37.3116 38H44.6884C46.8809 38 49 39.4982 49 41.7303V45.2697C49 47.5018 46.8809 49 44.6884 49H37.3116C35.1191 49 33 47.5018 33 45.2697V41.7303Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M43 36H39V38H43V36ZM39 34C37.8954 34 37 34.8954 37 36V40H45V36C45 34.8954 44.1046 34 43 34H39Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
