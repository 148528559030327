import { SPPrimaryButton } from "../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import {
  Wrapper,
  VideoContainer,
  Title, Subtitle,
  RecordingBlock,
  Separator,
  UploadingBlock,
  VideoPlayerWrap,
  VideoPreviewButtons,
  PlayerWrap,
  HelperTextWrap,
  UploadingBlockDescription,
  InfoText,
  BlockedDeviceWrap,
  BlockedDeviceIcon,
  SkipButtonWrap,
  SaveButtonWrap
} from "./VideoCV.presents";
import { ReactComponent as UploadIcon } from "../../../assets/img/uploadWhite.svg";
import { ReactComponent as CameraIcon } from "../../../assets/img/cameraWhite.svg";
import { DropArea } from "../BaseComponents/DropArea/DropArea";
import { useState } from "react";
import { VideoRecord } from "../BaseComponents/VideoRecord/VideoRecord";
import { VideoPlayer } from "../BaseComponents/VideoPlayer/VideoPlayer";
import { RemoveVideoBtn } from "./VideoCV.presents";
import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';
import { ReactComponent as VideoIcon } from '../../../assets/img/videoUpload.svg';
import { ReactComponent as AudioIcon } from '../../../assets/img/audioUpload.svg';
import { ReactComponent as CheckedGrey } from '../../../assets/img/resumeBuilder/checkedGrey.svg';
import { ReactComponent as Spinner } from '../../../assets/img/simpleSpinner.svg';
import { SPLightButton } from "../../common/buttons/SPLightButton/SPLightButton";
import { HelperText } from "../../common/HelperText";
import { SPBaseButton } from "../../common/buttons/SPBaseButton";
import { onUploadVideoResume } from "../../../services/firebase/firestore/writes";
import { useRootStore } from "../../../state/stores/storeContext";
import { RemoveModalWindow } from "./RemoveModalWindow";
import { CandidateHeader } from "../BaseComponents/Header/Desktop/Header";
import useGetDevice from "../../../hooks/useGetDevice";
import { MobileCandidateHeader } from "../BaseComponents/Header/Mobile/MobileAdminHeader";
import { useHistory } from "react-router-dom";
import { ErrorMsg } from "../../../theme/typography";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";

export const VideoCV = () => {
  const { candidateStore } = useRootStore();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isRecordWindowOpen, setRecordIsWindowOpen] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState({ isOpen: false, isRemoveContent: true });
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraBlocked, setIsCameraBlocked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSaveBtn, setShowSaveBtn] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isFileSaved, setIsFileSaved] = useState(false);


  const isDesktop = useGetDevice();
  const history = useHistory();

  const BYTES_IN_MB = 1048576; // 1 megabyte
  const MAX_SIZE = 100 * BYTES_IN_MB;

  const SIZE_ERROR_MSG = 'Maximum file size is 100 MB';

  if (candidateStore.hideVideoCVPage) {
    history.push('/candidate/profile');
  }


  const inputOnChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files[0].size < MAX_SIZE) {
      const file = target.files[0];
      setUploadedFile(file);
      await onUploadVideoResume(file, candidateStore.uid) as any
      setErrorMessage('');
    } else {
      setErrorMessage(SIZE_ERROR_MSG);
    }
  };

  const startRecordingHandler = () => {
    setIsLoading(true);
    setShowSaveBtn(false)
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    }).then((stream) => {
      setRecordIsWindowOpen(true);
      setIsLoading(false);
    })
      .catch((err) => {
        setIsCameraBlocked(true);
        alert("To record videos, grant access to the camera and microphone permissions on your device.")
      })
  }

  const handleUploadResume = async () => {
    if (uploadedFile) {
      setIsLoading(true);
      const result = await onUploadVideoResume(uploadedFile, candidateStore.uid) as any
      setIsFileSaved(true)
      setIsLoading(false);
      if (!result.error) {
        setIsSaved(true);
        setTimeout(() => {
          setShowSaveBtn(false);
          setIsSaved(false);
        }, 3000);
      };
    }
  }


  const helperText = <div>
    <p>You have <strong>60 seconds</strong> to introduce yourself.</p>
    <p>Tell about yourself honestly.</p>
    <p>You will be able to watch the recorded video.</p>
  </div>

  const redirectToResumeBuilder = async () => {
    setIsLoading(true);
    if (!isFileSaved && uploadedFile) {
      await onUploadVideoResume(uploadedFile, candidateStore.uid) as any
    }
    await candidateStore.redirectFromVideoCVPage();
    history.push('/candidate/resume-builder')
    setIsLoading(false);
  }



  return <>
    {isDesktop ? <CandidateHeader isLogoHide={false} /> : <MobileCandidateHeader />}
    <Wrapper>
      <Title>Let’s start from your video presentation!</Title>
      <Subtitle>{!uploadedFile ? 'Record or upload your video' : 'Check your video'}</Subtitle>


      {!uploadedFile && <>
        <VideoContainer>
          <HelperTextWrap>
            <HelperText text={helperText} />
            <span>Tips</span>
          </HelperTextWrap>

          <RecordingBlock>
            {!isCameraBlocked && <SPPrimaryButton type='button' width="100%" onClick={startRecordingHandler} disabled={isLoading}>
              <CameraIcon />
              Record your video
            </SPPrimaryButton>}
            {isCameraBlocked && <BlockedDeviceWrap>
              <span>To record video, your browser will need to request access to your camera & microphone.</span>
              <div>
                <BlockedDeviceIcon>
                  <VideoIcon />
                </BlockedDeviceIcon>
                <BlockedDeviceIcon>
                  <AudioIcon />
                </BlockedDeviceIcon>
              </div>

            </BlockedDeviceWrap>}
          </RecordingBlock>

          <Separator>Or</Separator>

          <UploadingBlock>
            <DropArea setUploadedFile={setUploadedFile} setErrorMessage={setErrorMessage} />
            <SPPrimaryButton type='button' width="100%" disabled={isLoading}>
              <input onChange={inputOnChangeHandler} type="file" accept={"video/m4v, video/mp4, video/quicktime"} disabled={isLoading} />
              <UploadIcon />
              Choose file
            </SPPrimaryButton>
            <UploadingBlockDescription>
              Or drag and drop file here
            </UploadingBlockDescription>
            <InfoText>
              Video format: M4V, MP4, MOV. Max. File Size: 100MB
            </InfoText>

            <InfoText>
              To record videos, don't forget to grant access to the camera and microphone permissions on your device.
            </InfoText>
            {errorMessage &&
              <ErrorMsg>{errorMessage}</ErrorMsg>
            }
          </UploadingBlock>

        </VideoContainer>

        <SkipButtonWrap>
          <SPBaseButton width="100%" type="button" onClick={redirectToResumeBuilder}>
            Skip
          </SPBaseButton>
        </SkipButtonWrap>

      </>}

      {uploadedFile && <VideoPlayerWrap>
        <RemoveVideoBtn
          type='button'
          onClick={() => { setIsRemoveWindowOpen({ isOpen: true, isRemoveContent: true }); setShowSaveBtn(true) }}>
          <CloseIcon />
          Delete video
        </RemoveVideoBtn>
        <PlayerWrap>
          <VideoPlayer dataSrc={URL.createObjectURL(uploadedFile)} />
        </PlayerWrap>
        <VideoPreviewButtons>
          <SPLightButton width="100%" type="button" onClick={() => { setIsRemoveWindowOpen({ isOpen: true, isRemoveContent: false }); setShowSaveBtn(true) }}>
            Back
          </SPLightButton>
          {!showSaveBtn && <SPPrimaryButton isLoading={isLoading} width="100%" type="button" onClick={redirectToResumeBuilder}>
            Next Step
          </SPPrimaryButton>
          }
          {showSaveBtn &&
            <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved} onClick={handleUploadResume}>
              <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved && uploadedFile) && 'Save'}
              </SaveButtonWrap>
            </SPSecondaryButton>

          }
        </VideoPreviewButtons>
      </VideoPlayerWrap>}


      {/* Modal Windows */}

      {isRecordWindowOpen && <VideoRecord
        isWindowOpen={isRecordWindowOpen}
        setIsWindowOpen={setRecordIsWindowOpen}
        setUploadedFile={setUploadedFile}
      />}

      <RemoveModalWindow setUploadedFile={setUploadedFile} setIsRemoveWindowOpen={setIsRemoveWindowOpen} isRemoveWindowOpen={isRemoveWindowOpen} />

    </Wrapper>
  </>
}
