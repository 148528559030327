import { colors } from '../../../../../../constants/colors';
import PhoneInput from 'react-phone-number-input'
import styled from 'styled-components'
import Arrow from '../../../../../../assets/img/arrow.svg'
import { theme } from '../../../../../../theme';

export const StyleForPhoneInput = styled(PhoneInput)`
input { 
  border: 1px solid #D1D7E3;
  border-radius: 10px;
  height: 52px;
  padding: 18px;
  //margin-bottom: 8px;
  background-color: #fff!important;
  color: ${colors.dark};
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.tablet}) {
    padding: 18px ${theme.offsets.md};
  }
};
.PhoneInputCountry {
  max-width: 122px;
  width: 100%;
  border: 1px solid #D1D7E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px ${theme.offsets.lg};
  justify-content: space-between;
  margin-right: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 100px;
    margin-right: ${theme.offsets.md};
    padding: 18px ${theme.offsets.md};
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 80px;
  }  
  } 
.PhoneInputCountrySelectArrow{
  border:none;
  content: url(${Arrow});
  width: 16px;
  height: 9px;
  transform: unset;
  }
`

export const StyleForLabel = styled("label")`
  font-size: 14px;
  margin-bottom: ${theme.offsets.sm};
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `

export const StyleForForm = styled("form")`
  .error input, .error .PhoneInputCountry {
    border: 1px solid ${colors.red};
  };
  `

export const ButtonWrap = styled('div')`
width: 100%;
margin-top: ${theme.offsets.sm};
@media(max-width: ${theme.screens.tablet}) {
  button{
  height: 44px;
  }
}
`
export const DescriptionText = styled("p")`
  font-size: 18px;
  color: ${theme.colors.black};
  margin: ${theme.offsets.lg} 0 ${theme.offsets.extraMajor};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.lg} 0 ${theme.offsets.extraBig};
  }
`

export const DescriptionLink = styled("button")`
  cursor: pointer;
  background: none;
  border: none;
  color: ${theme.colors.blue};
  font-weight: 500;
  flex-direction: column;
`

export const HelperText = styled("span")`
  position: absolute;
  max-width: 350px;
  width: 100%;
  border: 1px solid ${theme.colors.gray};
  box-sizing: border-box;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  padding: ${theme.offsets.lg};
  left: 95%;
  background-color: ${theme.colors.white};
  transform: translate(0%,-70%);
  @media(max-width: ${theme.screens.desktop}) {
    left: 50%;
    bottom: 100%;
    transform: translate(-50%,-5%);
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 300px;
  }
`

export const ReCAPTCHAWrap = styled('div')`
  margin: ${theme.offsets.lg} auto ${theme.offsets.extraBig};
`

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`