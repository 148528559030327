import { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import { StyleForAudioPlayer, StyleForDuration } from "./AudioSection.presents";
import { ReactComponent as PlayIcon } from '../../../assets/img/organization/play.svg';
import { ReactComponent as PauseIcon } from '../../../assets/img/organization/pause.svg';

const formWaveSurferOptions = (ref: any) => ({
    container: ref,
    waveColor: "#6C7277",
    progressColor: "#0083FF",
    //cursorColor: "#fff",
    barWidth: 2,
    barRadius: 0,
    responsive: true,
    height: 40,
    barGap: 3,
    barHeight: 1,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    plugins: [

    ]
});

function getTime(time: number) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
}


export default function Waveform({ url }: { url: string }) {
    const waveformRef = useRef(null);
    let wavesurfer = useRef<any>(null);
    const [playing, setPlay] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [currentTime, setCurrentTime] = useState("00:00");


    // create new WaveSurfer instance
    // On component mount and when url changes
    useEffect(() => {
        setPlay(false);

        const options = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(url);

        wavesurfer.current.on("ready", function () {
            if (wavesurfer.current) {
                wavesurfer.current.setVolume(volume);

                setVolume(volume);
                setCurrentTime(getTime(wavesurfer.current.getDuration() - wavesurfer.current.getCurrentTime()));
                setIsReady(true)
            }
        });
        wavesurfer.current.on("audioprocess", () => {
            if (wavesurfer.current && wavesurfer.current.getCurrentTime())
                setCurrentTime(getTime(wavesurfer.current.getDuration() - wavesurfer.current.getCurrentTime()))
        });
        wavesurfer.current.on("seek", () => {
            if (wavesurfer.current && wavesurfer.current.getCurrentTime())
                setCurrentTime(getTime(wavesurfer.current.getDuration() - wavesurfer.current.getCurrentTime()))
        });
        return () => wavesurfer.current.destroy();
    }, [url, volume]);

    const handlePlayPause = () => {
        setPlay(!playing);
        wavesurfer.current.playPause();
    };




    return (

        <StyleForAudioPlayer >
            <div className="controls">
                <button disabled={!isReady} className='play-btn' onClick={handlePlayPause}>{!playing ?
                    <PlayIcon />
                    :
                    <PauseIcon />
                }</button>
            </div>
            <div id="waveform" ref={waveformRef} />
            <StyleForDuration>-{currentTime}</StyleForDuration>
        </StyleForAudioPlayer>
    );
}