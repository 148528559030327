export const PrideWork = ({ color }: { color: string; }) => {
  return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="44" height="44" rx="8" fill={color}/>
  <path d="M30.3611 22.7315C29.9911 22.7315 29.6811 22.4515 29.6411 22.0815C29.4011 19.8815 28.2211 17.9015 26.4011 16.6415C26.0711 16.4115 25.9911 15.9615 26.2211 15.6315C26.4511 15.3015 26.9011 15.2215 27.2311 15.4515C29.4011 16.9615 30.8011 19.3215 31.0911 21.9315C31.1311 22.3315 30.8411 22.6915 30.4411 22.7315C30.4111 22.7315 30.3911 22.7315 30.3611 22.7315Z" fill="white"/>
  <path d="M13.7393 22.7812C13.7193 22.7812 13.6893 22.7812 13.6693 22.7812C13.2693 22.7412 12.9793 22.3812 13.0193 21.9812C13.2893 19.3712 14.6693 17.0112 16.8193 15.4912C17.1393 15.2612 17.5993 15.3412 17.8293 15.6612C18.0593 15.9912 17.9793 16.4412 17.6593 16.6712C15.8593 17.9512 14.6893 19.9312 14.4693 22.1212C14.4293 22.5012 14.1093 22.7812 13.7393 22.7812Z" fill="white"/>
  <path d="M25.9886 31.1003C24.7586 31.6903 23.4386 31.9903 22.0586 31.9903C20.6186 31.9903 19.2486 31.6703 17.9686 31.0203C17.6086 30.8503 17.4686 30.4103 17.6486 30.0503C17.8186 29.6903 18.2586 29.5503 18.6186 29.7203C19.2486 30.0403 19.9186 30.2603 20.5986 30.3903C21.5186 30.5703 22.4586 30.5803 23.3786 30.4203C24.0586 30.3003 24.7286 30.0903 25.3486 29.7903C25.7186 29.6203 26.1586 29.7603 26.3186 30.1303C26.4986 30.4903 26.3586 30.9303 25.9886 31.1003Z" fill="white"/>
  <path d="M22.0505 12.0117C20.5005 12.0117 19.2305 13.2717 19.2305 14.8317C19.2305 16.3917 20.4905 17.6517 22.0505 17.6517C23.6105 17.6517 24.8705 16.3917 24.8705 14.8317C24.8705 13.2717 23.6105 12.0117 22.0505 12.0117Z" fill="white"/>
  <path d="M15.0505 23.8711C13.5005 23.8711 12.2305 25.1311 12.2305 26.6911C12.2305 28.2511 13.4905 29.5111 15.0505 29.5111C16.6105 29.5111 17.8705 28.2511 17.8705 26.6911C17.8705 25.1311 16.6005 23.8711 15.0505 23.8711Z" fill="white"/>
  <path d="M28.9489 23.8711C27.3989 23.8711 26.1289 25.1311 26.1289 26.6911C26.1289 28.2511 27.3889 29.5111 28.9489 29.5111C30.5089 29.5111 31.7689 28.2511 31.7689 26.6911C31.7689 25.1311 30.5089 23.8711 28.9489 23.8711Z" fill="white"/>
  </svg>
  
}