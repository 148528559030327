import * as React from "react"
import { useState } from "react"
import { isMobile } from "../../../../theme/screens"
import { useWindowSize } from "../../../../utils/useWindowSize"
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton"
import { JobStoryCard } from "../../../common/Cards/JobStoryCard"
import { GalleryColumn, GalleryItem, GalleryItemMobile, GalleryItemOverlay, GalleryItemOverlayText, GalleryRow } from "./GallerySection.presets"
import { GallerySectionProps } from "./GallerySection.props"
import speakerCircle from '../../../../assets/img/jobStory/speakerCircle.svg';
import playCircle from '../../../../assets/img/jobStory/playCircle.svg';
import { MediaModal } from "./MediaModal"

export const GallerySection: React.FC<GallerySectionProps> = (props) => {
  const size = useWindowSize()
  const isMobileView = isMobile(size.width)

  const heroImage = props.mediaFiles[0]
  const secondImage = props.mediaFiles[1]
  const thirdImage = props.mediaFiles[2]
  const fourthImage = props.mediaFiles[3]
  const fifthImage = props.mediaFiles[4]

  const isSeeAllShown = props.mediaFiles.length > 5
  const isSeeAllShownMobile = props.mediaFiles.length > 1


  const [isModalOpen, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClose = () => {
    setSelectedIndex(0)
    setShowModal(false);
  }

  const handleShow = (index: number = 0) => {
    setSelectedIndex(index)
    setShowModal(true);
  }


const renderGalleryItem = (media:any, index: number, isLarge: boolean = false) => {
  if (media.type === "video") {
    return <div>
      <GalleryItem large={isLarge} mediaTitle={media.fileTitle || media.filename} onClick={()=>handleShow(index)}>  
        <img src={playCircle} alt="" />
      </GalleryItem>
    </div>
  } else if (media.type === "audio") {
    return <>
      <GalleryItem large={isLarge} mediaTitle={media.fileTitle || media.filename} onClick={()=>handleShow(index)}/>  
      <img src={speakerCircle} alt="" />
    </>
  } else {
    return <GalleryItem large={isLarge} imageUrl={media.url} onClick={()=>handleShow(index)}/>
  }
}



  const desktopGalleryView = () => {
    return (
      <GalleryRow>
        {/* Left column */}
        <GalleryColumn>
          { renderGalleryItem(heroImage, 0, true) }
        </GalleryColumn>

        {/* Right column */}
        { secondImage && <GalleryColumn offsetLeft>
          <GalleryRow>
            <GalleryColumn>
              { renderGalleryItem(secondImage, 1, props.mediaFiles.length===2) }
            </GalleryColumn>
            {
              fifthImage && <GalleryColumn offsetLeft>
                { renderGalleryItem(fifthImage, 4) }
              </GalleryColumn>
            }
            </GalleryRow>
            {
              thirdImage && <GalleryRow offsetTop>
                <GalleryColumn>
                  { renderGalleryItem(thirdImage, 2) }
                </GalleryColumn>
                {
                  fourthImage && <GalleryColumn offsetLeft>
                    { renderGalleryItem(fourthImage, 3) }
                    {
                      isSeeAllShown && <GalleryItemOverlay>
                        <GalleryItemOverlayText>See All Pictures</GalleryItemOverlayText>
                      </GalleryItemOverlay>
                    }
                  </GalleryColumn>
                }
              </GalleryRow>
            }
        </GalleryColumn> }
      </GalleryRow>
    )
  }

  const mobileGalleryView = () => {
    return (
      <>
        <GalleryItemMobile imageUrl={heroImage.url} onClick={()=>handleShow(0)}/>
        { isSeeAllShownMobile && <SPPrimaryButton width="100%" onClick={()=>handleShow(0)}>See All Gallery</SPPrimaryButton> }
      </>
    )
  }

  return(
    <JobStoryCard title={`Gallery`} componentRef={props.componentRef}>
      {
        isMobileView 
          ? mobileGalleryView()
          : desktopGalleryView()
      }
      { isModalOpen && <MediaModal isOpen={isModalOpen} mediaFiles={props.mediaFiles} selectedIndex={selectedIndex} onClose={() => handleClose()}/> }
    </JobStoryCard>
  )
}
