import { useRef } from "react"
import useGetDevice from "../../../../../../hooks/useGetDevice"
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton"
import { BenefitsList } from "../../BaseComponents/Benefits/BenefitsList"
import { Header } from "../../BaseComponents/Header/Header"
import { IntroForm } from "../../BaseComponents/IntroForm/IntroForm"
import { VideoBanner } from "../../BaseComponents/VideoBanner/VideoBanner"
import {
  ContentWrap,
  CoverBlock,
  Wrapper,
  PageDescription,
  PageSubtitle,
  PageDescriptionTitle,
  BenefitsSection,
  BenefitsSectionTitle,
  InfoSection,
  InfoSectionTitle,
  InfoSectionDescription,
  InfoContent,
} from "./IntroStep.presents"

export const IntroStep = () => {

  const coverSectionRef: any = useRef(null);
  const executeScroll = () => coverSectionRef.current?.scrollIntoView();
  const isDesktop = useGetDevice();

  return <Wrapper>

    {/* Cover Section */}
    <CoverBlock ref={coverSectionRef} isDesktop={isDesktop}>
      <ContentWrap>
        <Header />
        <IntroForm />
      </ContentWrap>
      {isDesktop && <VideoBanner />}
    </CoverBlock>

    {/* Benefits Section */}
    <BenefitsSection>
      <ContentWrap>
        <PageSubtitle>
          Landing a job has never been easier
        </PageSubtitle>
        <BenefitsSectionTitle>
          Here's how it works
        </BenefitsSectionTitle>
        <BenefitsList />
      </ContentWrap>
    </BenefitsSection>

    {/* Description Section */}
    <PageDescription>
      <ContentWrap>
        <PageSubtitle isLight>
          Video Grabs Hiring Managers Attention
        </PageSubtitle>
        <PageDescriptionTitle>
          Recruiters and hiring managers retain
          95% of a video’s message compared to 10% when reading text on your resume.
        </PageDescriptionTitle>
      </ContentWrap>
    </PageDescription>

    {/* Info Section */}
    <InfoSection>
      <ContentWrap>
        <InfoContent>
          <InfoSectionTitle>
            Let your resume stand out and get hired!
          </InfoSectionTitle>
          <InfoSectionDescription>
            <li>Pique interest and catch attention of recruiters</li>
            <li>Credentialize yourself to hiring managers</li>
            <li>Showcase your expertise and qualifications</li>
            <li>Beat the competition and be the first to be interviewed and hired</li>
            <li>Increase your chance of hiring by 10x</li>
            <li>Build a brand around your expertise</li>
          </InfoSectionDescription>
          <SPPrimaryButton
            onClick={executeScroll}
            type="button"
            width="100%">
            Get Started
          </SPPrimaryButton>
        </InfoContent>
      </ContentWrap>
    </InfoSection>

  </Wrapper>
}