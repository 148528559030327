import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { theme } from '../../../../theme';

export const SortingModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: ${theme.variables.borderRadiusSmallMedium};
    padding: ${theme.offsets.xl};
    
    .modal-body {
      padding: 0;
    }
  }
`

export const MenuItem = styled('span') <{ isLastItem?: boolean }>`
  font-size: 14px;
  line-height: ${theme.offsets.lg};
  color: ${theme.colors.black};
  margin-top: ${theme.offsets.lg};
  border-bottom: ${(props) => props.isLastItem ? 'none' : `1px solid ${theme.colors.lightGray}`} ;
  width: 100%;
  padding-bottom: ${(props) => props.isLastItem ? '0' : `${theme.offsets.lg}`};
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const ModalHeader = styled('div') <{ isLastItem?: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${theme.offsets.lg};
  
`

export const CloseButton = styled('button')`
  margin-left: auto;
  background: transparent;
  border: none;
  svg {
    height: 14px;
    width: 14px;
  }
`

export const ItemPoint = styled('div') <{ checked: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.checked ? `${theme.colors.lightOrange}` : `${theme.colors.lightGray}`};
  position: relative;
  margin-right: ${theme.offsets.sm};
  &::before{
    content: '';
    display: ${(props) => props.checked ? 'block' : 'none'};
    width: 12px;
    height: 12px;
    background: ${theme.colors.lightOrange};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }
`
