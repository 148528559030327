import { UploadButtonContainer } from './UploadButton.presets'
import plusBlack from '../../../../../assets/img/plusBlack.svg';

interface UploadButtonProps {
  text: string
  onChange: any
  multiple?: boolean
  isLoading?: boolean
  fileTypes?: string
  icon?: string
}

export const UploadButton = (props: UploadButtonProps) => {
  const btnIcon = props.icon || plusBlack;
  return (
    <UploadButtonContainer type="button" disabled={props.isLoading}>
      {props.text} 
      <img src={btnIcon} alt="Upload photos" />
      <input
        multiple={props.multiple}
        disabled={props.isLoading}
        type="file"
        onChange={props.onChange}
        accept={props.fileTypes} />
    </UploadButtonContainer>
  )
}
