import { Formik } from "formik"
import { FieldsWrap, ErrorText } from "./Support.presents"
import { Input } from "../../common/Input";
import { Button } from "../../common/Button";
import { SelectCom } from "../../common/Select";
import { sendSupportMessage } from "../../../services/api/user/user.domains";
import { useState } from "react";
import { ModalForResults } from "../../common/Modal/ModalForResults";
import { useHistory } from "react-router";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { Wrapper, Title, ButtonWrap, FieldWrap } from "../AdminPanel.presents";
import { PropsType } from "./Support.props";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";

const feedbackOptionsList = [
  { label: 'Report a bug', value: 'Report a bug' },
  { label: 'Report an incident', value: 'Report an incident' },
  { label: 'Suggest a feature', value: 'Suggest a feature' },
  { label: 'Give general feedback', value: 'Give general feedback' },
  { label: 'Other', value: 'Other' }
]

export const Support = (props: PropsType) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');
  const [isSubmitted, setSubmitted] = useState(false);
  const [isBtnDisable, setBtnDisable] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const successfullMessage = 'Thanks for reaching out. If you requested help, a member of our support team will contact you shortly';
  const errorMessage = 'Something went wrong. Please try again later';

  const autoСloseWindow = () => {
    setTimeout(() => {
      closeWindow();
      setBtnDisable(false);
    }, 5000)
  };

  const closeWindow = () => {
    setModalOpen(false);
    history.replace('/my-jobs');
  }

  return <Wrapper isDesktop={props.isDesktop}>
    <Formik
      initialValues={{ subject: '', message: '' }}
      validateOnChange={isSubmitted}
      validationSchema={Yup.object().shape({
        subject: Yup.string().required('Required'),
        message: Yup.string().min(3, '')
          .min(10, t('validation.min', { characters: 10 }))
          .max(3000, t('validation.max', { characters: 3000 }))
          .required('Required')
      })}
      onSubmit={async (values) => {
        setBtnDisable(true);
        const serverResponse = await sendSupportMessage({ subjectOfSupport: values.subject, message: values.message });
        setResponseResult(serverResponse.data.err ? 'error' : 'successfully');
        setModalOpen(true);
        autoСloseWindow();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className='d-flex flex-column'>
          <FieldsWrap>
            <Title isDesktop={props.isDesktop}>Ask for help or send us feedback</Title>
            <FieldWrap
              component={SelectCom}
              options={feedbackOptionsList}
              name={'subject'}
              placeholder='Choose the reason'
              labelText={'Subject'}
              error={errors.subject}
            />
            <ErrorText>{errors.subject}</ErrorText>
            <FieldWrap
              as={Input}
              name={'message'}
              onChange={handleChange}
              value={values.message}
              type='textarea'
              labelText='Your Message'
              error={errors.message}
            />
            <ErrorText>{errors.message}</ErrorText>
            <ButtonWrap>
              <SPSecondaryButton
                width="100%"
                disabled={isBtnDisable}
                type="submit"
                onClick={() => setSubmitted(true)}>
                Send
              </SPSecondaryButton>
            </ButtonWrap>
          </FieldsWrap>
        </form>
      )}
    </Formik>
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={closeWindow}
      text={responseResult === 'successfully' ? successfullMessage : errorMessage}
    />
  </Wrapper>
}