import { UploadButton, Wrapper, DeleteButton, FieldWrap, PreloaderWrap } from "./Audio.presents";
import { ReactComponent as AudioImg } from '../../../../../assets/img/audioUpload.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/close.svg';
import { useState } from "react";
import { Preloader } from "../../../../common/Preloader";
import { generateSignedUrl, s3upload } from "../../../../../services/api/fileManagement/fileManagement.domains";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { MainSectionProps } from "../../Organization.props";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { Field } from "formik";
import { Input } from "../../../../common/Input";
import { checkFileSizeInMB, sliceFullText } from "../../../../../services/helper";
import { HelperTextTitle } from "../../Organization.presents";
import { HelperText } from "../../../../common/HelperText";

export const Audio = (props: MainSectionProps) => {
  const { componentRef, isDesktop, values, handleChange, setFieldValue } = props;

  const { organizationStore } = useRootStore();
  const [isLoading, setLoading] = useState(false);

  const MAX_AUDIO_SIZE = 100;
  const UPLOAD_FOLDER = "videos";

  const audioUrl = values.audioUrl;
  const audioFileId = values.audioFileId;
  const uploadFile = async (event: any) => {
    if (!event.target.files) return

    let uploadFile = event.target.files[0];
    if (!checkFileSizeInMB(uploadFile.size, MAX_AUDIO_SIZE)) {
      event.target.value = null;
      return alert(`Maximum audio size is ${MAX_AUDIO_SIZE} megabytes`)
    }

    setLoading(true)
    const resp = await generateSignedUrl({ filename: uploadFile.name, filetype: uploadFile.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, uploadFile)
        const addBannerDto = {
          audio: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            fileName: sliceFullText(uploadFile.name.replace(/\.[^/.]+$/, ""), 40) ,
          }
        }
        await organizationStore.saveSingleFile(addBannerDto)
        setFieldValue('audioUrl', addBannerDto.audio.url)
        setFieldValue('audioFileId', addBannerDto.audio.key)
        setFieldValue('audioName', addBannerDto.audio.fileName)
      } catch (error) {
        console.log(error)
      }
    }
    event.target.value = null; // reset input value
    setLoading(false)
  }

  const removeFile = async () => {
    setLoading(true)

    const removeFileDto = {
      dataForUpdate: {
        audio: null
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: audioFileId
    }

    await organizationStore.deleteSingleFile(removeFileDto)
    setFieldValue('audioUrl', '')
    setFieldValue('audioFileId', '')
    setFieldValue('audioName', '')
    setLoading(false)
  }

  const UploadButtonTitle = isLoading ? "Updating..." : 'Upload';

  const helperText = 'You can upload a voice file about your company, culture or jobs. Maximum size for a voice file cannot exceed 100MB. Only once voice explainer file can be uploaded.'
  const TitleRender = () => <HelperTextTitle>
    <span>{'Audio'}</span>
    <HelperText text={helperText} />
  </HelperTextTitle>

  return <OrganizationCard title={<TitleRender />} componentRef={componentRef} isDesktop={isDesktop} width='100%'>
    <Wrapper>
      <UploadButton disabled={!!audioUrl} type='button' isDesktop={isDesktop} isFileLoad={!!audioUrl}>

        {isLoading ? <PreloaderWrap isFileLoad={!!audioUrl}>
          <Preloader />
        </PreloaderWrap> :
          <AudioImg />}

        {!audioUrl ? <>
          <input onChange={uploadFile} type="file" accept={"audio/*"} disabled={isLoading} />
          {UploadButtonTitle}
        </> : <DeleteButton type='button' onClick={() => removeFile()}>
          <CloseIcon />
        </DeleteButton>
        }
      </UploadButton>

      {audioUrl && <FieldWrap>
        <Field
          as={Input}
          maxLength={50}
          name={'audioName'}
          type={'text'}
          value={values.audioName}
          onChange={handleChange}
          labelText={'Title'} />
      </FieldWrap>
      }
    </Wrapper>
  </OrganizationCard>
}