import { colors } from './../../../constants/colors';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import actionIcon from '../../../assets/img/tableAction.svg'
import { theme } from '../../../theme';

export const StyleForTable = styled(Table)`
  thead{
    background: #F6F8FA;
    th{
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.01em;
      color: #7B7875;
      padding: 24px 16px; 
      :last-child{
        text-align: right;
      }
      .sort-btn{
        border: none;
        background: none;
      }
      .sort-btn.revert{
        transform: rotate(180deg);
      }
    }
  }
  thead th:first-child {
    border-radius: 12px 0 0 12px;
  }
  thead th:last-child {
    border-radius: 0 12px 12px 0;
  }
  tbody{
    td{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: ${colors.dark};
      padding: 32px 16px;
      border-top: none;
      border-bottom: 1px solid #D1D7E3;
      max-width: 200px;
    }
  }
  .action-btn{
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border: none;
    background: none;
    margin-left: auto;
    margin-right: 0;
  }

  .action-btn .menu{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), -4px -4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    width: 258px;
    padding:16px;
    flex-direction: column;
    display: none;
    z-index: 999;
    top: 40px;
    align-items: start;
  }
  .action-btn .menu.open{
    display: flex;
  }
  .action-btn .menu .menu-item{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: ${colors.dark};
    margin-bottom: 16px;
    &:hover {
      color: ${theme.colors.lightOrange};
    }
  }

  .action-btn .menu .menu-item.delete-btn{
    margin-bottom: 0px;
    margin-top: 16px;
    color: #FF6666;
  }

  hr{
    margin-top: 0px;
    margin-bottom: 0px;
    border-top: none;
    display: block;
    width: 100%;
    height: 1px;
    background: #D1D7E3;
  }
  .action-btn .icon{
    mask-image: url(${actionIcon});
    height: 18px;
    width: 4px;
    background-color: #433E3A;
  }
  .action-btn:hover, .action-btn.active{
    background-color: rgba(236, 146, 60, 0.1);
    fill: #EC923C;
  }
  .action-btn:hover .icon, .action-btn.active .icon{
    background-color: #EC923C;
  }
  .read-more{
    background: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 2px;
    color: ${colors.dark};
    border-bottom: 2px solid ${colors.dark};
    margin-top: 8px;
  }
  a{
    color: ${colors.dark};
  }
  .download-resume{
    font-weight: 600;
    svg{
      margin-right: 5px;
    }
  }
  .candidate-list-item{
    margin-top: 8px;
  }
`