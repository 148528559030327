import { ActionMenuWrap, BlurBackground } from './ActionsMenu.presents';
import { ActionsMenuType } from './JobsList.props';
import { ActionItem } from './ActionItem';

export const ActionsMenu = (props: ActionsMenuType) => {
  const { index, activeActionBtn, actionList, isDesktop, handleOpenMenu, isPositionUnder } = props;

  return <>
    <BlurBackground isOpen={activeActionBtn === index} isDesktop={isDesktop} onClick={() => handleOpenMenu(index)}>
    </BlurBackground>
    <ActionMenuWrap isPositionUnder={isPositionUnder ? isPositionUnder : false} isDesktop={isDesktop} >
      {actionList.map((item, index) => <ActionItem {...item} isDesktop={isDesktop} key={index} />)}
    </ActionMenuWrap>
  </>

}