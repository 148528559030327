import { DescriptionSubtitle, DescriptionTextWrap, ReadMoreButton, DownloadBtn, DownloadIcon, Content } from './JobDescription.presents';
import { PropsType } from './JobDescription.props';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from "react";
import { JobStoryCard } from '../../../common/Cards/JobStoryCard';
import downloadIcon from '../../../../assets/img/jobStory/downloadIcon.svg'

export const DescriptionText = (props: { text: string }) => {
  const [openMessage, setOpenMessage] = useState(props.text.length < 300 ? true : false)
  const sliceMessage = (lengt: number, message: string) => {
    return message.substr(0, lengt) + '...'
  }
  useEffect(() => {
    setOpenMessage(props.text.length < 300 ? true : false)
  }, [props.text])
  return !openMessage ?
    <div>
      {sliceMessage(300, props.text)}
      <div>
        <ReadMoreButton onClick={() => setOpenMessage(true)}>Read More</ReadMoreButton>
      </div>
    </div> :
    <p> {props.text} </p>
}

export const JobDescription = observer(({ jobData, componentRef, bannersUrl }: PropsType) => {
  const isDescriptionFileExist = jobData.descriptionStep.descriptionFile && jobData.descriptionStep.descriptionFile.url;
  const descriptionFileLink = isDescriptionFileExist ? jobData.descriptionStep.descriptionFile.url : '';

  return <JobStoryCard title='What is this job about?' imageUrl={bannersUrl.descriptionBanner} componentRef={componentRef}>
    <Content>
      {descriptionFileLink && <DownloadBtn href={descriptionFileLink}>
        <DownloadIcon src={downloadIcon} alt='' />
        <span>Download Full Description</span>
      </DownloadBtn>}
      <div className='description-content'>
        {jobData.descriptionStep.jobSummary ? <div>
          <DescriptionSubtitle>Job Description</DescriptionSubtitle>
          <DescriptionTextWrap><DescriptionText text={jobData.descriptionStep.jobSummary} /></DescriptionTextWrap>
        </div> : ''}
        {jobData.descriptionStep.aboutJob ? <div>
          <hr />
          <DescriptionSubtitle>What is special about this job?</DescriptionSubtitle>
          <DescriptionTextWrap><DescriptionText text={jobData.descriptionStep.aboutJob} /></DescriptionTextWrap>
        </div> : ''}
      </div>
    </Content>
  </JobStoryCard>;
})