import 'react-app-polyfill/ie11';
import { Formik } from 'formik';
import { Title } from "../../../../../common/Title";
import { Input } from "../../../../../common/Input";
import { useState } from "react";
import { Text } from "../../../../../common/Text";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { restorePassword } from '../../../../../../services/api/auth/auth.domains';
import { PropsType } from './RestorePass.props';
import { StyleForWrap, ButtonWrap } from './RestorePass.presents';
import { useTranslation } from "react-i18next";
import { HelperTextStatus } from '../../../SignIn/SignIn.props';
import { passwordValidation } from '../../../../../../services/validationSchemas';
import { SPPrimaryButton } from '../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton';

export const RestorePass = (props: PropsType) => {
  const [serverError, setServerError] = useState('')
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = { password: '', repeatedPassword: '' }
  const { userStore } = useRootStore()
  const history = useHistory();
  const { t } = useTranslation();
  return <StyleForWrap>
    <Title text={t('forgotPassword.restoreStepTitle')} />
    <Formik
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        password: passwordValidation(t),
        repeatedPassword: Yup.string().required()
          .oneOf([Yup.ref("password"), null], t('validation.mustMatch'))
      })}
      onSubmit={async (
        values: typeof initialValues
      ) => {
        setIsLoading(true);
        const dataFromServer = await restorePassword({ password: values.repeatedPassword, token: props.token })
        if (!dataFromServer.data.err) {
          setServerError('');
          userStore.setHelperTextStatus(HelperTextStatus.PasswordUpdated);
          history.push('/sign-in');
        } else {
          setServerError(dataFromServer.data.err)
        }
        setIsLoading(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Input
            labelText={<div className='d-flex justify-content-between'><span>{t('label.password')}</span><span className='additional'>{t('label.leastCharacters')}</span></div>}
            type={'password'}
            name={'password'}
            value={values.password}
            onChange={handleChange}
            error={errors.password ? true : false}
            m={'48px 0 8px'}
          />
          <Text mb={6} text={errors.password ? errors.password : ''} error />
          <Input
            labelText={t('label.repeatPassword')}
            type={'password'}
            name={'repeatedPassword'}
            value={values.repeatedPassword}
            onChange={handleChange}
            error={errors.repeatedPassword ? true : false}
            m={'12px 0 8px'}
          />
          <Text text={errors.repeatedPassword ? errors.repeatedPassword : ''} error />
          <ButtonWrap>
            <SPPrimaryButton 
              width='100%'
              type='submit'
              disabled={isLoading}
              onClick={()=>setFirstSubmitComplete(true)}>
                {t('forgotPassword.createPassword')}
            </SPPrimaryButton>
          </ButtonWrap>
        </form>
      )}
    </Formik>
    <Text text={serverError} error />
  </StyleForWrap>
}