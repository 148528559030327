import styled from "styled-components";
import { theme } from "../../../../../../../theme";
import blueCheckedIcon from "../../../../../../../assets/img/blueCheckedIcon.svg";
import orangeCheckedIcon from "../../../../../../../assets/img/checkedOrange.svg";




export const FieldWrap = styled('div') <{ isPanel: boolean }>`
  position: relative;
  width: fit-content;
  label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    width: fit-content;
    background: ${(props) => props.isPanel ? "#EEF1F6": "#EEF6FE"};
    color: ${theme.colors.black};
    border-radius: ${theme.variables.borderRadiusSmall};
    padding: 13px 10px;
    padding-left: 38px;
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.md} 6px;
      padding-left: 36px;
    }
    @media(max-width: ${theme.screens.tablet}) {
      font-size: ${theme.fontSizes.md};
    }
  }
:hover label{
  background: ${(props) => props.isPanel ? theme.colors.lightOrange : theme.colors.blue}; 
  color: ${theme.colors.white};
}

& input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2;
}

span {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background: ${theme.colors.white};
  border:none;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  left: 10px;
  @media(max-width: ${theme.screens.tablet}) {
    left: 6px;
  }
}

& input:checked ~ span {
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};
}

& input:checked ~ label  {
  background: ${(props) => props.isPanel ? theme.colors.lightOrange : theme.colors.blue}; 
  
  color: ${theme.colors.white};
}

& label span::after {
  content: "";
  position: absolute;
  display: none;
}

& input:checked ~ label span::after {
  display: block;
  width: 100%;
  height: 100%;
}

& label span::after {
  background-image: url(${(props) => props.isPanel? orangeCheckedIcon : blueCheckedIcon});
  background-repeat: no-repeat;
  background-position: center;
}
`