import styled from "styled-components"
import { theme } from "../../../../../theme"
import mapPointIcon from '../../../../../assets/img/jobStory/mapPoint.svg'

export const mapStyle = [
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e9e9e9"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dedede"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  }
]

export const MainInfoWrap = styled('div') <{ isDesktop: boolean }>`
  max-width: 686px;
  padding: ${theme.offsets.xxl};
  overflow-y: scroll;
  max-height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.lg};
  }
  ${(props) => props.isDesktop && `
  margin-right: 166px;
  margin-left: auto;
  `}
`

export const ContentWrap = styled('div') <{ isDesktop: boolean }>`
  width: ${(props) => props.isDesktop ? '50%' : '100%'};
  height: 100%;
  position: relative;
`

export const ContainerMap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: ${(props) => props.isDesktop ? 'row' : 'column-reverse'};
  align-items: flex-start;
  width: 100%;
  @media(max-width: ${theme.screens.tablet}) {
    height: auto;
}
`

export const CloseButton = styled('div')`
  padding: ${theme.offsets.xl};
  border: 1px solid ${theme.colors.darkGray};
  box-sizing: border-box;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  font-weight: 600;
  font-size: 18px;
  color: ${theme.colors.black};
  width: 190px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img{
    margin-right: ${theme.offsets.md};
  }
`

export const MobileCloseButton = styled('div')`
  padding: ${theme.offsets.xl};
  border: 1px solid ${theme.colors.darkGray};
  box-sizing: border-box;
  border-radius: 100%;
  font-weight: 600;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 9;
  background: ${theme.colors.white};
`

export const OrganizationName = styled('div')`
  font-size: 22px;
  font-weight: 500;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 18px;
}
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
`

export const LogoWrap = styled('div')`
  
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  margin-top: ${theme.offsets.extraMajor};
  overflow: hidden;
  img{
    object-fit: cover;
    width: 80px;
    height: 80px;
    margin-right: ${theme.offsets.sm};
    border-radius: 12px;
    @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.major};
    width: 50px;
    height: 50px; 
}
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.lg};
  }
`

export const LocationTitle = styled('p') <{ isActive: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgba(63,71,79,0.8);
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
  }
  ${
    (props)=> props.isActive && `
    &::before{
      content: '';
      width: 21px;
      height: 25px;
      display: block;
      background-image: url(${mapPointIcon});
      background-size: cover;
    }
    `
  }
  
`

export const LocationValue = styled('p')`
  margin-top: ${theme.offsets.sm};
  font-size: ${theme.fontSizes.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
  }
`

export const LocationWrap = styled('div')`
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.xxl};
  }
`

export const LocationItem = styled('div')`
  cursor: pointer;
  padding: ${theme.offsets.xxl} 0;
  border-top: 1px solid ${theme.colors.lightGray};
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.lg} 0;
  }
`