import styled from "styled-components";
export const Container = styled('div')`
  display: flex;
  flex: 1;
`

export const Content = styled('div')`
  flex: 1;
  background: #F8F8F8;
  
`