import styled from 'styled-components'
import { theme } from '../../../theme'
import { mainStyleType } from '../../../themes/mainStylesType'
import { colors } from './../../../constants/colors';

export const StyleForTitle = styled.h1`
  font-family: Recoleta;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};

  @media(max-width: ${theme.screens.desktop}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media(max-width: ${theme.screens.mobile}) {
    font-size: 28px;
    line-height: 32px;
  }
`
export const StyleForSubheader = styled.h1`
font-family: 'Apercu Pro';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: -0.01em;
  margin: ${theme.offsets.xl} 0 ${theme.offsets.xl};
  color: ${colors.dark};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.lg};
    line-height: 18px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px; 
    line-height: ${theme.offsets.lg};
  }
`