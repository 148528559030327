import { InputPropsType } from './Input.props'
import { InputWrap, StyleForLabel, StyleForInput, PasswordBtn, StyleForTextArea } from './Input.presents'
import { useState } from 'react';
import eyeOpen from '../../../assets/img/eyeOpen.svg'
import eyeClose from '../../../assets/img/eyeClose.svg'

export const Input = (props: InputPropsType) => {
  const [selectedType, setSelectedType] = useState(props.type)
  const [btnImg, setbtnImg] = useState(eyeOpen)
  const { type, ...otherProps } = props;

  const changePasswordVisibility = () => {
    if (selectedType === 'password') {
      setSelectedType('text')
      setbtnImg(eyeClose)
    } else {
      setSelectedType('password')
      setbtnImg(eyeOpen)
    }
  }

  const setPressOnlyNumbers = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.type === 'number' && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }
  }

  return <InputWrap {...props} >
    <div className='position-relative d-flex flex-column'>
      <StyleForLabel htmlFor={props.name} className={!props.labelText ? 'd-none' : ''}>{props.labelText}</StyleForLabel>
      {props.type === 'textarea' ? <StyleForTextArea {...otherProps} /> :
        <StyleForInput
          min={0}
          max={props.maxValue}
          onKeyPress={setPressOnlyNumbers}
          autoComplete={props.autoCompleteOff ? "chrome-off" : "on"}
          type={selectedType}
          {...otherProps}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />}
      {props.type === 'password' ?
        <PasswordBtn onClick={() => changePasswordVisibility()}>
          <img src={btnImg} alt="btn" />
        </PasswordBtn>
        : ''}
    </div>
  </InputWrap>
}