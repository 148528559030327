import { RadioBtn } from '../../BasicComponents/RadioBtn';
import { ActiveIcon } from './StartStep.icons';
import { SchemePropsType } from './StartStep.props';
import { Locations } from '../../../common/FieldArrays/Locations/Locations';
import { LocationWrapper } from '../Steps.presents';

export const SelectComponent = (data: SchemePropsType) => {
  switch (data.type) {
    case 'radio':
      return <div className="d-flex flex-wrap justify-content-center answer-wrap">
        {data.item.answers.map((answer: any, index: number) => <RadioBtn
          key={index + 'answer' + answer.value}
          text={answer.title}
          activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[data.item.name] ? true : false} />}
          name={data.item.name}
          value={answer.value}
          checked={answer.value === data.values[data.item.name] ? true : false}
          haveDependent={data.item.haveDependent}
          handleChange={data.handleChange}
          setFieldTouched={data.setFieldTouched}
        />)}
      </div>
    case 'location':
      return <LocationWrapper>
        <Locations
          values={data.values}
          handleChange={data.handleChange}
          errors={data.errors}
          setFieldValue={data.setFieldValue}
        />
      </LocationWrapper>
    default:
  }
}