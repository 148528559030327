import { ReactElement, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useOnScreen from "../../../../../hooks/useOnScreen";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { Wrapper, LogoWrap, UserName, UserMenuWrap, AvatarWrap, Avatar, ArrowIcon, NavigationBlock, Container } from "./Header.presents";
import { ReactComponent as LogoIcon } from '../../../../../assets/img/logo.svg';
import arrowIconSrc from '../../../../../assets/img/arrow.svg';
import avatarIcon from '../../../../../assets/img/avatar.svg';
import { UserMenu } from "./UserMenu/UserMenu";
import { observer } from "mobx-react-lite";

export const CandidateHeader = observer(({ isLogoHide, additionalButtons }: { isLogoHide: boolean, additionalButtons?: ReactElement | null }) => {
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const history = useHistory()

  const [isUserMenuShown, setIsUserMenuShown] = useState(false);

  const avatar = candidateStore.logo ? candidateStore.logo.url : avatarIcon;
  const userName = candidateStore.firstName && candidateStore.lastName ? `${candidateStore.firstName} ${candidateStore.lastName}` : 'Profile';
  const avatarRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(avatarRef);

  const isMenuShownOnScreen = () => {
    return isVisible ? isUserMenuShown : false
  }

  const handleClickLogo = () => {
    resumeBuilderStore.showResumePreview(false)
    history.push('/candidate/resume-builder')
  }

  return <Container>
    <Wrapper fullscreen={!isLogoHide}>

    {!isLogoHide && <LogoWrap onClick={handleClickLogo}>
      <LogoIcon />
    </LogoWrap>}

    <NavigationBlock>
      {!!additionalButtons && additionalButtons}
      <UserMenuWrap onClick={() => setIsUserMenuShown(!isUserMenuShown)} onBlur={() => setIsUserMenuShown(false)}>
        <AvatarWrap ref={avatarRef}>
          <Avatar
            src={avatar}
            alt="Avatar" />
          <UserName>{userName}</UserName>
          <ArrowIcon src={arrowIconSrc} alt="V" isOpen={isUserMenuShown} />
        </AvatarWrap>
        <UserMenu isOpen={isMenuShownOnScreen()} />
      </UserMenuWrap>
    </NavigationBlock>
  </Wrapper>
  </Container>
})
