import { Modal } from 'react-bootstrap';
import { colors } from '../../../../constants/colors';
import styled from 'styled-components'
import { PropsType } from './NavBtn.props'

export const StyleForBtn = styled('button') <PropsType>`
{
  width: 186px;
  height: 52px;
  background: ${(props) => props.next ? colors.orange : '#F8DDC3'};
  box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: ${(props) => props.next ? 'row' : 'row-reverse'};
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-right: ${(props) => !props.next ? 'auto' : '0'};
  margin-top: 40px;
  margin-left: ${(props) => props.next ? 'auto' : '0'};
  ${(props) => props.exit && `
    flex-direction:row;
    width: auto;
    padding: 16px;
    margin-right: 16px;
    img{
      margin-left: 16px;
    }
  `}
};
&:hover{
  background: #E18935;
  box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
  ${(props) => !props.next && `
  background: #EFC093;
  `}
}

span{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.next ? '#FFFFFF' : colors.dark};
}
`

export const StyleForModal = styled(Modal)`
padding-right: 15px;
  .modal-dialog{
    max-width: 848px;  
    h1{
      text-align: left;
    }
  }
  .modal-content{
    padding:40px;
    min-height: 456px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 20px;
  };
  img.close-btn{
    position: absolute;
    right: 40px;
    top: 45px;
    cursor: pointer;
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    font-size: 24px;
    width: 60%;
    text-align: center;
    img{
      width:52px;
      margin-bottom: 24px;
    }
  }
  .content h1{
    text-align: left;
  }
  .media-uploader-wrap{
    margin: 0 0 40px;
  }
  .media-uploader-wrap .second-field{
    border-bottom: 1px solid #D1D7E3;
    padding-bottom: 25px;
    margin-top: 16px;
  }
  .title{
    font-family: Publico;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    color: ${colors.dark}
  }
`

export const StyleForBtsWrap = styled('div')`
display: flex;
align-items: center;
align-self: flex-end;
.add-btn, .close-btn{
  height: 52px;
  border-radius: 8px;
  border: none;
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}
.add-btn{
  color: #fff;
  justify-content: center;
  background: #EC923C;
  box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
  width: 191px;
  font-weight: 600;
}
.add-btn:hover{
  background: #E18935;
  box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
}
.add-btn img{
  width: 18px;
  height: 12px;
}
.close-btn{
  background: #fff;
  color: ${colors.dark}
  font-weight: 500;
  border: 1px solid #D1D7E3;
  box-sizing: border-box;
  filter: drop-shadow(8px 8px 16px rgba(95, 120, 172, 0.05));
  border-radius: 8px;
  width: 191px;
  justify-content: center;
  margin-right: 24px;
}
.close-btn:hover{
  border: 1px solid #B1BACC;
}
`