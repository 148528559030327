import { ChangeJobThemeDto, CreateJobrDto } from './jobStoreBuilder.type';
import axios from 'axios';
import { baseURl } from "../../../constants/config";


export const createJob = (jobData: CreateJobrDto) => {
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/job_story_builder`, jobData)
}

export const uploadFile = (file: any, fileFolder: string) => {
  const formData = new FormData();
  formData.append("file", file);
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/job_story_builder/upload_file/${fileFolder}`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const uploadPhoto = (file: any, jobId: string) => {
  const formData = new FormData();
  formData.append("file", file);
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/job_story_builder/upload_photo/${jobId}`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const deletePhoto = (fileId: string, jobId: string) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/job_story_builder/delete_photo`, { fileId, jobId })
}

export const deleteFile = (fileId: string, fileFolder: string) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/job_story_builder/delete_file/${fileFolder}`, { fileId })
}

export const getJob = (jobId: string) => {
  axios.defaults.withCredentials = true
  return axios.get(`${baseURl}/job_story_builder/${jobId}`)
}

export const changeJobTheme = (dto: ChangeJobThemeDto) => {
  axios.defaults.withCredentials = true;
  return axios.put(`${baseURl}/job_story_builder/theme`, dto);
}

export const generateSignedUrl = (dto: any) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story_builder/generate_signed_url`, dto);
}

export const addGalleryPhoto = (dto: any) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story_builder/add_gallery_photo`, dto);
}

export const removeGalleryPhoto = (key: string, jobId: string) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/job_story_builder/remove_gallery_photo`, { key, jobId })
}

export const addVideo = (dto: any) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story_builder/add_video`, dto);
}

export const addFile = (dto: any) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story_builder/add_file`, dto);
}

export const removeVideo = (key: string, jobId: string) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/job_story_builder/remove_video`, { key, jobId })
}

export const removeFile = (key: string, jobId: string) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/job_story_builder/remove_file`, { key, jobId })
}

export const s3upload = async (url: string, file: File) => {
  return new Promise<any>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open("PUT", url, true)
    xhr.setRequestHeader("Content-Type", file.type)
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject(xhr.statusText)
      }
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.send(file)
  })
}
