import { Text } from "../../../../../common/Text"
import { Title } from "../../../../../common/Title"
import { Container, Row } from "react-bootstrap"
import 'react-app-polyfill/ie11';
import { Formik } from 'formik';
import { useRootStore } from '../../../../../../state/stores/storeContext'
import { useState } from 'react'
import { PropsType } from "./SecondStep.props"
import { StyleForCodeInput, ResetBtn, WindowText, WindowContent, FormWrap, DescrtiptionText, BackButtonWrap } from './SecondStep.presents'
import { useTranslation } from "react-i18next";
import { ModalWindow } from '../../../../../common/Modal';
import successfullyIcon from '../../../../../../assets/img/successfully.svg';
import { SPPrimaryButton } from '../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { ButtonWrap } from '../FirstStep/FirstStep.presents';
import { changeFocusWhenMaxLenghtReached } from "../../../../../../services/helper";
import { SPLightButton } from "../../../../../common/buttons/SPLightButton/SPLightButton";
import { resendPhoneCode } from "../../../../../../services/api/employerSignUp/employerSignUp.domains";

export const SecondStep = (props: PropsType) => {
  const { userStore } = useRootStore()

  const CODE_LENGTH = 6;

  const [serverError, setServerError] = useState('')
  const [indexWithErr, setIndexWithErr] = useState<number[]>([])
  const phoneCodeCount = new Array(CODE_LENGTH).fill('');
  const initialValues: any = { phoneCode1: '', phoneCode2: '', phoneCode3: '', phoneCode4: '', phoneCode5: '', phoneCode6: '' };
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const resetCode = async () => {
    const serverResponse = await resendPhoneCode({ phone: userStore.phone, verificationId: userStore.verificationId });
    if (!serverResponse.data.err) {
      setModalOpen(true);
    }
  }



  return <FormWrap>
    <Title text={t('signUp.secondStepTitle')} />
    <DescrtiptionText>
      {t('signUp.secondStepText', { phoneNumber: props.phoneNumber })}
    </DescrtiptionText>
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validate={(values: typeof initialValues) => {
        const errors: any = {}
        const emptyInputs: Array<number> = []
        Object.values(values).forEach((element: any, index: number) => {
          if (!element) {
            emptyInputs.push(index)
            errors.verificationCode = t('validation.required')
          }
          setIndexWithErr(emptyInputs)
        });
        return errors;
      }}
      onSubmit={async (
        values: typeof initialValues, { resetForm }
      ) => {
        const dataFromServer = await userStore.sendPhoneVerification({ phoneCode: Object.values(values).join(''), verificationId: userStore.verificationId })
        if (!dataFromServer.err) {
          props.nextStep()
        } else {
          setServerError(dataFromServer.err);
          resetForm();
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid className="px-0">
            <Row className="justify-content-between">
              {phoneCodeCount.map((item, index) => <div key={index++}>
                <StyleForCodeInput
                  className='text-center'
                  name={`phoneCode${index+1}`}
                  type={'text'}
                  maxLength={1}
                  value={values[`phoneCode${index+1}`]}
                  onChange={handleChange}
                  onInput={(e: any) => changeFocusWhenMaxLenghtReached(e, CODE_LENGTH)}
                  error={indexWithErr.indexOf(index) < 0 ? false : true}
                />
              </div>)}
            </Row>
          </Container>
          <Text m={'8px 0 0'} text={errors.verificationCode ? `${errors.verificationCode}` : ''} error />
          {serverError ? <Text text={serverError} error /> : ''}
          <ResetBtn onClick={() => resetCode()} type='button'>{t('signUp.resentCode')}</ResetBtn>
          <ButtonWrap>
            <SPPrimaryButton width={'100%'} type="submit">{t('signUp.continueBtn')}</SPPrimaryButton>
            <BackButtonWrap>
              <SPLightButton width={'100%'} type="button" onClick={()=>props.previousStep()}>Back</SPLightButton>
            </BackButtonWrap>
          </ButtonWrap>
        </form>
      )}
    </Formik>
    <ModalWindow title='' openWindow={isModalOpen} closeWindow={() => setModalOpen(false)} >
      <WindowContent>
        <img src={successfullyIcon} alt="img" />
        <WindowText>The code has been successfully sent!</WindowText>
      </WindowContent>
    </ModalWindow>
  </FormWrap>
}

