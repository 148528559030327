import { LikeIcon } from '../../../../assets/img/jobstoryIcons/active/Like'
import { DislikeIcon } from '../../../../assets/img/jobstoryIcons/active/Dislike'
import { DayIcon } from '../../../../assets/img/jobstoryIcons/active/Day'
import { MorningIcon } from '../../../../assets/img/jobstoryIcons/active/Morning'
import { AfternoonIcon } from '../../../../assets/img/jobstoryIcons/active/Afternoon'
import { NightIcon } from '../../../../assets/img/jobstoryIcons/active/Night'
import { MixedIcon } from '../../../../assets/img/jobstoryIcons/active/Mixed'
import { AnyDayIcon } from '../../../../assets/img/jobstoryIcons/active/AnyDay'
import { RingIcon } from '../../../../assets/img/jobstoryIcons/active/Ring'
import { Calendar2Icon } from '../../../../assets/img/jobstoryIcons/active/Calendar2'
import { CalendarWithDateIcon } from '../../../../assets/img/jobstoryIcons/active/CalendarWithDate'
import { AlarmIcon } from '../../../../assets/img/jobstoryIcons/active/Alarm'
import { NextQuaterIcon } from '../../../../assets/img/jobstoryIcons/active/NextQuater'
import { NormalIcon } from '../../../../assets/img/jobstoryIcons/active/Normal'
import { TruckIcon } from '../../../../assets/img/jobstoryIcons/active/Truck'
import { BriefcaseIcon } from '../../../../assets/img/jobstoryIcons/active/Briefcase'
import { FlyIcon } from '../../../../assets/img/jobstoryIcons/active/Fly'
import { AerostatIcon } from '../../../../assets/img/jobstoryIcons/active/Aerostat'
import { CarIcon } from '../../../../assets/img/jobstoryIcons/active/Car'

import { useEffect, useState } from 'react'


export const ActiveIcon = ({ name, checked }: { name: any, checked: boolean }) => {
  const [icon, setIcon] = useState<any>(<LikeIcon active={checked} />);

  useEffect(() => {
    const iconsList: any = {
      yes: <LikeIcon active={checked} />,
      no: <DislikeIcon active={checked} />,
      regular: <DayIcon active={checked} />,
      morning: <MorningIcon active={checked} />,
      afternoon: <AfternoonIcon active={checked} />,
      night: <NightIcon active={checked} />,
      mixed: <MixedIcon active={checked} />,
      any: <AnyDayIcon active={checked} />,
      now: <RingIcon active={checked} />,
      hiring: <AlarmIcon active={checked} />,
      '3weeks': <CalendarWithDateIcon active={checked} />,
      '8weeks': <Calendar2Icon active={checked} />,
      nextQuarter: <NextQuaterIcon active={checked} />,
      noRush: <NormalIcon active={checked} />,
      full: <TruckIcon active={checked} />,
      particial: <BriefcaseIcon active={checked} />,
      international: <FlyIcon active={checked} />,
      domestic: <AerostatIcon active={checked} />,
      short: <CarIcon active={checked} />
    }
    
    setIcon(iconsList[name])

  }, [name, checked])

  return icon ? icon : <div></div>

}