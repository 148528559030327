import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { Title, Wrap, ButtonWrap } from "./Instruction.presents";

export const Instruction = () => {
  const BLOG_LINK = "https://sidepost.com/blog/how-to-create-a-great-resume/";
  const openBlogLink = () => {
    window.open(BLOG_LINK, "_blank")?.focus();
  };
  return <CandidateFormCard
    isDesktop={false}>
    <Wrap>
      <Title>How to create a great resume?</Title>
      <ButtonWrap>
        <SPSecondaryButton width="100%" type={'button'} onClick={openBlogLink}>
          Read Guide
        </SPSecondaryButton>
      </ButtonWrap>
    </Wrap>
  </CandidateFormCard>
}
