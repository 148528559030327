import { useRootStore } from "../../../../../state/stores/storeContext";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { MainSectionProps } from "../../Organization.props";
import { Wrapper } from "./MediaGallery.presents";
import { observer } from "mobx-react-lite";
import { VideoUploadGallery } from "../../../../common/PhotoUploadGallery/VideoUploadGallery";
import { HelperText } from "../../../../common/HelperText";
import { Description, HelperTextTitle, LabelContent } from "../../Organization.presents";

export const MediaGallery = (props: MainSectionProps) => {
  const { componentRef, isDesktop, values, handleChange, setFieldValue } = props;

  const { organizationStore } = useRootStore();

  const UploadGalleryDto = {
    videoFiles: organizationStore.videoGallery,
    saveVideo: organizationStore.saveGalleryFile,
    setVideo: organizationStore.setGalleryVideo,
    deleteVideo: organizationStore.deleteGalleryFile,
    unsetVideo: organizationStore.unsetGalleryVideo,
    values,
    handleChange,
    setFieldValue
  }

  const isGalleryEmpty = !organizationStore.videoGallery || organizationStore.videoGallery.length === 0;

  const helperText = 'Add videos about your company. If you upload more than one video, choose which one is going to be on the cover of your profile. Maximum size for a video file cannot exceed 200MB. Up to 10 videos can be uploaded.'
  const TitleRender = () =>
    <LabelContent>
      <HelperTextTitle>
        <span>{'Videos'}</span>
        <HelperText text={helperText} />
      </HelperTextTitle>
      <Description>
        Select a main video to be displayed on top of the your public profile
      </Description>
    </LabelContent>

  return <OrganizationCard title={<TitleRender />} componentRef={componentRef} isDesktop={isDesktop} width='100%'>
    <Wrapper isGalleryEmpty={isGalleryEmpty}>
      <VideoUploadGallery {...UploadGalleryDto} />
    </Wrapper>
  </OrganizationCard>
}