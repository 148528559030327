import { theme } from './../../../../theme/theme';
import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
  text-align: center;
  margin: auto;
  padding: ${theme.offsets.extraMajor} 0;
`

export const Banner = styled('img')`
  margin-bottom: ${theme.offsets.extraMajor};
`

export const Text = styled('p')`
  margin-bottom: ${theme.offsets.major};
  margin-top: ${theme.offsets.xxl};
  font-size: ${theme.fontSizes.lg};
`

export const ButtonsWrap = styled('div')`
  max-width: 255px;
  width: 100%;
  button{
    margin-bottom: ${theme.offsets.lg};
  }
`

export const ShareBlock = styled('div')`
  width: 100%;
  padding: ${theme.offsets.major};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: ${theme.variables.borderRadiusLarge};
  margin: ${theme.offsets.xxl} auto ${theme.offsets.extraBig};
`

export const Description = styled('p')`
  font-size: ${theme.fontSizes.xl};
  text-align: center;
`

export const ShareBtnsWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.offsets.xxl};
`

export const ShareBtn = styled('div') <{ color: string }>`
  background-color: ${(props) => props.color ? props.color : '#FFF'};
  width: 48px;
  height: 48px;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    opacity: 0.8;
  }
`

export const Separator = styled('div')`
display: flex;
color: ${theme.colors.lightGray};
align-items: center;
margin: ${theme.offsets.xxl} 0;
::before,::after{
  content: '';
  background-color: ${theme.colors.lightGray};
  flex: 1;
  height: 1px;
  display: block;
}
::before{
  margin-right: ${theme.offsets.sm};
}
::after{
  margin-left: ${theme.offsets.sm};
}
`

export const CopylinkText = styled('p')`
  margin: 0 auto;
`

export const CopylinkContainer = styled('div')`
  padding-top: ${theme.offsets.xxl};
  display: flex;
  align-items: center;

  button {
    margin-left: 20px;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
  }
`

export const CopylinkInput = styled('input')`
  padding: 14px;
  height: 44px;
  border-radius: ${theme.variables.borderRadiusSmall};
  font-size: 14px;
  line-height: 16px;
  color: #2D353D;
  border: 1px solid #D1D7E3;
  width: 100%;
  
  &:disabled {
    background: #fff;
  }
`