import styled from 'styled-components'
import { theme } from '../../theme'
import { PageTypes } from './Candidates.props'

export const StyleForPage = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const StyleForWrap = styled('div') <{ activeTab: PageTypes, isDesktop: boolean }>`
  background:${props => props.activeTab === PageTypes.candidates ? '#F0F3F8' : theme.colors.white};
  padding: ${props => props.isDesktop ? `${theme.offsets.xxl} 0` : '0'};
  display: flex;
  flex: 1;
`