import { theme } from '../../../../theme';
import styled from 'styled-components';

export const OrganizationCardContainer = styled('div')<{isDesktop:boolean}>`
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  padding: ${theme.offsets.extraBig};
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.lg};
    padding: ${theme.offsets.lg};
  }
`

export const OrganizationCardContent = styled('div')<{isDesktop:boolean, hasTitle: boolean}>`
  margin: ${(props)=>props.hasTitle ? `${theme.offsets.extraBig} auto 0` : '0 auto'};
  hr{
    border-top: 1px solid ${theme.colors.lightGray};
  }
  ${(props)=>!props.isDesktop &&`
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${props.hasTitle ? `${theme.offsets.xxl} auto 0` : '0 auto'};
    hr{
      margin-top: ${theme.offsets.lg};
      margin-bottom: ${theme.offsets.lg};
    }
  }
  `}
`

export const Title = styled('p')`
  font-size: ${theme.fontSizes.xl};
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
`