import { colors } from './../../../constants/colors';
import styled from 'styled-components'
import { mainStyleType } from '../../../themes/mainStylesType'
import {PropsType} from './Text.props'
export const StyleForText = styled('p')`
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: ${(props: mainStyleType) => props.fw ? props.fw : 'normal'};
  font-size: ${(props: mainStyleType) => props.fs ? props.fs+'px' :'12px'};
  line-height: ${(props: mainStyleType) => props.lh ? props.lh :'24px'};
  color: ${(props: mainStyleType) => props.color ? props.color :'#3F474F'}; 
  text-align: ${(props: mainStyleType) => props.center ? 'center' :'left'}; 
  ${(props: mainStyleType) => props.error && `
    font-size: 12px;
    color: ${colors.red};
    text-align: left;
    line-height: 12px;
    height: 12px;
  `}
`