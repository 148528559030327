import { CardinalOrientation } from "walktour";

export const steps = (isDesktop: boolean) => [
  {
    selector: isDesktop ? "#preview_step" : "#preview_step_mob",
    title: "Preview Your Resume",
    description: "You can preview your online resume before publishing and sharing it. This feature shows you how your public resume looks like and seen by visitors once you share it.",
    nextLabel: "Got It",
    closeLabel: "Skip Tutorial",
    maskRadius: 8,
    movingTarget: true,
  },
  {
    selector: isDesktop ? "#share_step" : "#share_step_mob",
    title: "Share Your Resume",
    description: "By clicking here, you can share your online resume on social media or via email. This feature also allows you to generate an public Internet link for your resume and share it with anyone.",
    maskRadius: 8,
    maskPadding: 0,
    nextLabel: "Got It",
    closeLabel: "Skip Tutorial",
  },
  {
    selector: isDesktop ? "#visibility_step" : "#visibility_step_mob" ,
    title: "Show or Hide Your Resume",
    description: "In this section, you can make your public resume visible or invisible to non-registered visitors who are not registered, or not singed into their accounts.",
    maskRadius: 8,
    maskPadding: 0,
    nextLabel: "Got It",
    closeLabel: "Skip Tutorial",
    orientationPreferences: isDesktop ? [CardinalOrientation.SOUTHWEST] : [CardinalOrientation.SOUTH]
  },
  // {
  //   selector: isDesktop ? "#applications_step" : "#applications_step" ,
  //   title: "Track your applications",
  //   description: "You can track your applications in this area. This section shows you a list of jobs you have previously applied for.",
  //   maskRadius: 8,
  //   maskPadding: 0,
  //   nextLabel: "Got It",
  //   closeLabel: "Skip Tutorial",
  //   orientationPreferences: isDesktop ? [CardinalOrientation.EAST] : [CardinalOrientation.SOUTH]
  // },
]
