import { Field, FormikErrors } from "formik";
import {ChangeEvent, useEffect, useMemo} from "react";

import { SelectCom } from "../../../../../common/Select";
import { FieldBox } from "../../BaseComponents/PreferencesForm/PreferencesForm.presents";
import { Divider, FieldsWrap, SubtitleAdvice, RemoveIcon, ExpertiseFieldWrap, SelectFieldWrap } from "./ExpertiseStep.presents";
import { ExpertiseSubField } from "./ExpertiseSubField/ExpertiseSubField";
import digitalMarketing from "../../../../../../assets/staticData/expertises/digitalMarketing.json";
import graphicsAndDesign from "../../../../../../assets/staticData/expertises/graphicsAndDesign.json";
import writingAndTranslation from "../../../../../../assets/staticData/expertises/writingAndTranslation.json";
import businessAndSales from "../../../../../../assets/staticData/expertises/businessAndSales.json";
import programmingAndTech from "../../../../../../assets/staticData/expertises/programmingAndTech.json";
import musicAndAudio from "../../../../../../assets/staticData/expertises/musicAndAudio.json";
import videoAndAnimation from "../../../../../../assets/staticData/expertises/videoAndAnimation.json";
import dataAnalytics from "../../../../../../assets/staticData/expertises/dataAnalytics.json";
import photography from "../../../../../../assets/staticData/expertises/photography.json";
import lifeStyle from "../../../../../../assets/staticData/expertises/lifeStyle.json";
import closeIcon from "../../../../../../assets/img/close.svg";
import { ErrorMsg } from "../../../../../../theme/typography";

type OptionListType = { value: string, label: string, disable: boolean }[];

export const ExpertiseField = ({
  fieldIndex,
  values,
  handleChange,
  setFieldValue,
  options,
  setOption,
  remove,
  isPanel,
  errors
}: {
  fieldIndex: number;
  values: { area: string; skills: string[]; }[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (key: string, value: any) => void;
  options: OptionListType;
  setOption: (value: OptionListType) => void;
  remove: (index: number) => void;
  isPanel?: boolean;
  errors: FormikErrors<{
    expertiseAreas: {
      area: string;
      skills: never[];
    }[];
  }>
}) => {


  const optionsMap = {
    "Digital Marketing": digitalMarketing,
    "Graphics and Design": graphicsAndDesign,
    "Writing and Translation": writingAndTranslation,
    "Business and Sales": businessAndSales,
    "Programming and Tech": programmingAndTech,
    "Music and Audio": musicAndAudio,
    "Video and Animation": videoAndAnimation,
    "Data, Analytics and AI": dataAnalytics,
    "Photography": photography,
    "Life Style": lifeStyle,
    "Other": [],
  };

  const isNotFirstItem = fieldIndex > 0

  const areasMap = useMemo(() => {
    if (!values[fieldIndex].area) return null;
    return <FieldsWrap>
      {optionsMap[values[fieldIndex].area as keyof typeof optionsMap].map((item, index) =>
        <FieldBox key={`${item}_${index}`}>
          <Field
            component={ExpertiseSubField}
            inputType='checkbox'
            fieldIndex={fieldIndex}
            index={index}
            fieldValue={item}
            labelText={item}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
            }}
            isPanel={isPanel}
          />
        </FieldBox>)}
    </FieldsWrap>

  }, [values]);

  const currentError = errors?.expertiseAreas?.[fieldIndex as keyof typeof errors.expertiseAreas];
  //@ts-ignore
  const currentAreaError = currentError?.area;
  //@ts-ignore
  const currentSkillsError = currentError?.skills;

  useEffect(() => {
    if (isPanel) {
      const filteredList = options.map(item => {
        return { ...item, disable: !!values.find(v => v.area === item.value) }
      });
      setOption(filteredList);
    }
  }, [isPanel])

  const onChangeAreaValue = (value: string) => {
    const oldValue = values[fieldIndex].area;
    const filteredList = options.map(item => {
      let isDisable = item.disable;
      if (item.value === value) { // selected value
        isDisable = true;
      };

      if (item.value === oldValue) { // old value
        isDisable = false;
      };
      return { ...item, disable: isDisable }
    });
    setOption(filteredList);
    setFieldValue(`expertiseAreas.${fieldIndex}.skills`, []);
  };

  const onRemoveItem = () =>{
    const currentValue = values[fieldIndex];
    const currentOptionIndex = options.findIndex(item=>item.value === currentValue.area);
    if(currentOptionIndex !== -1){
      const updatedOptions = [...options];
      updatedOptions[currentOptionIndex].disable = false;
      setOption(updatedOptions);
    };
    remove(fieldIndex);
  };

  return <ExpertiseFieldWrap isPanel={isPanel}>
    {isNotFirstItem && <Divider />}
    {isNotFirstItem && <RemoveIcon type="button" onClick={onRemoveItem}><img src={closeIcon} alt="" /></RemoveIcon>}
    <SelectFieldWrap>
      <Field
        component={SelectCom}
        labelText={'Area of Expertise'}
        id={`expertiseAreas${fieldIndex}area`}
        name={`expertiseAreas.${fieldIndex}.area`}
        options={options}
        error={!!currentAreaError}
        onChange={onChangeAreaValue}
      />
    </SelectFieldWrap>

    <ErrorMsg>{currentAreaError}</ErrorMsg>
    {values[fieldIndex].area && values[fieldIndex].area !== "Other" && <SubtitleAdvice>Choose two to six of your best skills in {values[fieldIndex].area}</SubtitleAdvice>}
    {areasMap}
    {values[fieldIndex].area && <ErrorMsg>{currentSkillsError}</ErrorMsg>}
  </ExpertiseFieldWrap>
};