import { StyleForWrap } from '../Steps.presents'
import { Container, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { NavBtn } from '../../BasicComponents/NavBtn'
import { useRootStore } from '../../../../state/stores/storeContext'
import { PropsType } from './SpecificationStep.props'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { SelectComponent } from './SpecificationStep.scheme'
import { StyleForQuestion, StyleForSubtitle, StyleForTitle } from '../Steps.presents'
import { Row } from 'react-bootstrap'
import { HelperText } from '../../../common/HelperText'
import { useEffect } from 'react'
import { checkIsJobExist } from '../../../../pages/JobStoryBuilder'
import { handleSubmit, validation } from './SpecificationStep.helper'
import { useState } from 'react'
import { Text } from '../../../common/Text';
import { onKeyDown } from '../Start/StartStep.helper'
import { FocusError } from '../../BasicComponents/FocusError/FocusError'
import * as _ from 'lodash';

export const SpecificationStep = observer(({ jobId, setActiveStep, setDoneStep, openDropdownQuestions, dropdownQuestions, toPrevStep, saveAnswers }: PropsType) => {
  const { jobStoryBuilder } = useRootStore();
  const history = useHistory();

  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false)
  const [isSaveBtnClicked, setSaveBtnClicked] = useState(false)

  useEffect(() => {
    const steps = { active: 3, done: 2 }
    jobId && checkIsJobExist({ jobStoryBuilder, jobId, history, setActiveStep, setDoneStep, steps })
  }, [jobId, jobStoryBuilder, history, setActiveStep, setDoneStep])

  let initialValues: any = {}

  jobStoryBuilder.steps.stepSpecification.forEach((item: any) => {
    initialValues[item.name] = jobStoryBuilder.answers.specificationStep[item.name]
  })

  for (const key in jobStoryBuilder.answers.specificationStep) { // For dropdown questions
    if (Array.isArray(jobStoryBuilder.answers.specificationStep[key]) && jobStoryBuilder.answers.specificationStep[key].length > 0) {
      initialValues[key] = jobStoryBuilder.answers.specificationStep[key];
    } else if (!Array.isArray(jobStoryBuilder.answers.specificationStep[key]) && jobStoryBuilder.answers.specificationStep[key]) {
      initialValues[key] = jobStoryBuilder.answers.specificationStep[key];
    }
  }


  initialValues = { ...initialValues, ...jobStoryBuilder.answers.specificationStep.filter }
  const updatingUrl = `/job-story/contact`;
  const primaryLocation = jobStoryBuilder.answers.startStep.locations;

  const saveResult = async (values: any, validateForm: Function) => {
    setFirstSubmitComplete(true);
    setSaveBtnClicked(true);
    const errors = await validateForm();
    _.isEmpty(errors) && handleSubmit({ values, saveAnswers, updatingUrl: null });
    setSaveBtnClicked(false);
  }

  return <StyleForWrap>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      validate={validation}
      onSubmit={values => handleSubmit({ values, saveAnswers, updatingUrl })}
    >
      {({
        values,
        errors,
        touched,
        validateForm,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched
      }) => (
        <>
          <FocusError isSaveBtnClicked={isSaveBtnClicked} />
          <Form onKeyDown={onKeyDown} onSubmit={handleSubmit} className='d-flex flex-column'>
            <Container fluid className="my-auto px-0">
              {
                jobStoryBuilder.steps.stepSpecification.map((item: any, index: number) => {
                  const dataForSelectComponent = {
                    type: item.componentType,
                    allQuestions: jobStoryBuilder.allQuestions.stepSpecification,
                    index,
                    item,
                    errors,
                    touched,
                    handleChange,
                    setFieldTouched,
                    setFieldValue,
                    values,
                    openedQuestions: jobStoryBuilder.steps.stepSpecification,
                    dropdownQuestions: dropdownQuestions,
                    openDropdownQuestions: openDropdownQuestions,
                    primaryLocation
                  }
                  return <Row key={index + 'question'} className="justify-content-center">
                    <StyleForQuestion className={touched[item.name] && errors[item.name] ? 'error' : ''}>
                      <StyleForSubtitle>{`Question ${index + 1}/${jobStoryBuilder.steps.stepSpecification.length}`}{item.optional ?
                        <span className='optional'>Optional</span> : <Text fs={16} text={errors[item.name] && touched[item.name] && typeof errors[item.name] === 'string' ? errors[item.name] : ''} error />}
                      </StyleForSubtitle>
                      <StyleForTitle>{item.title}{item.helperText && <HelperText autoChangePosition text={item.helperText} />}</StyleForTitle>
                      {item.subtitle && <StyleForSubtitle m={'8px 0 0'}>{item.subtitle}</StyleForSubtitle>}
                      {SelectComponent(dataForSelectComponent)}
                    </StyleForQuestion>
                  </Row>
                })
              }
            </Container>
            <div className='d-flex justify-content-between'>
              <NavBtn callbackFunction={() => toPrevStep({ jobId, history, setActiveStep, setDoneStep, steps: { active: 2, done: 1 } })} />
              <div className='d-flex'>
                <NavBtn exit callbackFunction={() => saveResult(values, validateForm)} />
                <NavBtn next callbackFunction={() => setFirstSubmitComplete(true)} />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  </StyleForWrap >
})