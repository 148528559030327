import { PropsType, Option } from './Select.props'
import { SelectWrap, StyleForLabel, SelectStyle } from './Select.presents'
import Select, { components } from 'react-select'
import { FieldProps } from 'formik'
import multiRemoveBtn from '../../../assets/img/multiRemoveBtn.svg'

export const SelectCom: React.SFC<PropsType & FieldProps> = ({
  options,
  field,
  form,
  setFieldTouched,
  ...props
}) => {

  const MultiValueRemove = (prop: any) => {
    return <components.MultiValueRemove {...prop}>
      <img src={multiRemoveBtn} alt="X" />
    </components.MultiValueRemove>
  };

  const onChange = (option: any) => {
    if (props.onChange) {
      const singleValue = option ? (option as Option).value : "";
      props.onChange(singleValue) // for custom onChange callback
    }
    form.setFieldValue(
      field.name,
      props.isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : option ?
          (option as Option).value
          : ''
    );
  };

  const getValue = () => {
    if (options && field.value) {
      var multiSelectValue
      if (props.isMulti) {
        multiSelectValue = options.filter(option => field.value.indexOf(option.value) >= 0)
      }

      const singleSelectValue = () => {
        const value = options.find(option => option.value === field.value)
        return !value ? '' : value
      }
      return props.isMulti
        ? multiSelectValue
        : singleSelectValue()
    } else {
      return props.isMulti ? [] : ("" as any);
    }
  };
  return <SelectWrap {...props}>
    <StyleForLabel htmlFor={field.name} className={!props.labelText ? 'd-none' : ''}>{props.labelText}</StyleForLabel>
    <SelectStyle disabled={props.disabled} isMulti={props.isMulti} error={props.error} >
      <Select
        isDisabled={props.disabled}
        isOptionDisabled={(option) => option.disable}
        components={{ MultiValueRemove }}
        name={field.name}
        isClearable
        isMulti={props.isMulti}
        defaultValue={props.defaultValue ? props.defaultValue : ''}
        options={options}
        onBlur={() => setFieldTouched && setFieldTouched(field.name, true)}
        value={getValue()}
        onChange={onChange}
        classNamePrefix="cl-select"
        placeholder={props.placeholder ? props.placeholder : 'Select your option...'}
      />
    </SelectStyle>
  </SelectWrap>

}