import { observer } from 'mobx-react-lite';
import { GalleryProps } from './Gallery.props';
import { VideoPlayer } from '../VideoPlayer';
import { StyleForMainVideo, StyleForVideo, StyleForVideosWrap, VideoTitle } from './Gallery.presents';


export const VideoGallery = observer((props: GalleryProps) => {
    const {
        videoGallery,
    } = props;

    return (
        <>
            <StyleForMainVideo>
                <VideoPlayer videoLink={videoGallery[0].url} />
                <VideoTitle>{videoGallery[0].fileName}</VideoTitle>
            </StyleForMainVideo>
          
            <StyleForVideosWrap>
                {videoGallery.slice(1,videoGallery.length).map((video) => {
                    return <StyleForVideo>
                        <VideoPlayer videoLink={video.url} />
                        <VideoTitle>{video.fileName}</VideoTitle>
                    </StyleForVideo>
                })}

            </StyleForVideosWrap>
        </>

    )
})
