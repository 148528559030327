import { theme } from '../../../../theme';
import styled from 'styled-components';

export const ApplyJobSectionContainer = styled('div')`
  width: 100%;
  padding: 80px 24px;
  max-width: 1680px;
  margin: 0 auto;
  background: ${theme.colors.blue};
  box-shadow: -10px 10px 20px rgba(63, 71, 79, 0.05), 10px -10px 20px rgba(63, 71, 79, 0.05);
  border-radius: ${theme.variables.borderRadiusMedium};
  text-align: center;

  @media(max-width: ${theme.screens.desktop}) {
    padding: 56px 24px;
  }

  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.extraBig} ${theme.offsets.lg};
  }
`

export const ApplyJobSectionTitle = styled('h2')`
  font-family: Recoleta;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #FFF;
  margin: 0;
  margin-bottom: ${theme.offsets.extraBig};

  @media(max-width: ${theme.screens.desktop}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: ${theme.offsets.lg};
  }

  @media(max-width: ${theme.screens.tablet}) {
    font-size: 24px;
    line-height: 30px;
  }
`
