import { PageContainer, PageContent, Wrapper } from "./CandidateForgotPassword.presents";
import useGetDevice from "../../../../hooks/useGetDevice";
import { Banner } from "../../../../components/Auth/Candidate/BasicComponents/Banner/Banner";
import { ForgotPassword } from "../../../../components/Auth/Candidate/ForgotPassword/ForgotPassword";

export const CandidateForgotPasswordPage = ({ isEmailVerify }: { isEmailVerify?: boolean }) => {
  const isDesktop = useGetDevice();
  return <Wrapper isDesktop={isDesktop}>

    <Banner isDesktop={isDesktop} />
    
    <PageContainer>
      <PageContent isDesktop={isDesktop}>
        <ForgotPassword isEmailVerify={isEmailVerify} />
      </PageContent>
    </PageContainer>
  </Wrapper>
}