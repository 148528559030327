import styled from "styled-components";
import { theme } from "../../theme";

export const Container = styled('div')`
  display: flex;
  flex: 1;
`

export const Content = styled('div')`
  flex: 1;
  background: #F0F3F8;
  padding-right: 0px;
  padding-left: 0px;
`

export const AdditionalButtons = styled('div')`
  display: flex;
  align-items: center;
`

export const ToggleWrap = styled('div')<{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 ${theme.offsets.extraBig};
  ${(props) => !props.isDesktop && `
    margin: 0;
  `}
`

export const ToggleLabel = styled('span')`
  font-size: ${theme.fontSizes.lg};
  margin-left: ${theme.offsets.xs};
  display: block;
  font-weight: 500;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
		font-size: 14px;
	}
`
export const TooltipWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`

export const DoneButtonWrap = styled('div') <{ isDesktop: boolean }>`
  width: 160px;
  ${(props) => !props.isDesktop && `
    width: 116px;
    @media(max-width: ${theme.screens.mobile}) {
      width: 73px;
    }
  `}
`

export const MobileNavSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  padding: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.md};
  }
`

export const ButtonsWrap = styled(MobileNavSection)`
@media(max-width: ${theme.screens.mobileSmall}) {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    button{
      margin-right: 0;
      padding-right: 0;
      margin-bottom: ${theme.offsets.lg};
    }
  }
`

export const MobileNavTitle = styled('span')`
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.lg};
  }
`

export const PreferencesFormWrap = styled('div')`
  margin-top: ${theme.offsets.big};
`
