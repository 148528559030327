import { Header } from '../../BaseComponents/Header/Header';
import { PreferencesForm } from '../../BaseComponents/PreferencesForm/PreferencesForm';
import { Wrapper, HeaderWrap, Title, FormWrap } from './Preferences.presents';

export const Preferences = () => {
  return <Wrapper>
    <HeaderWrap>
      <Header isLogoBlack isPreferencesPage />
    </HeaderWrap>
    <Title>Tell us about your work preferences and interests</Title>
    <FormWrap>
      <PreferencesForm isPanel={false}/>
    </FormWrap>

  </Wrapper>
}