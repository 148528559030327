import { PropsType } from './RadioBtn.props'
import { StyleForWrap } from './RadioBtn.presents'
import React, { useRef } from 'react'
import _ from 'lodash';

export const RadioBtn = (props: PropsType) => {
  let inputRef = useRef<HTMLInputElement>(null);

  const callbackHandler = () => {
    if (props.onClick) props.onClick();
  }

  const changeCheckedStatus = (value: any) => {
    if (props.name && props.value && props.openDropdownQuestions && props.dropdownQuestions?.hasOwnProperty(props.name)) {
      const dropdownQuestionsCopy = _.cloneDeep(props.dropdownQuestions);
      for (const key in dropdownQuestionsCopy[props.name]) {
        dropdownQuestionsCopy[props.name][key] = false;
      };
      if (props.value === 'unpaidInternship') {
        for (const key in dropdownQuestionsCopy.commision) {
          dropdownQuestionsCopy.commision[key] = false;
        };
      };
      dropdownQuestionsCopy[props.name][props.value] = true;
      props.openDropdownQuestions(dropdownQuestionsCopy);
    }
    props.handleChange(value)
  }
  return <StyleForWrap long={props.long} empty={props.empty} onClick={callbackHandler}>
    <input onBlur={() => props.setFieldTouched && props.setFieldTouched(props.name, true)} ref={inputRef} type={props.type ? props.type : "radio"} name={props.name} value={props.value} onChange={changeCheckedStatus} checked={props.checked} />
    <label htmlFor={props.name} onBlur={() => props.setFieldTouched && props.setFieldTouched(props.name, true)}>
      {React.cloneElement(props.activeIcon)}
      <div className='title-wrap'>
        <span className='title'>{props.text}</span>
        {props.subtitle ? <p className='subtitle'>{props.subtitle}</p> : ''}
      </div>
    </label>
  </StyleForWrap>
}