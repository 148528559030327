import * as React from "react"
import { SPBaseButtonProps } from "../SPBaseButton/SPBaseButton.props"
import { WhiteButton } from "./SPWhiteButton.presets"

export const SPWhiteButton: React.FC<SPBaseButtonProps> = (props) => {
  const clickHandler = (event: any) => {
    props.onClick && props.onClick()
  }

  return(
    <WhiteButton
      onClick={clickHandler}
      width={props.width}
      disabled={props.disabled}
      isLight={props.isLight}>
        {props.children}
    </WhiteButton>
  )
}
