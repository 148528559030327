export const PointOnMap = ({ active, setCenterCoord, item }: any) => <div onClick={() => {
  setCenterCoord({
    lat: item.latitude,
    lng: item.longitude,
    value: item.value
  })
}}
  className='point'>
  <svg width="106" height="120" viewBox="0 0 106 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M34.75 76.9767H51.25L43.7711 89.5452C43.4102 90.1516 42.5898 90.1516 42.2289 89.5452L34.75 76.9767Z" fill={active ? "#0083FF" : '#39434D'} />
      <path d="M10 24.8837C10 16.6637 16.5665 10 24.6667 10H61.3333C69.4335 10 76 16.6637 76 24.8837V62.093C76 70.3131 69.4335 76.9767 61.3333 76.9767H24.6667C16.5665 76.9767 10 70.3131 10 62.093V24.8837Z" fill={active ? "#0083FF" : '#39434D'} />
    </g>
    <path d="M50.2952 58H35.7048C31.4567 58 28 54.5297 28 50.2633V41.7367C28 37.4703 31.4567 34 35.7048 34H50.2952C54.5433 34 58 37.4703 58 41.7367V50.2633C58 54.5297 54.5433 58 50.2952 58Z" fill="white" />
    <path d="M49.6758 39C48.9448 39 48.3516 38.4741 48.3516 37.826V33.08C48.3516 30.4706 45.9574 28.348 43.0159 28.348C41.6617 28.348 40.2403 28.8301 39.2286 29.7176C38.2152 30.6083 37.6537 31.7933 37.6484 33.058V37.826C37.6484 38.4741 37.0552 39 36.3242 39C35.5932 39 35 38.4741 35 37.826V33.08C35.0088 31.1562 35.8493 29.3811 37.3642 28.0521C38.8773 26.72 40.8336 26.0517 43.0212 26C47.4176 26 51 29.176 51 33.08V37.826C51 38.4741 50.4068 39 49.6758 39Z" fill="white" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="106" height="120" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="10" dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.247059 0 0 0 0 0.278431 0 0 0 0 0.309804 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
</div>;