import { Container, Content } from "./JobList.presents";
import { DesktopSidebar } from "../../components/common/Sidebars/AdminSidebar/Desktop";
import { MobileAdminHeader } from "../../components/common/Headers/AdminHeader/Mobile/MobileAdminHeader";
import { DesktopHeader } from "../../components/common/Headers/AdminHeader/Desktop";
import { Support } from "../../components/AdminPanel/Support";
import useGetDevice from "../../hooks/useGetDevice";

export const SupportPage = () => {
  const isDesktop = useGetDevice();

  return <Container>
    {isDesktop && <DesktopSidebar />}
    <Content>
      {isDesktop ? <DesktopHeader/> : <MobileAdminHeader/>}
      <Support isDesktop={isDesktop} />
    </Content>
  </Container>
}