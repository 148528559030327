import { Field, FieldArray, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, DefaultBanner, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { Datepicker } from "../../../../common/DatePicker";
import { ReactComponent as CertificationsBanner } from "../../../../../assets/img/published.svg";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialCertifications } from "../../../../../state/stores/resumeBuilder";
import { FileInputWrap } from "../FileInput/FileInput";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { observer } from "mobx-react-lite";
import { onRemoveFile, onUploadFile } from "../../../../../services/firebase/firestore/ResumeBuilder/writes";
import { dataURLtoFile } from "../../../../../services/helper";
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const Certifications = observer(({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShowContent(!!resumeBuilderStore.certifications.length);
  }, [resumeBuilderStore.certifications.length])

  const initialValues = !!resumeBuilderStore.certifications.length ? [...resumeBuilderStore.certifications] : [initialCertifications];

  const defaultBanner = useMemo(() => !showContent ? <DefaultBanner>
    <CertificationsBanner />
    <SPSecondaryButton type={'button'} width="100%" onClick={() => setShowContent(true)}>
      {`Add Licenses & Certifications`}
    </SPSecondaryButton>
  </DefaultBanner> : null, [showContent]);

  return <CandidateFormCard
    componentRef={componentRef}
    isDesktop={isDesktop}
    hasCloseButton={showContent}
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Licenses & Certifications'}>
    <ContentWrap>
      {defaultBanner}
      {showContent && <Formik
        enableReinitialize
        initialValues={{ certifications: initialValues }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            certifications: Yup.array()
              .of(
                Yup.object().shape({
                  title: Yup.string().required(t('validation.required')),
                  institution: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const copyValues = values.certifications;
          for (const item of copyValues) {
            if (item.file.url && !item.file.isUploaded) {
              const fileData = dataURLtoFile(item.file.url, item.file.name);
              const uploadedFile: any = await onUploadFile(fileData, candidateStore.uid);
              if (!uploadedFile.error) {
                item.file.url = uploadedFile.url;
                item.file.isUploaded = true;
              }
            }
          };
          const result = await resumeBuilderStore.saveCertifications({ certifications: copyValues }, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="certifications">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };

                return <div>
                  {values.certifications.map((item: any, index: number) => {
                    const arrayErrors: any = errors.certifications;
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`certifications${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional licenses/certifications</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`certifications.${index}.title`}
                          type={'text'}
                          value={values.certifications[index].title}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Licenses/Certification Title'}
                          error={mainValidationCondition('title')} />
                        <ErrorMsg>{mainValidationCondition('title') ? arrayErrors[index].title : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`certifications.${index}.institution`}
                          type={'text'}
                          value={values.certifications[index].institution}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Institution'}
                          error={mainValidationCondition('institution')} />
                        <ErrorMsg>{mainValidationCondition('institution') ? arrayErrors[index].institution : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          name={`certifications.${index}.date`}
                          as={Datepicker}
                          value={values.certifications[index].date ? new Date(values.certifications[index].date) : ''}
                          setValue={(value: Date) => setFieldValueHandler(`certifications.${index}.date`, `${value}`)}
                          label={<LabelWrap>Date<span>optional</span></LabelWrap>}
                          maxDate={new Date()}
                          showMonthYearPicker
                        />
                        <ErrorMsg>{mainValidationCondition('date') ? arrayErrors[index].date : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`certifications.${index}.description`}
                          type={'textarea'}
                          value={values.certifications[index].description}
                          onChange={onChangeHandler}
                          maxLength={500}
                          labelText={<LabelWrap>Description<span>optional</span></LabelWrap>}
                          error={mainValidationCondition('description')} />
                        <ErrorMsg>{mainValidationCondition('description') ? arrayErrors[index].description : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          component={FileInputWrap}
                          name={`certifications.${index}.file`}
                          type={'file'}
                          value={values.certifications[index].file}
                          updateItemCallback={() => resumeBuilderStore.saveCertifications(values, candidateStore.uid)}
                          onChange={(value: string) => setFieldValueHandler(`certifications.${index}.file`, value)} />
                        <ErrorMsg>{mainValidationCondition('description') ? arrayErrors[index].file : ''}</ErrorMsg>
                      </FieldWrap>
                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialCertifications)}>
                      Add Work Licenses/Certifications
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                for (const item of values.certifications) {
                  if (item.file.url) {
                    await onRemoveFile(item.file.name, candidateStore.uid);
                  }
                };
                await resumeBuilderStore.saveCertifications({ certifications: [] }, candidateStore.uid);
                await resumeBuilderStore.setCertifications([]);
                handleReset();
                setShowContent(false);
              }}
            />}
          </form>}
      </Formik>}

    </ContentWrap>
  </CandidateFormCard>
})

