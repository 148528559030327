import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  padding: 64px 64px ${theme.offsets.xxl};
  background-color: ${theme.colors.white};
  border-radius: 32px;
  width: fit-content;
  margin-top: 3%;
  min-height: 80%;
  .cl-select__option {
    color: ${theme.colors.black};
  }
  .cl-select__menu .cl-select__menu-list .cl-select__option--is-selected {
    color: ${theme.colors.blue};
  }
  .cl-select__menu .cl-select__menu-list .cl-select__option--is-focused {
    color: ${theme.colors.blue};
  }
  
  form{
    width: 100%;
    max-width: 416px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  label{
    text-transform: initial;
  }
  button{
    margin-top: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.desktopXXL}) {
    padding: ${theme.offsets.extraMajor} ${theme.offsets.extraMajor} ${theme.offsets.xxl};
    border-radius: ${theme.variables.borderRadiusXLarge};
  }
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.xxl};
    form{
      max-width: 360px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    border-radius: ${theme.variables.borderRadiusLarge};
    box-shadow: -4px 4px 20px rgba(45, 53, 61, 0.1);
    margin-top: auto;
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 45%);
    width: calc(100% - 32px);
    max-width: 475px;
    z-index: 9;    
    form{
      max-width: 100%;
      width: 100%;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    box-shadow: -4px 4px 20px rgba(45, 53, 61, 0.1);
    transform: translate(-50%, 15%);
    padding: ${theme.offsets.lg};
  }
`

export const Title = styled('p')`
  font-family: Recoleta;
  font-size: ${theme.fontSizes.major};
  font-weight: 500;
  margin-bottom: ${theme.offsets.xxl};
  text-align: center;
  line-height: 44px;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.big};
    line-height: ${theme.fontSizes.extraBig};
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
    margin-bottom: ${theme.offsets.xxl};
  }
`

export const Description = styled('p')`
  font-size: 18px;
  margin: 0 auto 64px;
  text-align: center;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: 14px;
    margin-bottom: ${theme.offsets.extraBig};
  }
`

export const ErrorText = styled('div')`
  height: 12px;
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 12px;
`

export const HelperText = styled('p')`
  font-size: 14px;
  text-align: center;
  color: #656B72;
  margin-top: ${theme.offsets.big};
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.lg};
  }
`
