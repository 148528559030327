import { observer } from 'mobx-react-lite';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { useState } from 'react';
import { MediaModal } from '../../JobStory/BasicComponents/GallerySection/MediaModal';

import { EmptyGallery } from '../Gallery/EmptyGallery';
import { PhotoGallery } from '../Gallery/PhotoGallery';
import { GalleryProps } from '../Gallery/Gallery.props';


export const GalleryPreview = observer((props: GalleryProps) => {
    const {
        photoGallery,
        setActiveTab,
        isDesktop,
        tabsComponentRef,
        isPreview,
    } = props;

    const [isModalOpen, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleClose = () => {
        setSelectedIndex(0)
        setShowModal(false);
    }
    const openGalleryTab = () => {
        setActiveTab('gallery');
        tabsComponentRef.current?.scrollIntoView();
    }
    return (<OrganizationPreviewCard isDesktop={isDesktop} title={'Gallery'}>
        {photoGallery && photoGallery.length > 0 ?
            <>
                <PhotoGallery {...props} />
                <SPWhiteButton onClick={openGalleryTab}>See All Gallery </SPWhiteButton>
            </>
            :
            <EmptyGallery />
        }
        {isModalOpen && <MediaModal isOpen={isModalOpen} mediaFiles={photoGallery} selectedIndex={selectedIndex} onClose={() => handleClose()} />}
    </OrganizationPreviewCard>)
})
