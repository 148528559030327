import plusBlack from '../../../../assets/img/plusBlack.svg'
import clipIcon from '../../../../assets/img/clip.svg'
import { LikeIcon } from '../../../../assets/img/jobstoryIcons/active/Like'
import { DislikeIcon } from '../../../../assets/img/jobstoryIcons/active/Dislike'
import { EntryIcon } from '../../../../assets/img/jobstoryIcons/active/Entry'
import { MiddleIcon } from '../../../../assets/img/jobstoryIcons/active/Middle'
import { JuniorIcon } from '../../../../assets/img/jobstoryIcons/active/Junior'
import { SeniorIcon } from '../../../../assets/img/jobstoryIcons/active/Senior'
import audioFileIcon from '../../../../assets/img/audioFile.svg'
import documentFileIcon from '../../../../assets/img/documentFile.svg'

import { useEffect, useState } from 'react'

export const ActiveIcon = ({ name, checked }: { name: any, checked: boolean }) => {
  const [icon, setIcon] = useState<any>(<LikeIcon active={checked} />);

  useEffect(() => {
    const iconsList: any = {
      yes: <LikeIcon active={checked} />,
      no: <DislikeIcon active={checked} />,
      entry: <EntryIcon active={checked} />,
      junior: <JuniorIcon active={checked} />,
      mid: <MiddleIcon active={checked} />,
      senior: <SeniorIcon active={checked} />
    }

    setIcon(iconsList[name])

  }, [name, checked])

  return icon

}

export const btnIcons: any = {
  mediaFile: plusBlack,
  descriptionFile: clipIcon
}

export const uploadedIcons: any = {
  mediaFile: audioFileIcon,
  descriptionFile: documentFileIcon
}
