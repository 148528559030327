import { StyleFortabs, TabItem, IconWrap, Title, Count } from "./ListTabs.presents";
import { IconsList } from "../../Table/Table.icon";
import { ListTabsProps } from "./ListTabs.props";

export const ListTabs = (props: ListTabsProps) => {
  const { tabsData, isDesktop, callback, activeTab } = props;
  return <StyleFortabs isDesktop={isDesktop}>
    {tabsData.map((item, index) => <TabItem isActive={activeTab === item.value} isDesktop={isDesktop} key={index} onClick={() => callback(item.value)}>
      <IconWrap>
        <IconsList isActive={activeTab === item.value} iconType={item.value} />
      </IconWrap>
      <Title isActive={activeTab === item.value}>{item.label}</Title>
      <Count>{item.length}</Count>
    </TabItem>)}
  </StyleFortabs>
}