import styled from "styled-components";
import { theme } from "../../../../../theme";
import { Link } from "react-router-dom";


export const Wrapper = styled('div') <{ isDesktop: boolean }>`
  background-color: #EEF6FE;
  max-width: 664px; 
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: ${(props) => props.isDesktop ? '435px' : '100%'};
  }
  ${(props) => !props.isDesktop && `
    max-width: 100%;
    height: 270px;
    justify-content: flex-start;
    overflow: hidden; 
    @media(max-width: ${theme.screens.mobile}) {
      height: auto;
    }
  `}
`

export const ContentBlock = styled('div') <{ isDesktop: boolean }>`
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.offsets.xl} 0;
  ${(props) => !props.isDesktop && `
    max-width: 100%;
    height: 100%;
    max-height: 270px;
    position: relative;
    text-align: start;
    padding: ${theme.offsets.xl};
    @media(max-width: ${theme.screens.mobile}) {
      max-width: 65%;
      padding: ${theme.offsets.lg};
    }
  `}
`

export const SliderItem = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  color: ${theme.colors.black};
  text-align: center;
  align-items: center;
  justify-content: center;
  flex: 1;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: 315px;
    svg{
    width: 90%;
    object-fit: contain;
  }
  @media(max-width: ${theme.screens.tablet}) {
    align-items: flex-start;
    margin-top: ${theme.offsets.extraMajor};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.lg};
  }
  }
`

export const LogoWrap = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 420px;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: 315px;
  }
`

export const LogoLink = styled(Link)`
display: block;
@media(max-width: ${theme.screens.desktopXXL}) {
svg{
  width: 128px;
}
}
@media(max-width: ${theme.screens.mobile}) {
svg{
  width: 93px;
  height: 33px;
}
}
`

export const Title = styled('p')`
  margin-top: ${theme.offsets.extraMajor};
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.major};
  line-height: ${theme.fontSizes.extraMajor};
  position: relative;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.extraBig};
    line-height: ${theme.fontSizes.major};
    margin-top: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xxl};
    line-height: ${theme.fontSizes.big};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.lg};
    line-height: ${theme.fontSizes.xl};
  }
`

export const Description = styled('p')`
  font-weight: 400;
  font-size: 18px;
  line-height: ${theme.fontSizes.xxl};
  margin-top: ${theme.offsets.extraBig};
  padding: 0 56px;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: 14px;
    line-height: 18px;
    margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0;
    text-align: start;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin-top: ${theme.offsets.sm};
  }
`

export const MobileBannerImage = styled('div')`
  transform: scale(-1, 1);
  position: absolute;
  bottom: -50%;
  right: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.mobile}) {
    right: -20%;
    bottom: -70%;
    svg{
      width: 60%;
    }
  }
`