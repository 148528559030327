import { SPColors } from "./colors"
import { SPFontSizes } from "./fontSizes"
import { SPOffsets } from "./offsets"
import { SPScreensWidth } from "./screens"
import { SPThemeVariables } from "./variables"
import { typography } from "./typography"


export const theme = {
  offsets: SPOffsets,
  colors: SPColors,
  screens: SPScreensWidth,
  variables: SPThemeVariables,
  // TODO: move to typography
  fontSizes: SPFontSizes,
  typography
}

export type SPTheme = typeof theme
