import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { theme } from '../../../../../theme';

const AVATAR_SIZE = "52px"
export const Avatar = styled('img')`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border-radius: 50%;
  object-fit: cover;
  background: rgba(209, 215, 227, 0.2);
  border: 1px solid ${theme.colors.lightGray};
`

export const StyleForHeader = styled('div') <{ previewPage?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${theme.colors.white};
  padding: ${theme.offsets.lg} 100px ${theme.offsets.lg} ${theme.offsets.xxl};
  
  ${props => props.previewPage && `
    justify-content: space-between;
    padding: ${theme.offsets.lg} 20px;
    max-width: 1720px;
    margin: 0 auto;
    box-shadow: none;
    width:  100%;
    z-index: 99;
  `}
`

export const StyleForUserWrap = styled('button')`
  position: relative;
  margin-left: ${theme.offsets.xxl};
  cursor: pointer;
  user-select: none;
  border: none;
  background: none;
`

export const StyleForLogo = styled(Link)`
  display: flex;
  padding-top: 0px;
`

export const BtnsWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BtnContainer = styled('div')`
  width: 190px;
  margin-left: ${theme.offsets.lg};
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.xs};
    height: 24px;
    width: 24px;
    margin-bottom: 3px;
  }
`

export const CreateJobBtn = styled(BtnContainer)`
  button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.offsets.lg};
    line-height: 18px;
  }
`

export const AvatarWrap = styled('div')`
  display: flex;
  align-items: center;
`

export const UserName = styled('span')`
  margin-left: ${theme.offsets.lg};;
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  white-space: nowrap;
`

export const ArrowIcon = styled('img')<{isOpen:boolean}>`
  margin-left: ${theme.offsets.md};
  ${(props)=>props.isOpen && `
    transform: rotate(180deg);
  `}
`

export const OrganizationBtns = styled('div')`
  display: flex;
  align-items: center;
`

export const PreviewBtnContainer = styled(BtnContainer)`
  width: fit-content;
  margin-left: 0;
  margin-right: ${theme.offsets.extraBig};
  button:disabled{
    border: none;
    opacity: 0.5;
  }
`