import { useEffect, useState } from "react";
import { TimerWrapper } from "./VideoRecord.presents";

export const Timer = ({ timeLimit }: { timeLimit: number }) => {
  const [counter, setCounter] = useState(timeLimit);

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <TimerWrapper isRecording>00:{counter < 10 ? '0' + counter : counter}</TimerWrapper>
  );
}