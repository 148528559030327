import { SliderItem, Wrapper, Title, Description, LogoWrap, LogoLink, ContentBlock, MobileBannerImage } from "./Banner.presents";
import { ReactComponent as Logo } from '../../../../../assets/img/logo.svg';
import { ReactComponent as BannerIcon } from '../../../../../assets/img/candidateAuthBanner.svg';

export const Banner = ({ isDesktop }: { isDesktop: boolean }) => {
  return <Wrapper isDesktop={isDesktop}>
    <ContentBlock isDesktop={isDesktop}>
      <LogoWrap>
        <LogoLink to='/'>
          <Logo />
        </LogoLink>
      </LogoWrap>
      <SliderItem>

        {/* Image for Desktop version */}
        {isDesktop && <BannerIcon />}

        <Title>
          Get Hired Quickly
        </Title>
        <Description>
          Stand out to recruiters and hiring managers by using a video resume that highlights your qualifications and experience.
        </Description>
      </SliderItem>
    </ContentBlock>

    {/* Image for Desktop version */}
    {!isDesktop && <MobileBannerImage>
      <BannerIcon />
    </MobileBannerImage>}

  </Wrapper>
}