import { Title } from "../../components/common/Title"
import { Header } from "../../components/Auth/BaseComponents/Header"
import { SignIn } from "../../components/Auth/Employer/SignIn"
import { PageContainer, PageContent, Wrapper } from "./SignIn.presents"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { useRootStore } from "../../state/stores/storeContext"
import { useHistory } from "react-router"
import { Banner } from "../../components/Auth/Employer/SignUp/Banner"
import useGetDevice from "../../hooks/useGetDevice"

export const SignInPage = ({showRegister}:{showRegister: boolean }) => {
  const { userStore } = useRootStore();
  const history = useHistory();
  const isDesktop = useGetDevice();

  useEffect(() => {
    async function fetchData() {
      if (userStore.isAuthorizated) {
        const resData = await userStore.addUserData()
        !resData.err && history.push('/my-jobs')
      }
    }
    fetchData();
  }, [userStore.isAuthorizated, history, userStore])
  const { t } = useTranslation();
  return <Wrapper>
    {isDesktop && <Banner />}
    <PageContainer>
      <Header showLogo={!isDesktop} showRegister={showRegister} />
      <PageContent isDesktop={isDesktop}>
        <Title text={t('signIn.title')} />
        <SignIn waitlistRedirect={!showRegister}/>
      </PageContent>
    </PageContainer>
  </Wrapper>
}
