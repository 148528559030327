export const Calendar2Icon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 43V21H50V33.5L49.5 44.5L47.5 47.5L43 50.5L16.5 51L11.5 48.5L8 43Z" fill="white" />
    <path d="M9 16C9 12.6863 11.6863 10 15 10H43C46.3137 10 49 12.6863 49 16V20H9V16Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 21C7 20.4477 7.44772 20 8 20H50C50.5523 20 51 20.4477 51 21C51 21.5523 50.5523 22 50 22H8C7.44772 22 7 21.5523 7 21Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.5 4C39.0523 4 39.5 4.44772 39.5 5V13C39.5 13.5523 39.0523 14 38.5 14C37.9477 14 37.5 13.5523 37.5 13V5C37.5 4.44772 37.9477 4 38.5 4Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 4C20.5523 4 21 4.44772 21 5V13C21 13.5523 20.5523 14 20 14C19.4477 14 19 13.5523 19 13V5C19 4.44772 19.4477 4 20 4Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3159 10.9893C12.4777 8.98127 15.5319 8 19.1322 8H38.8893C42.4996 8 45.5548 8.98083 47.712 10.9903C49.8799 13.0097 51.0109 15.9467 50.9999 19.5869V40.3922C50.9999 44.0328 49.8632 46.9742 47.6937 48.9984C45.5345 51.0129 42.4796 52 38.8677 52H19.1322C15.5261 52 12.4697 50.9939 10.3089 48.9474C8.13992 46.8933 7 43.9109 7 40.2197V19.5862C7 15.9441 8.14316 13.0076 10.3159 10.9893ZM11.6771 12.4547C9.99682 14.0155 9 16.3722 9 19.5862V40.2197C9 43.4926 10.0001 45.9004 11.6842 47.4953C13.3764 49.098 15.8862 50 19.1322 50H38.8677C42.1296 50 44.6409 49.1113 46.3293 47.536C48.0075 45.9703 48.9999 43.6079 48.9999 40.3922V19.5862L48.9999 19.5831C49.0101 16.3682 48.0227 14.013 46.3488 12.4537C44.6638 10.8842 42.1529 10 38.8893 10H19.1322C15.8803 10 13.3683 10.8837 11.6771 12.4547Z" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="18.5" cy="29.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="18.5" cy="38.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="29" cy="29.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="29" cy="38.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="39.5" cy="29.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="39.5" cy="38.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
