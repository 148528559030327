import styled from "styled-components";
import { theme } from "../../../theme";
import { shineAnimation, shineLine } from "../../common/Skeleton/Skeleton.presents";

const AVATAR_SIZE = "120px";
const TABLET_AVATAR_SIZE = "80px";
const MOBILE_AVATAR_SIZE = "65px";

const MAIN_CONTENT_COLOR = "#F6F7F9"

export const Wrapper = styled('div')`
margin: ${theme.offsets.xxl} auto;
max-width: 1681px;
@media(max-width: ${theme.screens.desktop}) {
  padding: ${theme.offsets.lg} ${theme.offsets.xxl};
}
@media(max-width: ${theme.screens.tablet}) {
  margin-bottom: 0;
  padding: ${theme.offsets.md} ${theme.offsets.xxl};
}
@media(max-width: ${theme.screens.mobile}) {
  padding: ${theme.offsets.sm} 0;
}
`

export const ShineSection = styled('div')`
  width: 100%;
  overflow: hidden;
  position: relative;
::after{
    ${shineAnimation}
    ${shineLine}
    }
`

export const TextContent = styled('div') <{ width?: string }>`
  width: ${(props) => props.width ? props.width : '100%'};
  border-radius: 4px;
  background-color: ${MAIN_CONTENT_COLOR};
  height: 17px;
  margin-top: ${theme.offsets.sm};
  margin-right: ${theme.offsets.extraBig};
`

export const BenefitCard = styled('div') <{ width?: string }>`
  width: ${(props) => props.width ? props.width : '100%'};
  border-radius: ${theme.variables.borderRadiusSmall};
  background-color: ${MAIN_CONTENT_COLOR};
  height: 37px;
  margin-right: ${theme.offsets.lg};
  margin-bottom:  ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) { 
    margin-right: ${theme.offsets.md};
    margin-bottom:  ${theme.offsets.md};
  } 
`

export const CoverSection = styled('div')`
  border-radius: ${theme.variables.borderRadiusMedium};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  background: ${theme.colors.white};
  margin: ${theme.offsets.xl} auto;
  padding-bottom: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.mobile}) { 
    width: auto;
  } 
`

export const LogoWrap = styled('div')`
  display: flex;
  flex-direction: column;
  width: 132px;
  margin-top: -80px;
  margin-left: 30px;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: -60px;
    margin-left: ${theme.offsets.lg};
    width: 86px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: -50px;
    width: 75px;
  } 
`

export const Logo = styled('div')`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border: 6px solid ${theme.colors.white};
  border-radius: ${theme.variables.borderRadiusLarge};
  background-color: ${MAIN_CONTENT_COLOR};
  @media(max-width: ${theme.screens.tablet}) {
    width: ${TABLET_AVATAR_SIZE};
    height: ${TABLET_AVATAR_SIZE};
    border: 3px solid ${theme.colors.white};
    border-radius: ${theme.variables.borderRadiusSmallMedium};
  } 
  @media(max-width: ${theme.screens.mobile}) {
    width: ${MOBILE_AVATAR_SIZE};
    height: ${MOBILE_AVATAR_SIZE};
  } 
`

export const Banner = styled('div')`
  background-color: ${MAIN_CONTENT_COLOR};
  width: 100%;
  height: 560px;
  @media(max-width: ${theme.screens.desktop}) { 
    height: 370px;
  }
  @media(max-width: ${theme.screens.tablet}) { 
    height: 240px;
  }
`

export const ContentWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.isDesktop ? 'row' : 'column'}; 
  ${(props) => !props.isDesktop && `
    @media(max-width: ${theme.screens.mobile}) { 
      padding: 0 ${theme.offsets.lg};
      }
  `};
`

export const TabSection = styled('div') <{ isDesktop: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusLarge};
  margin-bottom: ${theme.offsets.xxl};
  padding: 0 ${theme.offsets.extraBig};
  padding-right: 50%;
  ${(props) => !props.isDesktop && `
    @media(max-width: ${theme.screens.desktop}) {
      border-radius: ${theme.variables.borderRadiusSmallMedium}; 
    }
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.xxl};
    }
    @media(max-width: ${theme.screens.mobile}) { 
      background: none;
      box-shadow: none;
      margin-bottom: 20px;
      padding: 0;
      height: auto;
      div{
        background: #EBEBEB;
        margin-top: 0;
        margin: 0 ${theme.offsets.sm};
      }
      }
  `}; 
`

export const InfoBlock = styled('div') <{ isDesktop: boolean }>`
  max-width: ${(props) => props.isDesktop ? '1112px' : '100%'};
  width: 100%;
  margin-right: ${theme.offsets.md};
  ${(props) => !props.isDesktop && `
    @media(max-width: ${theme.screens.tablet}) { 
     margin-right: 0;
    }
  `}; 
`

export const ContentCard = styled('div') <{ isDesktop: boolean, height?: string }>`
  width: 100%;
  height: ${(props) => props.height ? props.height : 'auto'};
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  padding: ${theme.offsets.extraBig};
  margin-bottom: ${theme.offsets.xxl};
  hr{
    width: 100%;
    margin: ${theme.offsets.extraBig} 0;
    color: ${MAIN_CONTENT_COLOR};
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.lg};
    padding: ${theme.offsets.lg};
  }
`

export const PreviewBlock = styled('div') <{ isDesktop: boolean }>`
  max-width: ${(props) => props.isDesktop ? '544px' : '100%'};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${theme.offsets.md};
  ${(props) => !props.isDesktop && `
  margin-left: 0px;
  `};
`

export const OverviewContentWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
  }
`
export const OverviewContent = styled('div') <{ isLastItem: boolean }>`
  display: flex;
  flex-direction: column;
  width: 20%;
  @media(max-width: ${theme.screens.mobile}) {
    width: 100%;
    ${(props) => !props.isLastItem && `
    padding-bottom: ${theme.offsets.extraBig};
    margin-bottom: ${theme.offsets.extraBig};
    border-bottom: 1px solid rgba(0,0,0,.1);
    `}
  }
`

export const JobCart = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const AudioContent = styled('div')`
display: flex;
height: 40px;
margin-top: ${theme.offsets.extraBig};
::before, ::after{
  content: '';
  display: block;
  background: ${MAIN_CONTENT_COLOR};
  border-radius: ${theme.variables.borderRadiusSmall};
  height: 100%;
}
::before{
  width: 20%;
}
::after{
  flex: 1;
  margin-left: ${theme.offsets.lg};
}
`

export const BenefitsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.offsets.extraBig};
  margin-right: -${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: -${theme.offsets.md};
  }
`

export const SocNetworksList = styled('div')`
  display: flex;
  margin-top: ${theme.offsets.extraBig};
`

export const SocNetworksCard = styled('div')`
  background: ${MAIN_CONTENT_COLOR};
  border-radius: ${theme.variables.borderRadiusSmall};
  margin-right: ${theme.offsets.lg};
  width: 52px;
  height: 52px;
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: ${theme.offsets.sm};
  }
`

export const GalleryList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.offsets.extraBig};
  margin-right: -${theme.offsets.xs};
`

export const GalleryCard = styled('div')`
  background: ${MAIN_CONTENT_COLOR};
  border-radius: ${theme.variables.borderRadiusSmall};
  margin-right: ${theme.offsets.xs};
  margin-bottom: ${theme.offsets.xs};
  width: 155px;
  height: 155px;
  @media(max-width: ${theme.screens.tablet}) {
    width: 100px;
    height: 100px;
  }
`

export const JobInfo = styled('div')`
  display: flex;
  @media(max-width: ${theme.screens.tablet}) {
    div{
      height: 36px;
      width: 35%;
      :last-of-type{
        width: 55%;
      }
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
  }
`

export const Snapshots = styled('div')`
  display: flex;
  @media(max-width: ${theme.screens.mobile}) {
  div{
    margin-right: ${theme.offsets.sm};
    width: 20%;
  }
  }
`