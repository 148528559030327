import { Field } from "formik";
import styled from "styled-components";
import { theme } from "../../../../theme";

export const Wrapper = styled('div') <{ isSecondItem: boolean }>`
	${(props) => props.isSecondItem && `
	margin-top: ${theme.offsets.major};
  padding-top: ${theme.offsets.xl};
  border-top: 1px solid ${theme.colors.lightGray};
	`}
`

export const RemoveBtnWrap = styled('div')`
  display: flex;
	justify-content: flex-end;
	align-items: center;
`

export const ContentWrap = styled('div') <{ isSecondItem: boolean }>`
  width: 100%;
	max-width: 606px;
  margin: ${(props)=>props.isSecondItem ? 0 : theme.offsets.extraBig} auto ${theme.offsets.lg};
`

export const FieldsWrap = styled('div')`
	display: flex;
	justify-content: space-between;
`

export const FieldContainer = styled(Field)`
	max-width: 291px;
	width: 100%;
	margin-top: ${theme.offsets.md};
	margin-bottom: ${theme.offsets.md};
`