import { StyleForWrap, StyleForTitle } from './JobsList.presents';
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../state/stores/storeContext";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ShareModal } from "../../JobStory/BasicComponents/ShareModal";
import { MobileJobItem } from "./Components/Mobile/MobileJobList";
import { TableActionsModal } from "../../common/Modals/TableActionsModal/TableActionsModal";
import { Actions } from '../../common/Modals/TableActionsModal/TableActionsModal.props';
import { DesktopJobList } from './Components/Desktop/DesktopJobList';
import { getLastStep, mobileJobSortList, setTabsForSorting } from './helper';
import { DesktopOnlyFeatureModal } from '../../common/Modals/DesktopOnlyFeatureModal';
import { EmptyListBanner } from './Components/EmptyListBanner';
import { Pagination } from '../../common/Pagination';
import { ListTabs } from '../../common/Tabs/ListTabs';

export const JobsList = observer(({ isDesktop }: { isDesktop: boolean }) => {
  const history = useHistory();
  const { jobStory, userStore } = useRootStore();

  const [isShareModalOpen, setShowShareModal] = useState(false);
  const [shareJobId, setShareJobId] = useState("");
  const [showModal, setShowModal] = useState<{ isOpen: boolean, typeOfModal: Actions, jobId: '', oldStatus: '' }>({ isOpen: false, typeOfModal: Actions.draft, jobId: '', oldStatus: '' });
  const [activeTab, setActiveTab] = useState('all');
  const [activePage, setActivePage] = useState(0);
  const [activeActionBtn, setActiveActionBtn] = useState<number | null>(null);
  const [isSpinnerOpen, setSpinnerOpen] = useState(true);
  const [isDesktopOnlyFeatureModalOpen, setDesktopOnlyFeatureModalOpen] = useState(false);

  const [sortData, setSortData] = useState({ type: 'creationDate', value: 'up' });
  const [mobileSortSelectedItem, setMobileSortSelectedItem] = useState<number | null>(null);

  const handleOpenMenu = (index: number) => {
    const isMenuOpen = index === activeActionBtn;
    setActiveActionBtn(isMenuOpen ? null : index);
  };

  const handleOpenModal = (typeOfModal: Actions, item: any) => {
    setShowModal({ isOpen: true, typeOfModal, jobId: item._id, oldStatus: item.jobStatus });
    setActiveActionBtn(null);
  };

  const handleSortTable = async (sortType: string) => {
    let newValueOfSorting = 'up'
    setSpinnerOpen(true);
    if (sortData.type === sortType) {
      newValueOfSorting = sortData.value === 'up' ? 'down' : 'up';
    }

    setSortData({ type: sortType, value: newValueOfSorting });
    await jobStory.setJobList(activePage, activeTab, { type: sortType, value: newValueOfSorting });
    setSpinnerOpen(false);
  }

  const handleMobileSorting = async (index: number) => {
    setSpinnerOpen(true);
    const sortItem = mobileJobSortList[index];
    setMobileSortSelectedItem(index);
    setSortData(sortItem.data);
    await jobStory.setJobList(activePage, activeTab, sortItem.data);
    setSpinnerOpen(false);
  };

  const handleSetActiveTab = async (filterValue: string) => {
    setSpinnerOpen(true);
    setActiveTab(filterValue);
    await jobStory.setJobList(0, filterValue, sortData);
    setActiveActionBtn(null);
    setSpinnerOpen(false);
  }

  const handleEditJob = (jobId: string, steps: any) => {
    const lastStep = getLastStep(steps);
    history.push(`/job-story/${lastStep}?id=${jobId}`);
  }

  const handleShareJob = (jobId: string,) => {
    setShowShareModal(true);
    setShareJobId(jobId);
    setActiveActionBtn(null);
  }

  const navigatePage = async (direction: 'next' | 'prev') => {
    setSpinnerOpen(true);
    const pageToNavigate = direction === 'next' ? activePage + 1 : activePage - 1;
    await jobStory.setJobList(pageToNavigate, activeTab, sortData);
    setActivePage(pageToNavigate);
    setSpinnerOpen(false);
  };

  useEffect(() => {
    (async () => {
      setSpinnerOpen(true);
      await jobStory.setJobList(0, 'all', sortData);
      setSpinnerOpen(false);
    })()
  }, [jobStory])

  const createJob = () => {
    if (isDesktop) {
      const step = userStore.isBuildIntroDisabled ? 'start' : 'intro'; 
      history.push(`/job-story/${step}`);
    } else {
      setDesktopOnlyFeatureModalOpen(true);
    }
  }

  const DesktopJobListProps = {
    handleOpenModal,
    handleShareJob,
    handleEditJob,
    activeActionBtn,
    handleSortTable,
    sortData,
    handleOpenMenu,
    setActiveActionBtn,
    showTableBody: !isSpinnerOpen && jobStory.jobList.length > 0,
    isDesktop,
    history,
    isSpinnerOpen
  };

  const MobileJobListProps = {
    handleOpenMenu,
    handleOpenModal,
    handleShareJob,
    handleEditJob,
    activeActionBtn,
    isDesktop,
    createJob,
    handleMobileSorting,
    mobileSortList: mobileJobSortList,
    selectedIndex: mobileSortSelectedItem,
    history,
    isSpinnerOpen
  };

  const tabsForSorting = setTabsForSorting(jobStory.typesLength);
  const isListEmpty = !isSpinnerOpen && jobStory.typesLength && jobStory.jobList.length === 0;

  return <StyleForWrap isDesktop={isDesktop} isLoading={isSpinnerOpen}>
    <StyleForTitle isDesktop={isDesktop}>
      <p className='title'>My Jobs</p>
      {isDesktop && <Pagination
        isDesktop={isDesktop}
        activePage={activePage}
        navigatePage={navigatePage}
        listLength={jobStory.listLength}
      />}
    </StyleForTitle>
    <ListTabs activeTab={activeTab} callback={handleSetActiveTab} isDesktop={isDesktop} tabsData={tabsForSorting} />
      {isDesktop ? <DesktopJobList {...DesktopJobListProps} /> : <MobileJobItem {...MobileJobListProps} />}
      {(!isDesktop && !isSpinnerOpen )&& <Pagination
        isDesktop={isDesktop}
        activePage={activePage}
        navigatePage={navigatePage}
        listLength={jobStory.listLength}
      />}

    {/*Helper Components*/}

    {/*Banner for empty jobList*/}
    {isListEmpty && <EmptyListBanner createJob={createJob} activeTab={activeTab} />}
    {/*Action Modal*/}
    {showModal.isOpen && <TableActionsModal
      typeOfModal={showModal.typeOfModal}
      setShowModal={setShowModal}
      showModal={showModal.isOpen}
      activePage={activePage}
      activeTab={activeTab}
      jobId={showModal.jobId}
      oldStatus={showModal.oldStatus}
      sortData={sortData}
      handleSetActiveTab={handleSetActiveTab} />}
    {/* Share modal */}
    {isShareModalOpen && <ShareModal url={`${window.location.host}/jobs?id=${shareJobId}`} isAdminMode isOpen={isShareModalOpen} onClose={() => setShowShareModal(false)} />}
    {/* Desktop only feature warning modal */}
    {isDesktopOnlyFeatureModalOpen && <DesktopOnlyFeatureModal isOpen={isDesktopOnlyFeatureModalOpen} onClose={() => setDesktopOnlyFeatureModalOpen(false)} />}
  </StyleForWrap >
})
