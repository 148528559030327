import * as Yup from 'yup';
import { HandleSubmitType } from './DescriptionStep.props';

const changeEducationValue = (values: any,) => {
  if (values.educationForJob === 'yes') {
    const isAnyLevel = values.educationDegree && values.educationDegree.includes('acceptable');
    const isAnyMajors = values.educationMajor && values.educationMajor.includes('acceptable');
    values.educationLevel = isAnyLevel ? ['Any education level'] : values.educationLevel;
    values.majorsJob = isAnyMajors ? ['Any education major, minor and subjects'] : values.majorsJob;
  } else {
    values.educationLevel = [];
    values.majorsJob = [];
  }
}

export const handleSubmit = async ({ values, saveAnswers, updatingUrl }: HandleSubmitType) => {
  const arrayWithDependents: any = [];
  const valuesCopy = { ...values };
  changeEducationValue(valuesCopy);
  const stepInfo = {
    title: 'description',
    nextStep: 'specification',
    done: 2
  }
  saveAnswers({ values: valuesCopy, stepInfo, arrayWithDependents, updatingUrl })
}

export const validation = (values: any) => {
  const errors: any = {};
  const validationLogic = (valuesKey: any) => {
    switch (valuesKey) {
      case 'jobExperience':
        if (values.jobExperience === 'yes' && !values.experienceYearsFrom) {
          errors.jobExperience = 'Required'
          errors.experienceYearsFrom = 'Required'
        } else if (values.jobExperience === 'yes' && !values.experienceYearsTo) {
          errors.jobExperience = 'Required'
          errors.experienceYearsTo = 'Required'
        }
        break;
      case 'educationForJob':
        if (values.educationForJob === 'yes' && values.educationLevel.length <= 0 && !values.educationDegree.includes('acceptable')) {
          errors.educationForJob = 'Select Level Of Education'
          errors.educationLevel = 'Select Level Of Education'
        }
        break;
      case 'certificationsForJob':
        if (values.certificationsForJob === 'yes' && values.certificationType.length > 10) {
          errors.certificationsForJob = 'Maximum 10 certificates'
          errors.certificationType = 'You can enter a maximum of 10 certificates'
        }
        break;
      default:
    }
  }
  for (const key in values) {
    validationLogic(key)
  }
  return errors
}

export const validationScheme = (t: any) => Yup.object().shape({
  jobTitle: Yup.string()
    .trim()
    .required('Required')
    .min(3, t('validation.min', { characters: 3 }))
    .max(50, t('validation.max', { characters: 50 })),
  jobTrailer: Yup.string()
    .trim()
    .required('Required')
    .min(20, t('validation.min', { characters: 20 }))
    .max(140, t('validation.max', { characters: 140 })),
  jobSummary: Yup.string()
    .trim()
    .required('Required')
    .min(20, t('validation.min', { characters: 20 }))
    .max(500, t('validation.max', { characters: 500 })),
  keyAboutJob: Yup.array()
    .max(20, 'Exceeding 20 tags'),
  mustSkills: Yup.array()
    .min(1, 'Must include at least one')
    .max(20, 'Exceeding 20 tags')
    .required('Required'),
  niceSkills: Yup.array()
    .max(20, 'Exceeding 20 tags'),
  jobExperience: Yup.string()
    .required('Required'),
  experience: Yup.string()
    .required('Required'),
  educationForJob: Yup.string()
    .required('Required'),
  certificationsForJob: Yup.string()
    .required('Required'),
  responsibilities: Yup.array()
    .max(20, 'Exceeding 20 tags'),
  requirements: Yup.array()
    .max(20, 'Exceeding 20 tags'),
  startup: Yup.string()
    .required('Required'),
  languages: Yup.array()
    .of(
      Yup.object().shape({
        language: Yup.string().required('Required'),
        level: Yup.string().required('Required'),
      })
    ),
  additionalInfo: Yup.string()
    .max(5000, t('validation.max', { characters: 5000 })),
  companyCulture: Yup.string()
    .max(2000, t('validation.max', { characters: 2000 })),
  aboutTeam: Yup.string()
    .max(2000, t('validation.max', { characters: 2000 })),
  aboutJob: Yup.string()
    .max(2000, t('validation.max', { characters: 2000 }))
})