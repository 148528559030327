export const RocketIcon = ({ active }: { active?: boolean }) => {
  return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="35" cy="21" r="5" fill="#F8DDC3" />
    <path d="M41 5.5H50.5V10.5L49.5 15.5H47L42.5 12L41 8.5V5.5Z" fill="#F8DDC3" />
    <path d="M12 34L15 32.5L23.5 41L23 43L21 44.5L12 34Z" fill="white" />
    <path d="M13.5 19L23 17.5V18.5L12 29.5L5.5 27.5L13.5 19Z" fill="white" />
    <path d="M26.5 43.5L38 32.5L37.5 41.5L28 51L26.5 43.5Z" fill="white" />
    <path d="M35 8L40 6L43 13L49.5 16L49 20L45 25.5L27.5 43H26L13 29L26 15.5L35 8Z" fill="white" />
    <path d="M51.7963 5.07196C51.7401 4.6177 51.3823 4.25993 50.9282 4.20376C46.8357 3.69758 42.6126 4.13881 38.7141 5.47925C34.8153 6.81982 33.3003 6.98238 30.385 9.89765L23.7323 16.5503L14.1294 17.7888C13.9104 17.8171 13.7072 17.917 13.5511 18.073L4.29232 27.3318C4.03025 27.5939 3.93495 27.9797 4.04502 28.3338C4.15483 28.6878 4.45204 28.9517 4.81646 29.0192L11.8171 30.3159L13.1926 31.6915L10.2641 33.2773C9.98695 33.4273 9.79689 33.6995 9.7515 34.0114C9.70611 34.3232 9.8106 34.6381 10.0334 34.8609L21.1389 45.9665C21.3275 46.1551 21.582 46.2589 21.8449 46.2589C21.8925 46.2589 21.9407 46.2554 21.9886 46.2485C22.3005 46.2031 22.5727 46.0131 22.7227 45.736L24.3087 42.8074L25.6842 44.1829L26.9809 51.1836C27.0485 51.5481 27.3123 51.8452 27.6663 51.9552C27.7636 51.9854 27.8633 52 27.9624 52C28.2232 52 28.4783 51.8976 28.6684 51.7076L37.9272 42.4488C38.0832 42.2928 38.1832 42.0894 38.2114 41.8706L39.45 32.2675L46.1027 25.6147C49.0179 22.6994 49.1805 21.1846 50.5211 17.2857C51.8614 13.3872 52.3023 9.16357 51.7963 5.07196ZM11.9692 28.3139L7.046 27.4021L14.7222 19.7256L21.4213 18.8616L11.9692 28.3139ZM21.6044 43.6088L12.3914 34.3959L14.6654 33.1645L22.8357 41.3349L21.6044 43.6088ZM36.2745 41.2779L28.5982 48.9543L27.6862 44.0312L37.1385 34.5789L36.2745 41.2779ZM44.6906 24.2033L26.6091 42.2848L13.7153 29.391L31.7968 11.3093C34.4239 8.68205 35.5665 8.72767 39.065 7.47467C39.5168 9.80284 40.6552 11.9626 42.3464 13.6537C44.0374 15.3448 46.1971 16.4832 48.5254 16.9352C47.2722 20.4335 47.3177 21.576 44.6906 24.2033ZM49.1263 15.0171C47.1028 14.672 45.2205 13.7044 43.758 12.242C42.2955 10.7797 41.328 8.89724 40.9829 6.87373C43.8766 6.0939 46.9163 5.82797 49.8992 6.10082C50.1722 9.08371 49.9061 12.1234 49.1263 15.0171Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M30.8228 16.6129C28.4616 18.9742 28.4616 22.8161 30.8228 25.1774C32.0035 26.3582 33.5541 26.9483 35.1051 26.9483C36.6556 26.9483 38.2068 26.3579 39.3873 25.1774C41.7485 22.8163 41.7486 18.9742 39.3873 16.6129C37.026 14.2517 33.184 14.2519 30.8228 16.6129ZM37.9757 23.7657C36.3926 25.3486 33.8171 25.3483 32.2346 23.7657C30.6516 22.1828 30.6516 19.6074 32.2346 18.0246C33.0258 17.2331 34.0655 16.8376 35.105 16.8376C36.1446 16.8376 37.1841 17.2333 37.9754 18.0246C39.5583 19.6074 39.5583 22.1828 37.9757 23.7657Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M14.4569 41.7317C14.067 41.3418 13.435 41.3418 13.0452 41.7317L8.77483 46.0029C8.38498 46.3927 8.38498 47.0248 8.77483 47.4145C8.96982 47.6094 9.22537 47.7069 9.48079 47.7069C9.7362 47.7069 9.99175 47.6095 10.1867 47.4145L14.4569 43.1433C14.8467 42.7536 14.8467 42.1215 14.4569 41.7317Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M17.5185 44.2606C17.1285 43.8708 16.4965 43.8708 16.1067 44.2606L11.8363 48.5318C11.4465 48.9217 11.4465 49.5538 11.8363 49.9435C12.0313 50.1383 12.2869 50.2359 12.5423 50.2359C12.7977 50.2359 13.0533 50.1385 13.2483 49.9435L17.5185 45.6723C17.9082 45.2826 17.9082 44.6505 17.5185 44.2606Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M11.8132 39.1827C11.4232 38.7928 10.7913 38.7928 10.4014 39.1827L6.0455 43.2734C5.65566 43.6632 5.65566 44.2953 6.0455 44.685C6.24049 44.8799 6.49604 44.9775 6.75146 44.9775C7.00688 44.9775 7.26243 44.88 7.45742 44.685L11.8133 40.5943C12.203 40.2046 12.2031 39.5725 11.8132 39.1827Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
