import { useFormikContext } from "formik";
import { useEffect } from "react";
import { PropsType } from "./FocusError.props";

export const FocusError = ({ isSaveBtnClicked }: PropsType) => {
  const { errors, isValidating, isSubmitting }: any = useFormikContext();

  const getErrorElement = (keys: Array<string>) => {
    const firstErrorItem = errors[keys[0]];
    let selector = `[name=${keys[0]}]`;
    //For FieldArray Component
    if (Array.isArray(firstErrorItem)) {
      const indexWithError = firstErrorItem.findIndex((item) => item);
      const keysFromArrayItem = Object.keys(errors[keys[0]][indexWithError]);
      selector = `[id=${keys[0]}${indexWithError}${keysFromArrayItem[0]}]`
    }
    let errorElement = document.querySelector(selector) as HTMLElement;
    //For CustomSelectComponent
    const isComponentHidden = errorElement && (errorElement.getAttribute('type') === 'hidden' || errorElement.style.display === 'none');
    if (isComponentHidden) {
      errorElement = document.querySelector(selector)?.closest('div') as HTMLElement;
    }
    return errorElement
  }


  useEffect(() => {
    if (!isValidating && (isSubmitting || isSaveBtnClicked)) {
      let keys: Array<string> = Object.keys(errors);
      if (keys.length > 0) {
        const errorElement = getErrorElement(keys);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [errors, isValidating]);

  return null;
};