import { Field, Formik } from "formik";
import { useMemo, useState } from "react";
import { FieldWrap } from "../../../../../theme/typography";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { Tags } from "../../../../JobStoryBuilder/BasicComponents/Tags";
import { ErrorMsg } from "../../../Profile/Profile.presents";
import { AdditionalButtons, ContentWrap, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { PopularOptionsLabel } from "./SocialSkills.presents";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';

const popularOptionsData = [
  'Problem-solving',
  'Time management',
  'Critical thinking',
  'Adaptability',
  'Conflict management',
  'Leadership',
  'Decision-making',
  'Organizational',
  'Resourcefulness',
  'Persuasion',
  'Openness to criticism',
];

export const SocialSkills = observer(({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [popularOptions, setPopularOptions] = useState(popularOptionsData);
  const [removedPopularOptions, setRemovedPopularOptions] = useState<Array<string> | []>([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { resumeBuilderStore, candidateStore } = useRootStore();
  const initialValues = [...resumeBuilderStore.socialSkills];

  const cardSubtitle = "Social and business skills, sometimes referred to as soft skills or core skills, are the interpersonal attributes you need to succeed in the workplace, and are applicable to all professions.";

  const renderPopularOptions = (callback: (value: string) => void, values: Array<string>) => {
    const addNewSkill = (value: string, index: number) => {
      callback(value);
      setShowSaveBtn(true);
      const removedItem = popularOptions.splice(index, 1);
      setRemovedPopularOptions([...removedPopularOptions, ...removedItem]);
      setPopularOptions(popularOptions);
    }

    return !!popularOptions.length ? <AdditionalButtons>
      {popularOptions.map((item, index) => {
        if (!values.includes(item)) {
          return <SPSecondaryButton
            onClick={() => addNewSkill(item, index)}
            isLight width="fit-content"
            key={`popularOptions${index}`}
            type="button">
            {item}
          </SPSecondaryButton>
        }
      }
      )}
    </AdditionalButtons> : null;

  }

  return <CandidateFormCard
    isDesktop={isDesktop}
    componentRef={componentRef}
    title={'Social and Business Skills'}
    subtitle={cardSubtitle}
  >
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={{ socialSkills: initialValues }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            socialSkills: Yup.array()
              .max(50, 'Exceeding 50 tags'),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveSocialSkills(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue
        }) => {
          const addTagsList = (tags: Array<string>) => {
            setFieldValue('socialSkills', tags);
            setShowSaveBtn(true);
            !!removedPopularOptions.length && removedPopularOptions.forEach((removedTag, index) => {
              if (!tags.includes(removedTag)) {
                setPopularOptions([...popularOptions, removedTag]);
                const updatedRemovedOptions = [...removedPopularOptions];
                updatedRemovedOptions.splice(index, 1);
                setRemovedPopularOptions(updatedRemovedOptions);
              };
            })
          };
          const tagsOnchangeHandler = (tags: Array<string>) => {
            const isNewTag = values.socialSkills.length < tags.length;
            if (isNewTag) {
              const newTag = tags[tags.length - 1];
              const isTagAlreadyExist = values.socialSkills?.find(item => item.toLocaleLowerCase() === newTag.toLocaleLowerCase());
              if (isTagAlreadyExist) return;
              addTagsList(tags);
            } else {
              addTagsList(tags);
            };

          };
          return <form onSubmit={handleSubmit}>
            <FieldWrap>
              <Field
                labelText={<LabelWrap>Type any skill and hit enter to add<span>Up to 50</span></LabelWrap>}
                as={Tags}
                tags={values.socialSkills}
                name={'socialSkills'}
                m='0' // TODO: need to refactor Tags component
                setTegs={tagsOnchangeHandler}
                error={!!errors.socialSkills}
              />
              <ErrorMsg>{errors.socialSkills}</ErrorMsg>
            </FieldWrap>
            {!!popularOptions.length && <PopularOptionsLabel>
              Popular options
            </PopularOptionsLabel>}
            {renderPopularOptions((value: string) => setFieldValue('socialSkills', [...values.socialSkills, value]), values.socialSkills)}
            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}
          </form>
        }}
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
})