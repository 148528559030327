import * as React from "react"
import { SPBaseButtonProps } from "../SPBaseButton/SPBaseButton.props"
import { LightButton } from "./SPLightButton.presets"

export const SPLightButton: React.FC<SPBaseButtonProps> = (props) => {
  const clickHandler = (event: any) => {
    props.disablePropagation && event.stopPropagation()
    props.onClick && props.onClick()
  }

  return(
    <LightButton
      data-id={props.testID}
      type={props.type}
      onClick={clickHandler}
      onKeyDown={e => props.disablePropagation && e.stopPropagation()}
      width={props.width}
      tabIndex={props.tabIndex || 0}
      aria-label={props.label}
      disabled={props.disabled}>
        {props.children}
    </LightButton>
  )
}
