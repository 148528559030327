import { useState } from "react";
import { Wrapper, BurgerMenu, MenuLine, FeedbackBtn, LogoWrap, OrganizationBtns, BtnContainer } from "./MobileAdminHeader.presents";
import { ReactComponent as Logo } from '../../../../../assets/img/logo.svg';
import { ReactComponent as FeedbackIcon } from '../../../../../assets/img/Chat.svg';
import { MobileSidebar } from "../../../Sidebars/AdminSidebar/Mobile/MobileSidebar";
import { SPSecondaryButton } from "../../../buttons/SPSecondaryButton/SPSecondaryButton";

export const MobileAdminHeader = (props: { isOrganizationPage?: boolean, saveOrganizationChanges?: Function, isSaveBtnDisable?: boolean }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const saveChangesHandler = () =>{
    props.saveOrganizationChanges && props.saveOrganizationChanges();
  }

  return <Wrapper>
    <BurgerMenu isOpen={isMenuOpen} onClick={() => setMenuOpen(!isMenuOpen)}>
      <MenuLine />
      <MenuLine />
      <MenuLine />
    </BurgerMenu>
    <LogoWrap to={'/my-jobs'}>
      <Logo />
    </LogoWrap>
    {props.isOrganizationPage ? <OrganizationBtns>
      <BtnContainer>
        <SPSecondaryButton disabled={props.isSaveBtnDisable} onClick={saveChangesHandler} width={'100%'}>
          Save Changes
        </SPSecondaryButton>
      </BtnContainer>
    </OrganizationBtns> :
      <div>
        <FeedbackBtn to={'/support'}>
          <FeedbackIcon />
          Send feedback
        </FeedbackBtn>
      </div>
    }
    <MobileSidebar isOpen={isMenuOpen} closeMenu={() => setMenuOpen(false)} />
  </Wrapper>
}
