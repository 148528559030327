import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { Header } from "../../BaseComponents/Header/Header";
import {
  HeaderWrap,
  Wrapper,
  HeaderBlock,
  Title,
  Subtitle,
  ButtonWrap,
  ContentBlock,
  ListBlock,
  ListItem,
  ItemTitle,
  ItemDescription,
  ItemContent,
  Separator,
  ItemNumber,
  ButtonGroup
} from "./WelcomeStep.presents";
import { ReactComponent as ShareVideoIcon } from "../../../../../../assets/img/shareVideoIconMax.svg";
import { ReactComponent as GetHiredIcon } from "../../../../../../assets/img/fileMax.svg";
import { ReactComponent as CreateResumeIcon } from "../../../../../../assets/img/playOnDesktopMax.svg";
import { useHistory } from "react-router-dom";
import useGetDevice from "../../../../../../hooks/useGetDevice";
import { CandidateHeader } from "../../../../../CandidateAdminPanel/BaseComponents/Header/Desktop/Header";
import { MobileCandidateHeader } from "../../../../../CandidateAdminPanel/BaseComponents/Header/Mobile/MobileAdminHeader";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import { SPBaseButton } from "../../../../../common/buttons/SPBaseButton";
import { useState } from "react";

export const WelcomeStep = () => {
  const history = useHistory();
  const { candidateStore } = useRootStore();
  const profileId = localStorage.getItem('profileId');

  const [isLoading, setIsLoading] = useState(false);

  if (candidateStore.hideWelcomePage){
    history.push('/candidate/resume-builder');
  }

  const nextStepHandler = async () => {
    setIsLoading(true);
    await candidateStore.redirectFromWelcomePage();
    history.push('/candidate/videoCV');
    setIsLoading(false);
  }

  const skipStepHandler = () => {
    history.push(`/profile/${profileId}`);
    localStorage.removeItem('profileId');
  }


  const isDesktop = useGetDevice();
  return <Wrapper>
    <HeaderBlock>
        {isDesktop ? <CandidateHeader isLogoHide={false} /> : <MobileCandidateHeader />}
    </HeaderBlock>
    <ContentBlock>
      <div>
      <Title>
        Excellent!<br />
        Now you can start creating<br />
        your video resume
      </Title>
      <ButtonGroup>
        <ButtonWrap>
          <SPPrimaryButton isLoading={isLoading} width="100%" onClick={nextStepHandler}>
            Get Started
          </SPPrimaryButton>
        </ButtonWrap>
        {profileId && <ButtonWrap rightBtn>
          <SPBaseButton width="100%" onClick={skipStepHandler}>
            Skip for now
          </SPBaseButton>
        </ButtonWrap>}
      </ButtonGroup>
      </div>
      <div>
      <Subtitle>
        Thousands of professionals have used these 3 tips on SidePost to create a killer resume to get hired quickly
      </Subtitle>
      <ListBlock>

        <ListItem>
          <div>
          <CreateResumeIcon />
          </div>
          <ItemContent>
            <ItemNumber>01</ItemNumber>
            <ItemTitle>Add a video to stand out</ItemTitle>
            <ItemDescription>Use your phone or laptop to create a short 60 second video about your experinece and qualifications.</ItemDescription>
          </ItemContent>
        </ListItem>

        <Separator />

        <ListItem>
          <div>
          <GetHiredIcon />
          </div>
          <ItemContent>
            <ItemNumber>02</ItemNumber>
            <ItemTitle>Add skills, education and work experience</ItemTitle>
            <ItemDescription>Recruiters want to know your work experience, qualifications and certifications.</ItemDescription>
          </ItemContent>
        </ListItem>

        <Separator />

        <ListItem>
          <div>
          <ShareVideoIcon />
          </div>
          <ItemContent>
            <ItemNumber>03</ItemNumber>
            <ItemTitle>Share your resume on LinkedIn and Twitter proactively</ItemTitle>
            <ItemDescription>Leverage the power of social media such as LinkedIn, Twitter and Facebook to get noticed and hired quickly. </ItemDescription>
          </ItemContent>
        </ListItem>
      </ListBlock>
      </div>
    </ContentBlock>
  </Wrapper >
}
