import { Field, Formik } from "formik";
import { Form } from 'react-bootstrap';
import { useState } from "react";
import { ModalWindow } from "../../../common/Modal";
import { ReportModalPropsType } from "./Modals.props";
import { Input } from "../../../common/Input";
import { SelectCom } from "../../../common/Select";
import { StyleForReportModalWrap, Separator, FieldsWrap, ErrorText } from "./Modals.presents";
import { Button } from "../../../common/Button";
import { sendAbuseMessage } from "../../../../services/api/crm/crm.domains";
import { useHistory } from "react-router";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

const abuseOptions = [
  { label: 'Inappropriate video or voice content', value: 'Inappropriate video or voice content' },
  { label: 'Inappropriate photo', value: 'Inappropriate photo' },
  { label: 'Inappropriate text', value: 'Inappropriate text' },
  { label: 'Offensive and misleading information', value: 'Offensive and misleading information' },
  { label: 'Employer profile information and logo', value: 'Employer profile information and logo' },
  { label: 'Illegal use of copyrighted or protected content', value: 'Illegal use of copyrighted or protected content' },
  { label: 'Fake job post or employer', value: 'Fake job post or employer' },
  { label: 'Other', value: 'Other' }
]

export const ReportModal = (props: ReportModalPropsType) => {
  const history = useHistory();
  const { t } = useTranslation();
  function useQuery() {
    return new URLSearchParams(history.location.search);
  }
  const query = useQuery();
  const [isSubmitted, setSubmitted] = useState(false);
  const initialValues = { email: '', abuseText: '', abuseType: '' };

  const validateFields = (values: typeof initialValues) => {
    const errors: any = {};
    const isEmpty = !values.abuseText && !values.abuseType;
    const errorMessage = 'Please fill in one of the fields';
    if (isEmpty) {
      errors.abuseText = errorMessage;
      errors.abuseType = errorMessage;
    }
    return errors
  }

  return <ModalWindow title={'Report abuse'} openWindow={props.openReportWindow} closeWindow={() => props.setOpenReportWindow(false)}>
    <StyleForReportModalWrap>
      <Formik
        initialValues={initialValues}
        validateOnChange={isSubmitted}
        validate={validateFields}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(t('validation.email')),
        })}
        onSubmit={async (values) => {
          const jobId = query.get("id");
          const serverResponse = await sendAbuseMessage({ jobId: jobId, typeOfAbuse: values.abuseType, email: values.email, message: values.abuseText });
          if (!serverResponse.data.error) {
            props.setOpenReportWindow(false);
          }
        }}>
        {({
          values,
          errors,
          handleSubmit,
          handleChange
        }) => (
          <Form onSubmit={handleSubmit} className='d-flex flex-column'>
            <FieldsWrap>
              <Field
                as={Input}
                name={'email'}
                onChange={handleChange}
                value={values.email}
                placeholder='Enter your email'
                labelText={<div className='d-flex justify-content-between'>
                  <span>Your email</span>
                  <span className='optional'>Optional</span>
                </div>}
                error={errors.email}
              />
              <ErrorText>{errors.email}</ErrorText>
              <Field
                component={SelectCom}
                options={abuseOptions}
                name={'abuseType'}
                placeholder='Choose the reason'
                labelText={'Why are you reporting this job story?'}
                error={errors.abuseType}
              />
              <ErrorText>{errors.abuseType}</ErrorText>
              <Separator>Or</Separator>
              <Field
                as={Input}
                name={'abuseText'}
                type={'textarea'}
                onChange={handleChange}
                maxLength={1000}
                value={values.abuseText}
                placeholder='Your message'
                labelText={'Enter your message'}
                error={errors.abuseText}
              />
              <ErrorText>{errors.abuseText}</ErrorText>
            </FieldsWrap>
            <Button buttonText={'Report'} type='submit' onClick={setSubmitted(true)} />
          </Form>
        )}
      </Formik>
    </StyleForReportModalWrap>
  </ModalWindow >
}