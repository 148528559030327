import { theme } from './../../../../theme/theme';
import { colors } from './../../../../constants/colors';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';

export const SkillsSubtitle = styled('p')`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${colors.dark};
  margin-bottom: 8px;
  @media(max-width: ${theme.screens.desktop}) {
    line-height: ${theme.offsets.xl};
    font-size: ${theme.offsets.lg};
    margin-bottom: 4px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.offsets.lg}; 
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px; 
  }
`

export const SkillItem = styled(Col) < { color: string }> `
  display: block;
  padding: 16px 24px;
  background: ${(props) => props.color};
  border-radius: 12px;
  margin: 8px;
  font-size: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    padding: ${theme.offsets.md} ${theme.offsets.lg};
    border-radius: ${theme.variables.borderRadiusSmall};
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 10px ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.md}; 
    padding: ${theme.offsets.sm} ${theme.offsets.md};
    border-radius: 6px;
    margin: 6px;
  }
`

export const SkillsRow = styled(Row)`
  margin-left: -23px!important;
  margin-right: -23px!important;
  @media(max-width: ${theme.screens.mobile}) {
    margin-left: -21px!important;
    margin-right: -21px!important;
  }
`

export const SkillsSeparator = styled('hr')`
  background: #ECECEC;
  height: 1px;
  border-top: none;
  margin-top: ${theme.offsets.xxl};
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: ${theme.offsets.lg};
    margin-top: ${theme.offsets.lg};
  }
`

export const EducationList = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`

export const ResponsibilitiesList = styled('div')`
  display: flex;
  flex-direction: column;
`

export const ResponsibilitiesItem = styled('span')`
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.dark};
  margin-top: 8px;
  display: flex;
  align-items: center;
  img{
    margin-right: 18px;
    @media(max-width: ${theme.screens.desktop}) {
      margin-right:${theme.offsets.md};
    }
  }
  @media(max-width: ${theme.screens.desktop}) {
    line-height:${theme.offsets.lg};
    font-size: 14px;
  }
`

export const EducationType = styled('p')`
  font-size: 18px;
  line-height: 24px;
  color: ${theme.colors.gray};
  margin: 8px 0;
  @media(max-width: ${theme.screens.desktop}) {
    line-height:${theme.offsets.lg};
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.md};
    margin: 4px 0;
  }
`
export const EducationContent = styled('p')`
  font-size: 18px;
  line-height: 24px;
  color: ${colors.dark};
  padding-bottom: ${theme.offsets.xs};
  display: flex;
  flex-wrap: wrap;
  @media(max-width: ${theme.screens.desktop}) {
    line-height:${theme.offsets.lg};
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.md};
  }
`

export const LanguageLevel = styled('span')`
  margin-right: ${theme.offsets.xs};
`

export const MainLanguage = styled('span')`
  color: ${theme.colors.gray};
`