import { PropsType } from './ThemeCustomizer.props';
import { Sidebar } from '../../../common/Sidebars/Sidebar';
import { ColorsWrap, ColorItem, ColorItemWrap, ColorTitle, ColorCircleWrap, ImagesWrap, ImageCardWrap, ImageCardTitle, TitleWrap, ImagesCardRow, ButtonWrap, SwitcherWrap } from './ThemeCustomizer.presents';
import { useEffect, useState } from 'react';
import { Button } from '../../../common/Button';
import { findJobTheme, jobStoryThemes } from './jobStoryThemes';
import { useRootStore } from '../../../../state/stores/storeContext';

export const ThemeCustomizer = (props: PropsType) => {
  const { jobStoryBuilder } = useRootStore();
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [themeIndex, setThemeIndex] = useState(0);
  const [activeThemeImgs, setActiveThemeImgs] = useState(jobStoryThemes[0].themeItems[0].id);
  const [disabledCustomizer, setDisabledCustomizer] = useState(false);

  const handlerColor = (colorIndex: number) => {
    if (!disabledCustomizer) {
      const themeId = jobStoryThemes[colorIndex].themeItems[themeIndex].id;
      setActiveColorIndex(colorIndex);
      changeTheme(themeId, themeIndex);
    }
  }

  const sendJobTheme = async () => {
    setDisabledCustomizer(true);
    const responseData = await jobStoryBuilder.changeTheme(props.jobId || '');
    if (!responseData.err) {
      setDisabledCustomizer(false);
    }
  }

  const changeTheme = async (themeId: string, index:number) => {
    setThemeIndex(index);
    jobStoryBuilder.setThemeForJob(themeId);
    setActiveThemeImgs(themeId);
  }

  useEffect(() => {
    if (jobStoryBuilder.themeId) {
      let colorIndex = findJobTheme(jobStoryBuilder.themeId);
      setActiveThemeImgs(jobStoryBuilder.themeId);
      setActiveColorIndex(colorIndex);
    }
  }, [jobStoryBuilder.themeId])



  return <Sidebar setCloseSidebar={props.handleClose} isOpen={props.show}>
    <SwitcherWrap>
      <TitleWrap>Colors</TitleWrap>
      <ColorsWrap>
        {jobStoryThemes.map((item, index) => <ColorItemWrap key={index + 'ColorItemWrap'}>
          <ColorCircleWrap isActive={index === activeColorIndex} disabledCustomizer={disabledCustomizer}>
            <ColorItem background={item.hex} onClick={() => handlerColor(index)}>
            </ColorItem>
          </ColorCircleWrap>
          <ColorTitle>{item.title}</ColorTitle>
        </ColorItemWrap>
        )}
      </ColorsWrap>
      <TitleWrap>Styles</TitleWrap>
      <ImagesWrap fluid>
        <ImagesCardRow>
          {jobStoryThemes[activeColorIndex].themeItems.map((item, index) => <ImageCardWrap
            disabledCustomizer={disabledCustomizer}
            isActive={activeThemeImgs === item.id}
            onClick={() => !disabledCustomizer && changeTheme(item.id, index)}
            key={index + 'ImageCardWrap'}
            md={6}>
            <div className='img-wrap'>
              <img src={item.images.themePreview} alt="img" />
            </div>
            <ImageCardTitle>{item.title}</ImageCardTitle>
          </ImageCardWrap>)}
        </ImagesCardRow>
      </ImagesWrap>
    </SwitcherWrap>
    <ButtonWrap>
      <Button disabled={disabledCustomizer} buttonText={disabledCustomizer ? 'Loading' : 'Save changes'} type='button' styled='warning' onClick={() => sendJobTheme()} />
    </ButtonWrap>
  </Sidebar>
}