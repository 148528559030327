import { SelectWrap, SelectStyle } from './SPPrimarySelect.presents'
import Select, { components } from 'react-select'
import { FieldProps } from 'formik'
import multiRemoveBtn from '../../../../assets/img/multiRemoveBtn.svg'
import { MultiSelectProps, Option } from './SPPrimarySelect.props'

export const SPPrimarySelect: React.SFC<MultiSelectProps & FieldProps> = ({
  options,
  field,
  form,
  isPanel,
  ...props
}) => {

  const MultiValueRemove = (prop: any) => {
    return <components.MultiValueRemove {...prop}>
      <img src={multiRemoveBtn} alt="X" />
    </components.MultiValueRemove>
  };

  const onChange = (option: any) => {
    form.setFieldValue(
      field.name,
      (option as Option[]).map((item: Option) => item.value));
  };

  const getValue = () => {
    if (options && field.value) {
      const multiSelectValue = options.filter(option => field.value.indexOf(option.value) >= 0)
      return multiSelectValue
    } else {
      return [];
    }
  };
  return <SelectWrap {...props}>
    <SelectStyle disabled={props.disabled} isPanel={isPanel} >
      <Select
        isDisabled={props.disabled}
        components={{ MultiValueRemove }}
        name={field.name}
        isClearable
        isMulti
        defaultValue={props.defaultValue ? props.defaultValue : ''}
        options={options}
        value={getValue()}
        onChange={onChange}
        classNamePrefix="cl-select"
        hideSelectedOptions={false}
        placeholder={props.placeholder || 'Select your option...'}
        isOptionDisabled={() => getValue().length >= 10}
      />
    </SelectStyle>
  </SelectWrap>

}