import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { theme } from '../../../../theme';

const AVATAR_SIZE = "52px"
export const Avatar = styled('img')`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border-radius: 50%;
  object-fit: cover;
  background: rgba(209, 215, 227, 0.2);
  border: 1px solid ${theme.colors.lightGray};
`

export const StyleForHeader = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.colors.white};
  margin: ${theme.offsets.md} ${theme.offsets.huge};
  position: relative;
  ${props => !props.isDesktop && `
    margin: 0;
    padding: ${theme.offsets.sm} ${theme.offsets.xxl};
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.sm} ${theme.offsets.lg};
    }
  `}
`

export const StyleForUserWrap = styled('button')`
  position: relative;
  margin-left: ${theme.offsets.xxl};
  cursor: pointer;
  user-select: none;
  border: none;
  background: none;
`

export const StyleForLogo = styled('div')<{isAdminView?: boolean, isDesktop: boolean}>`
  display: flex;
  padding-top: 0px;

  ${(props)=>props.isAdminView && `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  ${(props)=>!props.isDesktop && `
    
    svg{
      max-width: 100px;
      height: 36px;
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin-left: 0;
      svg{
        max-width: 80px;
        height: 28px;
      }
    }
  `}

   ${(props)=>(props.isAdminView && !props.isDesktop) && `
   @media(max-width: ${theme.screens.mobile}) {
    display: none;
   }
   `}
`

export const BtnsWrap = styled('div')<{isShow: boolean}>`
  display: ${(props)=>props.isShow ? 'flex' : 'none' };
  justify-content: center;
  align-items: center;
`

export const BtnContainer = styled('div')<{isDesktop?: boolean}>`
  width: 190px;
  svg{
    margin-right: ${theme.offsets.xs};
    height: 24px;
    width: 24px;
    margin-bottom: 3px;
  }
  ${(props)=>!props.isDesktop && `
    width: 116px;
    @media(max-width: ${theme.screens.mobile}) {
      width: 58px;
    }
  `}
`


export const AvatarWrap = styled('div')`
  display: flex;
  align-items: center;
`

export const UserName = styled('span')`
  margin-left: ${theme.offsets.lg};;
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  white-space: nowrap;
`

export const ArrowIcon = styled('img')<{isOpen:boolean}>`
  margin-left: ${theme.offsets.md};
  ${(props)=>props.isOpen && `
    transform: rotate(180deg);
  `}
`

export const OrganizationBtns = styled('div')`
  display: flex;
  align-items: center;
`

export const HelperText = styled('span')`
  font-size: ${theme.fontSizes.lg};
  color: rgba(63, 71, 79, 0.8);
  margin-left: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
    max-width: 40%;
    margin-left: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 50%;
  }
`

export const PreviewBtnContainer = styled(BtnContainer)`
  width: fit-content;
  button:disabled{
    border: none;
    opacity: 0.5;
  }
  @media(max-width: ${theme.screens.mobile}) {
    button{
      font-size: 0
    }
  }
`

export const HelperBlockWrap = styled('div')`
  display: flex;
  align-items: center;
`