import { Avatar } from "../../../../common/Avatar";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import avatarIcon from '../../../../../assets/img/avatar.svg';
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import {
  ContentWrap,
  MultiFieldWrap,
  MultiField,
  FieldLabel,
  PhoneInputWrap,
  AdditionalButtons,
  SaveButtonWrap,
  HelperTextTitle
} from "../../ResumeBuilder.presents";
import { Field, Formik } from "formik";
import { Input } from "../../../../common/Input";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { useEffect, useMemo, useState } from "react";
import { LocationBlock } from "./LocationBlock";
import { DemographicalBlock } from "./DemographicalBlock";
import { InterestsBlock } from "./InterestsBlock";
import { AboutCandidateBlock } from "./AboutCandidateBlock";
import { AvatarWrapper } from "./PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { HelperTextTooltipWrapper } from "../../../../common/HelperText/HelperTextTooltipWrapper";

export const PersonalInfo = observer(({
  isDesktop,
  logoUrl,
  uploadUserLogo,
  removeUserLogo,
  componentRef
}: {
  isDesktop: boolean;
  logoUrl: string;
  uploadUserLogo: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeUserLogo: Function;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const { t } = useTranslation();

  const phoneNumberValue = resumeBuilderStore.personalInfo.phone;

  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValue);
  const [phoneFieldClass, setPhoneFieldClass] = useState('');

  useEffect(() => {
    const isLocationDataExist =
      !!resumeBuilderStore.location.city ||
      !!resumeBuilderStore.location.country ||
      !!resumeBuilderStore.location.state ||
      !!resumeBuilderStore.location.zipCode;

    const isDemographicalDataExist =
      !!resumeBuilderStore.demographicalInfo.ethnicity ||
      !!resumeBuilderStore.demographicalInfo.gender ||
      !!resumeBuilderStore.demographicalInfo.pronouns;

    const isAboutCandidateDataExist = !!resumeBuilderStore.aboutCandidate.length;

    const isInterestsDataExist = !!resumeBuilderStore.interests;

    setLocationOpen(isLocationDataExist);
    setDemographicalOpen(isDemographicalDataExist);
    setAboutCandidateOpen(isAboutCandidateDataExist);
    setInterestsOpen(isInterestsDataExist);

  }, [resumeBuilderStore.isDataUpdated])

  const [isLocationOpen, setLocationOpen] = useState(false);
  const [isDemographicalOpen, setDemographicalOpen] = useState(false);
  const [isInterestsOpen, setInterestsOpen] = useState(false);
  const [isAboutCandidateOpen, setAboutCandidateOpen] = useState(false);
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const renderTitle = useMemo(() => <HelperTextTitle>
    <span>Summary</span>
    <HelperTextTooltipWrapper left={!isDesktop} text={"A resume summary is a brief statement at the top of your online resume. Show the employers, at a glance, why you're qualiﬁed for the jobs. Leverage your experience, background, or education."} />
  </HelperTextTitle>, [])



  const initialValues = { ...resumeBuilderStore.personalInfo };
  return <>
    <CandidateFormCard isDesktop={isDesktop} title={'Personal Information'} componentRef={componentRef}>
      <ContentWrap>
        <AvatarWrapper>
          <Avatar
            url={logoUrl}
            placeholder={avatarIcon}
            onUpload={uploadUserLogo}
            onDelete={removeUserLogo}
            btnText={'profile picture'}
          />
        </AvatarWrapper>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnChange={isFirstSubmitComplete}
          validateOnBlur={false}
          validate={values => {
            const errors: any = {}
            if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
              setPhoneFieldClass('error')
              errors.phone = t('validation.phone');
            } else {
              setPhoneFieldClass('')
            }
            return errors;
          }}
          validationSchema={
            Yup.object().shape({
              email: Yup.string().email(t('validation.email')),
              summary: Yup.string()
                .min(50, t('validation.min', { characters: 50 }))
                .max(1000, t('validation.max', { characters: 1000 }))
            })
          }
          onSubmit={async values => {
            setIsLoading(true);
            const result = await resumeBuilderStore.savePersonalInfo({ personalInfo: { ...values, phone: phoneNumber || "", profileEmail: candidateStore.email } }, candidateStore.uid);
            setIsLoading(false);
            if (!result.error) {
              setIsSaved(true);
              setTimeout(() => {
                setShowSaveBtn(false);
                setIsSaved(false);
              }, 3000);
            };
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit
          }) => {

            const onChangeHandler = (value: any) => {
              setShowSaveBtn(true);
              handleChange(value);
            };

            const onChangePhone = (value: any) => {
              setShowSaveBtn(true);
              setPhoneNumber(value);
            };

            return <form onSubmit={handleSubmit}>
              <FieldWrap>
                <Field
                  as={Input}
                  name={'jobTitle'}
                  type={'text'}
                  value={values.jobTitle}
                  onChange={onChangeHandler}
                  maxLength={50}
                  labelText={'Wanted Job Title Or Your Current Position'}
                  error={errors.jobTitle} />
                <ErrorMsg>{errors.jobTitle}</ErrorMsg>
              </FieldWrap>

              <MultiFieldWrap>
                <MultiField>
                  <FieldWrap>
                    <Field
                      as={Input}
                      name={'firstName'}
                      type={'text'}
                      value={values.firstName}
                      onChange={onChangeHandler}
                      maxLength={50}
                      labelText={'First Name'}
                      error={errors.firstName} />
                    <ErrorMsg>{errors.firstName}</ErrorMsg>
                  </FieldWrap>
                </MultiField>

                <MultiField>
                  <FieldWrap>
                    <Field
                      as={Input}
                      name={'lastName'}
                      type={'text'}
                      value={values.lastName}
                      onChange={onChangeHandler}
                      maxLength={50}
                      labelText={'Last Name'}
                      error={errors.lastName} />
                    <ErrorMsg>{errors.lastName}</ErrorMsg>
                  </FieldWrap>
                </MultiField>

              </MultiFieldWrap>

              <FieldWrap>
                <Field
                  as={Input}
                  name={'email'}
                  type={'text'}
                  value={values.email}
                  onChange={onChangeHandler}
                  maxLength={50}
                  labelText={'Email'}
                  error={errors.email} />
                <ErrorMsg>{errors.email}</ErrorMsg>
              </FieldWrap>

              <FieldWrap>
                <FieldLabel htmlFor="phone">Phone number</FieldLabel>
                <Field
                  component={PhoneInputWrap}
                  className={phoneFieldClass}
                  name={'phone'}
                  value={phoneNumber}
                  onChange={onChangePhone}
                  international
                  isDesktop={isDesktop}
                />
                <ErrorMsg>{errors.phone}</ErrorMsg>
              </FieldWrap>

              <FieldWrap>
                <Field
                  as={Input}
                  name={'summary'}
                  type={'textarea'}
                  value={values.summary}
                  onChange={onChangeHandler}
                  maxLength={1000}
                  labelText={renderTitle}
                  placeholder={"Write a summary about yourself. Min 50, max 1000 characters."}
                  error={errors.summary} />
                <ErrorMsg>{errors.summary}</ErrorMsg>
              </FieldWrap>
              {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
                <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                  {isLoading && <Spinner />}
                  {isSaved && <div>
                    Saved <CheckedGrey />
                  </div>}
                  {(!isLoading && !isSaved) && 'Save'}
                </SPSecondaryButton>
              </SaveButtonWrap>}
            </form>
          }}
        </Formik>

        <AdditionalButtons>
          {!isLocationOpen && <SPSecondaryButton
            onClick={() => setLocationOpen(true)}
            isLight width="fit-content"
            type="button">
            Add Location
          </SPSecondaryButton>}

          {!isDemographicalOpen && <SPSecondaryButton
            onClick={() => setDemographicalOpen(true)}
            isLight
            width="fit-content"
            type="button">
            Add Demographical Information
          </SPSecondaryButton>}

          {!isInterestsOpen && <SPSecondaryButton
            onClick={() => setInterestsOpen(true)}
            isLight
            width="fit-content"
            type="button">
            Add Interests
          </SPSecondaryButton>}

          {!isAboutCandidateOpen && <SPSecondaryButton
            onClick={() => setAboutCandidateOpen(true)}
            isLight
            width="fit-content"
            type="button">
            Add More About You
          </SPSecondaryButton>}

        </AdditionalButtons>

      </ContentWrap>
    </CandidateFormCard>

    {isLocationOpen && <LocationBlock isDesktop={isDesktop} setLocationOpen={setLocationOpen} />}

    {isDemographicalOpen && <DemographicalBlock isDesktop={isDesktop} setDemographicalOpen={setDemographicalOpen} />}

    {isInterestsOpen && <InterestsBlock isDesktop={isDesktop} setInterestsOpen={setInterestsOpen} />}

    {isAboutCandidateOpen && <AboutCandidateBlock isDesktop={isDesktop} setAboutCandidateOpen={setAboutCandidateOpen} />}


  </>
})