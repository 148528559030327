import 'react-app-polyfill/ie11';
import { Field, Formik } from 'formik';
import { useState } from "react";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { passwordValidation } from '../../../../services/validationSchemas';
import { Input } from '../../../common/Input';
import { SPPrimaryButton } from '../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { ButtonWrap, ErrorText, Title, Wrapper, FormWrap, LabelText } from './ForgotPassword.presents';
import { useHistory } from 'react-router-dom';
import { onPasswordReset } from '../../../../services/firebase/auth';
import { ReactComponent as Spinner } from "../../../../assets/img/simpleSpinner.svg";

export const RestorePassword = () => {
  const [serverError, setServerError] = useState('')
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode")
  const actionCode = params.get("oobCode")
  const apiKey = params.get("apiKey")
  const history = useHistory()
 

  if (mode !="resetPassword" || !actionCode || !apiKey){
    history.push('/candidate/forgot-password')
  }

  const setNewPassword = async (password: string) =>{
    if (actionCode){
      const err = await onPasswordReset(actionCode,password)
      if (err){
        setServerError(err)
      }
      else {
        history.push({
          pathname: "/candidate/sign-in",
          state: { helperText: "Your password was successfully updated!"}
        });
      }
    }
  } 

  return <Wrapper>
    <Title>
      {t('forgotPassword.restoreStepTitle')}
    </Title>
    <FormWrap>
      <Formik
        validateOnChange={firstSubmitComplete}
        validateOnBlur={false}
        initialValues={{ password: '', repeatedPassword: '' }}
        validationSchema={Yup.object().shape({
          password: passwordValidation(t),
          repeatedPassword: Yup.string().required()
            .oneOf([Yup.ref("password"), null], t('validation.mustMatch'))
        })}
        onSubmit={async (
          values
        ) => {
          setIsLoading(true);
          setNewPassword(values.password)
          setIsLoading(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field
              as={Input}
              labelText={<LabelText>
                <p>{t('label.password')}</p>
                <span >{t('label.leastCharacters')}</span>
              </LabelText>}
              type={'password'}
              name={'password'}
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
            />
            <ErrorText>{errors.password}</ErrorText>
            <Field
              as={Input}
              labelText={t('label.repeatPassword')}
              type={'password'}
              name={'repeatedPassword'}
              value={values.repeatedPassword}
              onChange={handleChange}
              error={!!errors.repeatedPassword}
            />
            <ErrorText>{errors.repeatedPassword}</ErrorText>
            <ButtonWrap isLoading={isLoading}>
              <SPPrimaryButton
                width='100%'
                type='submit'
                disabled={isLoading}
                onClick={() => setFirstSubmitComplete(true)}>
                {isLoading ? <Spinner /> : t('forgotPassword.createPassword')}
              </SPPrimaryButton>
            </ButtonWrap>
          </form>
        )}
      </Formik>
    </FormWrap>
    <ErrorText>{serverError}</ErrorText>
  </Wrapper>
}