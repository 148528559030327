export const isHaveJobOptionList = [
  {
    value: "Yes, currently employed",
    label: "Yes, currently employed"
  },
  {
    value: "No, currently unemployed",
    label: "No, currently unemployed"
  },
  {
    value: "Just graduated from college and looking",
    label: "Just graduated from college and looking"
  },
  {
    value: "Just a starter, never had a job before ",
    label: "Just a starter, never had a job before "
  }
]