import { StyleForWrap } from '../Steps.presents'
import { Container, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { NavBtn } from '../../BasicComponents/NavBtn'
import { useRootStore } from '../../../../state/stores/storeContext'
import { PropsType } from './JobStep.props'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import { SelectComponent } from './JobStep.scheme'
import { StyleForQuestion, StyleForSubtitle, StyleForTitle } from '../Steps.presents'
import { Row } from 'react-bootstrap'
import { HelperText } from '../../../common/HelperText'
import { Text } from '../../../common/Text'
import * as Yup from 'yup';
import { checkIsJobExist } from '../../../../pages/JobStoryBuilder'
import { handleSubmit, validation } from './JobStep.helper'
import { onKeyDown } from '../Start/StartStep.helper'
import { FocusError } from '../../BasicComponents/FocusError/FocusError'
import * as _ from 'lodash';


export const JobStep = observer(({ jobId, setActiveStep, setDoneStep, saveAnswers, ...props }: PropsType) => {
  const { jobStoryBuilder } = useRootStore()
  const history = useHistory();

  const [openMixedShift, setOpenMixedShift] = useState(false)
  const [openTravelingBlock, setOpenTravelingBlock] = useState(false)
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false)
  const [isSaveBtnClicked, setSaveBtnClicked] = useState(false)


  useEffect(() => {
    const steps = { active: 1, done: 0 }
    jobId && checkIsJobExist({ jobStoryBuilder, jobId, history, setActiveStep, setDoneStep, steps })
  }, [jobId, jobStoryBuilder, history, setActiveStep, setDoneStep])

  useEffect(() => {
    setOpenMixedShift(jobStoryBuilder.answers.jobStep.workShift === 'mixed' ? true : false)
    setOpenTravelingBlock(jobStoryBuilder.answers.jobStep.traveling && jobStoryBuilder.answers.jobStep.traveling !== 'no' ? true : false)
  }, [jobStoryBuilder.answers.jobStep.workShift, jobStoryBuilder.answers.jobStep.traveling])

  const initialValues: any = { mixshiftData: jobStoryBuilder.answers.jobStep['mixshiftData'], canDoneOver: jobStoryBuilder.answers.jobStep['canDoneOver'] }
  let openValidateItems: any = {}
  const updatingUrl = `/job-story/description`;

  jobStoryBuilder.steps.stepJob.forEach((item: any) => {
    openValidateItems[item.name] = !item.optional && (item.name !== 'traveling' && item.name !== 'hiringLocations') ? Yup.string().required('Required') : ''
    initialValues[item.name] = jobStoryBuilder.answers.jobStep[item.name]
    initialValues.acceptRemoteWorkersWorld = jobStoryBuilder.answers.jobStep.acceptRemoteWorkersWorld ? jobStoryBuilder.answers.jobStep.acceptRemoteWorkersWorld : []
  })

  const saveResult = async (values: any, validateForm: Function) => {
    setFirstSubmitComplete(true);
    setSaveBtnClicked(true);
    const errors = await validateForm();
    _.isEmpty(errors) && handleSubmit({ values, saveAnswers, updatingUrl: null });
    setSaveBtnClicked(false);
  }

  return <StyleForWrap>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      validate={validation}
      validationSchema={Yup.object().shape(openValidateItems)}
      onSubmit={values => handleSubmit({ values, saveAnswers, updatingUrl })}
    >
      {({
        values,
        errors,
        touched,
        validateForm,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <FocusError isSaveBtnClicked={isSaveBtnClicked} />
          <Form onKeyDown={onKeyDown} onSubmit={handleSubmit} className='d-flex flex-column'>
            <Container fluid className="my-auto px-0">
              {
                jobStoryBuilder.steps.stepJob.map((item: any, index: number) => {
                  const propsForScheme = {
                    type: item.componentType,
                    index,
                    item,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    values,
                    openedQuestions: jobStoryBuilder.steps.stepJob,
                    openMixedShift,
                    setOpenMixedShift,
                    openTravelingBlock,
                    setOpenTravelingBlock
                  }
                  return <Row key={index + 'question'} className="justify-content-center">
                    <StyleForQuestion className={errors[item.name] ? 'error' : ''}>
                      <StyleForSubtitle>
                        {`Question ${index + 1}/${jobStoryBuilder.steps.stepJob.length}`}{item.optional ? <span className='optional'>Optional</span> :
                          <Text fs={16} text={errors[item.name] && typeof errors[item.name] === 'string' ? errors[item.name] : ''} error />}
                      </StyleForSubtitle>
                      <StyleForTitle>{item.title}{item.helperText && <HelperText autoChangePosition text={item.helperText} />}</StyleForTitle>
                      {SelectComponent(propsForScheme)}
                    </StyleForQuestion>
                  </Row>
                })}
            </Container>
            <div className='d-flex justify-content-between'>
              <NavBtn callbackFunction={() => props.toPrevStep({ jobId, history, setActiveStep, setDoneStep, steps: { active: 0, done: -1 } })} />
              <div className='d-flex'>
                <NavBtn exit callbackFunction={() => saveResult(values, validateForm)} />
                <NavBtn next callbackFunction={() => setFirstSubmitComplete(true)} />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  </StyleForWrap>
})