import styled from "styled-components";
import { theme } from "../../../../theme";

export const Wrapper = styled('div')`
  max-width: 544px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 358px;
  }
`

export const Title = styled('p')`
  font-family: Recoleta;
  font-size: ${theme.fontSizes.major};
  font-weight: 500;
  margin-bottom: ${theme.offsets.major};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
    margin-bottom: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
    margin-bottom: ${theme.offsets.xxl};
  }
`

export const Separator = styled('div')`
  font-size: 18px;
  margin: ${theme.offsets.extraBig} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::before, ::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #D1D7E3;
    width: 45%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.xxl} 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} 0;
  }
`

export const ErrorText = styled('div')`
  height: 12px;
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 10px;
`

export const ButtonWrap = styled('div')`
  display: flex;
  height: 53px;
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    height: 44px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    height: 40px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 34px
  }
`

export const WrapperForNotification = styled('div')`
  margin-top: -${theme.offsets.extraMajor};
  margin-bottom: ${theme.offsets.extraMajor};
`