import {
  StyleForModal,
  StyleForWrap,
  BannerImage,
  MainInfo,
  CompanyLogo,
  ActionBtn,
  TitleBlock,
  JobPosition,
  KindOfJob,
  InfoList,
  ListItem,
  JobTrailer,
  GreetingText,
  OrgName,
  JobKinds
} from './Introduction.presents';
import fireIcon from '../../../../assets/img/jobStory/fire.svg';
import ringIcon from '../../../../assets/img/jobStory/ring.svg';
import { PropsType } from './Introduction.props';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { jobKindIcons } from './Introduction.icons';
import closeIcon from '../../../../assets/img/close.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { salaryBonuses, setLocationsListItem, setSalaryBonuses, setSalaryString } from './Introduction.helper';
import { LocationModal } from '../Modals/LocationModal';
import reportIcon from '../../../../assets/img/jobStory/reportIcon.svg';
import { ReportModal } from '../Modals/ReportModal';
import { LocationType } from '../Modals/Modals.props';



export const Introduction = observer(({ organizationName, jobData, companyLogo, bannersUrl }: PropsType) => {
  const { t } = useTranslation();

  const [openShiftWindow, setOpenShiftWindow] = useState(false);
  const [openMapsWindow, setOpenMapsWindow] = useState(false);
  const [openReportWindow, setOpenReportWindow] = useState(false);
  const [activeActionBtn, setActiveActionBtn] = useState(false);

  const handleCloseShiftWindow = () => setOpenShiftWindow(false);
  const handleCloseMapsWindow = () => setOpenMapsWindow(false);

  const showJobStartSection = jobData.jobStep.jobStart === 'hiring' || jobData.jobStep.jobStart === 'now';
  const isHiringLocation = jobData.jobStep.hiringLocations && jobData.startStep.doneRemotely !== 'no';
  const anywhereFromWorld = isHiringLocation && jobData.jobStep.acceptRemoteWorkersWorld && jobData.jobStep.acceptRemoteWorkersWorld.includes('anywhereFromWorld');

  const locationListDto = { 
    jobData, 
    setOpenMapsWindow, 
    isHiringLocation: true,
  }

  return <StyleForWrap>
    <BannerImage src={bannersUrl.mainBanner} alt='banner' />
    <MainInfo>
      {companyLogo.fileUrl && <CompanyLogo>
        <img src={companyLogo.fileUrl} alt="logo" />
      </CompanyLogo>}
      <ActionBtn
        className={`action-btn ${activeActionBtn && 'active'}`}
        onClick={() => setActiveActionBtn(true)}
        onBlur={() => setActiveActionBtn(false)}
      >
        <div className='icon'></div>
        <div className={`menu ${activeActionBtn && 'open'}`}>
          <span onClick={() => setOpenReportWindow(true)} className='menu-item'><img src={reportIcon} alt='icon' /> Report</span>
        </div>
      </ActionBtn>
      <div className='d-flex justify-content-between'>
        <GreetingText>
          <OrgName>{organizationName}</OrgName> is looking for a
        </GreetingText>
      </div>
      <TitleBlock>
        <JobPosition>{jobData.descriptionStep.jobTitle}</JobPosition>
        <JobKinds>
          <KindOfJob>
            <img src={jobKindIcons[jobData.startStep.jobKind]} alt="bag" />
            {t(`jobStory.jobKind.${jobData.startStep.jobKind}`) + ' Job'}
          </KindOfJob>
          {showJobStartSection && <KindOfJob type={jobData.jobStep.jobStart}>
            <img src={jobData.jobStep.jobStart === 'hiring' ? fireIcon : ringIcon} alt="icon" />
            {t(`jobStory.jobStart.${jobData.jobStep.jobStart}`)}
          </KindOfJob>}
        </JobKinds>
      </TitleBlock>
      <InfoList>
        {jobData.startStep.doneRemotely && jobData.startStep.doneRemotely !== 'no' && <ListItem withPoint>{t(`jobStory.doneRemotely.${jobData.startStep.doneRemotely}`)}</ListItem>}
        {setSalaryString(jobData.specificationStep)}
        {salaryBonuses.map(item => setSalaryBonuses(jobData.specificationStep, item.bonusName, item.text))}
        {jobData.specificationStep.equityFrom && setSalaryBonuses(jobData.specificationStep, 'equity', `${jobData.specificationStep.vestingSchedule ? 'stocks in ' + jobData.specificationStep.vestingSchedule : 'stocks'}`)}
        {jobData.jobStep.workShift === 'mixed' && jobData.jobStep.mixshiftData ? <ListItem withPoint isModal onClick={() => { setOpenShiftWindow(true) }}>
          {t(`jobStory.workShift.${jobData.jobStep.workShift}`)}
        </ListItem> :
          <ListItem withPoint>
            {t(`jobStory.workShift.${jobData.jobStep.workShift}`)}
          </ListItem>
        }
        {jobData.jobStep.otherShiftInf && <ListItem withPoint>{t(`jobStory.otherShiftInf.${jobData.jobStep.otherShiftInf}`)}</ListItem>}
        {jobData.jobStep.weeklyInfo && <ListItem withPoint>{t(`jobStory.weeklyInfo.${jobData.jobStep.weeklyInfo}`)}</ListItem>}
        {setLocationsListItem(locationListDto)}
      </InfoList>
      <JobTrailer>{jobData.descriptionStep.jobTrailer}</JobTrailer>
    </MainInfo>
    <StyleForModal show={openShiftWindow} onHide={handleCloseShiftWindow} centered className='pl-0'>
      <img className='close-btn' src={closeIcon} alt="close" onClick={handleCloseShiftWindow} />
      <p className='title'>Shifts</p>
      <div>
        {jobData.jobStep.mixshiftData && jobData.jobStep.mixshiftData.map((item: any) => {
          return item.from || item.to ? <div className='d-flex day-row'>
            <span className='day'>{item.day}</span>
            <div className='timing'>
              <span>{moment(new Date(`25 June 2011 ${item.from}`)).format('hh:mm a') || '-'}</span>
              <span className='text'>to</span>
              <span>{moment(new Date(`25 June 2011 ${item.to}`)).format('hh:mm a') || '-'}</span>
            </div>
          </div> : null
        })}
      </div>
    </StyleForModal >
    {!anywhereFromWorld && <LocationModal
      openMapsWindow={openMapsWindow}
      handleCloseMapsWindow={handleCloseMapsWindow}
      organizationName={organizationName}
      companyLogo={companyLogo}
      contactStep={jobData.contactStep}
      locations={isHiringLocation ?
        { locationType: LocationType.hiring, locationsData: jobData.jobStep.hiringLocations } :
        { locationType: LocationType.primary, locationsData: jobData.startStep.locations }
      }
    />}
    <ReportModal openReportWindow={openReportWindow} setOpenReportWindow={setOpenReportWindow} />
  </StyleForWrap>
})