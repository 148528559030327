import styled from "styled-components";
import { theme } from "../../../../../theme";

export const FieldWrap = styled('div')`
margin-top: ${theme.offsets.sm};
display: block;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.sm};
  }
`

export const ErrorMsg = styled('div')`
  color: ${theme.colors.red};
  margin-top: ${theme.offsets.xs};
  height: 12px;
  font-size: ${theme.fontSizes.md};
  display: block;
`

export const BtnsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  button{
    margin-right: ${theme.offsets.lg};
    margin-top: ${theme.offsets.lg};
    width: auto;
    padding-left: ${theme.offsets.lg};
    padding-right: ${theme.offsets.lg};
    @media(max-width: ${theme.screens.tablet}) {
      padding-left: ${theme.offsets.lg};
      padding-right: ${theme.offsets.lg};
    }
    svg{
      margin-left: ${theme.offsets.sm};
      @media(max-width: ${theme.screens.tablet}) {
        width: 16px;
        height: 16px;
      }
    }
  }
`

export const Label = styled('div')`
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.darkGray};
  margin-bottom: ${theme.offsets.sm};
  display: flex;
  align-items: center;
`

export const BannerWrap = styled('div')`
  margin: ${theme.offsets.extraBig} 0;
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.xxl} 0;
  }
`

export const PublicPreviewFieldWrap = styled('div')`
position: relative;
label{
  text-transform: none;
}
  input{
    padding-left: 210px;
    @media(max-width: ${theme.screens.tablet}) {
      padding-left: 185px; 
    }
  }
`

export const DefaultURLString = styled('span')`
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding-left: 14px;
  padding-top: 2px;
  color: ${theme.colors.gray};
  cursor: default;
  height: 52px;
  display: flex;
  align-items: center;
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 40px;
  }
`