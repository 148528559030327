import { ModalWindow } from "../../../common/Modal";
import { SaveModalProps } from "./Modals.props";
import closeIcon from '../../../../assets/img/close.svg';
import {
  CloseBtnBlock,
  ApplyForm,
  LogoWrap,
  Logo,
  OrganizationName,
  JobTitle,
  InputField,
  PolicyText,
  PolicyLink,
  ErrorText,
  PreloaderWrap,
  RedirectText,
  RedirectLink,
  ErrorMessage
} from "./Modals.presents";
import { Formik } from "formik";
import { Input } from "../../../common/Input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useRootStore } from "../../../../state/stores/storeContext";
import * as Yup from 'yup';
import { PrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton.presets";
import { Preloader } from "../../../common/Preloader";
import { SaveJob } from "../../../../services/api/jobStore/jobStore.domains";
import { useHistory } from "react-router-dom";


export const SaveModal = (props: SaveModalProps) => {
  const { t } = useTranslation();
  const { jobStoryBuilder } = useRootStore();
  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(history.location.search);
  }
  const query = useQuery();
  const jobId = query.get("id")

  const [isFirstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isErrorResult, setErrorResult] = useState(false);

  const jobData = jobStoryBuilder.answers;
  const logo = jobData.contactStep.directEmployerLogo.fileUrl;

  return <ModalWindow
    openWindow={props.isOpen}
    closeWindow={props.onClose}
    empty
    fullscreen>
    <CloseBtnBlock onClick={() => props.onClose()}>
      <img src={closeIcon} alt="close" />
      <span>Close</span>
    </CloseBtnBlock>
    <Formik
      initialValues={{ email: '' }}
      validateOnChange={isFirstSubmitComplete}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      })}
      onSubmit={async (values) => {
        setLoading(true);
        setFirstSubmitComplete(true);
        const dto = {
          email: values.email,
          jobId
        };
        const serverResponse = await SaveJob(dto);
        const isSuccessfulRequest = !serverResponse.data.error;
        if (isSuccessfulRequest) {
          props.onClose();
          setLoading(false);
        } else {
          setErrorResult(true);
          setTimeout(() => {
            setErrorResult(true);
            props.onClose();
            setLoading(false);
          }, 5000)
        }
      }
      }
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <Container fluid>
          <ApplyForm onSubmit={handleSubmit}>
            <LogoWrap>
              {logo && <Logo src={logo} alt="logo" />}
              <div>
                <JobTitle>{jobData.descriptionStep.jobTitle}</JobTitle>
                <OrganizationName>{jobData.contactStep.organizationName}</OrganizationName>
              </div>
            </LogoWrap>
            <InputField
              as={Input}
              name={'email'}
              type={'text'}
              value={values.email}
              onChange={handleChange}
              labelText={t('label.candidateEmail')}
              error={errors.email}
            />
            <ErrorText>{errors.email}</ErrorText>
            <PolicyText>
              By applying, I agree to Sidepost's <PolicyLink to={{ pathname: 'https://sidepost.com/terms-of-service' }} target="_blank">Terms of Service</PolicyLink> and <PolicyLink to={{ pathname: 'https://sidepost.com/privacy-policy' }} target="_blank">Privacy Policy</PolicyLink>
            </PolicyText>
            <PrimaryButton disabled={isLoading} width={'100%'} type={'submit'}>{isLoading ? <PreloaderWrap><Preloader /></PreloaderWrap> : 'Save For Later'}</PrimaryButton>
          </ApplyForm>
          {isErrorResult && <ErrorMessage>Something went wrong. Try again later</ErrorMessage>}
        </Container>
      )}
    </Formik>
  </ModalWindow>
}