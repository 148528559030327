import { benefitsRendering, socNetworksRendering, galleryRendering } from "./helper";
import { JobListSkeleton } from "./JobListSkeleton";
import {
  ContentWrap,
  CoverSection,
  Wrapper,
  TabSection,
  ContentCard,
  InfoBlock,
  PreviewBlock,
  Logo,
  Banner,
  LogoWrap,
  TextContent,
  OverviewContent,
  OverviewContentWrap,
  ShineSection,
  AudioContent,
  BenefitsList,
  SocNetworksList,
  GalleryList,
  SocNetworksCard
} from "./Skeleton.presents"

export const Skeleton = ({ isDesktop }: { isDesktop: boolean }) => {
  const TABS_ITEMS = new Array(4).fill('');
  const OVERVIEW_ITEMS = new Array(4).fill('');
  const DESCRIPTION_ITEMS = new Array(2).fill('');

  return <Wrapper>
    <ShineSection>
      <CoverSection >
        <Banner />
        <LogoWrap>
          <Logo />
          <TextContent width="70%" />
          <TextContent width="70%" />
        </LogoWrap>
      </CoverSection>
      <ContentWrap isDesktop={isDesktop}>

        <InfoBlock isDesktop={isDesktop}>

          {/* Tabs section */}
          <TabSection isDesktop={isDesktop} >
            {TABS_ITEMS.map((item, index) => <TextContent key={`tab${index}`} width="20%" />)}
          </TabSection>

          {/* Overview section */}
          <ContentCard isDesktop={isDesktop}>
            <TextContent width="20%" />
            <OverviewContentWrap>
              {OVERVIEW_ITEMS.map((item, index) => <OverviewContent key={`overview${index}`} isLastItem={index === OVERVIEW_ITEMS.length-1}>
                <TextContent width="15%" />
                <TextContent width="65%" />
                <TextContent width="100%" />
              </OverviewContent>)}
            </OverviewContentWrap>

            {DESCRIPTION_ITEMS.map((item, index) => <div key={`description${index}`}>
              <hr />
              <TextContent width="20%" />
              <TextContent width="100%" />
              <TextContent width="100%" />
            </div>)}
          </ContentCard>

          {/* Desktop Job List */}
          {isDesktop && <JobListSkeleton isDesktop={isDesktop} />}
        </InfoBlock>

        <PreviewBlock isDesktop={isDesktop} >

          {/* Audio Block */}
          <ContentCard isDesktop={isDesktop} >
            <TextContent width="20%" />
            <AudioContent />
          </ContentCard>

          {/* Benefits */}
          <ContentCard isDesktop={isDesktop}>
            <TextContent width="20%" />
            <BenefitsList>
              {benefitsRendering()}
            </BenefitsList>
          </ContentCard>

          {/* Social Networks */}
          <ContentCard isDesktop={isDesktop}>
            <TextContent width="20%" />
            <SocNetworksList>
              {socNetworksRendering()}
            </SocNetworksList>
          </ContentCard>

          {/* Gallery */}
          <ContentCard isDesktop={isDesktop}>
            <TextContent width="20%" />
            <GalleryList>
              {galleryRendering()}
            </GalleryList>
          </ContentCard>

          {/* Contacts */}
          <ContentCard isDesktop={isDesktop}>
            <TextContent width="20%" />
            <SocNetworksList>
              <SocNetworksCard />
              <div>
                <TextContent width="80px" />
                <TextContent width="120px" />
              </div>
            </SocNetworksList>
            <hr />
            <TextContent width="20%" />
            <TextContent width="30%" />
            <hr />
            <TextContent width="35%" />
            <TextContent width="15%" />
          </ContentCard>

        </PreviewBlock>
        {/* Mobile Job List */}
        {!isDesktop && <JobListSkeleton isDesktop={isDesktop} />}
      </ContentWrap>
    </ShineSection>
  </Wrapper>
}