import * as React from "react"
import { SPBaseButtonProps } from "../SPBaseButton/SPBaseButton.props"
import { CloseButton, CloseIcon, CloseText } from "./SPCloseButton.presets"
import closeIcon from '../../../../assets/img/close.svg';


export const SPCloseButton: React.FC<SPBaseButtonProps> = (props) => {
  const clickHandler = (event: any) => {
    props.disablePropagation && event.stopPropagation()
    props.onClick && props.onClick()
  }

  return(
    <CloseButton
      data-id={props.testID}
      type={props.type}
      onClick={clickHandler}
      onKeyDown={e => props.disablePropagation && e.stopPropagation()}
      width={props.width}
      tabIndex={props.tabIndex || 0}
      aria-label={props.label}
      disabled={props.disabled}>
        <CloseIcon className='close-btn' src={closeIcon} alt="close"/>
        <CloseText>Close</CloseText>
    </CloseButton>
  )
}
