import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Subtitle } from "../../Resume.presents";
import { RestructuredEmploymentData } from "./MainInformation";
import {
  AboutCandidateContainer,
  AboutCandidateText,
  AdditionalText,
  EmploymentDot,
  EmploymentItemWrap,
  EmploymentLine,
  EmploymentAdditionalItem,
  InformationContainer, DownloadFile
} from "./MainInformation.presents";
import { ReactComponent as EmploymentIcon } from '../../../../../assets/img/resume/employment.svg';
import { ReactComponent as JobLevelIcon } from '../../../../../assets/img/resume/preferences/jobLevel.svg';
import { ReactComponent as JobTravelingIcon } from '../../../../../assets/img/resume/preferences/jobTraveling.svg';
import { ReactComponent as JobTypeIcon } from '../../../../../assets/img/resume/preferences/jobType.svg';
import { ReactComponent as UrgencyIcon } from '../../../../../assets/img/resume/preferences/urgency.svg';
import { ReactComponent as WeeklyScheduleIcon } from '../../../../../assets/img/resume/preferences/weeklySchedule.svg';
import { ReactComponent as WorkHoursIcon } from '../../../../../assets/img/resume/preferences/workHours.svg';
import { ReactComponent as WorkplaceIcon } from '../../../../../assets/img/resume/preferences/workplace.svg';
import { CompetitivePay } from "./Icons/CompetitivePay";
import { Wellbeing } from "./Icons/Wellbeing";
import { Diversity } from "./Icons/Diversity";
import { Benefits } from "./Icons/Benefits";
import { WorkLifeBalance } from "./Icons/WorkLifeBalance";
import { ClearExpectations } from "./Icons/ClearExpectations";
import { Recognition } from "./Icons/Recognition";
import { ClearCommunication } from "./Icons/ClearCommunication";
import { Growth } from "./Icons/Growth";
import { Trust } from "./Icons/Trust";
import { Responsibility } from "./Icons/Responsibility";
import { Respect } from "./Icons/Respect";
import { PrideWork } from "./Icons/PrideWork";
import { LearningDevelopment } from "./Icons/LearningDevelopment";
import { Achievement } from "./Icons/Achievement";
import { Productivity } from "./Icons/Productivity";
import { EmployeeEngagement } from "./Icons/EmployeeEngagement";
import { Teamwork } from "./Icons/Teamwork";
import { CareerAdvancement } from "./Icons/CareerAdvancement";
import { HighEmployeeMorale } from "./Icons/HighEmployeeMorale";
import { LowEmployeeTurnover } from "./Icons/LowEmployeeTurnover";
import { Flexibility } from "./Icons/Flexibility";
import { WorkplaceCulture } from "./Icons/WorkplaceCulture";
import { MeaningfulWork } from "./Icons/MeaningfulWork";
import { OutcomeDrivenWork } from "./Icons/OutcomeDrivenWork";
import { ForwardWorkplace } from "./Icons/ForwardWorkplace";
import { Purpose } from "./Icons/Purpose";
import { EmployeeMotivation } from "./Icons/EmployeeMotivation";
import employmentTypesList from "../../../../../assets/staticData/employmentTypes.json";


export const MainHistorySection = ({
  icon,
  title,
  subtitle,
  startDate,
  endDate,
  description,
  isLastItem,
  isSingleDate,
  isFirstItem,
  file,
  fileDownloadLabel
}: {
  icon: ReactElement;
  title: string;
  subtitle?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  file?: any;
  fileDownloadLabel?: string;
  isLastItem: boolean;
  isSingleDate: boolean;
  isFirstItem: boolean;
}) => {
  const endDateValue = !!endDate ? moment(endDate).format("MMM YYYY") : 'Present';
  const dateString = `${moment(startDate).format("MMM YYYY")}${isSingleDate ? '' : ` - ${endDateValue}`}`
  return <AboutCandidateContainer isLastItem={isLastItem} isFirstItem={isFirstItem}>
    {icon}
    <InformationContainer>
      <Subtitle>
        {title}
      </Subtitle>

      {subtitle && <AdditionalText>
        {subtitle}
      </AdditionalText>}

      {!!startDate && <AdditionalText>
        {dateString}
      </AdditionalText>}

      {!!description && <AboutCandidateText>
        {description}
      </AboutCandidateText>}

      {!!file?.isUploaded && <DownloadFile target="_blank" download href={file.url}>
        View {fileDownloadLabel}
      </DownloadFile>}


    </InformationContainer>
  </AboutCandidateContainer>
};

export const EmploymentHistorySection = ({
  employmentItem,
  isLastItem,
  isFirstItem,
}: {
  employmentItem: RestructuredEmploymentData;
  isLastItem: boolean;
  isFirstItem: boolean;
}) => {
  const startPointRef = useRef<HTMLDivElement>(null);
  const endPointRef = useRef<HTMLDivElement>(null);

  const [lineHeight, setLineHeight] = useState("0px");


  useEffect(() => {
    if (endPointRef.current?.offsetTop && startPointRef.current?.offsetTop) {
      setLineHeight(`${endPointRef.current?.offsetTop - startPointRef.current?.offsetTop - 16}px`);
    }
  }, [endPointRef.current, endPointRef.current?.offsetTop])

  return <AboutCandidateContainer isFirstItem={isFirstItem} isLastItem={isLastItem}>
    <EmploymentIcon />
    <div style={{ position: "relative" }}>

      <EmploymentLine style={{
        height: lineHeight,
        top: startPointRef.current?.offsetTop ? `${startPointRef.current?.offsetTop + 8}px` : 0
      }} />

      <Subtitle>
        {employmentItem.name}
      </Subtitle>

      {employmentItem.data.map((item, index) => {
        const {
          description,
          startDate,
          endDate,
          position,
          employmentType
        } = item;
        const endDateValue = !!endDate ? moment(endDate).format("MMM YYYY") : 'Present';
        const dateString = `${moment(startDate).format("MMM YYYY")} - ${endDateValue}`;
        const type = employmentTypesList.find(item => item.value === employmentType)?.label || "";
        const subtitle = `${position}${type ? ", " + type : ""}`;

        const showStartPoint = employmentItem.data.length > 1 && index === 0;
        const showEndPoint = employmentItem.data.length > 1 && index === employmentItem.data.length - 1;

        return <EmploymentAdditionalItem
          isLastItem={index === employmentItem.data.length - 1}
          ref={showStartPoint ? startPointRef
            : showEndPoint ? endPointRef
              : null}
          key={`${employmentItem.name}${index}`}>
          <EmploymentItemWrap>

            {showStartPoint && <EmploymentDot />}
            {showEndPoint && <EmploymentDot />}


            <AdditionalText>
              {subtitle}
            </AdditionalText>
          </EmploymentItemWrap>

          {!!startDate && <AdditionalText>
            {dateString}
          </AdditionalText>}

          {!!description && <AboutCandidateText>
            {description}
          </AboutCandidateText>}
        </EmploymentAdditionalItem>
      })}
    </div>
  </AboutCandidateContainer>
};

export const preferencesIconMap = {
  jobLevel: <JobLevelIcon />,
  jobTraveling: <JobTravelingIcon />,
  jobType: <JobTypeIcon />,
  urgency: <UrgencyIcon />,
  weeklySchedule: <WeeklyScheduleIcon />,
  workHours: <WorkHoursIcon />,
  workplace: <WorkplaceIcon />,
};

export const ImportantThingsIcon = ({
  iconKey,
  color
}: {
  iconKey: string;
  color: string;
}) => {
  const iconMap = {
    'Competitive pay': <CompetitivePay color={color} />,
    'Wellbeing': <Wellbeing color={color} />,
    'Diversity': <Diversity color={color} />,
    'Benefits': <Benefits color={color} />,
    'Work-life balance': <WorkLifeBalance color={color} />,
    'Clear expectations': <ClearExpectations color={color} />,
    'Recognition': <Recognition color={color} />,
    'Clear communication': <ClearCommunication color={color} />,
    'Growth': <Growth color={color} />,
    'Trust': <Trust color={color} />,
    'Responsibility': <Responsibility color={color} />,
    'Respect': <Respect color={color} />,
    'Pride at work': <PrideWork color={color} />,
    'Learning and development ': <LearningDevelopment color={color} />,
    'Achievement': <Achievement color={color} />,
    'Productivity': <Productivity color={color} />,
    'Employee engagement ': <EmployeeEngagement color={color} />,
    'Teamwork': <Teamwork color={color} />,
    'Career advancement': <CareerAdvancement color={color} />,
    'High employee morale': <HighEmployeeMorale color={color} />,
    'Low employee turnover': <LowEmployeeTurnover color={color} />,
    'Flexibility': <Flexibility color={color} />,
    'Workplace culture': <WorkplaceCulture color={color} />,
    'Meaningful work': <MeaningfulWork color={color} />,
    'Outcome driven work': <OutcomeDrivenWork color={color} />,
    'Forward-thinking workplace': <ForwardWorkplace color={color} />,
    'Purpose': <Purpose color={color} />,
    'Employee motivation': <EmployeeMotivation color={color} />
  };

  return iconKey in iconMap ? iconMap[iconKey as keyof typeof iconMap] : iconMap.Benefits;
};