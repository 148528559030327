export const TemporaryIcon = ({ active }: { active?: boolean }) => {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 17.5H38.5L38 22L35 26.5L29 27.5L23 26.5L20 22L19.5 17.5Z" fill="#F8DDC3" />
    <rect x="19" y="8" width="20" height="9" fill="white" />
    <path d="M19 38C19 32.4772 23.4772 28 29 28C34.5228 28 39 32.4772 39 38V48H19V38Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 9V18C20 22.9706 24.0294 27 29 27C33.9706 27 38 22.9706 38 18V9H20ZM19.6923 7C18.7577 7 18 7.75767 18 8.69231V18C18 24.0751 22.9249 29 29 29C35.0751 29 40 24.0751 40 18V8.69231C40 7.75767 39.2423 7 38.3077 7H19.6923Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M38 47L38 38C38 33.0294 33.9706 29 29 29C24.0294 29 20 33.0294 20 38L20 47L38 47ZM38.3077 49C39.2423 49 40 48.2423 40 47.3077L40 38C40 31.9249 35.0751 27 29 27C22.9249 27 18 31.9249 18 38L18 47.3077C18 48.2423 18.7577 49 19.6923 49L38.3077 49Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M28 29H30V35C30 35.5523 29.5523 36 29 36C28.4477 36 28 35.5523 28 35V29Z" fill={active ? "#E4954D" : "#433E3A"} />
    <rect x="15" y="7" width="28" height="2" rx="1" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M15 48C15 47.4477 15.4477 47 16 47H42C42.5523 47 43 47.4477 43 48C43 48.5523 42.5523 49 42 49H16C15.4477 49 15 48.5523 15 48Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M20 16H38V18H20V16Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
