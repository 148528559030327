import axios from 'axios';
import { baseURl } from "../../../constants/config";
import { UpdateOrganizationDto } from './organization.type'

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURl
})

export const getOrganizationData = () => {
  return instance.get(`/organization`)
}

export const getPublicOrganizationData = (organizationUrlName:string) => {
  return instance.get(`/organization/${organizationUrlName}`)
}

export const updateOrganization = (updatingUserData: UpdateOrganizationDto) => {
  return instance.put(`/organization`, updatingUserData)
}

export const uploadOrganizationLogo = (file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/organization/upload_file/images`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

export const deleteOrganizationLogo = (fileId: string) => {
  return instance.put(`/organization/delete_file/images`, { fileId },)
}

export const uploadSingleFile = (dto: any) => {
  return instance.post(`/organization/add_single_file`, dto)
}

export const removeSingleFile = (dto: any) => {
  return instance.put(`/organization/remove_single_file`, dto )
}

export const addGalleryFile = (dto: any) => {
  return instance.post(`/organization/add_gallery`, dto);
}

export const removeGalleryFile = (dto: any) => {
  return instance.put(`/organization/remove_gallery`, dto )
}

export const addContactLogo = (dto: any) => {
  return instance.post(`/organization/add_contact_logo`, dto);
}

export const removeContactLogo = (dto: any) => {
  return instance.put(`/organization/remove_contact_logo`, dto);
}

export const getJobList = (organizationName: string, activePage: number) => {
  return instance.get(`/organization/job_list/${organizationName}/${activePage}`);
}

export const checkUrlName = (urlName: string) =>{
  return instance.get(`/organization/check_uniq_url_name/${urlName}`);
}