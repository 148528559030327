import { observer } from 'mobx-react-lite';
import { GalleryProps } from './Gallery.props';
import { PhotosList } from '../../common/PhotoUploadGallery/UploadGallery.presents';
import { Photo, PhotoItemContainer } from './Gallery.presents';
import { useState } from 'react';
import { MediaModal } from '../../JobStory/BasicComponents/GallerySection/MediaModal';


export const PhotoGallery = observer((props: GalleryProps) => {
    const {
        photoGallery,
        isDesktop,
        isPreview
    } = props;

    const [isModalOpen, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleClose = () => {
        setSelectedIndex(0)
        setShowModal(false);
    }
    const handleShow = (index: number = 0) => {
        setSelectedIndex(index)
        setShowModal(true);
    }

    return (
        <>
            <PhotosList>

                {
                    photoGallery.map((item: any, index: number) =>
                        <PhotoItemContainer key={index}>
                            <Photo url={item.url} isDesktop={isDesktop} onClick={() => handleShow(index)} isPreview={isPreview} />

                        </PhotoItemContainer>
                    )
                }
            </PhotosList>
            {isModalOpen && <MediaModal isOpen={isModalOpen} mediaFiles={photoGallery} selectedIndex={selectedIndex} onClose={() => handleClose()} />}
        </>

    )
})
