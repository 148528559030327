import { useEffect, useState } from "react";
import { getDimensions } from "./helper";
import { ResumeBuilderMenuProps } from "./ResumeBuilderMenu.props";
import { ResumeBuilderMenuItem, Wrapper, Section, DesktopResumeBuilderMenu, MobileWrapper, Header, ProgressBarText } from "./ResumeBuilderMenu.presents";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Text } from "../../../../common/Text";
import { ReactComponent as Check } from '../../../../../assets/img/orangeCheck.svg'


export const ResumeBuilderMenu = (props: ResumeBuilderMenuProps) => {
	const [activeTab, setActiveTab] = useState('personalInformation');
	const [disableHandleScroll, setDisableHandleScroll] = useState(false);

	const handleScroll = () => {
		const scrollPosition = window.scrollY + 5;
		const isScreenEnd = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;

		const selected = props.resumeBuilderMenuList.find(item => {
			const ele = props.componentRef[item.key].current;
			if (ele) {
				const { offsetBottom, offsetTop } = getDimensions(ele);
				return scrollPosition > offsetTop && scrollPosition < offsetBottom;
			}
		});

		if (!isScreenEnd && selected && selected.key !== activeTab) {
			setActiveTab(selected.key);
		} else if (isScreenEnd) {
			setActiveTab('audios');
		}
	};

	const activeTabClickHandler = (key: string, setDisableHandleScroll: Function, setActiveTab: Function, componentRef: any) => {
		setDisableHandleScroll(true);
		componentRef[key].current.scrollIntoView();
		setActiveTab(key);
		setTimeout(() => setDisableHandleScroll(false), 1000);
	}

	useEffect(() => {
		if (!disableHandleScroll) {
			handleScroll()
			window.addEventListener("scroll", handleScroll);
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, [activeTab, disableHandleScroll]);

	const ResumeBuilderMenuItemRender = (isColored: boolean, isDesktop: boolean) => props.resumeBuilderMenuList.map((item, index) => {
		if (item.colored === isColored || !isDesktop) {
			return <ResumeBuilderMenuItem
				onClick={() => activeTabClickHandler(item.key, setDisableHandleScroll, setActiveTab, props.componentRef)}
				key={index}
				isActive={activeTab === item.key}
				isDesktop={props.isDesktop}
			>
				{item.title}
				{item.isComplete && props.isDesktop && (
					<Check />
				)}
			</ResumeBuilderMenuItem>
		}
	}
	)

	return <> {props.isDesktop ?

		<Wrapper>
			<Section>
				<Header>
					<Text text={"Resume Checklist"} fs={18} fw={"500"} m={"24px 0px 0px 0px"}  />
					<ProgressBarText>
						<Text text={"Progress"} fs={16} fw={"500"} />
						<Text text={props.progressTracker+"%"} fs={16} fw={"500"} />
					</ProgressBarText>
					<ProgressBar now={props.progressTracker} />
				</Header>
				<DesktopResumeBuilderMenu isColored={true}>
					<Text text={"Recommended:"} fs={18} fw={"500"} m={"20px 0px 10px 15px"} />
					{ResumeBuilderMenuItemRender(true, props.isDesktop )}
				</DesktopResumeBuilderMenu>
				<DesktopResumeBuilderMenu isColored={false}>
					<Text text={"Optional Section:"} fs={18} fw={"500"} m={"20px 0px 10px 15px"} />
					{ResumeBuilderMenuItemRender(false, props.isDesktop )}
				</DesktopResumeBuilderMenu>
			</Section>
		</Wrapper>

		:
		<MobileWrapper>
			{ResumeBuilderMenuItemRender(true, props.isDesktop )}
		</MobileWrapper>}
	</>
}