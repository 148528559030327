import styled from "styled-components";
import { theme } from "../../../theme";

export const Wrapper = styled('div')`
	max-width: 266px;
	margin-right: ${theme.offsets.xxl};
	width: 100%;
	height: 100%;
	position: sticky;
	top: ${theme.offsets.xxl};
`

export const DesktopMenu = styled('div')`
	display: flex;
	flex-direction: column;
	background: ${theme.colors.white};
	width: 100%;
	max-width: 266px;
	border-radius: ${theme.variables.borderRadiusXLarge};
	padding: ${theme.offsets.sm};
`

const ActiveItemStyle = `
	background-color: rgba(236, 146, 60, 0.1);
	color: ${theme.colors.lightOrange};
`

export const MenuItem = styled('span') <{ isActive: boolean, isDesktop: boolean }>`
	font-size: ${theme.fontSizes.lg};
	color: ${(props)=>props.isActive ? theme.colors.lightOrange : theme.colors.black};
	width: 100%;
	padding: ${theme.offsets.lg};
	border-radius: 10px;
	font-weight: 500;
	margin-bottom: 2px;
	text-align: start;
	cursor: pointer;
	transition: 300ms;
	${(props) => (props.isActive && props.isDesktop) && 'background-color: rgba(236, 146, 60, 0.1);'}
	&:hover{
		${ActiveItemStyle}
	}
	${(props) => !props.isDesktop && `
		font-size: 14px;
		padding: ${theme.offsets.md} ${theme.offsets.lg};
		border-bottom: 2px solid ${theme.colors.white};
		white-space: nowrap;
		border-radius: 0;
		margin-bottom: 0;
		font-weight: 300;
		${props.isActive && `
		border-bottom: 2px solid ${theme.colors.lightOrange};
	`}
	`}	
`

export const MobileWrapper = styled('div')`
	width: 100vw;
	background: ${theme.colors.white};
	display: flex;
	justify-content: start;
	overflow-x: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	position: sticky;
	top: 0;
	margin-bottom: ${theme.offsets.lg};
	z-index: 10;
	@media(max-width: ${theme.screens.mobile}) {
		margin-bottom: ${theme.offsets.md};
  }
	&::-webkit-scrollbar {
		display: none;
	}
`

export const MobileMenuItem = styled('span') <{ isActive: boolean }>`
	font-size: 14px;
	color: ${theme.colors.black};
	padding: ${theme.offsets.md} ${theme.offsets.lg};
	transition: 3ms;
	border-bottom: 2px solid ${theme.colors.white};
	white-space: nowrap;
	${(props) => props.isActive && `
		color: ${theme.colors.lightOrange};
		border-bottom: 2px solid ${theme.colors.lightOrange};
	`}
`