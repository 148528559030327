import axios from 'axios';
import { baseURl } from "../../../constants/config";
import { ChangeJobStatusDto, SaveJobForLaterDto } from './jobStore.type';

export const getJobList = (page: number, jobStatus: string, sortData: { type: string, value: string }) => {
  axios.defaults.withCredentials = true;
  const sortString = sortData.type ? `${sortData.type}-${sortData.value}` : 'all'
  return axios.get(`${baseURl}/job_story/${page}/${jobStatus}/${sortString}`)
}

export const changeJobStatus = (dto: ChangeJobStatusDto) => {
  axios.defaults.withCredentials = true;
  return axios.put(`${baseURl}/job_story`, dto);
}

export const applyForAJob = (file: any, dto: any) => {
  const formData = new FormData();
  formData.append("file", file);
  for (const item in dto) {
    formData.append(item, dto[item]);
  }

  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story/apply_job`, formData);
}

export const SaveJob = (dto: SaveJobForLaterDto) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/job_story/save_job`, dto);
}

export const duplicateTheJob = (jobId: string) => {
  axios.defaults.withCredentials = true;
  return axios.put(`${baseURl}/job_story/duplicate`, { jobId });
}

export const resetNewReviewers = (jobId: string) => {
  axios.defaults.withCredentials = true;
  return axios.put(`${baseURl}/job_story/reviewers`, { jobId });
}
