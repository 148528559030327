import { BannersType } from '../../../JobStoryBuilder/BasicComponents/ThemeCustomizer/ThemeCustomizer.props';

export type PropsType = {
  organizationName: string
  jobData: any
  companyLogo: {
    fileUrl: ""
    filename: ""
    id: ""
  }
  bannersUrl: BannersType
}

export enum KindOfJobTypes { hiring = 'hiring', now = 'now' }