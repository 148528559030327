import { PropsType } from './HelperText.props'
import { HelperText } from "./HelperText";
import { TooltipWrapper } from "./HelperText.presents"
import useGetDevice from "../../../hooks/useGetDevice";

export const HelperTextTooltipWrapper = (props: PropsType) => {
    const isDesktop = useGetDevice();

    return <TooltipWrapper isDesktop={isDesktop}><HelperText {...props}/></TooltipWrapper>
}
