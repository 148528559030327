import styled from 'styled-components';
import { theme } from '../../../../theme';
import blueCheckedIcon from "../../../../assets/img/blueCheckedIcon.svg";
import orangeCheckedIcon from "../../../../assets/img/checkedOrange.svg";

import plusIcon from "../../../../assets/img/plusBlack.svg";

export const StyleForSelect = styled("button") `
  margin: 0px;
  width: 100%;
  border: 1px solid #D1D7E3;
  
`

export const SelectWrap = styled("div")`
  margin: 0px;
  position: relative;
`

export const SelectStyle = styled.div<{ disabled?: boolean, isMulti?: boolean, error?: boolean, isPanel?: boolean }>`
  width: 100%;
  input{
    height: auto;
  }
  .css-1fhf3k1-control{
    background-color: #fff!important;
  }
  .css-26l3qy-menu{
    box-shadow: none; 
  }
  .bZJBoj .cl-select__menu .cl-select__menu-list{
    padding: 0;
  }
  .css-1wa3eu0-placeholder{
    color: ${(props) => props.disabled ? 'rgba(67,62,58,0.3)' : theme.colors.gray};
    font-size: ${theme.variables.borderRadiusLarge};
    @media(max-width: ${theme.fontSizes.lg}) {
      font-size: ${theme.fontSizes.md};
    }
  }
  .cl-select__control {
    border-radius: 10px;
    border: 1px solid ${theme.colors.lightGray}!important;
    cursor: pointer;
    box-shadow: none;
    min-height: 52px;
    ${(props) => props.isMulti && 'min-height: 52px; height: auto;'};
    @media(max-width: ${theme.screens.desktop}) {
    min-height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
    }
    @media(max-width: ${theme.screens.mobile}) {
      min-height: 40px;
    }

    .cl-select__value-container {
      font-size: ${theme.variables.borderRadiusLarge};
      color: ${theme.colors.gray};
      @media(max-width: ${theme.fontSizes.lg}) {
        font-size: ${theme.fontSizes.md};
      }
    }
    .cl-select__single-value{
      position: relative;
      transform: translateY(0%);
    }

    .cl-select__indicators {
      .cl-select__indicator-separator {
        display: none;
      }
      .cl-select__dropdown-indicator{
        display: none;
      }

      .cl-select__indicator {
        color: #433E3A;
        opacity:${(props) => props.disabled ? '0.3' : '1'};
      }
    }
  }

  .cl-select__multi-value__label{
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-right: 3px;
  }
  .css-1rhbuit-multiValue{
    background:  ${(props) => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
    border-radius: 6px;
    padding: 0 10px;
    height: 36px;
    display: flex;
    align-items: center;
    ::before{
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: ${theme.colors.white};
      border-radius: 4px;
      margin-right: 4px;
      background-image: url(${(props) => props.isPanel? orangeCheckedIcon : blueCheckedIcon});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .cl-select__clear-indicator{
    align-self: flex-start;
    padding: 14px 8px;
    display: ${(props) => props.isMulti ? 'block' : 'none' };
  }
  .cl-select__multi-value__remove svg{
    color: #fff;
  }
  .cl-select__multi-value__remove:hover{
    background-color:  ${(props) => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
  }
  
  .cl-select__control--menu-is-open {
    border-radius: 10px 10px 0 0;
    .cl-select__indicators {
      .cl-select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  .cl-select__menu {
    border-radius: 10px;
    border: 1px solid #D1D7E3;
    margin: 0;
    border-radius: 0 0 10px 10px !important;
    border-top: none!important;
    z-index: 3;
    .cl-select__menu-list {
      padding: 0 16px;
      
      .cl-select__option {
        padding: 16px 0;
        border-top: 1px solid #D1D7E3;
        font-size: 14px;
        line-height: 16px;
        color: #433E3A;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        ::after{
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          background: url(${plusIcon}) center no-repeat;
          background-size: contain;
        }
      }
      .cl-select__option:first-child{
        border-top: none;
      }
      .cl-select__option--is-focused {
        background: transparent;
        color: ${theme.colors.black};
      }

      .cl-select__option--is-selected {
        background: transparent;
        position: relative;
      }

      .cl-select__option--is-disabled {
        color: ${theme.colors.lightGray};
        cursor: not-allowed;
      }

      .cl-select__option--is-selected::after {
        background: url(${(props) => props.isPanel? orangeCheckedIcon : blueCheckedIcon}) center no-repeat;
        background-size: contain;
      }
      
      }
    
    }
`