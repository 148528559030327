import { colors } from './../../../constants/colors';
import styled from 'styled-components'
import { theme } from '../../../theme';

export const StyleForToggle= styled('div')`
#cbx{
  display:none;
}
.toggle{
  position: relative;
  display: block;
  width: 42px;
  height: 24px;
  cursor: pointer;
  transform: translate3d(0,0,0);
}
.toggle:before{
    content: "";
    position: relative;
    top: 0px;
    left: 0px;
    width: 42px;
    height: 24px;
    display: block;
    border-radius: 120px;
    transition: background .2s ease;
    border: 2px solid #D1D7E3;
    background: #D1D7E3;
  }
  span{
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background: ${theme.colors.white};
    display: block;
    border-radius: 10px;
    transition: all .2s ease;
  }
  span::before{
      content: "";
      position: absolute;
      display: block;
      margin: -18px;
      width: 56px;
      height: 56px;
      background: rgba(primary,.5);
      border-radius: 50%;
      transform: scale(0);
      opacity: 1;
      pointer-events: none;
    }

#cbx:checked + .toggle:before{
    background: #F8DDC3;
    border: 2px solid #F8DDC3;
  }
  #cbx:checked + .toggle span{
    background: ${colors.orange};
    transform: translateX(19px);
    transition: all .2s cubic-bezier(.8,.4,.3,1.25), background .15s ease;
  }
  #cbx:checked + .toggle span::before{
      transform: scale(1);
      opacity: 0;
      transition: all .4s ease;
    }
`