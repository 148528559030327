import { theme } from '../../../../theme';
import styled from 'styled-components';

export const JobStoryCardContainer = styled('div')`
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  background: white;
  box-shadow: -10px 10px 20px rgba(63, 71, 79, 0.05), 10px -10px 20px rgba(63, 71, 79, 0.05);
  border-radius: ${theme.variables.borderRadiusMedium};
  scroll-margin-top: 120px;
  margin-bottom:  ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.xl};
  }
`

export const JobStoryCardContent = styled('div')`
  padding: ${theme.offsets.xxl};
  position: relative;
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.lg};
    padding: ${theme.offsets.lg};
  }
`

export const JobStoryCardImage = styled.div<{imageUrl?: string}>`
  border-radius: ${theme.variables.borderRadiusMedium};
  background: ${props => props.imageUrl ? `url(${ props.imageUrl })` : theme.colors.darkGray};
  background-position: center;
  background-size: cover;
  min-height: 360px;
`

export const JobStoryCardImageWrapper = styled.div`
  padding: ${theme.offsets.sm} ${theme.offsets.sm} 0;
`

export const JobStoryCardTitleWrapper = styled.div`
  margin-bottom: ${theme.offsets.major};

  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.xl};
  }
`
