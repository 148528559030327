import { theme } from '../../../../theme';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const MediaModalWrapper = styled(Modal)`
.modal-dialog{
  max-width: 640px;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 400px;
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 288px;
  }
}
.modal-content {
  border-radius: ${theme.variables.borderRadiusLarge};
  padding: ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.lg};
  }
  .modal-body {
    padding: 0;
    max-width: 545px;
    width: 100%;
    margin: 0 auto;
  }
}
`

export const ContactMeHeader = styled('div')`
  margin-top: ${theme.offsets.xxl};
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.lg};
    margin-bottom: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.sm};
    margin-bottom: ${theme.offsets.lg};
  }
`

export const Title = styled('h3')`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${theme.colors.darkGray};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 18px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
  }
`

export const ButtonWrap = styled('div')`
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    height: 44px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
  }
`

export const QuestionWrap = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
  }
`

export const Divider = styled('div')`
  height: 1px;
  width: 100%;
  background: #D9D9D9;
`

export const QuestionText = styled('p')`
  width: 150px;
  font-size: 14px;
  text-align: center;
  margin: 0 15px;
  color: #656B72;
  @media(max-width: ${theme.screens.mobile}) {
    width: 130px;
    font-size: 12px;
    margin: 0 8px;
  }
`
