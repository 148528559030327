import { ModalWindow } from "../../../../common/Modal";
import { socialNetworksList } from "../../helper";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { SPBaseButton } from "../../../../common/buttons/SPBaseButton/SPBaseButton";
import { Checkbox } from "../../../../common/Checkbox";
import { ListItemWrap, ListWrap, BtnsWrap } from "./SocialNetworks.presents";
import { ListModalProps } from "../../Organization.props";

export const ListModal = (props: ListModalProps) => {
	const { isModalOpen, setModalOpen, selectedNetworks, socialNetworks, setFieldValue, isDesktop, handleChange } = props;

	const setNewNetworks = () => {
		let newNetworks: Array<{ title: string, link: string }> = [];
		const selectedNetworksCopy = [...selectedNetworks];

		socialNetworks.forEach(item => {
			const oldValueIndex = selectedNetworksCopy.findIndex((elem: string) => elem === item.title);
			if (oldValueIndex >= 0) {
				//safe old socNetworks value and remove it from checked list 
				newNetworks.push(item);
				selectedNetworksCopy.splice(oldValueIndex, 1);
			}
		})
		//add remaining checked socNetworks with default values
		const remainingValues = selectedNetworksCopy.map((item: string) => { return { title: item, link: '' } })
		newNetworks = [...newNetworks, ...remainingValues];
		setFieldValue('socialNetworks', newNetworks);
		setModalOpen(false);
	}

	return <ModalWindow closeWindow={setModalOpen} openWindow={isModalOpen} title="Social networks">
		<ListWrap isDesktop={isDesktop}>
			{socialNetworksList.map(item => <ListItemWrap isDesktop={isDesktop}>
				<Checkbox handleChange={handleChange} value={item} labelText={item} name="selectedNetworks" checked={selectedNetworks.includes(item)} />
			</ListItemWrap>)}
		</ListWrap>
		<BtnsWrap isDesktop={isDesktop}>
			<SPBaseButton width="auto" onClick={() => setModalOpen(false)} type="button">
				No
			</SPBaseButton>
			<SPSecondaryButton width="auto" onClick={() => setNewNetworks()} type="button">
				Yes
			</SPSecondaryButton>
		</BtnsWrap>
	</ModalWindow>
}