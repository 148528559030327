import { disableIntro, getUserData, removeSingleFile, updateUser, uploadSingleFile } from './../../services/api/user/user.domains';
import { logout, signUp } from '../../services/api/auth/auth.domains';
import { SignUpDto } from '../../services/api/auth/auth.type';
import { types, flow } from "mobx-state-tree";
import { signIn } from "../../services/api/auth/auth.domains";
import { HelperTextStatus } from '../../components/Auth/Employer/SignIn/SignIn.props';
import { addPhone, addUserMainInfo, phoneVerification } from '../../services/api/employerSignUp/employerSignUp.domains';
import { RegisterUserDto } from '../../services/api/employerSignUp/employerSignUp.type';

type mainUserData = {
  firstName: string
  lastName: string
  phone: number
  email: string
  position?: string
  logo?: any
  isBuildIntroDisabled?: boolean
}

const logoModel = {
  url: '',
  key: '',
  fileName: ''
}

export const user = types
  .model("userStore", {
    isAuthorizated: false,
    isVereficated: false,
    isVerPhone: false,
    isVerEmail: false,
    verificationId: '',
    helperTextStatus: types.maybeNull(types.enumeration(Object.values(HelperTextStatus))),
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    orgType: '',
    organizationName: '',
    position: types.maybeNull(types.string),
    logo: types.optional(types.model(logoModel), logoModel),
    isBuildIntroDisabled: types.maybe(types.boolean),
    creationDate: types.maybeNull(types.Date),
  })
  .actions(self => {

    const addMainUserData = (data: mainUserData) => {
      self.isAuthorizated = true;
      self.email = data.email;
      self.firstName = data.firstName;
      self.lastName = data.lastName;
      self.phone = `+${data.phone}`;
      self.logo = data.logo || logoModel;
      self.position = data.position ? data.position : null;
      self.isBuildIntroDisabled = data.isBuildIntroDisabled;
    }

    const setSignUpData = (serverResponse: any)=>{
      if (!serverResponse.err) {
        self.verificationId = serverResponse.data.verificationId;
        self.isVereficated = serverResponse.data.verification;
        self.isVerPhone = serverResponse.data.verPhone;
        self.isVerEmail = serverResponse.data.verEmail;
        self.email = serverResponse.data.email ? serverResponse.data.email : '';
        self.firstName = serverResponse.data.firstName ? serverResponse.data.firstName : '';
        self.lastName = serverResponse.data.lastName ? serverResponse.data.lastName : '';
        self.organizationName = serverResponse.data.organizationName ? serverResponse.data.organizationName : '';
        self.orgType = serverResponse.data.orgType ? serverResponse.data.orgType : '';
        self.phone = `${serverResponse.data.phone}`;
        return {
          err: '', data: {
            isVereficated: self.isVereficated,
            isVerPhone: self.isVerPhone,
            isVerEmail: self.isVerEmail,
            email: self.email
          }
        }
      } else {
        return { err: serverResponse.err }
      }
    }

    const savePhoneNumber = flow(function* (data: {phone: number, token: string, country: string}) {
      const serverResponse = yield addPhone(data);
      return setSignUpData(serverResponse.data);
    })

    const sendPhoneVerification = flow(function* (data: { phoneCode: string; verificationId: string; }) {
      const serverResponse = yield phoneVerification(data);
      return setSignUpData(serverResponse.data);
    })

    const saveUserMainInfo = flow(function* (data: RegisterUserDto) {
      const serverResponse = yield addUserMainInfo(data);
      return setSignUpData(serverResponse.data);
    })

    const login = flow(function* (data) {
      try {
        const dataFromServer = yield signIn(data)
        addMainUserData(dataFromServer.data)
        return { err: '' }
      } catch (e) {
        // TODO: add 401 check
        return { err: 'Email or password is incorrect' }
      }
    })

    const addUserData = flow(function* () {
      try {
        const dataFromServer = yield getUserData()
        addMainUserData(dataFromServer.data.data)
        return { err: '' }
      } catch {
        return { err: 'auth error' }
      }
    })

    const logoutUser = flow(function* () {
      try {
        yield logout()
        self.isAuthorizated = false
        self.email = ''
        self.firstName = ''
        self.lastName = ''
        self.phone = ''
        return { err: '' }
      } catch {
        return { err: 'error' }
      }
    })

    const setHelperTextStatus = (newStatus: HelperTextStatus | null) => {
      self.helperTextStatus = newStatus;
    }

    const updateUserData = flow(function* (data) {
      const dataFromServer = yield updateUser(data)
      if (!dataFromServer.data.err) {
        addMainUserData(dataFromServer.data.data)
        return { err: null }
      } else {
        return { err: dataFromServer.data.err }
      }
    })

    const disableBuilderIntro = flow(function* () {
      const response = yield disableIntro();
      if (!response.data.err) {
        self.isBuildIntroDisabled = true;
        return true
      } else {
        return false
      }
    })

    const saveSingleFile = async (dto: any) => {
      await uploadSingleFile(dto)
    }

    const deleteSingleFile = async (dto: any) => {
      await removeSingleFile(dto)
    }

    const setLogo = (key: string, url: string) => {
      self.logo.url = url
      self.logo.key = key
    }

    return { 
      setHelperTextStatus, 
      login, 
      addUserData, 
      logoutUser, 
      updateUserData, 
      disableBuilderIntro, 
      saveSingleFile, 
      deleteSingleFile, 
      setLogo,
      savePhoneNumber,
      sendPhoneVerification,
      saveUserMainInfo
    }
  })