import styled from "styled-components";
import { theme } from "../../../../../theme";

export const SocialField = styled('div') <{ isDesktop: boolean, isSingleItem: boolean }>`
	max-width: ${(props) => props.isSingleItem ? '100%' : `calc(50% - ${theme.offsets.xxl})`} ;
	width: 100%;
  margin: ${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.sm};
    max-width: ${(props) => props.isSingleItem ? '100%' : `calc(50% - ${theme.offsets.lg})`}
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: 0;
    max-width: 100%;
  }
`

export const ListWrap = styled('div')<{ isDesktop: boolean }>`  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 557px;
  margin: 0 auto;
  ${(props) => !props.isDesktop && `
      @media(max-width: ${theme.screens.mobile}) {
        overflow-y: scroll;
        max-height: 70vh;
      } 
    `}
  ::after{
    content: '';
    width: 100%;
    display: block;
    height: 1px;
    margin-top: -1px;
    background: ${theme.colors.white};
  }
`

export const ListItemWrap = styled('div') <{ isDesktop: boolean }>`
 width: 47%;
 border-bottom: 1px solid ${theme.colors.lightGray};
 padding: ${theme.offsets.xl} 0;
 display: flex;
 ${(props) => !props.isDesktop && 'width: 100%;'}
 input{
   width: 100%;
 }
  label{
    width: fit-content;
    font-size: ${theme.fontSizes.lg};
    font-weight: 400;
    @media(max-width: ${theme.screens.tablet}) {
      font-size: 14px;
    }
  }
`

export const BtnsWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  margin-top: ${theme.offsets.extraMajor};
  button{
    width: 190px;
    margin-left: ${theme.offsets.xxl};
    ${(props) => !props.isDesktop && `
      width: 120px;
      margin-left: ${theme.offsets.md};
    `}
  }
  
`
