import { types } from "mobx-state-tree"


export const router = types
  .model("routerStore", {
    path: ''
  })
  .actions(self => {
    const setLocationPath = (path: string) => {
      self.path = path
    }

    return { setLocationPath }
  })