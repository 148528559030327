import { Wrapper, ButtonWrap } from "./Header.presents";
import { ReactComponent as LogoIcon } from "../../../../../../assets/img/logo-white.svg";
import { ReactComponent as BlackLogoIcon } from "../../../../../../assets/img/logo.svg";
import { useHistory } from "react-router-dom";
import { SPBaseButton } from "../../../../../common/buttons/SPBaseButton";
import { useRootStore } from "../../../../../../state/stores/storeContext";

export const Header = ({ isLogoBlack, isPreferencesPage }: { isLogoBlack?: boolean, isPreferencesPage?: boolean }) => {
  const history = useHistory();
  const { candidateStore } = useRootStore();
      
  const goToSignInPage = async () =>{
    await candidateStore.signOutUser();
    history.replace('/candidate/sign-in')
  };

  return <Wrapper>
    <a href="https://sidepost.com">
      {isLogoBlack ? <BlackLogoIcon /> : <LogoIcon />}
    </a>
    <ButtonWrap isLogoBlack={isLogoBlack}>
      {!isPreferencesPage && <>
        <span>
          Already have an account?
        </span>
        <SPBaseButton
          type="button"
          width="auto"
          onClick={goToSignInPage}>
          Sign In
        </SPBaseButton>
      </>}
    </ButtonWrap>
  </Wrapper>
}