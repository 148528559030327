import axios from 'axios';
import { baseURl } from "../../../constants/config";
import { EmailWithLinkDto, SupportMessageDto } from '../user/user.type';
import { ChangeCandidateStatusDto, ContactMeDto, SharingEmailWithLinkDto } from './candidates.type';

export const getCandidatesList = (page: number, candidateStatus: string, sortData: { type: string, value: string }, jobId: string) => {
  axios.defaults.withCredentials = true;
  const sortString = sortData.type ? `${sortData.type}-${sortData.value}` : 'all'
  return axios.get(`${baseURl}/candidates/${jobId}/${page}/${candidateStatus}/${sortString}`)
}

export const changeCandidateStatus = (dto: ChangeCandidateStatusDto) => {
  axios.defaults.withCredentials = true;
  return axios.put(`${baseURl}/candidates`, dto);
}

export const sendSupportMessage = (supportData: SupportMessageDto) => {
  return axios.post(`${baseURl}/candidates/support`, supportData)
}

export const sendContactMeMessage = (data: ContactMeDto) => {
  return axios.post(`${baseURl}/candidate/contact_me`, data)
}

export const sendWelcomeMessage = (dto: EmailWithLinkDto) => {
  return axios.post(`${baseURl}/candidate/welcome`, dto)
}

export const sendEmailValidation = (dto: EmailWithLinkDto) => {
  return axios.post(`${baseURl}/candidate/email_verification`, dto)
}

export const sendShareResumeMessage = (dto: SharingEmailWithLinkDto) => {
  return axios.post(`${baseURl}/candidate/share_resume`, dto)
};