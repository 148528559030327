import { PersonIcon } from '../../../../assets/img/jobstoryIcons/active/Person'
import { CalendarIcon } from '../../../../assets/img/jobstoryIcons/active/Calendar'
import { ContractIcon } from '../../../../assets/img/jobstoryIcons/active/Contract'
import { InternshipIcon } from '../../../../assets/img/jobstoryIcons/active/Internship'
import { TemporaryIcon } from '../../../../assets/img/jobstoryIcons/active/Temporary'
import { RocketIcon } from '../../../../assets/img/jobstoryIcons/active/Rocket'
import { HandIcon } from '../../../../assets/img/jobstoryIcons/active/Hand'
import { LikeIcon } from '../../../../assets/img/jobstoryIcons/active/Like'
import { DislikeIcon } from '../../../../assets/img/jobstoryIcons/active/Dislike'
import { HomeIcon } from '../../../../assets/img/jobstoryIcons/active/Home'
import { ClockIcon } from '../../../../assets/img/jobstoryIcons/active/Clock'
import { BacteriaIcon } from '../../../../assets/img/jobstoryIcons/active/Bacteria'
import { BookIcon } from '../../../../assets/img/jobstoryIcons/active/Book'

import { useEffect, useState } from 'react'


export const ActiveIcon = ({ name, checked }: { name: any, checked: boolean }) => {
  const [icon, setIcon] = useState<any>(<PersonIcon />);

  useEffect(() => {
    const iconsList: any = {
      directHire: <PersonIcon active={checked} />,
      contractHire: <CalendarIcon active={checked} />,
      contract: <ContractIcon active={checked} />,
      internship: <InternshipIcon active={checked} />,
      temporary: <TemporaryIcon active={checked} />,
      projectBased: <RocketIcon active={checked} />,
      volunteer: <HandIcon active={checked} />,
      yes: <LikeIcon active={checked} />,
      no: <DislikeIcon active={checked} />,
      someRemote: <HomeIcon active={checked} />,
      temporaryRemote: <ClockIcon active={checked} />,
      covidRemote: <BacteriaIcon active={checked} />,
      apprenticeship: <BookIcon active={checked} />
    }

    setIcon(iconsList[name])

  }, [name, checked])

  return icon

}