import { StyleForWrap } from './MediaTrailer.presents';
import { observer } from 'mobx-react-lite';
import { Player, Ui, Video, Controls, PlaybackControl } from '@vime/react';
import Waveform from './wavesurfer';
import { PropsType } from './MediaTrailer.props';

export const MediaTrailer = observer(({ jobData }: PropsType) => {

  const trailerFile = jobData.contactStep.additionalVideoFile[0];

  return jobData.contactStep.additionalVideoFile.length > 0 && trailerFile ? <StyleForWrap>
    <p className='title'>About this job</p>
    {
      trailerFile.type === 'video'
        ? <div className='video-wrap'>
          <Player controls>
            <Video
              cross-origin
            >
              <source
                data-src={trailerFile.url}
              />
            </Video>
            <Ui>
              <Controls pin="center">
                <PlaybackControl hideTooltip/>
              </Controls>
            </Ui>
          </Player>
        </div> 
      : <Waveform url={trailerFile.url} />
      }
  </StyleForWrap> : null
})