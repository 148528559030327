import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Container = styled('div') <{ isDesktop: boolean }>`
  max-width: 414px;
  width: 100%;
  margin: 0 9px;
  ${(props) => !props.isDesktop ? `
    max-width: 100%;
    margin: 0;
    @media(max-width: ${theme.screens.mobile}) {
      flex: 1;
      margin: -${theme.offsets.lg} -${theme.offsets.lg} 0;
      max-width: 100vw;
    };
  `: `
    flex: 3;
  `}
`;

export const AvatarWrap = styled('div') <{ isDesktop: boolean }>`
${(props) => !props.isDesktop && `
  display: flex;
  justify-content: space-between;
  align-items: center;
  button{
    width: 170px;
    @media(max-width: ${theme.screens.mobile}) {
      display: none;
    };
  }
`}
`;

export const AvatarContainer = styled('div') <{ showBorder: boolean, isDesktop: boolean }>`
img{
  border-radius: 100%;
  border: ${props => props.showBorder ? `1px solid ${theme.colors.lightGray}` : 'none'};
  width: 120px;
  height: 120px;
  background: rgba(209, 215, 227, 0.2);
  object-fit: cover;
  @media(max-width: ${theme.screens.desktop}) {
    width: 80px;
    height: 80px;
  };
  @media(max-width: ${theme.screens.mobile}) {
    width: 64px;
    height: 64px;
    };
};
display: flex;
justify-content: center;
${(props) => !props.isDesktop && `
    justify-content: flex-start;
    margin-top: ${theme.offsets.big};
    @media(max-width: ${theme.screens.mobile}) {
      margin-top: ${theme.offsets.lg};
    };
  `}
`;

export const FullName = styled('p') <{ isDesktop: boolean }>`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.big};
  margin-top: ${theme.offsets.lg};
  text-align: center;
  ${(props) => !props.isDesktop && `
    text-align: left;
    font-size: ${theme.fontSizes.xxl};
  `}
`;

export const Position = styled('p') <{ isDesktop: boolean }>`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.offsets.sm};
  text-align: center;
  ${(props) => !props.isDesktop && `
    text-align: left;
    font-size: 14px;
  `}
`;

export const AdditionalInfoContainer = styled('div')`
  margin-top: ${theme.offsets.major};
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xxl};
  };
`;

export const AdditionalInfo = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.fontSizes.lg};
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin-top: ${theme.fontSizes.sm};
  };
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
    svg{
      width: 20px;
      height: 20px;
    }
  };
`;

export const SocialNetworksWrap = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -${theme.offsets.xs};
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
  }
`;

export const SocialNetworkItem = styled('a')`
  border-radius: 100%;
  border: 1px solid ${theme.colors.mediumGray};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  margin: ${theme.offsets.xs};
  @media(max-width: ${theme.screens.mobile}) {
    width: 40px;
    height: 40px;
    svg{
      width: 18px;
      height: 18px;
    }
  }
`;

export const ButtonWrap = styled('div') <{ isDesktop: boolean }>`
  margin-top: ${theme.offsets.extraBig};
  display: ${props => props.isDesktop ? "block" : "none"};
  button:disabled{
    background-color: ${theme.colors.lightGray};
    border-color: ${theme.colors.lightGray};
    color: ${theme.colors.gray};
  }
  @media(max-width: ${theme.screens.mobile}) {
    display: block;
    margin-top: ${theme.offsets.xxl};
  };
`;

export const VideoPlayerWrap = styled('div') <{ isDesktop?: boolean }>`
  overflow: hidden;
  position: relative;
  margin: 0 0 ${theme.offsets.xxl};
  video{
    border-radius: ${theme.variables.borderRadiusSmallMedium};
  }
  ${props=>!props.isDesktop && `
    margin: -${theme.offsets.xxl} -${theme.offsets.xxl} 0;
    video{
      border-top-left-radius: ${theme.variables.borderRadiusSmallMedium};
      border-top-right-radius: ${theme.variables.borderRadiusSmallMedium};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin: -${theme.offsets.lg} -${theme.offsets.lg} 0;
      video{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  
    }
  `}
  
  ;
`

export const PlayerWrap = styled('div') <{ isResumeBuilder?: boolean }>`
  width: 100%;
  max-height: ${props => props.isResumeBuilder ? '440px' : '670px'};
  height: 100vh;
  
  @media(max-width: ${theme.screens.desktop}) {
    max-height: 414px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-height: 404px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-height: 161px;
    padding: 0;
  }
`


