import { SPSecondaryButton } from '../../../common/buttons/SPSecondaryButton/SPSecondaryButton';
import { BtnBlock, Text, Title, Wrapper, ContentBox, CircleBlock, Content, Banner } from './Intro.presents';
import IntroIcon from '../../../../assets/img/BuilderInto.svg'
import { SPLightButton } from '../../../common/buttons/SPLightButton/SPLightButton';
import { useHistory } from 'react-router-dom';
import { disableIntro } from '../../../../services/api/user/user.domains';
import { useRootStore } from '../../../../state/stores/storeContext';

export const Intro = () => {
  const { userStore } = useRootStore();
  const history = useHistory();

  const goToStartStep = () => history.push('/job-story/start');
  const changeIntroStatus = async () => {
    const result = await userStore.disableBuilderIntro();
    if (result) goToStartStep();
  };

  return <Wrapper>
    <ContentBox>
      <Content>
        <Title>Let’s Start!</Title>
        <Text>Be patient! An attractive, competitive and eye-catching job posting needs a few minutes of your time.
          Answer as many questions and we’ll build a beautiful Job Story for you in seconds.</Text>
        <BtnBlock>
          <SPSecondaryButton onClick={goToStartStep} width='100%'>Create a Job Story</SPSecondaryButton>
          <SPLightButton onClick={changeIntroStatus} width='100%'>Don’t show it again</SPLightButton>
        </BtnBlock>
      </Content>
    </ContentBox>
    <Banner src={IntroIcon} alt='banner' />
    <CircleBlock />
  </Wrapper>
}