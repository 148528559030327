import { OrganizationCardContainer, OrganizationCardContent, Title } from "./OrganizationPreviewCard.presets"
import { OrganizationCardProps } from "./OrganizationPreviewCard.props"

export const OrganizationPreviewCard = (props: OrganizationCardProps) => {
  return (
    <OrganizationCardContainer isDesktop={props.isDesktop}>
      {props.title && <Title>{props.title}</Title>}
      <OrganizationCardContent isDesktop={props.isDesktop} hasTitle={!!props.title}>
        {props.children}
      </OrganizationCardContent>
    </OrganizationCardContainer>
  )
}