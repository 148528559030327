import currenciesOptions from '../../../../assets/staticData/currencies.json';
import { ListItem } from './Introduction.presents';

export const setSalaryString = (jobData: any) => {
  const from = jobData.salaryRateFrom || jobData.hourlyRateFrom;
  const to = jobData.salaryRateTo || jobData.hourlyRateTo;
  const period = jobData.salaryPeriodSelect;
  const currencies = currenciesOptions.find(item => item.value === (jobData.salaryFromSelect || jobData.hourlyFromSelect));
  if (from && to && currencies) {
    const salaryString = `${from} - ${to} ${currencies?.symbol}`;
    const periodString = period ? '/' + period : '';
    return < ListItem withPoint> {salaryString + periodString}</ListItem>;
  } else {
    return '';
  }
}

export const setSalaryBonuses = (jobData: any, bonusName: string, text: string) => {
  const from = jobData[`${bonusName}From`];
  const to = jobData[`${bonusName}To`];
  const percentage = jobData[`${bonusName}Select`];
  if (from && to && percentage) {
    const salaryString = `${from} - ${to} ${percentage} ${text}`;
    return <ListItem withPoint>{salaryString}</ListItem>;
  } else {
    return '';
  }
}
export const setLocationsListItem = (props: { jobData: any, setOpenMapsWindow: Function, isHiringLocation?: boolean}) => {
  const { jobData, setOpenMapsWindow, isHiringLocation } = props;
  const isRemoteLocation = isHiringLocation && jobData.startStep.doneRemotely !== 'no';
  const anywhereFromWorld = jobData.jobStep.acceptRemoteWorkersWorld && jobData.jobStep.acceptRemoteWorkersWorld.includes('anywhereFromWorld');
  if (isRemoteLocation && !anywhereFromWorld) {
    //Set title for hiring location
    let locationsLength = 0;
    const firstLocationItem = jobData.jobStep.hiringLocations[0];
    const isAnywhereFromCountry = firstLocationItem.acceptRemoteWorkers && firstLocationItem.acceptRemoteWorkers.includes('anywhereFromCountry');

    //Get nubmer of all cities
    for (const key of jobData.jobStep.hiringLocations) {
      locationsLength = locationsLength + key.city.length
    };
    
    const countryName =  firstLocationItem.country;
    const territoryName = firstLocationItem.territory;
    const cityName = firstLocationItem.city[0];
    const helperText = locationsLength > 1 ? `and ${--locationsLength} more` : '';

    const locationString = isAnywhereFromCountry ? `Anywhere from ${countryName} ${helperText}` : `${countryName}, ${territoryName}, ${cityName} ${helperText}`

    return <ListItem
      onClick={() => setOpenMapsWindow(!!locationsLength)}
      isModal={locationsLength > 0}>
      {locationString}
    </ListItem>;
  } else if (isRemoteLocation && anywhereFromWorld) {
    return <ListItem >Remote workers from anywhere in the world</ListItem>;
  } else {
    //Set title for primary location
    let locationsLength = jobData.startStep.locations.length;
    const firstLocationItem = jobData.startStep.locations[0];
    const cityName = firstLocationItem.city;
    const countryName = firstLocationItem.country;
    const territoryName = firstLocationItem.territory;
    const helperText = locationsLength > 1 ? `and ${--locationsLength} more` : '';

    const locationString = `${countryName}, ${territoryName}, ${cityName} ${helperText}`;
    return <ListItem
      onClick={() => setOpenMapsWindow(!!locationsLength)}
      isModal={locationsLength > 0}>
      {locationString}
    </ListItem>;
  }
}

export const salaryBonuses = [
  { bonusName: 'commisionRate', text: 'Commision' },
  { bonusName: 'bonusAmount', text: 'Bonus' },
  { bonusName: 'signInBonus', text: 'Sign In Bonus' },
]