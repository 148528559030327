import { db } from "../index";
import { FirebaseUser } from "../firebase.type";
import { doc, getDoc, query, where,collection, getDocs } from "firebase/firestore";

export const onGetUser = async (uid: string) => {
        try {
            const docRef = doc(db, "users", uid);
            const docSnap = await getDoc(docRef);
            return docSnap.data()

        } catch (error) {
            return error
        }
}

export const onGetUserViaEmail = async (email: string) => {
    try {
        let user:FirebaseUser[] = [] 
        const docRef = collection(db, "users");
        const q = query(docRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            user.push(doc.data())
        });
        return user
    } catch (error) {
        return error
    }
}

export const onCheckUserExistsViaEmail = async (email: string) => {
    try {
        let userExist = false
        const docRef = collection(db, "users");
        const q = query(docRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            userExist = true
        });
        return userExist

    } catch (error) {
        return error
    }
}

export const onGetAnonymousUserInfo = async (uid: string) => {
    try {
        const docRef = doc(db, "anonymous", uid);
        const docSnap = await getDoc(docRef);
        return docSnap.data()

    } catch (error) {
        return error
    }
}

export const onGetAnonymousUserPreferences = async (uid: string) => {
    try {
        const docRef = doc(db, "anonymous", uid);
        const preferencesRef = doc(docRef, "job","preferences")
        const docSnap = await getDoc(preferencesRef);
        return docSnap.data()

    } catch (error) {
        return error
    }
}

export const onGetAnonymousUserExpertises = async (uid: string) => {
    try {
        const docRef = doc(db, "anonymous", uid);
        const expertisesRef = doc(docRef, "job","expertises")
        const docSnap = await getDoc(expertisesRef);
        return docSnap.data()

    } catch (error) {
        return error
    }
}

export const onGetCandidatePreferences = async (uid: string) => {
    try {
        const docRef = doc(db, "users", uid);
        const preferencesRef = doc(docRef, "job","preferences")
        const docSnap = await getDoc(preferencesRef);
        return docSnap.data()

    } catch (error) {
        return error
    }
}

export const onGetCandidateExpertises = async (uid: string) => {
    try {
        const docRef = doc(db, "users", uid);
        const expertisesRef = doc(docRef, "job","expertises")
        const docSnap = await getDoc(expertisesRef);
        return docSnap.data()

    } catch (error) {
        return error
    }
}