import { theme } from './../../../../theme/theme';
import styled from 'styled-components';

export const HeaderWrap = styled('div')`
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: ${theme.offsets.xxl};
  padding: ${theme.offsets.xl} 0;
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.lg} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: 0;
    padding: ${theme.offsets.md} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.sm} 0;
  }
`

export const Content = styled('div')`
  max-width: 1680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 ${theme.offsets.lg};
`

export const ApplyBtn = styled('div')`
  max-width: 260px;
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 167px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 75px;
  }
`

export const ModalBtn = styled('div')`
  max-width: 190px;
  margin-right: ${theme.offsets.xxl};
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 167px;
    margin-right: ${theme.offsets.xl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: fit-content;
    margin-right: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: ${theme.offsets.md};
    & button{
      border: none;
    }
  }
`

export const ModalSpan = styled('span')`
  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }
`


export const ModalBtnText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.md};
  }
`

export const ShareIcon = styled('img')`
  margin-right: ${theme.offsets.sm};
  transform: rotate(180deg);
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: 0;
  }
`

export const SaveIcon = styled('img')`
  margin-right: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: 0;
  }
  `

export const BtnsWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const Logo = styled('img')`
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 133px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 100px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 78px;
  }
`



