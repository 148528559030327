import { Col } from 'react-bootstrap'
import { Input } from '../../../common/Input'
import { RadioBtn } from '../../BasicComponents/RadioBtn'
import { ActiveIcon, btnIcons, uploadedIcons } from './DescriptionStep.icons'
import { answerType, schemePropsType } from './DescriptionStep.props'
import { SelectCom } from '../../../common/Select'
import { Field } from 'formik'
import { FileBtn } from '../../../common/FileBtn'
import { LanguagesFieldArray } from '../../BasicComponents/Languages/LanguagesFieldArray'
import { Checkbox } from '../../../common/Checkbox'
import { Tags } from '../../BasicComponents/Tags'
import educationLevelOptions from '../../../../assets/staticData/education.json'
import { SalaryField } from '../../BasicComponents/SalaryField'
import { JobDescriptionStep } from '../../BasicComponents/FilesGallery/JobDescriptionStep'

export const selectComponent = (data: schemePropsType) => {

  const setOpenBlock = (name: string, value: boolean) => {
    switch (name) {
      case 'jobExperience':
        data.setOpenJobExperienceBlock(value)
        break
      case 'educationForJob':
        data.setOpenEducationForJobBlock(value)
        break
      case 'certificationsForJob':
        data.setOpenCertificationsForJobBlock(value)
        break
      default:
    }
  }

  const isBlockOpen = (name: string) => {
    switch (name) {
      case 'jobExperience':
        return data.openJobExperienceBlock
      case 'educationForJob':
        return data.openEducationForJobBlock
      case 'certificationsForJob':
        return data.openCertificationsForJobBlock
      default:
    }
  }

  const optionsList: any = {
    educationLevel: educationLevelOptions,
    majorsJob: [
      { label: 'A specific education level is not required', value: 'levelNotRequired' },
      { label: 'Any higher education degree is acceptable', value: 'anyHigherEducation' },
      { label: 'Any higher education major and minor are acceptable  ', value: 'majorAndMinor' },
    ]
  }

  switch (data.type) {
    case 'input':
    case 'textarea':
      return <div className="d-flex flex-wrap justify-content-center inputs-wrap">
        {data.item.answers.map((element: any, index: number) => <Field
          as={Input}
          key={index + 'answer' + element.name}
          name={data.item.name}
          type={data.item.type}
          m={data.index > 0 ? '2px 0 0' : ''}
          className='w-100'
          error={data.errors && data.touched[data.item.name] && data.errors[data.item.name] ? true : false}
          onChange={data.handleChange}
          maxLength={data.item.maxLength || 5000}
          value={data.values[data.item.name]}
          labelText={<div className='d-flex justify-content-between'>
            <span>{element.labelTitle}</span>
            <span className='optional'>{element.labelSubtitle}</span>
          </div>} />)}
      </div>
    case 'select':
      return <div className="d-flex flex-wrap justify-content-center inputs-wrap">
        {data.item.answers.map((element: any, index: number) => <Field
          key={index + 'answer' + element.name}
          name={element.name || data.item.name}
          m={index > 0 ? '24px 0 0' : ''}
          className='w-100'
          setFieldTouched={data.setFieldTouched}
          options={[{ value: 'some', label: 'string' }]}
          component={SelectCom}
          error={data.errors && data.touched[element.name || data.item.name] && data.errors[element.name || data.item.name] ? true : false}
          isMulti={true}
          labelText={<div className='d-flex justify-content-between'>
            <span>{element.labelTitle}</span>
            <span className='optional'>{element.labelSubtitle}</span>
          </div>} />
        )}
      </div>
    case 'radio-long':
      return <div className="d-flex flex-wrap justify-content-center answer-wrap">
        {data.item.answers.map((answer: any, index: number) =>
          <RadioBtn
            key={index + 'answer' + answer.value}
            long={data.type === 'radio-long' ? true : false}
            text={answer.title}
            subtitle={answer.subtitle ? answer.subtitle : ''}
            activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[data.item.name] ? true : false} />}
            name={data.item.name}
            value={answer.value}
            checked={answer.value === data.values[data.item.name] ? true : false}
            handleChange={data.handleChange} />
        )}
      </div>
    case 'radio-inputs':
      return <div className="d-flex flex-wrap justify-content-center answer-wrap">
        <RadioBtn
          onClick={() => setOpenBlock(data.item.name, true)}
          text={'Yes'}
          activeIcon={<ActiveIcon name={'yes'} checked={data.values[data.item.name] && data.values[data.item.name] !== 'no' ? true : false} />}
          setFieldTouched={data.setFieldTouched}
          name={data.item.name}
          value={'yes'}
          checked={data.values[data.item.name] && data.values[data.item.name] !== 'no' ? true : false}
          handleChange={data.handleChange} />
        <RadioBtn
          onClick={() => setOpenBlock(data.item.name, false)}
          text={'No'}
          activeIcon={<ActiveIcon name={'no'} checked={data.values[data.item.name] === 'no' ? true : false} />}
          name={data.item.name}
          value={'no'}
          checked={data.values[data.item.name] === 'no' ? true : false}
          handleChange={data.handleChange} />
        {
          isBlockOpen(data.item.name) && <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end"> {data.item.answers.map((element: answerType, index: number) => {
            const isFieldExperienceFrom = element.name === 'experienceYearsFrom';
            const maxValue = isFieldExperienceFrom && data.values.experienceYearsTo;
            return <Col key={index + 'answer' + element.name} md={data.item.name === 'jobExperience' ? 6 : 12} className='px-0'>
              {
                (element.type === 'text' || element.type === 'number') ? <Field
                  component={isFieldExperienceFrom && SalaryField}
                  as={Input}
                  maxValue={maxValue}
                  fieldNameTo={'experienceYearsTo'}
                  onBlur={() => data.setFieldTouched('experienceYearsTo')}
                  name={element.name}
                  type={element.type}
                  className={index === 1 ? 'job-experience' : ''}
                  placeholder={element.placeholder}
                  w={data.item.name === 'jobExperience' ? '96%' : '100%'}
                  value={data.values[element.name]}
                  onChange={data.handleChange}
                  error={data.errors && data.errors[element.name]}
                  labelText={<div className='d-flex justify-content-between'>
                    <span>{element.labelTitle}</span>
                    <span className='optional'>{element.labelSubtitle}</span>
                  </div>} />
                  : element.type === 'select' ?
                    <Field
                      m={'0 0 15px'}
                      name={element.name}
                      disabled={element.name === 'educationLevel' && data.values.educationDegree.includes('acceptable')}
                      isMulti
                      setFieldTouched={data.setFieldTouched}
                      error={data.errors && data.touched[element.name] && data.errors[element.name] ? true : false}
                      options={optionsList[element.name] ? optionsList[element.name] : [{ value: '', label: '' }]}
                      labelText={<div className='d-flex justify-content-between'>
                        <span>{element.labelTitle}</span>
                        <span className='optional'>{element.labelSubtitle}</span>
                      </div>}
                      component={SelectCom}
                    />
                    : element.type === 'tags' ?
                      <Field
                        as={Tags}
                        disabled={element.name === 'majorsJob' && data.values.educationMajor.includes('acceptable')}
                        m={'0 auto'}
                        name={element.name}
                        tags={data.values[element.name]}
                        setTegs={(tags: Array<string>) => { data.setFieldValue(element.name, tags) }}
                        error={data.errors && data.errors[element.name] ? true : false}
                        labelText={<div className='d-flex justify-content-between'>
                          <span>{element.labelTitle}</span>
                          <span className='optional'>{element.labelSubtitle}</span>
                        </div>}
                      />
                      : ''
              }
              {(element.name === 'educationDegree' || element.name === 'educationMajor') && <Checkbox
                className='d-inline-block'
                m={element.name === 'educationDegree' ? '0 0 24px' : '15px 0 24px'}
                value={'acceptable'}
                name={element.name}
                labelText={element.labelTitle}
                handleChange={data.handleChange}
                checked={data.values[element.name].includes('acceptable')}
              />}
            </Col>
          })}
          </div>
        }
      </div>
    case 'tags':
      return <div>{
        data.item.answers.map((element: answerType, index: number) => <Field
          m={index > 0 ? '24px auto 0' : '40px auto 0'}
          key={index + data.item.name}
          as={Tags}
          name={element.name || data.item.name}
          tags={data.values[element.name || data.item.name]}
          setTegs={(tags: Array<string>) => { data.setFieldValue(element.name || data.item.name, tags) }}
          error={data.errors && data.touched[element.name || data.item.name] && data.errors[element.name || data.item.name] ? true : false}
          setFieldTouched={data.setFieldTouched}
          labelText={<div className='d-flex justify-content-between'>
            <span>{element.labelTitle}</span>
            <span className='optional'>{element.labelSubtitle}</span>
          </div>}
        />)}</div>
    case 'file':
      return <JobDescriptionStep />
    case 'languages':
      return <LanguagesFieldArray
        values={data.values}
        handleChange={data.handleChange}
        errors={data.errors.languages}
        setFieldTouched={data.setFieldTouched}
        touched={data.touched.languages}
      />
    default:
  }
}