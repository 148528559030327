import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const ItemWrap = styled('div')`
  margin: ${theme.offsets.md};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 32px;
  padding: ${theme.offsets.extraBig};
  width: calc( 33.3% - 24px);
  svg{
    width: 120px;
    height: 120px;
    margin-bottom: 94px;
  }
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.xxl};
    border-radius: ${theme.variables.borderRadiusXLarge};
    svg{
      width: 80px;
      height: 80px;
      margin-bottom: 58px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.extraBig};
    width: calc( 50% - 24px);
    svg{
      margin-bottom: ${theme.offsets.extraMajor};
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.xxl};
    width: 100%;
    svg{
      width: 64px;
      height: 64px;
      margin-bottom: ${theme.offsets.big};
    }
  }
`

export const ListWrap = styled('div')`
  display: flex;
  margin: -${theme.offsets.md};
  flex-wrap: wrap;
`

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.major};
  line-height: 44px;
  margin-bottom: ${theme.offsets.xxl};
  width: 60%;
  @media(max-width: ${theme.screens.desktop}) {
    width: 80%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
    line-height: ${theme.fontSizes.major};
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 100%;
    font-size: ${theme.fontSizes.xxl};
    line-height: ${theme.fontSizes.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    line-height: ${theme.fontSizes.big};
    margin-bottom: ${theme.offsets.sm};
  }
`

export const Description = styled('p')`
  font-size: 18px;
  width: 80%;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    line-height: ${theme.fontSizes.big};
    margin-bottom: ${theme.offsets.md};
    width: 100%;
  }
`

export const CountNumber = styled('p')`
  color: ${theme.colors.gray};
  font-size: 14px;
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.md};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.xs};
  }
`