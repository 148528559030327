import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div') <{ isPanel?: boolean }>`
  font-family: ${(props) => props.isPanel ? 'Apercu Pro' : 'Recoleta'}; 
  background-color: ${theme.colors.white};
  border-radius: ${theme.variables.borderRadiusXLarge};
  width: 100%;
  padding: 40px ${theme.offsets.extraBig};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.xxl};
    border-radius: ${theme.variables.borderRadiusLarge};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.lg};
  }
`

export const WrapperSection = styled(Wrapper)`
    margin-bottom: ${theme.offsets.xxl};
  
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.md};
  }

`

export const Title = styled('p')`
  font-weight: 500;
  font-size: ${theme.fontSizes.big};
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.xl};
  }
`

export const MainText = styled('p')`
  font-family: Apercu Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
    line-height: 16px;
  }
`

export const Subtitle = styled('p')`
  color: ${theme.colors.gray};
  font-size: 14px;
  margin-bottom: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.md};
  }
`

export const FieldBox = styled('div')`
  margin: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    margin: ${theme.offsets.xs};
  }
`

export const FieldsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    margin: -${theme.offsets.xs};
  }
`

export const Separator = styled('div')`
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.lightGray};
  margin: ${theme.offsets.sm} ${theme.offsets.sm} ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktop}) {
    margin: ${theme.offsets.xs} ${theme.offsets.xs} ${theme.offsets.xxl};
  }
`

export const WindowContentWrap = styled('div')`
  margin-top: 40px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ButtonsWrap = styled('div')`
display: flex;
justify-content: end;
  button{
    max-width: 175px;
    margin-left: ${theme.offsets.md};
  }
`

export const OpenWindowButton = styled('p') <{ isPanel?: boolean }>`
  color: ${(props) => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
  font-weight: 500;
  margin-top: ${theme.offsets.lg};
  cursor: pointer;
`

export const SubmitButtonWrap = styled('p')`
  max-width: 145px;
  margin-top: ${theme.offsets.xxl};
  margin-left: auto;
  margin-right: 0;
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 100%;
  }
`

export const ErrorText = styled('div')`
  height: 12px;
  margin: ${theme.offsets.xs} ${theme.offsets.sm} ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 10px;
  width: 100%;
`