import ReactTagInput from "@pathofdev/react-tag-input";
import { PropsType } from './Tags.props';
import { StyleForWrap, StyleForLabel } from './Tags.presents';
import React from "react";
import useGetDevice from "../../../../hooks/useGetDevice";
import { isIOSCheck } from "../../../../services/helper";

export const Tags = (props: PropsType) => {
  const isDesktop = useGetDevice();
  const isIOS = isIOSCheck();
  const handleChange = (tags: Array<any>) => {
    props.setTegs(tags);
  }

  return <StyleForWrap m={props.m} error={props.error} disabled={props.disabled}>
    {props.labelText && <StyleForLabel>{props.labelText || 'Press enter'}</StyleForLabel>}
    <ReactTagInput
      tags={props.tags || []}
      onChange={handleChange}
      editable={true}
      readOnly={props.disabled}
      validator={(value) => value.trim().length > 0}
      placeholder={!isDesktop && isIOS ? "Type and press return" : "Type and press enter"}
    />
  </StyleForWrap>
}