import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Container = styled('div')<{isDesktop: boolean}>`
  max-width: 850px;
  width: 100%;
  margin: 0 9px;
  ${(props)=>!props.isDesktop ? `
    max-width: 100%;
    margin: 0;
  `:`
    flex: 6;
  `}
`;

export const SummaryText = styled('div')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin-top: ${theme.offsets.xxl};
  };
`;

export const AboutCandidateText = styled(SummaryText)`
  margin-top: 0;
`;

export const DownloadFile = styled('a')`
  font-family: Apercu Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #0083FF;
`

export const AboutCandidateContainer = styled('div') <{ isLastItem: boolean, isFirstItem: boolean }>`
  display: flex;
  width: 100%;
  flex: 1;
  svg{
    margin-top: 14px;
    margin-right: ${theme.offsets.sm};
    width: 24px;
    height: 24px;
  };
  ${props => !props.isLastItem && `
    padding-bottom: ${theme.offsets.lg};
    border-bottom: 1px solid rgba(45, 53, 61, 0.15);
  `}
  ${props=>props.isFirstItem && `
    margin-top: ${theme.offsets.lg};
    @media(max-width: ${theme.screens.mobile}) {
      margin-top: ${theme.offsets.sm};
    };
  `}
`;

export const AboutCandidateSection = styled('div')`
  margin-top: ${theme.offsets.lg};
`;

export const SkillsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.offsets.xs};
`;

export const SkillsSection = styled('div')`
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xxl};
  };
`;

export const TechnicalSkillsContainer = styled(SkillsContainer)`
  padding-bottom: ${theme.offsets.lg};
  border-bottom: 1px solid rgba(45, 53, 61, 0.15);
`

export const ExpertisesContainer = styled(SkillsContainer)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.offsets.lg};
  border-bottom: 1px solid rgba(45, 53, 61, 0.15);
`
export const ExpertisesContainerHeader = styled('div')`
  display: flex;
  align-items: center;
`

export const ExpertisesSkillsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

export const ExpertisesContainerHeaderIconWrapper = styled('div')`
  margin-right: 10px;
`


export const SkillsBlock = styled('div')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-radius: ${theme.variables.borderRadiusSmall};
  padding: ${theme.offsets.sm};
  margin: ${theme.offsets.xs};
`;

export const TechnicalSkillsBlock = styled(SkillsBlock)`
  ${SkillsBlock};
  color: #187DFF;
  background-color: #EEF6FE;
`;

export const SocialSkillsBlock = styled(SkillsBlock)`
  ${SkillsBlock};
  color: #4D9447;
  background-color: #DFFADF;
`;

export const AdditionalText = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #656B72;
  margin: -${theme.offsets.sm} 0 ${theme.offsets.lg};
`;

export const EmploymentDot = styled('div')`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #B0B0B0;
  position: absolute;
  left: -23px;
  top: -4px;
`;

export const EmploymentLine = styled('div')`
  width: 1px;
  background-color: #B0B0B0;
  position: absolute;
  left: -20px;
`;

export const EmploymentItemWrap = styled("div")`
  display: flex;
  align-items: center;
  position: relative;
`;

export const EmploymentAdditionalItem = styled("div") <{ isLastItem: boolean }>`
  margin-bottom: ${props => props.isLastItem ? "0px" : theme.offsets.xxl};
`;

export const PreferencesWrap = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.offsets.extraBig};
  justify-content: space-between;
  margin: ${theme.offsets.md} 0 ${theme.offsets.lg};
  padding-bottom: ${theme.offsets.md};
  border-bottom: 1px solid rgba(45, 53, 61, 0.15);
`;

export const PreferencesItem = styled("div")`
  display: flex;
  width: 50%;
  margin: ${theme.offsets.xl} 0;
  svg{
    width: 44px;
    height: 44px;
  };
  @media(max-width: ${theme.screens.mobile}) {
    width: 100%;
    margin: ${theme.offsets.sm} 0;
    svg{
      width: 36px;
      height: 36px;
    };
  };
`;


export const PreferencesContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.offsets.lg};
  width: fit-content;
`;

export const PreferencesTitle = styled("p")`
  font-size: 14px;
  color: #656B72;
  margin-bottom: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
    margin-bottom: ${theme.offsets.xs};
  };
`;

export const PreferencesTextWrap = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

export const PreferencesText = styled("span")`
  font-size: ${theme.fontSizes.lg};
  margin-right: ${theme.offsets.xs};
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
  };
`;

export const ImportantThingsBlock = styled("div") <{ color: string }>`
  border-radius: 100%;
  width: 184px;
  height: 184px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: ${(props) => props.color === '#FFC736' ? "#39434D" : props.color};
  margin: 0 ${theme.offsets.sm};
  text-align: center;
  flex-direction: column;
  svg{
    margin-top: 56px;
    margin-bottom: ${theme.offsets.sm};
  }
  span{
    width: 70%;
  }
  ::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-color: ${(props) => props.color};
    border-radius: 100%;
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 164px;
    height: 164px;
    font-size: 14px;
    svg{
      margin-top: 42px; 
    }
  };
  @media(max-width: ${theme.screens.mobile}) {
    width: 124px;
    height: 124px;
    font-size: ${theme.fontSizes.md};
    margin: ${theme.offsets.sm};
    span{
      width: 80%;
    }
    svg{
      margin-top: 33px; 
      width: 33px;
      height: 33px;
    }
  };
`;

export const ImportantThingsWrap = styled("div")`
  display: flex;
  justify-content: space-between;
  margin: 0 -${theme.offsets.sm};
  @media(max-width: ${theme.screens.mobile}) {
    width: 85vw;
    flex-wrap: wrap;
    justify-content: start;
  };
`;

export const InformationContainer = styled("div")`
  flex: 1;
`;

