import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { theme } from '../../../../theme';



export const MediaModalWrapper = styled(Modal)`
z-index: 9999;
  .modal-dialog {
    margin: 0; 
    min-width: 100vw;
  }
  
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
    padding: ${theme.offsets.xxl} 120px;
    @media(max-width: ${theme.screens.desktopLarge}) {
      padding: ${theme.offsets.xxl} 30px;
    }
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.xxl};
    }
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.md};
    }
  };

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`

export const MediaModalContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
`

export const CloseButtonWrapper = styled('div')`
  position: absolute;
  z-index: 1;
`

export const MediaModalContent = styled('div')`
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktopLarge}) {
    padding: 0 ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.md};
  }

`

export const MediaCounter = styled('p')`
  font-family: Apercu Pro;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: ${theme.offsets.extraBig};
`

export const MediaTitle = styled(MediaCounter)`
  width: 100%;
  text-align: center;
  margin-top: ${theme.offsets.xxl};
  margin-bottom: 0;
`

export const MediaImage = styled('img')`
  border-radius: 20px;
  max-width: 100%;
  max-height: 420px;
`

export const MediaControlButton = styled('button')`
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  border: 1px solid ${theme.colors.mediumGray};

  :hover, :active {
    border-color: ${theme.colors.darkGray};
  }

  @media(max-width: ${theme.screens.mobile}) {
    width: 32px;
    height: 32px;
  }
`
export const VideoPlayerContainer = styled('div')`
  width: 100%;
  flex: 1;
  border-radius: 20px;
  overflow: hidden;
`

export const AudioPlayerContainer = styled('div')`
  padding: ${theme.offsets.xxl};
  width: 100%;
  flex: 1;
  background: #FFFFFF;
  box-shadow: -5px 5px 20px rgba(63, 71, 79, 0.05), 5px -5px 20px rgba(63, 71, 79, 0.05);
  border-radius: 20px;
`


