import { observer } from "mobx-react-lite";
import { JobPreviewCard } from "./JobPreviewCard";

export const JobsList = observer((props: { jobListData: Array<any> }) => {
  const { jobListData } = props;
  return <div>

    {jobListData.map((item: any, index: number) => <JobPreviewCard jobData={item} key={`jobCard${index}`} />)}

  </div>
})