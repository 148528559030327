import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled('div')`
position: relative;
::before{
  content: '';
  display: block;
  height: 1px;
  background: ${theme.colors.white};
  width: 100%;
  position: absolute;
  top: 0px;
}
& button{
  margin-right: ${theme.offsets.lg};
  margin-top: ${theme.offsets.lg};
  width: auto;
  padding-left: ${theme.offsets.lg};
  padding-right: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xs} ${theme.offsets.lg};
    display: flex;
    white-space: pre-wrap;
    align-items: center;
    height: fit-content;
    min-height: 34px;
  }
  svg{
    margin-left: ${theme.offsets.sm};
    @media(max-width: ${theme.screens.tablet}) {
      width: 16px;
      height: 16px;
    }
  }
}
`

export const BenefitsSection = styled('div')`
  margin-bottom: ${theme.offsets.xxl};
  padding-top: ${theme.offsets.xxl};
  border-top: 1px solid ${theme.colors.lightGray};
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg};
    padding-top: ${theme.offsets.lg};
  }
`

export const LabelWrap = styled('div')`
  font-weight: 500;
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg};
  }
  span{
    font-size: ${theme.fontSizes.xxl};
    line-height: ${theme.fontSizes.big};
    color: ${theme.colors.black};
    max-width: 85%;
    @media(max-width: ${theme.screens.mobile}) {
      font-size: ${theme.fontSizes.lg};
      line-height: ${theme.fontSizes.xl};
      max-width: 80%;
    }
  }
`



