import { Field, FieldArray, Formik } from "formik";
import { useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, LabelWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { Datepicker } from "../../../../common/DatePicker";
import { Checkbox } from "../../../../common/Checkbox";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialMembership } from "../../../../../state/stores/resumeBuilder";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const Membership = ({
  isDesktop,
  closeBlockHandler
}: {
  isDesktop: boolean;
  closeBlockHandler: () => void;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  const initialValues = { membership: !!resumeBuilderStore.membership.length ? resumeBuilderStore.membership : [initialMembership] };

  return <CandidateFormCard
    isDesktop={isDesktop}
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Membership'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            membership: Yup.array()
              .of(
                Yup.object().shape({
                  organizationName: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveMembership(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="membership">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };

                return <div>
                  {values.membership.map((item: any, index: number) => {
                    const arrayErrors: any = errors.membership
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`membership${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional Membership</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`membership.${index}.organizationName`}
                          type={'text'}
                          value={values.membership[index].organizationName}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Organization Name'}
                          error={mainValidationCondition('organizationName')} />
                        <ErrorMsg>{mainValidationCondition('organizationName') ? arrayErrors[index].organizationName : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`membership.${index}.position`}
                          type={'text'}
                          value={values.membership[index].position}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Position'}
                          error={mainValidationCondition('position')} />
                        <ErrorMsg>{mainValidationCondition('position') ? arrayErrors[index].position : ''}</ErrorMsg>
                      </FieldWrap>

                      <MultiFieldWrap>
                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`membership.${index}.startDate`}
                              as={Datepicker}
                              value={values.membership[index].startDate ? new Date(values.membership[index].startDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`membership.${index}.startDate`, `${value}`)}
                              label={'Start Date'}
                              maxDate={new Date()}
                              showMonthYearPicker
                            />
                            <ErrorMsg>{mainValidationCondition('startDate') ? arrayErrors[index].startDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`membership.${index}.endDate`}
                              as={Datepicker}
                              value={values.membership[index].endDate ? new Date(values.membership[index].endDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`membership.${index}.endDate`, `${value}`)}
                              label={'End Date'}
                              maxDate={new Date()}
                              minDate={values.membership[index].startDate && new Date(values.membership[index].startDate)}
                              showMonthYearPicker
                              isDisabled={values.membership[index] && values.membership[index].isMembershipOngoing}
                            />
                            <ErrorMsg>{mainValidationCondition('endDate') ? arrayErrors[index].endDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                      </MultiFieldWrap>

                      <FieldWrap>
                        <Field
                          as={Checkbox}
                          checked={values.membership[index] && values.membership[index].isMembershipOngoing}
                          name={`membership.${index}.isMembershipOngoing`}
                          labelText={`Membership Ongoing`}
                          value={true}
                          handleChange={(value: boolean) => { onChangeHandler(value); setFieldValue(`membership.${index}.endDate`, '') }}
                        />
                        <ErrorMsg>{mainValidationCondition('isMembershipOngoing') ? arrayErrors[index].isCurrentJob : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`membership.${index}.description`}
                          type={'textarea'}
                          value={values.membership[index].description}
                          onChange={onChangeHandler}
                          maxLength={500}
                          labelText={<LabelWrap>Description<span>optional</span></LabelWrap>}
                          error={mainValidationCondition('description')} />
                        <ErrorMsg>{mainValidationCondition('description') ? arrayErrors[index].description : ''}</ErrorMsg>
                      </FieldWrap>
                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialMembership)}>
                      Add Another Membership
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveMembership({ membership: [] }, candidateStore.uid);
                await resumeBuilderStore.setMembership([]);
                handleReset();
                closeBlockHandler();
              }}
            />}
          </form>}
      </Formik>

    </ContentWrap>
  </CandidateFormCard>
}