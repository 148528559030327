import { theme } from '../../../../theme';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';


export const ModalHeader = styled('div')`
  margin-bottom: 48px;
  display: flex;
  align-items: center;
`

export const MediaModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: ${theme.variables.borderRadiusLarge};
    padding: ${theme.offsets.xxl};
    
    .modal-body {
      padding: 0;
    }
  }
`
export const Content = styled('div')`
  text-align: center;
  margin-bottom: ${theme.offsets.xxl};
`

export const Text = styled('p')`
  margin-top: ${theme.offsets.extraBig};
  margin-bottom: ${theme.offsets.xxl};
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  font-family: Apercu Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`

export const CloseButton = styled('button')`
  margin-left: auto;
  background: transparent;
  border: none;
  svg {
    height: 14px;
    width: 14px;
  }
`
