import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../theme";

export const MobileHeaderForCandidate = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.white};
  padding: ${theme.offsets.xxl} ${theme.offsets.md};
`

export const BackBtn = styled(Link)`
  font-size: 14px;
  color: ${theme.colors.black};
  text-transform: capitalize;
  svg{
    margin-right: ${theme.offsets.sm};
  }
`
export const BtnText = styled('span')`
  margin-left: ${theme.offsets.xs};
`
export const BtnsWrap = styled('div')`
  display: flex;
  button{
    margin-left: ${theme.offsets.md};
    display: flex;
    align-items: center;
    padding: 0 ${theme.offsets.md};
  }
  @media(max-width: ${theme.screens.mobile}) {
    button{
      padding: ${theme.offsets.md};
      border: none;
    }
    span{
      display: none;
    }
  }
`