import { BenefitItem } from "./BenefitItem";
import { ListWrap } from "./Benefits.presents";
import { BenefitsMap } from "./helper";

export const BenefitsList = () => {
  return <ListWrap>
    {BenefitsMap.map((item, index) => <BenefitItem
      key={`${index}Benefit`}
      index={index}
      icon={item.icon}
      title={item.title}
      description={item.description}
    />)}
  </ListWrap>
}