export const BookIcon = ({ active }: { active?: boolean }) => {

  return <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 13H7H8V39L17.5 39.5L26 42.5V44H3V13Z" fill="#F8DDC3" />
    <path d="M49 13H45H44V39L34.5 39.5L26 42.5V44H49V13Z" fill="#F8DDC3" />
    <path d="M18 8.5L26 13L35 8.5L44 8V38.8117L30.7202 41L26 43L21 41L8.5 38.8117V8L18 8.5Z" fill="white" />
    <path d="M8.03192 7.0011C7.77799 7.02453 7.54221 7.14014 7.37093 7.32519C7.19966 7.51024 7.10529 7.75134 7.10639 8.00107V12.001H3.02129C2.98939 11.9995 2.95744 11.9995 2.92554 12.001C2.67161 12.0244 2.43582 12.14 2.26455 12.325C2.09328 12.5101 1.99891 12.7512 2.00001 13.0009V44C2.00004 44.2652 2.10764 44.5196 2.29916 44.7071C2.49068 44.8946 2.75043 45 3.02129 45H48.9787C49.2496 45 49.5093 44.8946 49.7008 44.7071C49.8924 44.5196 50 44.2652 50 44V13.0009C50 12.7357 49.8924 12.4814 49.7008 12.2939C49.5093 12.1063 49.2496 12.001 48.9787 12.001H44.8936V8.00107C44.8936 7.73587 44.786 7.48154 44.5945 7.29401C44.4029 7.10649 44.1432 7.00113 43.8723 7.0011C37.6241 6.9961 31.2055 7.70092 26 11.2978C20.7945 7.70092 14.3759 6.99608 8.12767 7.0011C8.09577 6.99963 8.06382 6.99963 8.03192 7.0011ZM9.14894 9.03229C14.9446 9.10772 20.5532 9.89405 24.9787 13.0166V41.6095C20.2074 38.6837 14.0187 38.0933 9.14894 38.0315V9.03229ZM42.8511 9.03229V38.0315C37.9814 38.0933 31.7926 38.6837 27.0213 41.6095V13.0166C31.4468 9.89405 37.0554 9.10772 42.8511 9.03229ZM4.04256 14.0009H7.10639V39.0002C7.10642 39.2654 7.21403 39.5197 7.40555 39.7072C7.59707 39.8948 7.85682 40.0001 8.12767 40.0001C12.7933 40.0001 18.9416 40.5244 23.4149 43.0001H4.04256V14.0009ZM44.8936 14.0009H47.9575V43.0001H28.5851C33.0584 40.5244 39.2067 40.0001 43.8723 40.0001C44.1432 40.0001 44.4029 39.8948 44.5945 39.7072C44.786 39.5197 44.8936 39.2654 44.8936 39.0002V14.0009Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}

