import { PropsType } from './Skeleton.props';
import { Wrap, SkeletItem, MobileRow } from './Skeleton.presents';

export const Skeleton = (props: PropsType) => {
  const { isDesktop } = props;

  const SetList = () => {
    const itemArray = [];
    const maxItemsLength = isDesktop ? 4 : 6;
    for (let i = 0; i < maxItemsLength; i++) {
      itemArray.push(<SkeletItem isDesktop={isDesktop}>
        {!isDesktop && <MobileRow />}
      </SkeletItem>)
    }
    return itemArray;
  }

  return <Wrap>
    {SetList()}
  </Wrap>
}