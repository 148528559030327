import { PropsType } from './ForgotPass.props';
import { VerifEmail } from "../Steps/VerifEmail";
import { RestorePass } from "../Steps/RestorePass";
import { useHistory } from "react-router";

export const ForgotPass = (props: PropsType) => {
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(history.location.search);
  }
  const query = useQuery();
  const token = query.get("token")

  return  history.location.pathname === '/forgot-password/restore' && token ? <RestorePass token={token} /> : <VerifEmail />
}