import { colors } from '../../../../constants/colors';
import styled from 'styled-components';
import { IconType } from './RadioBtn.props';
import checkedIcon from '../../../../assets/img/checked.svg';

export const StyleForWrap = styled('div') <IconType>`
  display: inline-block;
  position: relative;
  margin: 16px;
  &:hover label {
    background-color: #E4E8EF;
    border: 2px solid #E4E8EF;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
  }
  label {
    padding:20px 0 12px;
    background: #F6F8FA;
    border-radius: 20px;
    width: 181px;
    min-height: 144px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #F6F8FA; 
    transition: 0s;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    ${props => props.long && `
      width: 287px;
      flex-direction: row;
      min-height: 94px;
      justify-content: center;
      align-items: center;
      background-position: 8% 50%;
      padding: 0 0 0 10px;
  `}
  ${props => props.empty && `
      width: 181px;
      min-height: 80px;
      justify-content: center;
      align-items: flex-end;
  `}
  }
  input:checked + label {
    background: #FDF9F5;
    border: 2px solid #E4954D;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    ${props => props.long && `
      background-position: 8% 50%;
  `}
  }
  input:checked + label::before{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background: #E4954D;
    border-radius: 100%;
    top: 8px;
    right: 8px;
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
  input[type=radio]:checked + label .iconWrap{
    background: #E4954D;
  }
  label:hover {
    color: #666;
  }
  label .title-wrap{
    //padding:0px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88%;
    ${props => props.long && `
    padding:0px;
    width: 75%;
    align-items: start;
    margin-left: 12px;
  `}
  ${props => props.empty && `
    padding:0px;
    width: 100%;
  `}
  }
  label .title{
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: ${colors.dark};
    margin-top: 16px;
    width:75%;
    ${props => props.long && `
    width:70%;
    margin-top: 0px;
    text-align: left;
  `}
  ${props => props.empty && `
    margin-top: 0px;
  `}
  }
  label .subtitle{
    width:85%;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #928D8A;
    text-align: center;
    margin-top: 8px;
    ${props => props.long && `
    width:70%;
    height: auto;
    text-align: left;
  `}
  }
`