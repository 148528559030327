import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Title = styled('p')`
  font-weight: 500;
  font-size: 18px;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xxl};
  };
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.lg};
  };
`

export const Wrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -${theme.offsets.lg} 0;
  @media(max-width: ${theme.screens.tablet}) {
    margin: 0;
    flex-direction: column;
    justify-content: center;
  }
`

export const ButtonWrap = styled('div')`
  width: 163px;
  margin-right: -${theme.offsets.lg};
  button{
    font-size: ${theme.fontSizes.lg};
    @media(max-width: ${theme.screens.tablet}) {
      height: 50px;
  };
  @media(max-width: ${theme.screens.mobile}) {
      height: 38px;
      font-size: ${theme.fontSizes.md};
  };
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 196px;
    margin-top: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 125px;
    margin-top: ${theme.offsets.lg};
  }
`