import { colors } from './../../../constants/colors';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import arrowIcon from '../../../assets/img/arrow.svg';
import { theme } from '../../../theme';
import { Input } from '../../common/Input';

export const StyleForPhoneInput = styled(PhoneInput) <{ isDesktop: boolean }>`
input { 
  border: 1px solid #D1D7E3;
  border-radius: 10px;
  height: 52px;
  padding: 18px;
  margin-bottom: 8px;
  background-color: #fff!important;
  color: #C6C5C4;
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    font-size: 14px;
  } 
};
.PhoneInputCountry {
  max-width: ${(props) => props.isDesktop ? '122px' : '100px'};
  width: 100%;
  border: 1px solid #D1D7E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px ${theme.offsets.lg};
  justify-content: space-between;
  margin-bottom: ${theme.offsets.sm};
  margin-right: ${(props) => props.isDesktop ? theme.offsets.xxl : theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 80px;
  }  
  } 
.PhoneInputCountrySelectArrow{
  border:none;
  content: url(${arrowIcon});
  width: 16px;
  height: 9px;
  transform: unset;
}
`
export const StyleForLabel = styled("label")`
  font-size: 14px;
  margin-bottom: ${theme.offsets.sm};
  margin-top: ${theme.offsets.md};
  text-transform: capitalize;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
    font-size: ${theme.fontSizes.md};
  } 
  `

export const FormWrap = styled("div")<{isDesktop: boolean}>`
  max-width: 557px;
  width: 100%;
  form{
    margin-top: ${(props)=>props.isDesktop ? theme.offsets.major : theme.offsets.md};
  }
  .error input {
    border: 1px solid ${colors.red};
  }
`