import { OrganizationPreviewCard } from "../../common/Cards/OrganizationPreviewCard";
import {
  MainDescriptionCard,
  BenefitsWrap,
  JobTitle,
  JobDescription,
  MainBonusesWrap,
  ListItemCard,
  Wrapper,
  ApplyBtnWrap,
  SnapshotsSection,
  JobUpdating
} from "./Jobs.presents";
import { hiringPriorityStyles, renderBenefitSections, salaryBonuses, setSalaryBonuses, setSalaryString } from "./helper";
import { useTranslation } from "react-i18next";
import { setLocationsListItem } from "./helper";
import { Snapshots } from "./Snapshots";
import { SPPrimaryButton } from "../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import moment from "moment";

export const JobPreviewCard = ({ jobData }: { jobData: any }) => {
  const { t } = useTranslation();

  const goToJobStory = () => window.open(`/jobs?id=${jobData._id}`, "_blank");

  const stepsAnswers = jobData.steps;

  const hiringPriorityValue = stepsAnswers.jobStep.answers.jobStart.value;
  const hiringPriorityData = hiringPriorityStyles[hiringPriorityValue];

  const jobDescription = stepsAnswers.descriptionStep.answers.jobTrailer.value;

  const remoteJobValue = stepsAnswers.startStep.answers.doneRemotely;
  const isJobRemote = remoteJobValue && remoteJobValue.value !== 'no';

  const workShift = stepsAnswers.jobStep.answers.workShift;
  const otherShiftInf = stepsAnswers.jobStep.answers.otherShiftInf;
  const weeklyInfo = stepsAnswers.jobStep.answers.weeklyInfo;

  const locationsValue = stepsAnswers.startStep.answers.locations.value;

  return <Wrapper>
    <OrganizationPreviewCard isDesktop={true}>
      <JobUpdating>
        {moment(jobData.updatingDate).fromNow()}
      </JobUpdating>
      <BenefitsWrap>
        {/* Hiring Priority Section*/}
        {hiringPriorityData && <MainDescriptionCard bgColor='#FFE9E9' color='#DD6363'>
          {hiringPriorityData.icon}
          <span>
            {hiringPriorityData.title}
          </span>
        </MainDescriptionCard>}

        {/* Main Benefits Sections */}
        {renderBenefitSections(stepsAnswers.specificationStep.answers)}
      </BenefitsWrap>

      <JobTitle onClick={goToJobStory}>
        {jobData.jobTitle}
      </JobTitle>

      <MainBonusesWrap>
        {setSalaryString(stepsAnswers.specificationStep.answers)}
        {salaryBonuses.map(item => setSalaryBonuses(stepsAnswers.specificationStep.answers, item.bonusName, item.text))}
        {isJobRemote && < ListItemCard>{t(`jobStory.doneRemotely.${remoteJobValue.value}`)}</ListItemCard>}
        {workShift && <ListItemCard> {t(`jobStory.workShift.${workShift.value}`)}</ListItemCard>}
        {(otherShiftInf && otherShiftInf.value) && <ListItemCard>{t(`jobStory.otherShiftInf.${otherShiftInf.value}`)}</ListItemCard>}
        {(weeklyInfo && weeklyInfo.value) && <ListItemCard>{t(`jobStory.weeklyInfo.${weeklyInfo.value}`)}</ListItemCard>}
        {setLocationsListItem(locationsValue)}
      </MainBonusesWrap>

      <JobDescription>
        {jobDescription}
      </JobDescription>

      <SnapshotsSection>
        <Snapshots stepsAnswers={stepsAnswers} />
        <ApplyBtnWrap>
          <SPPrimaryButton type="button" width="100%" onClick={goToJobStory}>
           Learn More
          </SPPrimaryButton>
        </ApplyBtnWrap>
      </SnapshotsSection>

    </OrganizationPreviewCard>
  </Wrapper>
}