import styled from "styled-components";
import { theme } from "../../../theme";

export const Wrapper = styled('div')`
  background: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${theme.offsets.lg};
  padding-bottom: ${theme.offsets.extraBig};
`

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 40px;
  margin-top: ${theme.offsets.extraMajor};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
    margin-top: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
  }
`
export const Subtitle = styled('p')`
  font-weight: 400;
  font-size: ${theme.offsets.xl};
  margin-top: ${theme.offsets.xxl};
  color: #39434D;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.lg};
    margin-top: ${theme.offsets.xl};
  }
`

export const VideoContainer = styled('div')`
  max-width: 848px;
  width: 100%;
  background: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  padding: ${theme.offsets.xxl} ${theme.offsets.extraBig} ${theme.offsets.extraBig};
  margin: ${theme.offsets.xxl} auto;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 738px;
    margin: ${theme.offsets.lg} auto;
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 596px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 100%;
    padding: ${theme.offsets.lg};
  }
`

const videoUploadStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 270px;
  width: 100%;
  border-radius: ${theme.variables.borderRadiusXLarge};
  position: relative;
  overflow: hidden;
  @media(max-width: ${theme.screens.desktopXXL}) {
    min-height: 200px;
    button{
      font-size: ${theme.fontSizes.md};
      max-width: 202px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    min-height: 220px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    min-height: 103px;
    padding: ${theme.offsets.md};
  }
  button{
    max-width: 252px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    @media(max-width: ${theme.screens.desktopXXL}) {
      font-size: ${theme.fontSizes.md};
      max-width: 202px;   
    }
    @media(max-width: ${theme.screens.mobile}) {
      max-width: 174px;   
    }
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    input[type=file]::-webkit-file-upload-button {
    display: none;
  }
    svg{
      margin-right: ${theme.offsets.sm};
    }
  };
`
export const RecordingBlock = styled('div')`
  ${videoUploadStyles}
  border: 1px solid rgba(45, 53, 61, 0.3); 
`

export const UploadingBlock = styled('div')`
  ${videoUploadStyles}
  border: 1px dashed rgba(45, 53, 61, 0.3);
  flex-direction: column;
`

export const Separator = styled('div')`
  font-size: 18px;
  margin: ${theme.offsets.extraBig} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::before, ::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #D1D7E3;
    width: 45%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.xxl} 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} 0;
  }
`

export const VideoPlayerWrap = styled('div')`
  max-width: 1137px;
  width: 100%;
  overflow: hidden;
  margin-top: ${theme.offsets.xxl};
  position: relative;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 738px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 100%;
  }
  video{
    border-radius: ${theme.variables.borderRadiusSmallMedium};
  }
`

export const RemoveVideoBtn = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  border: none;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  padding: ${theme.offsets.lg} ${theme.offsets.extraBig};
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  position: absolute;
  top: ${theme.offsets.xxl};
  right: ${theme.offsets.xxl};
  z-index: 2;
  svg{
    width: 16px;
    height: 16px;
    margin-right: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    line-height: 14px;
    padding: ${theme.offsets.md};
    border-radius: ${theme.variables.borderRadiusSmall};
    svg{
      width: 13px;
      height: 13px;
      margin-right: ${theme.offsets.md};
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 0;
    line-height: 0;
    padding: ${theme.offsets.sm};
    border-radius: 100%;
    top: ${theme.offsets.sm};
    right: ${theme.offsets.sm};
    svg{
      width: 7px;
      height: 7px;
      margin-right: 0;
    }
  }
`

export const DeleteWindowContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const DeleteWindowDescription = styled('div')`
  font-size: ${theme.fontSizes.xxl};
  margin-top: ${theme.offsets.big};
  text-align: center;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
    margin-top: ${theme.offsets.lg};
  }
`

export const DeleteWindowButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button{
    max-width: 173px;
  }
`

export const VideoPreviewButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${theme.offsets.xxl};
  button{
    max-width: 173px;
    &:first-of-type{
      background-color: transparent;
      border: none;
    }
  }
  @media(max-width: ${theme.screens.desktop}) {
    button{
      max-width: 131px;
    } 
  }
  @media(max-width: ${theme.screens.mobile}) {
    flex-wrap: wrap;
    
    button{
      max-width: 100%;
      &:first-of-type{
        order: 2;
        margin-top: ${theme.offsets.lg};
    }
    } 
  }
`

export const PlayerWrap = styled('div')<{isResumeBuilder?: boolean, playerWrapperWidth?: number}>`
  width: 100%;
  max-height: ${props => props.isResumeBuilder ? `${props.playerWrapperWidth ? (props.playerWrapperWidth / 1.8) : 320}px` : '670px' };
  height: 100vh;
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0 10px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0;
  }
`

export const HelperTextWrap = styled('div')`
  text-align: end;
  margin-bottom: ${theme.offsets.sm};
  p{
    margin: ${theme.offsets.xs} 0;
  }
  span{
    margin-left: ${theme.offsets.sm};
  }
`

export const UploadingBlockDescription = styled('p')`
  font-size: 14px;
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktopXXL}) {
    margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }
`

export const InfoText = styled('p')`
  font-size: 14px;
  color: #656B72;
  margin-top: ${theme.offsets.sm};
  width: 100%;
  text-align: center;
`

export const BlockedDeviceWrap = styled('div')`
max-width: 316px;
display: flex;
flex-direction: column;
align-items: center;
padding: ${theme.offsets.lg} 0;
  div{
    display: flex;
  }
  span{
    text-align: center;
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 80%;
  }
`

export const BlockedDeviceIcon = styled('div')`
border: 1px solid ${theme.colors.lightGray};
border-radius: ${theme.variables.borderRadiusLarge};
height: 72px;
width: 72px;
display: flex;
align-items: center;
justify-content: center;
background-color: #F6F8FA;
margin: 0 ${theme.offsets.sm};
margin-top: ${theme.offsets.xxl};
  svg{
    width: 21px;
    object-fit: contain;
  }
`

export const SkipButtonWrap = styled('div')`
display: flex;
justify-content: end;
max-width: 848px;
width: 100%;
margin: 0 auto;
button{
  background-color: transparent;
  color: ${theme.colors.blue};
  max-width: 173px;
}
@media(max-width: ${theme.screens.desktop}) {
  max-width: 738px;
  button{
    max-width: 151px;
  }
}
@media(max-width: ${theme.screens.tablet}) {
  max-width: 596px;
}
@media(max-width: ${theme.screens.tablet}) {
  button{
    max-width: 121px;
  }
}
`

export const ErrorWindowContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  svg{
    object-fit: contain;
    @media(max-width: ${theme.screens.tablet}) {
      height: 125px;
    }
  }
`

export const ErrorTitle = styled('p')`
 font-size: ${theme.fontSizes.xxl};
 margin-top: ${theme.offsets.extraBig};
 @media(max-width: ${theme.screens.tablet}) {
  font-size: 18px;
  margin-top: ${theme.offsets.xl};
 }
`

export const ErrorWindowDescription = styled('p')`
  font-size: 18px;
  margin-top: ${theme.offsets.lg};
  text-align: center;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin-top: ${theme.offsets.md};
 }
`

export const ErrorWindowButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.offsets.lg};
  button{
    max-width: 173px;
  }
`

export const SaveButtonWrap = styled('div')<{isLoading?: boolean, isSaved?: boolean}>`
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
  ${props=>props.isSaved && `
    svg{
      margin-left: ${theme.offsets.sm}; 
    };
    div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}

  ${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`
