import { ReactComponent as ReferencesIcon } from "../../../assets/img/resumeBuilder/references.svg";
import { ReactComponent as MembershipIcon } from "../../../assets/img/resumeBuilder/membership.svg";
import { ReactComponent as LanguagesIcon } from "../../../assets/img/resumeBuilder/languages.svg";
import { ReactComponent as HonorsIcon } from "../../../assets/img/resumeBuilder/honors.svg";
import { ReactComponent as TrainingsIcon } from "../../../assets/img/resumeBuilder/trainings.svg";
import { ReactComponent as PortfolioIcon } from "../../../assets/img/resumeBuilder/portfolio.svg";
import { ReactComponent as NonprofitIcon } from "../../../assets/img/resumeBuilder/nonprofit.svg";
import { ReactComponent as PublicationIcon } from "../../../assets/img/resumeBuilder/publication.svg";
import { ReactComponent as WorkAuthorizationIcon } from "../../../assets/img/resumeBuilder/workAuthorization.svg";

export const optionalQuestionsMap = [
  { title: 'Add References', icon: <ReferencesIcon />, key: 'references' },
  { title: 'Add Languages', icon: <LanguagesIcon />, key: 'languages' },
  { title: 'Add Honors and Awards', icon: <HonorsIcon />, key: 'honors' },
  { title: 'Add Trainings & Courses', icon: <TrainingsIcon />, key: 'trainings' },
  { title: 'Add Portfolio', icon: <PortfolioIcon />, key: 'portfolio' },
  { title: 'Add Nonprofit and Volunteer Experience', icon: <NonprofitIcon />, key: 'nonprofit' },
  { title: 'Add Publication', icon: <PublicationIcon />, key: 'publication' },
  { title: 'Add Work Authorization', icon: <WorkAuthorizationIcon />, key: 'workAuthorization' },
  { title: 'Add Membership', icon: <MembershipIcon />, key: 'membership' },
];

export const initialOptionalBlocksMap = {
  references: false,
  membership: false,
  languages: false,
  honors: false,
  trainings: false,
  portfolio: false,
  nonprofit: false,
  publication: false,
  workAuthorization: false
};

export const socialNetworksList = [
  'Website',
	'LinkedIn',
	'Facebook',
	'Twitter',
	'Instagram',
	'YouTube',
	'Reddit',
	'Whatsapp',
	'Tiktok',
	'Dribbble',
	'Behance',
	'Pinterest',
	'Snapchat'
]