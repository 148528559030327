export const AnnualSalaryIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 14C6 10.6863 8.68629 8 12 8H40C43.3137 8 46 10.6863 46 14V18H6V14Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 19C4 18.4477 4.44772 18 5 18H47C47.5523 18 48 18.4477 48 19C48 19.5523 47.5523 20 47 20H5C4.44772 20 4 19.5523 4 19Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.5 2C36.0523 2 36.5 2.44772 36.5 3V11C36.5 11.5523 36.0523 12 35.5 12C34.9477 12 34.5 11.5523 34.5 11V3C34.5 2.44772 34.9477 2 35.5 2Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M17 2C17.5523 2 18 2.44772 18 3V11C18 11.5523 17.5523 12 17 12C16.4477 12 16 11.5523 16 11V3C16 2.44772 16.4477 2 17 2Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M5 41.5V20H47V38.5L41.5 37C40.1667 37.8333 37.4 39.6 37 40C36.6 40.4 35.1667 42.5 34.5 43.5V49H12.5L7.5 46.5L5 41.5Z" fill="#FDF9F5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.31594 8.98934C9.47775 6.98127 12.5319 6 16.1322 6H35.8893C39.4996 6 42.5548 6.98083 44.712 8.9903C46.8799 11.0097 48.0109 13.9467 47.9999 17.5869V38.3922C47.9999 42.0328 46.8632 44.9742 44.6937 46.9984C42.5345 49.0129 39.4796 50 35.8677 50H16.1322C12.5261 50 9.4697 48.9939 7.30889 46.9474C5.13992 44.8933 4 41.9109 4 38.2197V17.5862C4 13.9441 5.14316 11.0076 7.31594 8.98934ZM8.6771 10.4547C6.99682 12.0155 6 14.3722 6 17.5862V38.2197C6 41.4926 7.00007 43.9004 8.68415 45.4953C10.3764 47.098 12.8862 48 16.1322 48H35.8677C39.1296 48 41.6409 47.1113 43.3293 45.536C45.0075 43.9703 45.9999 41.6079 45.9999 38.3922V17.5862L45.9999 17.5831C46.0101 14.3682 45.0227 12.013 43.3488 10.4537C41.6638 8.88416 39.1529 8 35.8893 8H16.1322C12.8803 8 10.3683 8.88372 8.6771 10.4547Z" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="15.5" cy="27.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="15.5" cy="36.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="26" cy="27.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="26" cy="36.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <circle cx="36.5" cy="27.5" r="1.5" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M53 45C53 50.5228 48.5228 55 43 55C37.4772 55 33 50.5228 33 45C33 39.4772 37.4772 35 43 35C48.5228 35 53 39.4772 53 45Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M43 53C47.4183 53 51 49.4183 51 45C51 40.5817 47.4183 37 43 37C38.5817 37 35 40.5817 35 45C35 49.4183 38.5817 53 43 53ZM43 55C48.5228 55 53 50.5228 53 45C53 39.4772 48.5228 35 43 35C37.4772 35 33 39.4772 33 45C33 50.5228 37.4772 55 43 55Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M42.6165 40.4965V40.739C41.2068 40.8545 40.2481 41.709 40.2481 42.9446C40.2481 44.2494 41.015 44.8845 42.6617 45.3233V47.5982C42.0414 47.4827 41.5226 47.2171 40.9925 46.8245C40.891 46.7436 40.7556 46.6975 40.6203 46.6975C40.2707 46.6975 40 46.9746 40 47.3326C40 47.5751 40.1128 47.7598 40.3045 47.8868C40.9925 48.3834 41.7707 48.6952 42.6165 48.7875V49.5035C42.6165 49.7806 42.8421 50 43.1128 50C43.3835 50 43.5977 49.7806 43.5977 49.5035V48.8106C45.0301 48.6721 46 47.8291 46 46.5589C46 45.3233 45.2669 44.6189 43.5526 44.1801V41.963C43.9361 42.0554 44.3195 42.2286 44.7143 42.4711C44.8271 42.5404 44.9398 42.5751 45.0526 42.5751C45.4023 42.5751 45.6842 42.2979 45.6842 41.94C45.6842 41.6628 45.5263 41.4896 45.3459 41.3857C44.8271 41.0508 44.2632 40.8314 43.5977 40.7506V40.4965C43.5977 40.2194 43.3835 40 43.1128 40C42.8421 40 42.6165 40.2194 42.6165 40.4965ZM44.6692 46.6397C44.6692 47.194 44.2744 47.5635 43.5526 47.6443V45.5427C44.4211 45.8199 44.6692 46.1316 44.6692 46.6397ZM41.5789 42.8637C41.5789 42.3557 41.9398 41.963 42.6617 41.8938V43.9376C41.7932 43.649 41.5789 43.3487 41.5789 42.8637Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
