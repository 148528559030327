import { HeaderWrap, Logo, Content, ApplyBtn, ModalBtnText, ShareIcon, BtnsWrap, ModalBtn, ModalSpan, SaveIcon } from "./Header.presents";
import logo from '../../../../assets/img/logo.svg'
import { SPBaseButton } from "../../../common/buttons/SPBaseButton";
import shareIcon from '../../../../assets/img/jobStory/downloadIcon.svg';
import saveIcon from '../../../../assets/img/jobStory/save.svg';
import { PropsType } from './Header.props';
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { ShareModal } from "../ShareModal";
import { useState } from "react";
import { SaveModal } from "../Modals/SaveModal";

export const JobStoryHeader = (props: PropsType) => {
  const [isShareModalOpen, setShareShowModal] = useState(false);
  const [isSaveModalOpen, setSaveShowModal] = useState(false);

  return <HeaderWrap>
    <Content>
      <a href="https://sidepost.com">
        <Logo src={logo} />
      </a>
      <BtnsWrap>
        <ModalBtn>
          <SPBaseButton width={'100%'} onClick={() => setShareShowModal(true)}>
            <ModalBtnText>
              <ShareIcon src={shareIcon} alt="icon" />
              <ModalSpan>Share</ModalSpan>
            </ModalBtnText>
          </SPBaseButton>
        </ModalBtn>
        <ModalBtn>
          <SPBaseButton width={'100%'} onClick={() => setSaveShowModal(true)}>
            <ModalBtnText>
              <SaveIcon src={saveIcon} alt="icon" />
              <ModalSpan>Save For Later</ModalSpan>
            </ModalBtnText>
          </SPBaseButton>
        </ModalBtn>
        <ApplyBtn >
          <SPPrimaryButton width={'100%'} onClick={() => props.setOpenApplyWindow(true)}>Apply</SPPrimaryButton>
        </ApplyBtn>
      </BtnsWrap>
    </Content>

    {isShareModalOpen && <ShareModal isOpen={isShareModalOpen} onClose={() => setShareShowModal(false)} />}
    {isSaveModalOpen && <SaveModal isOpen={isSaveModalOpen} onClose={() => setSaveShowModal(false)} />}

  </HeaderWrap>
}