import { useEffect, useState } from 'react';
import { generateSignedUrl, s3upload } from '../../../services/api/fileManagement/fileManagement.domains';
import { PhotoItemContainer, PhotosList } from './UploadGallery.presents';
import { PhotoPreview } from './PhotoPreview/PhotoPreview';
import { PhotoUploadButton } from './PhotoUploadButton/PhotoUploadButton';
import { observer } from 'mobx-react-lite';
import { checkFileSizeInMB } from '../../../services/helper';
import { PhotoGalleryProps } from './UploadGallery.props';


export const PhotoUploadGallery = observer((props: PhotoGalleryProps) => {
  const { photoFiles, saveGalleryPhoto, setGalleryPhoto, deleteGalleryPhoto, unsetGalleryPhoto } = props;

  const [isLoading, setIsLoading] = useState(false)
  const [photos, setPhotos] = useState<Array<any>>([]);

  const MAX_PHOTO_SIZE = 25
  const MAX_PHOTOS_COUNT = 30
  const UPLOAD_FOLDER = "images"

  useEffect(() => {
    if (photoFiles && !isLoading) {
      const mappedPhotos = photoFiles.map((item: any) => {
        return { url: item.url, key: item.key, isLoading: false }
      })
      setPhotos(mappedPhotos)
    }
  }, [photoFiles, photoFiles?.length, isLoading])

  const uploadFile = async (event: any) => {
    if (!event.target.files) return

    let uploadFiles = event.target.files
    uploadFiles = [...uploadFiles].filter((file: any) => checkFileSizeInMB(file.size, MAX_PHOTO_SIZE))

    const uploadLimit = MAX_PHOTOS_COUNT - photos.length
    if (uploadFiles.length > uploadLimit) uploadFiles.length = uploadLimit

    const placeholders = uploadFiles.map(() => { return { url: "", isLoading: true } })
    const initialPhotos = [...photos]
    setPhotos([...photos, ...placeholders])
    setIsLoading(true)
    for (const [i, file] of uploadFiles.entries()) {
      const resp = await generateSignedUrl({ filename: file.name, filetype: file.type, folder: UPLOAD_FOLDER });
      if (!resp.data.err) {
        try {
          await s3upload(resp.data.data.url, file)
          //TODO: For JobStoryBuilder need to change on server part + includes jobId
          const addPhotoDto = {
            photoGallery: {
              key: resp.data.data.key,
              url: resp.data.data.fileUrl,
            }
          }
          await saveGalleryPhoto(addPhotoDto)
          setGalleryPhoto(addPhotoDto.photoGallery.key, addPhotoDto.photoGallery.url)
          placeholders[i] = { key: resp.data.data.key, url: resp.data.data.fileUrl, isLoading: false }
          setPhotos(() => ([...initialPhotos, ...placeholders]))
        } catch (error) {
          setPhotos([...photos])
        }
      }
    }
    event.target.value = null; // reset input value
    setIsLoading(false)
  }


  const removePhoto = async (fileIndex: number, file: any) => {
    if (isLoading) return
    setIsLoading(true)
    const copiedPhotos = [...photos];
    copiedPhotos[fileIndex].isLoading = true
    setPhotos(copiedPhotos);

    const removeFileDto = {
      fileData: {
        photoGallery: { key: file.key }
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: file.key
    }

    const resp = await deleteGalleryPhoto(removeFileDto)
    unsetGalleryPhoto(file.key)
    if (!resp.data.err) {
      copiedPhotos.splice(fileIndex, 1);
    } else {
      copiedPhotos[fileIndex].isLoading = false
    }
    setPhotos([...copiedPhotos]);
    setIsLoading(false)
  }


  return (
    <>
      <PhotosList>
        {
          !!photos.length && photos.map((item: any, index: number) =>
            <PhotoItemContainer key={index}>
              <PhotoPreview url={item.url} onRemove={() => removePhoto(index, item)} isLoading={item.isLoading} />
            </PhotoItemContainer>
          )
        }
      </PhotosList>
      {
        photos.length < 20
          ? <PhotoUploadButton
            showButton={props.showButton}
            text={props.btnText}
            multiple
            onChange={uploadFile}
            isLoading={isLoading}
            fileTypes="image/*"
            icon={props.icon}
          />
          : null
      }
    </>
  )
})
