import { ModalWindow } from "../../../../common/Modal";
import { DeleteWindowButtons, DeleteWindowContent, DeleteWindowDescription } from "./RemoveWindow.presents";
import { ReactComponent as DeleteOrangeIcon } from '../../../../../assets/img/delete.svg';
import { SPLightButton } from "../../../../common/buttons/SPLightButton/SPLightButton";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";

export const RemoveModalWindow = ({
  removeDataHandler,
  setIsRemoveWindowOpen,
  isRemoveWindowOpen,
}: {
  removeDataHandler: () => void;
  setIsRemoveWindowOpen: Function;
  isRemoveWindowOpen: boolean;
}) => {
  const removeVideoHandler = () => {
    removeDataHandler();
    setIsRemoveWindowOpen(false);
  }
  return <ModalWindow
    openWindow={isRemoveWindowOpen}
    closeWindow={() => setIsRemoveWindowOpen(false)}
    title="Delete">

    <DeleteWindowContent>
      <DeleteOrangeIcon />
      <DeleteWindowDescription>
        Are you sure want to delete this section?
      </DeleteWindowDescription>
    </DeleteWindowContent>

    <DeleteWindowButtons>
      <SPLightButton width="100%" type="button" onClick={() => setIsRemoveWindowOpen(false)}>
        Cancel
      </SPLightButton>
      <SPSecondaryButton width="100%" type="button" onClick={removeVideoHandler}>
        Delete
      </SPSecondaryButton>
    </DeleteWindowButtons>
  </ModalWindow>
}