import { flow, types } from "mobx-state-tree";
import {
  getOrganizationData,
  updateOrganization,
  addGalleryFile,
  removeGalleryFile,
  uploadSingleFile,
  removeSingleFile,
  addContactLogo,
  removeContactLogo,
  getJobList,
  getPublicOrganizationData
} from '../../services/api/organization/organization.domains';

const initialValuesModel = {
  name: '',
  website: '',
  industry: '',
  organizationSize: '',
  aboutCompany: '',
  aboutCulture: '',
  aboutCareer: '',
};

const mainContactInformation = {
  fullName: '',
  position: '',
  email: '',
  phoneNumber: types.union(types.string, types.number)
}

const locationModel = {
  country: '',
  address: '',
  suite: '',
  city: '',
  territory: '',
  zipCode: '',
  timeZone: ''
};
const defaultSocialNetworks = [
  { title: 'LinkedIn', link: '' },
  { title: 'Facebook', link: '' },
  { title: 'Twitter', link: '' },
  { title: 'Instagram', link: '' },
]

export const organization = types
  .model("organizationStore", {
    logoUrl: types.optional(types.string, ""),
    logoFileId: types.optional(types.string, ""),
    bannerUrl: types.optional(types.string, ""),
    bannerFileId: types.optional(types.string, ""),
    audioUrl: types.optional(types.string, ""),
    audioFileId: types.optional(types.string, ""),
    audioName: types.optional(types.string, ""),
    publicPreviewUrl: types.maybeNull(types.string),
    creationDate: types.maybeNull(types.string),
    photoGallery: types.optional(types.array(types.model({
      url: '',
      key: ''
    })), []),
    videoGallery: types.optional(types.array(types.model({
      url: '',
      key: '',
      type: '',
      fileName: '',
      isMain: types.maybeNull(types.boolean)
    })), []),
    mainVideoKey: types.optional(types.string, ''),
    foundationYear: types.maybeNull(types.string),
    jobList: types.optional(types.array(
      types.model({
        creationDate: types.string,
        updatingDate: types.string,
        jobTitle: types.string,
        _id: types.string,
        steps: types.frozen()
      })
    ), []),
    allJobsListLength: types.maybeNull(types.number),
    initialValues: types.optional(types.model({
      ...initialValuesModel,
      creator: types.maybeNull(types.string),
      benefits: types.optional(types.frozen(), {}),
      locations: types.array(types.model({ ...locationModel })),
      socialNetworks: types.array(types.model({ title: '', link: '' })),
      contactPerson: types.array(
        types.model(
          {
            ...mainContactInformation,
            logo: types.maybeNull(
              types.model({
                url: '',
                key: ''
              })
            )
          })),
    }), {
      ...initialValuesModel,
      benefits: {},
      locations: [{ ...locationModel, country: 'United States' }],
      socialNetworks: defaultSocialNetworks,
      contactPerson: []
    }
    )
  })
  .actions(self => {
    const setLogo = (key: string, url: string) => {
      self.logoUrl = url
      self.logoFileId = key
    }
    const setOrganizationData = async (serverData: any) => {
      const locationsValue = serverData.locations.length > 0 ? serverData.locations : self.initialValues.locations;
      self.initialValues = { ...serverData, locations: locationsValue }
      self.photoGallery = serverData.photoGallery;
      self.videoGallery = serverData.videoGallery;
      self.foundationYear = serverData.foundationYear;
      setLogo(serverData.logo?.key, serverData.logo?.url)
      setPhotoBanner(serverData.banner?.key, serverData.banner?.url)
      setAudio(serverData.audio?.key, serverData.audio?.url, serverData.audio?.fileName)
      self.publicPreviewUrl = serverData.publicPreviewUrl;
      self.creationDate = serverData.creationDate;
    }

    const uploadOrganizationData = flow(function* (organizationUrlName?: string) {
      const responseServer = organizationUrlName ? yield getPublicOrganizationData(organizationUrlName) : yield getOrganizationData()
      if (!responseServer.data.err) {
        const serverData = responseServer.data.data;
        setOrganizationData(serverData)
        return { err: '', data: self }
      } else {
        return { err: responseServer.data.err }
      }
    })

    const updateOrganizationData = flow(function* (data) {
      const responseServer = yield updateOrganization(data)
      if (responseServer.data.err) {
        return { err: responseServer.data.err }
      }
      const serverData = responseServer.data.data;
      setOrganizationData(serverData)
      return { err: null }
    })

    const saveSingleFile = async (dto: any) => {
      await uploadSingleFile(dto)
    }

    const deleteSingleFile = async (dto: any) => {
      await removeSingleFile(dto)
    }

    const setPhotoBanner = (key: string, url: string,) => {
      self.bannerUrl = url;
      self.bannerFileId = key;
    }

    const unsetPhotoBanner = () => {
      self.bannerUrl = '';
      self.bannerFileId = '';
    }

    const saveGalleryFile = async (photoDto: any) => {
      await addGalleryFile(photoDto)
    }

    const deleteGalleryFile = async (key: string) => {
      return await removeGalleryFile(key);
    }

    const setGalleryPhoto = (key: string, url: string) => {
      self.photoGallery.push({ url, key })
    }

    const unsetGalleryPhoto = (key: string) => {
      const index = self.photoGallery.findIndex(photo => photo.key === key);
      self.photoGallery.splice(index, 1);
    }

    const setGalleryVideo = (videoData: { key: string, url: string, type: string, fileName: string, isMain: boolean }) => {
      self.videoGallery.push(videoData)
    }

    const unsetGalleryVideo = (key: string) => {
      const index = self.videoGallery.findIndex(photo => photo.key === key);
      self.videoGallery.splice(index, 1);
    }

    const setAudio = (key: string, url: string, fileName: string) => {
      self.audioUrl = url;
      self.audioFileId = key;
      self.audioName = fileName;
    }

    const unsetAudio = () => {
      self.audioUrl = '';
      self.audioFileId = '';
      self.audioName = '';
    }

    const saveContactLogo = async (dto: any) => {
      await addContactLogo(dto)
    }

    const deleteContactLogo = async (dto: any) => {
      await removeContactLogo(dto)
    }

    const setJobList = flow(function* (organizationName: string, activePage: number) {
      const serverResponse = yield getJobList(organizationName, activePage);
      if (!serverResponse.data.err) {
        const jobListData = serverResponse.data.data;
        self.jobList = jobListData.items;
        self.allJobsListLength = jobListData.listLength;
        return { error: null }
      } else {
        return { error: serverResponse.data.err }
      }
    })

    return {
      uploadOrganizationData,
      updateOrganizationData,
      saveGalleryFile,
      deleteGalleryFile,
      setGalleryPhoto,
      unsetGalleryPhoto,
      saveSingleFile,
      setPhotoBanner,
      unsetPhotoBanner,
      deleteSingleFile,
      setGalleryVideo,
      unsetGalleryVideo,
      setAudio,
      unsetAudio,
      saveContactLogo,
      deleteContactLogo,
      setLogo,
      setJobList
    }
  })
