export enum SPScreensWidth {
  desktopXXL = "1600px",
  desktopLarge = "1281px",
  desktop = "1280px",
  tablet = "820px",
  mobile = "767px",
  mobileSmall = "400px",
}

export const isMobile = (width: number) => width < parseInt(SPScreensWidth.mobile, 10)
export const isTablet = (width: number) => width <= parseInt(SPScreensWidth.tablet, 10) && width >= parseInt(SPScreensWidth.mobile, 10)
export const isDesktop = (width: number) => width > parseInt(SPScreensWidth.tablet, 10)
export const isNotDesktop = (width: number) => width < parseInt(SPScreensWidth.desktop, 10)

export function getViewByWidth(width: number) {
  const isMobileView = isMobile(width)
  const isTabletView = isTablet(width)
  const isDesktopView = isDesktop(width)
  return { isMobileView, isTabletView, isDesktopView }
}