import * as React from "react"
import { Wrapper, Checkmark, LabelWrap } from "./SPBaseRadioButton.presets"
import { SPBaseRadioButtonProps } from "./SPBaseRadioButton.props"

export const SPBaseRadioButton: React.FC<SPBaseRadioButtonProps> = (props) => {
  const { handleChange, name, value, labelText, disabled } = props;

  const clickCallback = () => {
    if (props.handleClick) props.handleClick();
  }

  return <Wrapper
    disabled={disabled} >
    <input disabled={disabled} type={'radio'} name={name} value={value} onChange={handleChange} checked={props.checked} onClick={clickCallback} />
    <LabelWrap>
      <Checkmark />
      {labelText}
    </LabelWrap>
  </Wrapper >
}
