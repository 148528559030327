import React from "react";
import { Wrapper, Title, Description } from "./ErrorBoundary.presents";
import errorBanner from '../../../assets/img/candidateListEmpty.svg'
import { ErrorForm } from "./ErrorForm";
import { customEvent } from "../../../services/analytics/usePageTracking";
import { ErrorBoundaryProps, ErrorBoundaryState } from "./ErrorBoundary.props";

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      redirectPath: props.redirectPath,
      pageTitle: props.pageTitle
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const category = 'Catch Error';
    const action = `Redirect to ErrorBoundary on the ${this.state.pageTitle} page`;
    customEvent(category, action);
  }

  render() {
    if (this.state.hasError) {
      return <Wrapper>
        <img src={errorBanner} alt='banner' />
        <Title>Oops, something went wrong!</Title>
        <Description>
          Please try again later or contact with support team, and we will help you
        </Description>
        <ErrorForm redirectPath={this.state.redirectPath} />
      </Wrapper>;
    }

    return this.props.children;
  }
}