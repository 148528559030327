export enum LocationType {
  'hiring',
  'primary',
  'organization'
}

export type PropsType = {
  contactStep: any
  organizationName: string
  handleCloseMapsWindow: Function
  openMapsWindow: boolean
  companyLogo: {
    fileUrl: string
    filename?: string
    id?: string
  }
  locations: {
    locationType: LocationType,
    locationsData: Array<any>
  }
}

export type PropsErrorWindowType = {
  openWindow: boolean
  handleCloseWindow: Function
  title: string
  text: string
}

export type ReportModalPropsType = {
  openReportWindow: boolean
  setOpenReportWindow: Function
}

export type ApplyProps = {
  openWindow: boolean
  closeWindow: Function
  jobId: string | null
}

export type CoordinatesProps = {
  label: string,
  latitude: string,
  longitude: string,
  value: string
}

export type setterCoordinatesProps = {
  locationsData: any,
  coordinates: Array<CoordinatesProps>,
  validLocation: Array<CoordinatesProps>,
  setLocationDataToState: Function
}

export type SaveModalProps = {
  isOpen: boolean
  onClose: () => void
}

export type ResponseDataProps = {
  label: string,
  value: string,
  latitude: string,
  longitude: string
}

export type saveLocationDataProps = {
  responseData: Array<ResponseDataProps>,
  locationItem: any,
  coordinates: Array<CoordinatesProps>,
  validLocation: Array<CoordinatesProps>,
  locationType: 'city' | 'territory'
}

export type saveHiringLocationProps ={ 
  locationItem: any, 
  coordinates:Array<CoordinatesProps>,
  validLocation:Array<CoordinatesProps> 
}