import { FieldArray, Formik } from "formik";
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { Header } from "../../BaseComponents/Header/Header";
import {
  Title,
  Wrapper,
  ContentWrap,
  Subtitle,
  HeaderWrap,
  FormWrap,
  AddMoreButton, ButtonWrap, SubtitleAdvice
} from "./ExpertiseStep.presents";
import { useTranslation } from "react-i18next";
import useGetDevice from "../../../../../../hooks/useGetDevice";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import candidateOccupationAreas from "../../../../../../assets/staticData/candidateOccupationAreas.json"
import { useState } from "react";
import { ExpertiseField } from "./ExpertiseField";
import { SPBaseButton } from "../../../../../common/buttons/SPBaseButton";
import * as Yup from 'yup';
import { FocusError } from "../../../../../JobStoryBuilder/BasicComponents/FocusError/FocusError";

export const ExpertiseStep = observer(({
  isPanel,
}: {
  isPanel?: boolean;
}) => {
  const { t } = useTranslation();
  const isDesktop = useGetDevice();
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const [areasOptionsList, setAreasOptionsList] = useState(candidateOccupationAreas.map(item => { return { ...item, disable: false } }));
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const validationSchema = Yup.object().shape({
    expertiseAreas: Yup.array().of(
      Yup.object().shape({
        area: Yup.string().required('Area of Expertise is required'),
        skills: Yup.mixed().when('area', {
          is: (val: string) => val !== 'Other',
          then: Yup.array().min(1, 'Skills are required').of(Yup.string()).min(2, 'Skills should contain at least 2 items').max(6, 'Skills should contain maximum 6 items'),
          otherwise: Yup.mixed(),
        })
      })
    ),
  });

  const updateExpertises = async (values: any) => {
    setIsLoading(true);
    resumeBuilderStore.setExpertises(values);
    const result = await candidateStore.setExpertises(isPanel, candidateStore.isAnonymous, candidateStore.uid, values);
    setIsLoading(false);
    if (!result.error) {
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 3000);
    };
  }

  const goToPreferenceStep = () => {
    candidateStore.setIsPreferences(false);
    candidateStore.setIsBackToPreferences(true);
  }

  return <Wrapper>
    <HeaderWrap isDesktop={isDesktop}>
      <Header isLogoBlack isPreferencesPage />
      <SPBaseButton onClick={() => updateExpertises({ expertiseAreas: [{ area: "Other", skills: [] }] })} width={isDesktop ? '156px' : '74px'} type="button">Skip</SPBaseButton>
    </HeaderWrap>
    <ContentWrap>
      <Title>Tell us about your occupation</Title>
      <Formik
        validateOnChange={firstSubmitComplete}
        validateOnBlur={false}
        initialValues={{ expertiseAreas: [{ area: "", skills: [] }] }}
        validationSchema={validationSchema}
        onSubmit={updateExpertises}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => <form onSubmit={handleSubmit}>
            <FocusError />
            <FieldArray
              name="expertiseAreas">
              {({ remove, push }) => {
                return <FormWrap>
                  <Subtitle>What are your areas of expertise for remote work?</Subtitle>
                  <SubtitleAdvice>These are the skills you can use to market yourself to potential employers for remote work. Only the ones suitable for remote jobs are listed here. If your expertise is not listed here, you can choose “other” from the drop down menu or skip this question.</SubtitleAdvice>
                  {values.expertiseAreas.map((item, index) => <ExpertiseField
                    isPanel={isPanel}
                    setFieldValue={setFieldValue}
                    options={areasOptionsList}
                    setOption={setAreasOptionsList}
                    handleChange={handleChange}
                    values={values.expertiseAreas}
                    fieldIndex={index}
                    remove={remove}
                    errors={errors}
                  />
                  )}
                  <AddMoreButton isPanel={isPanel} onClick={() => push({ area: "", skills: [] })}>+ Add more Areas of Expertise</AddMoreButton>
                </FormWrap>
              }}
            </FieldArray>

            <ButtonWrap>
              <SPBaseButton
                onClick={goToPreferenceStep}
                width={'156px'}
                mobileHeight={"42px"}
                type="button"
              >
                Previous Step
              </SPBaseButton>

              <SPPrimaryButton
                isLoading={isLoading}
                onClick={() => { setFirstSubmitComplete(true) }}
                width={'148px'}
                mobileHeight={"42px"}
                type="submit"
              >
                Next Step
              </SPPrimaryButton>
            </ButtonWrap>

          </form>
        }
      </Formik>

    </ContentWrap>
  </Wrapper>
})