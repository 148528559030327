import { addGalleryPhoto, addVideo, changeJobTheme, createJob, getJob, removeGalleryPhoto, uploadFile, addFile, removeFile, removeVideo } from '../../services/api/jobStoreBuilder/jobStoreBuilder.domains';
import { CreateJobrDto } from '../../services/api/jobStoreBuilder/jobStoreBuilder.type';
import { applySnapshot, detach, flow } from "mobx-state-tree";
import { initialModels, jobModele } from '../models/job.model';
import { jobStory } from '../models/job.model';
import { IObservableArray } from 'mobx';
import { IObservableMapInitialValues } from 'mobx/dist/internal';
import _ from 'lodash';

export const jobBuilder = jobModele
  .actions(self => {
    const openDropdownQuestions = (dropdownQuestions: any) => {
      const answersCopy: any = { ...self.answers };
      const dropdownQuestionsCopy = _.cloneDeep(dropdownQuestions);;
      for (const key in dropdownQuestionsCopy) {
        const answerValue = answersCopy.specificationStep[key]
        if (answerValue) {
          const isOpen = dropdownQuestionsCopy[key].hasOwnProperty(answerValue);
          dropdownQuestionsCopy[key][answerValue] = isOpen;
        }
      }
      return dropdownQuestionsCopy
    }
    const createNewJob = flow(function* createNewJob(data: CreateJobrDto) {
      const responseServer = yield createJob(data)
      if (!responseServer.data.err) {
        const filterSteps: any = {}
        for (const key in responseServer.data.data.steps) {
          filterSteps[key] = responseServer.data.data.steps[key] ? responseServer.data.data.steps[key] : { ...self.answers }[key];
        }
        self.dependent = responseServer.data.data.dependents
        self.jobId = responseServer.data.data._id
        self.answers.jobStep.mixshiftData && detach(self.answers.jobStep.mixshiftData)
        self.answers = filterSteps
        return { err: '', data: { jobId: self.jobId } }
      } else {
        return { err: responseServer.data.err }
      }
    })
    const getJobData = flow(function* (jobId: string) {
      const responseServer = yield getJob(jobId)
      let actionResponse = {};
      if (!responseServer.data.err) {
        self.dependent = responseServer.data.data.dependents
        self.jobId = jobId
        self.themeId = responseServer.data.data.themeId
        self.jobStatus = responseServer.data.data.jobStatus
        responseServer.data.data.steps.jobStep && responseServer.data.data.steps.jobStep.mixshiftData?.forEach((item: any) => item as IObservableMapInitialValues)
        const valideAnswers: any = { ...self.answers }
        for (const key in responseServer.data.data.steps) {
          if (responseServer.data.data.steps[key]) {
            valideAnswers[key] = responseServer.data.data.steps[key]
          } else {
            applySnapshot(self.answers, initialModels)
          }
        }
        self.answers = valideAnswers
        actionResponse = { err: '', data: { jobId: self.jobId, answers: responseServer.data.data.answers } }
      } else {
        actionResponse = { err: responseServer.data.err }
      }
      setStepsQuestions();
      return actionResponse;
    })

    const transformValuesFromForm = (values: any, step: any) => {
      const valuesCopy = { ...values }
      for (const key in valuesCopy) {
        const questionItem = step.find((item: any) => item.name === key)
        const value = valuesCopy[key]

        valuesCopy[key] = {
          value,
          questionId: questionItem?.id,
          isItDependent: questionItem?.isItDependent
        }
      }
      return valuesCopy
    }

    const addCustomBenefitsToStore = (jobStoryQuestions: any) => {
      const specificationStepCopy: any = { ...self.answers.specificationStep }
      const specificationStepKeys = Object.keys(self.answers.specificationStep)
      specificationStepKeys.forEach((item) => {
        Array.isArray(specificationStepCopy[item]) && specificationStepCopy[item].forEach((benefitValue: any) => {
          const benefitItem = jobStoryQuestions.stepSpecification.find((question: any) => question.name === item)
          const benefitValueIndex = benefitItem && benefitItem.answers ? benefitItem.answers.findIndex((benefit: any) => benefit.value === benefitValue) : 1 //for questions without benefits
          if (benefitValueIndex && benefitValueIndex < 0) {
            benefitItem?.answers.push({ title: benefitValue, value: benefitValue })
          }
        })
      })
    }

    const setStepsQuestions = () => {
      const jobStoryQuestions: any = { ...jobStory }
      addCustomBenefitsToStore(jobStoryQuestions)
      self.steps = {
        stepStart: [...jobStoryQuestions.stepStart],
        stepJob: [...jobStoryQuestions.stepJob.filter((element: any) => element.display || Array.from(self.dependent).includes(element.id))],
        stepDescription: [...jobStoryQuestions.stepDescription.filter((element: any) => element.display || Array.from(self.dependent).includes(element.id))],
        stepSpecification: [...jobStoryQuestions.stepSpecification.filter((element: any) => element.id !== 'step4_compensationInternship' && (element.display || Array.from(self.dependent).includes(element.id)))],
        stepContact: [...jobStoryQuestions.stepContact]
      } as IObservableMapInitialValues
      self.allQuestions = {
        stepSpecification: [...jobStoryQuestions.stepSpecification]
      } as IObservableMapInitialValues
    }

    const addNewBenefit = (benefitName: keyof typeof self.answers.specificationStep, newBenefits: Array<any>) => {
      self.customBenefits = { ...self.customBenefits, [benefitName]: newBenefits.map(item => item.value) }
      const questionIndex = self.steps.stepSpecification.findIndex((item: any) => item.name === benefitName)
      const updatingStepSpecification = [...self.steps.stepSpecification]
      updatingStepSpecification[questionIndex].answers = [...updatingStepSpecification[questionIndex].answers, ...newBenefits] as IObservableArray
      self.steps = { ...self.steps, stepSpecification: updatingStepSpecification }
      self.answers.specificationStep = { ...self.answers.specificationStep, [benefitName]: [...self.answers.specificationStep[benefitName], ...newBenefits.map((customBenefit: any) => customBenefit.value)] }
    }

    const uploadFileToJob = flow(function* (file: any, fileFolder: string) {
      const uploadedFile = yield uploadFile(file, fileFolder)
      if (uploadedFile.data.data) {
        self.files = [...self.files, {
          filename: uploadedFile.data.data.filename,
          key: uploadedFile.data.data.key,
          url: uploadedFile.data.data.url,
          _id: uploadedFile.data.data._id
        }]
      }
      return uploadedFile.data
    })

    const saveGalleryPhoto = async (photoDto: any) => {
      await addGalleryPhoto(photoDto)
    }

    const deleteGalleryPhoto = async (key: string) => {
      return await removeGalleryPhoto(key, self.jobId);
    }

    const setGalleryPhoto = (key: string, url: string) => {
      self.answers.contactStep.teamPhotosFile.push({ key, url })
    }

    const unsetGalleryPhoto = (key: string) => {
      const index = self.answers.contactStep.teamPhotosFile.findIndex(photo => photo.key === key);
      self.answers.contactStep.teamPhotosFile.splice(index, 1);
    }

    const saveVideo = async (videoDto: any) => {
      await addVideo(videoDto)
    }

    const saveFile = async (fileDto: any) => {
      await addFile(fileDto)
    }

    const deleteVideo = async (key: string) => {
      return await removeVideo(key, self.jobId);
    }
    const deleteFile = async (key: string) => {
      return await removeFile(key, self.jobId);
    }

    const setVideo = (videoDto: any) => {
      self.answers.contactStep.additionalVideoFile.push(videoDto)
    }

    const setFile = (fileDto: any) => {
      self.answers.descriptionStep.descriptionFile = fileDto;
    }

    const unsetVideo = (key: string) => {
      const index = self.answers.contactStep.additionalVideoFile.findIndex(photo => photo.key === key);
      self.answers.contactStep.additionalVideoFile.splice(index, 1);
    }

    const unsetFile = () => {
      self.answers.descriptionStep.descriptionFile = null;
    }

    const resetAnswerData = () => {
      detach(self.answers);
    }

    const setThemeForJob = (themeId: string) => {
      self.themeId = themeId;
    }

    const changeTheme = flow(function* (jobId: string) {
      const responseData = yield changeJobTheme({ jobId, themeId: self.themeId });
      return { err: responseData.data.err ? 'response error' : null };
    })

    return {
      createNewJob,
      getJobData,
      transformValuesFromForm,
      setStepsQuestions,
      addNewBenefit,
      openDropdownQuestions,
      uploadFileToJob,
      resetAnswerData,
      setThemeForJob,
      changeTheme,
      saveGalleryPhoto,
      setGalleryPhoto,
      deleteGalleryPhoto,
      unsetGalleryPhoto,
      saveVideo,
      deleteVideo,
      setVideo,
      unsetVideo,
      saveFile,
      deleteFile,
      setFile,
      unsetFile
    }
  })