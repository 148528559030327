import { Player, Ui, Video, Controls, PlaybackControl, Youtube, Vimeo, ViewType, ControlGroup, ScrubberControl, VolumeControl, FullscreenControl, ControlSpacer } from '@vime/react';
import { VideoPlayerContainer } from './VideoPlayer.presents';
import '@vime/core/themes/default.css';
import './player.css';
import { useRef, useState } from 'react';

export const VideoPlayer = ({ videoLink }: { videoLink: string }) => {
  const player = useRef<HTMLVmPlayerElement>(null);

  const [playerIsLoaded, setPlayerIsLoaded] = useState(false);
  const isYouTubeLink = videoLink.indexOf('www.youtube.com') >= 0;
  const isVimeoLink = videoLink.indexOf('vimeo.com') >= 0;
  const url = new URL(videoLink);

  const isEmbedPlayer = isYouTubeLink || isVimeoLink;

  const getVideoId = () => {
    let videoId = videoLink;
    if (isYouTubeLink) {
      const idParam = url.searchParams.get("v");
      videoId = `${idParam}` || videoId;
    }
    if (isVimeoLink) {
      const idParam = url.pathname.substring(1);
      videoId = idParam || videoId;
    }
    return videoId;
  }

  const videoId = getVideoId();
  const test = (elem: any) => {
    !playerIsLoaded && elem?.pause();
    setPlayerIsLoaded(true)
  }

  return <VideoPlayerContainer>
    <Player
      ref={player}
      controls={false}
      viewType={ViewType.Video}
      muted
      onVmPlay={() => test(player.current)}
    >

      {/* YouTube Player */}
      {isYouTubeLink && <Youtube videoId={videoId} />}

      {/* Vimeo Player */}
      {isVimeoLink && <Vimeo videoId={videoId} />}

      {/* Default Player */}
      {!isEmbedPlayer && <Video cross-origin>
        <source
          data-src={videoLink}
          type="video/mp4"
        />
      </Video>}

      <Ui>
        <Controls fullWidth>
          <ControlGroup>
            <ScrubberControl />
          </ControlGroup>

          <ControlGroup space="top">
            <PlaybackControl/>
            <VolumeControl />
            <ControlSpacer />
            <FullscreenControl />
          </ControlGroup>
        </Controls>
      </Ui>
      
    </Player>
  </VideoPlayerContainer>
}
