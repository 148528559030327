import * as React from "react"
import { SPBaseButtonProps } from "../SPBaseButton/SPBaseButton.props"
import { SecondaryButton } from "./SPSecondaryButton.presets"

export const SPSecondaryButton: React.FC<SPBaseButtonProps> = (props) => {
  const clickHandler = (event: any) => {
    props.disablePropagation && event.stopPropagation()
    props.onClick && props.onClick()
  }

  return(
    <SecondaryButton
      id={props.selectorId}
      data-id={props.testID}
      type={props.type}
      onClick={clickHandler}
      onKeyDown={e => props.disablePropagation && e.stopPropagation()}
      width={props.width}
      tabIndex={props.tabIndex || 0}
      aria-label={props.label}
      disabled={props.disabled}
      isLight={props.isLight}
      color={props.color}
    >
        {props.children}
    </SecondaryButton>
  )
}
