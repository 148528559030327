import { useEffect, useState } from 'react';
import { generateSignedUrl, s3upload } from '../../../../services/api/jobStoreBuilder/jobStoreBuilder.domains';
import { useRootStore } from '../../../../state/stores/storeContext';
import { UploadButton } from './UploadButton/UploadButton';
import { observer } from 'mobx-react-lite';
import { VideoPreview } from './VideoPreview/VideoPreview';
import { checkFileSizeInMB } from '../../../../services/helper';


export const VideoUploadStep = observer(() => {
  const { jobStoryBuilder } = useRootStore();
  const [isLoading, setIsLoading] = useState(false)
  const [media, setMedia] = useState<Array<any>>([]);

  const MAX_FILE_SIZE = 150
  const UPLOAD_FOLDER = "videos"

  useEffect(() => {
    if (jobStoryBuilder.answers.contactStep.additionalVideoFile && !isLoading) {
      const mappedMedia = jobStoryBuilder.answers.contactStep.additionalVideoFile.map((item: any) => {
        return { url: item.url, key: item.key, name: item.name, type: item.type, isLoading: false }
      })
      setMedia(mappedMedia)
    }
  }, [jobStoryBuilder.answers.contactStep.additionalVideoFile, jobStoryBuilder.answers.contactStep.additionalVideoFile.length, isLoading])

  const getFileType = (file: File) => {

    if(file.type.match('image.*'))
      return 'image';
  
    if(file.type.match('video.*'))
      return 'video';
  
    if(file.type.match('audio.*'))
      return 'audio';
  
    return 'other';
  }

  const uploadFile = async (event: any) => {
    if(!event.target.files) return

    let file = event.target.files[0]

    if(!checkFileSizeInMB(file.size, MAX_FILE_SIZE)) return alert("Maximum file size is 300mb")


    const placeholder = {
      name: file.name,
      type: getFileType(file),
      isLoading: true
    } 

    setMedia([placeholder])
    setIsLoading(true)
    

    const resp = await generateSignedUrl({filename: file.name, filetype: file.type, folder: UPLOAD_FOLDER});
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, file)
        const addVideoDto = {
          key: resp.data.data.key,
          url: resp.data.data.fileUrl,
          name: file.name,
          type: getFileType(file),
          jobId: jobStoryBuilder.jobId
        }
        await jobStoryBuilder.saveVideo(addVideoDto)
        const uploadResult = {
          name: file.name,
          type: getFileType(file),
          url: resp.data.data.fileUrl,
          key: resp.data.data.key,
          isLoading: false
        }
        jobStoryBuilder.setVideo(uploadResult)
        setMedia(() => ([uploadResult]))
      } catch (error) {
        setMedia([...media])
      }
    }
    
    event.target.value = null; // reset input value
    setIsLoading(false)
  }


  const removePhoto = async (fileIndex: number, file: any) => {
    if(isLoading) return
    setIsLoading(true)
    if (!jobStoryBuilder.jobId) return
    const copiedPhotos = [...media];
    copiedPhotos[fileIndex].isLoading = true
    setMedia(copiedPhotos);
    const resp = await jobStoryBuilder.deleteVideo(file.key)
    jobStoryBuilder.unsetVideo(file.key)
    if (!resp.data.err) {
      copiedPhotos.splice(fileIndex, 1);
    } else {
      copiedPhotos[fileIndex].isLoading = false
    }
    setMedia([...copiedPhotos]);
    setIsLoading(false)
  }


  return (
    <>
      {
        !!media.length && media.map((item: any, index: number) => 
          <VideoPreview key={index} name={item.name} type={item.type} onRemove={()=> removePhoto(index, item)} isLoading={item.isLoading} />
        )
      }
      {
        media.length < 1 
          ? <UploadButton
              text="Upload media"
              onChange={uploadFile}
              isLoading={isLoading}
              fileTypes="video/*,audio/*"
            />
        : null
      }
    </>
  )
})
