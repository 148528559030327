import { theme } from './../../../../theme/theme';
import { colors } from './../../../../constants/colors';
import styled from 'styled-components';

export const StyleForWrap = styled('div')`
  width:100%;
  margin: 0 auto;
  max-width: 1112px;
  scroll-margin-top: 120px;
  .download-btn{
    display:flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #E2E3E5;
    box-sizing: border-box;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: ${colors.dark};
    img{
      margin-right: 12px;
    }
  }
`

export const DescriptionSubtitle = styled('p')`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${colors.dark};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.lg};
    line-height: 18px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px; 
    line-height: ${theme.offsets.lg};
  }
`

export const DescriptionTextWrap = styled('p')`
  font-size: 18px;
  line-height: ${theme.offsets.xxl};
  margin-top:${theme.offsets.lg};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    line-height: ${theme.offsets.lg};
    margin-top:${theme.offsets.md};
  }
`

export const DescriptionSeparator = styled('hr')`
  background: #ECECEC;
  height: 1px;
  border-top: none;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ReadMoreButton = styled('button')`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0C75FF;
  border: none;
  background: none;
  padding: 0;
  margin-top: 8px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    line-height: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.md};
    line-height: ${theme.offsets.lg};
  }
`

export const DownloadBtn = styled('a')`
  position: absolute;
  top: ${theme.offsets.lg};
  right: ${theme.offsets.extraBig};
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  border: 1px solid ${theme.colors.lightGray};
  padding: ${theme.offsets.lg};
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  &:hover{
    color: ${theme.colors.darkGray};
  }
  @media(max-width: ${theme.screens.mobile}) {
    position: relative;
    order: 4;
    top: 0;
    right: 0;
    margin-top: ${theme.offsets.lg};
  }
`
export const DownloadIcon = styled('img')`
  margin-right: ${theme.offsets.md};
`

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
`