import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CandidatesList } from "../../components/CandidatesList/Candidates";
import { useRootStore } from "../../state/stores/storeContext";
import { StyleForWrap, StyleForPage } from './Candidates.present';
import { Navigation } from "../../components/CandidatesList/Navigation";
import { JobStory } from "../../components/JobStory";
import isMobile from "is-mobile";
import { MobileAdminHeader } from "../../components/common/Headers/AdminHeader/Mobile/MobileAdminHeader";
import { PageTypes, PropsType } from "./Candidates.props";
import { MobileNavigation } from "../../components/CandidatesList/Navigation/Mobile/MobileNavigation";
import { ThemeCustomizer } from "../../components/JobStoryBuilder/BasicComponents/ThemeCustomizer";
import { DesktopHeader } from "../../components/common/Headers/AdminHeader/Desktop";

export const CandidatesPage = (props: PropsType) => {
  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(history.location.search);
  }
  const query = useQuery();
  const jobId = query.get("id") || '';

  const { candidatesStore } = useRootStore();

  const [activeTab, setActiveTab] = useState(props.activePage);
  const [isDesktop, setDesktopOnly] = useState(false);
  const [isSpinnerOpen, setSpinnerOpen] = useState(false);
  const [isThemeMenuOpen, setThemeMenuOpen] = useState(false);

  const listLength = candidatesStore.typesLength ? candidatesStore.typesLength.allCandidates : 0;
  const jobTitle = candidatesStore.jobTitle;
  const isPreviewPage = activeTab === PageTypes.jobPreview;


  useEffect(() => {
    (async () => {
      setSpinnerOpen(true);
      setDesktopOnly(!isMobile({ tablet: true, featureDetect: true }));
      await candidatesStore.setCandidatesList(0, 'all', { type: '', value: '' }, jobId);
      setSpinnerOpen(false);
    })()
  }, [])

  const CandidatesListProps = {
    jobId,
    isDesktop,
    isSpinnerOpen,
    setSpinnerOpen
  };

  const NavigationProps = {
    setThemeMenuOpen,
    jobTitle,
    listLength,
    setActiveTab,
    activeTab,
    isPreviewPage,
    jobId
  };

  const MobileNavigationProps = {
    isPreviewPage,
    setThemeMenuOpen,
    jobTitle
  };

  return <StyleForPage>
    {isDesktop ? <DesktopHeader previewPage /> : <MobileAdminHeader />}
    {isDesktop ? <Navigation  {...NavigationProps} /> : <MobileNavigation {...MobileNavigationProps} />}
    <StyleForWrap isDesktop={isDesktop} activeTab={activeTab}>
      {isPreviewPage ? <JobStory preview openApplyWindow={false} /> : < CandidatesList {...CandidatesListProps} />}
    </StyleForWrap>
    {isPreviewPage && <ThemeCustomizer show={isThemeMenuOpen} handleClose={() => setThemeMenuOpen(false)} jobId={jobId} />}
  </StyleForPage>
}