import { WindowContentType } from "./PreferencesForm.props";
import countries from '../../../../../../assets/staticData/countries.json';
import languages from '../../../../../../assets/staticData/languages.json';

export const preferenceMap = {
  jobKind: [
    'Onsite', 'Remote', 'Hybrid', 'I don’t have any preferences'
  ],
  jobTime: [
    'Regular business week (5 days a week)', 'Once a week', 'Twice a week', 'Three times a week', 'Four times a week', 'Six times a week'
  ],
  workShift: [
    'Regular day shift (8:00 am - 6:00 pm)',
    'Morning shift (4:00 am – Noon)',
    'Afternoon shift (Noon – 8:00 pm)',
    'Night shift (8:00 pm to 4:00 am)',
    'Mixed shift (rotating schedule)',
    'Any shift (flexible hours)',
  ],
  includesOvertime: [
    'Yes', 'No'
  ],
  jobTraveling: [
    'Yes, I love traveling', 'No, I don’t like traveling', 'I don’t have any preferences'
  ],
  jobType: [
    'Permanent (FTE)', 'Contract to Hire', 'Contract', 'Internship', 'Temporary', 'Project Based', 'Volunteer', 'Apprenticeship', 'Research & Academia'
  ],
  workplaceBenefits: [
    'Competitive pay', 'Wellbeing', 'Diversity', 'Benefits', 'Work-life balance', 'Clear expectations', 'Recognition', 'Clear communication', 'Growth',
    'Trust', 'Responsibility', 'Respect', 'Pride at work', 'Learning and development ', 'Achievement', 'Productivity', 'Employee engagement ', 'Teamwork',
    'Career advancement', 'High employee morale', 'Low employee turnover', 'Flexibility', 'Workplace culture', 'Meaningful work', 'Outcome driven work',
    'Forward-thinking workplace', 'Purpose', 'Employee motivation'
  ],
  preferenceCountry: [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'New Zealand', 'Germany', 'Netherlands', 'Switzerland', 'Ukraine',
    'Poland', 'France', 'Spain', 'Italy', 'India', 'Pakistan', 'Singapore', 'Hong Kong', 'Malaysia',
    'Indonesia', 'Vietnam', 'United Arab Emirates', 'Qatar', 'Bahrain', 'Philippines'
  ],
  jobStart: [
    'As soon as possible', 'In the next two to three months', 'In the next year', 'No rush until I find the right job', 'I’m not sure yet'
  ],
  languages: [
    'English', 'Spanish', 'French', 'German', 'Portuguese', 'Italian', 'Ukrainian', 'Dutch', 'Arabic', 'Hindi', 'Urdu'
  ],
  levelSkills: [
    'Entry Level', 'Junior', 'Mid Level', 'Senior', 'Executive & Leadership'
  ]
  
}

export const initialValues = {
  jobKind: '',
  jobTime: [],
  workShift: [],
  includesOvertime: '',
  jobTraveling: '',
  jobType: [],
  workplaceBenefits: [],
  preferenceCountry: [],
  jobStart: '',
  languages: [],
  levelSkills: [],
  //for modal window
  middlewareValue: [],
  expertiseAreas: [{ area: "", skills: [] }] 
};

export function SetModalWindowContent(contentType: WindowContentType) {


  const contentForCountryList = {
    title: 'Search Countries',
    optionList: countries,
    fieldName: 'preferenceCountry'
  }
  switch (contentType) {
    case WindowContentType.countries:
      return contentForCountryList;
    case WindowContentType.languages:
      return {
        title: 'Search Languages',
        optionList: languages,
        fieldName: 'languages'
      };
    default:
      return contentForCountryList;
  }

}