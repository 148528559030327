import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Container = styled('div')<{isDesktop: boolean}>`
  max-width: 414px;
  width: 100%;
  margin: 0 9px;
  ${(props)=>!props.isDesktop ? `
    max-width: 100%;
    margin: 0;
  `:`
    flex: 3;
  `}
`;

export const Header = styled('p')`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.big};
`;

export const SideInfoHeader = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.fontSizes.lg};
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.sm};
  }
`;

export const SideInfoWrapper = styled('div') <{ isLastItem: boolean }>`
${props => !props.isLastItem && `
padding-bottom: ${theme.offsets.lg};
border-bottom: 1px solid rgba(45, 53, 61, 0.15);
`}
`;

export const SideInfoContentHeader = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-size: 14px;
  margin-top: ${theme.offsets.xs};
  margin-left: ${theme.offsets.extraBig};
   color: #656B72;
`;

export const SideInfoContent = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-size: ${theme.fontSizes.lg};
  margin-left: ${theme.offsets.extraBig};
`;
