import styled from "styled-components";
import { theme } from "../../../../theme";

const CARD_SIZE_LARGE = '137px';
const CARD_SIZE_MEDIUM = '125px';
const CARD_SIZE_SMALL = '88px';

const ICON_SIZE_LARGE = '48px';
const ICON_SIZE_MEDIUM = '33px';
const ICON_SIZE_SMALL = '27px';

export const Wrapper = styled('div')`
  display: flex;
`

export const UploadButtonContainer = styled('button') <{ isDesktop: boolean }>`
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${CARD_SIZE_LARGE};
  width: ${CARD_SIZE_LARGE};
  border: 1px solid ${theme.colors.lightGray};
  background: #F6F8FA;
  border-radius: ${theme.variables.borderRadiusXLarge};
  font-size: 14px;
  color: #91959D;
  flex-direction: column-reverse;
  margin: ${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: 2px
  }
  img{
    height: ${ICON_SIZE_LARGE};
    object-fit: contain;
    margin-bottom: ${theme.offsets.lg};
  }
  &:hover {
    background: #ebf0f4;
  }

  ${(props)=>!props.isDesktop && `
    height: ${CARD_SIZE_MEDIUM};
    width: ${CARD_SIZE_MEDIUM};
    border-radius: ${theme.variables.borderRadiusLarge};
    font-size: ${theme.fontSizes.md};
    img{
      height: ${ICON_SIZE_MEDIUM};
      margin-bottom: ${theme.offsets.md};
    }
    @media(max-width: ${theme.screens.mobile}) {
      height: ${CARD_SIZE_SMALL};
      width: ${CARD_SIZE_SMALL};
      border-radius: ${theme.variables.borderRadiusSmallMedium};
      img{
        height: ${ICON_SIZE_SMALL};
        margin-bottom: ${theme.offsets.sm};
      }
    }   
  `}
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-family: 0;
  }
  input[type=file]::-webkit-file-upload-button {
    display: none;
}
`
