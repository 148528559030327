export const JuniorIcon = ({ active }: { active?: boolean }) => {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44 25.5C44.8333 28.1667 46.5 33.7 46.5 34.5L47.5 27.5L48 20.5C47.6667 19.1667 46.9 16.3 46.5 15.5C46.1 14.7 44.6667 12.8333 44 12L37 8L29 7L24 7.5L19 10.5L15 11L9.5 15.5L8.5 22L11 33L13.5 25.5L19 22.5L20.5 18L21.5 19.5L29.5 24L44 25.5Z" fill="#F8DDC3" />
    <path d="M19.5 21.5L21 18L24 21.5C26.8333 22.5 32.6 24.5 33 24.5C33.4 24.5 40.1667 25.1667 43.5 25.5L47.5 32.5L46 38L42 43L36 47.5L27.5 49L23 48L16.5 45L12 40L10 34.5L11.5 28.5L14 25L19.5 21.5Z" fill="white" />
    <path d="M6 30.5L9.5 27L10 35.5L6.5 33.5L6 30.5Z" fill="white" />
    <path d="M50.5801 33L47 36L48 27L51 30L50.5801 33Z" fill="white" />
    <path d="M28.5196 50C21.7009 50 15.4897 46.5602 11.8893 40.7991C11.8677 40.7717 11.8481 40.7444 11.8324 40.7189C11.3875 40.0052 10.9759 39.2229 10.5741 38.3351C9.4746 35.8183 8.91993 33.1685 8.91993 30.4444C8.89053 30.3212 7.93995 23.9676 7.93995 20.6667C7.93995 11.2272 16.3502 10.0715 18.3141 9.93067C19.3626 8.99787 23.1571 6 28.5196 6C37.0651 6 49.0993 10.5506 49.0993 20.6667C49.0993 23.907 48.1487 30.3173 48.1095 30.5872C48.1193 33.1588 47.5666 35.8164 46.4729 38.341C46.0358 39.3012 45.5576 40.1792 45.0421 40.9712C45.0049 41.0279 44.9618 41.0827 44.9128 41.1296C41.424 46.6286 35.254 50 28.5196 50ZM13.4592 39.6277C13.4808 39.6551 13.5004 39.6805 13.5161 39.7079C16.752 44.9273 22.3594 48.0444 28.5196 48.0444C34.5798 48.0444 40.1324 45.0114 43.3722 39.9328C43.4114 39.8741 43.4545 39.8155 43.5055 39.7666C43.8583 39.1975 44.2895 38.4055 44.6795 37.549C45.6595 35.2884 46.1593 32.8908 46.1593 30.4444C46.1789 30.2372 47.1393 23.7643 47.1393 20.6667C47.1393 11.8999 36.2517 7.95556 28.5196 7.95556C23.1081 7.95556 19.4489 11.544 19.4136 11.5812C19.2294 11.763 18.9804 11.8647 18.7217 11.8667C18.3611 11.8686 9.89992 12.0036 9.89992 20.6667C9.89992 23.8229 10.8603 30.2352 10.8701 30.2997C10.8799 32.8967 11.3797 35.2864 12.3675 37.5431C12.7144 38.3175 13.0751 39.0039 13.4592 39.6277Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M46.7238 35.3333C46.2985 35.3333 45.9065 35.0537 45.783 34.6254L43.4604 26.5294C29.282 26.4121 23.5393 22.8882 21.254 20.4261C19.7742 24.2199 15.2447 25.8743 13.6297 26.3534L11.2582 34.6235C11.1092 35.1456 10.5643 35.4468 10.0469 35.2942C9.52752 35.1456 9.22569 34.6039 9.37465 34.0857L11.8991 25.2857C11.9951 24.9454 12.2715 24.6834 12.6164 24.6032C12.6889 24.5856 19.7683 22.8608 19.7683 17.8487C19.7683 17.354 20.1388 16.9374 20.6307 16.8788C21.1246 16.8064 21.5813 17.1349 21.7009 17.616C21.7166 17.6688 23.9313 24.5778 44.1993 24.5778C44.6364 24.5778 45.0206 24.8652 45.1401 25.2857L47.6646 34.0857C47.8135 34.6059 47.5117 35.1456 46.9923 35.2942C46.9041 35.3216 46.812 35.3333 46.7238 35.3333Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M47.1001 36.3111C46.8727 36.3111 46.6434 36.2896 46.4161 36.27C45.8771 36.2172 45.4831 35.7381 45.536 35.2004C45.589 34.6645 46.0613 34.2949 46.6062 34.3223C46.7708 34.338 46.9354 34.3556 47.1001 34.3556C48.721 34.3556 50.04 33.0395 50.04 31.4222C50.04 29.9321 48.9287 28.6786 47.4548 28.5006C46.9178 28.438 46.5337 27.9511 46.5983 27.4133C46.663 26.8795 47.155 26.4825 47.6881 26.5588C50.1459 26.8521 52 28.9445 52 31.4203C52 34.1189 49.8009 36.3111 47.1001 36.3111Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M9.89992 36.3111C7.19908 36.3111 5 34.1189 5 31.4222C5 28.923 6.87569 26.8306 9.36093 26.5588C9.91364 26.5118 10.3821 26.8873 10.4428 27.4231C10.5036 27.9589 10.1136 28.442 9.57652 28.5026C8.10851 28.6629 6.95997 29.9458 6.95997 31.4222C6.95997 33.0395 8.27902 34.3556 9.89992 34.3556C10.0567 34.3556 10.2037 34.3419 10.3291 34.3164C10.8564 34.1952 11.3777 34.5492 11.4855 35.0772C11.5953 35.6071 11.2523 36.1214 10.7231 36.2309C10.4683 36.2837 10.19 36.3111 9.89992 36.3111Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M20.6797 34.3556C19.0588 34.3556 17.7398 33.0395 17.7398 31.4222C17.7398 29.805 19.0588 28.4889 20.6797 28.4889C22.3006 28.4889 23.6197 29.805 23.6197 31.4222C23.6197 33.0395 22.3006 34.3556 20.6797 34.3556ZM20.6797 30.4444C20.1388 30.4444 19.6997 30.8844 19.6997 31.4222C19.6997 31.96 20.1388 32.4 20.6797 32.4C21.2207 32.4 21.6597 31.96 21.6597 31.4222C21.6597 30.8844 21.2207 30.4444 20.6797 30.4444Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M36.3595 34.3556C34.7386 34.3556 33.4195 33.0395 33.4195 31.4222C33.4195 29.805 34.7386 28.4889 36.3595 28.4889C37.9804 28.4889 39.2994 29.805 39.2994 31.4222C39.2994 33.0395 37.9804 34.3556 36.3595 34.3556ZM36.3595 30.4444C35.8185 30.4444 35.3795 30.8844 35.3795 31.4222C35.3795 31.96 35.8185 32.4 36.3595 32.4C36.9004 32.4 37.3395 31.96 37.3395 31.4222C37.3395 30.8844 36.9004 30.4444 36.3595 30.4444Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M28.5196 42.2228C26.5126 42.2228 24.5036 41.4601 22.9749 39.9348C22.5927 39.5534 22.5927 38.9335 22.9749 38.5522C23.357 38.1708 23.9784 38.1708 24.3606 38.5522C26.6537 40.8402 30.3835 40.8402 32.6767 38.5522C33.0589 38.1708 33.6802 38.1708 34.0624 38.5522C34.4446 38.9335 34.4446 39.5534 34.0624 39.9348C32.5356 41.462 30.5266 42.2228 28.5196 42.2228Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
