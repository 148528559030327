import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useRootStore } from "../../state/stores/storeContext";
import { AboutCompany } from "./BasicComponents/AboutCompany";
import { GallerySection } from "./BasicComponents/GallerySection";
import { Introduction } from "./BasicComponents/Introduction";
import { JobBenefits } from "./BasicComponents/JobBenefits";
import { JobDescription } from "./BasicComponents/JobDescription";
import { JobSkills } from "./BasicComponents/JobSkills";
import { MediaTrailer } from "./BasicComponents/MediaTrailer";
import { ErrorModal } from "./BasicComponents/Modals/ErrorModal";
import { Snapshots } from "./BasicComponents/Snapshots";
import { Tabs } from "./BasicComponents/Tabs";
import { StyleForWrap, MainInformationWrapper } from './JobStory.presents';
import { PropsType } from './JobStory.props';
import { findJobTheme, jobStoryThemes } from "../JobStoryBuilder/BasicComponents/ThemeCustomizer/jobStoryThemes";
import { ApplyModal } from "./BasicComponents/Modals/ApplyModal";
import { ApplyJobSection } from "./BasicComponents/ApplyJobSection";
import { NotFound } from "../common/NotFound";

export const JobStory = observer((props: PropsType) => {
  const { jobStoryBuilder } = useRootStore();
  const jobAnswers = jobStoryBuilder.answers;
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [bannersUrl, setBannersUrl] = useState(jobStoryThemes[0].themeItems[0].images);
  const [openErrorWindow, setOpenErrorWindow] = useState(false);
  const [isBenefitsShow, setBenefitsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const urlFromThema = jobStoryThemes[activeColorIndex].themeItems.find(item => item.id === jobStoryBuilder.themeId)?.images;
    if (urlFromThema) {
      setBannersUrl(urlFromThema);
    }
  }, [activeColorIndex, jobStoryBuilder.themeId])

  useEffect(() => {
    if (jobStoryBuilder.themeId) {
      let colorIndex = findJobTheme(jobStoryBuilder.themeId);
      setActiveColorIndex(colorIndex);
    }
  }, [jobStoryBuilder.themeId])


  const componentRef: any = {
    SnapshotsRef: useRef(null),
    JobDescriptionRef: useRef(null),
    JobSkillsRef: useRef(null),
    JobBenefitsRef: useRef(null),
    AboutCompanyRef: useRef(null),
    GalleryRef: useRef(null),
  }


  const executeScroll = (refObject: any) => componentRef[refObject].current.scrollIntoView();


  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(history.location.search);
  }
  const query = useQuery();
  const jobId = query.get("id")

  const benefitList = [
    { value: 'healthcareOffered', title: 'Healthcare and wellness insurance benefits:' },
    { value: 'savingsOffered', title: 'Healthcare and wellness savings:' },
    { value: 'retirementSavingsOffered', title: 'Retirement savings benefits:' },
    { value: 'vacationOffered', title: 'Vacation and paid leave benefits:' },
    { value: 'learningOpportunitiesOffered', title: 'Learning opportunities or career development benefits:' },
    { value: 'officeOffered', title: 'Office, commute and travel benefits and perks:' },
    { value: 'volunteerAbout', title: 'What is this volunteer job about:' },
  ];

  useEffect(() => {
    (async () => {
      await jobStoryBuilder.getJobData(jobId);
      await benefitList.forEach(item => {
        if (jobAnswers.specificationStep[item.value] && jobAnswers.specificationStep[item.value].length > 0) {
          setBenefitsShow(true);
        }
      })
      setIsLoading(false)
    })()
  }, [jobId, jobStoryBuilder]);

  const applyWindowHandler = (isOpen: boolean) => {
    props.setOpenApplyWindow && props.setOpenApplyWindow(isOpen)
  }

  const organizationLogo = jobAnswers.contactStep.directEmployerLogo;
  const organizationName = jobAnswers.contactStep.staffingName || jobAnswers.contactStep.organizationName;

  if (!isLoading && !organizationName){
    return <NotFound />
  }
  return <StyleForWrap >
    <Introduction
      bannersUrl={bannersUrl}
      organizationName={organizationName}
      jobData={jobAnswers}
      companyLogo={organizationLogo}
    />
    <Tabs executeScroll={executeScroll} preview={props.preview} isBenefitsShow={isBenefitsShow} />
    <MainInformationWrapper>
      <MediaTrailer jobData={jobAnswers} />
      <Snapshots jobData={jobAnswers} componentRef={componentRef.SnapshotsRef} />
      <JobDescription bannersUrl={bannersUrl} jobData={jobAnswers} componentRef={componentRef.JobDescriptionRef} />
      <JobSkills bannersUrl={bannersUrl} jobData={jobAnswers} componentRef={componentRef.JobSkillsRef} />
      <JobBenefits bannersUrl={bannersUrl} jobData={jobAnswers} componentRef={componentRef.JobBenefitsRef} isBenefitsShow={isBenefitsShow} benefitList={benefitList} />
      <AboutCompany bannersUrl={bannersUrl} jobData={jobAnswers} companyLogo={organizationLogo} componentRef={componentRef.AboutCompanyRef} />
    </MainInformationWrapper>
    {!!jobAnswers.contactStep.teamPhotosFile.length && <GallerySection componentRef={componentRef.GalleryRef} mediaFiles={[...jobStoryBuilder.answers.contactStep.teamPhotosFile]} />}
    <ApplyJobSection onClick={() => applyWindowHandler(true)} />
    <ErrorModal openWindow={openErrorWindow}
      handleCloseWindow={() => { setOpenErrorWindow(false) }}
      text={'We are having trouble playing this audio. Please try again later.'}
      title={'Something went wrong'}
    />
    <ApplyModal openWindow={props.openApplyWindow} closeWindow={applyWindowHandler} jobId={jobId} />
  </StyleForWrap>
})