import { theme } from '../../../../theme';
import styled from 'styled-components';

export const PhotosList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 24px -18px;
`

export const PhotoItemContainer = styled('div')`
  padding: ${theme.offsets.lg};
`
