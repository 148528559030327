import { useRootStore } from "../../../../../state/stores/storeContext"
import { ItemBlock, Title } from "./SocialNetworks.presents"
import { signInWithPopup, linkWithPopup, getAuth } from "firebase/auth";
import { socialMediaAuth } from "../../../../../services/firebase";
import { useHistory } from "react-router-dom";

export const SocialNetworksItem = ({ title, icon }: { title: string, icon: JSX.Element, callback: Function }) => {
  const { candidateStore } = useRootStore();
  const history = useHistory()
  const auth = getAuth();
  const socialMediaAuthentication = async () => {
    let provider: any
    let authorizer: any
    switch (title) {
      case "Google":
        provider = socialMediaAuth.Google.provider;
        authorizer = socialMediaAuth.Google.auth;
        break
      case "Facebook":
        provider = socialMediaAuth.Facebook.provider;
        authorizer = socialMediaAuth.Facebook.auth;
        break
      }
      if (auth.currentUser) {
        await linkWithPopup(auth.currentUser, provider).then((result) => {
          const credential = authorizer.credentialFromResult(result);
          const user = result.user;
          candidateStore.linkSocialMediaAccount(user)
          history.push('/candidate/sign-up')
        }).catch((error) => {
        });
      }
      else {
        await signInWithPopup(auth, provider).then(function (result) {
          let user = result.user;
          candidateStore.loginWithSocialMedia(user)
          history.push('/candidate/sign-up')
          history.push({
            pathname: '/candidate/sign-up',
            state: { newUser: true  }
          })
        });
      }
  }
  return <ItemBlock onClick={socialMediaAuthentication} type='button'>
    {icon}
    <Title>
      {`Continue with ${title}`}
    </Title>
  </ItemBlock>
}