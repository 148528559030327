import closeIcon from '../../../../assets/img/close.svg'
import spinnerIcon from '../../../../assets/img/spinner.svg'
import { FileContainer, RemoveIcon, PosterBlock, FieldWrap, Spinner, ButtonWrap, VideoContainer } from './VideoPreview.presets'
import useGetDevice from '../../../../hooks/useGetDevice'
import { Field } from 'formik'
import { Input } from '../../Input'
import { SPBaseRadioButton } from '../../RadioButton/SPBaseRadioButton'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Player, Video } from '@vime/react'
import {ReactComponent as VideoUploadIcon} from '../../../../assets/img/videoUpload.svg';

interface VideoPreviewProps {
  url: string
  onRemove: any
  isLoading?: boolean
  values: any
  handleChange: Function
  itemIndex: number
  setFieldValue: Function
  removeFromForm: Function
}

export const VideoPreview = observer((props: VideoPreviewProps) => {
  const { handleChange, setFieldValue, itemIndex, values, isLoading, onRemove, url } = props
  const isDesktop = useGetDevice();

  const mainVideoHandler = (evant: any) => {
    handleChange(evant);
    setFieldValue(`videoGallery.${itemIndex}.isMain`, true)
  }

  const videoGalleryValues = values.videoGallery;
  const isValueExists = videoGalleryValues && videoGalleryValues[itemIndex];
  const isMainVideo = isValueExists && values.mainVideoKey === videoGalleryValues[itemIndex].key;

  const fileKey = isValueExists ? videoGalleryValues[itemIndex].key : '';
  const fileName = isValueExists ? videoGalleryValues[itemIndex].fileName : '';

  const isButtonChecked = isValueExists && (isMainVideo || videoGalleryValues[itemIndex].isMain);

  const isBtnAlreadyChecked = videoGalleryValues[itemIndex] && videoGalleryValues[itemIndex].isMain;

  useEffect(() => {
    //Set initial mainVideoKey value
    if(isBtnAlreadyChecked){
      setFieldValue('mainVideoKey', videoGalleryValues[itemIndex].key)
    }
  }, [])

  useEffect(() => {
    //Reset old mainVideo value
    setFieldValue(`videoGallery.${itemIndex}.isMain`, isMainVideo)
  }, [values.mainVideoKey])
  

const handleClick = () =>{
  // Remove value for already checked button
  if(isBtnAlreadyChecked){
    setFieldValue(`videoGallery.${itemIndex}.isMain`, false);
    setFieldValue('mainVideoKey', '');
  }
}

  return (
    <FileContainer>
      <PosterBlock isDesktop={isDesktop}>
        {isLoading && <Spinner src={spinnerIcon} />}
        {url && !isLoading && <>
          {isDesktop ? <VideoContainer>
            <Player aspectRatio={isDesktop ? '16:16' : '10:16'} controls={false} isMobile={false}>
              <Video preload='metadata' cross-origin id={`video${itemIndex}`} >
                <source
                  data-src={url}
                />
              </Video>
            </Player>
          </VideoContainer>:
          <VideoUploadIcon/>
          }
          <RemoveIcon type="button" onClick={onRemove}><img src={closeIcon} alt="delete" /></RemoveIcon>
        </>
        }
      </PosterBlock>
      <FieldWrap>
        <Field
          as={Input}
          name={`videoGallery.${itemIndex}.fileName`}
          type={'text'}
          value={fileName}
          maxLength={50}
          onChange={handleChange}
          labelText={'Title'} />
        <ButtonWrap>
          <SPBaseRadioButton
            handleClick={handleClick}
            checked={isButtonChecked}
            name='mainVideoKey'
            value={fileKey}
            disabled={isLoading || false}
            handleChange={mainVideoHandler}
            labelText={'Main video'} />
        </ButtonWrap>
      </FieldWrap>
    </FileContainer>
  )
})
