import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { Wrapper, LabelWrap, BenefitsSection } from "./Benefins.presents";
import { ReactComponent as PlusIcon } from '../../../../../assets/img/plusBlack.svg';
import { MainSectionProps } from "../../Organization.props";
import healthcareOptions from "../../../../../assets/staticData/benefits/healthcare.json";
import wellbeingPerksOptions from "../../../../../assets/staticData/benefits/savings.json";
import retirementOptions from "../../../../../assets/staticData/benefits/retirementAdditional.json";
import retirementUSOptions from "../../../../../assets/staticData/benefits/retirement.json";
import learningOptions from "../../../../../assets/staticData/benefits/learning.json";
import officeOptions from "../../../../../assets/staticData/benefits/office.json";
import vacationOptions from "../../../../../assets/staticData/benefits/vacation.json";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { Benefits } from "../../../../common/BenefitSection/Benefits";
import { useEffect, useState } from "react";
import { RemoveLabel } from "../RemoveLabel/RemoveLabel";
import { Description, HelperTextTitle, LabelContent } from "../../Organization.presents";
import { HelperText } from "../../../../common/HelperText";

export const Benefins = (props: MainSectionProps) => {
	const { componentRef, isDesktop, values, errors, handleChange, setFieldValue } = props;
	const benefitsValue = values.benefits;
	const benefitsData = [
		{ title: 'Healthcare insurance benefits', show: !!benefitsValue.healthcare, options: healthcareOptions, name: 'healthcare' },
		{ title: 'Well-being perks', show: !!benefitsValue.wellbeingPerks, options: wellbeingPerksOptions, name: 'wellbeingPerks' },
		{ title: 'International retirement savings benefits', show: !!benefitsValue.retirement, options: retirementOptions, name: 'retirement' },
		{ title: 'US retirement savings benefits', show: !!benefitsValue.retirementUS, options: retirementUSOptions, name: 'retirementUS' },
		{ title: 'Learning opportunities', show: !!benefitsValue.learning, options: learningOptions, name: 'learning' },
		{ title: 'Office, commute and travel benefits and perks', show: !!benefitsValue.travel, options: officeOptions, name: 'travel' },
		{ title: 'Vacation and paid leave benefits', show: !!benefitsValue.vacation, options: vacationOptions, name: 'vacation' }
	]

	const [mainData, setMainData] = useState(benefitsData);

	useEffect(() => {
		setMainData(benefitsData)
	}, [])

	const openSection = (index: number) => {
		const copy = [...mainData]
		copy[index].show = true;
		setMainData(copy);
	}

	const closeSection = (index: number) => {
		const copy = [...mainData];
		const fieldName = copy[index].name;
		copy[index].show = false;
		setMainData(copy);
		const benefitsCopy: any = {...benefitsValue};
		delete benefitsCopy[fieldName]
		setFieldValue('benefits', benefitsCopy )
	}

	const helperText = 'Select some of the common benefits and perks that you offer to your employees. This increases the chance of attracting top talent for your jobs.'
	const TitleRender = () => <LabelContent>
		<HelperTextTitle>
			<span>{'Common company benefits'}</span>
			<HelperText text={helperText} />
		</HelperTextTitle>
		<Description>
			Employee benefits may vary depending on the type of jobs and regulations in each state, county, territory and country
		</Description>
	</LabelContent>

	return <div id='orgBenefits'>
		<OrganizationCard title={<TitleRender />} componentRef={componentRef} isDesktop={isDesktop}>
			<Wrapper>
				{mainData.map((item, index) => {
					return item.show ? <BenefitsSection key={index}>
						<LabelWrap>
							<RemoveLabel callback={() => closeSection(index)} labelText={item.title} />
						</LabelWrap>
						<Benefits
							title=''
							fieldKey={item.name}
							benefitList={item.options}
							fieldName={`benefits.${item.name}`}
							handleChange={handleChange}
							initialValues={benefitsValue} />
					</BenefitsSection> : ''
				})}

				{mainData.map((item, index) => {
					return !item.show ? <SPSecondaryButton key={`btn${index}`} onClick={() => openSection(index)} width="auto" isLight type="button">
						<span>{item.title}</span>
						<PlusIcon />
					</SPSecondaryButton> : ''
				}
				)}
			</Wrapper>
		</OrganizationCard>
	</div>
}