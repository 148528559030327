import { theme } from './../../../../theme/theme';
import { colors } from '../../../../constants/colors';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IconType } from './Header.type';
import checkIcon from '../../../../assets/img/check.svg';
import changeThemeIcon from '../../../../assets/img/changeTheme.svg';

export const StepsRow = styled(Row)`
  padding: 0 20px;
  box-shadow: 8px 8px 16px rgba(106, 120, 150, 0.05);
  background: #fff;
  position: sticky;
  top: 0px;
  z-index: 8;
`

export const StepsWrap = styled('div')`
  max-width: 1720px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.offsets.lg} 0;
`

export const StyleForStepWrap = styled('div')`
{ 
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  ::after{
    content: '';
    width: 90px;
    height: 1px;
    background: #D1D7E3;
  }
  &.last::after{
    content: '';
    width: 0px;
    height: 0px;
  }
  &.done::after{
    background: ${colors.green};
  }
  &.done .step{
    background: ${colors.green};
    border: 1px solid ${colors.green};
    cursor:pointer;
  }
  &.done .step::before{
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    position: absolute;
    background: ${colors.green};
    border-radius: 100%;
    top: -1px;
    right: -7px;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
  &.done .step div{
    background: #fff;
  }
  &.active::after{
    background: ${colors.orange};
  }
  &.active .step{
    background: ${colors.orange};
    border: 1px solid ${colors.orange};
  }
  &.active .step div{
    background: #fff;
  }
};
`

export const StyleForStep = styled('div')`
{ 
  width: 46px;
  height: 46px;
  background: #F6F8FA;
  border: 1px solid #D1D7E3;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
};
`

export const StyleForStepIcon = styled('div') <IconType>`
{ 
  width: inherit;
  height: inherit;
  background: ${colors.dark};
  mask: url(${(props) => props.url}) no-repeat center;
};
`

export const StyleForStepTitle = styled('p')`
{ 
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${colors.dark};
  margin-top: 5px;
  position: absolute;
  bottom: -21px;
  white-space: nowrap;
};
`

export const ThemeBtn = styled('div')`
  display: flex;
  mask-image: url(${changeThemeIcon});
  height: 24px;
  width: 24px;
  background: ${theme.colors.black};
`

export const ThemeBtnWrap = styled('button')` 
  display: flex;
  background: none;
  border: 1px solid #D1D7E3;
  filter: drop-shadow(8px 8px 16px rgba(95, 120, 172, 0.05));
  border-radius: 8px;
  height: 52px;
  width: 160px;
  align-items: center;
  justify-content: start;
  margin-right: 24px;
  padding: 0 ${theme.offsets.lg};
  &:hover{
    border: 1px solid ${theme.colors.lightOrange};
    background: rgba(236, 146, 60, 0.1);
    div{
      background: ${theme.colors.lightOrange};
    }
    span{
      color: ${theme.colors.lightOrange};
    }
  }
`

export const BackToListBtn = styled(Link)`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.dark};
  display: flex;
  align-items: center;
  img{
    margin-right: ${theme.offsets.sm};
  }
  &:hover{
    color: #7B7875;
  }
`

export const StepsProgressContainer = styled('div')`
  display: flex;
  padding: 0 ${theme.offsets.md};
`

export const ButtonsContainer = styled('div')`
  display: flex;
  min-width: 200px;
`

export const BtnsWrap = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0px;
`

export const PublishBtnWrap = styled('div')`
  width: 165px;
`

export const ThemeBtnText = styled('span')`
  font-size: ${theme.fontSizes.lg};
  margin-left: ${theme.offsets.sm};
  color: ${theme.colors.darkGray};
  white-space: nowrap;
`