import { LikeIcon } from '../../../../assets/img/jobstoryIcons/active/Like'
import { DislikeIcon } from '../../../../assets/img/jobstoryIcons/active/Dislike'
import { OptionalIcon } from '../../../../assets/img/jobstoryIcons/active/Optional'
import { PaidIcon } from '../../../../assets/img/jobstoryIcons/active/Paid'
import { UnpaidIcon } from '../../../../assets/img/jobstoryIcons/active/Unpaid'
import { AnnualSalaryIcon } from '../../../../assets/img/jobstoryIcons/active/AnnualSalary'
import { HourlyWageIcon } from '../../../../assets/img/jobstoryIcons/active/HourlyWage'
import { CommissionIcon } from '../../../../assets/img/jobstoryIcons/active/Commission'

import { useEffect, useState } from 'react'

export const ActiveIcon = ({ name, checked }: { name: any, checked: boolean }) => {
  const [icon, setIcon] = useState<any>(<LikeIcon active={checked} />);
  
  useEffect(() => {
    const iconsList: any = {
      yes: <LikeIcon active={checked} />,
      no: <DislikeIcon active={checked} />,
      yesEquity: <LikeIcon active={checked} />,
      fixedBudgetYes: <LikeIcon active={checked} />,
      optional: <OptionalIcon active={checked} />,
      paidInternship: <PaidIcon active={checked} />,
      unpaidInternship: <UnpaidIcon active={checked} />,
      annualSalary: <AnnualSalaryIcon active={checked} />,
      hourlyWage: <HourlyWageIcon active={checked} />,
      commission: <CommissionIcon active={checked} />,
    }

    setIcon(iconsList[name] ? iconsList[name] : <div></div>)

  }, [name, checked])

  return icon

}