import { theme } from '../../theme/theme';
import styled from 'styled-components';


export const StyleForWrap = styled('div')`
margin: ${theme.offsets.md} ${theme.offsets.huge};
@media(max-width: ${theme.screens.desktop}) {
  padding: ${theme.offsets.lg} 0;
  margin: ${theme.offsets.md} ${theme.offsets.xxl};
}
@media(max-width: ${theme.screens.tablet}) {
  margin: 0px;
  margin-bottom: 0;
  padding: ${theme.offsets.md} 0;
}
@media(max-width: ${theme.screens.mobile}) {
  margin: 0px;
  padding: ${theme.offsets.sm} 0;
}
`

export const TabsWrap = styled('div')`
  background: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  width: 100%;
  padding-top: 5px;
  overflow-x: scroll;
  -ms-overflow-style: none;
	scrollbar-width: none;
  margin-bottom: ${theme.offsets.lg}; 
	&::-webkit-scrollbar {
		display: none;
	}
  @media(max-width: ${theme.screens.mobile}) {
    background: ${theme.colors.transparent};
    box-shadow: none;
  }
`

export const TabContent = styled('div')`
width:95%;
margin: 0 auto;
@media(max-width: ${theme.screens.mobile}) { 
width: auto;
}
`

export const TabSection = styled('div') <{ isDesktop: boolean, isRightSection: boolean }>`
padding-right: ${(props) => props.isRightSection ? '0px' : theme.offsets.md };
padding-left: ${(props) => props.isRightSection ? theme.offsets.md : '0px'};
width: 100%;
  ${(props) => !props.isDesktop && `
    @media(max-width: ${theme.screens.tablet}) { 
    padding: ${theme.offsets.xxl};
    }
    @media(max-width: ${theme.screens.mobile}) { 
      padding: ${theme.offsets.lg};
      }
  `}; 
`

export const DescriptionSection = styled('div') <{ isDesktop: boolean }>`
  max-width: ${(props) => props.isDesktop ? '544px' : '100%'};
  width: 100%;
`

export const ContentWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.isDesktop ? 'row' : 'column'}; 
`

export const AllJobsButtonWrap = styled('div')`
  max-width: 173px;
  @media(max-width: ${theme.screens.tablet}) { 
    max-width: 160px;
  }
  @media(max-width: ${theme.screens.mobile}) { 
    max-width: 100%;
  }
`

export const WrapForLink = styled('div')`
width: 50%;
  a{
    width: 100%;
    height: 100%;
    display: block;
  }
`