import { Field, FieldArray, Formik } from "formik";
import { useState } from "react";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { FieldWrap } from "../../../../../theme/typography";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { ErrorMsg } from "../../../Profile/Profile.presents";
import { socialNetworksList } from "../../helper";
import { ContentWrap, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ButtonWrap } from "../PersonalInfo/PersonalInfo.presents";
import { RemoveLabel } from "../RemoveLabel/RemoveLabel";
import { ListModal } from "./ListModal";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg";
import { Input } from "../../../../common/Input";
import { SocialField } from "./SocialNetworks.presents";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';

export const SocialNetworks = observer(({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const selectedNetworks = resumeBuilderStore.socialNetworks.map((item) => item.title);
  const initialValues = { socialNetworks: resumeBuilderStore.socialNetworks, selectedNetworks };
  const showAddBtn = initialValues.socialNetworks.length < socialNetworksList.length;

  return <CandidateFormCard
    isDesktop={isDesktop}
    componentRef={componentRef}
    title={'Website & Social Networks'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveSocialNetworks({ socialNetworks: values.socialNetworks }, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          handleChange
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="socialNetworks">{({ remove }) => {

                return <MultiFieldWrap hasWrap>
                  {values.socialNetworks.map((item, index) => {

                    const removeNetworkItem = (remove: Function, index: number, key: string) => {
                      const selectedNetworksCopy = [...values.selectedNetworks]
                      selectedNetworksCopy.splice(index, 1);
                      setFieldValue('selectedNetworks', selectedNetworksCopy)
                      remove(index);
                      setShowSaveBtn(true);
                    };
                    const onChangeHandler = (value: any) => {
                      setShowSaveBtn(true);
                      handleChange(value);
                    };
                    return <SocialField isDesktop={isDesktop} key={`socialField${index}`} isSingleItem={socialNetworksList.length === 1} >
                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`socialNetworks.${index}.url`}
                          type={'text'}
                          maxLength={100}
                          value={values.socialNetworks[index].url}
                          onChange={onChangeHandler}
                          labelText={<RemoveLabel labelText={item.title} callback={() => removeNetworkItem(remove, index, item.title)} />}
                        />
                        <ErrorMsg></ErrorMsg>
                      </FieldWrap>
                    </SocialField>
                  }
                  )}
                </MultiFieldWrap>
              }}
            </FieldArray>
            <ButtonWrap>
              <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => setModalOpen(true)}>
                Add Social Network Link
                <PlusIcon />
              </SPSecondaryButton>
            </ButtonWrap>
            {isModalOpen && <ListModal
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              socialNetworks={values.socialNetworks}
              selectedNetworks={values.selectedNetworks}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              isDesktop={isDesktop} />}

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}
          </form>}
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
})