import { ReactElement, useState } from "react";
import { Wrapper, BurgerMenu, MenuLine, LogoWrap } from "./MobileAdminHeader.presents";
import { ReactComponent as Logo } from '../../../../../assets/img/logo.svg';
import { MobileSidebar } from "../../../../common/Sidebars/AdminSidebar/Mobile/MobileSidebar";

export const MobileCandidateHeader = ({
  additionalButtons
}: {
  additionalButtons?: ReactElement | null
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return <Wrapper>
    <BurgerMenu isOpen={isMenuOpen} id={"burger-menu-id"} onClick={() => setMenuOpen(!isMenuOpen)}>
      <MenuLine />
      <MenuLine />
      <MenuLine />
    </BurgerMenu>
    <LogoWrap to={'/candidate/resume-builder'} isIncludesNav={!!additionalButtons}>
      <Logo />
    </LogoWrap>
    {additionalButtons && additionalButtons}
    <MobileSidebar isOpen={isMenuOpen} closeMenu={() => setMenuOpen(false)} isCandidateService />
  </Wrapper>
}
