import styled from "styled-components";
import { theme } from "../../../../../../theme";
import CoverImage from "../../../../../../assets/img/candidateSignUpCover.svg";
import laptopBanner from "../../../../../../assets/img/laptopBanner.svg";
import tabletBanner from "../../../../../../assets/img/tabletBanner.svg";
import mobileBanner from "../../../../../../assets/img/mobileBanner.svg";


export const Wrapper = styled('div')`

`

export const ContentWrap = styled('div')`
  margin: 0 auto;
  max-width: 1840px;
  padding: 0 80px;
  position: relative;
  z-index: 1;
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0 ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.lg};
  }
`

export const CoverBlock = styled('div') <{ isDesktop: boolean }>`
  width: 100%;
  background-image: ${(props) => props.isDesktop ? 'none' : `url(${CoverImage})`};
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding-top: ${theme.offsets.xl};
  video{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  video::-webkit-media-controls {
    display:none !important;
  }
  @media(max-width: ${theme.screens.tablet}) {
    height: 590px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 278px;
  }
`

export const PageDescription = styled('div')`
  background-color: ${theme.colors.blue};
  height: 932px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button{
    max-width: 240px;
    margin: 0 auto;
    display: block;
  }
  @media(max-width: ${theme.screens.desktop}) {
    height: 621px;
    button{
     max-width: 210px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    height: 516px;
    button{
      max-width: 170px;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 440px;
    button{
      max-width: 145px;
    }
  }
`

export const PageSubtitle = styled('p') <{ isLight?: boolean }>`
  color: ${props => props.isLight ? theme.colors.white : theme.colors.black};
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 11px;
  }
`

export const PageDescriptionTitle = styled('p')`
  color: ${theme.colors.white};
  text-align: center;
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  width: 85%;
  margin: ${theme.offsets.xxl} auto 64px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraMajor};
    line-height: 56px;
    margin: ${theme.offsets.xxl} auto ${theme.offsets.extraMajor};
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 100%;
    font-size: ${theme.fontSizes.major};
    line-height: ${theme.fontSizes.extraMajor};
    margin: ${theme.offsets.lg} auto ${theme.offsets.extraMajor};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
    line-height: ${theme.fontSizes.extraBig};
  }
`

export const BenefitsSection = styled('div')`
  background-color: ${theme.colors.white};
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(max-width: ${theme.screens.desktop}) {
    padding: 160px 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 360px 0 120px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 430px 0 64px;
  }
`

export const BenefitsSectionTitle = styled('p')`
  color: ${theme.colors.black};
  text-align: center;
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  margin: ${theme.offsets.xxl} auto ${theme.offsets.huge};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraMajor};
    line-height: 56px;
    margin: ${theme.offsets.xxl} auto 80px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.major};
    line-height: ${theme.fontSizes.extraMajor};
    margin: ${theme.offsets.lg} auto 64px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
    line-height: ${theme.fontSizes.extraBig};
    margin: ${theme.offsets.sm} auto ${theme.offsets.extraBig};
  }
`

export const InfoSection = styled('div')`
  background-color: #EEF6FE;
  height: 1080px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  >div{
    width: 100%;
  }
  ::before{
    content: '';
    position: absolute;
    display: block;
    background-size: contain;
    background-image: url(${laptopBanner});
    height: 100%;
    width: 60%;
    background-repeat: no-repeat;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    background-position: 50%;
  }
  button{
    max-width: 240px;
    display: block;
    margin-top: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.desktop}) {
    height: 720px;
    ::before{
      width: 50%;
    }
    button{
     max-width: 210px;
     margin-top: ${theme.offsets.xxl};
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    ::before{
      background-image: url(${tabletBanner});
      width: 45%;
    }
    height: 432px;
    button{
      max-width: 170px;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 660px;
    justify-content: flex-start;
    padding-top: ${theme.offsets.extraMajor};
    ::before{
      background-image: url(${mobileBanner});
      width: 100%;
      height: 300px;
      top: auto;
      bottom: 0;
      right: 50%;
      transform: translate(50%, 0%);
    }
    button{
      max-width: 145px;
      margin-top: ${theme.offsets.md};
    }
  }
`

export const InfoContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export const InfoSectionTitle = styled('p')`
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: ${theme.fontSizes.major};
  line-height: 44px;
  margin-bottom: ${theme.offsets.extraBig};
  max-width: 330px;
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: ${theme.offsets.xxl};
    font-size: ${theme.fontSizes.extraBig};
    line-height: 36px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xxl};
    line-height: ${theme.fontSizes.big};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
    line-height: ${theme.fontSizes.extraBig};
    max-width: 100%;
  }
`

export const InfoSectionDescription = styled('ul')`
margin-left: ${theme.offsets.lg};
  li{
    list-style: disc;
    margin-bottom: ${theme.offsets.lg};
    font-size: 18px;
    @media(max-width: ${theme.screens.desktop}) {
      font-size: 14px;
      max-width: 350px;
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin-bottom: ${theme.offsets.sm};
      font-size: ${theme.fontSizes.md};
      max-width: 100%;
    }
  }
`