import styled from 'styled-components';
import { theme } from '../../../theme';

const DESKTOP_ITEM_HEIGHT = '140px';
const MOBILE_ITEM_HEIGHT = '115px';

const styleForBlurBox = `
content: '';
display: block;
background-color: #F6F8FA;
height: 16px;
border-radius: ${theme.variables.borderRadiusXLarge};
`;

export const shineAnimation = `
@keyframes shineEffect {
  from {
    top: -110%;
    left: -210%;
  }

  to {
    top: -65%;
    left: 100%;
  }
};
`;

export const shineLine = `
content: "";
position: absolute;
animation-duration: 1500ms;
animation-iteration-count: infinite;
animation-timing-function: linear;
animation-name: shineEffect;
width: 50%;
height: 200%;
transform: rotate(30deg);
z-index: 98;
background: rgba(255, 255, 255, 0.13);
background: linear-gradient( to right, rgba(255,255,255,0.01) -1%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.0) 100%);
`

export const Wrap = styled("div")`
  width: 100%;
  overflow: hidden;
  position: relative;
  ::after{
    ${shineAnimation}
    ${shineLine}
    }
`

export const SkeletItem = styled("div") <{ isDesktop: boolean }>`
  border-bottom: 1px solid ${theme.colors.lightGray};
  height: ${(props) => props.isDesktop ? DESKTOP_ITEM_HEIGHT : MOBILE_ITEM_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  ${(props) => !props.isDesktop && `
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
    border-radius: ${theme.variables.borderRadiusLarge};
    margin-bottom: ${theme.offsets.lg};
    flex-direction: column;
    border-bottom: none;
    justify-content: start;
    align-items: flex-start;
    padding: ${theme.offsets.lg};
  `}
  ::before{
    ${styleForBlurBox}
    width: ${(props) => props.isDesktop ? '88%' : '100%'};
    margin-bottom: ${(props) => props.isDesktop ? '0' : theme.offsets.lg};
  }
  ::after{
    ${styleForBlurBox}
    width: ${(props) => props.isDesktop ? '4%' : '40%'};
  }
`

export const MobileRow = styled("div")`
  ${styleForBlurBox}
  width: 100%;
  margin-bottom: ${theme.offsets.lg};
`