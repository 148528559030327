import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	& button{
  margin-right: ${theme.offsets.lg};
  margin-top: ${theme.offsets.lg};
  width: auto;
  padding-left: ${theme.offsets.lg};
  padding-right: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    padding-left: ${theme.offsets.lg};
    padding-right: ${theme.offsets.lg};
  }
  svg{
    margin-left: ${theme.offsets.sm};
    @media(max-width: ${theme.screens.tablet}) {
      width: 16px;
      height: 16px;
    }
  }
}
`;

export const InputsWrap = styled('div')`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
  width: 100%;
`

export const FieldWrap = styled('div') <{ isDesktop: boolean, isSingleItem: boolean }>`
	max-width: ${(props) => !props.isDesktop || props.isSingleItem ? '100%' : '266px'} ;
	width: 100%;
`

export const ErrorMsg = styled('div')`
  color: ${theme.colors.red};
  margin-top: ${theme.offsets.xs};
  height: 12px;
  font-size: ${theme.fontSizes.md};
  display: block;
`

export const ListWrap = styled('div')<{ isDesktop: boolean }>`  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${theme.offsets.xxl};
  ${(props) => !props.isDesktop && `
      @media(max-width: ${theme.screens.mobile}) {
        overflow-y: scroll;
        max-height: 70vh;
      } 
    `}
  ::after{
    content: '';
    width: 100%;
    display: block;
    height: 1px;
    margin-top: -1px;
    background: ${theme.colors.white};
  }
`

export const ListItemWrap = styled('div') <{ isDesktop: boolean }>`
 width: 47%;
 border-bottom: 1px solid ${theme.colors.lightGray};
 padding: ${theme.offsets.xl} 0;
 display: flex;
 ${(props) => !props.isDesktop && 'width: 100%;'}
 input{
   width: 100%;
 }
  label{
    width: fit-content;
    font-size: ${theme.fontSizes.lg};
    font-weight: 400;
    @media(max-width: ${theme.screens.tablet}) {
      font-size: 14px;
    }
  }
`

export const BtnsWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  margin-top: ${theme.offsets.extraMajor};
  button{
    width: 190px;
    margin-left: ${theme.offsets.xxl};
    ${(props) => !props.isDesktop && `
      width: 120px;
      margin-left: ${theme.offsets.md};
    `}
  }
  
`
