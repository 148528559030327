import styled from 'styled-components'
import { theme } from '../../../theme'

export const StyleForWrap = styled('div')`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.offsets.lg} 100px ${theme.offsets.lg} ${theme.offsets.xxl};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px 14px 14px 14px ;
  margin-bottom: ${theme.offsets.xxl};
  padding: ${theme.offsets.md} 0;
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.lg} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.md} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.sm} 0;
  }
`

export const Content = styled('div')`
width:90%;
margin: 0 auto; 
padding-bottom: ${theme.offsets.lg};
@media(max-width: ${theme.screens.mobile}) { 
width: auto;
`

export const Center = styled('div')`
display: flex; 
justify-content: center;
`