import { Field, Formik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import { useRootStore } from "../../../state/stores/storeContext";
import { Avatar } from "../../common/Avatar";
import avatarIcon from '../../../assets/img/avatar.svg';
import { Input } from "../../common/Input";
import { ErrorMsg, FieldLabel, FieldWrap, PhoneInputWrap, SubmitButtonWrap, FormContent } from "./Profile.presents";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { userNameValidation } from "../../../services/validationSchemas";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import { observer } from "mobx-react-lite";
import { ConfirmPasswordModal } from "./ConfirmPasswordModal";
import { onReauthenticate, onUpdateEmail } from "../../../services/firebase/auth";
import { CandidateFormCard } from "../../common/Cards/CandidateFormCard/CandidateFormCard";
import { ReactComponent as Spinner } from "../../../assets/img/simpleSpinner.svg";

export const PersonalInfoForm = observer((
  {
    isDesktop,
    setResponseResult,
    setResponseResultText,
    setModalOpen,
    componentRef
  }:
    {
      isDesktop: boolean,
      setResponseResult: Function,
      setResponseResultText: Function,
      setModalOpen: Function,
      componentRef: React.RefObject<HTMLDivElement>;
    }) => {
  const { candidateStore } = useRootStore();
  const { t } = useTranslation();

  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(candidateStore.phone);
  const [phoneFieldClass, setPhoneFieldClass] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordWindowOpen, setIsPasswordWindowOpen] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<null | string>(null);

  const logoUrl = candidateStore.logo ? candidateStore.logo.url : '';
  const {
    firstName,
    lastName,
    email,
    phone
  } = candidateStore;

  const initialValues = {
    firstName,
    lastName,
    email,
    phone
  };

  const onConfirmPassword = async (password: string, values: typeof initialValues) => {
    try {
      const firebaseResponse: any = await onReauthenticate(email, password);
      if (firebaseResponse.message) {
        setConfirmPasswordError('Email or password is incorrect');
      } else {
        const result = await onUpdateEmail(values.email);
        if (result?.message) {
          setIsPasswordWindowOpen(false);
          setResponseResult("error");
          setResponseResultText(result.message);
          setModalOpen(true);
        } else {
          setConfirmPasswordError(null);
          setIsPasswordWindowOpen(false);
          onSubmit(values);
        }
        setIsFirstSubmitComplete(false);
      }
    } catch (e) {
      setConfirmPasswordError('Email or password is incorrect');
    }
  }

  const onCheckPassword = (values: typeof initialValues) => {
    setIsFirstSubmitComplete(true);
    if (values.email !== candidateStore.email) {
      setIsPasswordWindowOpen(true);
    } else {
      onSubmit(values);
    };
  }
  const onSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    const result = await candidateStore.updatePersonalInfo({ ...values, phone: phoneNumber });
    setResponseResult(result.error ? 'error' : 'successfully');
    setModalOpen(true);
    setIsLoading(false);
  };

  return <CandidateFormCard title={'Account Information'} isDesktop={isDesktop} componentRef={componentRef}>
    <Avatar
      url={logoUrl}
      placeholder={avatarIcon}
      onUpload={candidateStore.uploadUserLogo}
      onDelete={candidateStore.removeUserLogo}
      btnText={'profile picture'}
    />
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={isFirstSubmitComplete}
      validateOnBlur={false}
      validate={values => {
        const errors: any = {}
        if (!isValidPhoneNumber(phoneNumber)) {
          setPhoneFieldClass('error')
          errors.phone = t('validation.phone');
        } else {
          setPhoneFieldClass('')
        }
        return errors;
      }}
      validationSchema={
        Yup.object().shape({
          firstName: userNameValidation(t),
          lastName: userNameValidation(t),
          email: Yup.string().email(t('validation.email')).required(t('validation.required')),
        })
      }
      onSubmit={values => onCheckPassword(values)}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => <FormContent onSubmit={handleSubmit}>
          <FieldWrap>
            <Field
              as={Input}
              name={'firstName'}
              type={'text'}
              value={values.firstName}
              onChange={handleChange}
              maxLength={50}
              labelText={'First Name'}
              error={errors.firstName} />
            <ErrorMsg>{errors.firstName}</ErrorMsg>
          </FieldWrap>

          <FieldWrap>
            <Field
              as={Input}
              name={'lastName'}
              type={'text'}
              value={values.lastName}
              onChange={handleChange}
              maxLength={50}
              labelText={'Last Name'}
              error={errors.lastName} />
            <ErrorMsg>{errors.lastName}</ErrorMsg>
          </FieldWrap>

          <FieldWrap>
            <FieldLabel htmlFor="phone">Phone number</FieldLabel>
            <Field
              component={PhoneInputWrap}
              className={phoneFieldClass}
              name={'phone'}
              value={phoneNumber}
              onChange={setPhoneNumber}
              international
              defaultCountry="US"
              isDesktop={isDesktop}
            />
            <ErrorMsg>{errors.phone}</ErrorMsg>
          </FieldWrap>

          <FieldWrap>
            <Field
              as={Input}
              disabled={!!candidateStore.isRegisteredBySocMedia}
              name={'email'}
              type={'text'}
              value={values.email}
              onChange={handleChange}
              maxLength={50}
              labelText={'Email'}
              error={errors.email} />
            <ErrorMsg>{errors.email}</ErrorMsg>
          </FieldWrap>
          <SubmitButtonWrap isLoading={isLoading}>
            <SPSecondaryButton width="100%" type="submit" onClick={() => setIsFirstSubmitComplete(true)} disabled={isLoading}>
              {isLoading ? <Spinner /> : "Save Changes"}
            </SPSecondaryButton>
          </SubmitButtonWrap>
          <ConfirmPasswordModal
            isDesktop={isDesktop}
            isPasswordWindowOpen={isPasswordWindowOpen}
            setIsPasswordWindowOpen={setIsPasswordWindowOpen}
            onConfirmPassword={onConfirmPassword}
            values={values}
            confirmPasswordError={confirmPasswordError}
          />
        </FormContent>
      }</Formik >
  </CandidateFormCard>
})