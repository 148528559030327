import styled from "styled-components";
import { theme } from "../../../../theme";

export const Wrapper = styled('button')`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	background: none;
	border: none;
	@media(max-width: ${theme.screens.tablet}) {
		margin-right: ${theme.offsets.lg};
	}
`
export const Title = styled('span')`
    color: ${theme.colors.black};
	margin: 3px ${theme.offsets.sm} 0;
	font-weight: 500;
	font-size: ${theme.fontSizes.lg};
	@media(max-width: ${theme.screens.tablet}) {
		font-size: 14px;
	}
`

export const List = styled('div')`
  position: absolute;
  flex-direction: column;
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  bottom: -152px;
  width: 100%;
  right: 0px;
  z-index: 11;
  overflow: hidden;
	@media(max-width: ${theme.screens.tablet}) {
		bottom: -132px;
	}
`

export const MenuItem = styled('p') <{ isWarning?: boolean, isActive: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.isWarning ? theme.colors.red : theme.colors.black};
  margin: 0 ${theme.offsets.xxl};
  padding: ${theme.offsets.xxl} 0;
  border-bottom: ${(props) => props.isWarning ? 'none' : `1px solid ${theme.colors.lightGray}`};
  position: relative;
  transition: 300ms;
  ::after{
    content: "";
    display: block;
    width: 150%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props=> props.isActive ? 'rgba(236,146,60,0.1)' : 'none' };
  }
  :hover::after{
    background-color: rgba(236,146,60,0.1);
  }
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.lg};
    margin: 0 ${theme.offsets.xl};
    padding: ${theme.offsets.xl} 0;
  }
`

export const ArrowIconWrap = styled('div') <{ isListOpen: boolean }>`
${(props)=>props.isListOpen && `
    transform: rotate(180deg);
  `}
`