import { colors } from './../../../../constants/colors';
import styled from 'styled-components';
import closeIcon from '../../../../assets/img/closeWhite.svg';
import { mainStyleType } from '../../../../themes/mainStylesType'

export const StyleForWrap = styled('div') <mainStyleType>`
width:100%;
max-width: 606px;
margin: ${props => props.m ? props.m : '64px auto 0'};
.react-tag-input{
  background-color: #fff;
  overflow: hidden;
  padding: 2px 4px;
  min-height: 52px;
  border: 1px solid ${props => props.error ? colors.red : '#D1D7E3'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.react-tag-input:hover{
  border-color: hsl(0, 0%, 70%);
}
.react-tag-input__tag__content{
  outline: none;
}
.react-tagsinput span:nth-of-type(1) {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.react-tag-input__tag {
  background: #EC923C;
  border-radius: 6px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin: 4px;
  padding: 10px;
  width: fit-content!important;
  display: inline-flex;
  width: fit-content;
  align-items: center;
  z-index: 1;
  ${(props)=>props.disabled && `
    background: #D1D7E3;
  `}
}

.react-tag-input__tag:focus-within{
  background: #F8DDC3;
}

.react-tag-input__tag:focus-within .react-tag-input__tag__content{
  color: ${colors.dark};
}

.react-tag-input__tag__remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tag-input__tag__remove{
  content: "";
  width: 12px;
  height: 12px;
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  margin-left: 9px;
}

.react-tag-input__input {
  background: transparent;
  border: 0;
  color: ${colors.dark};
  font-size: 16px;
  line-height: 20px;
  outline: none;
  flex: 1;
  height: 52px;
  padding: 0 8px;
}
`

export const StyleForLabel = styled("label")`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:8px;
  text-transform: capitalize;
  width: 100%;
  color: ${colors.dark};
`
