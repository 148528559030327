import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { Locations } from "../../../../common/FieldArrays/Locations/Locations";
import { Wrapper } from "./Contacts.presents";
import { Contacts } from "../../../../common/FieldArrays/Contacts/Contacts";

export const ContactsBlock = (props: { componentRef: React.RefObject<HTMLDivElement>, isDesktop: boolean, values: any, errors: any, handleChange: Function, setFieldValue: Function }) => {
  const { componentRef, isDesktop, values, errors, handleChange, setFieldValue } = props;

  const propsData = {
    setFieldValue,
    handleChange,
    values,
    errors
  }

  return <OrganizationCard title={'Contact Information'} componentRef={componentRef} isDesktop={isDesktop}>
    <Wrapper>
      <Locations {...propsData} />
      <Contacts {...propsData} />
    </Wrapper>
  </OrganizationCard>
}