import { theme } from '../../../../../../theme/theme';
import styled from 'styled-components'
import { Input } from '../../../../../common/Input'

export const StyleForCodeInput = styled(Input)`
input{
  width: 71px;
  height: 71px;
  font-size: 18px;
  @media(max-width: ${theme.screens.tablet}) {
    width: 53px;
    height: 53px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 41px;
    height: 41px;
  } 
}
input:focus  { 
  border: 1px solid #2D353D;
}
`

export const ResetBtn = styled('button')`
  background: none;
  border: none;
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  line-height: ${theme.fontSizes.xxl};
  color: ${theme.colors.blue};
  margin-bottom: ${theme.offsets.extraBig};
  margin-top: ${theme.offsets.xs};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.sm};
    order: 5;
  }
`

export const WindowText = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  line-height: ${theme.fontSizes.big};
  margin-top: ${theme.offsets.xxl};
`

export const WindowContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`

export const FormWrap = styled('div')`
form{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media(max-width: ${theme.screens.mobile}) {
    align-items: center;
  }
}
`

export const DescrtiptionText = styled('p')`
  font-size: 18px;
  margin: ${theme.offsets.lg} auto ${theme.offsets.extraBig};
  text-align: center;
  width: 75%;
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 14px;
    margin: ${theme.offsets.lg} ${theme.offsets.sm} ${theme.offsets.extraBig};
  }
`

export const BackButtonWrap = styled('div')`
 button{
  color: ${theme.colors.blue};
 }
`