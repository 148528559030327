import { observer } from 'mobx-react-lite';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { BenefitsWrap } from '../Jobs/Jobs.presents';
import { OrgProps } from '../Organization.props';
import { renderBenefitPreview } from './helper';
import { benefitsCount } from "../BenefitsPreview/helper";
import { EmptyBenefits } from '../Benefits/EmptyBenefits';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { BenefitsProps } from './Benefits.props';
import { WrapForButton } from './Benefits.presents';


export const BenefitsPreview = observer((props: BenefitsProps) => {
    const {
        setActiveTab,
        isDesktop,
        tabsComponentRef,
        benefits,
    } = props;
    const openGalleryTab = () => {
        setActiveTab('benefits');
        tabsComponentRef.current?.scrollIntoView();
    }
    return (
        <OrganizationPreviewCard isDesktop={isDesktop} title={'Common Benefits'} >
            {benefitsCount(benefits) > 0 ?
                <>
                    <BenefitsWrap>
                        {renderBenefitPreview(benefits)}
                    </BenefitsWrap>
                    <WrapForButton>
                        <SPWhiteButton onClick={openGalleryTab}>See All Benefits </SPWhiteButton>
                    </WrapForButton>
                </>

                :
                <EmptyBenefits />
            }
        </OrganizationPreviewCard>
    )
})