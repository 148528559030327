import { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import { StyleForAudioPlayer } from "./MediaTrailer.presents";

const formWaveSurferOptions = (ref: any) => ({
  container: ref,
  waveColor: "#6C7277",
  progressColor: "#0083FF",
  //cursorColor: "#fff",
  barWidth: 2,
  barRadius: 0,
  responsive: true,
  height: 72,
  barGap: 3,
  barHeight: 1,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
  plugins: [

  ]
});

export default function Waveform({ url }: { url: string }) {
  const waveformRef = useRef(null);
  let wavesurfer = useRef<any>(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url, volume]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  // const onVolumeChange = (e: any) => {
  //   const { target } = e;
  //   const newVolume = +target.value;

  //   if (newVolume) {
  //     setVolume(newVolume);
  //     wavesurfer.current.setVolume(newVolume || 1);
  //   }
  // };

  return (
    <StyleForAudioPlayer >
      <div className="controls">
        <button className='play-btn' onClick={handlePlayPause}>{!playing ? <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5343 6.16496C12.1552 6.53417 12.1552 7.46583 11.5343 7.83504L1.38271 13.8719C0.766593 14.2382 -3.1018e-08 13.7753 0 13.0368L5.07135e-07 0.963184C5.38153e-07 0.224721 0.766594 -0.238241 1.38271 0.12814L11.5343 6.16496Z" fill="#fff" />
        </svg>
          : <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 18.3333C0 19.2538 0.895431 20 2 20C3.10457 20 4 19.2538 4 18.3333V1.66667C4 0.746192 3.10457 0 2 0C0.895431 0 0 0.746192 0 1.66667V18.3333Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18.3333C10 19.2538 10.8954 20 12 20C13.1046 20 14 19.2538 14 18.3333V1.66667C14 0.746192 13.1046 0 12 0C10.8954 0 10 0.746192 10 1.66667V18.3333Z" fill="white" />
          </svg>
        }</button>
        {/* <input
          type="range"
          id="volume"
          name="volume"
          // waveSurfer recognize value of `0` same as `1`
          //  so we need to set some zero-ish value for silence
          min="0.01"
          max="1"
          step=".025"
          onChange={onVolumeChange}
          defaultValue={volume}
        />
        <label htmlFor="volume">Volume</label> */}
      </div>
      <div id="waveform" ref={waveformRef} />
    </StyleForAudioPlayer>
  );
}