import styled from "styled-components";
import { theme } from "../../../../theme";

export const ButtonWrap = styled('div')`
    max-width: 600px;
    margin-top: ${theme.offsets.xl};
`

export const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${theme.colors.mediumGray};
    max-width: 606px;
    margin: 0 auto;
`