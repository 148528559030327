import styled from "styled-components"
import { BaseButton } from "../SPBaseButton/SPBaseButton.presets"
import { theme } from "../../../../theme"

export const LightButton = styled(BaseButton)`
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.white};
  color: ${theme.colors.darkGray};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.9;
    }
  }

  :disabled {
    color: ${theme.colors.darkGray};
  }

  :focus {
    /* border: 2px outset black; */
  }
`
