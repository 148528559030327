import { Field, FieldArray, Formik } from "formik";
import { useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { ContentWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialLanguages } from "../../../../../state/stores/resumeBuilder";
import { SelectCom } from "../../../../common/Select";
import languagesList from "../../../../../assets/staticData/languages.json";
import languageLevelList from "../../../../../assets/staticData/languageLevelOptions.json";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required('Choose a language'),
      level: Yup.string().when('language', {
        is: (val: string) => Boolean(val),
        then: Yup.string().required('Choose a level'),
      }),
    })
  ),
});

export const Languages = ({
  isDesktop,
  closeBlockHandler
}: {
  isDesktop: boolean;
  closeBlockHandler: () => void;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const initialValues = { languages: !!resumeBuilderStore.languages.length ? resumeBuilderStore.languages : [initialLanguages] };

  return <CandidateFormCard
    isDesktop={isDesktop}
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Languages'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveLanguages(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="languages">
              {({ remove, push }) => {

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };
                return <div>
                  {values.languages.map((item: any, index: number) => {
                    const arrayErrors: any = errors.languages;
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`languages${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional language</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <MultiFieldWrap>
                        <MultiField>
                          <FieldWrap>
                            <Field
                              component={SelectCom}
                              name={`languages.${index}.language`}
                              options={languagesList}
                              onChange={(value: string) => setFieldValueHandler(`languages.${index}.language`, value)}
                              labelText={'Language'}
                              error={mainValidationCondition('language')} />
                            <ErrorMsg>{mainValidationCondition('language') ? arrayErrors[index].language : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                        <MultiField>
                          <FieldWrap>
                            <Field
                              component={SelectCom}
                              name={`languages.${index}.level`}
                              options={languageLevelList}
                              onChange={(value: string) => setFieldValueHandler(`languages.${index}.level`, value)}
                              labelText={'Language Level'}
                              error={mainValidationCondition('level')} />
                            <ErrorMsg>{mainValidationCondition('level') ? arrayErrors[index].level : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                      </MultiFieldWrap>

                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialLanguages)}>
                      Add Another Language
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved} onClick={()=>setIsFirstSubmitComplete(true)}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveLanguages({ languages: [] }, candidateStore.uid);
                await resumeBuilderStore.setLanguages([]);
                handleReset();
                closeBlockHandler();
              }}
            />}
          </form>}
      </Formik>

    </ContentWrap>
  </CandidateFormCard>
}