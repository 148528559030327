import { Organization } from "../../components/AdminPanel/Organization";
import { Container, Content } from "./Organization.presents";
import { DesktopSidebar } from "../../components/common/Sidebars/AdminSidebar/Desktop";
import { MobileAdminHeader } from "../../components/common/Headers/AdminHeader/Mobile/MobileAdminHeader";
import { DesktopHeader } from "../../components/common/Headers/AdminHeader/Desktop";
import useGetDevice from "../../hooks/useGetDevice";
import { ErrorBoundary } from "../../components/common/ErrorBoundary/ErrorBoundary";

export const OrganizationPage = () => {
  const isDesktop = useGetDevice();

  return <ErrorBoundary redirectPath="/my-jobs" pageTitle="Organization Form">
    <Container>
      {isDesktop && <DesktopSidebar />}
      <Content>
        <Organization isDesktop={isDesktop} />
      </Content>
    </Container>
  </ErrorBoundary>
}