import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  background: #F8F8F8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.desktop}) {
    padding-bottom: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding-bottom: ${theme.offsets.lg};
  }
`
export const HeaderBlock = styled('div')`
  background-color: ${theme.colors.white};
`

export const HeaderWrap = styled('div')`
  max-width: 1728px;
  margin: 0 auto;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 1115px;
  }
`

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: ${theme.offsets.major};
  line-height: 52px;
  text-align: center;
  max-width: 545px;
  margin-top: 80px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.big};
    line-height: 32px;
    max-width: 100%;
    margin-top: 42px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.offsets.extraBig};
    margin-top: 64px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.big};
    line-height: ${theme.offsets.extraBig};
    margin-top: 44px;
  }
`

export const Subtitle = styled('p')`
  font-size: ${theme.fontSizes.xl};
  text-align: center;
  max-width: 555px;
  margin: 72px auto 0;
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: 54px;
    font-size: ${theme.fontSizes.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: 80px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: 64px;
  }
`

export const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: row;
  @media(max-width: ${theme.screens.tablet}) {
    flex-direction: column;
  }
`

export const ButtonWrap = styled('div')<{rightBtn?: boolean}>`
  margin: ${theme.offsets.major} auto 0;
  ${props=> props.rightBtn && `
      margin-left: ${theme.offsets.xxl};
    `}
  max-width: 260px;
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 155px;
    margin-top: ${theme.offsets.xxl};
    button {
      height: 40px;
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 220px;
    margin-top: ${theme.offsets.extraBig};
    ${props=> props.rightBtn && `
      margin-top: ${theme.offsets.lg};
    `}
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 175px;
  }
`

export const ContentBlock = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0 ${theme.offsets.lg};
`

export const ListBlock = styled('div')`
  width: 830px;
  background: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  padding: ${theme.offsets.extraBig};
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.xxl};
    padding: ${theme.offsets.xl};
    width: 740px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: 18px;
    padding: ${theme.offsets.xxl};
    width: 600px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    width: 100%;
    margin-top: 64px;
    padding: ${theme.offsets.lg};
  }
`

export const ListItem = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  svg{
    width: 90px;
    height: 90px;
  }
  @media(max-width: ${theme.screens.desktop}) {
    svg{
      width: 64px;
      height: 64px;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ItemContent = styled('div')`
  margin-left: ${theme.offsets.extraMajor};
  max-width: 540px;
  @media(max-width: ${theme.screens.desktop}) {
    margin-left: 40px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 390px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: 12px;
    margin-left: 0;
  }
`

export const ItemTitle = styled('p')`
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 22px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 18px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
`

export const ItemDescription = styled('p')`
  font-size: 20px;
  color: rgba(63, 71, 79, 0.8);
  margin-top: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.md};
    margin-top: ${theme.offsets.xs};
    max-width: 320px;
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
`

export const Separator = styled('div')`
  font-size: 18px;
  width: 100%;
  margin: ${theme.offsets.extraBig} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1px;
  background-color: #D1D7E3;
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.xxl} 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} 0;
  }
`

export const ItemNumber = styled('p')`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  color: rgba(63, 71, 79, 0.8);
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.md};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.xs};
  }
`
