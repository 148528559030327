import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { OrganizationTabs } from "../common/Tabs/OrganizationTabs";
import { StyleForWrap, TabsWrap, TabContent, TabSection, DescriptionSection, ContentWrap, AllJobsButtonWrap } from "./Organization.presents";
import { Overview } from "./Overview";
import { Benefits } from "./Benefits";
import { BenefitsPreview } from "./BenefitsPreview";
import { Contacts } from "./Contacts";
import { Follow } from "./Follow";
import { Gallery } from "./Gallery";
import { GalleryPreview } from "./GalleryPreview";
import { AudioSection } from "./AudioSection";
import { DefaultJobsCard } from "./Jobs/DefaultJobsCard";
import { CoverSection } from "./CoverSection";
import { useRootStore } from "../../state/stores/storeContext";
import { PropsType } from "./Organization.props";
import { SPBasePagination } from "../common/Pagination/SPBasePagination/SPBasePagination";
import { JobsList } from "./Jobs/JobsList";
import { SPWhiteButton } from "../common/buttons/SPWhiteButton/SPWhiteButton";
import { benefitsCount } from "./BenefitsPreview/helper";
import { isSectionShowHandler } from "./helper";
import { JobListSkeleton } from "./Skeleton/JobListSkeleton";
import { ShineSection } from "./Skeleton/Skeleton.presents";

export const Organization = observer((props: PropsType) => {
  const { isAdminView } = props;
  const [activeTab, setActiveTab] = useState('overview');
  const [isJobListLoading, setIsJobListLoading] = useState(false);
  const { organizationStore } = useRootStore();
  const isDesktop = props.isDesktop
  const org = organizationStore.initialValues

  const { bannerUrl, logoUrl, photoGallery, videoGallery, audioUrl, audioName } = organizationStore;

  const isSectionShow = isSectionShowHandler(isAdminView || false, organizationStore);

  const setOrgTabs = (typesLength?: { overview: number, jobs: number, gallery: number, benefits: number }) => {
    return [
      { label: 'Overview', value: 'overview' },
      { label: 'Jobs', value: 'jobs' },
      { label: 'Gallery', value: 'gallery', length: photoGallery.length + videoGallery.length },
      { label: 'Benefits', value: 'benefits', length: benefitsCount(org.benefits) },
      { label: 'Overview', value: 'overview', show: isSectionShow.overview },
      { label: 'Jobs', value: 'jobs', show: isSectionShow.jobs, length: organizationStore.allJobsListLength },
      { label: 'Gallery', value: 'gallery', show: isSectionShow.gallery, length: photoGallery.length + videoGallery.length },
      { label: 'Benefits', value: 'benefits', show: isSectionShow.benefits, length: benefitsCount(org.benefits) },
    ]
  }
  const orgTabs = setOrgTabs();
  const handleSetActiveTab = async (filterValue: string) => {
    setActiveTab(filterValue);
  }

  const tabsRef: any = useRef(null);

  const CoverComponentProps = {
    bannerUrl,
    logoUrl,
    photoGallery,
    videoGallery,
    setActiveTab,
    isDesktop,
    tabsComponentRef: tabsRef,
    organizationName: org.name,
    organizationIndustry: org.industry,
    isAdminView
  }

  const GalleryProps = {
    photoGallery,
    videoGallery,
    setActiveTab,
    isDesktop,
    tabsComponentRef: tabsRef,
    isAdminView,
  }

  const BenefitsProps = {
    setActiveTab,
    isDesktop,
    tabsComponentRef: tabsRef,
    benefits: org.benefits,
  }

  const setJobListHandler = async (activePage: number) => {
    setIsJobListLoading(true);
    tabsRef.current?.scrollIntoView();
    await organizationStore.setJobList(organizationStore.publicPreviewUrl, activePage);
    setIsJobListLoading(false);
  }

  const openJobListTab = () => {
    setActiveTab('jobs');
    tabsRef.current?.scrollIntoView();
  }

  return (
    <StyleForWrap>
      <CoverSection {...CoverComponentProps} />
      <ContentWrap isDesktop={isDesktop}>
        <TabSection isDesktop={isDesktop} isRightSection={false}>
          {isSectionShow.tabs && <TabsWrap ref={tabsRef}>
            <OrganizationTabs activeTab={activeTab} callback={handleSetActiveTab} isDesktop={true} tabsData={orgTabs} />
          </TabsWrap>}
          {activeTab === 'overview' && (
            <>
              {audioUrl && !isDesktop && (
                <AudioSection url={audioUrl} title={audioName} isDesktop={isDesktop} />
              )}
              {isSectionShow.overview && <Overview
                organizationData={organizationStore}
                foundationYear={organizationStore.foundationYear}
                companyLogoUrl={organizationStore.logoUrl}
                isDesktop={isDesktop}
                isAdminView={isAdminView} />}
              {(isSectionShow.jobs && organizationStore.allJobsListLength === 0) && <DefaultJobsCard isDesktop={isDesktop} />}
              {(isSectionShow.jobs && organizationStore.allJobsListLength > 0) && <>
                <JobsList jobListData={organizationStore.jobList} />
                <AllJobsButtonWrap>
                  <SPWhiteButton width={'100%'} onClick={openJobListTab} isLight>
                    See All Jobs
                  </SPWhiteButton>
                </AllJobsButtonWrap>
              </>}
            </>
          )}
          {activeTab === 'jobs' && (<>
            {organizationStore.allJobsListLength > 0 && <>
              {isJobListLoading ? <ShineSection><JobListSkeleton isDesktop={isDesktop} /></ShineSection> : <JobsList jobListData={organizationStore.jobList} />}
              <SPBasePagination listLength={organizationStore.allJobsListLength} itemsPerPage={10} callback={setJobListHandler} />
            </>}
            {(organizationStore.allJobsListLength === 0 && isAdminView) && <DefaultJobsCard isDesktop={isDesktop} />}
          </>

          )}
          {activeTab === 'gallery' && (
            <Gallery {...GalleryProps} isPreview={false} />
          )}
          {activeTab === 'benefits' && (
            <Benefits organizationData={org} isDesktop={isDesktop} /> 
          )}

        </TabSection>
        <DescriptionSection isDesktop={isDesktop}>
          <TabSection isDesktop={isDesktop} isRightSection={true}>
            {audioUrl && isDesktop && (
              <AudioSection url={audioUrl} title={audioName} isDesktop={isDesktop} />
            )}
            {isSectionShow.benefits && activeTab !== 'benefits' && <BenefitsPreview {...BenefitsProps} />}
            {isSectionShow.socialNetworks && <Follow organizationData={org} isDesktop={isDesktop} />}
            {isSectionShow.galleryPreview && activeTab !== 'gallery' && <GalleryPreview {...GalleryProps} isPreview={true}/>}
            {isSectionShow.contacts && <Contacts organizationData={org} isDesktop={isDesktop} />}
          </TabSection>
        </DescriptionSection>
      </ContentWrap>
    </StyleForWrap>
  )
})