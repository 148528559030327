import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { theme } from "../../../theme";
import arrowIcon from '../../../assets/img/arrow.svg';

export const Container = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  position: relative;
  margin-top: ${theme.offsets.xxl};
  margin-left: ${theme.offsets.xxl};
  margin-right: 100px;
  margin-bottom: 80px;
  ${(props) => !props.isDesktop && `
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: ${theme.offsets.extraBig};
    flex-direction: column;
  `}
  & button{
    font-size: 14px;
  }
`

export const FormsWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 848px;
  width: 100%;
  ${(props) => !props.isDesktop && `
    margin: 0 ${theme.offsets.lg} ;
    @media(max-width: ${theme.screens.tablet}) {
      margin: 0 ${theme.offsets.md} ;
    }
  `}
`

export const FieldWrap = styled('div')`
margin-top: ${theme.offsets.sm};
display: block;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.sm};
  }
`

export const ErrorMsg = styled('div')`
  color: ${theme.colors.red};
  margin-top: ${theme.offsets.xs};
  height: 12px;
  font-size: ${theme.fontSizes.md};
  display: block;
`

export const FieldLabel = styled('label')`
  font-size: 14px;
  margin-bottom: ${theme.offsets.sm};
  text-transform: capitalize;
  color: ${theme.colors.black};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
  } 
`

export const PhoneInputWrap = styled(PhoneInput) <{ isDesktop: boolean }>`
input { 
  border: 1px solid #D1D7E3;
  border-radius: 10px;
  height: 52px;
  padding: 18px;
  background-color: #fff!important;
  color: ${theme.colors.black};
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    font-size: 14px;
  } 
};
.PhoneInputCountry {
  max-width: ${(props) => props.isDesktop ? '122px' : '100px'};
  width: 100%;
  border: 1px solid #D1D7E3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px ${theme.offsets.lg};
  justify-content: space-between;
  margin-right: ${(props) => props.isDesktop ? theme.offsets.xxl : theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 80px;
  }  
  } 
.PhoneInputCountrySelectArrow{
  border:none;
  content: url(${arrowIcon});
  width: 16px;
  height: 9px;
  transform: unset;
}
&.error input, &.error .PhoneInputCountry{
  border-color: ${theme.colors.lightRed};
}
`

export const SubmitButtonWrap = styled('div')<{isLoading?: boolean}>`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
  max-width: 191px;
  width: 100%;
  margin-left: 0;
  margin-right: auto;
  align-self: flex-start;
  margin-top: ${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 161px;
    margin-left: auto;
    margin-right: 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 123px;
  }

  ${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`

export const FormContent = styled('form')`
  margin-top: ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.extraBig};
  }
`

export const ModalDescription = styled('p')`
  font-size: ${theme.fontSizes.lg};
  text-align: center;
  width: 80%;
  margin: 40px auto ${theme.offsets.extraBig};
  span{
    color: ${theme.colors.lightOrange};
    cursor: pointer;
    font-weight: 500;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.extraBig} auto;
    font-size: 14px;
  }

  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.xxl} auto;
  }
`

export const ModalBtnWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${theme.offsets.extraMajor};
  button{
    max-width: 191px;
    margin-left: ${theme.offsets.lg};
    &:first-of-type{
      color: ${theme.colors.black};
    }
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xxl};
    button{
      max-width: 130px;
    }
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: 0;
    button{
      max-width: 90px;
    }
  }
`

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

