import { ModalWindow } from ".";
import { ModalForResultsProps } from "./Modal.props";
import successfullyIcon from '../../../assets/img/successfully.svg';
import errorIcon from '../../../assets/img/errorResult.svg';
import {WindowText, WindowContent, WindowAdditionalText} from './Modal.presents';


export const ModalForResults = (props: ModalForResultsProps) => {

  const handleCloseWindows = () => {
    props.setModalOpen(false);
    props.setAdditionalText && props.setAdditionalText('')
  }

  return <ModalWindow openWindow={props.isModalOpen} closeWindow={() => handleCloseWindows()} >
    <WindowContent>
      <img src={props.result === 'successfully' ? successfullyIcon : errorIcon} alt="img" />
      <WindowText>{props.text}</WindowText>
      {props?.additionalText && <WindowAdditionalText>{props.additionalText}</WindowAdditionalText>}
    </WindowContent>
  </ModalWindow>
}