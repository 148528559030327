export const setLocationsListItem = (locationData: Array<any>) => {
  let locationsLength = locationData.length;
  const firstLocationItem = locationData[0];
  const cityName = firstLocationItem.city;
  const countryName = firstLocationItem.country;
  const territoryName = firstLocationItem.territory;
  const helperText = locationsLength > 1 ? `and ${--locationsLength} more` : '';

  const locationString = `${countryName}, ${territoryName}, ${cityName} ${helperText}`;
  return locationString
}

export const isSectionShowHandler = (isAdminView: boolean, organizationStore: any) => {
  const initialOrgValues = organizationStore.initialValues;

  const isLocationExist = initialOrgValues.locations && initialOrgValues.locations.length > 0 && initialOrgValues.locations[0].territory;

  const showBenefitSection = isAdminView || Object.keys(initialOrgValues.benefits).length > 0;
  const showSocialNetworksSection = isAdminView || initialOrgValues.socialNetworks.length > 0;
  const showGallerySection = isAdminView || (organizationStore.videoGallery.length > 0 || organizationStore.photoGallery.length > 0);
  const showGalleryPreviewSection = isAdminView || organizationStore.photoGallery.length > 0;
  const showContactsSection = isAdminView || (initialOrgValues.contactPerson && initialOrgValues.contactPerson.length > 0);
  const showJobList = isAdminView || organizationStore.allJobsListLength > 0;

  const showAboutCompany = isAdminView || initialOrgValues.aboutCompany;
  const showAboutCulture = isAdminView || initialOrgValues.aboutCulture;
  const showAboutCareer = isAdminView || initialOrgValues.aboutCareer;
  const showOrganizationSize = isAdminView || initialOrgValues.organizationSize;
  const showWebsite = isAdminView || initialOrgValues.website;
  const showLocation = isAdminView || isLocationExist;
  const showFoundationYear = isAdminView || organizationStore.foundationYear;

  const showOverview = showAboutCompany || showAboutCulture || showAboutCareer || showOrganizationSize || showWebsite || showLocation || showFoundationYear;
  const showTabs = showOverview || showJobList || showGallerySection || showBenefitSection;

  return {
    overview: showOverview,
    jobs: showJobList,
    gallery: showGallerySection,
    galleryPreview: showGalleryPreviewSection,
    benefits: showBenefitSection,
    socialNetworks: showSocialNetworksSection,
    contacts: showContactsSection,
    tabs: showTabs,
    aboutCompany: showAboutCompany,
    aboutCulture: showAboutCulture,
    aboutCareer: showAboutCareer,
    organizationSize: showOrganizationSize,
    website: showWebsite,
    location: showLocation,
    foundationYear: showFoundationYear
  }
}
