import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../../../../theme";

export const Wrapper = styled('div')`
  width: 100%;
  background-color: ${theme.colors.coral};
  display: flex;
  justify-content: center;
`

export const Description = styled('span')`
  padding: 10px 0;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  text-align: center;
  button{
    background: none;
    font-weight: 500;
    text-decoration: underline;
    border: none;
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: 15px;
  }
`

export const VerificationModalWrapper = styled(Modal)`
.modal-dialog{
  max-width: 703px;
  @media(max-width: ${theme.screens.desktopLarge}) {
    max-width: 448px;
  }
  @media(max-width: ${theme.screens.tablet}) {
  }
}
.modal-content {
  border-radius: ${theme.variables.borderRadiusLarge};
  padding: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktopLarge}) {
    padding: ${theme.offsets.xxl} 30px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.md};
  }
  .modal-body {
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }
}
` 

export const Title = styled('h3')`
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  line-height: 22px;
  color: ${theme.colors.darkGray};
  @media(max-width: ${theme.screens.desktopLarge}) {
    font-size: 18px;
  }
`

export const CloseButton = styled('button')`
  position: absolute;
  right: ${theme.offsets.extraBig};
  top: ${theme.offsets.extraBig};
  background: transparent;
  border: none;
  z-index: 9;
  svg {
    height: 14px;
    width: 14px;
  }
  @media(max-width: ${theme.screens.desktopLarge}) {
    right: 30px;
    top: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    right: ${theme.offsets.md};
    top: ${theme.offsets.xl};
  }
`

export const ButtonWrap = styled('div')`
  margin-top: ${theme.offsets.extraMajor};
  right: 44px;
  top: 44px;
  max-width: 191px;
  width: 100%;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.big};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.xl};
  }
`

export const VerificationModalHeader = styled('div')`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @media(max-width: ${theme.screens.desktopLarge}) {
    margin-bottom: ${theme.offsets.big};
  }
`

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 323px;
  margin: 0 auto;
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 290px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 240px;
  }
`

export const Subtitle = styled('p')`
  font-weight: 400;
  font-size: ${theme.fontSizes.xxl};
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
    margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
`

export const ModalDescription = styled('p')`
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.offsets.lg};
  text-align: center;
  span{
    color: ${theme.colors.lightOrange};
    font-weight: 500;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin-top: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
    margin-top: 6px;
  }
`

export const ButtonSubtitle = styled('p')`
  font-size: ${theme.fontSizes.lg};
  text-align: center;
  margin: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
  }
`
