import { useEffect, useState } from 'react';
import { generateSignedUrl, s3upload } from '../../../../services/api/jobStoreBuilder/jobStoreBuilder.domains';
import { useRootStore } from '../../../../state/stores/storeContext';
import { UploadButton } from './UploadButton/UploadButton';
import { observer } from 'mobx-react-lite';
import { checkFileSizeInMB } from '../../../../services/helper';
import { FilePreview } from './FilePreview/FilePreview';
import fileIcon from '../../../../assets/img/clip.svg'

export const JobDescriptionStep = observer(() => {
  const { jobStoryBuilder } = useRootStore();
  const [isLoading, setIsLoading] = useState(false)
  const [media, setMedia] = useState<any>(null);

  const MAX_FILE_SIZE = 10;
  const UPLOAD_FOLDER = "documents";
  const descriptionFileData = jobStoryBuilder.answers.descriptionStep.descriptionFile;

  useEffect(() => {
    if (descriptionFileData && descriptionFileData.key && !isLoading) {
      const descriptionFile = {
        url: descriptionFileData.url,
        key: descriptionFileData.key,
        name: descriptionFileData.name,
        isLoading: false
      };
      setMedia(descriptionFile)
    }
  }, [descriptionFileData, isLoading])

  const uploadFile = async (event: any) => {
    if (!event.target.files) return

    let file = event.target.files[0]

    if (!checkFileSizeInMB(file.size, MAX_FILE_SIZE)) return alert("Maximum file size is 10mb")


    const placeholder = {
      name: file.name,
      isLoading: true
    }

    setMedia(placeholder)
    setIsLoading(true)


    const resp = await generateSignedUrl({ filename: file.name, filetype: file.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, file)
        const addFileDto = {
          key: resp.data.data.key,
          url: resp.data.data.fileUrl,
          name: file.name,
          jobId: jobStoryBuilder.jobId
        }
        await jobStoryBuilder.saveFile(addFileDto)
        const uploadResult = {
          name: file.name,
          url: resp.data.data.fileUrl,
          key: resp.data.data.key,
          isLoading: false
        }
        jobStoryBuilder.setFile(uploadResult)
        setMedia(() => (uploadResult))
      } catch (error) {
        setMedia(null)
      }
    }

    event.target.value = null; // reset input value
    setIsLoading(false)
  }


  const removePhoto = async (file: any) => {
    if (isLoading) return
    setIsLoading(true)
    if (!jobStoryBuilder.jobId) return
    let copiedFile = { ...media };
    copiedFile.isLoading = true
    setMedia(copiedFile);
    const resp = await jobStoryBuilder.deleteFile(file.key)
    jobStoryBuilder.unsetFile()
    if (!resp.data.err) {
      copiedFile = null;
    } else {
      copiedFile.isLoading = false
    }
    setMedia(copiedFile);
    setIsLoading(false)
  }


  return media ?
    <FilePreview name={media.name} onRemove={() => removePhoto(media)} isLoading={media.isLoading} />
    :
    <UploadButton
      text="Attach a file"
      onChange={uploadFile}
      isLoading={isLoading}
      fileTypes=".doc, .docx, .txt, .pdf, .psd"
      icon={fileIcon}
    />
})