import { ModalWindow } from "../../common/Modal";
import { DeleteWindowButtons, DeleteWindowContent, DeleteWindowDescription } from "./VideoCV.presents";
import { ReactComponent as DeleteIcon } from '../../../assets/img/deleteBlue.svg';
import { ReactComponent as DeleteOrangeIcon } from '../../../assets/img/delete.svg';
import { SPPrimaryButton } from "../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { SPLightButton } from "../../common/buttons/SPLightButton/SPLightButton";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { useRootStore } from "../../../state/stores/storeContext";

export const RemoveModalWindow = ({
  setUploadedFile,
  setIsRemoveWindowOpen,
  isRemoveWindowOpen,
  isResumeBuilder
}: {
  setUploadedFile: Function,
  setIsRemoveWindowOpen: Function,
  isRemoveWindowOpen: { isOpen: boolean, isRemoveContent: boolean },
  isResumeBuilder?: boolean
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const removeVideoHandler =async ({ isRemoveContent }: { isRemoveContent: boolean }) => {
    setUploadedFile(null);
    await resumeBuilderStore.removeVideoResume(candidateStore.uid)
    setIsRemoveWindowOpen({ isOpen: false, isRemoveContent });
  }
  return <ModalWindow
    openWindow={isRemoveWindowOpen.isOpen}
    closeWindow={() => setIsRemoveWindowOpen({ isOpen: false, isRemoveContent: true })}
    title={isRemoveWindowOpen.isRemoveContent ? "Delete" : "Back"}>

    <DeleteWindowContent>
      {isResumeBuilder ?
        <DeleteOrangeIcon />
        : <DeleteIcon />}
      <DeleteWindowDescription>{isRemoveWindowOpen.isRemoveContent ? 'Are you sure you want to delete this video?' : 'This action will remove your progress'}</DeleteWindowDescription>
    </DeleteWindowContent>

    <DeleteWindowButtons>
      <SPLightButton width="100%" type="button" onClick={() => setIsRemoveWindowOpen({ isOpen: false, isRemoveContent: true })}>
        Cancel
      </SPLightButton>
      {isResumeBuilder ? <SPSecondaryButton width="100%" type="button" onClick={() => removeVideoHandler({ isRemoveContent: true })}>
        {isRemoveWindowOpen.isRemoveContent ? 'Delete' : 'Ok'}
      </SPSecondaryButton> :
        <SPPrimaryButton width="100%" type="button" onClick={() => removeVideoHandler({ isRemoveContent: true })}>
          {isRemoveWindowOpen.isRemoveContent ? 'Delete' : 'Ok'}
        </SPPrimaryButton>}
    </DeleteWindowButtons>
  </ModalWindow>
}