import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{
    display: block;
    cursor: pointer;
  }
  svg{
    height: 62px;
    width: 173px;
    object-fit: contain;
    @media(max-width: ${theme.screens.desktop}) {
      height: 47px;
      width: 128px;
    }
    @media(max-width: ${theme.screens.mobile}) {
      height: 33px;
      width: 93px;
    }
  }
`

export const ButtonWrap = styled('div') <{ isLogoBlack?: boolean }>`
  display: flex;
  align-items: center;
  span{
    color: ${(props)=>props.isLogoBlack? theme.colors.black :theme.colors.white};
    font-size: 18px;
    margin-right: ${theme.offsets.xxl};
    @media(max-width: ${theme.screens.desktop}) {
      font-size: 14px;
    }
    @media(max-width: ${theme.screens.tablet}) {
      margin-right: ${theme.offsets.lg};
    }
    @media(max-width: ${theme.screens.mobile}) {
      display: none;
    }
  }
  button{
    width: 150px;
    @media(max-width: ${theme.screens.mobile}) {
      width: 90px;
    }
  }
`