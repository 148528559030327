

import { Content } from "./OrganizationPage.presents";
import useGetDevice from "../../hooks/useGetDevice";
import { Organization } from "../../components/Organization";
import { useEffect, useState } from "react";
import { useRootStore } from "../../state/stores/storeContext";
import { useHistory } from "react-router-dom";
import { Skeleton } from "../../components/Organization/Skeleton";
import { Header } from "../../components/common/Headers/OrganizationPreviewHeader/Header";
import { NotFound } from "../../components/common/NotFound";
import { ErrorBoundary } from "../../components/common/ErrorBoundary";

export const OrganizationPreview = ({ isAdminView }: { isAdminView?: boolean }) => {
  const { organizationStore } = useRootStore();
  const isDesktop = useGetDevice();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [orgExist, setOrgExist] = useState(false);

  useEffect(() => {
    (async () => {
      const pathname = history.location.pathname;
      const nameFromUrl = pathname.split('/')[2];
      const startPage = 0;
      const publicPreviewUrl = isAdminView ? null : nameFromUrl;

      const result = await organizationStore.uploadOrganizationData(publicPreviewUrl);
      setOrgExist(!isAdminView && (!publicPreviewUrl || result.err))
      await organizationStore.setJobList(organizationStore.publicPreviewUrl, startPage)
      setIsLoaded(true);
    })()
  }, [])

  if (isLoaded && orgExist){
    return <NotFound />
  }
  return <ErrorBoundary redirectPath="/my-jobs" pageTitle="Organization">
  <Header isDesktop={isDesktop} isAdminView={isAdminView} publicPreviewUrl={organizationStore.publicPreviewUrl} />
    <Content>
      {!isLoaded ? <Skeleton isDesktop={isDesktop} /> :
        <Organization isDesktop={isDesktop} isAdminView={isAdminView}/>}
    </Content>

  </ErrorBoundary>
}