import { Certifications } from "./Components/Certifications/Certifications";
import { Education } from "./Components/Education/Education";
import { useEffect, useRef, useState } from "react";
import useGetDevice from "../../../hooks/useGetDevice";
import { useRootStore } from "../../../state/stores/storeContext";
import { EmploymentHistory } from "./Components/EmploymentHistory/EmploymentHistory";
import { ResumeBuilderMenu } from "./Components/ResumeBuilderMenu/ResumeBuilderMenu";
import { PersonalInfo } from "./Components/PersonalInfo/PersonalInfo";
import { SocialSkills } from "./Components/SocialSkills/SocialSkills";
import { TechnicalSkills } from "./Components/TechnicalSkills/TechnicalSkills";
import { Wrapper, Container, FormWrap, OptionalBlocks, OptionalItem } from "./ResumeBuilder.presents";
import { References } from "./Components/References/References";
import { initialOptionalBlocksMap, optionalQuestionsMap } from "./helper";
import { Languages } from "./Components/Languages/Languages";
import { Trainings } from "./Components/Trainings/Trainings";
import { Nonprofit } from "./Components/Nonprofit/Nonprofit";
import { WorkAuthorization } from "./Components/WorkAuthorization/WorkAuthorization";
import { Membership } from "./Components/Membership/Membership";
import { Honors } from "./Components/Honors/Honors";
import { Portfolio } from "./Components/Portfolio/Portfolio";
import { VideoPresentation } from "./Components/VideoPresentation/VideoPresentation";
import { Publication } from "./Components/Publication/Publication";
import { SocialNetworks } from "./Components/SocialNetworks/SocialNetworks";
import { Instruction } from "./Components/Instruction/Instruction";
import { observer } from "mobx-react-lite";

export const ResumeBuilder = observer(() => {
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const isDesktop = useGetDevice();
  const logoUrl = resumeBuilderStore.logo ? resumeBuilderStore.logo.url : '';
  const [optionalBlocksMap, setOptionalBlocksMap] = useState(initialOptionalBlocksMap);
  const [isLoading, setIsLoading] = useState(false);
  const [progressTracker, setProgressTracker] = useState(0);

	const progressCalculator = () => {
		let count = 0
		if (resumeBuilderStore.videoResume.url) {
			count += 20
		}
		if (resumeBuilderStore.personalInfo.firstName && resumeBuilderStore.personalInfo.lastName) {
			count += 20
		}
		if (resumeBuilderStore.education.length > 0) {
			count += 20
		}
		if (resumeBuilderStore.technicalSkills.length > 0) {
			count += 10
		}
		if (resumeBuilderStore.socialSkills.length > 0) {
			count += 10
		}
		if (resumeBuilderStore.employmentHistory.length > 0) {
			count += 20
		}
		setProgressTracker(count)
	} 

  useEffect(() => {
    (async () => {
      await resumeBuilderStore.setResumeData(candidateStore.uid);
      progressCalculator()
    })();
  }, [resumeBuilderStore.videoResume.url, resumeBuilderStore.personalInfo.firstName, resumeBuilderStore.personalInfo.lastName, resumeBuilderStore.education.length,resumeBuilderStore.technicalSkills.length, resumeBuilderStore.employmentHistory.length, resumeBuilderStore.socialSkills.length])


  useEffect(() => {
    let optionalBlocksMapCopy = { ...optionalBlocksMap };
    for (const item in optionalBlocksMap) {
      const resumeBuilderItem = item as keyof typeof resumeBuilderStore;
      optionalBlocksMapCopy[item as keyof typeof optionalBlocksMap] = !!resumeBuilderStore[resumeBuilderItem].length;
    };
    setOptionalBlocksMap(optionalBlocksMapCopy);
  }, [resumeBuilderStore.isDataUpdated])

  const componentRef: any = {
    licensesAndCertifications: useRef(null),
    websitesAndLinks: useRef(null),
    otherInformation: useRef(null),
    videoPresentation: useRef(null),
    personalInformation: useRef(null),
    technicalSkills: useRef(null),
    socialAndBusinessSkills: useRef(null),
    employmentHistory: useRef(null),
    education: useRef(null),
  };
  const resumeBuilderMenuList = [
    { title: 'Video Presentation', key: 'videoPresentation', colored: true, isComplete: false },
    { title: 'Personal Information', key: 'personalInformation', colored: true, isComplete: false },
    { title: 'Technical Skills', key: 'technicalSkills', colored: true, isComplete: false },
    { title: 'Social and Business Skills', key: 'socialAndBusinessSkills', colored: true, isComplete: false },
    { title: 'Employment History', key: 'employmentHistory', colored: true, isComplete: false },
    { title: 'Education', key: 'education', colored: true, isComplete: false },
    { title: 'Licenses & Certifications', key: 'licensesAndCertifications', colored: false, isComplete: false },
    { title: 'Websites & Links', key: 'websitesAndLinks', colored: false, isComplete: false },
    { title: 'Other Information', key: 'otherInformation', colored: false, isComplete: false }
  ]
  const resumeBuilderMenuProps = { componentRef, progressTracker, isDesktop: isDesktop, resumeBuilderMenuList };
  return <Wrapper>
    <Container isDesktop={isDesktop}>

      <ResumeBuilderMenu {...resumeBuilderMenuProps} />
      <FormWrap isDesktop={isDesktop}>
        <Instruction />
        <VideoPresentation isDesktop={isDesktop} componentRef={componentRef.videoPresentation} />
        <PersonalInfo
          isDesktop={isDesktop}
          logoUrl={logoUrl}
          removeUserLogo={resumeBuilderStore.removeResumeLogo}
          uploadUserLogo={resumeBuilderStore.uploadResumeLogo}
          componentRef={componentRef.personalInformation}
        />
        <TechnicalSkills isDesktop={isDesktop} componentRef={componentRef.technicalSkills} />
        <SocialSkills isDesktop={isDesktop} componentRef={componentRef.socialAndBusinessSkills} />
        <EmploymentHistory isDesktop={isDesktop} componentRef={componentRef.employmentHistory} />
        <Education isDesktop={isDesktop} componentRef={componentRef.education} />
        <Certifications isDesktop={isDesktop} componentRef={componentRef.licensesAndCertifications} />
        <SocialNetworks isDesktop={isDesktop} componentRef={componentRef.websitesAndLinks} />
        {optionalBlocksMap.references && <References componentRef={componentRef.otherInformation} isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, references: false })} />}
        {optionalBlocksMap.languages && <Languages isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, languages: false })} />}
        {optionalBlocksMap.trainings && <Trainings isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, trainings: false })} />}
        {optionalBlocksMap.nonprofit && <Nonprofit isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, nonprofit: false })} />}
        {optionalBlocksMap.workAuthorization && <WorkAuthorization isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, workAuthorization: false })} />}
        {optionalBlocksMap.honors && <Honors isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, honors: false })} />}
        {optionalBlocksMap.portfolio && <Portfolio isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, portfolio: false })} />}
        {optionalBlocksMap.publication && <Publication isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, publication: false })} />}
        {optionalBlocksMap.membership && <Membership isDesktop={isDesktop} closeBlockHandler={() => setOptionalBlocksMap({ ...optionalBlocksMap, membership: false })} />}
        <OptionalBlocks>
          {optionalQuestionsMap.map((item, index) => !optionalBlocksMap[item.key as keyof typeof optionalBlocksMap] ? <OptionalItem
            onClick={() => setOptionalBlocksMap({ ...optionalBlocksMap, [item.key]: true })}
            key={`optionalQuestions${index}`}>
            {item.icon}
            <span>
              {item.title}
            </span>
          </OptionalItem> :
            null
          )}
        </OptionalBlocks>
      </FormWrap>

    </Container>
  </Wrapper>
})