import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrap = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const Label = styled('span')`
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.darkGray};
`

export const RemoveBtn = styled('div')`
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.red};
  cursor: pointer;
  font-weight: 500;
  margin-top: ${theme.offsets.xs};
`