import { BackBtn, MobileHeaderForCandidate, BtnsWrap, BtnText } from "./MobileNavigation.presents";
import { ReactComponent as ArrowIcon } from "../../../../assets/img/arrowLeft.svg";
import { ReactComponent as EditIcon } from "../../../../assets/img/Edit.svg";
import { ThemeBtn, ThemeBtnText } from "../../../JobStoryBuilder/BasicComponents/Header/Header.presents";
import { PropsType } from "./MobileNavigation.props";
import { SPBaseButton } from "../../../common/buttons/SPBaseButton";

export const MobileNavigation = (props: PropsType) => {
  return <MobileHeaderForCandidate>
    <BackBtn to={'/admin-panel/my-jobs'}>
      <ArrowIcon />
      {props.jobTitle}
    </BackBtn>
    {props.isPreviewPage && <BtnsWrap>
      <SPBaseButton width="100%" onClick={() => props.setThemeMenuOpen(true)}>
        <ThemeBtn />
        <ThemeBtnText>Edit style</ThemeBtnText>
      </SPBaseButton>
      <SPBaseButton width="100%">
        <EditIcon />
        <BtnText>
        Edit Job Story
        </BtnText>
      </SPBaseButton>
    </BtnsWrap>}
  </MobileHeaderForCandidate>
}