import { useRootStore } from "../../../../state/stores/storeContext";
import { SPSecondaryButton } from "../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import jobTypeEmpty from '../../../../assets/img/jobTypeEmpty.svg';
import jobListEmpty from '../../../../assets/img/jobListEmpty.svg';
import { EmptyListBannerProps } from "../JobsList.props";
import { EmptyListBannerWrap, BannerText } from "../JobsList.presents";

export const EmptyListBanner = (props: EmptyListBannerProps) => {
  const { jobStory } = useRootStore();
  const { activeTab, createJob } = props;

  const isAllJobsListEmpty = jobStory.typesLength.allJobs < 1;

  return <EmptyListBannerWrap>
    <img src={isAllJobsListEmpty ? jobListEmpty : jobTypeEmpty} alt="img" />
    <BannerText>{isAllJobsListEmpty ? 'You have no jobs yet' : `You have no ${activeTab} jobs.`}</BannerText>
    {isAllJobsListEmpty && <SPSecondaryButton onClick={() => createJob()}>Create my first job</SPSecondaryButton>}
  </EmptyListBannerWrap>
}


