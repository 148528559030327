import { PropsType } from './HelperText.props'
import { Wrapper, ContentBlock, Text, Title } from './HelperText.presents'
import { ReactComponent as InfoIcon } from '../../../assets/img/info.svg'
import { useState } from 'react';

export const HelperText = (props: PropsType) => {
  const [isPositionUnder, setPositionUnder] = useState(false);
  const [isBlockOpen, setBlockOpen] = useState(false);

  const positionhandler = (event: React.MouseEvent<HTMLElement>) => {
    const HEADER_HEIGHT = 100;
    const CONTENT_BLOCK_HEIGHT = 200;
    const topPadding = HEADER_HEIGHT + CONTENT_BLOCK_HEIGHT;
    const helperTextPosition = event.clientY;
    //set position
    props.autoChangePosition && setPositionUnder(helperTextPosition < topPadding);
    //Open menu
    setBlockOpen(true)
  }

  return <Wrapper
      backgroundColor={props.backgroundColor}
      color={props.color}
      onMouseOver={(event) => positionhandler(event)}
      onMouseLeave={() => setBlockOpen(false)}>
    i
    {isBlockOpen && <ContentBlock isPositionLeft={props.left}  isPositionUnder={isPositionUnder}>
      <Title>{props.title}</Title>
      <Text>{props.text}</Text>
    </ContentBlock>}
  </Wrapper>;
}
