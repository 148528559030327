import { Field, Formik } from "formik";
import { useState } from "react";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { FieldWrap } from "../../../../../theme/typography";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { Tags } from "../../../../JobStoryBuilder/BasicComponents/Tags";
import { ErrorMsg } from "../../../Profile/Profile.presents";
import { ContentWrap, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { observer } from "mobx-react-lite";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';

export const TechnicalSkills = observer(({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const cardSubtitle = "Technical skills, sometimes referred to as hard skills, are abilities that let you tackle job-specific duties and responsibilities. These abilities or knowledge are usually industry specific, and learned through hands-on experience, education or training.";

  const { resumeBuilderStore, candidateStore } = useRootStore();
  const initialValues = [...resumeBuilderStore.technicalSkills];

  return <CandidateFormCard
    isDesktop={isDesktop}
    componentRef={componentRef}
    title={'Technical Skills'}
    subtitle={cardSubtitle}
  >
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={{ technicalSkills: initialValues }}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            technicalSkills: Yup.array()
              .max(50, 'Exceeding 50 tags'),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveTechnicalSkills(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue
        }) => {
          const addTagsList = (tags: Array<string>) => {
            setFieldValue('technicalSkills', tags);
            setShowSaveBtn(true);
          };
          
          const onChange = (tags: Array<string>) => {
            const isNewTag = values.technicalSkills.length < tags.length;
            if (isNewTag) {
              const newTag = tags[tags.length - 1];
              const isTagAlreadyExist = values.technicalSkills.find(item => item.toLocaleLowerCase() === newTag.toLocaleLowerCase());
              if (isTagAlreadyExist) return;
              addTagsList(tags);
            } else {
              addTagsList(tags);
            }
          };

          return <form onSubmit={handleSubmit}>
            <FieldWrap>
              <Field
                labelText={<LabelWrap>Type any skill and hit enter to add<span>Up to 50</span></LabelWrap>}
                as={Tags}
                tags={values.technicalSkills}
                name={'technicalSkills'}
                m='0' // TODO: need to refactor Tags component
                setTegs={onChange}
                error={!!errors.technicalSkills}
              />
              <ErrorMsg>{errors.technicalSkills}</ErrorMsg>
            </FieldWrap>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

          </form>
        }}
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
})
