import { Actions } from "../../common/Modals/TableActionsModal/TableActionsModal.props";

export const mobileSortList = [
  { title: 'Candidate Name (A-Z)', data: { type: 'firstName', value: 'down' }, isChecked: false },
  { title: 'Candidate Name (Z-A)', data: { type: 'firstName', value: 'up' }, isChecked: false },
  { title: 'Application Date (From Newest To Oldest)', data: { type: 'responseDate', value: 'up' }, isChecked: false },
  { title: 'Application Date (From Oldest To Newest)', data: { type: 'responseDate', value: 'down' }, isChecked: false },
];

export const setActionsForCandidates = (props: { handleOpenModal: Function, candidateItem: any }) => {
  const { candidateItem, handleOpenModal } = props;
  const isCandidateArchived = candidateItem.candidateStatus === 'archived';
  return [
    {
      title: 'Unarchive',
      callback: () => handleOpenModal(Actions.active, candidateItem),
      show: isCandidateArchived
    },
    {
      title: 'Delete',
      callback: () => handleOpenModal(Actions.delete, candidateItem),
      show: isCandidateArchived,
      isDeleteBtn: true
    },
    {
      title: 'Archive',
      callback: () => handleOpenModal(Actions.archived, candidateItem),
      show: !isCandidateArchived,
      isSingleElement: !isCandidateArchived,
      isDeleteBtn: true
    },
  ]
}
