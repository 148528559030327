import { ReactComponent as EmployerIcon } from '../../../../../assets/img/technology.svg';
import { ReactComponent as PublishingIcon } from '../../../../../assets/img/supportIcon.svg';
import { ReactComponent as BuilderIcon } from '../../../../../assets/img/Group1250.svg';

export const BannerContent = [
  {
    title: 'Employer Branding and Career Site',
    description: 'Build a powerful multimedia employer’s brand',
    icon: <EmployerIcon />,
    helperText: 'Free employer branding and career site builder are available on all accounts. Use this feature to build a beautiful career site and link it to your website.'
  },
  {
    title: 'Job Listing and Publishing',
    description: 'Publish and list video and voice jobs and share them on social media',
    icon: <PublishingIcon />,
    helperText: 'Post jobs under your organization’s profile and let the candidates know about your jobs. Add video, voice and images and attract the best talent. Create a social media friendly searchable listing for your website.'
  },
  {
    title: 'Job Description Builder',
    description: 'Use SidePost’s job description builder, create video job descriptions and share them',
    icon: <BuilderIcon />,
    helperText: 'Never send a text or PDF file for jobs descriptions to candidates. Create and track all job descriptions on SidePost online. Just send candidates a link to your job descriptions and start hiring.'
  }
];

export const AUTOPLAY_SPEED = 4000;

export const sliderSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  arrows: false,
  dotsClass: 'banner-dots',
  autoplaySpeed: AUTOPLAY_SPEED,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};