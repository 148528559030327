import { PropsType } from "./FinishStep.props";
import { JobStory } from "../../../JobStory";
import { StepWrap } from "./FinishStep.presents";
import { useEffect } from "react";


export const FinishStep = (props: PropsType) => {

  useEffect(() => {
    props.setDoneStep(4);
    props.setActiveStep(5);
  }, [])

  return <StepWrap>
    <JobStory preview openApplyWindow={false} setOpenApplyWindow={() => { }} />
  </StepWrap>
}