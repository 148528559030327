import { changeCandidateStatus } from './../../services/api/candidates/candidates.domains';
import { flow, types } from "mobx-state-tree";
import { getCandidatesList } from '../../services/api/candidates/candidates.domains';
import { ChangeCandidateStatusDto } from '../../services/api/candidates/candidates.type';

export const candidates = types
  .model("candidatesStory", {
    candidatesList: types.array(types.model({
      _id: types.string,
      firstName: types.string,
      lastName: types.string,
      applyingAt: types.string,
      email: types.string,
      phone: types.number,
      website: types.maybeNull(types.string),
      linkedIn: types.maybeNull(types.string),
      message: types.maybe(types.string),
      jobId: types.string,
      hasVisa: types.boolean,
      resume: types.string,
      candidateStatus: types.string
    })),
    listLength: types.maybe(types.number),
    typesLength: types.maybe(types.model({
      allCandidates: types.number,
      archivedCandidates: types.number,
    })),
    jobTitle: types.maybe(types.string),
    jobStatus: types.maybe(types.string),
    newReviewers: types.maybeNull(types.number),
  })
  .actions(self => {
    const setCandidatesList = flow(function* (page: number, candidateStatus: string, sortData: { type: string, value: string }, jobId: string) {
      const responseServer = yield getCandidatesList(page, candidateStatus, sortData, jobId)
      if (!responseServer.data.err) {
        self.candidatesList = responseServer.data.data.items;
        self.listLength = responseServer.data.data.listLength;
        self.typesLength = responseServer.data.data.typesLength;
        self.jobTitle = responseServer.data.data.jobTitle;
        self.newReviewers = responseServer.data.data.newReviewers;
        self.jobStatus =responseServer.data.data.jobStatus
        return { err: '', data: self.candidatesList }
      } else {
        return { err: responseServer.data.err }
      }
    })

    const updateCandidateStatus = flow(function* (dto: ChangeCandidateStatusDto) {
      const responseServer = yield changeCandidateStatus(dto)
      if (!responseServer.data.err) {
        self.candidatesList = responseServer.data.data.items;
        self.listLength = responseServer.data.data.listLength;
        self.typesLength = responseServer.data.data.typesLength;
        return { err: '', data: self.candidatesList }
      } else {
        return { err: responseServer.data.err }
      }
    })

    const resetNewReviewersFromState = () => {
      self.newReviewers = 0;
    }

    return { setCandidatesList, updateCandidateStatus, resetNewReviewersFromState }
  })