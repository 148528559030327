import { Field, FieldArray } from "formik";
import { useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { Input } from "../../../../common/Input";
import { socialNetworksList } from "../../helper";
import { RemoveLabel } from "../RemoveLabel/RemoveLabel";
import { Wrapper, FieldWrap, ErrorMsg, InputsWrap } from "./SocialNetworks.presents";
import { ReactComponent as PlusIcon } from '../../../../../assets/img/plusBlack.svg';
import { ListModal } from "./ListModal";
import { MainSectionProps } from "../../Organization.props";
import { HelperTextTitle } from "../../Organization.presents";
import { HelperText } from "../../../../common/HelperText";

export const SocialNetworks = (props: MainSectionProps) => {
	const { componentRef, isDesktop, values, errors, handleChange, setFieldValue } = props;

	const [isModalOpen, setModalOpen] = useState(false);

	const showAddBtn = values.socialNetworks.length < socialNetworksList.length;


	const removeNetworkItem = (remove: Function, index: number, key: string) => {
		const indexOnCheckedNetworkValue = values.selectedNetworks.findIndex((elem: string) => elem === key);
		values.selectedNetworks.splice(indexOnCheckedNetworkValue, 1)
		remove(index);
	}

	const helperText = 'Adding social media profiles to your SidePost profile will help you boost your public relations, talent acquisition marketing, and job advertising.'
	const TitleRender = () => <HelperTextTitle>
		<span>{'Social Networks'}</span>
		<HelperText text={helperText} />
	</HelperTextTitle>

	return <div id='orgNetworks'>
		<OrganizationCard title={<TitleRender />} componentRef={componentRef} isDesktop={isDesktop}>
			<Wrapper>
				<FieldArray
					name="socialNetworks">{({ remove }) => (
						<InputsWrap>{
							values.socialNetworks.map((item: { title: string, link: string }, index: number) => <FieldWrap key={index} isSingleItem={socialNetworksList.length === 1} isDesktop={isDesktop}>
								<Field
									as={Input}
									name={`socialNetworks.${index}.link`}
									type={'text'}
									maxLength={100}
									value={values.socialNetworks[index].link}
									onChange={handleChange}
									labelText={<RemoveLabel labelText={item.title} callback={() => removeNetworkItem(remove, index, item.title)} />}
								/>
								<ErrorMsg></ErrorMsg>
							</FieldWrap>)
						}</InputsWrap>
					)}

				</FieldArray>
				{showAddBtn && <SPSecondaryButton onClick={() => setModalOpen(true)} width="auto" isLight type="button">
					<span>More social networks</span>
					<PlusIcon />
				</SPSecondaryButton>}
			</Wrapper>
			{isModalOpen && <ListModal
				isModalOpen={isModalOpen}
				setModalOpen={setModalOpen}
				socialNetworks={values.socialNetworks}
				selectedNetworks={values.selectedNetworks}
				handleChange={handleChange}
				setFieldValue={setFieldValue}
				isDesktop={isDesktop} />}
		</OrganizationCard>
	</div>
}