import bagIcon from '../../../../assets/img/jobStory/bag.svg';
import bagClockIcon from '../../../../assets/img/jobStory/bagClock.svg';
import bulbIcon from '../../../../assets/img/jobStory/bulb.svg';
import contractIcon from '../../../../assets/img/jobStory/contract.svg';
import contractToHireIcon from '../../../../assets/img/jobStory/contractToHire.svg';
import heartIcon from '../../../../assets/img/jobStory/heart.svg';
import intershipIcon from '../../../../assets/img/jobStory/intership.svg';
import rocketIcon from '../../../../assets/img/jobStory/rocket.svg';

export const jobKindIcons: any = {
  directHire: bagIcon,
  contractHire: contractToHireIcon,
  contract: contractIcon,
  internship: intershipIcon,
  temporary: bagClockIcon,
  projectBased: rocketIcon,
  volunteer: heartIcon,
  apprenticeship: bulbIcon,
}