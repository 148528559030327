import styled from "styled-components";
import {theme} from "../../../../theme";

export const PlayerWrapper = styled('div')`
  border-radius: 12px;
  overflow: hidden;
  height: 100%;

  vm-playback-control {
    scale: 2;
    --vm-control-border-radius: 40px;

    @media(max-width: ${theme.screens.desktop}) {
      scale: 1.5;
    }
  }
  
` 
