import { HandleSubmitType } from './SpecificationStep.props';
import * as Yup from 'yup';

const clearEquityValues = (values: any) => {
  const equityKeyses = ['equityFrom', 'equityTo', 'equitySelect', 'vestingSchedule'];

  for (const key of equityKeyses) {
    values[key] && delete values[`${key}`];
  }
}

const clearCompensationValues = (values: any) => {
  const compensationKeyses: any = {
    annualSalary: [
      'salaryRateFrom',
      'salaryRateTo',
      'salaryFromSelect',
      'salaryPeriodSelect',
      'commisionRateFrom',
      'commisionRateTo',
      'commisionRateSelect',
      'bonusAmountFrom',
      'bonusAmountTo',
      'bonusAmountSelect',
      'signInBonusFrom',
      'signInBonusTo',
      'signInBonusSelect'
    ],
    hourlyWage: [
      'hourlyRateFrom',
      'hourlyRateTo',
      'hourlyFromSelect',
      'commisionRateFrom',
      'commisionRateTo',
      'commisionRateSelect'
    ],
    commission: [
      'commisionRateFrom',
      'commisionRateTo',
      'commisionRateSelect'
    ]
  }

  const concatArr = compensationKeyses.annualSalary.concat(compensationKeyses.hourlyWage).concat(compensationKeyses.commission);
  const compensationKeys = concatArr.filter((item: string, idx: number) => concatArr.indexOf(item) === idx);

  for (const key of Array.from(compensationKeys)) {
    const isCompensationIncludeField = values.compensation && compensationKeyses[values.compensation].includes(key);
    const isNeedToDelete = !isCompensationIncludeField && values[`${key}`];
    const unpaidJob = values.internshipType && values.internshipType === 'unpaidInternship' && values[`${key}`];
    (isNeedToDelete || unpaidJob) && delete values[`${key}`];
  }
}

export const handleSubmit = async ({ values, saveAnswers, updatingUrl }: HandleSubmitType) => {
  const valuesCopy = { ...values };
  clearCompensationValues(valuesCopy);
  (valuesCopy.equityIncluded && valuesCopy.equityIncluded === 'no') && clearEquityValues(valuesCopy);
  const arrayWithDependents: any = [];
  const stepInfo = {
    title: 'specification',
    nextStep: 'contact',
    done: 3
  }
  saveAnswers({ values: valuesCopy, stepInfo, arrayWithDependents, updatingUrl })
}

const validationForCompensation: any = {
  createValidation: (validationKeys: Array<string>, values: any) => {
    const errors: any = {};
    for (const key of validationKeys) {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    }
    return Object.keys(errors).length > 0 ? errors : null
  },
  annualSalary: function (values: any) {
    const validationKeys = ['salaryRateFrom', 'salaryRateTo', 'salaryFromSelect', 'salaryPeriodSelect']
    return this.createValidation(validationKeys, values)
  },
  hourlyWage: function (values: any) {
    const validationKeys = ['hourlyRateFrom', 'hourlyRateTo', 'hourlyFromSelect']
    return this.createValidation(validationKeys, values)
  },
  yesEquity: function (values: any) {
    const validationKeys = ['equitySelect', 'equityFrom', 'equityTo']
    return this.createValidation(validationKeys, values)
  },
  fixedBudgetYes: function (values: any) {
    const validationKeys = ['fixedBudgetFrom', 'fixedBudgetTo', 'fixedBudgetSelect']
    return this.createValidation(validationKeys, values)
  },
}

const validationForFixedBudget = (values: any) => {
  const fieldsForBudget = ['fixedBudgetFrom', 'fixedBudgetTo', 'budgetToSelect'];
  const errors: any = {};
  for (const field of fieldsForBudget) {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  }
  return errors;
}

export const validation = (values: any) => {
  let errors: any = {};
  const isPaidIntership = values.internshipType && values.internshipType === 'paidInternship';
  const validationLogic: any = (valuesKey: any) => {
    switch (valuesKey) {
      case 'workGoingToBe':
        if (!values.workGoingToBeFrom) {
          errors.workGoingToBe = 'Required'
          errors.workGoingToBeFrom = 'Required';
        } else if (!values.workGoingToBeTo) {
          errors.workGoingToBe = 'Required'
          errors.workGoingToBeTo = 'Required';
        } else if (!values.periodWorkGoingToBe) {
          errors.workGoingToBe = 'Required';
          errors.periodWorkGoingToBe = 'Required';
        }
        break;
      case 'internshipType':
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required';
        } else if (values.internshipType && isPaidIntership && !values.compensation) {
          errors.internshipType = 'Required';
        }
        break;
      case 'compensation':
        const isItCompensation = values.compensation && validationForCompensation[values.compensation];
        const compensationField = isItCompensation && validationForCompensation[values.compensation](values);
        const isIntershipCompensation = isPaidIntership && isItCompensation && compensationField;
        const isMainCompensation = !values.internshipType && isItCompensation && compensationField;
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required';
        } else if (isIntershipCompensation || isMainCompensation) {
          errors = { ...errors, ...compensationField, compensation: 'Required' }
        }

        if (values.internshipType === 'paidInternship' && (errors.compensation)) {
          errors.internshipType = 'Required';
        }
        break;
      case 'fixedBudget':
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required';
        } else if (values[valuesKey] === 'fixedBudgetYes') {
          const budgetErrors = validationForFixedBudget(values);
          errors = { ...errors, ...budgetErrors };
        }
        break;
      case 'needResume':
      case 'contractLength':
      case 'contractExtendible':
        if (!values[valuesKey]) {
          errors[valuesKey] = 'Required';
        }
        break;
      case 'whatCandidateNeedLearn':
        if (!values[valuesKey] || values[valuesKey].length === 0) {
          errors[valuesKey] = 'Required';
        }
        break;
      default:
    }
  }
  for (const key in values) {
    validationLogic(key)
  }
  return errors
}

export const validationScheme = Yup.object().shape({
  compensation: Yup.string().required('Required'),
  needResume: Yup.string()
    .required('Required'),
  contractLength: Yup.string()
    .required('Required'),
  contractExtendible: Yup.string()
    .required('Required'),
  internshipType: Yup.string()
    .required('Required'),
})