import styled from "styled-components";
import { theme } from "../../../../theme";
import closeIcon from '../../../../assets/img/close.svg';

export const FieldHeader = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const Title = styled('div')`
  font-size: ${theme.fontSizes.big};
  color: ${theme.colors.black};
  display: flex;
  align-items: flex-start;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xl};
    margin: ${theme.offsets.md} 0;
  }
`

export const RemoveBtn = styled('span')`
  font-weight: 600;
  font-size: ${theme.offsets.lg};
  display: flex;
  align-items: center;
  cursor: pointer;
  :after{
    content: '';
    display: flex;
    background-image: url(${closeIcon});
    background-repeat: no-repeat;
    background-position: center;
    width:16px; 
    height:16px;
    margin-left: ${theme.offsets.md};
  }
`

export const DoubleFieldWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media(max-width: ${theme.screens.tablet}) {
    flex-wrap: wrap;
  }
`

export const FieldWrap = styled('div')<{leftSide?:boolean}>`
  width:100%;
  display: block;
  ${(props)=>props.leftSide?`margin-right:${theme.offsets.md};`:`margin-left:${theme.offsets.md};`}
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

export const ErrorText = styled('div')`
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.red};
  text-align: left;
  height: 12px;
`

export const OptionalLabel = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  span{
    color: #656B72;
  }
`

export const ButtonWrap = styled('div')`
& button{
  margin-right: ${theme.offsets.lg};
  margin-top: ${theme.offsets.lg};
  width: auto;
  padding-left: ${theme.offsets.lg};
  padding-right: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    padding-left: ${theme.offsets.lg};
    padding-right: ${theme.offsets.lg};
  }
  svg{
    margin-left: ${theme.offsets.sm};
    @media(max-width: ${theme.screens.tablet}) {
      width: 16px;
      height: 16px;
    }
  }
}
`