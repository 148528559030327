import { StyleForTable } from "../../../../common/Table/Table.presents";
import moment from "moment-timezone";
import { NewReviewers } from '../../JobsList.presents';
import { ActionsMenu } from "../../../../common/Modals/ActionListMenu";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { DesktopJobListType } from "../../JobsList.props";
import sortIcon from '../../../../../assets/img/sortIcon.svg';
import { getSortButtonClass, setActionsForJob } from "../../helper";
import { Skeleton } from "../../../../common/Skeleton";
import { useState } from "react";

export const DesktopJobList = (props: DesktopJobListType) => {
  const {
    handleOpenModal,
    handleShareJob,
    handleEditJob,
    activeActionBtn,
    handleSortTable,
    sortData,
    handleOpenMenu,
    setActiveActionBtn,
    showTableBody,
    isDesktop,
    history,
    isSpinnerOpen
  } = props;

  const { jobStory } = useRootStore();

  const [isPositionUnder, setPositionUnder] = useState(false);

  const menuPositionhandler = (evant: React.MouseEvent<HTMLElement>, index: number) => {
    //For two last items
    const BOTTOM_LIST_PADDING = 350;
    const windowHeight = window.innerHeight;
    const menuPosition = evant.clientY + BOTTOM_LIST_PADDING;
    //set position
    setPositionUnder(windowHeight > menuPosition);
    //Open menu
    handleOpenMenu(index)
  }

  const ActionsMenuProps = {
    handleOpenModal,
    handleShareJob,
    handleEditJob,
    activeActionBtn,
    isDesktop,
    handleOpenMenu,
    history
  };

  return <>
    <StyleForTable>
      <thead>
        <tr>
          <th>Job title <button onClick={() => handleSortTable('jobTitle')} className={getSortButtonClass(sortData, 'jobTitle')}><img src={sortIcon} alt="V" /></button></th>
          <th>Created  <button onClick={() => handleSortTable('creationDate')} className={getSortButtonClass(sortData, 'creationDate')}><img src={sortIcon} alt="V" /></button></th>
          <th>Last update <button onClick={() => handleSortTable('updatingDate')} className={getSortButtonClass(sortData, 'updatingDate')}><img src={sortIcon} alt="V" /></button></th>
          <th>Applications</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      {showTableBody && <tbody>
        {jobStory.jobList.map((item: any, index: number) => <tr key={index}>
          <td>{item.jobTitle}</td>
          <td>
            <p>{moment(new Date(item.creationDate)).format('DD/MM/YY')}</p>
            <p>{moment(new Date(item.creationDate)).format('hh:mm a')}</p>
          </td>
          <td>
            <p>{moment(new Date(item.updatingDate)).format('DD/MM/YY')}</p>
            <p>{moment(new Date(item.updatingDate)).format('hh:mm a')}</p>
          </td>
          <td>
            {item.reviewsNumber || 0}
            {item.newReviewers ? <NewReviewers>{item.newReviewers}</NewReviewers> : ''}
          </td>
          <td>{item.jobStatus.charAt(0).toUpperCase(0)+item.jobStatus.slice(1)}</td>
          <td >
            <button
              className={`action-btn ${activeActionBtn === index && 'active'}`}
              onClick={(evant) => { menuPositionhandler(evant, index)}}
              onBlur={() => setActiveActionBtn(null)}
            >
              <div className='icon'></div>
              {activeActionBtn === index && <ActionsMenu
                index={index}
                activeActionBtn={activeActionBtn}
                isDesktop={isDesktop}
                handleOpenMenu={handleOpenMenu}
                isPositionUnder={isPositionUnder}
                actionList={setActionsForJob({ jobListItem: item, ...ActionsMenuProps })} />}
            </button>
          </td>
        </tr>
        )}
      </tbody>}
    </StyleForTable>
    {isSpinnerOpen && <Skeleton isDesktop={isDesktop} />}
  </>
}