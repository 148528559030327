import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useGetDevice from '../../../hooks/useGetDevice';
import {
  onGetCandidateExpertises,
  onGetCandidatePreferences,
  onGetUser
} from '../../../services/firebase/firestore/reads';
import { onGetResume } from '../../../services/firebase/firestore/ResumeBuilder/writes';
import { useRootStore } from '../../../state/stores/storeContext';
import { NotFound } from '../../common/NotFound';
import { ContactInformation } from './Components/ContactInformation/ContactInformation';
import { MainInformation } from './Components/MainInformation/MainInformation';
import { SideInformation } from './Components/SideInformation/SideInformation';
import { Background, Wrapper } from './Resume.presents';
import {Candidate, CandidateExpertises, CandidatePreferences, CandidateResume} from './Resume.props';

export const Resume = ({
  isPublic,
}:{
  isPublic?: boolean;
}) => {
  const isDesktop = useGetDevice();
  const [preferences, setPreferences] = useState<CandidatePreferences>()
  const [expertises, setExpertises] = useState<{ area: string; skills: string[]; }[]>()
  const [resume, setResume] = useState<CandidateResume>()
  const [candidate, setCandidate] = useState<Candidate>()
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const [isPrivate, setIsPrivate] = useState(false);  
  const { candidateStore } = useRootStore();
  const params = useParams() as any
  const uid = params.id ? params.id : candidateStore.uid
  
  useEffect(() => {
    (async () => {
      let _preferences = await onGetCandidatePreferences(uid) as CandidatePreferences
      let _expertises = await onGetCandidateExpertises(uid) as CandidateExpertises
      if (_preferences) {
        let _resume = await onGetResume(uid) as CandidateResume
        let _candidate = await onGetUser(uid) as Candidate
        setResume(_resume)
        setPreferences(_preferences);
        _expertises?.expertiseAreas && _expertises.expertiseAreas.length > 0 && setExpertises(_expertises.expertiseAreas);
        setCandidate(_candidate);
      }
      setIsLoaded(true)
    })()
  }, []);

  if (!preferences && isLoaded){
    return <NotFound />
  }
  return <Background>
    <Wrapper isDesktop={isDesktop}>
      {isLoaded && (
        <>
          <ContactInformation isAuthenticated={candidateStore.isAuthenticated} isPublic={isPublic} isDesktop={isDesktop} candidate={candidate} resume={resume} />
          <MainInformation isDesktop={isDesktop} resume={resume} preferences={preferences} expertises={expertises}/>
          <SideInformation isDesktop={isDesktop} resume={resume} preferences={preferences} />
        </>
      )}

    </Wrapper>
  </Background>
}
