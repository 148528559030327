import styled from 'styled-components'
import { theme } from '../../../../theme'

export const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const PageContent = styled('div')<{isDesktop: boolean}>`
  width: 100%;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: ${(props)=>props.isDesktop ? '0px' : `${theme.offsets.xxl} 0` };
`

export const Wrapper = styled('div')<{isDesktop:boolean}>`
  display: flex;
  flex-direction: ${(props)=>props.isDesktop ? 'row' : 'column'};
  min-height: 100vh;
`