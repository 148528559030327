export const BriefcaseIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="27" width="43" height="19" fill="#F8DDC3" />
    <path d="M8 22C8 19.2386 10.2386 17 13 17H45C47.7614 17 50 19.2386 50 22V26H8V22Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M46 18H12C9.79086 18 8 19.7909 8 22V42C8 44.2091 9.79086 46 12 46H46C48.2091 46 50 44.2091 50 42V22C50 19.7909 48.2091 18 46 18ZM12 16C8.68629 16 6 18.6863 6 22V42C6 45.3137 8.68629 48 12 48H46C49.3137 48 52 45.3137 52 42V22C52 18.6863 49.3137 16 46 16H12Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M35 12H23C21.8954 12 21 12.8954 21 14V16H37V14C37 12.8954 36.1046 12 35 12ZM23 10C20.7909 10 19 11.7909 19 14V18H39V14C39 11.7909 37.2091 10 35 10H23Z" fill={active ? "#E4954D" : "#433E3A"} />
    <rect x="6" y="26" width="46" height="2" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M13 26H23V31C23 32.6569 21.6569 34 20 34H16C14.3431 34 13 32.6569 13 31V26Z" fill="#F6F8FA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 28H15V31C15 31.5523 15.4477 32 16 32H20C20.5523 32 21 31.5523 21 31V28ZM13 26V31C13 32.6569 14.3431 34 16 34H20C21.6569 34 23 32.6569 23 31V26H13Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M35 26H45V31C45 32.6569 43.6569 34 42 34H38C36.3431 34 35 32.6569 35 31V26Z" fill="#F6F8FA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M43 28H37V31C37 31.5523 37.4477 32 38 32H42C42.5523 32 43 31.5523 43 31V28ZM35 26V31C35 32.6569 36.3431 34 38 34H42C43.6569 34 45 32.6569 45 31V26H35Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
