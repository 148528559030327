import styled from 'styled-components';
import { theme } from '../../../../../theme';
import actionIcon from '../../../../../assets/img/tableAction.svg';
import plusIcon from '../../../../../assets/img/plus.svg';

export const MobileScreen = styled('div')<{ isLoading: boolean }>`
  padding:${theme.offsets.md};
  ${(props)=>!props.isLoading && 'position: relative;'}
`

export const ItemWrap = styled('div')`
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  margin-bottom: ${theme.offsets.lg};
  display: flex;
  flex-direction: column;
  padding: ${theme.offsets.lg};
  font-size: 14px;
  color: ${theme.colors.black};
  position: relative;
  @media(max-width: ${theme.screens.mobile}) { 
    font-size: ${theme.fontSizes.md};
    padding: ${theme.offsets.md};
  } 
`

export const JobStatusWrap = styled('div')`
  display: flex;
  line-height: ${theme.offsets.lg};
`
export const Status = styled('div')`
  text-transform: capitalize;
  border-right: 1px solid ${theme.colors.lightGray};
  padding-right: ${theme.offsets.sm};
  margin-right: ${theme.offsets.sm};
  display: flex;
  align-items: center;
`
export const LastUpdated = styled('div')`
  display: flex;
  align-items: center;
  
`
export const UpdatedDate = styled('div')`
  margin-left: ${theme.offsets.sm};
  color: ${theme.colors.gray};
`
export const StatusIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: ${theme.offsets.xs};

`
export const JobTitle = styled('div')`
  text-transform: capitalize;
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.offsets.sm};
  @media(max-width: ${theme.screens.mobile}) { 
    font-size: 14px;
} 
`
export const BlockFooter = styled('div')`
 display: flex;
 justify-content: space-between;
 margin-top: ${theme.offsets.sm};
 @media(max-width: ${theme.screens.mobile}) { 
  flex-direction: column;
}
`
export const OrganizationName = styled('div')`
  text-transform: capitalize;
`
export const Applications = styled('div')`
  display: flex;
  align-items: center;
  @media(max-width: ${theme.screens.mobile}) { 
    margin-top: ${theme.offsets.sm};
}
`
export const AllApplications = styled('span')`
  margin-left: ${theme.offsets.sm};
  color: ${theme.colors.gray};

`

export const ActionBtnWrap = styled('div')`
  position: absolute;
  top: ${theme.offsets.xl};
  right: ${theme.offsets.sm};
`

export const ActionBtn = styled('button')`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  &:hover {
    background-color: rgba(236, 146, 60, 0.1);
  }
  &:hover div:first-child {
    background-color: ${theme.colors.lightOrange};
  }
`

export const BtnIcon = styled('div')`
  mask-image: url(${actionIcon});
  height: 18px;
  width: 4px;
  background-color: ${theme.colors.darkGray};
`

export const CreateJobBtn = styled('div')`
  height: 64px;
  width: 64px;
  background-color: ${theme.colors.lightOrange};
  border-radius: 100%;
  background-image: url(${plusIcon});
  background-position: center;
  background-size: 18px;
  background-repeat: no-repeat;
  position: absolute;
  top: -${theme.offsets.extraMajor};
  right: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) { 
    position: fixed;
    bottom: ${theme.offsets.md};
    right: ${theme.offsets.md};
    top: auto;
} 
`

export const MobilePageNavigation = styled('div')`
  padding: ${theme.offsets.md};
  @media(max-width: ${theme.screens.mobile}) { 
    position: fixed;
    bottom: ${theme.offsets.md};
    right: ${theme.offsets.md};
    top: auto;
} 
`

export const SortBtn = styled('div')`
  margin-bottom: ${theme.offsets.xl};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.xs};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    margin-bottom: ${theme.offsets.md};
} 
`

export const SortedBy = styled('span')`
  font-weight: 400;
  margin-left: ${theme.offsets.xs};
`

