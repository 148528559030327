import { Link } from 'react-router-dom'
import logo from '../../../../assets/img/logo.svg'
import { HeaderProps } from "./Header.props"
import { HeaderContainer, HeaderContent, CtaContainer, Logo, HeaderText, HeaderCta } from "./Header.presents"
import { useTranslation } from "react-i18next";

export const Header = (props: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <HeaderContent>
      {
        props.showLogo ? <Link to='/'>
          <Logo src={logo} alt="Sidepost logo" />
        </Link> :
        <div></div>
      }
      {/* Register CTA */}
      { 
        props.showRegister
          ? <CtaContainer>
              <HeaderText>{t('header.registerText')}</HeaderText>
              <HeaderCta to={props.isCandidateAuth ? 'sign-up' :'sign-up'}>{t('header.registerCta')}</HeaderCta>
            </CtaContainer>
          : null
      }
      {/* Login CTA */}
      { 
        props.showLogin 
          ? <CtaContainer>
              <HeaderText>{t('header.loginText')}</HeaderText>
              <HeaderCta to={props.isCandidateAuth ? 'sign-out' :'sign-in'}>{t('header.loginCta')}</HeaderCta>
            </CtaContainer>
          : null
      }
      </HeaderContent>
    </HeaderContainer>
  )
}
