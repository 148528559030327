import directHireIcon from '../../../../assets/img/jobStory/directHire.svg';
import temporaryRemoteIcon from '../../../../assets/img/jobStory/temporaryRemote.svg';
import sideJobIcon from '../../../../assets/img/jobStory/sideJobYes.svg';
import customerFacingIcon from '../../../../assets/img/jobStory/customerFacing.svg';
import relocationPackIcon from '../../../../assets/img/jobStory/relocationPackFull.svg';
import workVisaIcon from '../../../../assets/img/jobStory/workVisa.svg';
import startupIcon from '../../../../assets/img/jobStory/startup.svg';
import overtimeIsIncludedIcon from '../../../../assets/img/jobStory/overtimeIsIncluded.svg';
import contractHireIcon from '../../../../assets/img/jobStory/contractHireSnap.svg';
import contractIcon from '../../../../assets/img/jobStory/contractSnap.svg';
import internshipIcon from '../../../../assets/img/jobStory/internshipSnap.svg';
import temporaryIcon from '../../../../assets/img/jobStory/temporarySnap.svg';
import projectBasedIcon from '../../../../assets/img/jobStory/projectBasedSnap.svg';
import volunteerIcon from '../../../../assets/img/jobStory/volunteerSnap.svg';
import apprenticeshipIcon from '../../../../assets/img/jobStory/apprenticeshipSnap.svg';
import internationalIcon from '../../../../assets/img/jobStory/international.svg';
import domesticIcon from '../../../../assets/img/jobStory/domestic.svg';
import shortIcon from '../../../../assets/img/jobStory/short.svg';
import someRemoteIcon from '../../../../assets/img/jobStory/someRemote.svg';
import covidRemoteIcon from '../../../../assets/img/jobStory/covidRemote.svg';
import yesRemoteIcon from '../../../../assets/img/jobStory/yesRemote.svg';
import particalIcon from '../../../../assets/img/jobStory/partical.svg';
import canDoneOverWeekendsIcon from '../../../../assets/img/jobStory/canDoneOverWeekends.svg';
import canDoneOverbusinessHoursIcon from '../../../../assets/img/jobStory/canDoneOverbusinessHours.svg';
import salaryRateIcon from '../../../../assets/img/jobStory/salaryRate.svg';
import hourlyRateIcon from '../../../../assets/img/jobStory/hourlyRate.svg';
import commisionRateIcon from '../../../../assets/img/jobStory/commisionRate.svg';
import paidInternshipIcon from '../../../../assets/img/jobStory/paidInternship.svg';
import yesEquityIcon from '../../../../assets/img/jobStory/yesEquity.svg';
import fixedBudgetYesIcon from '../../../../assets/img/jobStory/fixedBudgetYes.svg';





export const SnapshotsIcons: any = {
  directHire: directHireIcon,
  contractHire: contractHireIcon,
  contract: contractIcon,
  internship: internshipIcon,
  temporary: temporaryIcon,
  projectBased: projectBasedIcon,
  volunteer: volunteerIcon,
  apprenticeship: apprenticeshipIcon,
  international: internationalIcon,
  domestic: domesticIcon,
  short: shortIcon,
  temporaryRemote: temporaryRemoteIcon,
  someRemote: someRemoteIcon,
  covidRemote: covidRemoteIcon,
  yesRemote: yesRemoteIcon,
  sideJob: sideJobIcon,
  customerFacing: customerFacingIcon,
  packfull: relocationPackIcon,
  packparticial: particalIcon,
  canDoneOverWeekends: canDoneOverWeekendsIcon,
  canDoneOverbusinessHours: canDoneOverbusinessHoursIcon,
  workVisa: workVisaIcon,
  startup: startupIcon,
  overtimeIsIncluded: overtimeIsIncludedIcon,
  salaryRate: salaryRateIcon,
  hourlyRate: hourlyRateIcon,
  commisionRate: commisionRateIcon,
  paidInternship: paidInternshipIcon,
  yesEquity: yesEquityIcon,
  fixedBudgetYes: fixedBudgetYesIcon
}