import { useHistory } from "react-router-dom";
import { MenuWrap, MenuItem } from "./UserMenu.presents";
import { ReactComponent as UserIcon } from '../../../../../../assets/img/user.svg';
import { ReactComponent as ExitIcon } from '../../../../../../assets/img/exit.svg';
import { ReactComponent as SupportIcon } from '../../../../../../assets/img/support.svg';
import { useRootStore } from "../../../../../../state/stores/storeContext";
import { useTranslation } from "react-i18next";

export const UserMenu = ({ isOpen }: { isOpen: boolean }) => {
  const history = useHistory();
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const { t } = useTranslation();

  const exitFromWebsite = async () => {
    candidateStore.signOutUser();
    resumeBuilderStore.clearResumeBuilder();
    history.push('/candidate/sign-in');
  }

  const menuList = [
    { path: "/candidate/profile", title: t('adminPanel.tabProfile'), img: <UserIcon /> },
    { path: "/candidate/support", title: t('adminPanel.tabSupport'), img: <SupportIcon /> }
  ]

  return <MenuWrap isOpen={isOpen}>
    {menuList.map((item, index) =>
      <MenuItem
        key={index}
        onClick={() => {
          resumeBuilderStore.showResumePreview(false);
          history.push(item.path);
          }}>
        {item.img}
        {item.title}
      </MenuItem>
    )}
    <MenuItem isWarning onClick={() => exitFromWebsite()}>
      <ExitIcon />
      Exit
    </MenuItem>
  </MenuWrap>
}