export const TruckIcon = ({ active }: { active?: boolean }) => {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53 40.5V30H38.5V22H47.5L45 18.5H35L34.5 12V40.5H38.5L41 36C42.3333 36.3333 46.6 37.1 47 37.5C47.4 37.9 48.1667 39.6667 48.5 40.5H53Z" fill="#F8DDC3" />
    <path d="M4 40.5V12H30L35 12.2606V41H20V39.5L17.5 37L15.5 36.5L14 36L11.5 37.5L9.5 40.5H4Z" fill="white" />
    <circle cx="15" cy="41" r="4" fill="white" />
    <circle cx="43" cy="41" r="4" fill="white" />
    <path d="M39 29.5V22H47.5L52.5 29.5H39Z" fill="white" />
    <path d="M45.5851 17.7007C45.4161 17.4437 45.1258 17.2884 44.8135 17.2875H35.6268V11.8982C35.6268 11.4024 35.2152 11 34.7082 11H3.91863C3.41157 11 3 11.4024 3 11.8982V40.6414C3 41.1372 3.41157 41.5396 3.91863 41.5396H9.43195C9.95743 44.4714 12.8136 46.4314 15.8121 45.9184C18.0986 45.5268 19.89 43.7762 20.2906 41.5396H37.5466C38.0721 44.4714 40.9282 46.4314 43.9268 45.9184C46.2133 45.5268 48.0047 43.7762 48.4053 41.5396H53.0814C53.5884 41.5396 54 41.1372 54 40.6414V30.7609C54 30.5885 53.9485 30.4205 53.853 30.2759L45.5851 17.7007ZM46.6507 22.6769L50.8123 28.9644H39.3014V22.6769H46.6507ZM14.8614 44.2343C12.8321 44.2343 11.1868 42.6256 11.1868 40.6414C11.1868 38.6572 12.8321 37.0486 14.8614 37.0486C16.8907 37.0486 18.536 38.6572 18.536 40.6414C18.536 42.6256 16.8907 44.2343 14.8614 44.2343ZM14.8614 35.2521C12.1733 35.2548 9.88034 37.1518 9.43206 39.7432H4.83737V12.7964H33.7894L33.7893 36.1503L33.7894 39.7432H20.2907C19.8423 37.1527 17.5494 35.2547 14.8614 35.2521ZM42.9761 44.2343C40.9468 44.2343 39.3014 42.6256 39.3014 40.6414C39.3014 38.6572 40.9468 37.0486 42.9761 37.0486C45.0054 37.0486 46.6507 38.6572 46.6507 40.6414C46.6507 42.6256 45.0054 44.2343 42.9761 44.2343ZM48.4054 39.7431C47.8799 36.8113 45.0238 34.8513 42.0253 35.3643C39.7388 35.756 37.9473 37.5065 37.5468 39.7431H35.6267V19.084H44.3081L45.4932 20.8805H38.3827C37.8756 20.8805 37.4641 21.2829 37.4641 21.7787V29.8627C37.4641 30.3585 37.8756 30.7609 38.3827 30.7609H51.9882L52.1627 31.0214V36.1503H50.3254C49.818 36.1503 49.4067 36.5524 49.4067 37.0485C49.4067 37.5446 49.818 37.9467 50.3254 37.9467H52.1627V39.7432L48.4054 39.7431Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M13.9427 40.6414C13.9427 41.1375 14.354 41.5396 14.8614 41.5396C15.3688 41.5396 15.7801 41.1375 15.7801 40.6414C15.7801 40.1453 15.3688 39.7432 14.8614 39.7432C14.354 39.7432 13.9427 40.1453 13.9427 40.6414Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M42.0574 40.6414C42.0574 41.1375 42.4687 41.5396 42.9761 41.5396C43.4835 41.5396 43.8948 41.1375 43.8948 40.6414C43.8948 40.1453 43.4835 39.7432 42.9761 39.7432C42.4687 39.7432 42.0574 40.1453 42.0574 40.6414Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
