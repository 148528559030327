import styled from "styled-components"
import { BaseButton } from "../SPBaseButton/SPBaseButton.presets"
import { theme } from "../../../../theme"

export const SecondaryButton = styled(BaseButton) <{isLight?: boolean, color?: string}>`
  background-color: ${(props)=>props.isLight ? theme.colors.coral : theme.colors.lightOrange};
  border-color: ${(props)=>props.isLight ? theme.colors.coral : theme.colors.lightOrange};
  color: ${(props)=>props.isLight ? theme.colors.black : theme.colors.white};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      opacity: 0.9;
    }
  }
  @media(max-width: ${theme.screens.mobile}) { 
    font-size: ${theme.fontSizes.md};
  } 

  :disabled {
    cursor: not-allowed;
    ${props=> props.isLight ? `
      color: ${props.color ? props.color : theme.colors.black};
      background: ${theme.colors.coral};
      border-color: ${theme.colors.coral};
    `: 
    `
      color: ${theme.colors.lightGray};
      background: ${theme.colors.lightOrange};
      border-color: ${theme.colors.lightOrange};
    `
    }
  }

  :focus {
    /* border: 2px outset black; */
  }
`
