import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { getPlaces } from "../../../../../services/api/outsideResources/outsideResources.domains";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { Input } from "../../../../common/Input";
import { SelectCom } from "../../../../common/Select";
import { ContentWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { initialLocation } from "../../../../../state/stores/resumeBuilder";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const LocationBlock = (
  {
    isDesktop,
    setLocationOpen
  }:
    {
      isDesktop: boolean;
      setLocationOpen: (value: boolean) => void;
    }
) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [countryList, setCountryList] = useState<Array<{ label: string, value: string }>>([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('country');
      const countryList = placeResponse.data.data;
      setCountryList(countryList);
    })()
  }, [])

  const { resumeBuilderStore, candidateStore } = useRootStore();

  return <CandidateFormCard
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    isDesktop={isDesktop}
    title={'Location'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={resumeBuilderStore.location}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            country: Yup.string().required(t('validation.required')),
            zipCode: Yup.string()
              .matches(/^[a-zA-Z0-9-]+$/, t('validation.zipCode'))
              .max(16, t('validation.max', { characters: 16 })),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveLocation({ location: values }, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleReset
        }) => {
          const onChangeHandler = (value: any) => {
            setShowSaveBtn(true);
            handleChange(value);
          };
          return <form onSubmit={handleSubmit}>

            <MultiFieldWrap>
              <MultiField>
                <FieldWrap>
                  <Field
                    labelText={'Country/Region'}
                    options={countryList}
                    component={SelectCom}
                    name={'country'}
                    onChange={() => setShowSaveBtn(true)}
                    error={errors.country}
                  />
                  <ErrorMsg>{errors.country}</ErrorMsg>
                </FieldWrap>
              </MultiField>

              <MultiField>
                <FieldWrap>
                  <Field
                    as={Input}
                    name={'state'}
                    type={'text'}
                    value={values.state}
                    onChange={onChangeHandler}
                    maxLength={50}
                    labelText={'State/Territory'}
                    error={errors.state} />
                  <ErrorMsg>{errors.state}</ErrorMsg>
                </FieldWrap>
              </MultiField>

            </MultiFieldWrap>


            <MultiFieldWrap>
              <MultiField>
                <FieldWrap>
                  <Field
                    as={Input}
                    name={'city'}
                    type={'text'}
                    value={values.city}
                    onChange={onChangeHandler}
                    maxLength={50}
                    labelText={'City'}
                    error={errors.city} />
                  <ErrorMsg>{errors.city}</ErrorMsg>
                </FieldWrap>
              </MultiField>
              <MultiField>
                <FieldWrap>
                  <Field
                    as={Input}
                    name={'zipCode'}
                    type={'text'}
                    value={values.zipCode}
                    onChange={onChangeHandler}
                    maxLength={50}
                    labelText={'ZIP/Postal Code'}
                    error={errors.zipCode} />
                  <ErrorMsg>{errors.zipCode}</ErrorMsg>
                </FieldWrap>
              </MultiField>

            </MultiFieldWrap>
            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveLocation({ location: initialLocation }, candidateStore.uid);
                await resumeBuilderStore.setLocation(initialLocation);
                handleReset();
                setLocationOpen(false);
              }}
            />}
          </form>
        }}
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
}