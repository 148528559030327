import { theme } from '../../../../../theme';
import styled from 'styled-components';

const SPINNER_SIZE = "24px";

export const FileContainer = styled('div')`
  width: 100%;
  max-width: 186px;
  margin: 40px auto;
`

export const Media = styled('div')`
  display: flex;
  align-items: center;
  background: #F6F8FA;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  min-height: 52px;
  padding: ${theme.offsets.lg};
`

export const MediaIcon = styled('img')`
  height: 22px;
  width: 22px;
`

export const MediaName = styled('p')`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.black};
  margin: 0 ${theme.offsets.sm};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const RemoveIcon = styled('button')`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 12px;
    height: 12px;
  }
`

export const Spinner = styled('img')`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  user-select: none;
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
`
