import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { PreferencesForm } from "../../components/Auth/Candidate/SignUp/BaseComponents/PreferencesForm/PreferencesForm";
import { FormWrap } from "../../components/Auth/Candidate/SignUp/Steps/Preferences/Preferences.presents";
import { CandidateHeader } from "../../components/CandidateAdminPanel/BaseComponents/Header/Desktop/Header";
import { MobileCandidateHeader } from "../../components/CandidateAdminPanel/BaseComponents/Header/Mobile/MobileAdminHeader";
import { CandidateProfile } from "../../components/CandidateAdminPanel/Profile/Profile";
import { ResumeBuilder } from "../../components/CandidateAdminPanel/ResumeBuilder/ResumeBuilder";

import { SPSecondaryButton } from "../../components/common/buttons/SPSecondaryButton/SPSecondaryButton";
import { DesktopSidebar } from "../../components/common/Sidebars/AdminSidebar/Desktop";
import { Toggle } from "../../components/common/Toggle";
import useGetDevice from "../../hooks/useGetDevice";
import { Support } from "../../components/CandidateAdminPanel/Support";
import {
  Container,
  Content,
  AdditionalButtons,
  ToggleWrap,
  ToggleLabel,
  DoneButtonWrap,
  MobileNavSection,
  MobileNavTitle,
  PreferencesFormWrap,
  TooltipWrapper,
  ButtonsWrap
} from "./CandidateAccount.presents";
import { CandidatePageType } from "./CandidateAccount.types";
import { useRootStore } from "../../state/stores/storeContext";
import { Resume } from "../../components/CandidateAdminPanel/Resume/Resume";
import { onGetCandidatePreferences } from "../../services/firebase/firestore/reads";
import { VerificationBanner } from "../../components/CandidateAdminPanel/BaseComponents/VerificationBanner/VerificationBanner";
import { VerificationModal } from "../../components/CandidateAdminPanel/BaseComponents/VerificationBanner/VerificationModal";
import { PublicVisibility } from "../../components/CandidateAdminPanel/BaseComponents/PublicVisibility/PublicVisibility";
import { Tutorial } from "../../components/CandidateAdminPanel/BaseComponents/Tutorial/Tutorial";
import { HelperTextTooltipWrapper } from "../../components/common/HelperText/HelperTextTooltipWrapper";
import { Preloader } from "../../components/common/Preloader";
import { ResumeShareModal } from "../../components/CandidateAdminPanel/BaseComponents/ShareModal";

export const CandidateAccount = observer(({
  pageType
}: {
  pageType: CandidatePageType
}) => {
  const isDesktop = useGetDevice();
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const [isShareModalOpen, setShareShowModal] = useState(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isTutorialStart, setIsTutorialStart] = useState(false);


  const onOpenShareModal = () => {
    if (candidateStore.isEmailVerified) {
      setShareShowModal(true);
    } else {
      setIsVerificationModalOpen(true);
    }
  }

  useEffect(() => {
    (async () => {
      let _preferences = await onGetCandidatePreferences(candidateStore.uid);
      resumeBuilderStore.setPreferences(_preferences);
      if (!candidateStore.isTutorialFinished && _preferences) {
        setTimeout(() => {
          setIsTutorialStart(true);
        }, 1000)

      }
      else {
        setIsTutorialStart(false)
      }
    })()
  }, [candidateStore.email]);

  const renderResumeDesktopButtons = useMemo(() => {
    return pageType === CandidatePageType.resume_builder ? <AdditionalButtons>
      <PublicVisibility isDesktop={isDesktop} selectorId="visibility_step" />
      {isTutorialStart && <Tutorial
        isTutorialStart={isTutorialStart}
        setIsTutorialStart={setIsTutorialStart}
        isDesktop={isDesktop}
      />}
      <ToggleWrap isDesktop={isDesktop} id={"preview_step"}>
        <Toggle />
        <ToggleLabel>
          Preview
        </ToggleLabel>
        {candidateStore.isTutorialFinished && <HelperTextTooltipWrapper autoChangePosition text={"You can preview your online resume before publishing and sharing it. This feature shows you how your public resume looks like and seen by visitors once you share it."} title={"Preview Your Resume"} />}
      </ToggleWrap>

      <DoneButtonWrap isDesktop={isDesktop}>
        <SPSecondaryButton
          selectorId="share_step"
          type="button"
          width="100%"
          onClick={() => onOpenShareModal()}>
          Share
        </SPSecondaryButton>
      </DoneButtonWrap>
      {candidateStore.isTutorialFinished && <HelperTextTooltipWrapper autoChangePosition text={"By clicking here, you can share your online resume on social media or via email. This feature also allows you to generate an public Internet link for your resume and share it with anyone."} title={"Share Your Resume"} />}
    </AdditionalButtons> : null;
  }, [pageType, isDesktop, isTutorialStart]);

  const renderResumeMobileButtons = useMemo(() => {
    const additionalButtonsMap = {
      [CandidatePageType.profile]: <MobileNavSection>
        <MobileNavTitle>
          Profile
        </MobileNavTitle>
      </MobileNavSection>,
      [CandidatePageType.support]: <MobileNavSection>
        <MobileNavTitle>
          Support and Feedback
        </MobileNavTitle>
      </MobileNavSection>,
      [CandidatePageType.resume_builder]: <MobileNavSection>
        <Tutorial
          isTutorialStart={isTutorialStart}
          setIsTutorialStart={setIsTutorialStart}
          isDesktop={isDesktop}
        />
        <MobileNavTitle>
          My Resume
        </MobileNavTitle>
        <ButtonsWrap>
          <PublicVisibility isDesktop={isDesktop} selectorId="visibility_step_mob" />
          <ToggleWrap isDesktop={isDesktop} id={"preview_step_mob"}>
            <Toggle />
            <ToggleLabel>
              Preview
            </ToggleLabel>
            {candidateStore.isTutorialFinished && <HelperTextTooltipWrapper left autoChangePosition text={"You can preview your online resume before publishing and sharing it. This feature shows you how your public resume looks like and seen by visitors once you share it."} title={"Preview Your Resume"} />}
          </ToggleWrap>
        </ButtonsWrap>
      </MobileNavSection>,
      [CandidatePageType.preferences]: <MobileNavSection>
        <MobileNavTitle>
          My Preferences
        </MobileNavTitle>
      </MobileNavSection>,
    }
    return additionalButtonsMap[pageType];
  }, [pageType, isDesktop, isTutorialStart]);

  const renderHeaderMobileButtons = useMemo(() => {
    const additionalButtonsMap = {
      [CandidatePageType.profile]: null,
      [CandidatePageType.support]: null,
      [CandidatePageType.resume_builder]: <TooltipWrapper>
        <DoneButtonWrap isDesktop={isDesktop}>
          <SPSecondaryButton
            type="button"
            width="100%"
            onClick={onOpenShareModal}
            selectorId="share_step_mob"
          >
            Share
          </SPSecondaryButton>
        </DoneButtonWrap>
        {candidateStore.isTutorialFinished && <HelperTextTooltipWrapper left autoChangePosition text={"By clicking here, you can share your online resume on social media or via email. This feature also allows you to generate an public Internet link for your resume and share it with anyone."} title={"Share Your Resume"} />}
      </TooltipWrapper>,
      [CandidatePageType.preferences]: null,
    }
    return additionalButtonsMap[pageType];
  }, [pageType, isDesktop, isTutorialStart]);

  const renderPageContent = useMemo(() => {
    const pageContentMap = {
      [CandidatePageType.profile]: <CandidateProfile />,
      [CandidatePageType.support]: <Support />,
      [CandidatePageType.resume_builder]: resumeBuilderStore.isResumeOpen ? <Resume /> : <ResumeBuilder />,
      [CandidatePageType.preferences]: <FormWrap>
        <PreferencesFormWrap>
          <PreferencesForm isPanel={true} />
        </PreferencesFormWrap>
      </FormWrap>,
    };
    return pageContentMap[pageType]
  }, [pageType, resumeBuilderStore.isResumeOpen]);

  return <>
    {candidateStore.uid ?
      <>
        {!candidateStore.isEmailVerified && <VerificationBanner />}
        <Container>
          {isDesktop && !resumeBuilderStore.isResumeOpen && <DesktopSidebar isCandidateService />}
          <Content>
            {isDesktop ? <CandidateHeader
              isLogoHide={isDesktop && !resumeBuilderStore.isResumeOpen}
              additionalButtons={renderResumeDesktopButtons}
            /> : <MobileCandidateHeader additionalButtons={renderHeaderMobileButtons} />}
            {!isDesktop && renderResumeMobileButtons}
            {renderPageContent}
          </Content>
          {isShareModalOpen && <ResumeShareModal
            isOpen={isShareModalOpen}
            onClose={() => setShareShowModal(false)}
            title={"Share your resume with anyone"}
            url={`${window.location.host}/profile/${candidateStore.uid}`} />}
          {isVerificationModalOpen && <VerificationModal isOpen={isVerificationModalOpen} onClose={() => setIsVerificationModalOpen(false)} />}
        </Container>
      </>
      :
      <Preloader isFullScreen />
    }
  </>
})
