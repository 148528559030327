import { ReactComponent as FacebookIcon } from '../../../../../assets/img/resume/socialNetworks/Facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../../../assets/img/resume/socialNetworks/Twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../../../assets/img/resume/socialNetworks/LinkedIn.svg';
import { ReactComponent as YouTubeIcon } from '../../../../../assets/img/resume/socialNetworks/YouTube.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/img/resume/socialNetworks/Instagram.svg';
import { ReactComponent as WebsiteIcon } from '../../../../../assets/img/resume/socialNetworks/Website.svg';
import { ReactComponent as RedditIcon } from '../../../../../assets/img/resume/socialNetworks/Reddit.svg';
import { ReactComponent as WhatsappIcon } from '../../../../../assets/img/resume/socialNetworks/Whatsapp.svg';
import { ReactComponent as TiktokIcon } from '../../../../../assets/img/resume/socialNetworks/Tiktok.svg';
import { ReactComponent as DribbbleIcon } from '../../../../../assets/img/resume/socialNetworks/Dribbble.svg';
import { ReactComponent as BehanceIcon } from '../../../../../assets/img/resume/socialNetworks/Behance.svg';
import { ReactComponent as PinterestIcon } from '../../../../../assets/img/resume/socialNetworks/Pinterest.svg';
import { ReactComponent as SnapchatIcon } from '../../../../../assets/img/resume/socialNetworks/Snapchat.svg';


export const socialNetworksIconMap = {
	Website: <WebsiteIcon />,
	LinkedIn: <LinkedInIcon />,
	Facebook: <FacebookIcon />,
	Twitter: <TwitterIcon />,
	Instagram: <InstagramIcon />,
	YouTube: <YouTubeIcon />,
	Reddit: <RedditIcon />,
	Whatsapp: <WhatsappIcon />,
	Tiktok: <TiktokIcon />,
	Dribbble: <DribbbleIcon />,
	Behance: <BehanceIcon />,
	Pinterest: <PinterestIcon />,
	Snapchat: <SnapchatIcon />
};