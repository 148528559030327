import * as React from "react"
import { SPLightButton } from "../../../common/buttons/SPLightButton/SPLightButton"
import { ApplyJobSectionContainer, ApplyJobSectionTitle } from "./ApplyJobSection.presets"
import { ApplyJobSectionProps } from "./ApplyJobSection.props"

export const ApplyJobSection: React.FC<ApplyJobSectionProps> = (props) => {
  return (
    <ApplyJobSectionContainer>
      <ApplyJobSectionTitle>Interested in this job?</ApplyJobSectionTitle>
      <SPLightButton width="260px" onClick={props.onClick}>Apply Now</SPLightButton> 
    </ApplyJobSectionContainer>
  )
}
