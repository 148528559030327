
import isMobile from "is-mobile";
import { useEffect, useState } from "react"

export default function useGetDevice() {
	const [isDesktop, setDesktopOnly] = useState(false);

	useEffect(() => {
		setDesktopOnly(!isMobile({ tablet: true, featureDetect: true }));
	}, [])
	return isDesktop;
}