import { theme } from './../../../../theme/theme';

// Previews
import bluePreview_1 from '../../../../assets/img/jobStory/themes/preview/14.svg';
import bluePreview_2 from '../../../../assets/img/jobStory/themes/preview/10.svg';
import bluePreview_3 from '../../../../assets/img/jobStory/themes/preview/11.svg';
import bluePreview_4 from '../../../../assets/img/jobStory/themes/preview/12.svg';
import bluePreview_5 from '../../../../assets/img/jobStory/themes/preview/13.svg';


import greenPreview_1 from '../../../../assets/img/jobStory/themes/preview/44.svg';
import greenPreview_2 from '../../../../assets/img/jobStory/themes/preview/40.svg';
import greenPreview_3 from '../../../../assets/img/jobStory/themes/preview/41.svg';
import greenPreview_4 from '../../../../assets/img/jobStory/themes/preview/42.svg';
import greenPreview_5 from '../../../../assets/img/jobStory/themes/preview/43.svg';

import redPreview_1 from '../../../../assets/img/jobStory/themes/preview/34.svg';
import redPreview_2 from '../../../../assets/img/jobStory/themes/preview/30.svg';
import redPreview_3 from '../../../../assets/img/jobStory/themes/preview/31.svg';
import redPreview_4 from '../../../../assets/img/jobStory/themes/preview/32.svg';
import redPreview_5 from '../../../../assets/img/jobStory/themes/preview/33.svg';


import yellowPreview_1 from '../../../../assets/img/jobStory/themes/preview/24.svg';
import yellowPreview_2 from '../../../../assets/img/jobStory/themes/preview/20.svg';
import yellowPreview_3 from '../../../../assets/img/jobStory/themes/preview/21.svg';
import yellowPreview_4 from '../../../../assets/img/jobStory/themes/preview/22.svg';
import yellowPreview_5 from '../../../../assets/img/jobStory/themes/preview/23.svg';

// Blue Color
import blueOfficial_1 from '../../../../assets/img/jobStory/themes/imgs/m-20.svg';
import blueOfficial_2 from '../../../../assets/img/jobStory/themes/imgs/m-21.svg';
import blueOfficial_3 from '../../../../assets/img/jobStory/themes/imgs/m-22.svg';
import blueOfficial_4 from '../../../../assets/img/jobStory/themes/imgs/m-23.svg';
import blueOfficial_5 from '../../../../assets/img/jobStory/themes/imgs/m-24.svg';

import bluePassionate_1 from '../../../../assets/img/jobStory/themes/imgs/p-20.svg';
import bluePassionate_2 from '../../../../assets/img/jobStory/themes/imgs/p-21.svg';
import bluePassionate_3 from '../../../../assets/img/jobStory/themes/imgs/p-22.svg';
import bluePassionate_4 from '../../../../assets/img/jobStory/themes/imgs/p-23.svg';
import bluePassionate_5 from '../../../../assets/img/jobStory/themes/imgs/p-24.svg';

import blueCurious_1 from '../../../../assets/img/jobStory/themes/imgs/c-20.svg';
import blueCurious_2 from '../../../../assets/img/jobStory/themes/imgs/c-21.svg';
import blueCurious_3 from '../../../../assets/img/jobStory/themes/imgs/c-22.svg';
import blueCurious_4 from '../../../../assets/img/jobStory/themes/imgs/c-23.svg';
import blueCurious_5 from '../../../../assets/img/jobStory/themes/imgs/c-24.svg';

import blueSuperman_1 from '../../../../assets/img/jobStory/themes/imgs/v-20.svg';
import blueSuperman_2 from '../../../../assets/img/jobStory/themes/imgs/v-21.svg';
import blueSuperman_3 from '../../../../assets/img/jobStory/themes/imgs/v-22.svg';
import blueSuperman_4 from '../../../../assets/img/jobStory/themes/imgs/v-23.svg';
import blueSuperman_5 from '../../../../assets/img/jobStory/themes/imgs/v-24.svg';

import blueVisionary_1 from '../../../../assets/img/jobStory/themes/imgs/s-20.svg';
import blueVisionary_2 from '../../../../assets/img/jobStory/themes/imgs/s-21.svg';
import blueVisionary_3 from '../../../../assets/img/jobStory/themes/imgs/s-22.svg';
import blueVisionary_4 from '../../../../assets/img/jobStory/themes/imgs/s-23.svg';
import blueVisionary_5 from '../../../../assets/img/jobStory/themes/imgs/s-24.svg';

// Green Color
import greenOfficial_1 from '../../../../assets/img/jobStory/themes/imgs/m-30.svg';
import greenOfficial_2 from '../../../../assets/img/jobStory/themes/imgs/m-31.svg';
import greenOfficial_3 from '../../../../assets/img/jobStory/themes/imgs/m-32.svg';
import greenOfficial_4 from '../../../../assets/img/jobStory/themes/imgs/m-33.svg';
import greenOfficial_5 from '../../../../assets/img/jobStory/themes/imgs/m-34.svg';

import greenPassionate_1 from '../../../../assets/img/jobStory/themes/imgs/p-30.svg';
import greenPassionate_2 from '../../../../assets/img/jobStory/themes/imgs/p-31.svg';
import greenPassionate_3 from '../../../../assets/img/jobStory/themes/imgs/p-32.svg';
import greenPassionate_4 from '../../../../assets/img/jobStory/themes/imgs/p-33.svg';
import greenPassionate_5 from '../../../../assets/img/jobStory/themes/imgs/p-34.svg';

import greenCurious_1 from '../../../../assets/img/jobStory/themes/imgs/c-30.svg';
import greenCurious_2 from '../../../../assets/img/jobStory/themes/imgs/c-31.svg';
import greenCurious_3 from '../../../../assets/img/jobStory/themes/imgs/c-32.svg';
import greenCurious_4 from '../../../../assets/img/jobStory/themes/imgs/c-33.svg';
import greenCurious_5 from '../../../../assets/img/jobStory/themes/imgs/c-34.svg';

import greenSuperman_1 from '../../../../assets/img/jobStory/themes/imgs/v-30.svg';
import greenSuperman_2 from '../../../../assets/img/jobStory/themes/imgs/v-31.svg';
import greenSuperman_3 from '../../../../assets/img/jobStory/themes/imgs/v-32.svg';
import greenSuperman_4 from '../../../../assets/img/jobStory/themes/imgs/v-33.svg';
import greenSuperman_5 from '../../../../assets/img/jobStory/themes/imgs/v-34.svg';

import greenVisionary_1 from '../../../../assets/img/jobStory/themes/imgs/s-30.svg';
import greenVisionary_2 from '../../../../assets/img/jobStory/themes/imgs/s-31.svg';
import greenVisionary_3 from '../../../../assets/img/jobStory/themes/imgs/s-32.svg';
import greenVisionary_4 from '../../../../assets/img/jobStory/themes/imgs/s-33.svg';
import greenVisionary_5 from '../../../../assets/img/jobStory/themes/imgs/s-34.svg';

// Red Color
import redOfficial_1 from '../../../../assets/img/jobStory/themes/imgs/m-40.svg';
import redOfficial_2 from '../../../../assets/img/jobStory/themes/imgs/m-41.svg';
import redOfficial_3 from '../../../../assets/img/jobStory/themes/imgs/m-42.svg';
import redOfficial_4 from '../../../../assets/img/jobStory/themes/imgs/m-43.svg';
import redOfficial_5 from '../../../../assets/img/jobStory/themes/imgs/m-44.svg';

import redPassionate_1 from '../../../../assets/img/jobStory/themes/imgs/p-40.svg';
import redPassionate_2 from '../../../../assets/img/jobStory/themes/imgs/p-41.svg';
import redPassionate_3 from '../../../../assets/img/jobStory/themes/imgs/p-42.svg';
import redPassionate_4 from '../../../../assets/img/jobStory/themes/imgs/p-43.svg';
import redPassionate_5 from '../../../../assets/img/jobStory/themes/imgs/p-44.svg';

import redCurious_1 from '../../../../assets/img/jobStory/themes/imgs/c-40.svg';
import redCurious_2 from '../../../../assets/img/jobStory/themes/imgs/c-41.svg';
import redCurious_3 from '../../../../assets/img/jobStory/themes/imgs/c-42.svg';
import redCurious_4 from '../../../../assets/img/jobStory/themes/imgs/c-43.svg';
import redCurious_5 from '../../../../assets/img/jobStory/themes/imgs/c-44.svg';

import redSuperman_1 from '../../../../assets/img/jobStory/themes/imgs/v-40.svg';
import redSuperman_2 from '../../../../assets/img/jobStory/themes/imgs/v-41.svg';
import redSuperman_3 from '../../../../assets/img/jobStory/themes/imgs/v-42.svg';
import redSuperman_4 from '../../../../assets/img/jobStory/themes/imgs/v-43.svg';
import redSuperman_5 from '../../../../assets/img/jobStory/themes/imgs/v-44.svg';

import redVisionary_1 from '../../../../assets/img/jobStory/themes/imgs/s-40.svg';
import redVisionary_2 from '../../../../assets/img/jobStory/themes/imgs/s-41.svg';
import redVisionary_3 from '../../../../assets/img/jobStory/themes/imgs/s-42.svg';
import redVisionary_4 from '../../../../assets/img/jobStory/themes/imgs/s-43.svg';
import redVisionary_5 from '../../../../assets/img/jobStory/themes/imgs/s-44.svg';

// Yellow Color
import yellowOfficial_1 from '../../../../assets/img/jobStory/themes/imgs/m-10.svg';
import yellowOfficial_2 from '../../../../assets/img/jobStory/themes/imgs/m-11.svg';
import yellowOfficial_3 from '../../../../assets/img/jobStory/themes/imgs/m-12.svg';
import yellowOfficial_4 from '../../../../assets/img/jobStory/themes/imgs/m-13.svg';
import yellowOfficial_5 from '../../../../assets/img/jobStory/themes/imgs/m-14.svg';

import yellowPassionate_1 from '../../../../assets/img/jobStory/themes/imgs/p-10.svg';
import yellowPassionate_2 from '../../../../assets/img/jobStory/themes/imgs/p-11.svg';
import yellowPassionate_3 from '../../../../assets/img/jobStory/themes/imgs/p-12.svg';
import yellowPassionate_4 from '../../../../assets/img/jobStory/themes/imgs/p-13.svg';
import yellowPassionate_5 from '../../../../assets/img/jobStory/themes/imgs/p-14.svg';

import yellowCurious_1 from '../../../../assets/img/jobStory/themes/imgs/c-10.svg';
import yellowCurious_2 from '../../../../assets/img/jobStory/themes/imgs/c-11.svg';
import yellowCurious_3 from '../../../../assets/img/jobStory/themes/imgs/c-12.svg';
import yellowCurious_4 from '../../../../assets/img/jobStory/themes/imgs/c-13.svg';
import yellowCurious_5 from '../../../../assets/img/jobStory/themes/imgs/c-14.svg';

import yellowSuperman_1 from '../../../../assets/img/jobStory/themes/imgs/v-10.svg';
import yellowSuperman_2 from '../../../../assets/img/jobStory/themes/imgs/v-11.svg';
import yellowSuperman_3 from '../../../../assets/img/jobStory/themes/imgs/v-12.svg';
import yellowSuperman_4 from '../../../../assets/img/jobStory/themes/imgs/v-13.svg';
import yellowSuperman_5 from '../../../../assets/img/jobStory/themes/imgs/v-14.svg';

import yellowVisionary_1 from '../../../../assets/img/jobStory/themes/imgs/s-10.svg';
import yellowVisionary_2 from '../../../../assets/img/jobStory/themes/imgs/s-11.svg';
import yellowVisionary_3 from '../../../../assets/img/jobStory/themes/imgs/s-12.svg';
import yellowVisionary_4 from '../../../../assets/img/jobStory/themes/imgs/s-13.svg';
import yellowVisionary_5 from '../../../../assets/img/jobStory/themes/imgs/s-14.svg';






export const jobStoryThemes = [
  {
    hex: theme.colors.lightBlue,
    title: 'Light Blue',
    themeItems: [
      {
        id: 'bluePassionate',
        title: 'Passionate',
        images: {
          themePreview: bluePreview_2,
          mainBanner: bluePassionate_1,
          benefitsBanner: bluePassionate_2,
          forCompanyBanner: bluePassionate_3,
          descriptionBanner: bluePassionate_4,
          skillsBanner: bluePassionate_5
        },
      },
      {
        id: 'blueOfficial',
        title: 'Official',
        images: {
          themePreview: bluePreview_1,
          mainBanner: blueOfficial_1,
          benefitsBanner: blueOfficial_2,
          forCompanyBanner: blueOfficial_3,
          descriptionBanner: blueOfficial_4,
          skillsBanner: blueOfficial_5
        },
      },
      {
        id: 'blueCurious',
        title: 'Curious',
        images: {
          themePreview: bluePreview_3,
          mainBanner: blueCurious_1,
          benefitsBanner: blueCurious_2,
          forCompanyBanner: blueCurious_3,
          descriptionBanner: blueCurious_4,
          skillsBanner: blueCurious_5
        },
      },
      {
        id: 'blueSuperman',
        title: 'Superman',
        images: {
          themePreview: bluePreview_4,
          mainBanner: blueSuperman_1,
          benefitsBanner: blueSuperman_2,
          forCompanyBanner: blueSuperman_3,
          descriptionBanner: blueSuperman_4,
          skillsBanner: blueSuperman_5
        },
      },
      {
        id: 'blueVisionary',
        title: 'Visionary',
        images: {
          themePreview: bluePreview_5,
          mainBanner: blueVisionary_1,
          benefitsBanner: blueVisionary_2,
          forCompanyBanner: blueVisionary_3,
          descriptionBanner: blueVisionary_4,
          skillsBanner: blueVisionary_5
        },
      },
    ]
  },
  {
    hex: theme.colors.lightGreen,
    title: 'Light Green',
    themeItems: [
      {
        id: 'greenPassionate',
        title: 'Passionate',
        images: {
          themePreview: greenPreview_2,
          mainBanner: greenPassionate_1,
          benefitsBanner: greenPassionate_2,
          forCompanyBanner: greenPassionate_3,
          descriptionBanner: greenPassionate_4,
          skillsBanner: greenPassionate_5
        },
      },
      {
        id: 'greenOfficial',
        title: 'Official',
        images: {
          themePreview: greenPreview_1,
          mainBanner: greenOfficial_1,
          benefitsBanner: greenOfficial_2,
          forCompanyBanner: greenOfficial_3,
          descriptionBanner: greenOfficial_4,
          skillsBanner: greenOfficial_5
        },
      },
      {
        id: 'greenCurious',
        title: 'Curious',
        images: {
          themePreview: greenPreview_3,
          mainBanner: greenCurious_1,
          benefitsBanner: greenCurious_2,
          forCompanyBanner: greenCurious_3,
          descriptionBanner: greenCurious_4,
          skillsBanner: greenCurious_5
        },
      },
      {
        id: 'greenSuperman',
        title: 'Superman',
        images: {
          themePreview: greenPreview_4,
          mainBanner: greenSuperman_1,
          benefitsBanner: greenSuperman_2,
          forCompanyBanner: greenSuperman_3,
          descriptionBanner: greenSuperman_4,
          skillsBanner: greenSuperman_5
        },
      },
      {
        id: 'greenVisionary',
        title: 'Visionary',
        images: {
          themePreview: greenPreview_5,
          mainBanner: greenVisionary_1,
          benefitsBanner: greenVisionary_2,
          forCompanyBanner: greenVisionary_3,
          descriptionBanner: greenVisionary_4,
          skillsBanner: greenVisionary_5
        },
      },
    ]
  },
  {
    hex: theme.colors.lightRed,
    title: 'Light Red',
    themeItems: [
      {
        id: 'redPassionate',
        title: 'Passionate',
        images: {
          themePreview: redPreview_2,
          mainBanner: redPassionate_1,
          benefitsBanner: redPassionate_2,
          forCompanyBanner: redPassionate_3,
          descriptionBanner: redPassionate_4,
          skillsBanner: redPassionate_5
        },
      },
      {
        id: 'redOfficial',
        title: 'Official',
        images: {
          themePreview: redPreview_1,
          mainBanner: redOfficial_1,
          benefitsBanner: redOfficial_2,
          forCompanyBanner: redOfficial_3,
          descriptionBanner: redOfficial_4,
          skillsBanner: redOfficial_5
        },
      },
      {
        id: 'redCurious',
        title: 'Curious',
        images: {
          themePreview: redPreview_3,
          mainBanner: redCurious_1,
          benefitsBanner: redCurious_2,
          forCompanyBanner: redCurious_3,
          descriptionBanner: redCurious_4,
          skillsBanner: redCurious_5
        },
      },
      {
        id: 'redSuperman',
        title: 'Superman',
        images: {
          themePreview: redPreview_4,
          mainBanner: redSuperman_1,
          benefitsBanner: redSuperman_2,
          forCompanyBanner: redSuperman_3,
          descriptionBanner: redSuperman_4,
          skillsBanner: redSuperman_5
        },
      },
      {
        id: 'redVisionary',
        title: 'Visionary',
        images: {
          themePreview: redPreview_5,
          mainBanner: redVisionary_1,
          benefitsBanner: redVisionary_2,
          forCompanyBanner: redVisionary_3,
          descriptionBanner: redVisionary_4,
          skillsBanner: redVisionary_5
        },
      },
    ]
  },
  {
    hex: theme.colors.lightYellow,
    title: 'Light Yellow',
    themeItems: [
      {
        id: 'yellowPassionate',
        title: 'Passionate',
        images: {
          themePreview: yellowPreview_2,
          mainBanner: yellowPassionate_1,
          benefitsBanner: yellowPassionate_2,
          forCompanyBanner: yellowPassionate_3,
          descriptionBanner: yellowPassionate_4,
          skillsBanner: yellowPassionate_5
        },
      },
      {
        id: 'yellowOfficial',
        title: 'Official',
        images: {
          themePreview: yellowPreview_1,
          mainBanner: yellowOfficial_1,
          benefitsBanner: yellowOfficial_2,
          forCompanyBanner: yellowOfficial_3,
          descriptionBanner: yellowOfficial_4,
          skillsBanner: yellowOfficial_5
        },
      },
      {
        id: 'yellowCurious',
        title: 'Curious',
        images: {
          themePreview: yellowPreview_3,
          mainBanner: yellowCurious_1,
          benefitsBanner: yellowCurious_2,
          forCompanyBanner: yellowCurious_3,
          descriptionBanner: yellowCurious_4,
          skillsBanner: yellowCurious_5
        },
      },
      {
        id: 'yellowSuperman',
        title: 'Superman',
        images: {
          themePreview: yellowPreview_4,
          mainBanner: yellowSuperman_1,
          benefitsBanner: yellowSuperman_2,
          forCompanyBanner: yellowSuperman_3,
          descriptionBanner: yellowSuperman_4,
          skillsBanner: yellowSuperman_5
        },
      },
      {
        id: 'yellowVisionary',
        title: 'Visionary',
        images: {
          themePreview: yellowPreview_5,
          mainBanner: yellowVisionary_1,
          benefitsBanner: yellowVisionary_2,
          forCompanyBanner: yellowVisionary_3,
          descriptionBanner: yellowVisionary_4,
          skillsBanner: yellowVisionary_5
        },
      },
    ]
  }
]

export const findJobTheme = (themeId: string) => {
  const filterResult = jobStoryThemes.findIndex(colorItem => colorItem.themeItems.find(themeItem => themeItem.id === themeId));
  return filterResult
}