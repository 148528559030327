import { SPSecondaryButton } from "../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import {
  CloseButton,
  VerificationModalWrapper,
  Title,
  ButtonWrap,
  VerificationModalHeader,
  ModalContent,
  Subtitle,
  ModalDescription,
  ButtonSubtitle
} from "./VerificationBanner.presents";
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';
import { ReactComponent as MailIcon } from '../../../../assets/img/emailOrange.svg';
import { useState } from "react";
import { useRootStore } from "../../../../state/stores/storeContext";
import { sendEmailValidation } from "../../../../services/api/candidates/candidates.domains";
import { v4 as uuid } from 'uuid';

export const VerificationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const { candidateStore } = useRootStore();

  const onSendEmail = async () => {
    const verification_id = uuid()
    let url = `${window.location.host}/candidate/verify?verification_id=${verification_id}`
    if (window.location.hostname === 'localhost') {
      url = `staging-app.sidepost.com/candidate/verify?verification_id=${verification_id}`
    }

    setIsLoading(true);
    await candidateStore.setVerficationID(verification_id);
    await sendEmailValidation({
      email: candidateStore.email,
      url:  url
    });
    console.log("sendEmailValidation",url)
    setIsMessageSent(true);
    setIsLoading(false);
  }

  return <VerificationModalWrapper centered show={isOpen} onHide={onClose} >
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
    <VerificationModalWrapper.Body>
      <VerificationModalHeader>
        <Title>Email Verification</Title>
      </VerificationModalHeader>
      <ModalContent>
        <MailIcon />
        <Subtitle>Please verify your email</Subtitle>
        <ModalDescription>
          {`In order to share your resume please verify your email. 
        An account verification message will be sent to your email address `}
          <span>{candidateStore.email}</span>.
        </ModalDescription>
        <ButtonWrap>
          {isMessageSent && <ButtonSubtitle>
            Can’t find the email?
          </ButtonSubtitle>}
          <SPSecondaryButton
            width="100%"
            type="submit"
            disabled={isLoading}
            isLight={isLoading}
            onClick={onSendEmail}
            color={"#FFF"}
          >
            {isMessageSent ? "Resend Email" : "Send Email"}
          </SPSecondaryButton>
        </ButtonWrap>
      </ModalContent>

    </VerificationModalWrapper.Body>
  </VerificationModalWrapper>
};
