import { FieldProps } from "formik";
import { FieldWrap } from "./ExpertiseSubField.presents";
import { ExpertiseSubFieldProps } from "./ExpertiseSubField.props";
import { Option } from "../../../../../../common/Select/Select.props";

export const ExpertiseSubField: React.SFC<ExpertiseSubFieldProps & FieldProps> = (props) => {
  const {
    isDisabled,
    isPanel,
    labelText,
    fieldIndex,
    index,
    inputType,
    fieldValue,
    onChange,
    form,
    isAnyValue
  } = props;
  const expertiseAreaSkills = form.values.expertiseAreas[fieldIndex].skills;
  const isChecked = expertiseAreaSkills[index] === fieldValue || (expertiseAreaSkills && expertiseAreaSkills.includes(fieldValue));

  const onChangeHandler = (event: any) => {
    const maxLength = 6;
    const isValueLengthLimited = inputType === 'radio' || (expertiseAreaSkills.length < maxLength || isChecked);
    if (isValueLengthLimited || isAnyValue) {
      onChange(event)
    }
  }
  return <FieldWrap isPanel={!!isPanel}>
    <input
      disabled={isDisabled}
      type={inputType ? inputType : 'checkbox'}
      id={`expertiseAreas${fieldIndex}skills`}
      name={`expertiseAreas.${fieldIndex}.skills`}
      value={fieldValue}
      onChange={(event) => onChangeHandler(event)}
      checked={isChecked}
    />
    <label>
      {labelText}
      <span></span>
    </label>
  </FieldWrap >
}