import { theme } from './../../../theme/theme';
import { colors } from './../../../constants/colors';
import styled from 'styled-components';

export const StyleForWrap = styled('div') <{ isDesktop: boolean, isLoading: boolean }>`
  background: ${(props) => props.isDesktop ? '#FFFFFF' : 'none'};
  box-shadow: ${(props) => props.isDesktop ? '6px 6px 20px rgb(0 0 0 / 2%)' : 'none'};
  border-radius: 20px;
  margin: ${(props) => props.isDesktop ? `${theme.offsets.xxl} 100px ${theme.offsets.xxl} ${theme.offsets.xxl}` : '0'}; 
  padding:${(props) => props.isDesktop ? `${theme.offsets.extraBig} ${theme.offsets.xxl} ${theme.offsets.xxl}` : '0'};
  min-height: 83%;
  position: relative;
  max-width: ${(props) => props.isDesktop ? 'auto' : '100vw'}; 
`

export const StyleForTitle = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  .title{
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: ${colors.dark};
    ${(props) => !props.isDesktop && `
    font-size: ${theme.fontSizes.xl};
  `}
  }
  ${(props) => !props.isDesktop && `
  background-color: ${theme.colors.white};
  padding:${theme.offsets.md};
  `};
`

export const NewReviewers = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.white};
  background-color: ${theme.colors.red};
  border-radius: 100%;
  width: ${theme.offsets.xl};
  height: ${theme.offsets.xl};
  margin-left: ${theme.offsets.md};
  padding-top: 1px;
  line-height: 18px;
  @media(max-width: ${theme.screens.mobile}) { 
    width: ${theme.offsets.lg};
    height: ${theme.offsets.lg};
    line-height: ${theme.offsets.lg};
    }
`

export const EmptyListBannerWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const BannerText = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  text-align: center;
  color: ${theme.colors.black};
  margin: ${theme.offsets.xxl} 0;
`