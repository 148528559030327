import { SocialNetworksAuthMap } from './helper';
import { Wrapper } from './SocialNetworks.presents';
import { SocialNetworksItem } from './SocialNetworksItem';

export const SocialNetworksAuth = () => {

  return <Wrapper>
    {SocialNetworksAuthMap.map((item, index) => <SocialNetworksItem
      key={`socNetwork${index}`}
      title={item.title}
      icon={item.icon}
      callback={item.callback} />
    )}
  </Wrapper>
}