import { Field, Formik } from "formik";
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { Input } from "../../../../../common/Input";
import { SocialNetworksAuth } from "../../../BasicComponents/SocialNetworks/SocialNetworksAuth";
import { Header } from "../../BaseComponents/Header/Header";
import { ErrorText, Separator, Title, Wrapper, ContentWrap, Subtitle, PolicyText, HeaderWrap, SignUpMobileLink } from "./PasswordStep.presents";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import useGetDevice from "../../../../../../hooks/useGetDevice";
import { passwordValidation } from "../../../../../../services/validationSchemas";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import { useHistory } from "react-router-dom";
import { sendEmailValidation, sendWelcomeMessage } from "../../../../../../services/api/candidates/candidates.domains";
import { v4 as uuid } from "uuid";
import { useState } from "react";

export const PasswordStep = observer(() => {
  const { t } = useTranslation();
  const isDesktop = useGetDevice();
  const { candidateStore } = useRootStore();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const setPassword = async (values: { password: '' }) => {
    setIsLoading(true);
    const verification_id = uuid()
    let urlWelcome = `${window.location.host}/candidate/resume-builder`
    let urlVerification = `${window.location.host}/candidate/verify?verification_id=${verification_id}`
    if (window.location.hostname === 'localhost') {
      urlWelcome = `staging-app.sidepost.com/candidate/resume-builder`
      urlVerification = `staging-app.sidepost.com/candidate/verify?verification_id=${verification_id}`
    }
    await candidateStore.setPassword(values.password);
    sendWelcomeMessage({
      email: candidateStore.email,
      url: urlWelcome
    });
    await sendEmailValidation({
      email: candidateStore.email,
      url: urlVerification
    });
    history.push('/candidate/welcome')
    setIsLoading(false);
  }
  return <Wrapper>
    <HeaderWrap isDesktop={isDesktop}>
      <Header isLogoBlack />
    </HeaderWrap>
    <ContentWrap>
      <Title>Create an account</Title>
      <Subtitle>
        Add your password to create an account
      </Subtitle>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ password: '' }}
        validationSchema={Yup.object().shape({
          password: passwordValidation(t)
        })}
        onSubmit={setPassword}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <Field
              as={Input}
              labelText={'Password'}
              type={'password'}
              name={'password'}
              value={values.password}
              onChange={handleChange}
              error={!!errors.password}
            />
            <ErrorText>{errors.password}</ErrorText>

            <SPPrimaryButton
              isLoading={isLoading}
              width={'100%'}
              type="submit">
              Create an account
            </SPPrimaryButton>

          </form>
        )}
      </Formik>

      <Separator>Or</Separator>
      <SocialNetworksAuth />
      <PolicyText>
        By creating account, I agree to Sidepost's
        <a href='https://sidepost.com/terms-of-service' target="_blank" >
          {` Terms of Service `}
        </a>
        and
        <a href='https://sidepost.com/privacy-policy' target="_blank">
          {` Privacy Policy `}
        </a>
      </PolicyText>
    </ContentWrap>
    {!isDesktop && <SignUpMobileLink>Already have an account?
      <a href={"/candidate/sign-in"}>Sign In</a>
    </SignUpMobileLink>}
  </Wrapper>
})