import { useEffect, useState } from 'react';
import { useRootStore } from '../../../../state/stores/storeContext';
import { Wrapper, Title, List, MenuItem, ArrowIconWrap } from './PublicVisibility.presents';
import { ReactComponent as VisibleIcon } from '../../../../assets/img/eyeOpen.svg';
import { ReactComponent as InvisibleIcon } from '../../../../assets/img/eyeClose.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/img/arrow.svg';
import { observer } from "mobx-react-lite";
import {HelperTextTooltipWrapper} from "../../../common/HelperText/HelperTextTooltipWrapper";

export const PublicVisibility = observer(({
  selectorId,
                                            isDesktop
}:{
  selectorId?: string;
  isDesktop: boolean

}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isListOpen, setIsListOpen] = useState(false);
  const [title,setTitle] = useState("")

  const onChangeVisibility = (value: boolean) => {
    resumeBuilderStore.saveIsResumeVisible({ isResumeVisible: value }, candidateStore.uid);
  }

  useEffect(() => {
    setTitle(resumeBuilderStore.isResumeVisible?"Publicly Accessible":"Publicly Inaccessible")
  }, [resumeBuilderStore.isResumeVisible]);

  return <Wrapper
    id={selectorId}
    onClick={() => setIsListOpen(!isListOpen)}
    onBlur={() => setIsListOpen(false)}>
    {isDesktop && <>
      {resumeBuilderStore.isResumeVisible
          ? <VisibleIcon/>
          : <InvisibleIcon/>
      }</>
    }
    <Title>{title}</Title>
    <ArrowIconWrap isListOpen={isListOpen}>
      <ArrowIcon />
    </ArrowIconWrap>
    {candidateStore.isTutorialFinished && <HelperTextTooltipWrapper left={!isDesktop} autoChangePosition text={"In this section, you can make your public resume visible or invisible to viewers who are not registered, or not singed into their accounts."} title={"Show or Hide Your Resume"} />}
    {isListOpen && <List>
      <MenuItem isActive={!!resumeBuilderStore.isResumeVisible} onClick={() => onChangeVisibility(true)}>Accessible</MenuItem>
      <MenuItem isActive={!resumeBuilderStore.isResumeVisible} isWarning onClick={() => onChangeVisibility(false)}>Inaccessible</MenuItem>
    </List>}
  </Wrapper>
});
