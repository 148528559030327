import { StyleForToggle } from "./Toggle.presents"
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../state/stores/storeContext";

export const Toggle = observer(() => {
  const { resumeBuilderStore } = useRootStore();
  return <StyleForToggle>
    <input type="checkbox" id="cbx" checked={resumeBuilderStore.isResumeOpen} onChange={() => resumeBuilderStore.showResumePreview(!resumeBuilderStore.isResumeOpen)}/>
    <label htmlFor={"cbx"} className="toggle"><span></span></label>
  </StyleForToggle>
})
