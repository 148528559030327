import { ModalWindow } from "../../../common/Modal";
import { ApplyProps } from "./Modals.props";
import closeIcon from '../../../../assets/img/close.svg';
import {
  CloseBtnBlock,
  ApplyForm,
  PhoneField,
  SocialRow,
  VisaTitle,
  VisaBlock,
  VisaLabel,
  VisaField,
  Checkmark,
  LogoWrap,
  Logo,
  OrganizationName,
  JobTitle,
  InputField,
  PhoneFieldWrap,
  VisaWrap,
  PolicyText,
  PolicyLink,
  ResumeBtn,
  OptionalLabel,
  OptionalText,
  ErrorText,
  PreloaderWrap
} from "./Modals.presents";
import { Field, Formik } from "formik";
import { Input } from "../../../common/Input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useRootStore } from "../../../../state/stores/storeContext";
import { applyForAJob } from "../../../../services/api/jobStore/jobStore.domains";
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup';
import { PrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton.presets";
import { Preloader } from "../../../common/Preloader";


export const ApplyModal = (props: ApplyProps) => {
  const { t } = useTranslation();
  const { jobStoryBuilder } = useRootStore();

  const [inputClass, setInputClass] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isFirstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const phoneHandler = (value: string) => {
    setPhoneNumber(value);
    const invalidPhone = isFirstSubmitComplete && value && !isValidPhoneNumber(value);
    setInputClass(invalidPhone ? 'error' : '')
  }

  const jobData = jobStoryBuilder.answers;
  const shouldBeResume = jobData.specificationStep.needResume && jobData.specificationStep.needResume !== 'no';
  const needResume = jobData.specificationStep.needResume && jobData.specificationStep.needResume === 'yes';
  const logo = jobData.contactStep.directEmployerLogo.fileUrl;


  return <ModalWindow
    openWindow={props.openWindow}
    closeWindow={props.closeWindow}
    empty
    fullscreen>
    <CloseBtnBlock onClick={() => props.closeWindow(false)}>
      <img src={closeIcon} alt="close" />
      <span>Close</span>
    </CloseBtnBlock>
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', website: '', linkedIn: '', hasVisa: '', resume: '', message: '' }}
      validateOnChange={isFirstSubmitComplete}
      validateOnBlur={false}
      validate={values => {
        setFirstSubmitComplete(true);
        const errors: any = {}
        setInputClass(!isValidPhoneNumber(phoneNumber) ? 'error' : '')
        if (needResume && !uploadedFile) {
          errors.resume = 'need resume';
        }
        return errors;
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .min(2, t('validation.min', { characters: 2 }))
          .max(50, t('validation.max', { characters: 50 }))
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
          .required('Required'),
        lastName: Yup.string()
          .min(2, t('validation.min', { characters: 2 }))
          .max(50, t('validation.max', { characters: 50 }))
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
          .required(t('validation.required')),
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
        hasVisa: Yup.string().required('Required'),
        message: Yup.string().max(1000, t('validation.max')),
        website: Yup.string().max(100, t('validation.max')),
        linkedIn: Yup.string().max(100, t('validation.max'))
      })}
      onSubmit={async (values) => {
        setLoading(true);
        const dto = {
          ...values,
          phone: phoneNumber,
          jobId: props.jobId
        };
        const response = await applyForAJob(uploadedFile, dto);
        //TODO: need design for Error result
        //!response.data.err && props.closeWindow(false);
        props.closeWindow(false);
        setLoading(false);
      }
      }
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <Container fluid>
          <ApplyForm onSubmit={handleSubmit}>
            <LogoWrap>
              {logo && <Logo src={logo} alt="logo" />}
              <div>
                <JobTitle>{jobData.descriptionStep.jobTitle}</JobTitle>
                <OrganizationName>{jobData.contactStep.organizationName}</OrganizationName>
              </div>
            </LogoWrap>
            <InputField
              as={Input}
              name={'firstName'}
              type={'text'}
              value={values.firstName}
              onChange={handleChange}
              labelText={t('label.firstName')}
              error={errors.firstName}
            />
            <ErrorText>{errors.firstName}</ErrorText>
            <InputField
              as={Input}
              name={'lastName'}
              type={'text'}
              value={values.lastName}
              onChange={handleChange}
              labelText={t('label.lastName')}
              error={errors.lastName}
            />
            <ErrorText>{errors.lastName}</ErrorText>
            <InputField
              as={Input}
              name={'email'}
              type={'text'}
              value={values.email}
              onChange={handleChange}
              labelText={t('label.candidateEmail')}
              error={errors.email}
            />
            <ErrorText>{errors.email}</ErrorText>
            <PhoneFieldWrap
              as={PhoneField}
              className={inputClass}
              name={'phone'}
              value={phoneNumber}
              onChange={phoneHandler}
              international
              defaultCountry="US"
              m={'0'}
            />
            <SocialRow>
              <Col md={6}>
                <Field
                  as={Input}
                  name={'website'}
                  type={'text'}
                  value={values.website}
                  onChange={handleChange}
                  labelText={<OptionalLabel>
                    <span>Website</span>
                    <OptionalText>Optional</OptionalText>
                  </OptionalLabel>}
                  error={errors.website}
                />
                <ErrorText>{errors.website}</ErrorText>
              </Col>
              <Col md={6}>
                <Field
                  as={Input}
                  name={'linkedIn'}
                  type={'text'}
                  value={values.linkedIn}
                  onChange={handleChange}
                  labelText={<OptionalLabel>
                    <span>LinkedIn</span>
                    <OptionalText>Optional</OptionalText>
                  </OptionalLabel>}
                  error={errors.linkedIn}
                />
                <ErrorText>{errors.linkedIn}</ErrorText>
              </Col>
            </SocialRow>
            <InputField
              as={Input}
              name={'message'}
              type={'textarea'}
              value={values.message}
              onChange={handleChange}
              maxLength={1000}
              error={errors.message}
              labelText={<OptionalLabel>
                <span>Message</span>
                <OptionalText>Up to 1000 characters</OptionalText>
              </OptionalLabel>}
            />
            <ErrorText>{errors.message}</ErrorText>
            <VisaWrap>
              <VisaTitle>Work authorization</VisaTitle>
              <VisaBlock>
                <VisaLabel>
                  <span>Yes, I’m currently eligible to work in the country to which I’m applying</span>
                  <VisaField as={'input'} type="radio" name='hasVisa' value={true} onChange={handleChange} />
                  <Checkmark error={errors.hasVisa}></Checkmark>
                </VisaLabel>
              </VisaBlock>
              <VisaBlock>
                <VisaLabel>
                  <span>No, I need visa and work permit assistance to work in the country to which I'm applying</span>
                  <VisaField as={'input'} type="radio" name='hasVisa' value={false} onChange={handleChange} />
                  <Checkmark error={errors.hasVisa}></Checkmark>
                </VisaLabel>
              </VisaBlock>
            </VisaWrap>
            {shouldBeResume && <ResumeBtn error={errors.resume} uploadedFile={uploadedFile ? uploadedFile.name : null} as={'input'} type='file' name='resume' onChange={(evant: any) => setUploadedFile(evant.target.files[0])} />}
            <PolicyText>
              By applying, I agree to Sidepost's <PolicyLink to={{ pathname:'https://sidepost.com/terms-of-service'}} target="_blank">Terms of Service</PolicyLink> and <PolicyLink to={{ pathname:'https://sidepost.com/privacy-policy' }} target="_blank">Privacy Policy</PolicyLink>
            </PolicyText>
            <PrimaryButton disabled={isLoading} width={'100%'} type={'submit'}>{isLoading ? <PreloaderWrap><Preloader /></PreloaderWrap> : 'Apply'}</PrimaryButton>
          </ApplyForm>
        </Container>
      )}
    </Formik>
  </ModalWindow>
}