import axios from 'axios';
import { baseURl } from "../../../constants/config";
import { RegisterUserDto } from './employerSignUp.type';

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURl
})

export const addPhone = (dto: { phone: number, token: string, country: string }) => {
  return instance.post('/sign_up/phone', dto);
}

export const resendPhoneCode = (dto: { phone: number, verificationId: string }) => {
  return instance.put('/sign_up/resend_phone_code', dto);
}

export const phoneVerification = (dto: { phoneCode: string, verificationId: string }) => {
  return instance.put('/sign_up/phone_verification', dto);
}

export const addUserMainInfo = (dto: RegisterUserDto) => {
  return instance.put('/sign_up/main_info', dto);
}

export const resendEmailCode = (dto: { verificationId: string, email: string }) => {
  return instance.put('/sign_up/resend_email_code', dto);
}

