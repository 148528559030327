import { Container, Content } from "./Settings.presents";
import { DesktopSidebar } from "../../components/common/Sidebars/AdminSidebar/Desktop";
import { MobileAdminHeader } from "../../components/common/Headers/AdminHeader/Mobile/MobileAdminHeader";
import { DesktopHeader } from "../../components/common/Headers/AdminHeader/Desktop";
import { AdminTabs } from "../../components/common/Tabs/AdminTabs/AdminTabs";
import { Settings } from "../../components/AdminPanel/Settings";
import useGetDevice from "../../hooks/useGetDevice";

export const SettingsPage = () => {
  const isDesktop = useGetDevice();

  return <Container>
    {isDesktop && <DesktopSidebar />}
    <Content>
      {isDesktop ? <DesktopHeader /> : <MobileAdminHeader />}
      <AdminTabs />
      <Settings isDesktop={isDesktop}/>
    </Content>
  </Container>
}