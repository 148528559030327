import * as React from "react"
import Waveform from "../../MediaTrailer/wavesurfer"
import { MediaModalProps } from "./MediaModal.props"
import { Player, Ui, Video, Controls, PlaybackControl } from '@vime/react';
import { useState, useEffect } from "react"
import { MediaModalContainer, MediaModalContent, MediaCounter, MediaModalWrapper, MediaImage, CloseButtonWrapper,
  MediaControlButton, VideoPlayerContainer, AudioPlayerContainer, MediaTitle } from "./MediaModal.presets"
import { SPCloseButton } from "../../../../common/buttons/SPCloseButton/SPCloseButton";
import arrowLeft from "../../../../../assets/img/arrowLeft.svg"
import arrowRight from "../../../../../assets/img/arrowRight.svg"

export const MediaModal: React.FC<MediaModalProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(props.selectedIndex || 0)
  }, [props.selectedIndex])

  const handleNext = () => setSelectedIndex(selectedIndex < (props.mediaFiles.length-1) ? (selectedIndex + 1) : selectedIndex);
  const handlePrev = () => setSelectedIndex(selectedIndex > 0 ?  (selectedIndex-1) : selectedIndex);

  const myRenderItem = (media: any) => {
    if (media.type === "video") {
      return <>
        <VideoPlayerContainer>
          <Player controls>
          <Video cross-origin>
            <source
              data-src={media.fileUrl}
              type="video/mp4"
            />
            </Video>
            <Ui>
              <Controls pin="center">
                <PlaybackControl hideTooltip
                />
              </Controls>
            </Ui>
          </Player>
        </VideoPlayerContainer>
        <MediaTitle>{media.fileTitle || media.filename}</MediaTitle>
      </>
    } else if (media.type === "audio") {
      return <>
          <AudioPlayerContainer>
            <Waveform url={media.fileUrl} />
          </AudioPlayerContainer>
          <MediaTitle>{media.fileTitle || media.filename}</MediaTitle>
        </>
    } else {
      return <MediaImage src={media.url} alt={media.fileTitle || media.filename} />
    }
  }

  const renderCounter = () => {
    const counterText = `${selectedIndex + 1}/${props.mediaFiles.length}`
    return <MediaCounter>{counterText}</MediaCounter>
  }
  
  return (
    <MediaModalWrapper show={props.isOpen} fullscreen={true}>
      <CloseButtonWrapper>
        <SPCloseButton onClick={props.onClose} />
      </CloseButtonWrapper>
      <MediaModalWrapper.Body>  
        <MediaModalContainer>
          <MediaControlButton onClick={handlePrev}>
            <img src={arrowLeft} alt="Previous media" />
          </MediaControlButton>
          <MediaModalContent>
            {renderCounter()}
            {myRenderItem(props.mediaFiles[selectedIndex])}
          </MediaModalContent>
          <MediaControlButton onClick={handleNext}>
            <img src={arrowRight} alt="Next media" />
          </MediaControlButton>
        </MediaModalContainer>

        
      </MediaModalWrapper.Body>
    </MediaModalWrapper>

  )
}
