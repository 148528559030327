import { PropsType } from './Modal.props';
import { StyleForModal } from './Modal.presents';
import closeIcon from '../../../assets/img/close.svg';

export const ModalWindow: React.FC<PropsType> = (props) => {
  return <StyleForModal
    show={props.openWindow}
    onHide={props.closeWindow}
    centered
    width={props.width}
    fullscreen={props.fullscreen}>
    {!props.empty && <img className='close-btn' src={closeIcon} alt="close" onClick={() => props.closeWindow()} />}
    {props.title && <p className='title'>{props.title}</p>}
    {props.children}
  </StyleForModal>
}