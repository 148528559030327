import { useState } from 'react'
import 'react-phone-number-input/style.css'
import { Title } from '../../../../../common/Title'
import 'react-app-polyfill/ie11';
import { Formik } from 'formik';
import { StyleForLabel, StyleForPhoneInput, StyleForForm, DescriptionText, DescriptionLink, HelperText, ButtonWrap, ReCAPTCHAWrap, Wrapper } from './FirstStep.presents';

import { PropsType } from './FirstStep.props'
import { useRootStore } from '../../../../../../state/stores/storeContext';
import { Text } from '../../../../../common/Text';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { useTranslation } from "react-i18next";
import { SPPrimaryButton } from '../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import ReCAPTCHA from "react-google-recaptcha";
import { Center } from '../../../../../common/Wraps/Wraps.presents';
import { recaptcha_site_key } from '../../../../../../constants/config';

export const FirstStep = (props: PropsType) => {
  const { userStore } = useRootStore()
  const [value, setValue] = useState('')
  const [inputClass, setInputClass] = useState('')
  const [serverError, setServerError] = useState('')
  const [isHelperTextOpen, setHelperTextOpen] = useState(false)
  const initialValues = { phone: '' }
  const [phone, setPhone] = useState('')
  const [isHuman, setIsHuman] = useState(false)
  const [showRecaptch, setShowRecaptch] = useState(false)

  const { t } = useTranslation();

  const helperTextHandler = (value: boolean) => setHelperTextOpen(value);

  const onVerify = async (token: any) => {
    setTimeout(() => { setIsHuman(true) }, 2000);
    const country = parsePhoneNumber(phone)?.country;
    const dataFromServer = await userStore.savePhoneNumber({ phone: phone, token: token, country })
    setServerError(dataFromServer.err)
    if (!dataFromServer.err) {
      setInputClass('')
      props.setPhoneNumber(value)
      const generateStep = (data: typeof dataFromServer) => {
        if (!data.isVerPhone) {
          return 2
        } else if (data.isVerPhone && !data.email) {
          return 3
        } else if (data.email && !data.isVerEmail) {
          return 4
        } else if (data.isVerEmail) {
          return 5
        }
      }
      props.setStep(generateStep(dataFromServer.data))
    } else {
      setInputClass('error')
    }
  }

  if (!isHuman && showRecaptch) {
    return (
      <Wrapper>
        <Title text={"Are you a human?"} />
        <ReCAPTCHAWrap>
          <Center>
            <ReCAPTCHA
              sitekey={recaptcha_site_key}
              onChange={onVerify}
            />
          </Center>
        </ReCAPTCHAWrap>
      </Wrapper>)
  }
  
  return <div>
    <Title text={t('signUp.title')} />
    <DescriptionText>
      Please enter your mobile number to sign-up. <DescriptionLink onBlur={() => helperTextHandler(false)} onClick={() => helperTextHandler(true)}>Why? Click here to know.</DescriptionLink>
      {isHelperTextOpen && <HelperText>
        To help protect you from abuse, and maintaining a safe environment for our users,
        we will ask you to prove you're not a robot before you can create an account.
        This extra confirmation by phone helps keep spammers from abusing our systems.
      </HelperText>}
    </DescriptionText>
    <StyleForLabel htmlFor="phone">{t('label.phone')}</StyleForLabel>
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validate={values => {
        const errors: any = {}
        if (!isValidPhoneNumber(value)) {
          setInputClass('error')
          errors.phone = t('validation.phone');
        } else {
          setInputClass('')
        }
        return errors;
      }}
      onSubmit={async () => {
        setPhone(value)
        setShowRecaptch(true)
      }}
    >
      {({
        errors,
        touched,
        handleBlur,
        handleSubmit,
      }) => (
        <StyleForForm onSubmit={handleSubmit}>
          <StyleForPhoneInput
            className={inputClass}
            name={'phone'}
            value={value}
            onChange={setValue}
            international
            defaultCountry="US"
            onBlur={handleBlur}
          />
          <Text text={touched.phone ? errors.phone : ''} error />
          <Text text={serverError} error />
          <ButtonWrap>
            <SPPrimaryButton width={'100%'} type="submit">{t('signUp.continueBtn')}</SPPrimaryButton>
          </ButtonWrap>
        </StyleForForm>
      )}
    </Formik>
  </div >
}