export const HourlyWageIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M35.8394 5H16.1584C9.29989 5 5 9.85611 5 16.7282V35.2718C5 42.1439 9.27719 47 16.1584 47H35.8371C42.7183 47 47 42.1439 47 35.2718V16.7282C47 9.85611 42.7183 5 35.8394 5Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.27575 7.60523C9.41652 5.30852 12.4814 4 16.1584 4H35.8394C39.5259 4 42.5918 5.30809 44.7311 7.60561C46.8629 9.89507 48 13.0856 48 16.7282V35.2718C48 38.9144 46.8629 42.105 44.7308 44.3944C42.5912 46.692 39.5246 48 35.8371 48H16.1584C12.4708 48 9.40529 46.6919 7.26674 44.3942C5.13575 42.1047 4 38.9141 4 35.2718V16.7282C4 13.0845 5.14226 9.89414 7.27575 7.60523ZM8.73876 8.9689C7.00769 10.8261 6 13.4998 6 16.7282V35.2718C6 38.5016 7.00284 41.1751 8.73075 43.0316C10.4511 44.88 12.9648 46 16.1584 46H35.8371C39.0307 46 41.5457 44.88 43.2672 43.0314C44.9962 41.1748 46 38.5013 46 35.2718V16.7282C46 13.4987 44.9962 10.8251 43.2674 8.96853C41.5461 7.11996 39.0318 6 35.8394 6H16.1584C12.9769 6 10.4626 7.11953 8.73876 8.9689Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M26 17C26.5523 17 27 17.4477 27 18V27.6777L34.5108 32.1403C34.9856 32.4224 35.1418 33.036 34.8597 33.5108C34.5776 33.9856 33.964 34.1418 33.4892 33.8597L25.4892 29.1064C25.1859 28.9262 25 28.5995 25 28.2467V18C25 17.4477 25.4477 17 26 17Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M53 44C53 49.5228 48.5228 54 43 54C37.4772 54 33 49.5228 33 44C33 38.4772 37.4772 34 43 34C48.5228 34 53 38.4772 53 44Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M43 52C47.4183 52 51 48.4183 51 44C51 39.5817 47.4183 36 43 36C38.5817 36 35 39.5817 35 44C35 48.4183 38.5817 52 43 52ZM43 54C48.5228 54 53 49.5228 53 44C53 38.4772 48.5228 34 43 34C37.4772 34 33 38.4772 33 44C33 49.5228 37.4772 54 43 54Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M42.6165 39.4965V39.739C41.2068 39.8545 40.2481 40.709 40.2481 41.9446C40.2481 43.2494 41.015 43.8845 42.6617 44.3233V46.5982C42.0414 46.4827 41.5226 46.2171 40.9925 45.8245C40.891 45.7436 40.7556 45.6975 40.6203 45.6975C40.2707 45.6975 40 45.9746 40 46.3326C40 46.5751 40.1128 46.7598 40.3045 46.8868C40.9925 47.3834 41.7707 47.6952 42.6165 47.7875V48.5035C42.6165 48.7806 42.8421 49 43.1128 49C43.3835 49 43.5977 48.7806 43.5977 48.5035V47.8106C45.0301 47.6721 46 46.8291 46 45.5589C46 44.3233 45.2669 43.6189 43.5526 43.1801V40.963C43.9361 41.0554 44.3195 41.2286 44.7143 41.4711C44.8271 41.5404 44.9398 41.5751 45.0526 41.5751C45.4023 41.5751 45.6842 41.2979 45.6842 40.94C45.6842 40.6628 45.5263 40.4896 45.3459 40.3857C44.8271 40.0508 44.2632 39.8314 43.5977 39.7506V39.4965C43.5977 39.2194 43.3835 39 43.1128 39C42.8421 39 42.6165 39.2194 42.6165 39.4965ZM44.6692 45.6397C44.6692 46.194 44.2744 46.5635 43.5526 46.6443V44.5427C44.4211 44.8199 44.6692 45.1316 44.6692 45.6397ZM41.5789 41.8637C41.5789 41.3557 41.9398 40.963 42.6617 40.8938V42.9376C41.7932 42.649 41.5789 42.3487 41.5789 41.8637Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
