import candidateTypeEmpty from '../../../../assets/img/candidateTypeEmpty.svg';
import candidateListEmpty from '../../../../assets/img/candidateListEmpty.svg';
import { useRootStore } from '../../../../state/stores/storeContext';
import { LinkBtn } from "../../../common/Links";
import { EmptyListBannerWrap, BannerText } from "../CandidatesList.presents"

export const EmptyListBanner = (props: { activeTab: string }) => {
  const { candidatesStore } = useRootStore();

  const isJobActivated = candidatesStore.jobStatus !== 'active';
  const allCandidatesLength = candidatesStore.typesLength.allCandidates;
  const isEmptyList = allCandidatesLength === 0 && isJobActivated;
  const candidateLengthByType = candidatesStore.typesLength[`${props.activeTab}Candidates`];

  const mainText = isEmptyList ? 'You have no candidates for this job yet' :
    allCandidatesLength > 0 && candidateLengthByType === 0 ? `You have no ${props.activeTab} candidates.` : '';
  const descriptionText = isEmptyList ? 'Share this job to get more candidates' : !isJobActivated && allCandidatesLength === 0 ? 'To get the applications you need to activate this job' : '';

  return <EmptyListBannerWrap>
    <img src={allCandidatesLength > 0 ? candidateTypeEmpty : candidateListEmpty} alt="img" />
    <BannerText>{mainText}</BannerText>
    <BannerText>{descriptionText}</BannerText>
    {isEmptyList && <LinkBtn styled='main' path='/job-story/start' text='Share job' />}
  </EmptyListBannerWrap>
}