import styled from "styled-components";

export const Container = styled('div')`
  display: flex;
  flex: 1;
`

export const Content = styled('div')`
  flex: 1;
  background: #F0F3F8;
  padding-right: 0px;
  padding-left: 0px;
`