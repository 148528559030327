import arrowIcon from '../../../../assets/img/arrowNextDark.svg';
import {
  StyleForStep,
  StyleForStepIcon,
  StyleForStepTitle,
  StyleForStepWrap,
  BackToListBtn,
  ThemeBtn,
  ThemeBtnWrap,
  StepsWrap,
  StepsRow,
  PublishBtnWrap,
  ThemeBtnText,
  StepsProgressContainer,
  ButtonsContainer
} from "./Header.presents";
import bagIcon from '../../../../assets/img/bagStep.svg';
import calendarIcon from '../../../../assets/img/calendar.svg';
import paperIcon from '../../../../assets/img/paper.svg';
import moneyIcon from '../../../../assets/img/money.svg';
import messageIcon from '../../../../assets/img/message.svg';
import rocketIcon from '../../../../assets/img/rocket.svg';
import { PropsType } from "./Header.type";
import { useHistory } from "react-router-dom";
import { Button } from "../../../common/Button";
import { useRootStore } from "../../../../state/stores/storeContext";
import { useTranslation } from "react-i18next";
import { ThemeCustomizer } from "../ThemeCustomizer";
import { useState } from "react";
import { changeJobStatus } from "../../../../services/api/jobStore/jobStore.domains";
import { DesktopHeader } from "../../../common/Headers/AdminHeader/Desktop";

const steps = [
  { url: 'start', icon: bagIcon, title: 'Start' },
  { url: 'job', icon: calendarIcon, title: 'The Job' },
  { url: 'description', icon: paperIcon, title: 'Job Description' },
  { url: 'specification', icon: moneyIcon, title: 'Job Specification' },
  { url: 'contact', icon: messageIcon, title: 'Video & Voice' },
  { url: 'finish', icon: rocketIcon, title: 'Finish' }
]

export const HeaderOfJobStoryBuilder = (props: PropsType) => {
  const history = useHistory();
  const { jobStoryBuilder } = useRootStore();
  const { t } = useTranslation();
  const step = history.location.pathname.split('/')[2];

  const [isThemeMenuOpen, setThemeMenuOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const changeStatus = async () => {
    setLoading(true);
    const serverResponse = await changeJobStatus({ jobId: props.jobId || '', newStatus: 'active' });
    //TODO: fix index on contact step
    if (!serverResponse.data.err) {
      redirectToStep('status', 1)
    }
  }

  const redirectToStep = (step: string, index: number) => {
    if (index <= props.doneStep) {
      history.push(`/job-story/${step}?id=${props.jobId}`)
      props.setActiveStep(index)
      props.setDoneStep(index - 1)
    }
  }

  const showStepNavigation = step !== 'status' && step !== 'intro';

  return <>
    <DesktopHeader previewPage />    
    {
      showStepNavigation && <StepsRow>
      <StepsWrap className='align-items-center'>
        
          <BackToListBtn to='/my-jobs'>
            <img src={arrowIcon} alt="prevIcon" />
            Back to the list of jobs
          </BackToListBtn>

          <StepsProgressContainer>
            {steps.map((item, index) => <StyleForStepWrap key={index}
              onClick={() => redirectToStep(item.url, index)}
              className={`${index === steps.length - 1 && 'last'} ${index === props.activeStep ? 'active' : index <= props.doneStep ? 'done' : ''}`}>
              <div className='d-flex flex-column align-items-center position-relative'>
                <StyleForStep className='step'>
                  <StyleForStepIcon url={item.icon}></StyleForStepIcon>
                </StyleForStep>
                <StyleForStepTitle>{item.title}</StyleForStepTitle>
              </div>
            </StyleForStepWrap>
            )}
          </StepsProgressContainer>

        <ButtonsContainer>
          {step === 'finish' && <>
            <ThemeBtnWrap onClick={() => setThemeMenuOpen(true)}>
              <ThemeBtn />
              <ThemeBtnText>Edit style</ThemeBtnText>
            </ThemeBtnWrap>
            {jobStoryBuilder.jobStatus !== 'active' && <PublishBtnWrap>
              <Button disabled={isLoading} onClick={() => changeStatus()} buttonText={'Publish'} type='button' styled='warning' />
            </PublishBtnWrap>}
          </>}
        </ButtonsContainer>
      </StepsWrap>
    </StepsRow>
    }

    <ThemeCustomizer show={isThemeMenuOpen} handleClose={() => setThemeMenuOpen(false)} jobId={props.jobId} />
  </>
}