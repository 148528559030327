import { theme } from './../../../../theme/theme';
import { colors } from './../../../../constants/colors';
import styled from 'styled-components';

export const StyleForWrap = styled('div') <{ preview: boolean }>`
  display: inline-block;
  width: calc(100% + 32px);
  position: ${(props) => props.preview ? 'relative' : 'sticky'};
  top: 0px;
  z-index: ${(props) => props.preview ? '7' : '99'};
  margin: 18px -${theme.offsets.lg} ${theme.offsets.extraMajor};
  @media(max-width: ${theme.screens.desktop}) {
    margin: 18px -${theme.offsets.lg} ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: 18px -${theme.offsets.lg} ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: 18px -${theme.offsets.lg} ${theme.offsets.xl}; 
  }
  background: #fff;
  .tabs-wrap{
    display: flex;
    justify-content: center;
    @media(max-width: ${theme.screens.desktop}) {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .listitem{
    font-size: 18px;
    line-height: 20px;
    color: ${colors.dark};
    padding: 30px 16px;
    cursor: pointer;
    border-bottom: 4px solid #fff;
    white-space: nowrap;
    &:hover{
      border-bottom: 4px solid #0083FF;
    }
    @media(max-width: ${theme.screens.tablet}) {
      font-size: 14px;
      line-height: 16px;
      padding: ${theme.offsets.lg} ${theme.offsets.md};
     }
  }
  hr{
    background: #ECECEC;
    margin: 0;
  }
`