
import { useHistory } from "react-router-dom";
import { useRootStore } from "../../../../state/stores/storeContext";

export const CandidateVerifyPage = () => {
  const { candidateStore } = useRootStore();
  const params = new URLSearchParams(window.location.search);
  const verification_id = params.get("verification_id")
  const history = useHistory();
  if (verification_id && candidateStore.uid){
    candidateStore.validateCandidateEmail(candidateStore.uid,verification_id); 
  }
  history.push('/candidate/resume-builder');
  return <>
  </>
}