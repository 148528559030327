import styled from 'styled-components'
import { colors } from '../../../../../../constants/colors'
import { theme } from '../../../../../../theme'
import { mainStyleType } from '../../../../../../themes/mainStylesType'

export const StyleForText = styled('p')`
  margin: ${theme.offsets.extraBig} 0 ${theme.offsets.lg};
  font-size: 14px;
  color: ${theme.colors.darkGray};
  width: 50%;
  @media(max-width: ${theme.screens.tablet}) {
    width: 100%;
    font-size: ${theme.fontSizes.md};
    a{
      font-size: ${theme.fontSizes.md};
    }
  }
`

export const StyleForWrap = styled('div')`
  label .additional{
    color: rgba(45, 53, 61, 0.6);
  }
  form{
    margin-top: ${theme.offsets.extraMajor};
    @media(max-width: ${theme.screens.tablet}) {
      margin-top: ${theme.offsets.extraBig};
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin-top: ${theme.offsets.xxl};
    }
  }
  input{
    @media(max-width: ${theme.screens.tablet}) {
      height: 44px;
    }
  }
  label{
    @media(max-width: ${theme.screens.tablet}) {
      font-size: 12px;
    }
  }
  
`

export const StyleRadioWrap = styled('div')`
  .item{
    
  }
  
  
  
`

export const RadioWrapTitle = styled('div')`
  font-size: 18px;
  color: ${theme.colors.black};
  margin: ${theme.offsets.md} 0 ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
    margin: ${theme.offsets.sm} 0 ${theme.offsets.md};
  }
`

export const RadioItem = styled('div')`
  position: relative;
  margin-top: ${theme.offsets.lg};
  display: flex;
  label{
    font-size: 14px;
    color: ${theme.colors.black};
    padding-left: ${theme.offsets.extraBig};
    cursor: pointer;
    @media(max-width: ${theme.screens.tablet}) {
    font-size: 12px;
  }
  }
  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ div {
    border: 2px solid #0083FF;
  }
  input:checked ~ div:after {
    display: block;
  }
`

export const Checkmark = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  border: 2px solid #D1D7E3;
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #0083FF;
  }
`