import styled from 'styled-components';
import { theme } from '../../../../../../theme';

export const TextDescription = styled('p')`
  margin: ${theme.offsets.extraBig} 0;
  font-size: 18px;
  text-align: center;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
`
export const StepWrap = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`

export const HelperText = styled('div')<{isError?: boolean}>`
  font-size: ${theme.fontSizes.md};
  text-align: center;
  margin-top: ${theme.offsets.xxl};
  color: ${(props)=>props.isError ? theme.colors.red : theme.colors.black};
`