import 'react-app-polyfill/ie11';
import { Formik } from 'formik';
import { Title } from "../../../../../common/Title";
import { Input } from "../../../../../common/Input";
import { Button } from "../../../../../common/Button";
import { LinkBtn } from "../../../../../common/Links";
import { useState } from "react";
import { Text } from "../../../../../common/Text";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { verifyEmail } from '../../../../../../services/api/auth/auth.domains';
import { useTranslation } from "react-i18next";
import { HelperTextStatus } from '../../../SignIn/SignIn.props';
import { SPPrimaryButton } from '../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { SPWhiteButton } from '../../../../../common/buttons/SPWhiteButton/SPWhiteButton';

export const VerifEmail = () => {
  const [serverError, setServerError] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = { email: '' }
  const { userStore } = useRootStore()
  let history = useHistory();
  const { t } = useTranslation();

  const goBackHandler = () => {
    history.push('/sign-in')
  }

  return <div>
    <Title text={t('forgotPassword.emailVerTitle')} />
    <Text m={'24px 0 16px'} text={t('forgotPassword.emailVerText')} className='text-center' />
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      })}
      onSubmit={async (
        values: typeof initialValues
      ) => {
        setIsLoading(true);
        const dataFromServer = await verifyEmail({ email: values.email })
        if (!dataFromServer.data.err) {
          setServerError('')
          userStore.setHelperTextStatus(HelperTextStatus.EmailSent);
          history.push('/sign-in')
        } else {
          setServerError(dataFromServer.data.err)
        }
        setIsLoading(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Input
            mb={errors.email ? 4 : 32}
            name={'email'}
            type={'text'}
            value={values.email}
            onChange={handleChange}
            labelText={t('label.email')}
            error={errors.email ? true : false}
            m={'16px 0 8px'}
          />
          <Text m={'0 0 12px'} text={errors.email ? errors.email : ''} error />
          <SPPrimaryButton 
            width='100%'
            type='submit'
            disabled={isLoading}>
              {t('forgotPassword.resetPassword')}
          </SPPrimaryButton>
          <SPWhiteButton isLight width='100%' onClick={goBackHandler}>{t('forgotPassword.backBtn')}</SPWhiteButton>
        </form>
      )}
    </Formik>
    <Text text={serverError} error />
  </div>
}