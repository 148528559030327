export const IconsList = ({ iconType, isActive }: { iconType: string, isActive: boolean }) => {

  const icons: any = {
    all: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4184 4.47H14.6232C17.3152 4.47 19.5 6.72 19.5 9.48V15C19.5 17.76 17.3152 20 14.6232 20H5.3768C2.6848 20 0.5 17.76 0.5 15V9.48C0.5 6.72 2.6848 4.47 5.3768 4.47H5.58162C5.60113 3.27 6.05955 2.15 6.8886 1.31C7.72741 0.46 8.80031 0.03 10.0098 0C12.4286 0 14.3891 2 14.4184 4.47ZM7.91273 2.38C7.36653 2.94 7.06417 3.68 7.04466 4.47H12.9553C12.9261 2.83 11.6191 1.5 10.0098 1.5C9.25873 1.5 8.47844 1.81 7.91273 2.38ZM13.7064 8.32C14.116 8.32 14.4379 7.98 14.4379 7.57V6.41C14.4379 6 14.116 5.66 13.7064 5.66C13.3065 5.66 12.9748 6 12.9748 6.41V7.57C12.9748 7.98 13.3065 8.32 13.7064 8.32ZM6.93737 7.57C6.93737 7.98 6.6155 8.32 6.20585 8.32C5.80595 8.32 5.47433 7.98 5.47433 7.57V6.41C5.47433 6 5.80595 5.66 6.20585 5.66C6.6155 5.66 6.93737 6 6.93737 6.41V7.57Z" fill={isActive ? "#EC923C" : "#7B7875"} />
    </svg>,
    active: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.67 0H14.34C17.73 0 20 2.38 20 5.92V14.091C20 17.62 17.73 20 14.34 20H5.67C2.28 20 0 17.62 0 14.091V5.92C0 2.38 2.28 0 5.67 0ZM9.43 12.99L14.18 8.24C14.52 7.9 14.52 7.35 14.18 7C13.84 6.66 13.28 6.66 12.94 7L8.81 11.13L7.06 9.38C6.72 9.04 6.16 9.04 5.82 9.38C5.48 9.72 5.48 10.27 5.82 10.62L8.2 12.99C8.37 13.16 8.59 13.24 8.81 13.24C9.04 13.24 9.26 13.16 9.43 12.99Z" fill={isActive ? "#EC923C" : "#7B7875"} />
    </svg>,
    inactive: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.34 0H5.67C2.28 0 0 2.379 0 5.919V14.089C0 17.62 2.28 20 5.67 20H14.34C17.73 20 20 17.62 20 14.089V5.919C20 2.379 17.73 0 14.34 0Z" fill={isActive ? "#EC923C" : "#7B7875"} />
      <path d="M7 7C7 6.44772 7.44772 6 8 6C8.55228 6 9 6.44772 9 7V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V7Z" fill="white" />
      <path d="M11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7Z" fill="white" />
    </svg>
    ,
    draft: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6653 0.0103353C16.1038 -0.0795717 17.5224 0.419911 18.5913 1.3989C19.5703 2.46779 20.0697 3.88633 19.9898 5.33483V14.6652C20.0797 16.1137 19.5703 17.5322 18.6013 18.6011C17.5323 19.5801 16.1038 20.0796 14.6653 19.9897H5.33487C3.88636 20.0796 2.46781 19.5801 1.39891 18.6011C0.419915 17.5322 -0.0795724 16.1137 0.0103353 14.6652V5.33483C-0.0795724 3.88633 0.419915 2.46779 1.39891 1.3989C2.46781 0.419911 3.88636 -0.0795717 5.33487 0.0103353H14.6653ZM8.98113 14.845L15.7042 8.10196C16.3136 7.4826 16.3136 6.48364 15.7042 5.87427L14.4056 4.57561C13.7862 3.95625 12.7872 3.95625 12.1679 4.57561L11.4985 5.25491C11.3986 5.35481 11.3986 5.52463 11.4985 5.62453C11.4985 5.62453 13.0869 7.20289 13.1169 7.24285C13.2268 7.36273 13.2967 7.52256 13.2967 7.70238C13.2967 8.06201 13.007 8.36169 12.6374 8.36169C12.4675 8.36169 12.3077 8.29177 12.1978 8.18188L10.5295 6.5236C10.4496 6.44368 10.3098 6.44368 10.2298 6.5236L5.46474 11.2887C5.13507 11.6183 4.94527 12.0579 4.93528 12.5274L4.87534 14.8949C4.87534 15.0248 4.9153 15.1447 5.00521 15.2346C5.09512 15.3245 5.21499 15.3744 5.34486 15.3744H7.69245C8.17196 15.3744 8.63149 15.1846 8.98113 14.845Z" fill={isActive ? "#EC923C" : "#7B7875"} />
    </svg>,
    archived: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5H0Z" fill={isActive ? "#EC923C" : "#7B7875"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 7H0V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V7ZM5.5 10C4.67157 10 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13H14.5C15.3284 13 16 12.3284 16 11.5C16 10.6716 15.3284 10 14.5 10H5.5Z" fill={isActive ? "#EC923C" : "#7B7875"} />
    </svg>,
    arrow: <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 0.292893C9.31658 -0.0976311 8.68342 -0.0976311 8.29289 0.292893C7.90237 0.683417 7.90237 1.31658 8.29289 1.70711L15.5858 9H1C0.447715 9 0 9.44771 0 10C0 10.5523 0.447716 11 1 11H15.5858L8.29289 18.2929C7.90237 18.6834 7.90237 19.3166 8.29289 19.7071C8.68342 20.0976 9.31658 20.0976 9.70711 19.7071L18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289L9.70711 0.292893Z" fill="#433E3A" />
    </svg>,
    downloadResume: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4ZM10 4C9.44772 4 9 4.44771 9 5V12.5858L6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929C4.90237 10.6834 4.90237 11.3166 5.29289 11.7071L9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L14.7071 11.7071C15.0976 11.3166 15.0976 10.6834 14.7071 10.2929C14.3166 9.90237 13.6834 9.90237 13.2929 10.2929L11 12.5858V5C11 4.44772 10.5523 4 10 4Z" fill="#433E3A" />
    </svg>,
    overview:<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.54 2H7.92C9.33 2 10.46 3.15 10.46 4.561V7.97C10.46 9.39 9.33 10.53 7.92 10.53H4.54C3.14 10.53 2 9.39 2 7.97V4.561C2 3.15 3.14 2 4.54 2ZM4.54 13.4697H7.92C9.33 13.4697 10.46 14.6107 10.46 16.0307V19.4397C10.46 20.8497 9.33 21.9997 7.92 21.9997H4.54C3.14 21.9997 2 20.8497 2 19.4397V16.0307C2 14.6107 3.14 13.4697 4.54 13.4697ZM19.4601 2H16.0801C14.6701 2 13.5401 3.15 13.5401 4.561V7.97C13.5401 9.39 14.6701 10.53 16.0801 10.53H19.4601C20.8601 10.53 22.0001 9.39 22.0001 7.97V4.561C22.0001 3.15 20.8601 2 19.4601 2ZM16.0801 13.4697H19.4601C20.8601 13.4697 22.0001 14.6107 22.0001 16.0307V19.4397C22.0001 20.8497 20.8601 21.9997 19.4601 21.9997H16.0801C14.6701 21.9997 13.5401 20.8497 13.5401 19.4397V16.0307C13.5401 14.6107 14.6701 13.4697 16.0801 13.4697Z" fill={isActive ? "#187DFF" : "#7B7875"}/>
    </svg>,    
    jobs: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4184 4.47H14.6232C17.3152 4.47 19.5 6.72 19.5 9.48V15C19.5 17.76 17.3152 20 14.6232 20H5.3768C2.6848 20 0.5 17.76 0.5 15V9.48C0.5 6.72 2.6848 4.47 5.3768 4.47H5.58162C5.60113 3.27 6.05955 2.15 6.8886 1.31C7.72741 0.46 8.80031 0.03 10.0098 0C12.4286 0 14.3891 2 14.4184 4.47ZM7.91273 2.38C7.36653 2.94 7.06417 3.68 7.04466 4.47H12.9553C12.9261 2.83 11.6191 1.5 10.0098 1.5C9.25873 1.5 8.47844 1.81 7.91273 2.38ZM13.7064 8.32C14.116 8.32 14.4379 7.98 14.4379 7.57V6.41C14.4379 6 14.116 5.66 13.7064 5.66C13.3065 5.66 12.9748 6 12.9748 6.41V7.57C12.9748 7.98 13.3065 8.32 13.7064 8.32ZM6.93737 7.57C6.93737 7.98 6.6155 8.32 6.20585 8.32C5.80595 8.32 5.47433 7.98 5.47433 7.57V6.41C5.47433 6 5.80595 5.66 6.20585 5.66C6.6155 5.66 6.93737 6 6.93737 6.41V7.57Z" fill={isActive ? "#187DFF" : "#7B7875"}/>
    </svg>,
    gallery:<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.66618 22H16.3328C19.7231 22 22 19.6219 22 16.0833V7.91672C22 4.37811 19.7231 2 16.3338 2H7.66618C4.2769 2 2 4.37811 2 7.91672V16.0833C2 19.6219 4.2769 22 7.66618 22ZM8.49886 11C7.12021 11 6 9.87827 6 8.5C6 7.12173 7.12021 6 8.49886 6C9.8765 6 10.9977 7.12173 10.9977 8.5C10.9977 9.87827 9.8765 11 8.49886 11ZM19.8208 14.934C20.1557 15.7926 19.9817 16.8246 19.6237 17.6749C19.1994 18.6863 18.3869 19.452 17.3632 19.7864C16.9087 19.935 16.432 20 15.9564 20H7.52864C6.68999 20 5.94788 19.7988 5.3395 19.4241C4.95839 19.1889 4.89102 18.646 5.17358 18.2941C5.6462 17.7059 6.11279 17.1156 6.5834 16.5201C7.48038 15.3808 8.08473 15.0506 8.75645 15.3406C9.02896 15.4603 9.30248 15.6398 9.58404 15.8297C10.3342 16.3395 11.377 17.0402 12.7506 16.2797C13.6906 15.7532 14.2358 14.8501 14.7106 14.0637L14.7185 14.0506C14.7522 13.9954 14.7855 13.9402 14.8187 13.8852C14.9783 13.6212 15.1357 13.3607 15.3138 13.1207C15.5371 12.8204 16.3646 11.8813 17.4366 12.5501C18.1194 12.9711 18.6936 13.5408 19.308 14.1507C19.5423 14.3839 19.7092 14.6491 19.8208 14.934Z" fill={isActive ? "#187DFF" : "#7B7875"}/>
    </svg>,
    benefits: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9189 14.32C17.6599 14.571 17.5409 14.934 17.5999 15.29L18.4889 20.21C18.5639 20.627 18.3879 21.049 18.0389 21.29C17.6969 21.54 17.2419 21.57 16.8689 21.37L12.4399 19.06C12.2859 18.978 12.1149 18.934 11.9399 18.929H11.6689C11.5749 18.943 11.4829 18.973 11.3989 19.019L6.96888 21.34C6.74988 21.45 6.50188 21.489 6.25888 21.45C5.66688 21.338 5.27188 20.774 5.36888 20.179L6.25888 15.259C6.31788 14.9 6.19888 14.535 5.93988 14.28L2.32888 10.78C2.02688 10.487 1.92188 10.047 2.05988 9.65C2.19388 9.254 2.53588 8.965 2.94888 8.9L7.91888 8.179C8.29688 8.14 8.62888 7.91 8.79888 7.57L10.9889 3.08C11.0409 2.98 11.1079 2.888 11.1889 2.81L11.2789 2.74C11.3259 2.688 11.3799 2.645 11.4399 2.61L11.5489 2.57L11.7189 2.5H12.1399C12.5159 2.539 12.8469 2.764 13.0199 3.1L15.2389 7.57C15.3989 7.897 15.7099 8.124 16.0689 8.179L21.0389 8.9C21.4589 8.96 21.8099 9.25 21.9489 9.65C22.0799 10.051 21.9669 10.491 21.6589 10.78L17.9189 14.32Z" fill={isActive ? "#187DFF" : "#7B7875"}/>
    </svg>
    
  }

  return icons[iconType]
}