export const CalendarWithDateIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 22H49V45C49 48.3137 46.3137 51 43 51H15C11.6863 51 9 48.3137 9 45V22Z" fill="white" />
    <path d="M9 17C9 13.6863 11.6863 11 15 11H43C46.3137 11 49 13.6863 49 17V21H9V17Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 22C7 21.4477 7.44772 21 8 21H50C50.5523 21 51 21.4477 51 22C51 22.5523 50.5523 23 50 23H8C7.44772 23 7 22.5523 7 22Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.5 5C39.0523 5 39.5 5.44772 39.5 6V14C39.5 14.5523 39.0523 15 38.5 15C37.9477 15 37.5 14.5523 37.5 14V6C37.5 5.44772 37.9477 5 38.5 5Z" fill="#E4954D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 5C20.5523 5 21 5.44772 21 6V14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14V6C19 5.44772 19.4477 5 20 5Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3159 11.9893C12.4777 9.98127 15.5319 9 19.1322 9H38.8893C42.4996 9 45.5548 9.98083 47.712 11.9903C49.8799 14.0097 51.0109 16.9467 50.9999 20.5869V41.3922C50.9999 45.0328 49.8632 47.9742 47.6937 49.9984C45.5345 52.0129 42.4796 53 38.8677 53H19.1322C15.5261 53 12.4697 51.9939 10.3089 49.9474C8.13992 47.8933 7 44.9109 7 41.2197V20.5862C7 16.9441 8.14316 14.0076 10.3159 11.9893ZM11.6771 13.4547C9.99682 15.0155 9 17.3722 9 20.5862V41.2197C9 44.4926 10.0001 46.9004 11.6842 48.4953C13.3764 50.098 15.8862 51 19.1322 51H38.8677C42.1296 51 44.6409 50.1113 46.3293 48.536C48.0075 46.9703 48.9999 44.6079 48.9999 41.3922V20.5862L48.9999 20.5831C49.0101 17.3682 48.0227 15.013 46.3488 13.4537C44.6638 11.8842 42.1529 11 38.8893 11H19.1322C15.8803 11 13.3683 11.8837 11.6771 13.4547Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M22.8362 41.8056C22.8362 42.4713 23.3727 43 24.0051 43C24.6567 43 25.1741 42.4713 25.1741 41.8056V30.214C25.1741 29.5483 24.6758 29.0196 24.0051 29.0196H23.9668C23.6218 29.0196 23.2769 29.0979 22.817 29.235L20.7857 29.9007C20.3066 30.0378 20 30.4294 20 30.9385C20 31.4867 20.4791 31.9566 21.0348 31.9566C21.1498 31.9566 21.3223 31.9371 21.4564 31.8979L22.8362 31.4671V41.8056Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M33.7266 41.8643C33.7266 42.5105 34.2249 43 34.8572 43C35.4896 43 35.9879 42.5105 35.9879 41.8643V39.828H37.0227C37.5784 39.828 38 39.3972 38 38.849C38 38.3007 37.5784 37.8503 37.0227 37.8503H35.9879V30.1552C35.9879 29.5091 35.4705 29 34.8381 29C34.1482 29 33.8224 29.2741 33.4008 29.7832L27.0961 37.4196C26.7512 37.8308 26.5787 38.2224 26.5787 38.6923C26.5787 39.358 27.0961 39.828 27.7285 39.828H33.7266V41.8643ZM29.3191 37.8503L33.7266 32.4266V37.8503H29.3191Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
