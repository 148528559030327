import healthInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/healthInsurance.svg';
import dentalInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/dentalInsurance.svg';
import visionInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/visionInsurance.svg';
import disabilityInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/disabilityInsurance.svg';
import lifeInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/lifeInsurance.svg';
import petInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/petInsurance.svg';
import ADInsuranceIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/ADInsurance.svg';

import healthReimbursementIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/healthReimbursement.svg';
import FSAIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/FSA.svg';
import mentalBenefitsIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/mentalBenefits.svg';
import HSAIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/HSA.svg';
import gymIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/gym.svg';
import gymReimbursementIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/gymReimbursement.svg';
import gymDiscountIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/gymDiscount.svg';
import onsiteMassageIcon from '../../../../assets/img/jobStory/benefits/healthcareOffered/onsiteMassage.svg';


import KIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/K.svg';
import companyEquityPlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/companyEquityPlan.svg';
import RetirementSavingPlansIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/RetirementSavingPlans.svg';
import PensionSavingPlansIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/PensionSavingPlans.svg';
import RetirementWealthPlanningIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/RetirementWealthPlanning.svg';
import TaxdeferredRetirementPlansIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/TaxdeferredRetirementPlans.svg';
import TaxfreeRetirementPlansIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/TaxfreeRetirementPlans.svg';
import RetirementSavingProgramIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/RetirementSavingProgram.svg';
import TheSuperIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/TheSuper.svg';
import RegisteredRetirementSavingsPlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/RegisteredRetirementSavingsPlan.svg';
import GroupRegisteredRetirementSavingsPlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/GroupRegisteredRetirementSavingsPlan.svg';
import TFSAIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/TFSA.svg';
import RRIFIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/RRIF.svg';
import PrivatePersonalPensionIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/PrivatePersonalPension.svg';
import PublicPersonalPensionIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/PublicPersonalPension.svg';
import StatePersonalPensionIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/StatePersonalPension.svg';
import CityPersonalPensionIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/CityPersonalPension.svg';
import TESSAIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/TESSA.svg';
import ISAIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/ISAI.svg';
import SIPPIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/SIPP.svg';
import EPFIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/EPF.svg';
import SuperannuationPublicPensionSystemIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/SuperannuationPublicPensionSystem.svg';
import roth401KIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/roth401K.svg';
import KMatchingIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/KMatchingIcon.svg';
import KCatchipPlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/KCatchipPlan.svg';
import StockOptionsPlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/StockOptionsPlan.svg';
import stockPurchasePlanIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/stockPurchasePlan.svg';
import CollegeSavingsAccountIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/CollegeSavingsAccount.svg';
import FreeFinancialProgramIcon from '../../../../assets/img/jobStory/benefits/retirementSavingsOffered/FreeFinancialProgram.svg';

import PTOIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/PTO.svg';
import paidSickDaysOffIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/paidSickDaysOff.svg';
import paidHolidaysIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/paidHolidays.svg';
import paidEmergencyDaysOffIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/paidEmergencyDaysOff.svg';
import paidVolunteerTimeIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/paidVolunteerTime.svg';
import unlimitedVacationPlanIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/unlimitedVacationPlan.svg';
import parentalLeaveIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/parentalLeave.svg';
import familyMedicalLeaveIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/familyMedicalLeave.svg';
import flexibleRemoteWorkIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/flexibleRemoteWork.svg';
import flexibleWorkHoursIcon from '../../../../assets/img/jobStory/benefits/vacationOffered/flexibleWorkHours.svg';

import jobTrainingsIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/jobTrainings.svg';
import paidTrainingsIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/paidTrainings.svg';
import freeOnlineCoursesIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/freeOnlineCourses.svg';
import freeWebinarsIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/freeWebinars.svg';
import certificationExamsIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/certificationExams.svg';
import universityTuitionIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/universityTuition.svg';
import mentorshipProgramIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/mentorshipProgram.svg';
import salaryRaiseIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/salaryRaise.svg';
import careerPromotionIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/careerPromotion.svg';
import fastTrackLeadershipIcon from '../../../../assets/img/jobStory/benefits/learningOpportunitiesOffered/fastTrackLeadership.svg';

import pantryAndKitchenIcon from '../../../../assets/img/jobStory/benefits/officeOffered/pantryAndKitchen.svg';
import freeMealsIcon from '../../../../assets/img/jobStory/benefits/officeOffered/freeMeals.svg';
import companyCarIcon from '../../../../assets/img/jobStory/benefits/officeOffered/companyCar.svg';
import someFreeMealsIcon from '../../../../assets/img/jobStory/benefits/officeOffered/someFreeMeals.svg';
import freeDrinksIcon from '../../../../assets/img/jobStory/benefits/officeOffered/freeDrinks.svg';
import paidInternationalTripsIcon from '../../../../assets/img/jobStory/benefits/officeOffered/paidInternationalTrips.svg';
import paidTripsConferencesIcon from '../../../../assets/img/jobStory/benefits/officeOffered/paidTripsConferences.svg';
import weeklyTravelIcon from '../../../../assets/img/jobStory/benefits/officeOffered/weeklyTravel.svg';
import monthlyTransitStipendIcon from '../../../../assets/img/jobStory/benefits/officeOffered/monthlyTransitStipend.svg';
import commuterBenefitsIcon from '../../../../assets/img/jobStory/benefits/officeOffered/commuterBenefits.svg';
import defaultIcon from '../../../../assets/img/jobStory/benefits/officeOffered/default.svg';


import capacityBuildingIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/capacityBuilding.svg';
import emergencyFoodIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/emergencyFood.svg';
import logisticsIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/logistics.svg';
import publicHealthIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/publicHealth.svg';
import educationIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/education.svg';
import teachingIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/teaching.svg';
import helpingChildrenIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/helpingChildren.svg';
import helpingRefugeesIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/helpingRefugees.svg';
import protectingEnvironmentIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/protectingEnvironment.svg';
import religiousCharityIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/religiousCharity.svg';
import sportsIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/sports.svg';
import technologyAssistanceIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/technologyAssistance.svg';
import governmentPoliciesIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/governmentPolicies.svg';
import wildlifeConservationIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/wildlifeConservation.svg';
import marineConservationIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/marineConservation.svg';
import animalCareIcon from '../../../../assets/img/jobStory/benefits/volunteerAbout/animalCare.svg';


const healthcareOffered = {
  healthInsurance: healthInsuranceIcon,
  dentalInsurance: dentalInsuranceIcon,
  visionInsurance: visionInsuranceIcon,
  disabilityInsurance: disabilityInsuranceIcon,
  lifeInsurance: lifeInsuranceIcon,
  petInsurance: petInsuranceIcon,
  ADInsurance: ADInsuranceIcon,
  defaultIcon: healthInsuranceIcon
}

const savingsOffered = {
  healthReimbursement: healthReimbursementIcon,
  FSA: FSAIcon,
  mentalBenefits: mentalBenefitsIcon,
  HSA: HSAIcon,
  gym: gymIcon,
  gymReimbursement: gymReimbursementIcon,
  gymDiscount: gymDiscountIcon,
  onsiteMassage: onsiteMassageIcon,
  defaultIcon: healthInsuranceIcon
}

const retirementSavingsOffered = {
  "Retirement saving plans": RetirementSavingPlansIcon,
  "Pension saving plans": PensionSavingPlansIcon,
  "Retirement wealth planning": RetirementWealthPlanningIcon,
  "Tax-deferred retirement plans": TaxdeferredRetirementPlansIcon,
  "Tax-free retirement plans": TaxfreeRetirementPlansIcon,
  "Retirement saving program": RetirementSavingProgramIcon,
  "The Super (superannuation guarantee/SG)": TheSuperIcon,
  "Registered retirement savings plan (RRSP)": RegisteredRetirementSavingsPlanIcon,
  "Group registered retirement savings plan (GRSP)": GroupRegisteredRetirementSavingsPlanIcon,
  "Tax free savings account (TFSA)": TFSAIcon,
  "Registered retirement income fund (RRIF)": RRIFIcon,
  "Private personal pension": PrivatePersonalPensionIcon,
  "Public personal pension": PublicPersonalPensionIcon,
  "State personal pension": StatePersonalPensionIcon,
  "City personal pension": CityPersonalPensionIcon,
  "Tax-exempt savings schemes (TESSA)": TESSAIcon,
  "Individual savings accounts (ISA)": ISAIcon,
  "Self-invested personal pension (SIPP)": SIPPIcon,
  "Employee Provident Fund (EPF)": EPFIcon,
  "Superannuation public pension system": SuperannuationPublicPensionSystemIcon,
  "401K": KIcon,
  "roth401K": roth401KIcon,
  "401KMatching": KMatchingIcon,
  "401KCatchipPlan": KCatchipPlanIcon,
  "StockOptionsPlan": StockOptionsPlanIcon,
  "stockPurchasePlan": stockPurchasePlanIcon,
  "companyEquityPlan": companyEquityPlanIcon,
  "529CollegeSavingsAccount": CollegeSavingsAccountIcon,
  "FreeFinancialProgram": FreeFinancialProgramIcon,
  defaultIcon: KIcon
}

const vacationOffered = {
  PTO: PTOIcon,
  paidSickDaysOff: paidSickDaysOffIcon,
  paidHolidays: paidHolidaysIcon,
  paidEmergencyDaysOff: paidEmergencyDaysOffIcon,
  paidVolunteerTime: paidVolunteerTimeIcon,
  unlimitedVacationPlan: unlimitedVacationPlanIcon,
  parentalLeave: parentalLeaveIcon,
  familyMedicalLeave: familyMedicalLeaveIcon,
  flexibleRemoteWork: flexibleRemoteWorkIcon,
  flexibleWorkHours: flexibleWorkHoursIcon,
  defaultIcon: PTOIcon
}

const learningOpportunitiesOffered = {
  jobTrainings: jobTrainingsIcon,
  paidTrainings: paidTrainingsIcon,
  freeOnlineCourses: freeOnlineCoursesIcon,
  freeWebinars: freeWebinarsIcon,
  certificationExams: certificationExamsIcon,
  universityTuition: universityTuitionIcon,
  mentorshipProgram: mentorshipProgramIcon,
  salaryRaise: salaryRaiseIcon,
  careerPromotion: careerPromotionIcon,
  fastTrackLeadership: fastTrackLeadershipIcon,
  defaultIcon: jobTrainingsIcon
}

const officeOffered = {
  pantryAndKitchen: pantryAndKitchenIcon,
  freeMeals: freeMealsIcon,
  companyCar: companyCarIcon,
  someFreeMeals: someFreeMealsIcon,
  freeDrinks: freeDrinksIcon,
  paidInternationalTrips: paidInternationalTripsIcon,
  paidTripsConferences: paidTripsConferencesIcon,
  weeklyTravel: weeklyTravelIcon,
  monthlyTransitStipend: monthlyTransitStipendIcon,
  commuterBenefits: commuterBenefitsIcon,
  defaultIcon: defaultIcon
}

const volunteerAbout = {
  capacityBuilding: capacityBuildingIcon,
  emergencyFood: emergencyFoodIcon,
  logistics: logisticsIcon,
  publicHealth: publicHealthIcon,
  education: educationIcon,
  teaching: teachingIcon,
  helpingChildren: helpingChildrenIcon,
  helpingRefugees: helpingRefugeesIcon,
  protectingEnvironment: protectingEnvironmentIcon,
  religiousCharity: religiousCharityIcon,
  sports: sportsIcon,
  technologyAssistance: technologyAssistanceIcon,
  governmentPolicies: governmentPoliciesIcon,
  wildlifeConservation: wildlifeConservationIcon,
  marineConservation: marineConservationIcon,
  animalCare: animalCareIcon,
  defaultIcon: capacityBuildingIcon
}

export const iconMap: any = {
  healthcareOffered,
  savingsOffered,
  retirementSavingsOffered,
  vacationOffered,
  learningOpportunitiesOffered,
  officeOffered,
  volunteerAbout
}