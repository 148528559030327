import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled('div') <{ isGalleryEmpty: boolean }>`
  display: flex;
  justify-content: ${(props)=>props.isGalleryEmpty ? 'center' : 'flex-start'};
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    margin: 0 -${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: 0 -2px;
  }
`