import { theme } from './../../../../theme/theme';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const SnapshotItem = styled(Col)`
  display: flex;
  align-items: center;
  padding: 0px 0px ${theme.offsets.xxl};
  border-bottom: 1px solid #ECECEC;
  width: 100%;
  font-size: 18px;
  line-height: ${theme.offsets.xxl};
  margin: 0 ${theme.offsets.md}; 
  @media(max-width: ${theme.screens.desktop}) {
    line-height: ${theme.offsets.xl}; 
    padding: 0px 0px ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0px 0px ${theme.offsets.lg}; 
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0px 0px ${theme.offsets.md}; 
  }
`

export const SnapshotImg = styled('img')`
    margin-right: ${theme.offsets.lg};
    @media(max-width: ${theme.screens.desktop}) {
      width: 36px;  
    }
    @media(max-width: ${theme.screens.mobile}) {
      margin-right: ${theme.offsets.md};
    }
`

export const SnapshotItemWrap = styled(Col)`
  display: flex;
  align-items: end;
  padding: 0;
  width: 100%;
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: ${theme.offsets.xl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: ${theme.offsets.lg}; 
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.md}; 
  }
`

export const SnapshotRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${theme.offsets.xxl};
`

export const SnapshotContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`