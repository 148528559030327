import { mapStyle } from './LocationModal.presents';
import GoogleMapReact from 'google-map-react';
import { PointOnMap } from './PointOnMap';

export const SimpleMap = ({ coordinates, centerCoord, setCenterCoord }: any) => {
  const defaultProps = {
    zoom: 8
  };
  const createMapOptions = (maps: any) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: mapStyle
    }
  }
  const defaultLatitude = coordinates.length > 0 ? Number.parseInt(coordinates[0].latitude) : 100;
  const defaultLongitude = coordinates.length > 0 ? Number.parseInt(coordinates[0].longitude) : 100;
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        style={{minHeight: '400px'}}
        bootstrapURLKeys={{ key: "" }}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={{
          lat: defaultLatitude,
          lng: defaultLongitude
        }}
        center={centerCoord}
        defaultZoom={defaultProps.zoom}
      >
        {coordinates.map((item: { label: "", value: "", latitude: "", longitude: "" }) => <PointOnMap
          active={centerCoord.value === item.value}
          item={item}
          setCenterCoord={setCenterCoord}
          lat={item.latitude}
          lng={item.longitude}
        />)}

      </GoogleMapReact>
    </div>
  );
}