import spinnerIcon from '../../../assets/img/spinner.svg';
import { SpinnerWrap } from './Preloader.presents';

export const Preloader = ({
  isFullScreen
}:{
  isFullScreen?: boolean;
}) => {
  return <SpinnerWrap isFullScreen={isFullScreen}>
    <img src={spinnerIcon} alt="spinner" className='spinner' />
  </SpinnerWrap>
}