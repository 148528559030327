import styled from 'styled-components'
import { theme } from '../../../../theme'

export const StyleForHelperText = styled('div')`
  background: #EEF6FE;
  border-radius: 10px;
  padding: 24px;
  margin-top: 48px;
  margin-bottom: -16px;
`

export const SignUpMobileLink = styled('p')`
  margin-top: 60px;
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.darkGray};
  text-align: center;
  a {
    padding-left: 8px;
    font-weight: 500;
    color: ${theme.colors.blue};
  }
  @media(min-width: ${theme.screens.mobile}) {
    display: none;
  }
`

export const ButtonWrap = styled('div')`
width: 100%;
@media(max-width: ${theme.screens.tablet}) {
  button{
  height: 44px;
  }
}
`
