export const AnyDayIcon = ({ active }: { active?: boolean }) => {

  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 16C9 12.6863 11.6863 10 15 10H43C46.3137 10 49 12.6863 49 16V20H9V16Z" fill="#F8DDC3" />
    <path d="M9 21H49V44C49 47.3137 46.3137 50 43 50H15C11.6863 50 9 47.3137 9 44V21Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 21C7 20.4477 7.44772 20 8 20H50C50.5523 20 51 20.4477 51 21C51 21.5523 50.5523 22 50 22H8C7.44772 22 7 21.5523 7 21Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.5 4C39.0523 4 39.5 4.44772 39.5 5V13C39.5 13.5523 39.0523 14 38.5 14C37.9477 14 37.5 13.5523 37.5 13V5C37.5 4.44772 37.9477 4 38.5 4Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 4C20.5523 4 21 4.44772 21 5V13C21 13.5523 20.5523 14 20 14C19.4477 14 19 13.5523 19 13V5C19 4.44772 19.4477 4 20 4Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.3159 10.9893C12.4777 8.98127 15.5319 8 19.1322 8H38.8893C42.4996 8 45.5548 8.98083 47.712 10.9903C49.8799 13.0097 51.0109 15.9467 50.9999 19.5869V40.3922C50.9999 44.0328 49.8632 46.9742 47.6937 48.9984C45.5345 51.0129 42.4796 52 38.8677 52H19.1322C15.5261 52 12.4697 50.9939 10.3089 48.9474C8.13992 46.8933 7 43.9109 7 40.2197V19.5862C7 15.9441 8.14316 13.0076 10.3159 10.9893ZM11.6771 12.4547C9.99682 14.0155 9 16.3722 9 19.5862V40.2197C9 43.4926 10.0001 45.9004 11.6842 47.4953C13.3764 49.098 15.8862 50 19.1322 50H38.8677C42.1296 50 44.6409 49.1113 46.3293 47.536C48.0075 45.9703 48.9999 43.6079 48.9999 40.3922V19.5862L48.9999 19.5831C49.0101 16.3682 48.0227 14.013 46.3488 12.4537C44.6638 10.8842 42.1529 10 38.8893 10H19.1322C15.8803 10 13.3683 10.8837 11.6771 12.4547Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M38 28C38.5523 28 39 28.4477 39 29V34C39 34.5523 38.5523 35 38 35H33C32.4477 35 32 34.5523 32 34C32 33.4477 32.4477 33 33 33H37V29C37 28.4477 37.4477 28 38 28Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.8929 29C27.9383 29 26.9915 29.1994 26.106 29.5885C25.2203 29.9778 24.4116 30.55 23.7277 31.2756C23.3489 31.6775 22.716 31.6962 22.3141 31.3174C21.9122 30.9386 21.8935 30.3057 22.2723 29.9038C23.1361 28.9874 24.1648 28.257 25.3013 27.7576C26.438 27.258 27.6586 27 28.8929 27C30.1271 27 31.3477 27.258 32.4844 27.7576C33.6209 28.257 34.6496 28.9874 35.5134 29.9038L38.7277 33.3141C39.1065 33.716 39.0878 34.3489 38.6859 34.7277C38.284 35.1065 37.6511 35.0878 37.2723 34.6859L34.058 31.2756C33.3741 30.55 32.5654 29.9778 31.6797 29.5885C30.7942 29.1994 29.8474 29 28.8929 29Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19 38C19 37.4477 19.4477 37 20 37H25C25.5523 37 26 37.4477 26 38C26 38.5523 25.5523 39 25 39H21V43C21 43.5523 20.5523 44 20 44C19.4477 44 19 43.5523 19 43V38Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3141 37.2723C19.716 36.8935 20.3489 36.9122 20.7277 37.3141L23.942 40.7244C24.6259 41.45 25.4346 42.0222 26.3203 42.4115C27.2058 42.8006 28.1526 43 29.1071 43C30.0617 43 31.0085 42.8006 31.894 42.4115C32.7797 42.0222 33.5884 41.45 34.2723 40.7244C34.6511 40.3225 35.284 40.3038 35.6859 40.6826C36.0878 41.0614 36.1065 41.6943 35.7277 42.0962C34.8639 43.0126 33.8352 43.743 32.6987 44.2424C31.562 44.742 30.3414 45 29.1071 45C27.8729 45 26.6523 44.742 25.5156 44.2424C24.3791 43.743 23.3504 43.0126 22.4866 42.0962L19.2723 38.6859C18.8935 38.284 18.9122 37.6511 19.3141 37.2723Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
