import styled from "styled-components"
import { SPColors } from "./colors"
import { SPFontSizes } from "./fontSizes"
import { SPOffsets } from "./offsets"
import { SPScreensWidth } from "./screens"

const HeadingFontFamily = "'Recoleta', 'Arial', serif"
const FontFamily = "'Apercu Pro', 'Arial', serif"


export const typography = {
  headingFontFamily: HeadingFontFamily,
  fontFamily: FontFamily
}

export type Typography = typeof typography

// Components Typography

export const SPHeading2 = styled('h2')`
  font-family: ${HeadingFontFamily};
  font-style: "normal";
  font-weight: 500;
  font-size: "36px";
  line-height: "44px";
  color: ${SPColors.black};

  @media(max-width: ${SPScreensWidth.desktop}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media(max-width: ${SPScreensWidth.mobile}) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const FieldWrap = styled('div')`
margin-top: ${SPOffsets.sm};
display: block;
  @media(max-width: ${SPScreensWidth.tablet}) {
    margin-top: ${SPOffsets.xs};
  }
  @media(max-width: ${SPScreensWidth.mobile}) {
    margin-top: ${SPOffsets.sm};
  }
`

export const ErrorMsg = styled('div')`
  color: ${SPColors.red};
  margin-top: ${SPOffsets.xs};
  height: 12px;
  font-size: ${SPFontSizes.md};
  display: block;
`

