export enum SPFontSizes {
  /**
   * @extraMajor 48px
   */
  extraMajor = "48px",

  /**
   * @major 40px
   */
  major = "40px",

  /**
   * @extraBig 32px
   */
  extraBig = "32px",

  /**
   * @big 28px
   */
  big = "28px",

  /**
   * @extraExtraLarge 24px
   */
  xxl = "24px",

  /**
   * @extraLarge 20px
   */
  xl = "20px",

  /**
   * @large 16px
   */
  lg = "16px",

  /**
   * @medium 12px
   */
  md = "12px",

  /**
   * @small 8px
   */
  sm = "8px",

  /**
   * @extraSmall 4px
   */
  xs = "4px"
}