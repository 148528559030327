import { FieldArray } from "formik"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { StyleForAddCityBtn, StyleForBtnWrap } from "../../../AdminPanel/Organization/Organization.presents"
import { PropsType } from './LanguagesFieldArray.props'
import { LanguagesField } from "./LanguagesField"

export const LanguagesFieldArray = observer((props: PropsType) => {
  const [languagesArray, setLanguagesArray] = useState(props.values.languages);

  useEffect(() => {
    setLanguagesArray(props.values.languages)
  }, [props.values.languages, languagesArray])

  return <FieldArray
    name="languages">
    {({ insert, remove, push }) => (
      <div>
        {languagesArray.map((item: any, index: number) => <LanguagesField
          key={index}
          index={index}
          remove={remove}
          errors={props.errors}
          handleChange={props.handleChange}
          values={props.values}
        />)}
        <StyleForBtnWrap>
          <StyleForAddCityBtn type={'button'} onClick={() => push({
            language: '',
            level: '',
            isItmainLanguage: []
          })}>Add another language</StyleForAddCityBtn>
        </StyleForBtnWrap>
      </div>
    )}
  </FieldArray>
})