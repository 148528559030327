import styled from "styled-components";
import { theme } from "../../../../theme";

export const StyleFortabs = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  cursor: pointer;
  margin-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.lightGray};
  ${(props) => !props.isDesktop && `
  background-color: ${theme.colors.white};
    @media(max-width: ${theme.screens.mobile}) { 
      overflow-x: scroll;
      max-width: 100vw;
      overflow-y: hidden;
    } 
  `};
`
export const Title = styled('span') <{ isActive: boolean }>`
  font-size: ${theme.fontSizes.lg};
  color: ${(props) => props.isActive ? theme.colors.lightOrange : theme.colors.black};
`

export const IconWrap = styled('span')`
  margin-right: ${theme.offsets.sm};
`

export const Count = styled('span')`
  border: 1px solid ${theme.colors.lightGray};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  line-height: ${theme.fontSizes.xl};
  border-radius: 100%;
  font-size: ${theme.fontSizes.md};
  margin-left: ${theme.offsets.xs};
`

export const TabItem = styled('div') <{ isActive: boolean, isDesktop: boolean }>`
  padding: 16px 29px;
  display: flex;
  align-items: center;
  position: relative;
  ${(props) => !props.isDesktop && `
  padding: ${theme.offsets.md} ${theme.offsets.lg};
`};
  &::after{
    content:'';
    display:${(props) => props.isActive ? 'block' : 'none'};
    width: 100%;
    height: 2px;
    background-color:#EC923C;
    position: absolute;
    bottom: -1px;
    left: 50%; 
    transform: translate(-50%, 0%); 
  }
  &:hover::after{
    display: block;
  } 
`

