import { SnapshotsWrap, MainDescriptionCard } from "./Jobs.presents";
import { ReactComponent as SideJobIcon } from '../../../assets/img/organization/jobCard/sideJob.svg';
import { ReactComponent as CustomerFacingIcon } from '../../../assets/img/organization/jobCard/customerFacing.svg';
import { ReactComponent as WorkVisaIcon } from '../../../assets/img/organization/jobCard/workVisa.svg';
import { ReactComponent as DoneOverWeekendsIcon } from '../../../assets/img/organization/jobCard/doneOverWeekends.svg';
import { ReactComponent as DoneBusinessHoursIcon } from '../../../assets/img/organization/jobCard/doneBusinessHours.svg';
import { ReactComponent as StartupIcon } from '../../../assets/img/organization/jobCard/startup.svg';
import { ReactComponent as EquityIcon } from '../../../assets/img/organization/jobCard/equity.svg';
import { ReactComponent as PaidIntershipIcon } from '../../../assets/img/organization/jobCard/paidIntership.svg';
import { ReactComponent as PaidApprenticeshipIcon } from '../../../assets/img/organization/jobCard/paidApprenticeship.svg';
import { ReactComponent as FixedBudgetIcon } from '../../../assets/img/organization/jobCard/fixedBudget.svg';
import { compensationData, jobKindIcons, relocationPackIcons, remoteJobIcons, travelingData } from "./helper";
import { useTranslation } from "react-i18next";

export const Snapshots = ({ stepsAnswers }: { stepsAnswers: any }) => {
  const { t } = useTranslation();

  const jobKindValue = stepsAnswers.startStep.answers.jobKind.value;
  const sideJob = stepsAnswers.jobStep.answers.sideJob;
  const customerFacing = stepsAnswers.jobStep.answers.customerFacing;
  const workVisa = stepsAnswers.jobStep.answers.workVisa;
  const startup = stepsAnswers.descriptionStep.answers.startup;

  const canDoneOver = stepsAnswers.jobStep.answers.canDoneOver;
  const relocationPack = stepsAnswers.jobStep.answers.relocationPack;

  const compensation = stepsAnswers.specificationStep.answers.compensation;

  const equity = stepsAnswers.specificationStep.answers.equityIncluded;
  const paidJob = stepsAnswers.specificationStep.answers.internshipType;
  const paidIntership = paidJob && jobKindValue && jobKindValue === 'internship';

  const fixedBudget = stepsAnswers.specificationStep.answers.fixedBudget;

  const traveling = stepsAnswers.jobStep.answers.traveling;
  const showTraveling = traveling && Array.isArray(traveling.value) && traveling.value.length > 0;

  const remoteJobValue = stepsAnswers.startStep.answers.doneRemotely;
  const isJobRemote = remoteJobValue && remoteJobValue.value !== 'no';



  return <SnapshotsWrap>
    <MainDescriptionCard>
      {jobKindIcons[jobKindValue]}
      <span>
        {t(`jobStory.jobKind.${jobKindValue}`) + ' job'}
      </span>
    </MainDescriptionCard>

    {isJobRemote && <MainDescriptionCard>
      {remoteJobIcons[remoteJobValue.value]}
      <span>
        {t(`jobStory.doneRemotely.${remoteJobValue.value}`)}
      </span>
    </MainDescriptionCard>}

    {(sideJob && sideJob.value === 'yes') && <MainDescriptionCard>
      <SideJobIcon />
      <span>
        Can be done as a side job
      </span>
    </MainDescriptionCard>}

    {(customerFacing && customerFacing.value === 'yes') && <MainDescriptionCard>
      <CustomerFacingIcon />
      <span>
        Customer facing job
      </span>
    </MainDescriptionCard>}

    {(workVisa && workVisa.value === 'yes') && <MainDescriptionCard>
      <WorkVisaIcon />
      <span>
        Visa sponsorship
      </span>
    </MainDescriptionCard>}

    {(startup && startup.value === 'yes') && <MainDescriptionCard>
      <StartupIcon />
      <span>
        Startup job
      </span>
    </MainDescriptionCard>}

    {(equity && equity.value === 'yesEquity') && <MainDescriptionCard>
      <EquityIcon />
      <span>
        Equity is included
      </span>
    </MainDescriptionCard>}

    {(canDoneOver && canDoneOver.value.includes('weekends')) && <MainDescriptionCard>
      <DoneOverWeekendsIcon />
      <span>
        Can be done over the weekends
      </span>
    </MainDescriptionCard>}

    {(canDoneOver && canDoneOver.value.includes('businessHours')) && <MainDescriptionCard>
      <DoneBusinessHoursIcon />
      <span>
        Can be done after business hours
      </span>
    </MainDescriptionCard>}

    {showTraveling && traveling.value.map((item: string, index: number) =>
      <MainDescriptionCard key={`traveling${index}`}>
        {travelingData[item].icon}
        <span>
          {travelingData[item].title}
        </span>
      </MainDescriptionCard>
    )}

    {(relocationPack && relocationPack.value !== 'no') && <MainDescriptionCard>
      {relocationPackIcons[relocationPack.value]}
      <span>
        {t(`jobStory.relocationPack.${relocationPack.value}`)}
      </span>
    </MainDescriptionCard>}

    {compensation && <MainDescriptionCard>
      {compensationData[compensation.value].icon}
      <span>
        {compensationData[compensation.value].title}
      </span>
    </MainDescriptionCard>}

    {paidJob && <MainDescriptionCard>
      {paidIntership ? <PaidIntershipIcon /> : <PaidApprenticeshipIcon />}
      <span>
        {paidIntership ? 'Paid Internship' : 'Paid apprenticeship'}
      </span>
    </MainDescriptionCard>}

    {(fixedBudget && fixedBudget.value === 'fixedBudgetYes') && <MainDescriptionCard>
      <FixedBudgetIcon />
      <span>
        Fixed budget
      </span>
    </MainDescriptionCard>}

  </SnapshotsWrap>
}