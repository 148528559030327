import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import {
    Container,
    SideInfoHeader,
    SideInfoContent,
    SideInfoContentHeader,
    SideInfoWrapper,
} from "./SideInformation.presents";
import { ReactComponent as AwardIcon } from '../../../../../assets/img/resume/award.svg';
import { ReactComponent as LanguageIcon } from '../../../../../assets/img/resume/language.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/img/resume/user.svg';
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { TextButton } from "../../../../common/buttons/TextButton/TextButton";
import Flag from 'react-world-flags'
import countries from 'svg-country-flags/countries.json'
import { linkHandler } from "../../../../../services/helper";
import { Title } from "../../Resume.presents";

export const SideInformation = observer(({
    isDesktop,
    resume,
    preferences
}: {
    isDesktop: boolean;
    resume: any;
    preferences: any;
}) => {
    function getCountryCode(value: string) {
        return Object.keys(countries).find(key => countries[key as keyof typeof countries] === value);
    }
    return <Container isDesktop={isDesktop}>
        <CandidateFormCard isDesktop={isDesktop}>
            <Title>
                Languages
            </Title>
            {resume.languages && resume.languages.length > 0 ?
                <>
                    {resume.languages.map((item: any, index: number) => <Language
                        language={item.language}
                        level={item.level.charAt(0).toUpperCase() + item.level.slice(1)}
                        isLastItem={(index + 1) === resume.languages.length}
                    />
                    )}
                </>
                :
                <>
                    {preferences.languages && (
                        preferences.languages.map((language: string, index: number) => <Language
                            language={language}
                            level={""}
                            isLastItem={(index + 1) === preferences.languages.length}
                        />
                        )
                    )}
                </>
            }
        </CandidateFormCard>

        {resume.workAuthorization && resume.workAuthorization.length > 0 && (
            <CandidateFormCard isDesktop={isDesktop}>
                <Title>
                    Work Authorization
                </Title>
                {resume.workAuthorization.map((item: any, index: number) => <WorkAuthorization
                    code={getCountryCode(item.country) || ''}
                    country={item.country}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    isLastItem={(index + 1) === resume.workAuthorization.length}
                />

                )}
            </CandidateFormCard>
        )
        }
        {resume.references && resume.references.length > 0 && (
            <CandidateFormCard isDesktop={isDesktop}>
                <Title>
                    References
                </Title>
                {resume.references.map((item: any, index: number) => <Reference
                    key={`reference${index}`}
                    fullName={item.fullName}
                    company={item.company}
                    email={item.email}
                    phone={item.phone}
                    isLastItem={(index + 1) === resume.references.length}
                />
                )}
            </CandidateFormCard>
        )
        }
        {resume.honors && resume.honors.length > 0 && (
            <CandidateFormCard isDesktop={isDesktop}>
                <Title>
                    Honors and awards
                </Title>
                {resume.honors.map((item: any, index: number) => <Award
                    title={item.title}
                    date={new Date(item.date).getFullYear().toString()}
                    awardURL={item.awardURL}
                    isLastItem={(index + 1) === resume.honors.length}
                />
                )}
            </CandidateFormCard>
        )
        }
    </Container>
});

export const Reference = ({ key, fullName, email, phone, isLastItem }: { key: string, fullName: string, company: string, email: string, phone: string, isLastItem: boolean }) => {
    const [showPhone, setShowPhone] = useState(false)
    const [showEmail, setShowEmail] = useState(false)
    return <SideInfoWrapper isLastItem={isLastItem}>

        <SideInfoHeader>
            <UserIcon />
            {fullName}
        </SideInfoHeader>
        <SideInfoContentHeader>Email:</SideInfoContentHeader>
        <SideInfoContent>{!showEmail ?
            <TextButton text={"View"} onClick={() => setShowEmail(!showEmail)} />
            :
            email
        }</SideInfoContent>
        <SideInfoContentHeader>Phone Number:</SideInfoContentHeader>
        <SideInfoContent>
            {!showPhone ?
                <TextButton text={"View"} onClick={() => setShowPhone(!showPhone)} />
                :
                phone
            }
        </SideInfoContent>
    </SideInfoWrapper>
}

export const Language = ({ language, level, isLastItem }: { language: string, level: string, isLastItem: boolean }) => {
    return <SideInfoWrapper isLastItem={isLastItem}>
        <SideInfoHeader>
            <LanguageIcon />
            {language}
        </SideInfoHeader>
        <SideInfoContentHeader>{level}</SideInfoContentHeader>
    </SideInfoWrapper>
}

export const WorkAuthorization = ({ code, country, startDate, endDate, isLastItem }: { code: string, country: string, startDate: string, endDate: string, isLastItem: boolean }) => {
    return <SideInfoWrapper isLastItem={isLastItem}>
        <SideInfoHeader>
            <Flag
                key={code}
                code={code}
                alt={country}
                title={country}
                style={{ marginRight: 10 }}
                width={"20"}
            />

            {country}
        </SideInfoHeader>
        {startDate.length > 0 && endDate.length > 0 && (
            <SideInfoContentHeader>{new Date(startDate).getFullYear().toString()} - {new Date(endDate).getFullYear().toString()}</SideInfoContentHeader>

        )}
    </SideInfoWrapper>
}

export const Award = ({ title, awardURL, date, isLastItem }: { title: string, awardURL: string, date: string, isLastItem: boolean }) => {
    return <SideInfoWrapper isLastItem={isLastItem}>
        <SideInfoHeader>
            <AwardIcon />
            {title}
        </SideInfoHeader>

        <a target="_blank" href={linkHandler(awardURL)}><SideInfoContentHeader><TextButton text={"Click to view"} /></SideInfoContentHeader></a>
        <SideInfoContentHeader>{date}</SideInfoContentHeader>
    </SideInfoWrapper>
}

