import styled from 'styled-components';
import { theme } from '../../../theme';

const CIRCLE_SIZE = '20px';

export const Wrapper = styled('div')<{backgroundColor?:string, color?:string}>`
  position: relative;
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  background: ${(props)=>props.backgroundColor ? props.backgroundColor : theme.colors.coral};
  color: ${(props)=>props.color ? props.color : theme.colors.black};
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xl};
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  border-radius: 100%;
  cursor: pointer;
  text-transform: lowercase;
`

export const TooltipWrapper = styled('div')<{isDesktop: boolean}>`
`

export const ContentBlock = styled('div')<{isPositionUnder:boolean, isPositionLeft?: boolean}>`
  position: absolute;
  width: 330px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: ${theme.variables.borderRadiusSmall};
  padding: ${theme.offsets.lg};
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  z-index: 999;
  ${(props)=>props.isPositionUnder ? 'top: 25px;' : 'bottom: 25px;'}
  text-transform: none;
  @media(max-width: ${theme.screens.mobile}) {
    width: 280px;
  };
  @media(max-width: ${theme.screens.mobileSmall}) {
    width: 230px;
  };
  ${props=>props.isPositionLeft &&`
    left: auto;
    right: 102%;
    transform: translate(0%, -40%);
    bottom: auto;
  `};
`

export const Title = styled('p')`
  font-size: 18px;
  color:${theme.colors.black};
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
`

export const Text = styled('span')`
  font-size: 14px;
  color:${theme.colors.black};
  text-align: left;
  font-weight: 400;
`
