import styled from "styled-components";
import { theme } from "../../../../../theme";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const DOTS_SIZE = '8px';

export const SliderWrapper = styled(Slider)<{autoplaySpeed: number}>`
@keyframes dotLoading {
  from {
    width: ${DOTS_SIZE};
  }
  to {
    width: 100%;
  }
}
  position: initial;
  .banner-dots{
    font-size: 0;
    width: fit-content;
    position: absolute;
    bottom: ${theme.offsets.extraBig};
    left: 50%;
    transform: translate(-50%, 0);
    li{
      display: inline;
      margin: 0 ${theme.offsets.xs};
    }
    li button{
      width: ${DOTS_SIZE};
      height: ${DOTS_SIZE};
      border-radius: ${theme.variables.borderRadiusXLarge};
      background: rgba(255, 255, 255, 0.3);
      border: none;
      padding: 0;
      position: relative;
    }
    li.slick-active button{
      width: 32px;
      ::before{
        content: '';
        display: block;
        animation-name: dotLoading;
        height: ${DOTS_SIZE};
        animation-duration: ${(props)=>props.autoplaySpeed}ms;
        animation-timing-function: linear; 
        animation-fill-mode: forwards;
        background-color: ${theme.colors.white};
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        border-radius: ${theme.variables.borderRadiusXLarge};
      }
    }
  }
`

export const Wrapper = styled('div')`
  background-color: ${theme.colors.blue};
  max-width: 664px; 
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: 435px; 
  }
`

export const SliderItem = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  color: ${theme.colors.white};
  text-align: center;
  margin: 0 auto 80px;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: 315px;
    svg{
    width: 90%;
    object-fit: contain;
  }
  }
`

export const LogoWrap = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 420px;
  margin: ${theme.offsets.xl} auto 0;
  @media(max-width: ${theme.screens.desktopXXL}) {
    max-width: 315px;
  }
`

export const LogoLink = styled(Link)`
display: block;
@media(max-width: ${theme.screens.desktopXXL}) {
svg{
  width: 128px;
}
}
`

export const SubTitle = styled('p')`
  font-weight: 400;
  font-size: ${theme.fontSizes.lg};
  margin-top: 56px;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.fontSizes.lg};
    margin-top: ${theme.offsets.major};
  }
`
export const Title = styled('p')`
  margin-top: ${theme.offsets.lg};
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.major};
  line-height: ${theme.fontSizes.extraMajor};
  position: relative;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: ${theme.fontSizes.extraBig};
    line-height: ${theme.fontSizes.major};
    margin-top: ${theme.offsets.sm};
  }
`

export const HelperWrap = styled('span')`
  position: absolute;
  bottom: ${theme.offsets.sm};
  margin-left: ${theme.offsets.sm};
`

export const Description = styled('p')`
  font-weight: 400;
  font-size: 18px;
  line-height: ${theme.fontSizes.xxl};
  margin-top: ${theme.offsets.extraBig};
  padding: 0 56px;
  @media(max-width: ${theme.screens.desktopXXL}) {
    font-size: 14px;
    line-height: 18px;
    margin-top: ${theme.offsets.lg};
  }
`
