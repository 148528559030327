import user from '../../../../assets/img/user.svg';
import setting from '../../../../assets/img/gearIcon.svg';
import { useTranslation } from 'react-i18next';
import { NavItem, Wrapper } from './AdminTabs.presents';

export const AdminTabs = () => {
	const { t } = useTranslation();

	const tabsList = [
		{ path: "profile", title: t('adminPanel.tabProfile'), img: user, },
		{ path: "settings", title: t('adminPanel.tabSettings'), img: setting },
	]
	return <Wrapper>
		{tabsList.map((item, index) => <NavItem
			key={index}
			activeClassName="active"
			to={item.path}>
			{item.title}
		</NavItem>)}
	</Wrapper>
}