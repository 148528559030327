import styled from "styled-components";
import { theme } from "../../../../theme";

const CIRCLE_SIZE = '1750px';

export const Wrapper = styled('div')`
	display: flex;
	flex: 1;
	height: 100%;
	position: relative;
	overflow: hidden;
`

export const ContentBox = styled('div')`
	padding: 0 ${theme.offsets.xl};
	max-width: 1720px;
	margin: 0 auto;
	width: 100%;
	display: flex;
  align-items: center;
	z-index: 5;
`

export const Content = styled('div')`
	display: flex;
	flex-direction: column;
	max-width: 420px;
`

export const Title = styled('p')`
	font-family: 'Recoleta';
	font-weight: 400;
	font-size: 56px;
`

export const Text = styled('p')`
	font-size: ${theme.fontSizes.xl};
	margin-top: ${theme.offsets.xxl};
`

export const BtnBlock = styled('div')`
	margin-top: ${theme.offsets.extraBig};
	display: flex;
	flex-direction: column;
	max-width: 215px;
	button{
		margin-bottom: ${theme.offsets.sm};
	}
`

export const CircleBlock = styled('div')`
	background: #F6F8FA;
	height: ${CIRCLE_SIZE};
	width: ${CIRCLE_SIZE};
	position: absolute;
	top: -40%;
	right: -45%;
	border-radius: 100%;
`

export const Banner = styled('img')`
	position: absolute;
	top: 50%; 
  right: 0;
	transform: translate(-10%,-50%);
	z-index: 2;
	height: 90%;
`