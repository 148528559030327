import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { NavButton, Wrapper } from "./SPBasePagination.presents";
import { ReactComponent as ArrowIcon } from "../../../../assets/img/arrowNextDark.svg";
import isMobile from "is-mobile";

export const SPBasePagination = (props: { listLength: number, itemsPerPage: number, callback: (activePage: number) => void  }) => {
  const { listLength, itemsPerPage, callback } = props;

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(listLength / itemsPerPage));
  }, [itemsPerPage]);

  const handlePageClick = (event: any) => {
    callback(event.selected);
  };

  const isMobileDevice = isMobile({ featureDetect: true })

  const pageRangeDisplayed = isMobileDevice ? 1 : 3;

  return (<Wrapper>
    <ReactPaginate
      breakLabel="..."
      nextLabel={<NavButton next><ArrowIcon /></NavButton>}
      onPageChange={handlePageClick}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel={<NavButton><ArrowIcon /></NavButton>}
      containerClassName='base-pagination-wrap'
      pageLinkClassName='nav-item-wrap'
    />
  </Wrapper>
  );
} 