import { ReactComponent as TravelingOpportunitiesIcon } from '../../../assets/img/organization/jobCard/travelingOpportunities.svg';
import { ReactComponent as LearningOpportunitiesIcon } from '../../../assets/img/organization/jobCard/learningOpportunities.svg';
import { ReactComponent as VacationOfferedIcon } from '../../../assets/img/organization/jobCard/vacationOffered.svg';
import { ReactComponent as RetirementSavingsOfferedIcon } from '../../../assets/img/organization/jobCard/retirementSavingsOffered.svg';
import { ReactComponent as HealthcareOfferedIcon } from '../../../assets/img/organization/jobCard/healthcareOffered.svg';
import { ReactComponent as HealthReimbursementIcon } from '../../../assets/img/organization/jobCard/healthReimbursement.svg';

export const BenefitSchema: any = {
    travel: {
      title: 'Traveling Opportunities',
      icon: <TravelingOpportunitiesIcon />,
      color: '#655588',
      bgColor: '#EFE9FE'
    },
    learning: {
      title: 'Learning Opportunities',
      icon: <LearningOpportunitiesIcon />,
      color: '#D774AA',
      bgColor: '#FFE8FF'
    },
    vacation: {
      title: 'Vacation and Paid Leave',
      icon: <VacationOfferedIcon />,
      color: '#187DFF',
      bgColor: '#EEF6FE'
    },
    retirement: {
      title: 'Retirement Savings',
      icon: <RetirementSavingsOfferedIcon />,
      color: '#E89314',
      bgColor: '#FFF3DB'
    },
    retirementUS: {
      title: 'Retirement Savings',
      icon: <RetirementSavingsOfferedIcon />,
      color: '#E89314',
      bgColor: '#FFF3DB'
    },
    healthcare: {
      title: 'Health Insurance',
      icon: <HealthcareOfferedIcon />,
      color: '#52A34B',
      bgColor: '#D5FAD6'
    },
    wellbeingPerks: {
      title: 'Well-being Perks & Benefits',
      icon: <HealthReimbursementIcon />,
      color: '#4ABCA6',
      bgColor: '#E8F8F5'
    }
  }