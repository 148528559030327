import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1840px;
  padding: ${theme.offsets.xxl} 120px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.md} 80px 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl} ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.lg};
  }
`

export const HeaderWrap = styled('div')<{isDesktop: boolean}>`
${(props)=>!props.isDesktop && `
    button, span{
      display: none;
    }
  ` }
`
export const SignUpMobileLink = styled('div')`
  font-size: 14px;
  color: ${theme.colors.darkGray};
  text-align: center;
  a {
    padding-left: 8px;
    font-weight: 500;
    color: ${theme.colors.blue};
  }
`

export const ContentWrap = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 544px;
  //padding: ${theme.offsets.xxl} 80px;
  width: 100%;
  form{
    width: 100%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 360px;
  }
`

export const Title = styled('p')`
  font-family: Recoleta;
  font-size: ${theme.fontSizes.major};
  font-weight: 500;
  margin-bottom: ${theme.offsets.xxl};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
  }
`

export const Separator = styled('div')`
  font-size: 18px;
  width: 100%;
  margin: ${theme.offsets.extraBig} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::before, ::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #D1D7E3;
    width: 45%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.xxl} 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} 0;
  }
`

export const ErrorText = styled('div')`
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 10px;
`

export const Subtitle = styled('p')`
  font-size: 18px;
  margin-bottom: ${theme.offsets.major};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin-bottom: ${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-bottom: ${theme.offsets.xxl};
  }
`
export const PolicyText = styled('p')`
  margin: ${theme.offsets.lg} 0;
  font-size: 14px;
  color: ${theme.colors.darkGray};
  width: 70%;
  text-align: center;
  a{
    color: ${theme.colors.blue};
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 80%;
    font-size: ${theme.fontSizes.md};
    a{
      font-size: ${theme.fontSizes.md};
    }
  }
`
