import { PropsType } from "./Header.props";
import {
  StyleForHeader,
  StyleForUserWrap,
  StyleForLogo,
  Avatar,
  BtnsWrap,
  AvatarWrap,
  UserName,
  ArrowIcon,
  BtnContainer,
  HelperText,
  PreviewBtnContainer,
  HelperBlockWrap
} from './Header.presents';
import { ReactComponent as LogoIcon } from '../../../../assets/img/logo.svg';
import arrowIcon from '../../../../assets/img/arrow.svg';
import avatarIcon from '../../../../assets/img/avatar.svg';
import { useRef, useState } from "react";
import { UserMenu } from "../AdminHeader/Desktop/UserMenu";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SPSecondaryButton } from "../../buttons/SPSecondaryButton/SPSecondaryButton";
import { useRootStore } from "../../../../state/stores/storeContext";
import useOnScreen from "../../../../hooks/useOnScreen";
import { SPLightButton } from "../../buttons/SPLightButton/SPLightButton";
import { ReactComponent as PublicView } from '../../../../assets/img/public-view.svg';

export const Header = (props: PropsType) => {
  const { userStore } = useRootStore();
  const history = useHistory();
  const { t } = useTranslation();

  const [isUserMenuShown, setIsUserMenuShown] = useState(false);

  const avatar = userStore?.logo?.url || avatarIcon;
  const avatarRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(avatarRef);

  const isMenuShownOnScreen = () => {
    return isVisible ? isUserMenuShown : false
  }

  const goBack = () => {
    history.push('/my-organization')
  }

  const openPublicOrganizationPreview = () => {
    window.open(`/organization/${props.publicPreviewUrl}`, "_blank");
  }

  const showUserMenu = !!props.isAdminView && props.isDesktop;

  return <StyleForHeader isDesktop={props.isDesktop}>
    {props.isAdminView? <HelperBlockWrap>
      <BtnContainer isDesktop={props.isDesktop}>
        <SPSecondaryButton isLight onClick={goBack} width={'100%'}>
          Back
        </SPSecondaryButton>
      </BtnContainer>
      <HelperText>
        You are viewing this page as an admin user
      </HelperText>
    </HelperBlockWrap>
    :
    <StyleForLogo isAdminView={props.isAdminView} isDesktop={props.isDesktop}>
      <a href={'https://sidepost.com/'}>
        <LogoIcon />
      </a>
    </StyleForLogo>
    }

    

    <div className="d-flex">
      {props.isAdminView && <PreviewBtnContainer>
        <SPLightButton disabled={!props.publicPreviewUrl} onClick={openPublicOrganizationPreview} width={'auto'}>
          <PublicView />
          Public View
        </SPLightButton>
      </PreviewBtnContainer>}

      <BtnsWrap isShow={showUserMenu}>
        <StyleForUserWrap onClick={() => setIsUserMenuShown(!isUserMenuShown)} onBlur={() => setIsUserMenuShown(false)}>
          <AvatarWrap ref={avatarRef}>
            <Avatar
              src={avatar}
              alt="Avatar" />
            <UserName>{userStore.firstName + ' ' + userStore.lastName}</UserName>
            <ArrowIcon src={arrowIcon} alt="V" isOpen={isUserMenuShown} />
          </AvatarWrap>
          <UserMenu show={isMenuShownOnScreen()} />
        </StyleForUserWrap>
      </BtnsWrap>
    </div>

  </StyleForHeader>
}