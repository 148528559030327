import { Formik } from "formik"
import { StyleForPhoneInput, StyleForLabel, FormWrap } from "./Profile.presents"
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { PropsType } from './Profile.props'
import { Text } from "../../common/Text";
import { Button } from "../../common/Button";
import { Container, Row } from "react-bootstrap";
import { Avatar } from "../../common/Avatar";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useRootStore } from "../../../state/stores/storeContext";
import { observer } from "mobx-react-lite";
import avatarIcon from '../../../assets/img/avatar.svg'
import { useTranslation } from "react-i18next"
import { ModalForResults } from "../../common/Modal/ModalForResults";
import { Wrapper, ButtonWrap, FieldWrap } from "../AdminPanel.presents";
import { Input } from "../../common/Input";
import { checkFileSizeInMB } from "../../../services/helper";
import { generateSignedUrl, s3upload } from "../../../services/api/fileManagement/fileManagement.domains";
import { userNameValidation } from "../../../services/validationSchemas";

export const Profile = observer((props: PropsType) => {
  const { userStore } = useRootStore()
  const [inputClass, setInputClass] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(userStore.phone)
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');
  const { t } = useTranslation();

  const MAX_LOGO_SIZE = 25;
  const UPLOAD_FOLDER = 'images'

  useEffect(() => {
    setPhoneNumber(userStore.phone)
  }, [userStore.phone]);

  const initialValues = {
    firstName: userStore.firstName,
    lastName: userStore.lastName,
    email: userStore.email,
    position: userStore.position,
    phone: phoneNumber,
    logo: userStore.logo || {
      url: "",
      filename: "",
      id: ""
    }
  }

  
  const uploadUserLogo = async (event: any) => {
    if (!event.target.files) return

    let uploadFile = event.target.files[0];
    if (!checkFileSizeInMB(uploadFile.size, MAX_LOGO_SIZE)) {
      event.target.value = null;
      return alert(`Maximum logo size is ${MAX_LOGO_SIZE} megabytes`)
    }
    const resp = await generateSignedUrl({ filename: uploadFile.name, filetype: uploadFile.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, uploadFile)
        const addLogoDto = {
          logo: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            fileName: uploadFile.name.replace(/\.[^/.]+$/, ""),
          }
        }
        await userStore.saveSingleFile(addLogoDto)
        userStore.setLogo(resp.data.data.key, resp.data.data.fileUrl)
      } catch (error) {
        console.log(error)
      }
    }
    event.target.value = null; // reset input value
  }

  const deleteUserLogo = async () => {
    const removeFileDto = {
      dataForUpdate: {
        logo: null
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: userStore.logo?.key
    }

    await userStore.deleteSingleFile(removeFileDto);
    userStore.setLogo('', '')
  }

  const onSubmit = async (values: any) => {
    const serverResponse = await userStore.updateUserData({
      action: 'updateMainInfo',
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: phoneNumber,
      position: values.position,
    })
    setResponseResult(serverResponse.err ? 'error' : 'successfully');
    setModalOpen(true);
  }

  return userStore.isAuthorizated ? <Wrapper isDesktop={props.isDesktop} >
    <Container fluid className="my-auto">
      <Row className="justify-content-center">
        <FormWrap isDesktop={props.isDesktop}>
          <Avatar
            url={userStore.logo?.url}
            placeholder={avatarIcon}
            onUpload={uploadUserLogo}
            onDelete={deleteUserLogo}
            btnText={'profile picture'}
          />

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validate={values => {
              const errors: any = {}
              if (!isValidPhoneNumber(phoneNumber)) {
                setInputClass('error')
                errors.phone = t('validation.phone');
              } else {
                setInputClass('')
              }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              firstName: userNameValidation(t),
              lastName: userNameValidation(t),
              email: Yup.string().email(t('validation.email')).required(t('validation.required')),
            })}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} className='d-flex flex-column'>
                <FieldWrap
                  as={Input}
                  name={'firstName'}
                  type={'text'}
                  value={values.firstName}
                  onChange={handleChange}
                  labelText={t('label.firstName')}
                  error={errors.firstName}
                  maxLength={50}
                />
                <Text text={errors.firstName ? `${errors.firstName}` : ''} error />
                <FieldWrap
                  as={Input}
                  name={'lastName'}
                  type={'text'}
                  value={values.lastName}
                  onChange={handleChange}
                  labelText={t('label.lastName')}
                  error={errors.lastName}
                  maxLength={50}
                />
                <Text text={errors.lastName ? `${errors.lastName}` : ''} error />
                <FieldWrap
                  as={Input}
                  name={'email'}
                  type={'text'}
                  value={values.email}
                  onChange={handleChange}
                  labelText={t('label.emailMain')}
                  error={errors.email}
                  disabled
                />
                <Text text={errors.email ? `${errors.email}` : ''} error />
                <StyleForLabel htmlFor="phone">{t('label.phone')}</StyleForLabel>
                <StyleForPhoneInput
                  className={inputClass}
                  name={'phone'}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  international
                  defaultCountry="US"
                  isDesktop={props.isDesktop}
                  disabled
                />
                <Text text={errors.phone ? `${errors.phone}` : ''} error />
                <FieldWrap
                  as={Input}
                  name={'position'}
                  type={'text'}
                  value={values.position}
                  onChange={handleChange}
                  labelText={t('label.position')}
                  error={errors.position}
                  maxLength={50}
                />
                <Text text={errors.position ? errors.position : ''} error />
                <ButtonWrap>
                  <Button type={'submit'} buttonText={t('adminPanel.save')} styled={'warning'} />
                </ButtonWrap>
              </form>
            )}
          </Formik>
        </FormWrap>
      </Row>
    </Container>
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      text={responseResult === 'successfully' ? 'Your changes have been successfully saved!' : 'Your changes haven`t been updated!'}
    />
  </Wrapper> : <div></div>
})