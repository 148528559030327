import styled from "styled-components"
import { BaseButton } from "../SPBaseButton/SPBaseButton.presets"
import { theme } from "../../../../theme"

export const PrimaryButton = styled(BaseButton)<{isLoading?: boolean}>`
  background-color: ${theme.colors.blue};
  border-color: ${theme.colors.blue};
  color: ${theme.colors.white};

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${theme.colors.darkBlue};
      border-color: ${theme.colors.darkBlue};
    }
  }

  :disabled {
    cursor: not-allowed;
    color: ${theme.colors.mediumGray};
    background: ${theme.colors.darkBlue};
    border-color: ${theme.colors.darkBlue};
  }

  :focus {
    /* border: 2px outset black; */
  }

  @keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
${props=>props.isLoading && `
    svg{
      animation-name: spin;
      animation-duration: 3500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  `}
`
