import { Input } from "../../../common/Input";
import { PropsType } from "./SalaryField.props";
import { FieldProps } from 'formik';
import { useEffect } from "react";

export const SalaryField: React.SFC<PropsType & FieldProps> = ({
  field,
  form,
  ...props
}) => {

  const isTouchedToMaxField = form.getFieldMeta(props.fieldNameTo).touched;

  useEffect(() => {
    const isValueHireThanMax = props.maxValue < field.value;
    const canChangeValue = isTouchedToMaxField && props.maxValue && field.value && isValueHireThanMax;
    if (canChangeValue) {
      form.setFieldValue(field.name, props.maxValue);
    }
  }, [props.maxValue, isTouchedToMaxField])

  const setValue = (evant: any) => {
    const isValueLessThanMax = props.maxValue < evant.target.value;
    const value = props.maxValue && isValueLessThanMax ? props.maxValue : evant.target.value;
    form.setFieldValue(field.name, value);
  }

  return <Input
    onChange={setValue}
    value={field.value}
    name={props.name}
    labelText={props.labelText}
    placeholder={props.placeholder}
    type='number'
    maxValue={props.maxValue}
    error={props.error}
    w={props.w} //width TODO: remove after refactoring
  />
}