import { useRef, useState } from "react";
import useGetDevice from "../../../hooks/useGetDevice";
import { Menu } from "../../common/Menu/Menu";
import { ModalForResults } from "../../common/Modal/ModalForResults";
import { PasswordForm } from "./PasswordForm";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { Container, FormsWrap } from "./Profile.presents";

export const CandidateProfile = () => {
  const isDesktop = useGetDevice();
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');

  const [responseResultText, setResponseResultText] = useState('');

  const componentRef: any = {
    personalInformation: useRef(null),
    password: useRef(null)
  };

  const menuList = [
    { title: 'Account Information', key: 'personalInformation' },
    { title: 'Password', key: 'password' }
  ]

  const menuProps = { componentRef, isDesktop: isDesktop, menuList };
  return <Container isDesktop={isDesktop}>
    <Menu {...menuProps} />
    <FormsWrap isDesktop={isDesktop}>
      <PersonalInfoForm isDesktop={isDesktop} setResponseResultText={setResponseResultText} setResponseResult={setResponseResult} setModalOpen={setModalOpen} componentRef={componentRef.personalInformation} />
      <PasswordForm isDesktop={isDesktop} setResponseResult={setResponseResult} setModalOpen={setModalOpen} componentRef={componentRef.password} setResponseResultText={setResponseResultText}/>
    </FormsWrap>
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      setAdditionalText={setResponseResultText}
      text={responseResult === 'successfully' ? 'Your changes have been successfully saved!' : 'Your changes haven`t been updated!'}
      additionalText={responseResultText}
    />
  </Container>
}