import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { JobStoryCard } from '../../../common/Cards/JobStoryCard';
import { SnapshotsIcons } from './Snapshots.icons';
import { SnapshotItem, SnapshotItemWrap, SnapshotImg, SnapshotRow, SnapshotContainer } from './Snapshots.presents';
import { PropsType } from './Snapshots.props';

export const Snapshots = observer(({ jobData, componentRef }: PropsType) => {
  const { t } = useTranslation();

  const componentMap = [
    {
      iconName: jobData.startStep.jobKind,
      text: t(`jobStory.jobKind.${jobData.startStep.jobKind}`)
    },
    {
      iconName: jobData.startStep.doneRemotely === 'yes' ? 'yesRemote' : jobData.startStep.jobKind,
      text: jobData.startStep.doneRemotely !== 'no' ? t(`jobStory.doneRemotely.${jobData.startStep.doneRemotely}`) : ''
    },
    {
      iconName: jobData.jobStep.sideJob === 'yes' ? 'sideJob' : null,
      text: jobData.jobStep.sideJob === 'yes' ? t(`jobStory.sideJob.${jobData.jobStep.sideJob}`) : null
    },
    {
      iconName: jobData.jobStep.customerFacing === 'yes' ? 'customerFacing' : null,
      text: jobData.jobStep.customerFacing === 'yes' ? t(`jobStory.customerFacing.${jobData.jobStep.customerFacing}`) : null
    },
    {
      iconName: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('international') ? 'international' : null,
      text: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('international') ? t(`jobStory.traveling.international`) : null
    },
    {
      iconName: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('domestic') ? 'domestic' : null,
      text: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('domestic') ? t(`jobStory.traveling.domestic`) : null
    },
    {
      iconName: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('short') ? 'short' : null,
      text: jobData.jobStep.traveling && jobData.jobStep.traveling.includes('short') ? t(`jobStory.traveling.short`) : null
    },
    {
      iconName: jobData.jobStep.relocationPack && jobData.jobStep.relocationPack !== 'no' ? `pack${jobData.jobStep.relocationPack}` : null,
      text: jobData.jobStep.relocationPack && jobData.jobStep.relocationPack !== 'no' ? t(`jobStory.relocationPack.${jobData.jobStep.relocationPack}`) : null
    },
    {
      iconName: jobData.jobStep.workVisa !== 'no' ? 'workVisa' : null,
      text: jobData.jobStep.workVisa !== 'no' ? t(`jobStory.workVisa.${jobData.jobStep.workVisa}`) : null
    },
    {
      iconName: jobData.descriptionStep.startup !== 'no' ? 'startup' : null,
      text: jobData.descriptionStep.startup !== 'no' ? t(`jobStory.startup.${jobData.descriptionStep.startup}`) : null
    },
    {
      iconName: jobData.specificationStep.overtimeIsIncluded && jobData.specificationStep.overtimeIsIncluded.includes('include') ? 'overtimeIsIncluded' : null,
      text: jobData.specificationStep.overtimeIsIncluded && jobData.specificationStep.overtimeIsIncluded.includes('include') ? t(`jobStory.overtimeIsIncluded.included`) : null
    },
    {
      iconName: jobData.jobStep.canDoneOver.length > 0 && jobData.jobStep.canDoneOver.includes('weekends') ? 'canDoneOverWeekends' : null,
      text: jobData.jobStep.canDoneOver.length > 0 && jobData.jobStep.canDoneOver.includes('weekends') ? t(`jobStory.canDoneOver.weekends`) : null
    },
    {
      iconName: jobData.jobStep.canDoneOver.length > 0 && jobData.jobStep.canDoneOver.includes('businessHours') ? 'canDoneOverbusinessHours' : null,
      text: jobData.jobStep.canDoneOver.length > 0 && jobData.jobStep.canDoneOver.includes('businessHours') ? t(`jobStory.canDoneOver.businessHours`) : null
    },
    {
      iconName: jobData.specificationStep.salaryRateFrom ? 'salaryRate' : null,
      text: jobData.specificationStep.salaryRateFrom ? t(`jobStory.aboutMoney.salaryRate`) : null
    },
    {
      iconName: jobData.specificationStep.hourlyRateFrom ? 'hourlyRate' : null,
      text: jobData.specificationStep.hourlyRateFrom ? t(`jobStory.aboutMoney.hourlyRate`) : null
    },
    {
      iconName: jobData.specificationStep.commisionRateFrom ? 'commisionRate' : null,
      text: jobData.specificationStep.commisionRateFrom ? t(`jobStory.aboutMoney.commisionRate`) : null
    },
    {
      iconName: jobData.specificationStep.internshipType ? jobData.specificationStep.internshipType : null,
      text: jobData.specificationStep.internshipType ? t(`jobStory.internshipType.${jobData.specificationStep.internshipType}`) : null
    },
    {
      iconName: jobData.specificationStep.equityIncluded && jobData.specificationStep.equityIncluded === 'yesEquity' ? jobData.specificationStep.equityIncluded : null,
      text: jobData.specificationStep.equityIncluded && jobData.specificationStep.equityIncluded === 'yesEquity' ? t(`jobStory.equityIncluded.${jobData.specificationStep.equityIncluded}`) : null
    },
    {
      iconName: jobData.specificationStep.fixedBudget && jobData.specificationStep.fixedBudget === 'fixedBudgetYes' ? jobData.specificationStep.fixedBudget : null,
      text: jobData.specificationStep.fixedBudget && jobData.specificationStep.fixedBudget === 'fixedBudgetYes' ? t(`jobStory.fixedBudget.${jobData.specificationStep.fixedBudget}`) : null
    },
  ]

  return <JobStoryCard title='Snapshot' componentRef={componentRef}>
    <SnapshotContainer fluid>
      <SnapshotRow>
        {
          componentMap.map(item => item.text && item.iconName && SnapshotsIcons[item.iconName] ? <SnapshotItemWrap xl={3} sm={4} xs={12} >
            <SnapshotItem>
              <SnapshotImg src={SnapshotsIcons[item.iconName]} alt="img" />
              {item.text}
            </SnapshotItem>
          </SnapshotItemWrap> : null)
        }
      </SnapshotRow>
    </SnapshotContainer>
  </JobStoryCard>
})