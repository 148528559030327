export enum WindowContentType {
  countries,
  languages
}

export type initialValuesType = {
  jobKind: string,
  jobTime: Array<string> | [],
  workShift: Array<string> | [],
  includesOvertime: string,
  jobTraveling: string,
  jobType: Array<string> | [],
  workplaceBenefits: Array<string> | [],
  preferenceCountry: Array<string> | [],
  jobStart: string,
  languages: Array<string> | [],
  levelSkills: Array<string> | [],
  middlewareValue: Array<string> | []
}

export type WindowType = {
  closeWindow: Function,
  isWindowOpen: boolean,
  isPanel?: boolean,
  windowContent: WindowContentType,
  setFieldValue: Function
  middlewareValue: Array<string> | []
  formValues: initialValuesType
  updateListCallback: Function
}