import closeIcon from '../../../../../assets/img/close.svg'
import videoIcon from '../../../../../assets/img/audioFile.svg'
import audioIcon from '../../../../../assets/img/audioSmall.svg'

import spinnerIcon from '../../../../../assets/img/spinner.svg'
import { FileContainer, FileLabel, Spinner, Media, MediaName, MediaIcon, RemoveIcon } from './VideoPreview.presets'

interface VideoPreviewProps {
  name: string
  type: string
  onRemove: any
  isLoading?: boolean
}

export const VideoPreview = (props: VideoPreviewProps) => {

  const icon = props.type === "audio" ? audioIcon : videoIcon

  return (
    <FileContainer>
      <FileLabel>File</FileLabel>

      <Media>
        {
          props.isLoading
            ? <Spinner src={ spinnerIcon } />
            : <MediaIcon src={icon}/>
        }
        <MediaName>{props.name}</MediaName>
        {
          !props.isLoading 
            ? <RemoveIcon type="button" onClick={props.onRemove}><img src={closeIcon} alt=""/></RemoveIcon>
            : null 
        }
      </Media>

    </FileContainer>
  )
}
