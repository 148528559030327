import styled from "styled-components";
import { theme } from "../../../../theme";

const CARD_SIZE_LARGE = '137px';
const CARD_SIZE_MEDIUM = '125px';
const CARD_SIZE_SMALL = '88px';

const ICON_SIZE_LARGE = '48px';
const ICON_SIZE_MEDIUM = '33px';
const ICON_SIZE_SMALL = '27px';

const StyleForButton = `
  margin: 40px auto 0; 
  min-width: 186px;
  min-height: 52px;
  background: #F8DDC3;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  border-color: transparent;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    background: #EA994B;
  }
  img {
    margin-left: 30px;
    width: 18px;
    height: 18px;
  }
`

const StyleForCard = `
  height: ${CARD_SIZE_LARGE};
  width: ${CARD_SIZE_LARGE};
  border: 1px solid ${theme.colors.lightGray};
  background: #F6F8FA;
  border-radius: ${theme.variables.borderRadiusXLarge};
  font-size: 14px;
  color: #91959D;
  flex-direction: column-reverse;
  margin: ${theme.offsets.md};
  @media(max-width: ${theme.screens.tablet}) {
    margin: ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin: 2px
  }
  img{
    width: ${ICON_SIZE_LARGE};
    height: ${ICON_SIZE_LARGE};
    margin-bottom: ${theme.offsets.lg};
  }
  &:hover {
    background: #ebf0f4;
  }
`

export const UploadButtonContainer = styled('button') <{ showButton: boolean, isDesktop: boolean }>`
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${(props) => props.showButton && StyleForButton}
  ${(props) => !props.showButton && StyleForCard}

  ${(props)=>!props.showButton && !props.isDesktop && `
    height: ${CARD_SIZE_MEDIUM};
    width: ${CARD_SIZE_MEDIUM};
    border-radius: ${theme.variables.borderRadiusLarge};
    font-size: ${theme.fontSizes.md};
    img{
      width: ${ICON_SIZE_MEDIUM};
      height: ${ICON_SIZE_MEDIUM};
      margin-bottom: ${theme.offsets.md};
    }
    @media(max-width: ${theme.screens.mobile}) {
      height: ${CARD_SIZE_SMALL};
      width: ${CARD_SIZE_SMALL};
      border-radius: ${theme.variables.borderRadiusSmallMedium};
      img{
        width: ${ICON_SIZE_SMALL};
        height: ${ICON_SIZE_SMALL};
        margin-bottom: ${theme.offsets.sm};
      }
    }   
  `}
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
  }
  input[type=file]::-webkit-file-upload-button {
    display: none;
}
`
