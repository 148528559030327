import styled from 'styled-components'

export const PageContainer = styled('div')` 
  min-width: 1140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`

export const StepsPreloader = styled('div')` 
  display: flex;
  align-items: center;
  flex: 1;
  img{
    width: 100%;
    max-width: 150px;
  }
`