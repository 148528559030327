import { PropsType } from './Checkbox.props'
import { StyleForWrap } from './Checkbox.presents'

export const Checkbox = (props: PropsType) => {
  // TODO: refactoring to checkbox
  const changeCheckedStatus = (value: any) => {
    props.handleChange(value)
  }

  const handler = async () => {
    if (props.clickCallback) {
      props.clickCallback()
    }
    if (props.type === 'radio' && props.setFieldValue && props.checked) {
      props.setFieldValue(props.name, '');
    }
  }
  return <StyleForWrap
    className={props.className}
    block={props.block}
    disabled={props.disabled}
    m={props.m}
    onClick={handler}>
    <input disabled={props.disabled} type={props.type ? props.type : 'checkbox'} name={props.name} value={props.value} onChange={changeCheckedStatus} checked={props.checked} />
    <label className="container">{props.labelText}
      <span className="checkmark"></span>
    </label>
  </StyleForWrap >
}