import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components'
import { theme } from '../../../../../theme';

const SIDEBAR_MAX_WIDTH = "367px";

export const SidebarContainer = styled('div')`
  flex: 1;
  max-width: ${SIDEBAR_MAX_WIDTH};
  padding: ${theme.offsets.md};
  padding-top: ${theme.offsets.lg};
  background: ${theme.colors.blueGray};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const MenuList = styled('div')`
  margin: 80px -${theme.offsets.md} 0;
`

export const StyleForSidebarLink = styled(NavLink)` 
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${theme.colors.white};
  padding: 32px 20px 32px 0px;
  display: flex;
  align-items: center;
  padding-left: 25%;
  transition:.3s ease-in-out;

  &:hover {
    color: ${theme.colors.white};
    background: rgba(255, 255, 255, 0.15);
  }

  &.active{
    background: rgba(255, 255, 255, 0.15);
  }

  &:hover.active {
    color: ${theme.colors.white};
    background: rgba(255, 255, 255, 0.2);
  }
  
  img {
    margin-right: ${theme.offsets.md};
  }
`

export const LogoWrap = styled(Link)`
  display: flex;
  align-self: flex-start;
  padding-left: 25%;
`

export const ContentWrap = styled('div')`
  position: sticky;
  top: ${theme.offsets.lg};
`;
