import { colors } from '../../../../constants/colors';
import styled from 'styled-components'

export const StyleForWrap = styled('div')`
  width: 606px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .commisionRate{
    position: relative;
  }
  .input-rate{
    margin-top: 24px;
  }
  .commisionRate::before{
    content:'%';
    position: absolute;
    right: 16px;
    bottom: 14px;
    z-index: 9;
}
  }
  label .optional{
    color: #928D8A;
  }
  .selectWrap{
    width: 81px;
    position: absolute;
    bottom: 0px;
    right: 5%;
  }
  .selectWrap.second{
    right:0;
  }
  .selectWrap.midd{
    right: 2.5%;
  }
  .selectWrap .cl-select__control{
    border: none!important;
    background: transparent;
    border-left:1px solid #D1D7E3!important;
    border-radius:0;
    height: 36px;
  }
  .selectWrap .cl-select__menu{
    z-index:10;
  }
  .long-select{
    width: 95%;
    margin-left: auto;
  }
  .midd-select .d-flex{
    align-items: center;
  }
`

export const StyleForLabel = styled("label")`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:8px;
  text-transform: capitalize;
  width: 100%;
  color: ${colors.dark};
`
