import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1840px;
  padding: ${theme.offsets.xxl} 120px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.md} 80px 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.xxl} ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.lg};
  }
`

export const HeaderWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
`

export const SignUpMobileLink = styled('div')`
  font-size: 14px;
  color: ${theme.colors.darkGray};
  text-align: center;
  a {
    padding-left: 8px;
    font-weight: 500;
    color: ${theme.colors.blue};
  }
`

export const ContentWrap = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 828px;
  width: 100%;
  form{
    width: 100%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 738px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 596px;
  }
`

export const Title = styled('p')`
  font-family: Recoleta;
  font-size: ${theme.fontSizes.major};
  font-weight: 500;
  margin-bottom: ${theme.offsets.xxl};
  text-align: center;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.extraBig};
    margin-bottom: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.big};
  }
`

export const Separator = styled('div')`
  font-size: 18px;
  width: 100%;
  margin: ${theme.offsets.extraBig} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::before, ::after{
    content: '';
    display: block;
    height: 1px;
    background-color: #D1D7E3;
    width: 45%;
  }
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    margin: ${theme.offsets.xxl} 0;
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin: ${theme.offsets.lg} 0;
  }
`

export const ErrorText = styled('div')`
  height: 12px;
  margin: ${theme.offsets.xs} 0 ${theme.offsets.sm};
  color: ${theme.colors.red};
  font-size: 10px;
`

export const Subtitle = styled('p')`
  font-family: Recoleta;
  font-weight: 500;
  font-size: ${theme.fontSizes.big};
  margin-bottom: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.xl};
  }
`

export const AddMoreButton = styled('div') <{ isPanel?: boolean }>`
${props=> props.isPanel ?`
  color: ${theme.colors.lightOrange};
  font-weight: 500;
  margin-top: ${theme.offsets.lg};
  cursor: pointer;
` :`
  font-family: Apercu Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #187DFF;
  cursor: pointer;
  margin-top: 24px;
`} 
`

export const SubtitleAdvice = styled('p')`
  color: ${theme.colors.gray};
  font-size: 14px;
  margin-bottom: ${theme.offsets.lg};
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.fontSizes.md};
  }
`

export const PolicyText = styled('p')`
  margin: ${theme.offsets.lg} 0;
  font-size: 14px;
  color: ${theme.colors.darkGray};
  width: 70%;
  text-align: center;
  a{
    color: ${theme.colors.blue};
  }
  @media(max-width: ${theme.screens.tablet}) {
    width: 80%;
    font-size: ${theme.fontSizes.md};
    a{
      font-size: ${theme.fontSizes.md};
    }
  }
`

export const FormWrap = styled('div')`
  min-height: 628px;
  background: #FFFFFF;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: ${theme.variables.borderRadiusXLarge};
  width: 100%;
  padding: ${theme.offsets.major} ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktopXXL}) {
    min-height: 528px;
  } 
  @media(max-width: ${theme.screens.desktop}) {
    min-height: 412px;
  } 
  @media(max-width: ${theme.screens.tablet}) {
    min-height: 632px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    min-height: 269px;
  }
`

export const FieldsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: -${theme.offsets.sm};
  @media(max-width: ${theme.screens.desktop}) {
    margin: -${theme.offsets.xs};
  }
`

export const ExpertiseFieldWrap = styled('div') <{ isPanel?: boolean }>`
 position: relative;
  .cl-select__menu .cl-select__menu-list .cl-select__option--is-focused {
    color: ${props => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
  }
  .cl-select__menu .cl-select__menu-list .cl-select__option--is-selected {
    color: ${props => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
  }

  .cl-select__menu .cl-select__menu-list .cl-select__option--is-disabled{
    color: ${props => props.isPanel ? theme.colors.lightOrange : theme.colors.blue};
  } 
 
`

export const SelectFieldWrap = styled('div') <{ isPanel?: boolean }>`
  width: 70%;
`

export const ButtonWrap = styled('div')`
  margin-top: ${theme.offsets.extraBig};
  display: flex;
  justify-content: space-between;
`

export const Divider = styled('div')`
  height: 1px;
  width: 100%;
  background: #D9D9D9;
  margin: ${theme.offsets.extraBig} 0;
`

export const RemoveIcon = styled('button')`
  position: absolute;
  top: 20px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 12px;
    height: 12px;
  }
`