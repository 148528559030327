import styled from 'styled-components';

export const SpinnerWrap = styled("div")<{isFullScreen?: boolean}>`
  @keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
  width: ${(props=>props.isFullScreen ? "100vw" : "100%")};
  height: ${(props=>props.isFullScreen ? "100vh" : "100%")};;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    margin: 0 auto;
    animation-name: spin;
    animation-duration: 3500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    z-index:100;
    
    ${(props=>props.isFullScreen && `
      width: 80px;
      object-fit: contain;
    `)};
  }
`

