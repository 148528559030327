import { Link } from 'react-router-dom';
import { theme } from './../../../../theme/theme';
import styled from 'styled-components';
import { Form, Field } from 'formik';
import PhoneInput from 'react-phone-number-input';
import arrowIcon from '../../../../assets/img/arrow.svg';
import plusIcon from '../../../../assets/img/plusBlack.svg';
import { Row } from 'react-bootstrap';

export const StyleForReportModalWrap = styled('div')`
  button{
    align-self: end;
    width: 220px;
    margin-top: ${theme.offsets.major};
  }
`

export const FieldsWrap = styled('div')`
  width: 544px;
  display: flex;
  flex-direction: column;
  margin: 48px auto 0;
`

export const Separator = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  margin: ${theme.offsets.xl} 0 ${theme.offsets.major};
  &::before, &::after{
    content: '';
    display: block;
    width: 46%;
    height: 1px;
    background: ${theme.colors.lightGray};
  }
`

export const CloseBtnBlock = styled('div')`
  padding: ${theme.offsets.xl};
  border: 1px solid ${theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: ${theme.variables.borderRadiusMedium};
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  line-height: ${theme.fontSizes.xxl};
  color: ${theme.colors.darkGray};
  max-width: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media(max-width: ${theme.screens.tablet}) {
    max-width: min-content;
    border-radius: 100%;
    margin-left: ${theme.offsets.lg};
    span{
      display: none;
    }
    img{
    margin-right: 0;
    }
  }
  img{
    margin-right: ${theme.offsets.md};
    @media(max-width: ${theme.screens.tablet}) {
      margin-right: 0;
    }
  }
`

export const ApplyForm = styled(Form)`
  max-width: 544px;
  width: 100%;
  margin: ${theme.offsets.extraMajor} auto 0;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 358px;
  }
`

export const PhoneField = styled(PhoneInput)`
input { 
  width: 75%;
  border: 1px solid ${theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  height: 52px;
  padding: ${theme.offsets.lg};
  margin-bottom: 8px;
  background-color: #fff!important;
  color: ${theme.colors.darkGray};
  font-size: 16px;
};
.PhoneInputCountry {
  width: 20%;
  border: 1px solid ${theme.colors.lightGray};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 18px ${theme.offsets.lg};
  justify-content: space-between;
  margin-bottom: ${theme.offsets.extraBig};
  margin-right: 30px;
  @media(max-width: ${theme.screens.desktop}) {
      padding: ${theme.offsets.sm};
      margin-right: ${theme.offsets.md};
      width: 23%;
    }
  } 
.PhoneInputCountrySelectArrow{
  border:none;
  content: url(${arrowIcon});
  width: 16px;
  height: 9px;
  transform: unset;
}
&.error input, &.error .PhoneInputCountry {
    border: 1px solid ${theme.colors.lightRed};
  }
`
export const SocialRow = styled(Row)`
  margin-right: -15px;
  margin-left: -15px;
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.desktop}) {
    margin-bottom: 0;
    & input{
      margin-top: ${theme.offsets.xxl};
    }
  }
`

export const VisaWrap = styled('div')`
  margin: ${theme.offsets.xxl} 0 ${theme.offsets.extraBig};
`

export const VisaTitle = styled('p')`
  color: ${theme.colors.darkGray};
  margin: ${theme.offsets.lg} 0;
  font-size: ${theme.fontSizes.lg};
  `
export const VisaBlock = styled('div')`
  display: flex;
  position: relative;
  margin-top: ${theme.offsets.lg};
`
export const VisaLabel = styled('label')`
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.colors.darkGray};
  padding-left: ${theme.offsets.extraBig};
  cursor: pointer;`

export const VisaField = styled(Field)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    border: 2px solid ${theme.colors.blue};
  }
  &:checked ~ span:after {
    display: block;
  }
`
export const Checkmark = styled('span') <{ error?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  border: 2px solid ${(props) => props.error ? theme.colors.lightRed : theme.colors.lightGray};
&:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${theme.colors.blue};
    
  }
  `
export const LogoWrap = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.offsets.xl};
`

export const Logo = styled('img')`
  width: 64px;
  height: 64px;
  border-radius: ${theme.variables.borderRadiusSmall};
  margin-right: ${theme.offsets.lg};
  object-fit: cover;
`

export const OrganizationName = styled('p')`
  font-size: ${theme.fontSizes.lg};
  color: rgba(63, 71, 79, 0.8);
`

export const JobTitle = styled('p')`
  font-size: ${theme.fontSizes.xxl};
`

export const InputField = styled(Field)`
& label{
  margin-top: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.sm};
  }
}
`

export const PhoneFieldWrap = styled(Field)`
& .PhoneInputCountry, & input {
  margin-top: ${theme.offsets.lg};
  margin-bottom: 0;
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.sm};
  }
}
`

export const PolicyText = styled('p')`
  font-size: ${theme.fontSizes.lg};
  margin-bottom: ${theme.offsets.lg};
`

export const PolicyLink = styled(Link)`
  color: ${theme.colors.blue};
`

export const ResumeBtn = styled(Field) <{ uploadedFile?: string, error: string }>`
  color: black;
  margin-bottom: ${theme.offsets.extraBig};
  max-width: 190px;
  position: relative;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
  content: ${props => props.uploadedFile ? `"${props.uploadedFile}"` : '"Attach Resume"'};
  display: inline-block;
  border: 1px solid ${(props) => props.error ? theme.colors.lightRed : '#E2E3E5'};
  width: 100%;
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.lg};
  font-weight: 500;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  padding: ${theme.offsets.lg} ${theme.offsets.xl};
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-align: ${props => props.uploadedFile ? 'left' : 'right'};
}
&::after {
  content: '';
  display: inline-block;
  background: ${props => props.uploadedFile ? 'none' : `no-repeat url(${plusIcon})`};
  width: 20px;
  height: 20px;
  position: absolute;
  left: ${theme.offsets.xl};
  top: 50%;
  transform: translate(0%,-50%);
  
}
  &:hover::before {
  border-color: ${theme.colors.darkGray};
}
  &:active {
  outline: 0;
}
`

export const OptionalLabel = styled('div')`
  display: flex;
  justify-content: space-between;
`
export const OptionalText = styled('span')`
  color: ${theme.colors.darkGray};
  opacity: 0.6;
`

export const ErrorText = styled('div')`
  display: block;
  color: ${theme.colors.red};
  height: ${theme.fontSizes.lg};
  font-size: ${theme.fontSizes.md};
  margin-top: ${theme.offsets.sm};
`

export const PreloaderWrap = styled('div')`
  padding: ${theme.offsets.xs};
`

export const RedirectText = styled('p')`
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  text-align: center;
  margin-top: ${theme.offsets.xxl};
  font-weight: 500;
`

export const RedirectLink = styled(Link)`
  color: ${theme.colors.blue};
`

export const ErrorMessage = styled('p')`
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.red};
  text-align: center;
  margin: ${theme.offsets.lg} auto;
  font-weight: 500;
`