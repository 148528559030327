import axios from 'axios';
import { baseURl } from "../../../constants/config";

export const generateSignedUrl = (dto: any) => {
  axios.defaults.withCredentials = true;
  return axios.post(`${baseURl}/s3/generate_signed_url`, dto);
}

export const s3upload = async (url: string, file: File) => {
  return new Promise<any>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open("PUT", url, true)
    xhr.setRequestHeader("Content-Type", file.type)
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      } else {
        reject(xhr.statusText)
      }
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.send(file)
  })
}
