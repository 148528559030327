import { textContentRendering } from "./helper";
import { ContentCard, TextContent, JobCart, JobInfo, Snapshots } from "./Skeleton.presents";

export const JobListSkeleton = ({ isDesktop }: { isDesktop: boolean }) => {
  const JOB_ITEMS = new Array(3).fill('');
  
  return <div>
    {JOB_ITEMS.map((item, index) =>
      <ContentCard key={`job${index}`} isDesktop={isDesktop} height={'320px'}>
        <JobCart>
          <JobInfo>
            <TextContent width="10%" />
            <TextContent width="10%" />
          </JobInfo>
          <TextContent width="40%" />
          <Snapshots>
            {textContentRendering({ width: '10%', length: 6, fieldName: 'jobDescription' })}
          </Snapshots>
          <TextContent width="80%" />
          <TextContent width="80%" />
          <JobInfo>
            <TextContent width="10%" />
            <TextContent width="10%" />
          </JobInfo>
        </JobCart>
      </ContentCard>
    )}
  </div>
}