import styled from 'styled-components'
import checkedOrangeIcon from '../../../assets/img/checkedOrange.svg'
import checkedIcon from '../../../assets/img/checked.svg'
import { colors } from '../../../constants/colors'
import { CheckBoxType } from './Checkbox.props'
import { theme } from '../../../theme'

export const StyleForWrap = styled('div') <CheckBoxType>`
  position: relative;
  margin: ${props => props.m ? props.m : '0px'};
  ${props => props.block && `
    margin-right:16px;
    margin-top: 12px;
    margin-bottom: 12px;
  `}
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
  border-radius: 10px;
  //width: fit-content; 
  padding: 0 0 0 35px;
  ${props => props.block && `
    background: #F0F2F3;
    padding: 18px 16px 18px 47px;
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.lg};
      padding-left: ${theme.offsets.extraMajor};
      border-radius: ${theme.variables.borderRadiusSmall};
    }
    @media(max-width: ${theme.screens.mobile}) {
      padding: 10px;
      font-size: ${theme.fontSizes.md};
      padding-left: ${theme.offsets.major};
    }
  `}
}

&:hover .container{
${props => props.block && `
  background-color: #E4E8EF;
`}
  
}

& input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: ${props => props.w ? props.w : '100%'};
  top: 0;
  z-index: 2;
}

.checkmark {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #D1D7E3;
  ${props => props.block && `
    border:none;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    left: 16px;
    @media(max-width: ${theme.screens.mobile}) {
      width: 18px;
      height: 18px;
      left: 10px;
    }
  `}
}

& input:checked ~ .container .checkmark {
  border: 1px solid ${props => props.disabled ? '#D1D7E3' : colors.orange};
  background-color: ${props => props.disabled ? '#D1D7E3' : colors.orange};
  ${props => props.block && `
    background-color: #fff;
    border: 1px solid #fff;
  `}
}

& input:checked ~ .container  {
  ${props => props.block && `
    background: ${colors.orange};
    color: #fff;
  `}
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

& input:checked ~ .container .checkmark:after {
  display: block;
  width: 100%;
  height: 100%;
}

.container .checkmark:after {
  background-image: url(${props => props.block ? checkedOrangeIcon : checkedIcon});
  background-repeat: no-repeat;
  background-position: center;
}
`