import { Formik } from "formik"
import { FormWrap } from "./Settings.presents"
import * as Yup from 'yup';
import { useState } from "react";
import { useRootStore } from "../../../state/stores/storeContext";
import { Text } from "../../common/Text";
import { Button } from "../../common/Button";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { ModalForResults } from "../../common/Modal/ModalForResults";
import { PropsType } from "./Settings.props";
import { Wrapper, Title, ButtonWrap, FieldWrap } from "../AdminPanel.presents";
import { Input } from "../../common/Input";
import { passwordValidation } from "../../../services/validationSchemas";

export const Settings = (props: PropsType) => {
  const { userStore } = useRootStore()
  const initialValues = { oldPassword: '', password: '', repeatedPassword: '' }
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);

  const { t } = useTranslation();
  return <Wrapper isDesktop={props.isDesktop}>
    <Container fluid className="my-auto">
      <Row className="justify-content-center">
        <FormWrap>
          <Title isDesktop={props.isDesktop}>Password</Title>
          <Formik
            validateOnChange={firstSubmitComplete}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              oldPassword: passwordValidation(t),
              password: passwordValidation(t)
                .notOneOf([Yup.ref("oldPassword"), null], t('validation.mustBeDifferent')),
              repeatedPassword: Yup.string().required()
                .oneOf([Yup.ref("password"), null], t('validation.mustMatch'))
            })}
            onSubmit={async (
              values: typeof initialValues, form
            ) => {
              const serverResponse = await userStore.updateUserData({
                action: 'updatePassword',
                oldPassword: values.oldPassword,
                newPassword: values.repeatedPassword
              })
              setResponseResult(serverResponse.err ? 'error' : 'successfully');
              setModalOpen(true);
              if (!serverResponse.err) form.resetForm();
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className='d-flex flex-column'>
                <FieldWrap
                  as={Input}
                  name={'oldPassword'}
                  type={'password'}
                  value={values.oldPassword}
                  onChange={handleChange}
                  labelText={t('label.oldPassword')}
                  error={!!errors.oldPassword}
                />
                <Text text={errors.oldPassword ? errors.oldPassword : ''} error />
                <FieldWrap
                  as={Input}
                  name={'password'}
                  type={'password'}
                  value={values.password}
                  onChange={handleChange}
                  labelText={t('label.newPassword')}
                  error={!!errors.password}
                />
                <Text text={errors.password ? errors.password : ''} error />
                <FieldWrap
                  as={Input}
                  name={'repeatedPassword'}
                  type={'password'}
                  value={values.repeatedPassword}
                  onChange={handleChange}
                  labelText={t('label.confirmPassword')}
                  error={!!errors.repeatedPassword}
                />
                <Text text={errors.repeatedPassword ? errors.repeatedPassword : ''} error />
                <ButtonWrap>
                  <Button 
                  onClick={() => setFirstSubmitComplete(true)}
                  type={'submit'} 
                  buttonText={t('adminPanel.updatePasswordBtn')} 
                  styled={'warning'} />
                </ButtonWrap>
              </form>
            )}
          </Formik>
        </FormWrap>
      </Row>
    </Container>
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      text={responseResult === 'successfully' ? 'Your changes have been successfully saved!' : 'Your changes haven`t been updated!'}
    />
  </Wrapper>
}