import { PropsType } from './DropdownQuestions.props';
import { StyleForWrap, StyleForLabel } from './DropdownQuestions.presents';
import { Input } from '../../../common/Input';
import { Checkbox } from '../../../common/Checkbox';
import { Col, Row } from 'react-bootstrap';
import { SelectCom } from '../../../common/Select';
import { Field } from 'formik';
import currenciesOption from '../../../../assets/staticData/currencies.json';
import { SalaryField } from '../SalaryField';

const currenciesOptionWithFlag = currenciesOption.map(item => {
  return {
    value: item.value,
    label: <div className='d-flex align-items-center'>
      <div className={`currency-flag currency-flag-${item.value.toLocaleLowerCase()}`}></div>{item.label}<span className='currency-description'>{'- ' + item.description}</span>
    </div>,
  }
})

const contractLengthOptions = () => {
  const options = []
  for (let i = 1; i < 11; i++) {
    options.push({ value: i + `year${i > 1 && i < 10 ? 's' : ''}`, label: i + ` year${i > 1 && i < 10 ? 's' : ''}` })
  }
  return options
}

const InputRate = (props: { inputName: string, labelText: string, handleChange: Function, values: any, margin?: string, setFieldTouched: Function }) => {
  const fieldNameFrom = props.inputName + 'From';
  const fieldNameTo = props.inputName + 'To';

  const valuesFrom = props.values[fieldNameFrom];
  const valuesTo = props.values[fieldNameTo];

  return <Row className='align-items-end input-rate w-100'>
    <Col md={4} className='pl-0'>
      <Field
        component={SalaryField}
        maxValue={valuesTo}
        fieldNameTo={fieldNameTo}
        onChange={props.handleChange}
        value={valuesFrom}
        name={fieldNameFrom}
        labelText={props.labelText}
        placeholder={'From:'}
        type='number'
      />
    </Col>
    <Col md={4} className='pl-0'>
      <Input
        onChange={props.handleChange}
        value={valuesTo}
        name={fieldNameTo}
        placeholder={'To:'}
        type='number'
        onBlur={() => props.setFieldTouched(fieldNameTo)}
      />
    </Col>
    <Col md={4} className='px-0'>
      <Field
        name={props.inputName + 'Select'}
        placeholder={'Select the type ...'}
        options={[{ value: 'Percentage', label: 'Percentage' }, { value: 'Amount', label: 'Amount' } ]}
        component={SelectCom}
        labelText={<div className='d-flex justify-content-between'>
          <span></span>
          <span className='optional'>optional</span>
        </div>}
      />
    </Col>
  </Row>
}

export const DropdownQuestions = (props: PropsType) => {
  switch (props.answerValue) {
    case 'hourlyWage':
      return props.dropdownQuestions.compensation.hourlyWage ? <StyleForWrap>
        <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end">
          <Col md={3} className='pl-0'>
            <Field
              component={SalaryField}
              maxValue={props.values.hourlyRateTo}
              fieldNameTo={'hourlyRateTo'}
              onChange={props.handleChange}
              value={props.values.hourlyRateFrom}
              name={'hourlyRateFrom'}
              labelText={'Hourly rate'}
              placeholder={'From:'}
              type='number'
              error={props.errors && props.errors.hourlyRateFrom ? true : false}
            />
          </Col>
          <Col md={3} className='pl-0'>
            <Input
              onChange={props.handleChange}
              value={props.values.hourlyRateTo}
              name={'hourlyRateTo'}
              placeholder={'To:'}
              type='number'
              onBlur={() => props.setFieldTouched('hourlyRateTo')}
              error={props.errors && props.errors.hourlyRateTo ? true : false}
            />
          </Col>
          <Col md={6} className='px-0'>
            <Field
              name={'hourlyFromSelect'}
              placeholder={'Currency'}
              options={currenciesOptionWithFlag}
              component={SelectCom}
              error={props.errors && props.errors.hourlyFromSelect ? true : false}
            />
          </Col>
          <Checkbox
            checked={props.values.hourlyPrefer && props.values.hourlyPrefer.includes('disclose')}
            m={'24px auto 0 0'}
            name='hourlyPrefer'
            labelText='Prefer not to disclose'
            value='disclose'
            handleChange={props.handleChange} />
        </div>
        <InputRate
          margin={'8px 0 0'}
          handleChange={props.handleChange}
          values={props.values}
          inputName='commisionRate'
          labelText='Commision'
          setFieldTouched={props.setFieldTouched}
        />
        <Checkbox
          checked={props.values['overtimeIsIncluded'] && props.values['overtimeIsIncluded'].includes('include')}
          m={'24px 0 0'}
          name='overtimeIsIncluded'
          labelText='Overtime is included'
          value='include'
          handleChange={props.handleChange} />
      </StyleForWrap> : null
    case 'commission':
      return props.dropdownQuestions.compensation.commission ? <StyleForWrap>
        <InputRate
          handleChange={props.handleChange}
          values={props.values}
          inputName='commisionRate'
          labelText='Commision rate'
          setFieldTouched={props.setFieldTouched}
        />
      </StyleForWrap> : null
    case 'annualSalary':
      return props.dropdownQuestions.compensation.annualSalary ? <StyleForWrap>
        <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end">
          <Col md={3} className='px-0'>
            <Field
              component={SalaryField}
              maxValue={props.values.salaryRateTo}
              fieldNameTo={'salaryRateTo'}
              w={'95%'}
              onChange={props.handleChange}
              value={props.values.salaryRateFrom}
              name={'salaryRateFrom'}
              labelText={'Salary rate'}
              placeholder={'From:'}
              type='number'
              error={props.errors && props.errors.salaryRateFrom ? true : false}
            />
          </Col>
          <Col md={3} className='px-0'>
            <Input
              w={'95%'}
              onChange={props.handleChange}
              value={props.values.salaryRateTo}
              name={'salaryRateTo'}
              placeholder={'To:'}
              type='number'
              error={props.errors && props.errors.salaryRateTo ? true : false}
              onBlur={() => props.setFieldTouched('salaryRateTo')}
            />
          </Col>
          <Col md={6} className='px-0'>
            <Field
              w={'95%'}
              placeholder={'Currency'}
              name={'salaryFromSelect'}
              options={currenciesOptionWithFlag}
              component={SelectCom}
              error={props.errors && props.errors.salaryFromSelect ? true : false}
            />
          </Col>
          <Col md={12} className='px-0'>
            <Field
              m={'24px 0 0'}
              placeholder={'Periods...'}
              name={'salaryPeriodSelect'}
              error={props.errors && props.errors.salaryPeriodSelect ? true : false}
              options={[
                { value: 'annual', label: 'Annual' },
                { value: 'quarterly', label: 'Quarterly' },
                { value: 'monthly', label: 'Monthly' },
                { value: 'bi-weekly', label: 'Bi-weekly' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'daily', label: 'Daily' }
              ]}
              component={SelectCom} />
          </Col>
          <Checkbox
            checked={props.values['salaryPrefer'] && props.values['salaryPrefer'].includes('disclose')}
            m={'18px auto 0 0'}
            name='salaryPrefer'
            labelText='Prefer not to disclose'
            value='disclose'
            handleChange={props.handleChange} />
        </div>
        <InputRate
          margin='8px 0 0'
          handleChange={props.handleChange}
          values={props.values}
          inputName='commisionRate'
          labelText='Commision'
          setFieldTouched={props.setFieldTouched}
        />
        <InputRate
          margin={'24px auto 0 0'}
          handleChange={props.handleChange}
          values={props.values}
          inputName='bonusAmount'
          labelText='Bonus amount'
          setFieldTouched={props.setFieldTouched}
        />
        <InputRate
          margin={'24px auto 0 0'}
          handleChange={props.handleChange}
          values={props.values}
          inputName='signInBonus'
          labelText='Signing Bonus Amount'
          setFieldTouched={props.setFieldTouched}
        />
        <Checkbox
          checked={props.values['overtimeIsIncluded'] && props.values['overtimeIsIncluded'].includes('include')}
          m={'26px 0 0'}
          name='overtimeIsIncluded'
          labelText='Overtime is included'
          value='include'
          handleChange={props.handleChange} />
      </StyleForWrap> : null
    case 'yesEquity':
      return props.dropdownQuestions.equityIncluded.yesEquity ? <StyleForWrap>
        <div className="d-flex flex-wrap justify-content-between inputs-wrap align-items-end">

          <Col md={4} className='px-0'>
            <Field
              component={SalaryField}
              maxValue={props.values.equityTo}
              fieldNameTo={'equityTo'}
              w={'95%'}
              onChange={props.handleChange}
              value={props.values.equityFrom}
              name={'equityFrom'}
              placeholder={'From:'}
              type='number'
              error={props.errors && props.errors.equityFrom ? true : false} />
          </Col>
          <Col md={4} className='px-0'>
            <Input
              w={'95%'}
              onChange={props.handleChange}
              value={props.values.equityTo}
              name={'equityTo'}
              placeholder={'To:'}
              type='number'
              error={props.errors && props.errors.equityTo ? true : false}
              onBlur={() => props.setFieldTouched('equityTo')}
            />
          </Col>
          <Col md={4} className='px-0'>
            <Field
              w={'95%'}
              name={'equitySelect'}
              placeholder={'Select the type ...'}
              options={[{ value: 'Percentage', label: 'Percentage' }, { value: 'Shares', label: 'Shares' }]}
              component={SelectCom}
              error={props.errors && props.errors.equitySelect ? true : false}
            />
          </Col>
        </div>
        <StyleForLabel htmlFor="vestingSchedule">
          <div className='d-flex justify-content-between'>
            <span>Vesting schedule for complete ownership</span>
          </div>
        </StyleForLabel>
        <Field
          className='w-100'
          name={'vestingSchedule'}
          options={contractLengthOptions()}
          component={SelectCom}
        />
      </StyleForWrap> : null
    case 'customContractLength':
      return props.dropdownQuestions.contractLength.customContractLength ? <StyleForWrap>
        <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end">
          <Col md={6} className='px-0'>
            <Input
              w={'95%'}
              onChange={props.handleChange}
              name={'contractLengthInput'}
              value={props.values['contractLengthInput'] || ''}
              placeholder={'Length'}
              labelText={'Contract length'}
              type='number'
            />
          </Col>
          <Col md={6} className='px-0'>
            <StyleForLabel htmlFor="contractLengthSelect">
              <div className='d-flex justify-content-between'>
                <span></span>
                <span className='optional'>optional</span>
              </div>
            </StyleForLabel>
            <Field
              className={'long-select'}
              name={'contractLengthSelect'}
              options={[
                { value: 'days', label: 'Days' },
                { value: 'weeks', label: 'Weeks' },
                { value: 'months', label: 'Months' },
                { value: 'years', label: 'Years' },
              ]}
              component={SelectCom}
            />
          </Col>
        </div>
      </StyleForWrap> : null
    case 'fixedBudgetYes':
      return props.dropdownQuestions.fixedBudget.fixedBudgetYes ? <StyleForWrap>
        <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end">
          <Col md={3} className='pl-0'>
            <Field
              component={SalaryField}
              maxValue={props.values.fixedBudgetTo}
              fieldNameTo={'fixedBudgetTo'}
              onChange={props.handleChange}
              name={'fixedBudgetFrom'}
              value={props.values.fixedBudgetFrom}
              placeholder={'From:'}
              type='number'
              labelText={'Hourly rate'}
              error={props.errors && props.errors.fixedBudgetFrom}
            />
          </Col>
          <Col md={3} className='pl-0'>
            <Input
              onChange={props.handleChange}
              name={'fixedBudgetTo'}
              value={props.values.fixedBudgetTo}
              placeholder={'To:'}
              type='number'
              error={props.errors && props.errors.fixedBudgetTo}
              onBlur={() => props.setFieldTouched('fixedBudgetTo')}
            />
          </Col>
          <Col md={6} className='px-0'>
            <Field
              placeholder={'Currency'}
              name={'budgetToSelect'}
              options={currenciesOptionWithFlag}
              component={SelectCom}
              error={props.errors && props.errors.budgetToSelect ? true : false}
            />
          </Col>
        </div>
      </StyleForWrap> : null
    default:
      return null
  }
}