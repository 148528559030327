import { colors } from '../../../constants/colors';
import styled from 'styled-components'
import { theme } from '../../../theme';
import { Link } from 'react-router-dom';

export const StyleForWrap = styled('div') <{ isDesktop: boolean, isLoading: boolean }>`
  background: ${(props) => props.isDesktop ? theme.colors.white : 'none'};
  box-shadow: ${(props) => props.isDesktop ? '6px 6px 20px rgb(0 0 0 / 2%)' : 'none'};
  border-radius: ${(props) => props.isDesktop ? theme.variables.borderRadiusXLarge : 'none'};
  margin: 0 auto;
  padding: ${(props) => props.isDesktop ? `${theme.offsets.extraBig} ${theme.offsets.xxl} ${theme.offsets.xxl}` : '0'};
  min-height: 83%;
  max-width: 1720px;
  position: relative;
  flex: 1;
`

export const StyleFortabs = styled('div')`
  display: flex;
  cursor: pointer;
  margin-bottom: 16px;
  border-bottom: 1px solid #D1D7E3;
  .count{
    border: 1px solid #D1D7E3;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    //align-items: center;
    border-radius: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    margin-left: 4px;
  }
  .tab-item{
    padding: 16px 29px;
    display: flex;
    align-items: center;
    position: relative;
    &::after{
      content:'';
      display: none;
      width: 100%;
      height: 2px;
      background-color:#EC923C;
      position: absolute;
      bottom: -1px;
      left: 50%; 
      transform: translate(-50%, 0%); 
    }
    &.active::after{
      display: block;
    } 
    &:hover::after{
      display: block;
    } 
    &.active .title{
      color: #EC923C;
    }
    .title{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: ${colors.dark};
      
    }
    .icon{
      margin-right: 10px;
    }
  }
`

export const TitleWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  background: ${(props) => !props.isDesktop ? theme.colors.white : 'none'};
  padding: ${(props) => !props.isDesktop ? `${theme.offsets.xs} ${theme.offsets.md}` : '0'};
`

export const Title = styled('p')`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.xxl};
  color: ${theme.colors.black};
`

export const PreloaderWrap = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 76px;
  max-width: 76px;
  width: 100%;
  height: 100%;
  img{
    margin-bottom: ${theme.offsets.lg};
  }
`

export const EmptyListBannerWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  img{
    margin-bottom: ${theme.offsets.lg};
  }
`

export const BannerText = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  text-align: center;
  color: ${theme.colors.black};
  margin-bottom: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) { 
    font-size: ${theme.fontSizes.lg};
  }; 
  @media(max-width: ${theme.screens.tablet}) { 
    font-size: 14px;
  } 
`