import { RouteComponentProps } from "react-router"

export type IsJobExistType = {
  jobStoryBuilder: any
  jobId: string
  history: RouteComponentProps["history"]
  setActiveStep: Function
  setDoneStep: Function
  steps: {
    active: number
    done: number
  }
}

export type SendAnswersType = {
  values: any
  updatingUrl: string;
  stepInfo: {
    title: string
    nextStep: string
    done: number
  }
  arrayWithDependents: Array<any>
}

export enum JobBuilderStep {'start' , 'job' , 'description' , 'specification' , 'contact' , 'finish' , 'status', 'intro'}