import { UpdateUserDto, SupportMessageDto } from './user.type';
import axios from 'axios'
import { baseURl } from "../../../constants/config";


export const getUserData = () => {
  axios.defaults.withCredentials = true
  return axios.get(`${baseURl}/user/data`)
}

export const updateUser = (updatingUserData: UpdateUserDto) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/user/update`, updatingUserData)
}

export const sendSupportMessage = (supportData: SupportMessageDto) => {
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/user/support`, supportData)
}

export const disableIntro = () => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/user/disable-intro`)
} 

export const uploadSingleFile = (dto: any) => {
  axios.defaults.withCredentials = true
  return axios.post(`${baseURl}/user/add_single_file`, dto)
}

export const removeSingleFile = (dto: any) => {
  axios.defaults.withCredentials = true
  return axios.put(`${baseURl}/user/remove_single_file`, dto )
}