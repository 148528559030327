import { MainListRow, ViewMoreBtn, ListTitle, AboutCompanySeparator, ListSubtitle, CompanyName, CompanyLogo, ListText, SocialLinks, MainListContainer, CompanyInfoCol } from './AboutCompany.presents';
import { PropsType } from './AboutCompany.props';
import { observer } from 'mobx-react-lite';
import { setLocationsListItem } from '../Introduction/Introduction.helper';
import { useState } from 'react';
import { LocationModal } from '../Modals/LocationModal';
import websiteIcon from '../../../../assets/img/jobStory/websiteIcon.svg';
import linkedInIcon from '../../../../assets/img/jobStory/linkedinMaps.svg';
import facebookIcon from '../../../../assets/img/jobStory/facebookMaps.svg';
import twitterIcon from '../../../../assets/img/jobStory/twitterMaps.svg';
import instagramIcon from '../../../../assets/img/jobStory/instagramMaps.svg';
import youtubeIcon from '../../../../assets/img/jobStory/youtubeMaps.svg';
import { Col } from 'react-bootstrap';
import { DescriptionText } from '../JobDescription';
import { JobStoryCard } from '../../../common/Cards/JobStoryCard';
import organizationSizeOptions from '../../../../assets/staticData/organizationSize.json';
import { linkHandler } from '../../../../services/helper';
import { LocationType } from '../Modals/Modals.props';

export const AboutCompany = observer(({ jobData, companyLogo, componentRef, bannersUrl }: PropsType) => {
  const [openMapsWindow, setOpenMapsWindow] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);

  const handleCloseMapsWindow = () => setOpenMapsWindow(false);

  const contactStepAnswers = jobData.contactStep;

  const isHaveWebsite = contactStepAnswers.contactLinkedIn || contactStepAnswers.contactFacebook || contactStepAnswers.contactTwitter || contactStepAnswers.contactYoutube || contactStepAnswers.contactInstagram || contactStepAnswers.contactWebsite;
  const organizationSizeItem = organizationSizeOptions.find(item => item.value === jobData.contactStep.organizationSize);

  const isVisible = (fieldName: string) => {
    return jobData.contactStep[fieldName] && jobData.contactStep[fieldName].includes('visible');
  };
  const isContactBlockVisible = isVisible('fullNameVisible') || isVisible('emailVisible') || isVisible('phoneVisible');
  const logo = jobData.contactStep.directEmployerLogo.fileUrl || companyLogo.fileUrl;
  const locationListDto = { 
    jobData, 
    setOpenMapsWindow, 
    isHiringLocation: false, 
  }
  return <JobStoryCard title='The company' imageUrl={bannersUrl.forCompanyBanner} componentRef={componentRef}>
    <MainListRow>
      <MainListContainer fluid>
        <CompanyInfoCol xs={12} md={4}>
          {logo && <CompanyLogo>
            <img src={logo} alt="logo" />
          </CompanyLogo>}
          <CompanyName>{jobData.contactStep.organizationName}</CompanyName>
        </CompanyInfoCol>
        <Col xs={12} md={4} className='d-flex flex-column align-items-start'>
          <ListSubtitle>Location</ListSubtitle>
          <ListText className='modal-item'>{setLocationsListItem(locationListDto)}</ListText>
          <ListSubtitle>Company size</ListSubtitle>
          <ListText>
            {organizationSizeItem?.label}
          </ListText>
          {jobData.contactStep.industry && <><ListSubtitle>Industry</ListSubtitle>
            <ListText>{jobData.contactStep.industry}</ListText></>}
        </Col>
        {isHaveWebsite && <Col xs={12} md={4} >
          <ListSubtitle>Contacts</ListSubtitle>
          <SocialLinks>
            {contactStepAnswers.contactWebsite && <a target="_blank" href={linkHandler(contactStepAnswers.contactWebsite)}><img src={websiteIcon} alt="icon" /></a>}
            {contactStepAnswers.contactLinkedIn && <a target="_blank" href={linkHandler(contactStepAnswers.contactLinkedIn)}><img src={linkedInIcon} alt="icon" /></a>}
            {contactStepAnswers.contactFacebook && <a target="_blank" href={linkHandler(contactStepAnswers.contactFacebook)}><img src={facebookIcon} alt="icon" /></a>}
            {contactStepAnswers.contactTwitter && <a target="_blank" href={linkHandler(contactStepAnswers.contactTwitter)}><img src={twitterIcon} alt="icon" /></a>}
            {contactStepAnswers.contactYoutube && <a target="_blank" href={linkHandler(contactStepAnswers.contactYoutube)}><img src={youtubeIcon} alt="icon" /></a>}
            {contactStepAnswers.contactInstagram && <a target="_blank" href={linkHandler(contactStepAnswers.contactInstagram)}><img src={instagramIcon} alt="icon" /></a>}
          </SocialLinks>
        </Col>}
      </MainListContainer>
    </MainListRow>
    {isContactBlockVisible && <>
      <AboutCompanySeparator />
      <ListTitle>Contact person</ListTitle>
      <MainListRow>
        <MainListContainer fluid>
          {isVisible('fullNameVisible') && <Col xs={12} md={4}>
            <ListSubtitle>Name</ListSubtitle>
            <ListText>{jobData.contactStep.fullName}</ListText>
          </Col>}
          {isVisible('emailVisible') && <Col xs={12} md={4}>
            <ListSubtitle>Email</ListSubtitle>
            <p className='d-flex flex-column'>
              <ViewMoreBtn onClick={() => setOpenEmail(!openEmail)}>{!openEmail ? 'View' : 'Hide'}</ViewMoreBtn>
              <ListText>{openEmail ? jobData.contactStep.email : ''}</ListText>
            </p>
          </Col>}
          {isVisible('phoneVisible') && <Col xs={12} md={4}>
            <ListSubtitle>Phone number</ListSubtitle>
            <p className='d-flex flex-column'>
              <ViewMoreBtn onClick={() => setOpenPhone(!openPhone)}>{!openPhone ? 'View' : 'Hide'}</ViewMoreBtn>
              <ListText>{openPhone ? jobData.contactStep.phone : ''}</ListText>
            </p>
          </Col>}
        </MainListContainer>
      </MainListRow>
    </>}
    <AboutCompanySeparator />
    {jobData.descriptionStep.aboutTeam && <>
      <ListTitle>The team and the career path</ListTitle>
      <ListText>
        <DescriptionText text={jobData.descriptionStep.aboutTeam} />
      </ListText>
      <AboutCompanySeparator />
    </>}
    {jobData.descriptionStep.companyCulture && <>
      <ListTitle>The company and culture</ListTitle>
      <ListText>
        <DescriptionText text={jobData.descriptionStep.companyCulture} />
      </ListText>
      <AboutCompanySeparator />
    </>}
    {jobData.descriptionStep.additionalInfo && <>
      <ListTitle>Additional information</ListTitle>
      <ListText>
        <DescriptionText text={jobData.descriptionStep.additionalInfo} />
      </ListText>
    </>}
    <LocationModal
      openMapsWindow={openMapsWindow}
      handleCloseMapsWindow={handleCloseMapsWindow}
      organizationName={jobData.contactStep.organizationName}
      companyLogo={companyLogo}
      contactStep={jobData.contactStep}
      locations={{ locationType: LocationType.primary, locationsData: jobData.startStep.locations }}
    />
  </JobStoryCard>
})