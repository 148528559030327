import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropAreaWrapper } from './DropArea.presents';

export const DropArea = ({ setUploadedFile, setErrorMessage }: { setUploadedFile: Function, setErrorMessage: Function }) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    maxFiles: 1,
    maxSize: 2e+8,
    accept: {
      'video/*': ['*'],
    }
  });

  const SIZE_ERROR_MSG = 'Maximum file size is 200 MB';

  useEffect(() => {
    acceptedFiles.forEach((file: any) => {
      setUploadedFile(file);
      setErrorMessage('');
    });
  }, [acceptedFiles])

  fileRejections.map(({ file, errors }: any) => {
    setErrorMessage(SIZE_ERROR_MSG);
  });


  return (
    <DropAreaWrapper isActive={isDragActive} {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
    </DropAreaWrapper>
  );
}