import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../theme";

export const Wrapper = styled('div')`
  background-color: ${theme.colors.white}; 
  padding-left: ${theme.offsets.xs};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  @media(max-width: ${theme.screens.tablet}) { 
    padding-left: ${theme.offsets.lg};
  }
`

export const NavItem = styled(NavLink)`
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  border:none;
  border-bottom: 4px solid ${theme.colors.white};
  padding: 0 0 ${theme.offsets.lg};
  margin: ${theme.offsets.lg} ${theme.offsets.xl} 0;
  &.active {
    color: ${theme.colors.lightOrange};
    border: none;
    border-bottom: 4px solid ${theme.colors.lightOrange};
  }
  @media(max-width: ${theme.screens.tablet}) { 
    padding: ${theme.offsets.md};
    margin: 0;
  }
  &:focus, &:hover {
  outline: none;
  color: ${theme.colors.lightOrange};
}
`