import { useMemo } from "react";
import { Subtitle } from "../../Resume.presents";
import { ImportantThingsIcon, preferencesIconMap } from "./helper";
import { observer } from "mobx-react-lite";
import {
  PreferencesWrap,
  PreferencesItem,
  PreferencesContent,
  PreferencesTitle,
  PreferencesText,
  TechnicalSkillsContainer,
  TechnicalSkillsBlock,
  ImportantThingsBlock,
  ImportantThingsWrap,
  PreferencesTextWrap,
} from "./MainInformation.presents";

export const Preferences = observer(({
  preferences,
}: {
  preferences: any,
}) => {
  const renderPreferenceCountry = useMemo(() => <TechnicalSkillsContainer>
    {preferences.preferenceCountry.map((item: string, index: number) => <TechnicalSkillsBlock key={`${index}PreferenceCountry`}>
      {item}
    </TechnicalSkillsBlock>)}
  </TechnicalSkillsContainer>
    , []);

  const renderImportantThings = useMemo(() => {
    const colors = ['#187DFF', '#52A34B', '#FFC736', '#D774AA'];
    return <ImportantThingsWrap>
      {preferences.workplaceBenefits.map((item: string, index: number) => <ImportantThingsBlock color={colors[index]} key={`${index}PreferenceCountry`}>
        <ImportantThingsIcon color={colors[index]} iconKey={item} />
        <span>{item}</span>
      </ImportantThingsBlock>)}
    </ImportantThingsWrap>
  }
    , []);

  return <>

    <PreferencesWrap>

      <PreferencesItem>
        {preferencesIconMap.jobType}

        <PreferencesContent>
          <PreferencesTitle>
            Desired job type
          </PreferencesTitle>
          <PreferencesTextWrap>
            {preferences.jobType.map((item: string, index: number) => <PreferencesText key={item}>
              {item}{index + 1 !== preferences.jobType.length && ','}
            </PreferencesText>)}
          </PreferencesTextWrap>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.workplace}

        <PreferencesContent>
          <PreferencesTitle>
            Desired workplace type
          </PreferencesTitle>
          <PreferencesText>
            {preferences.jobKind}
          </PreferencesText>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.weeklySchedule}

        <PreferencesContent>
          <PreferencesTitle>
           My weekly schedule
          </PreferencesTitle>
          <PreferencesTextWrap>
            {preferences.jobTime.map((item: string, index: number) => <PreferencesText key={item}>
              {item}{index + 1 !== preferences.jobTime.length && ','}
            </PreferencesText>)}
          </PreferencesTextWrap>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.jobTraveling}

        <PreferencesContent>
          <PreferencesTitle>
            Comfortability with work travels
          </PreferencesTitle>
          <PreferencesText>
            {preferences.jobTraveling}
          </PreferencesText>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.urgency}

        <PreferencesContent>
          <PreferencesTitle>
            My availability
          </PreferencesTitle>
          <PreferencesText>
            {preferences.jobStart}
          </PreferencesText>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.jobLevel}

        <PreferencesContent>
          <PreferencesTitle>
            Desired job levels
          </PreferencesTitle>
          <PreferencesTextWrap>
            {preferences.levelSkills.map((item: string, index: number) => <PreferencesText key={item}>
              {item}{index + 1 !== preferences.levelSkills.length && ','}
            </PreferencesText>)}
          </PreferencesTextWrap>
        </PreferencesContent>
      </PreferencesItem>

      <PreferencesItem>
        {preferencesIconMap.workHours}

        <PreferencesContent>
          <PreferencesTitle>
            Willing to work after business hours
          </PreferencesTitle>
          <PreferencesText>
            {preferences.includesOvertime}
          </PreferencesText>
        </PreferencesContent>
      </PreferencesItem>
    </PreferencesWrap>

    <Subtitle>Locations I’m Interested to Work</Subtitle>
    {renderPreferenceCountry}

    <Subtitle>Important Things for Me at Workplace</Subtitle>
    {renderImportantThings}
  </>
});