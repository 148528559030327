import { SignInPage } from "../pages/SignIn/SignIn.page";
import { Route, Switch } from 'react-router-dom';
import { SignUpPage } from "../pages/SignUp";
import { ForgotPassPage } from "../pages/ForgotPass";
import { observer } from "mobx-react-lite";
import { JobStoryBuilderPage } from "../pages/JobStoryBuilder";
import { useEffect, useState } from "react";
import { useRootStore } from "../state/stores/storeContext";
import { CandidatesPage } from "../pages/Candidates";
import { ErrorPage } from "../pages/Error/Error.page";
import usePageTracking from "../services/analytics/usePageTracking";
import { JobStoryPage } from "../pages/JobStory";
import { PageTypes } from "../pages/Candidates/Candidates.props";
import { OrganizationPage } from "../pages/Organization/Organization.page";
import { OrganizationPreview } from "../pages/OrganizationPage/OrganizationPage.page";
import { ProfilePage } from "../pages/Profile/Profile.page";
import { SettingsPage } from "../pages/Settings/Settings.page";
import { JobListPage } from "../pages/JobList/JobList.page";
import { SupportPage } from "../pages/Support/Suppor.page";
import { PrivateRoute } from "../components/common/PrivateRoute";
import { CandidateSignInPage } from "../pages/Auth/Candidate/SignIn/CandidateSignIn.page";
import { CandidateForgotPasswordPage } from "../pages/Auth/Candidate/ForgotPassword/CandidateForgotPassword.page";
import { CandidateSignUpPage } from "../pages/Auth/Candidate/SignUp/CandidateSignUp.page";
import { VideoCV } from "../components/CandidateAdminPanel/VideoCV/VideoCV";
import { WelcomeStep } from "../components/Auth/Candidate/SignUp/Steps/Welcome/WelcomeStep";
import { CandidateVerifyPage } from "../pages/Auth/Candidate/Verify/CandidateVerify.page";
import { CandidateAccount } from "../pages/CandidateAccount";
import { CandidatePageType } from "../pages/CandidateAccount/CandidateAccount.types";
import { Resume } from "../components/CandidateAdminPanel/Resume/Resume";
import { Preloader } from "../components/common/Preloader";
import {useLocation} from "react-router";

export const Routers = observer(() => {
  const { userStore, organizationStore, candidateStore } = useRootStore();
  const location = useLocation();
  const isCandidateApplication = location.pathname.includes("/candidate/");
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = userStore.isAuthorizated;

  useEffect(() => {
    (async () => {
      if (isCandidateApplication) {
        await candidateStore.getCandidate();
      } else {
        await userStore.addUserData();
        if (userStore.isAuthorizated) {
          await organizationStore.uploadOrganizationData();
        }
      }
      setIsLoading(false);
    })()
  }, [])

  // track dev/staging activity temporarily for development purposes
  if (window.location.hostname !== 'localhost') {
    usePageTracking()
  }

  return <>
    {
      !isLoading ? <Switch>
        <Route exact path={['/', '/sign-in']} render={() => <SignInPage showRegister/>} />
        <Route exact path='/employer/login' render={() => <SignInPage showRegister={false}/>} />
        <Route path='/candidate/sign-in' render={() => <CandidateSignInPage />} />
        <Route path='/candidate/sign-up' render={() => <CandidateSignUpPage />} />
        <Route path='/candidate/forgot-password/restore' render={() => <CandidateForgotPasswordPage isEmailVerify />} />
        <Route path='/candidate/forgot-password' render={() => <CandidateForgotPasswordPage />} />
        <Route path='/sign-up' render={() => <SignUpPage />} />
        <Route path='/organization' render={() => <OrganizationPreview />} />
        <Route path='/job-preview' render={() => <CandidatesPage activePage={PageTypes.jobPreview} />} />
        <Route path='/forgot-password' render={() => <ForgotPassPage />} />
        <Route path='/forgot-password/restore' render={() => <ForgotPassPage />} />
        <Route path='/jobs' render={() => <JobStoryPage />} />

        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/videoCV' component={() => <VideoCV />} redirect="/candidate/sign-in" />
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/welcome' component={() => <WelcomeStep />} redirect="/candidate/sign-in"/>
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/profile' component={() => <CandidateAccount pageType={CandidatePageType.profile}/>} redirect="/candidate/sign-in"/>
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/support' component={() => <CandidateAccount pageType={CandidatePageType.support}/>} redirect="/candidate/sign-in"/>
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/resume-builder' component={() => <CandidateAccount pageType={CandidatePageType.resume_builder}/>} redirect="/candidate/sign-in"/>
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/preferences' component={() => <CandidateAccount pageType={CandidatePageType.preferences}/>} redirect="/candidate/sign-in"/>
        <PrivateRoute isAuthenticated={candidateStore.isAuthenticated} path='/candidate/verify' component={() => <CandidateVerifyPage />} redirect="/candidate/sign-in"/>
        <Route path='/profile/:id' component={() => <Resume isPublic/>} />

        <PrivateRoute isAuthenticated={isAuthenticated} path='/my-organization' component={() => <OrganizationPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/profile' component={() => <ProfilePage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/settings' component={() => <SettingsPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/my-jobs' component={() => <JobListPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/support' component={() => <SupportPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/candidates' component={() => <CandidatesPage activePage={PageTypes.candidates} />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/intro' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/start' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/job' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/description' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/specification' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/contact' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/finish' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/job-story/status' component={() => <JobStoryBuilderPage />} />
        <PrivateRoute isAuthenticated={isAuthenticated} path='/organization-preview' component={() => <OrganizationPreview isAdminView />} />
        <Route path='/*' render={() => <ErrorPage />} />
      </Switch>
        : <Preloader isFullScreen/>
    }
  </>
})