import styled from "styled-components";
import { theme } from "../../../theme";

export const NavigationWrap = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => !props.isDesktop && `
  justify-content: center;
  padding: 0 ${theme.offsets.md} ${theme.offsets.md};
    @media(max-width: ${theme.screens.mobile}) { 
      justify-content: start;
    } 
  `}
`

const StylesForNavigationBtns = `
  width: 52px;
  height: 52px;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  background: ${theme.colors.white};
`;

const StylesForMobileBtns = `
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: none;
`;

export const NextPage = styled('div') <{ isDesktop: boolean }>`
  transform: rotate(180deg);
  margin-left: ${theme.offsets.lg};
  ${StylesForNavigationBtns}
  ${(props) => !props.isDesktop && StylesForMobileBtns}
`

export const PrevPage = styled('div') <{ isDesktop: boolean }>`
  margin-right: ${theme.offsets.lg};
  ${StylesForNavigationBtns}
  ${(props) => !props.isDesktop && StylesForMobileBtns}
`