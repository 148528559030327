import { Field, FieldArray, Formik } from "formik";
import { useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { Input } from "../../../../common/Input";
import { ContentWrap, LabelWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialPortfolio } from "../../../../../state/stores/resumeBuilder";
import { FileInputWrap } from "../FileInput/FileInput";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { dataURLtoFile } from "../../../../../services/helper";
import { onRemoveFile, onUploadFile } from "../../../../../services/firebase/firestore/ResumeBuilder/writes";
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export const Portfolio = ({
  isDesktop,
  closeBlockHandler
}: {
  isDesktop: boolean;
  closeBlockHandler: () => void;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { t } = useTranslation();

  const initialValues = { portfolio: !!resumeBuilderStore.portfolio.length ? resumeBuilderStore.portfolio : [initialPortfolio] };

  return <CandidateFormCard
    isDesktop={isDesktop}
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Portfolio'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            portfolio: Yup.array()
              .of(
                Yup.object().shape({
                  title: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const copyValues = values.portfolio;
          for (const item of copyValues) {
            if (item.file.url && !item.file.isUploaded) {
              const fileData = dataURLtoFile(item.file.url, item.file.name);
              const uploadedFile: any = await onUploadFile(fileData, candidateStore.uid);
              if (!uploadedFile.error) {
                item.file.url = uploadedFile.url;
                item.file.isUploaded = true;
              }
            }
          };
          const result = await resumeBuilderStore.savePortfolio({ portfolio: copyValues }, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="portfolio">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };

                return <div>
                  {values.portfolio.map((item: any, index: number) => {
                    const arrayErrors: any = errors.portfolio;
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`portfolio${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional portfolio</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`portfolio.${index}.title`}
                          type={'text'}
                          value={values.portfolio[index].title}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Portfolio Title'}
                          error={mainValidationCondition('title')} />
                        <ErrorMsg>{mainValidationCondition('title') ? arrayErrors[index].title : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`portfolio.${index}.url`}
                          type={'text'}
                          value={values.portfolio[index].url}
                          onChange={onChangeHandler}
                          maxLength={50}
                          labelText={'Portfolio URL'}
                          error={mainValidationCondition('url')} />
                        <ErrorMsg>{mainValidationCondition('url') ? arrayErrors[index].url : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          as={Input}
                          name={`portfolio.${index}.description`}
                          type={'textarea'}
                          value={values.portfolio[index].description}
                          onChange={onChangeHandler}
                          maxLength={500}
                          labelText={<LabelWrap>Description<span>optional</span></LabelWrap>}
                          error={mainValidationCondition('description')} />
                        <ErrorMsg>{mainValidationCondition('description') ? arrayErrors[index].description : ''}</ErrorMsg>
                      </FieldWrap>

                      <FieldWrap>
                        <Field
                          component={FileInputWrap}
                          name={`portfolio.${index}.file`}
                          type={'file'}
                          value={values.portfolio[index].file}
                          updateItemCallback={() => resumeBuilderStore.savePortfolio(values, candidateStore.uid)}
                          onChange={(value: string) => setFieldValueHandler(`portfolio.${index}.file`, value)} />
                        <ErrorMsg>{mainValidationCondition('file') ? arrayErrors[index].file : ''}</ErrorMsg>
                      </FieldWrap>
                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialPortfolio)}>
                      Add Another Portfolio Link
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                const copyValues = values.portfolio;
                for (const item of copyValues) {
                  if (item.file.url) {
                    await onRemoveFile(item.file.name, candidateStore.uid);
                  }
                };
                await resumeBuilderStore.savePortfolio({ portfolio: [] }, candidateStore.uid);
                await resumeBuilderStore.setPortfolio([]);
                handleReset();
                closeBlockHandler();
              }}
            />}
          </form>}
      </Formik>

    </ContentWrap>
  </CandidateFormCard>
}

