import { Input } from "../../../common/Input";
import { LinkBtn } from "../../../common/Links";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Text } from "../../../common/Text";
import * as Yup from 'yup';
import { ButtonWrap, SignUpMobileLink, StyleForHelperText } from "./SignIn.presents";
import { useRootStore } from "../../../../state/stores/storeContext";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { Link } from "react-router-dom";
import { SignInProps } from "./SignIn.props";


export const SignIn = (props: SignInProps) => {
  const { userStore, organizationStore, routerStore } = useRootStore()
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [helperText, setHelperText] = useState('')

  const initialValues = { email: "", password: "" }
  const { t } = useTranslation();
  const history = useHistory();

  const helperTextHandler = () => {
    if (userStore.helperTextStatus) {
      setHelperText(t(`signIn.helperText.${userStore.helperTextStatus}`));
      userStore.setHelperTextStatus(null);
    }
  }

  useEffect(() => helperTextHandler(), [])

  useEffect(() => {
    routerStore.setLocationPath(history.location.pathname)
  }, [history.location.pathname, routerStore])

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string().email(t('validation.email')).required(t('validation.required')),
      password: Yup.string().required(t('validation.required'))
    })
  }

  const signIn = async (values: typeof initialValues) => {
    setLoading(true)
    const resData = await userStore.login({ username: values.email, password: values.password })
    if (!resData.err) {
      const pathValue = window.localStorage.getItem('redirectPath');
      const redirectPath = pathValue || '/my-jobs';
      setError("");
      await userStore.addUserData();
      await organizationStore.uploadOrganizationData();
      history.push(redirectPath);
    } else {
      setError(resData.err)
    }
    setLoading(false)
  }

  return (
    <>
      {
        helperText &&
        <StyleForHelperText>
          {helperText}
        </StyleForHelperText>
      }
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={signIn}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <fieldset disabled={isLoading}>
              <Input
                labelText={t('label.email')}
                type={'text'}
                name={'email'}
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
                m={'48px 0 8px'}
              />
              <Text text={errors.email ? errors.email : ''} error />
              <Input
                labelText={t('label.password')}
                type={'password'}
                name={'password'}
                value={values.password}
                onChange={handleChange}
                error={!!errors.password}
                m={'12px 0 8px'}
              />
              <Text m={'0 0 8px'} text={errors.password ? errors.password : ''} error />
              <LinkBtn path={'/forgot-password'} text={t('signIn.forgotPassword')} m={'0 0 16px'} className="d-flex"></LinkBtn>
              <ButtonWrap>
                <SPPrimaryButton width={'100%'} type="submit">{t('signIn.signInBtn')}</SPPrimaryButton>
              </ButtonWrap>
            </fieldset>
          </form>
        )}
      </Formik>
      {
          !props.waitlistRedirect &&<SignUpMobileLink>Don’t have an account?
         <Link to={"/sign-up"}>Sign Up</Link>
        
      </SignUpMobileLink>
      }
      <Text m={'32px 0px 0'} text={error} error />
    </>
  )
}


