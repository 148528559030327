import { changeJobStatus, getJobList } from './../../services/api/jobStore/jobStore.domains';
import { flow, types } from "mobx-state-tree";
import { ChangeJobStatusDto } from '../../services/api/jobStore/jobStore.type';

export const jobs = types
  .model("jobStory", {
    jobList: types.array(types.model({
      _id: types.string,
      creator: types.string,
      jobTitle: types.string,
      creationDate: types.string,
      updatingDate: types.string,
      organization: types.optional(types.string, 'my org'),
      jobStatus: types.string,
      isBuildCompleted: types.boolean,
      reviewsNumber: types.maybeNull(types.number),
      newReviewers: types.maybeNull(types.number),
      steps: types.frozen()
    })),
    listLength: types.maybe(types.number),
    typesLength: types.maybe(types.model({
      allJobs: types.number,
      activeJobs: types.number,
      inactiveJobs: types.number,
      archivedJobs: types.number,
      draftJobs: types.number,
    }))
  })
  .actions(self => {
    const setJobList = flow(function* (page: number, jobStatus: string, sortData: { type: string, value: string }) {
      const responseServer = yield getJobList(page, jobStatus, sortData)
      if (!responseServer.data.err) {
        self.jobList = responseServer.data.data.items;
        self.listLength = responseServer.data.data.listLength;
        self.typesLength = responseServer.data.data.typesLength;
        return { err: '', data: self.jobList }
      } else {
        return { err: responseServer.data.err }
      }
    })

    const updateJobStatus = flow(function* (dto: ChangeJobStatusDto) {
      const responseServer = yield changeJobStatus(dto)
      if (!responseServer.data.err) {
        self.jobList = responseServer.data.data.items;
        self.listLength = responseServer.data.data.listLength;
        self.typesLength = responseServer.data.data.typesLength;
        return { err: '', data: self.jobList }
      } else {
        return { err: responseServer.data.err }
      }
    })

    return { setJobList, updateJobStatus }
  })