import styled from "styled-components"
import { theme } from "../../../../../theme"

export const Wrapper = styled('div')`
  margin-top: ${theme.offsets.sm};
`

export const BannerWrap = styled('div') <{ isUploaded: boolean }>`
  position: relative;
  width: 100%;
  height: 186px;
  border-radius: ${theme.variables.borderRadiusLarge};
  border: ${(props) => props.isUploaded ? 'none' : `1px dashed ${theme.colors.lightGray}`};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media(max-width: ${theme.screens.tablet}) {
    height: 178px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 91px;
    border-radius: ${theme.variables.borderRadiusSmallMedium};
  }
`

export const BannerImg = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;

`

export const UploaderBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg, img{
    max-width: 40px;
    width: 100%;
  }
  @media(max-width: ${theme.screens.mobile}) {
    svg, img{
      display: none;
    }
  }
`

export const UploadButton = styled('button')`
  position: relative;
  height: 52px;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: ${theme.variables.borderRadiusSmall};
  padding: ${theme.offsets.lg} ${theme.offsets.xxl};
  background-color: transparent;
  transition: 300ms all ease-in-out;
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.lg};
  margin-top: ${theme.offsets.xxl};
  font-weight: 500;
  @media(max-width: ${theme.screens.tablet}) {
    height: 42px;
    padding: ${theme.offsets.md} ${theme.offsets.xxl};
    font-size: 14px;
    margin-top: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 38px;
    padding: ${theme.offsets.md} ${theme.offsets.lg};
    margin-top: 0;
  }
  input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    padding:0;
    cursor: pointer;
    width: fill-available;
    font-size: 0;
}
input[type=file]::-webkit-file-upload-button {
    display: none;
}

  &:hover {
    opacity: 0.8;
  }
`

export const DeleteButton = styled('button')`
  z-index: 2;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  svg {
    width: 8px;
    height: 8px;
  }
`