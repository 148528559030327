import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useEffect } from 'react';
import { PropsType, CoordinatesProps, LocationType } from '../Modals.props';
import { StyleForModal } from '../../Introduction/Introduction.presents';
import closeIcon from '../../../../../assets/img/close.svg';
import linkedInIcon from '../../../../../assets/img/jobStory/linkedinMaps.svg';
import facebookIcon from '../../../../../assets/img/jobStory/facebookMaps.svg';
import twitterIcon from '../../../../../assets/img/jobStory/twitterMaps.svg';
import instagramIcon from '../../../../../assets/img/jobStory/instagramMaps.svg';
import youtubeIcon from '../../../../../assets/img/jobStory/youtubeMaps.svg';
import { setCoordinatesForHiringLocation, setCoordinatesForPrimaryLocation } from './LocationModal.helper';
import { SimpleMap } from './Map';
import { linkHandler } from '../../../../../services/helper';
import useGetDevice from '../../../../../hooks/useGetDevice';
import { CloseButton, ContainerMap, ContentWrap, LocationItem, LocationTitle, LocationValue, LocationWrap, LogoWrap, MainInfoWrap, MobileCloseButton, OrganizationName } from './LocationModal.presents';


export const LocationModal = observer((props: PropsType) => {
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [locationData, setLocationData] = useState<Array<CoordinatesProps>>();
  const [coordinates, setCoordinates] = useState<Array<CoordinatesProps>>([{ label: "", value: "", latitude: "", longitude: "" }]);
  const [centerCoord, setCenterCoord] = useState<any>();
  const isDesktop = useGetDevice();

  const logo = props.companyLogo.fileUrl;
  const contactStepAnswers = props.contactStep;
  const isHaveWebsite = contactStepAnswers && (contactStepAnswers.contactLinkedIn || contactStepAnswers.contactFacebook || contactStepAnswers.contactTwitter || contactStepAnswers.contactYoutube || contactStepAnswers.contactInstagram);

  const isLocationHiring = props.locations.locationType === LocationType.hiring;
  const isLocationPrimary = props.locations.locationType === LocationType.primary;
  const isLocationOrganization = props.locations.locationType === LocationType.organization;

  const setLocationDataToState = (validLocation: Array<CoordinatesProps>, coordinates: Array<CoordinatesProps>) => {
    setCoordinates(coordinates);
    setLocationData(validLocation);
    validLocation.length > 0 && setCenterCoord({
      lat: Number.parseInt(validLocation[0].latitude),
      lng: Number.parseInt(validLocation[0].longitude),
      value: validLocation[0].value
    })

  }

  useEffect(() => {
    (async () => {
      let validLocation: Array<CoordinatesProps> = [];
      let coordinatesState: Array<CoordinatesProps> = [];
      const propsData = {
        locationsData: props.locations.locationsData,
        coordinates: coordinatesState,
        validLocation,
        setLocationDataToState
      }
      if (isLocationHiring) {
        setCoordinatesForHiringLocation(propsData);
      } else {
        setCoordinatesForPrimaryLocation(propsData);
      }
    })()
  }, [props.locations.locationsData[0]])//re-run the effect when first( empty by default ) location are change to location from the State

  const isVisible = (fieldName: string) => {
    return props.contactStep && props.contactStep[fieldName] && props.contactStep[fieldName].includes('visible');
  };
  const isContactBlockVisible = isVisible('fullNameVisible') || isVisible('emailVisible') || isVisible('phoneVisible');

  return <StyleForModal map show={props.openMapsWindow} onHide={props.handleCloseMapsWindow} centered className='pl-0'>
    <ContainerMap isDesktop={isDesktop} >
      {!isDesktop && <MobileCloseButton onClick={() => props.handleCloseMapsWindow()}>
        <img src={closeIcon} alt="close" />
      </MobileCloseButton>}
      <ContentWrap isDesktop={isDesktop}>
        <MainInfoWrap isDesktop={isDesktop}>
          {isDesktop && <CloseButton onClick={() => props.handleCloseMapsWindow()}>
            <img src={closeIcon} alt="close" />
            Close
          </CloseButton>}
          <LogoWrap>
            {logo && <img src={logo} alt="logo" />}
            <OrganizationName>{props.organizationName}</OrganizationName>
          </LogoWrap>
          {props.contactStep ? <div className='main-info'>
            {(contactStepAnswers && (isHaveWebsite || contactStepAnswers.contactWebsite)) && <div className='sites'>
              {contactStepAnswers.contactWebsite && <div>
                <p className='subtitle'>Website</p>
                <a target="_blank" className='text' href={linkHandler(props.contactStep.contactWebsite)}>{props.contactStep.contactWebsite}</a>
              </div>}
              {isHaveWebsite && <div className='second-item'>
                <p className='subtitle'>Social Media</p>
                <div className='social-links'>
                  {props.contactStep.contactLinkedIn && <a target="_blank" href={linkHandler(props.contactStep.contactLinkedIn)}><img src={linkedInIcon} alt="icon" /></a>}
                  {props.contactStep.contactFacebook && <a target="_blank" href={linkHandler(props.contactStep.contactFacebook)}><img src={facebookIcon} alt="icon" /></a>}
                  {props.contactStep.contactTwitter && <a target="_blank" href={linkHandler(props.contactStep.contactTwitter)}><img src={twitterIcon} alt="icon" /></a>}
                  {props.contactStep.contactYoutube && <a target="_blank" href={linkHandler(props.contactStep.contactYoutube)}><img src={youtubeIcon} alt="icon" /></a>}
                  {props.contactStep.contactInstagram && <a target="_blank" href={linkHandler(props.contactStep.contactInstagram)}><img src={instagramIcon} alt="icon" /></a>}
                </div>
              </div>}
            </div>}
            {isContactBlockVisible && <div className='contact-info'>
              {isVisible('fullNameVisible') && <div>
                <p className='subtitle'>Contact Person</p>
                <p className='text'>{props.contactStep.fullName}</p>
              </div>}
              {isVisible('emailVisible') && <div className='second-item'>
                <p className='subtitle'>Email</p>
                <p className='d-flex flex-column'><span onClick={() => setOpenEmail(!openEmail)} className='view-btn'>{!openEmail ? 'View' : 'Hide'}</span> {openEmail && <span className='text'>{props.contactStep.email}</span>}</p>
              </div>}
              {isVisible('phoneVisible') && <div className='second-item'>
                <p className='subtitle'>Phone number</p>
                <p className='d-flex flex-column'> <span onClick={() => setOpenPhone(!openPhone)} className='view-btn'>{!openPhone ? 'View' : 'Hide'}</span>{openPhone && <span className='text'>{props.contactStep.phone}</span>}</p>
              </div>}
            </div>}
          </div> :
            null
          }
          <LocationWrap>{
            locationData?.map((item, index) => <LocationItem
              key={index}
              onClick={() => {
                setCenterCoord({
                  lat: Number.parseInt(item.latitude),
                  lng: Number.parseInt(item.longitude),
                  value: item.value
                });
              }}
            >
              {isLocationPrimary && <LocationTitle isActive={centerCoord && centerCoord.value === item.value}>{index > 1 ? 'Secondary Location' : 'Primary Location'}</LocationTitle>}
              {isLocationHiring && <LocationTitle isActive={centerCoord && centerCoord.value === item.value}>Location</LocationTitle>}
              {isLocationOrganization && <LocationTitle isActive={centerCoord && centerCoord.value === item.value}>{index > 1 ? 'Office:' : 'Headquarter:'}</LocationTitle>}
              <LocationValue>{item.label}</LocationValue>
            </LocationItem>)
          }</LocationWrap>
        </MainInfoWrap>
      </ContentWrap>
      <ContentWrap isDesktop={isDesktop}>
        <SimpleMap coordinates={coordinates} centerCoord={centerCoord} setCenterCoord={setCenterCoord} />
      </ContentWrap>
    </ContainerMap>
  </StyleForModal >
})