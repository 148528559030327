import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const productionFirebaseConfig = {
  apiKey: "AIzaSyCtQ3vz4MJdwx14lwBMX9jCQYIPPG5uZtQ",
  authDomain: "sidepost.firebaseapp.com",
  projectId: "sidepost",
  storageBucket: "sidepost.appspot.com",
  messagingSenderId: "214600498629",
  appId: "1:214600498629:web:587704750b152a2043dfac",
  measurementId: "G-533F5SV7W2"
};


const stagingfirebaseConfig = {
    apiKey: "AIzaSyCiAABfj0l7UsxFNSrqu4cuh8VQ8fn8HdA",
    authDomain: "sidepost-test.firebaseapp.com",
    projectId: "sidepost-test",
    storageBucket: "sidepost-test.appspot.com",
    messagingSenderId: "709658645153",
    appId: "1:709658645153:web:555085db01e0cd0456d6e0",
    measurementId: "G-X7KN8B4LZQ"
  };

const firebaseConfig = window.location.hostname === 'sidepost.com'? productionFirebaseConfig: stagingfirebaseConfig
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
export const socialMediaAuth = {
  "Google":{provider: googleProvider, auth: GoogleAuthProvider},
  "Facebook":{provider: facebookProvider, auth: FacebookAuthProvider},
}
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
export {onAuthStateChanged } from "firebase/auth"