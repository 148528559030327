import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { ContentWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import {
  VideoPlayerWrap,
  PlayerWrap,
  RemoveVideoBtn,
} from "../../../VideoCV/VideoCV.presents";
import { ReactComponent as CloseIcon } from '../../../../../assets/img/close.svg';
import { ReactComponent as CameraIcon } from "../../../../../assets/img/cameraGray.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/img/playBtnGray.svg";
import { VideoPlayer } from "../../../BaseComponents/VideoPlayer/VideoPlayer";
import { VideoRecord } from "../../../BaseComponents/VideoRecord/VideoRecord";
import { ButtonWrap, VideoButton, ErrorWrap } from "./VideoPresentation.presents";
import { RemoveModalWindow } from "../../../VideoCV/RemoveModalWindow";
import { onUploadVideoResume } from "../../../../../services/firebase/firestore/writes";
import { ErrorMsg } from "../../../../../theme/typography";
import { SubtitleNoMg, SubtitleTutorial } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard.presents";

export const VideoPresentation = ({
  isDesktop,
  componentRef
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}) => {
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState({ isOpen: false, isRemoveContent: true });
  const [isRecordWindowOpen, setRecordIsWindowOpen] = useState(false);
  const [isSimplifiedTutorial, setSimplifiedTutorial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraBlocked, setIsCameraBlocked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [playerWrapperWidth, setPlayerWrapperWidth] = useState<number | undefined>();

  const startRecordingHandler = async () => {
    setIsLoading(true);
    const streamResult = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    }).then((stream) => {
      setSimplifiedTutorial(false);
      setRecordIsWindowOpen(true);
      setIsLoading(false);
    })
      .catch((err) => {
        setIsCameraBlocked(true);
        setIsLoading(false);
        alert("To record videos, grant access to the camera and microphone permissions on your device.");
      })
    setIsLoading(false);
    //TODO: remove after android test
    console.log(streamResult)

  };

  const showTutorial = () => {
    setSimplifiedTutorial(true);
    setRecordIsWindowOpen(true);
  }

  const inputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const BYTES_IN_MB = 1048576; // 1 megabyte
    const MAX_SIZE = 100 * BYTES_IN_MB;
    if (target.files && target.files[0].size < MAX_SIZE) {
      const file = target.files[0];
      setUploadedFile(file);
      setShowSaveBtn(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Maximum file size is 100 MB');
    }
  };

  const setVideoRecordingHandler = (file: File) => {
    setUploadedFile(file);
    setShowSaveBtn(true);
  };

  const RemoveVideoHandler = (newValue: null) => {
    setUploadedFile(newValue);
    setShowSaveBtn(false);
  };

  const handleUploadResume = async () => {
    if (uploadedFile) {
      const result = await onUploadVideoResume(uploadedFile, candidateStore.uid)
      return result as any
    }
  }

  return <CandidateFormCard
    isDesktop={isDesktop}
    componentRef={componentRef}
    title={'Video presentation'}>
    <Formik
      enableReinitialize
      initialValues={{}}
      validateOnChange={isFirstSubmitComplete}
      validateOnBlur={false}
      onSubmit={async values => {
        setIsLoading(true);
        const resp = await handleUploadResume()
        const result = await resumeBuilderStore.saveVideoResume(resp, candidateStore.uid);
        setIsLoading(false);
        if (!result.error) {
          setIsSaved(true);
          setTimeout(() => {
            setShowSaveBtn(false);
            setIsSaved(false);
          }, 3000);
        };
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => <form onSubmit={handleSubmit}>
          {(uploadedFile || resumeBuilderStore.videoResume.url) && <VideoPlayerWrap>
            <RemoveVideoBtn
              type='button'
              onClick={() => setIsRemoveWindowOpen({ isOpen: true, isRemoveContent: true })}>
              <CloseIcon />
              Delete video
            </RemoveVideoBtn>
            <PlayerWrap ref={el => {
              if (!el) return;
              setPlayerWrapperWidth(el.getBoundingClientRect().width);
            }
            } isResumeBuilder playerWrapperWidth={playerWrapperWidth}>
              <VideoPlayer dataSrc={uploadedFile ? URL.createObjectURL(uploadedFile) : resumeBuilderStore.videoResume.url} />
            </PlayerWrap>
          </VideoPlayerWrap>}

          {(!uploadedFile && !resumeBuilderStore.videoResume.url) && <ButtonWrap>
            <VideoButton type='button' onClick={startRecordingHandler} disabled={isLoading}>
              <CameraIcon />
              Record
            </VideoButton>
            <VideoButton type='button' disabled={isLoading}>
              <input onChange={inputOnChangeHandler} type="file" accept={"video/m4v, video/mp4, video/quicktime"} disabled={isLoading} />
              <UploadIcon />
              Upload
            </VideoButton>
          </ButtonWrap>}

          {(!uploadedFile && !resumeBuilderStore.videoResume.url) && <>
            <SubtitleNoMg center>Video format: M4V, MP4, MOV. Max. File Size: 100MB
            </SubtitleNoMg>
            <SubtitleNoMg center>To record videos, don't forget to grant access to the camera and microphone permissions on your device.
            </SubtitleNoMg>
          </>}

          <SubtitleTutorial onClick={() => showTutorial()}>Watch Tutorial</SubtitleTutorial>

          {isRecordWindowOpen && <VideoRecord
            isResumeBuilder
            isSimplifiedTutorial={isSimplifiedTutorial}
            isWindowOpen={isRecordWindowOpen}
            setIsWindowOpen={setRecordIsWindowOpen}
            setUploadedFile={setVideoRecordingHandler}
          />}

          <RemoveModalWindow
            isResumeBuilder
            setUploadedFile={RemoveVideoHandler}
            setIsRemoveWindowOpen={setIsRemoveWindowOpen}
            isRemoveWindowOpen={isRemoveWindowOpen} />
          {errorMessage && <ErrorWrap>
            <ErrorMsg>{errorMessage}</ErrorMsg>
          </ErrorWrap>}
          {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
            <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved}>
              {isLoading && <Spinner />}
              {isSaved && <div>
                Saved <CheckedGrey />
              </div>}
              {(!isLoading && !isSaved) && 'Save'}
            </SPSecondaryButton>
          </SaveButtonWrap>}
        </form>}
    </Formik>
  </CandidateFormCard>
}
