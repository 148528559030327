import { useState } from "react";
import { Tags } from "../../JobStoryBuilder/BasicComponents/Tags";
import { SPSecondaryButton } from "../buttons/SPSecondaryButton/SPSecondaryButton";
import { ModalWindow } from "../Modal";
import { CustomBenefitsModalProps } from "./Benefits.props";
import { ReactComponent as CheckedIcon } from '../../../assets/img/checked.svg';
import { useRootStore } from "../../../state/stores/storeContext";
import { SubtitleInModal, TitleInModal, BtnsWrap } from "./Benefits.present";
import { SPLightButton } from "../buttons/SPLightButton/SPLightButton";

export const CustomBenefitsModal = (props: CustomBenefitsModalProps) => {
  const [customBenefits, setCustomBenefits] = useState<Array<string>>([]);
  const { jobStoryBuilder } = useRootStore();

  const isVolunteerBenefit = props.fieldName === 'volunteerAbout';

  const addNewBenefit = () => {
    const newBenefits = customBenefits.map(item => {
      return { title: item, value: item };
    })
    if (newBenefits.length > 0) {
      const copyAnswers = [...props.benefitList, ...newBenefits];
      props.setBenefitList(copyAnswers);
      jobStoryBuilder.addNewBenefit(props.fieldName, newBenefits);
      props.setModalOpen(false);
      setCustomBenefits([]);
    }
  }

  return <ModalWindow width="848px" openWindow={props.isModalOpen} closeWindow={() => props.setModalOpen(false)} >
    <TitleInModal>{isVolunteerBenefit ? 'Add more categories:' : 'Add more benefits:'}</TitleInModal>
    <SubtitleInModal>Press enter after each keyword</SubtitleInModal>
    <Tags tags={customBenefits} setTegs={setCustomBenefits} labelText={isVolunteerBenefit ? 'Add more categories:' : ''} />

    <BtnsWrap>
      <SPSecondaryButton width="auto" onClick={() => addNewBenefit()} type="button">
        Done
        <CheckedIcon />
      </SPSecondaryButton>
      <SPLightButton width="auto" onClick={() => props.setModalOpen(false)} type="button">
        Cancel
      </SPLightButton>
    </BtnsWrap>
  </ModalWindow>
}