import { Field } from 'formik';
import styled from 'styled-components'
import { theme } from '../../../theme';
import plusBlackIcon from '../../../assets/img/plusBlack.svg'

export const Container = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  position: relative;
  margin-top: ${theme.offsets.xxl};
  margin-left: ${theme.offsets.xxl};
  margin-right: 100px;
  margin-bottom: 80px;
  ${(props) => !props.isDesktop && `
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: ${theme.offsets.extraBig};
    flex-direction: column;
  `}
  & button{
    font-size: 14px;
  }
`

export const FormWrap = styled('form') <{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 848px;
  ${(props) => !props.isDesktop && `
    margin: 0 ${theme.offsets.lg} ;
    @media(max-width: ${theme.screens.tablet}) {
      margin: 0 ${theme.offsets.md} ;
    }
  `}
`

export const BtnContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  button{
    width: 160px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    button{
    width: 120px;
  }
  }
`

export const OrganizationWrap = styled('div') <{ isDesktop: boolean }>`
  background: ${theme.colors.white};
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.02);
  border-radius: ${theme.variables.borderRadiusXLarge};
  margin: ${theme.offsets.xxl};
  margin-right: 100px;
  padding: ${theme.offsets.major} 0 ${theme.offsets.xxl};
  min-height: 83%;
  ${(props) => !props.isDesktop && `
    margin-right: 0px;
    margin: ${theme.offsets.xxl} ${theme.offsets.lg};
    padding: ${theme.offsets.extraBig} 0 ${theme.offsets.xl};
    //TODO: need to refact Location component for this case
    @media(max-width: ${theme.screens.tablet}) {
      .col-md-6{
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media(max-width: ${theme.screens.mobile}) {
      padding: ${theme.offsets.xl} 0;
      label{
        margin-top: ${theme.offsets.xs};
        font-size: ${theme.fontSizes.md};
      }
    }
  `}
  form{
    margin-top: ${(props) => props.isDesktop ? theme.offsets.major : theme.offsets.md};
  }
  label .optional{
    color: #928D8A;
  }
`

export const StyleForItRecruitmentWrap = styled("div")`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D1D7E3;
  padding-bottom: 24px;
  align-items: center;
  margin-top:12px;
  `
export const StyleForAddCityBtn = styled("button")`
  height: 52px;
  background: #F8DDC3;
  border-radius: 10px;
  border: none;
  padding: 18px 16px;
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${theme.colors.black};
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::after{
    content: "";
    background-image: url(${plusBlackIcon});
    background-repeat: no-repeat;
    background-size: 100%;
    height: 20px;
    width: 20px;
    display: block; 
    margin-left: ${theme.offsets.md};
  }
  :disabled{
    background: #EBEBEB;
  }
`

export const StyleForBtnWrap = styled('div')` 
  width:606px;
  margin: 0 auto;
  &.hiring-location{
    border-top: 1px solid #D1D7E3;
  }
  & button:hover{
    background: #EFC093;
  }
`

export const FieldWrap = styled(Field)`
margin-bottom: ${theme.offsets.md};
input{
  margin-bottom: 0;
}
& label{
  margin-top: ${theme.offsets.md}; 
@media(max-width: ${theme.screens.tablet}) {
  margin-top: ${theme.offsets.xs};
  font-size: ${theme.fontSizes.md};
}  
}
`

export const ButtonWrap = styled("div")`
  max-width: 190px;
  width: 100%;
  margin-top: ${theme.offsets.lg} ;
  margin-left: auto;
  margin-right: 0;
  margin-top: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 160px;
    ${theme.offsets.sm};
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 125px;
  }
`
export const LocationTitle = styled("p") <{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: ${theme.fontSizes.xxl};
  color: ${theme.colors.black};
  margin: ${theme.offsets.xxl} 0 ${theme.offsets.xl};
  ${(props) => !props.isDesktop && `
  font-size: ${theme.fontSizes.xl};
  margin: ${theme.offsets.md} 0;
  `}
`

export const HelperTextTitle = styled('div')`
  display: flex;
  align-items: center;
`

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

export const PreviewBtnContainer = styled('div')`
  margin-left: ${theme.offsets.lg};
  display: flex;
  align-items: center;
  svg{
    margin-right: ${theme.offsets.xs};
    height: 24px;
    width: 24px;
    margin-bottom: 3px;
  }
  button:disabled{
    border: none;
    opacity: 0.5;
  }
  @media(max-width: ${theme.screens.mobile}) {
    button{
      font-size: 0;
    }
  }
`

export const TitleBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  align-items: center;
  padding: ${theme.offsets.sm} ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) {
    padding: ${theme.offsets.sm} ${theme.offsets.md};
  }
`

export const Title = styled('span')`
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
`

export const LabelContent = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Description = styled('p')`
  font-size: 14px;
  line-height: 16px;
  color: #928D8A;
  padding-top: ${theme.offsets.sm};
  max-width: 75%;
  font-weight: 400;
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.md};
  }
`

export const BtnsWrap = styled('div')`
  display: flex;
  button{
    margin-left: ${theme.offsets.md};
  }
`