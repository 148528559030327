import { StyleForWrap } from '../Steps.presents';
import { Container, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { NavBtn } from '../../BasicComponents/NavBtn';
import { useRootStore } from '../../../../state/stores/storeContext';
import { PropsType } from './Contact.props';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { SelectComponent } from './Contact.scheme';
import { StyleForQuestion, StyleForSubtitle, StyleForTitle } from '../Steps.presents';
import { Row } from 'react-bootstrap';
import { HelperText } from '../../../common/HelperText';
import { useEffect, useState } from 'react';
import { handleSubmit, validation } from './Contact.helper';
import { Text } from '../../../common/Text';
import { onKeyDown } from '../Start/StartStep.helper';
import { FocusError } from '../../BasicComponents/FocusError/FocusError';
import * as _ from 'lodash';
import { checkIsJobExist } from '../../../../pages/JobStoryBuilder';

export const ContactStep = observer(({ saveAnswers, jobId, setActiveStep, setDoneStep }: PropsType) => {
  const { jobStoryBuilder, organizationStore, userStore } = useRootStore();
  const history = useHistory();
  const answers = jobStoryBuilder.answers.contactStep;

  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [organizationData, setOrganizationData] = useState(organizationStore);
  const [isSaveBtnClicked, setSaveBtnClicked] = useState(false)

  useEffect(() => {
    setOrganizationData(organizationStore)
  }, [organizationStore.initialValues.name, organizationStore.initialValues])

  const toPrevStep = () => {
    history.push(`/job-story/specification?id=${jobId}`)
    setActiveStep(3)
    setDoneStep(2)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const steps = { active: 4, done: 3 }
    jobId && checkIsJobExist({ jobStoryBuilder, jobId, history, setActiveStep, setDoneStep, steps })
  }, [jobId, jobStoryBuilder, history, setActiveStep, setDoneStep])

  const defaultFullName = `${userStore.firstName} ${userStore.lastName}`;

  const initialValues = {
    ...answers,
    organizationName: answers.organizationName || organizationData.initialValues.name,
    industry: answers.industry || organizationData.initialValues.industry,
    organizationSize: answers.organizationSize || organizationData.initialValues.organizationSize,
    fullName: answers.fullName || defaultFullName,
    phone: answers.phone || userStore.phone,
    email: answers.email || userStore.email
  }
  const updatingUrl = '/job-story/finish';

  const saveResult = async (values: any, validateForm: Function) => {
    setFirstSubmitComplete(true);
    setSaveBtnClicked(true);
    const errors = await validateForm();
    _.isEmpty(errors) && handleSubmit({ values, saveAnswers, updatingUrl: null });
    setSaveBtnClicked(false);
  }

  return <StyleForWrap>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      validate={(values) => validation(values)}
      onSubmit={async (
        values
      ) => {
        handleSubmit({ values, updatingUrl, saveAnswers })
      }}
    >
      {({
        values,
        errors,
        touched,
        validateForm,
        handleChange,
        handleSubmit,
        setFieldTouched,
        setFieldValue
      }) => (
        <>
          <FocusError isSaveBtnClicked={isSaveBtnClicked} />
          <Form onKeyDown={onKeyDown} onSubmit={handleSubmit} className='d-flex flex-column'>
            <Container fluid className="my-auto px-0">
              {
                jobStoryBuilder.steps.stepContact.map((item: any, index: number) => {
                  const dataForSelectComponent = {
                    type: item.componentType,
                    index,
                    item,
                    handleChange,
                    values,
                    setFieldTouched,
                    setFieldValue,
                    errors,
                    organizationData,
                    touched
                  }
                  return <Row key={index + 'question'} className="justify-content-center">
                    <StyleForQuestion className={errors[item.name] ? 'error' : ''}>
                      <StyleForSubtitle>{`Question ${index + 1}/${jobStoryBuilder.steps.stepContact.length}`}{item.optional ?
                        <span className='optional'>Optional</span> : <Text fs={16} text={errors[item.name] && typeof errors[item.name] === 'string' ? errors[item.name] : ''} error />}
                      </StyleForSubtitle>
                      <StyleForTitle>{item.title}{item.helperText && <HelperText autoChangePosition text={item.helperText} />}</StyleForTitle>
                      {item.subtitle && <StyleForSubtitle m={'8px 0 0'}>{item.subtitle}</StyleForSubtitle>}
                      {SelectComponent(dataForSelectComponent)}
                    </StyleForQuestion>
                  </Row>
                }
                )
              }
            </Container>
            <div className='d-flex justify-content-between'>
              <NavBtn callbackFunction={toPrevStep} />
              <div className='d-flex'>
                <NavBtn exit callbackFunction={() => saveResult(values, validateForm)} />
                <NavBtn next callbackFunction={() => setFirstSubmitComplete(true)} />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  </StyleForWrap >
})