import { ReactComponent as TravelingOpportunitiesIcon } from '../../../assets/img/organization/jobCard/travelingOpportunities.svg';
import { ReactComponent as LearningOpportunitiesIcon } from '../../../assets/img/organization/jobCard/learningOpportunities.svg';
import { ReactComponent as VacationOfferedIcon } from '../../../assets/img/organization/jobCard/vacationOffered.svg';
import { ReactComponent as RetirementSavingsOfferedIcon } from '../../../assets/img/organization/jobCard/retirementSavingsOffered.svg';
import { ReactComponent as HealthcareOfferedIcon } from '../../../assets/img/organization/jobCard/healthcareOffered.svg';
import { ReactComponent as HealthReimbursementIcon } from '../../../assets/img/organization/jobCard/healthReimbursement.svg';
import { ReactComponent as HiringUrgentlyIcon } from '../../../assets/img/organization/jobCard/hiringUrgently.svg';
import { ReactComponent as InterviewingNowIcon } from '../../../assets/img/organization/jobCard/interviewingNow.svg';
import { ListItemCard, MainDescriptionCard } from './Jobs.presents';
import currenciesOptions from '../../../assets/staticData/currencies.json';

import { ReactComponent as DirectHireIcon } from '../../../assets/img/organization/jobCard/directHire.svg';
import { ReactComponent as ContractHireIcon } from '../../../assets/img/organization/jobCard/contractHire.svg';
import { ReactComponent as ContractIcon } from '../../../assets/img/organization/jobCard/contract.svg';
import { ReactComponent as InternshipIcon } from '../../../assets/img/organization/jobCard/internship.svg';
import { ReactComponent as TemporaryIcon } from '../../../assets/img/organization/jobCard/temporary.svg';
import { ReactComponent as ProjectBasedIcon } from '../../../assets/img/organization/jobCard/projectBased.svg';
import { ReactComponent as VolunteerIcon } from '../../../assets/img/organization/jobCard/volunteer.svg';
import { ReactComponent as ApprenticeshipIcon } from '../../../assets/img/organization/jobCard/apprenticeship.svg';

import { ReactComponent as AnnualSalaryIcon } from '../../../assets/img/organization/jobCard/annualSalary.svg';
import { ReactComponent as HourlyWageIcon } from '../../../assets/img/organization/jobCard/hourlyWage.svg';
import { ReactComponent as CommissionIcon } from '../../../assets/img/organization/jobCard/commission.svg';
import { ReactComponent as RemoteJobIcon } from '../../../assets/img/organization/jobCard/remoteJob.svg';
import { ReactComponent as SomeRemoteIcon } from '../../../assets/img/organization/jobCard/someRemote.svg';
import { ReactComponent as TemporaryRemoteIcon } from '../../../assets/img/organization/jobCard/temporaryRemote.svg';
import { ReactComponent as CovidRemoteIcon } from '../../../assets/img/organization/jobCard/covidRemote.svg';
import { ReactComponent as InternationalTravelingIcon } from '../../../assets/img/organization/jobCard/internationalTraveling.svg';
import { ReactComponent as DomesticTravelingIcon } from '../../../assets/img/organization/jobCard/domesticTraveling.svg';
import { ReactComponent as ShortTravelingIcon } from '../../../assets/img/organization/jobCard/shortTraveling.svg';
import { ReactComponent as FullRelocationPackIcon } from '../../../assets/img/organization/jobCard/fullRelocationPack.svg';
import { ReactComponent as ParticialRelocationPackIcon } from '../../../assets/img/organization/jobCard/particialRelocationPack.svg';

export const isBenefitExist = (benefitName: string, stepsAnswers: any) => {
  return stepsAnswers && stepsAnswers[benefitName] && stepsAnswers[benefitName].value.length > 0;
}

export const renderBenefitSections = (stepsAnswers: any) => {
  const benefitArray = [];
  for (const item in BenefitSchema) {
    if (isBenefitExist(item, stepsAnswers)) {
      benefitArray.push(<MainDescriptionCard color={BenefitSchema[item].color} bgColor={BenefitSchema[item].bgColor}>
        {BenefitSchema[item].icon}
        <span>
          {BenefitSchema[item].title}
        </span>
      </MainDescriptionCard>)
    }
  }

  return benefitArray;
}

export const setSalaryString = (jobData: any) => {
  const from = jobData.salaryRateFrom ? jobData.salaryRateFrom.value : jobData.hourlyRateFrom ? jobData.hourlyRateFrom.value : '';
  const to = jobData.salaryRateTo ? jobData.salaryRateTo.value : jobData.hourlyRateTo ? jobData.hourlyRateTo.value : '';
  const period = jobData.salaryPeriodSelect ? jobData.salaryPeriodSelect.value : '';
  const currenciesSelect = jobData.salaryFromSelect ? jobData.salaryFromSelect.value : jobData.hourlyFromSelect ? jobData.hourlyFromSelect.value : '';
  const currencies = currenciesOptions.find(item => item.value === (currenciesSelect));
  if (from && to && currencies) {
    const salaryString = `${from} - ${to} ${currencies?.symbol}`;
    const periodString = period ? '/' + period : '';
    return < ListItemCard> {salaryString + periodString}</ListItemCard>;
  } else {
    return '';
  }
}

export const salaryBonuses = [
  { bonusName: 'commisionRate', text: 'Commision' },
  { bonusName: 'bonusAmount', text: 'Bonus' },
  { bonusName: 'signInBonus', text: 'Sign In Bonus' },
]

export const setSalaryBonuses = (jobData: any, bonusName: string, text: string) => {
  const from = jobData[`${bonusName}From`] ? jobData[`${bonusName}From`].value : '';
  const to = jobData[`${bonusName}To`] ? jobData[`${bonusName}To`].value : '';
  const percentage = jobData[`${bonusName}Select`] ? jobData[`${bonusName}Select`].value : '';
  if (from && to && percentage) {
    const salaryString = `${from} - ${to} ${percentage} ${text}`;
    return <ListItemCard>{salaryString}</ListItemCard>;
  } else {
    return '';
  }
}

export const setLocationsListItem = (locations: any) => {
  //Set title for primary location
  let locationsLength = locations.length;
  const firstLocationItem = locations[0];
  const cityName = firstLocationItem.city;
  const countryName = firstLocationItem.country;
  const territoryName = firstLocationItem.territory;
  const helperText = locationsLength > 1 ? `and ${--locationsLength} more` : '';

  const locationString = `${countryName}, ${territoryName}, ${cityName} ${helperText}`;
  return <ListItemCard>
    {locationString}
  </ListItemCard>;
}

//  ___________  Icons/Title schemas ___________
//
//        (every key - is a answer value )
//

export const jobKindIcons: any = {
  directHire: <DirectHireIcon />,
  contractHire: <ContractHireIcon />,
  contract: <ContractIcon />,
  internship: <InternshipIcon />,
  temporary: <TemporaryIcon />,
  projectBased: <ProjectBasedIcon />,
  volunteer: <VolunteerIcon />,
  apprenticeship: <ApprenticeshipIcon />
}

export const compensationData: any = {
  annualSalary: {
    title: 'Salary',
    icon: <AnnualSalaryIcon />
  },
  hourlyWage: {
    title: 'Hourly rate',
    icon: <HourlyWageIcon />
  },
  commission: {
    title: 'Commision only',
    icon: <CommissionIcon />
  },
}

export const remoteJobIcons: any = {
  yes: <RemoteJobIcon />,
  someRemote: <SomeRemoteIcon />,
  temporaryRemote: <TemporaryRemoteIcon />,
  covidRemote: <CovidRemoteIcon />
}

export const travelingData: any = {
  international: {
    icon: <InternationalTravelingIcon />,
    title: 'International traveling'
  },
  domestic: {
    icon: <DomesticTravelingIcon />,
    title: 'Domestic traveling'
  },
  short: {
    icon: <ShortTravelingIcon />,
    title: 'Road trips'
  },
}

export const relocationPackIcons: any = {
  full: <FullRelocationPackIcon />,
  particial: <ParticialRelocationPackIcon />
}

export const hiringPriorityStyles: any = {
  hiring: {
    title: 'Hiring Urgently',
    icon: <HiringUrgentlyIcon />
  },
  now: {
    title: 'Interviewing Now',
    icon: <InterviewingNowIcon />
  }
}

export const BenefitSchema: any = {
  officeOffered: {
    title: 'Traveling Opportunities',
    icon: <TravelingOpportunitiesIcon />,
    color: '#655588',
    bgColor: '#EFE9FE'
  },
  learningOpportunitiesOffered: {
    title: 'Learning Opportunities',
    icon: <LearningOpportunitiesIcon />,
    color: '#D774AA',
    bgColor: '#FFE8FF'
  },
  vacationOffered: {
    title: 'Vacation and Paid Leave',
    icon: <VacationOfferedIcon />,
    color: '#187DFF',
    bgColor: '#EEF6FE'
  },
  retirementSavingsOffered: {
    title: 'Retirement Savings',
    icon: <RetirementSavingsOfferedIcon />,
    color: '#E89314',
    bgColor: '#FFF3DB'
  },
  healthcareOffered: {
    title: 'Health Insurance',
    icon: <HealthcareOfferedIcon />,
    color: '#52A34B',
    bgColor: '#D5FAD6'
  },
  healthReimbursement: {
    title: 'Well-being Perks & Benefits',
    icon: <HealthReimbursementIcon />,
    color: '#4ABCA6',
    bgColor: '#E8F8F5'
  }
}