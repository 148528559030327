import { useState, useEffect, useLayoutEffect } from "react"
import { SPScreensWidth } from "../theme/screens"
const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect

type Range = {from: number, to: number}
type ScreenSize = {width: number, height: number}
type ScreenType = "desktopLarge" | "desktop" | "tablet" | "mobile" | undefined

// Generate ranges from thresholds
// [1, 2, 3] -> [{from: 0, to: 1}, {from: 1, to: 2}, {from: 2, to: 3}]
// For current case last "to" was set as Infinity for tracking more than standard desktop screen width
function getRangesFromThresholds(thresholds: Array<SPScreensWidth>): Array<Range> {
  return thresholds.reduce(
    (prev, current, i) => {
      if (!prev.length) {
        prev.push({
          from: 0,
          to: parseInt(thresholds[0], 10)
        })
        return prev
      } else {
        const previous: Range = prev[prev.length - 1]
        prev.push({
          from: previous.to,
          to: thresholds.length - 1 === i ? Infinity : parseInt(current, 10)
        })
        return prev
      }
    },
    [] as Array<Range>
  )
}

// Return one of "desktop" | "tablet" | "mobile"
// if value (window.innerWidth) will be inside one of ranges
function getCurrentScreenType(
  value: number,
  ranges: Array<Range>,
  thresholds: Array<SPScreensWidth>
): ScreenType {
  let index: number = 0
  for (let i = 0; i < ranges.length; i++) {
    if (value >= ranges[i].from && value <= ranges[i].to) {
      index = i
    }
  }
  return Object
    .keys(SPScreensWidth)
    .find(item => SPScreensWidth[item as keyof ScreenType] === thresholds[index]) as ScreenType
}

export function useWindowSize(
  thresholds: Array<SPScreensWidth> = [
    SPScreensWidth.mobile,
    SPScreensWidth.tablet,
    SPScreensWidth.desktop,
    SPScreensWidth.desktopLarge
  ]
): ScreenSize {

  const [size, setSize] = useState<ScreenSize>({
    width: 1000,
    height: 1000
  })

  const ranges = getRangesFromThresholds(thresholds)

  useIsomorphicLayoutEffect(() => {
    let currentScreenType: ScreenType
    // Handler to call on window resize
    function updateSize() {
      const newScreenType: ScreenType = getCurrentScreenType(window.innerWidth, ranges, thresholds)
      // Checking if screen type should be changed
      currentScreenType !== newScreenType && setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
      currentScreenType = newScreenType
    }
    // Call handler right away so state gets updated with initial window size
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    })

    window.addEventListener("resize", updateSize)

    // Remove event listener on cleanup (componentWillUnmount analog)
    return () => window.removeEventListener("resize", updateSize)

  },                        [])

  return size
}
