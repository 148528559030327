import styled from "styled-components";
import { theme } from "../../../../../../theme";

export const Wrapper = styled('div')`
  background-color: #F8F8F8;
` 

export const HeaderWrap = styled('div')`
  max-width: 1728px;
  padding: 0 120px;
  margin: 0 auto;
  margin-top: ${theme.offsets.xl};
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: ${theme.offsets.md};
    padding: 0 80px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xxl};
    padding: 0 ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.lg};
    margin-top: ${theme.offsets.lg};
  }
`

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: ${theme.offsets.major};
  text-align: center;
  margin: 80px auto ${theme.offsets.extraMajor};
  max-width: 545px;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: ${theme.offsets.extraBig};
    margin: 60px auto ${theme.offsets.extraMajor};
    max-width: 415px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 360px;
    margin: 55px auto ${theme.offsets.extraMajor};
  }
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.offsets.big};
    margin: 40px auto ${theme.offsets.extraBig};
  }
`

export const FormWrap = styled('div')`
  max-width: 830px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 116px;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 740px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 600px;
    margin-bottom: ${theme.offsets.extraMajor};
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 ${theme.offsets.lg} ${theme.offsets.lg};
  }
`
