import { UploadButtonContainer, Wrapper } from './VideoUploadButtons.presets';
import videoUploadIcon from '../../../../assets/img/videoUpload.svg';
import addVideoLinkIcon from '../../../../assets/img/addVideoLink.svg';
import useGetDevice from '../../../../hooks/useGetDevice';

interface UploadButtonProps {
  onChange: any
  isLoading?: boolean
  fileTypes?: string
  setLinkFieldOpen: Function
  isLinkFieldOpen: boolean
}

export const VideoUploadButtons = (props: UploadButtonProps) => {
  const isDesktop = useGetDevice();
  return <Wrapper>
    <UploadButtonContainer
      type="button"
      disabled={props.isLoading}
      isDesktop={isDesktop}>
      Upload
      <img src={videoUploadIcon} alt="Upload video" />
      <input
        disabled={props.isLoading}
        type="file"
        onChange={props.onChange}
        accept={props.fileTypes} />
    </UploadButtonContainer>
    <UploadButtonContainer
      type="button"
      disabled={props.isLoading}
      isDesktop={isDesktop}
      onClick={()=>props.setLinkFieldOpen(!props.isLinkFieldOpen)}
      >
      Add by Link
      <img src={addVideoLinkIcon} alt="Add Link" />
      <input disabled={props.isLoading} />
    </UploadButtonContainer>
  </Wrapper>
}
