
import styled from 'styled-components';
import { theme } from '../../../theme';


const PHOTO_DESKTOP_SIZE = '197px';
const PHOTO_TABLET_SIZE = '156px';
const PHOTO_MOBILE_SIZE = '120px';
const PREVIEW_PHOTO_DESKTOP_SIZE = '136px';
const PREVIEW_PHOTO_TABLET_SIZE = '98px';
const PREVIEW_PHOTO_MOBILE_SIZE = '90px';

const VIDEO_DESKTOP_HEIGHT = '197px';


export const Photo = styled('div') <{ url?: string, isDesktop: boolean, isPreview: boolean }>`
width: ${props => props.isPreview ? PREVIEW_PHOTO_DESKTOP_SIZE : PHOTO_DESKTOP_SIZE};
height:  ${props => props.isPreview ? PREVIEW_PHOTO_DESKTOP_SIZE : PHOTO_DESKTOP_SIZE};
border-radius: ${theme.variables.borderRadiusLarge};
position: relative;
display: flex;
align-items: center;
justify-content: center;
background-color: #F6F8FA;
background-image: url('${props => props.url ? props.url : "none"}');
background-size: cover;
@media (hover: hover) and (pointer: fine) {
  cursor: pointer; 
}
background-position: 50% 50%;
@media(max-width: ${theme.screens.tablet}) {
  width: ${props => props.isPreview ? PREVIEW_PHOTO_TABLET_SIZE : PHOTO_TABLET_SIZE};
  height: ${props => props.isPreview ? PREVIEW_PHOTO_TABLET_SIZE : PHOTO_TABLET_SIZE};
}
  @media(max-width: ${theme.screens.mobile}) {
    width: ${props => props.isPreview ? PREVIEW_PHOTO_MOBILE_SIZE : PHOTO_MOBILE_SIZE};
    height: ${props => props.isPreview ? PREVIEW_PHOTO_MOBILE_SIZE : PHOTO_MOBILE_SIZE};
  }
`

export const PhotoItemContainer = styled('div')`
padding: 0px ${theme.offsets.lg} ${theme.offsets.lg} 0px;
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0px ${theme.offsets.md} ${theme.offsets.md} 0px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: 0px ${theme.offsets.sm} ${theme.offsets.sm} 0px;
  }
`

export const VideoTitle = styled('p')`
font-family: 'Apercu Pro';
font-weight: 400;
font-size: ${theme.fontSizes.lg};
line-height: 20px;
margin: ${theme.offsets.xl} ${theme.offsets.xl} 0px 0px ;

`
export const StyleForVideosWrap = styled('div')`
display: flex;
flex-direction: row;
flex-wrap: wrap;
@media(max-width: ${theme.screens.mobile}) {
flex-direction: column;
padding: 0px ;
}

`

export const StyleForMainVideo = styled('div')`
display: flex;
flex-direction: column;
box-sizing: border-box;
margin: ${theme.offsets.xl} ${theme.offsets.xl} 0px 0px ;
@media(max-width: ${theme.screens.mobile}) {
  margin: 0;
}
`

export const StyleForVideo = styled('div')`
box-sizing: border-box;
display: flex;
flex-direction: column;
margin: ${theme.offsets.xl} ${theme.offsets.xl} 0px 0px ;
height: ${VIDEO_DESKTOP_HEIGHT};

@media(max-width: ${theme.screens.tablet}) {
  height: auto;
}
@media(max-width: ${theme.screens.mobile}) {
  width: 100%;
}
`