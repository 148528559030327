import styled from 'styled-components'
import { theme } from "../../../../theme"
export const StyleForText = styled('p')`
font-family: 'Apercu Pro';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: -0.01em;
padding: ${theme.offsets.sm} 0;
color: ${theme.colors.blue};
@media (hover: hover) and (pointer: fine) {
    cursor: pointer; 
}
padding  { padding:0; margin:0;}
`