import styled from "styled-components";
import { theme } from "../../../../../theme";

export const ButtonWrap = styled('div')`
  display: flex;
  justify-content: center;
`

export const VideoButton = styled('button')`
  width: 125px;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F6F8FA;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: ${theme.variables.borderRadiusLarge};
  font-size: 14px;
  color: #91959D;
  margin: 0 ${theme.offsets.sm};
  position: relative;
  @media(max-width: ${theme.screens.mobile}) {
    width: 88px;
    height: 88px;
    font-size: ${theme.fontSizes.md};
    margin: 0 ${theme.offsets.xs};
  }
  svg{
    margin-bottom: ${theme.offsets.sm};
    @media(max-width: ${theme.screens.mobile}) {
      height: 32px;
      width: 32px;
    }
  }
  input{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }
  input[type=file]::-webkit-file-upload-button {
    display: none;
  }
`

export const ErrorWrap = styled('div')`
  text-align: center;
  margin-top: ${theme.offsets.lg};
`