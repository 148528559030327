import { storage } from "../index";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

export const uploadFile = async (file: File, path: string) => {
    try {
        const storageRef = ref(storage, path);
        await uploadBytes(storageRef, file)
        const url = await getDownloadURL(ref(storage, path));
        return url
    } catch (error) {
        return error
    }
}

export const removeFile = async (path: string) => {
    try {
        const storageRef = ref(storage, path);
        await deleteObject(storageRef);
        return { errer: null }
    } catch (error) {
        return { error }
    }
}