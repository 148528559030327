import { MenuItem, ModalHeader, CloseButton, ItemPoint } from "./MobileSortingModal.presents";
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg';
import { SortingModalWrapper } from "./MobileSortingModal.presents";
import { MobileSortingModalProps } from "./MobileSortingModal.props";

export const MobileSortingModal = (props: MobileSortingModalProps) => {
  const { isOpen, onClose } = props;

  const handleSorting = (index: number) => {
    props.handleMobileSorting(index);
    onClose();
  }

  return <SortingModalWrapper show={isOpen} centered onHide={onClose}>
    <SortingModalWrapper.Body>
      <ModalHeader>
        Sort By
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      {props.mobileSortList.map((item, index) => <MenuItem
        isLastItem={index === props.mobileSortList.length - 1}
        key={index}
        onClick={() => handleSorting(index)}>
        <ItemPoint checked={props.selectedIndex === index} />
        {item.title}
      </MenuItem>)}
    </SortingModalWrapper.Body>
  </SortingModalWrapper>
}