import { db } from "../index";
import { FirebaseUser } from "../firebase.type";
import { setDoc, doc, updateDoc, deleteDoc, collection, addDoc } from "firebase/firestore";
import {onGetAnonymousUserExpertises, onGetAnonymousUserInfo, onGetAnonymousUserPreferences} from "./reads";
import { removeFile, uploadFile } from "../storage";

export const onUpgradeUser = async (user: FirebaseUser) => {
    if (user && user.uid) {
        try {
            const userInfo = await onGetAnonymousUserInfo(user.uid) as FirebaseUser
            const userPreferences = await onGetAnonymousUserPreferences(user.uid) as FirebaseUser
            const userExpertises = await onGetAnonymousUserExpertises(user.uid) as FirebaseUser
            const updatedUserInfo = { ...userInfo, passwordSet: true, isEmailVerified: false }
            const candidate = await setDoc(doc(db, "users", user.uid), updatedUserInfo);
            const userRef = doc(db, "users/" + user.uid);
            const jobRef = collection(userRef, "job")
            await setDoc(doc(jobRef, "preferences"), userPreferences)
            await setDoc(doc(jobRef, "expertises"), userExpertises)
            setDoc(doc(jobRef, "resume"), {"resumeId":user.uid})
            await deleteDoc(doc(db, "anonymous", user.uid));
            const zohoRef = doc(db, "zoho/" + user.uid);
            updateDoc(zohoRef, {
                status: "Registered",
            });
            return candidate
        } catch (error) {
            return error
        }
    }
}

export const onCreateUserPreferences = async (isPanel: boolean, isAnonymous: boolean, uid: string, preferences: any) => {
    try {
        let userRef = doc(db, "anonymous/" + uid);
        if (!isAnonymous) {
            userRef = doc(db, "users/" + uid);
        }
        updateDoc(userRef, { preferencesSet: true });
        const jobRef = collection(userRef, "job")
        await setDoc(doc(jobRef, "preferences"), preferences)
        if(!isPanel){
            setDoc(doc(jobRef, "resume"), {"resumeId":uid})
        }

    } catch (error) {
        return error
    }
}

export const onCreateUserExpertises = async (isPanel: boolean, isAnonymous: boolean, uid: string, expertises: any) => {
    try {
        let userRef = doc(db, "anonymous/" + uid);
        if (!isAnonymous) {
            userRef = doc(db, "users/" + uid);
        }
        updateDoc(userRef, { expertisesSet: true });
        const jobRef = collection(userRef, "job")
        await setDoc(doc(jobRef, "expertises"), expertises)
        if(!isPanel){
            setDoc(doc(jobRef, "resume"), {"resumeId":uid})
        }

    } catch (error) {
        return error
    }
}

export const onPasswordSet = async (uid: string) => {
    try {
        let collRef = doc(db, "users/" + uid);
        await updateDoc(collRef, {
            passwordSet: true,
        });
    } catch (error) {
        return error
    }
}

export const onCreateAnonymous = async (user: FirebaseUser, email: string, state: string) => {
    if (user && user.uid) {
        try {
            const candidate = await setDoc(doc(db, "anonymous", user.uid), {
                email: email,
                state: state,
                type: "Candidate",
                passwordSet: false,
                preferencesSet: false,
                isEmailVerified: false,
                uid: user.uid
            });
            setDoc(doc(db, "zoho", user.uid), {
                email: email,
                type: "Candidate",
                status: "Anonymous",
                uid: user.uid
            });
            return candidate
        } catch (error) {
            return error
        }
    }
}

export const onCreateSocialMediaAccount = async (user: FirebaseUser) => {
    if (user && user.uid) {
        try {
            const candidate = await setDoc(doc(db, "users", user.uid), {
                email: user.email,
                type: "Candidate",
                passwordSet: true,
                preferencesSet: false,
                isEmailVerified: true, 
                uid: user.uid
            });
            setDoc(doc(db, "zoho", user.uid), {
                email: user.email,
                type: "Candidate",
                status: "Registered",
                uid: user.uid
            });
            return candidate
        } catch (error) {
            return error
        }
    }
}


export const onUploadVideoResume = async (file: File, uid: string) => {
    try {
        const path = "candidates/" + uid + "/files/" + file.name
        const url = await uploadFile(file, path)
        const data = {videoResume: {url:url,name:file.name}}
        const resumeRef = doc(db, "users", uid, "job", "resume");
        await updateDoc(resumeRef, data);
        return data
    } catch (error) {
        return error
    }
}

export const onUploadPhoto = async (file: File, uid: string) => {
    try {
        const path = "candidates/" + uid + "/img/" + file.name
        const uploadedLogo = await uploadFile(file, path)
        let collRef = doc(db, "users/" + uid);
        const logo = {
            url: uploadedLogo,
            name: file.name
        };
        await updateDoc(collRef, { logo });
        return logo;
    } catch (error) {
        return error
    }
}

export const onRemovePhoto = async (fileName: string, uid: string) => {
    try {
        const path = "candidates/" + uid + "/img/" + fileName;
        const uploadedLogo = await removeFile(path)
        let collRef = doc(db, "users/" + uid);
        await updateDoc(collRef, {
            logo: null,
        });
        return uploadedLogo;
    } catch (error) {
        return { error }
    }
}

export const onChangePersonalInfo = async (uid: string, data: { firstName: string, lastName: string, email?: string, phone?: string }) => {
    try {
        let collRef = doc(db, "users/" + uid);

        await updateDoc(collRef, data);
        return { error: null };
    } catch (error) {
        return { error }
    }
}

export const onUpdateUserAttributes = async (uid: string, payload: {}) => {
    try {
        let collRef = doc(db, "users/" + uid);
        await updateDoc(collRef, payload);
    } catch (error) {
        return error
    }
}

export const onChangeTutorialProgress = async (uid: string, value: boolean) => {
    try {
        let collRef = doc(db, "users/" + uid);

        await updateDoc(collRef, {
            isTutorialFinished: value
        });
        return { error: null };
    } catch (error) {
        return { error }
    }
}