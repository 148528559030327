import {
  MediaModalWrapper,
  ContactMeHeader,
  Title,
  ButtonWrap,
  QuestionText,
  QuestionWrap,
  Divider
} from "./SignInToViewModal.presets";
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import {SPBaseButton} from "../../../common/buttons/SPBaseButton";
import {AvatarContainer, AvatarWrap} from "../../Resume/Components/ContactInformation/ContactInformation.presents";
import {useHistory} from "react-router-dom";

export const SignInToViewModal = ({
  isOpen,
  onClose,
  isDesktop,
  candidate,
  logoUrl,
                                    firstName
}: {
  isOpen: boolean;
  onClose: () => void;
  isDesktop: boolean;
  candidate: any;
  logoUrl: string;
  firstName?: string
}) => {
  const history = useHistory();

  const handleSign = (route: string) => {
    localStorage.setItem('profileId', window.location.href.split('profile/')[1]);
    history.push(`/candidate/${route}`)
  }


  return <MediaModalWrapper centered show={isOpen} onHide={onClose} >
    <MediaModalWrapper.Body>

      <AvatarWrap isDesktop={true}>
        <AvatarContainer showBorder={!candidate.logo} isDesktop={true}>
          <img src={logoUrl} alt="avatar" />
        </AvatarContainer>
      </AvatarWrap>

      <ContactMeHeader>
        <Title>Sign in to view {firstName}'s resume</Title>
      </ContactMeHeader>

      <ButtonWrap>
        <SPPrimaryButton mobileHeight="44px" width="100%" onClick={()=> handleSign('sign-in')}>
          Sign In
        </SPPrimaryButton>
      </ButtonWrap>

      <QuestionWrap>
        <Divider/>
          <div>
            <QuestionText>
              Don’t have an account?
            </QuestionText>
          </div>
        <Divider/>
      </QuestionWrap>

      <ButtonWrap>
        <SPBaseButton mobileHeight="44px" width="100%" onClick={()=> handleSign('sign-up')}>
          Sign Up
        </SPBaseButton>
      </ButtonWrap>

    </MediaModalWrapper.Body>
  </MediaModalWrapper>
};
