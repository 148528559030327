import { theme } from '../../../theme/theme';
import styled from 'styled-components';

const AVATAR_SIZE = "120px"
const TABLET_AVATAR_SIZE = "80px" 
const MOBILE_AVATAR_SIZE = "65px" 

export const StyleForWrap = styled('div')`
border-radius: 14px 14px 14px 14px;
box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
background: ${theme.colors.white};
margin: ${theme.offsets.xl} auto; 
@media(max-width: ${theme.screens.tablet}) {
  margin: ${theme.offsets.sm} ${theme.offsets.xxl};
}
@media(max-width: ${theme.screens.mobile}) { 
  margin: ${theme.offsets.sm} 0px;
  width: auto;
}   
`

export const CoverWrap = styled('div')<{isDesktop: boolean}>`
  width: 100%;
  height: 550px;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  position: relative;
  @media(max-width: ${theme.screens.desktop}) {
  margin-bottom: -${theme.offsets.extraBig};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: 0;
    border-radius: 0;
  }
  ${props=> !props.isDesktop && `
  height: 240px;
  `}
`

export const Cover = styled('img')`
  width:100%;
  height: 100%;
  object-fit: cover;                          
`

export const MediaContent = styled('div')`
 

  @media(max-width: ${theme.screens.desktop}) {
    padding: ${theme.offsets.lg} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-bottom: 0;
    padding: ${theme.offsets.md} 0;
  }
  @media(max-width: ${theme.screens.tablet}) {
    padding: ${theme.offsets.sm} 0;
  }
`

export const Content = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding:  ${theme.offsets.lg} ;
`

export const BtnsWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 190px;
`

export const ModalBtn = styled('div')`
  max-width: 190px;
  margin-right: ${theme.offsets.xxl};
  width: 100%;
  @media(max-width: ${theme.screens.desktop}) {
    max-width: 167px;
    margin-right: ${theme.offsets.xl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: fit-content;
    margin-right: ${theme.offsets.lg};
  }
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: ${theme.offsets.md};
    & button{
      border: none;
    }
  }
`

export const ModalBtnText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${theme.offsets.lg};
  @media(max-width: ${theme.screens.mobile}) {
    padding: 0 ${theme.offsets.md};
  }
`

export const ShareIcon = styled('img')`
  margin-right: ${theme.offsets.sm};
  transform: rotate(180deg);
  @media(max-width: ${theme.screens.mobile}) {
    margin-right: 0;
  }
`

export const ModalSpan = styled('span')`
  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }
`

export const AvatarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: -80px;
  margin-left: 30px;
  img{
    position: relative;
    width: ${AVATAR_SIZE};
    height: ${AVATAR_SIZE};
    display: flex;
    background: rgba(209, 215, 227, 0.2);
    border: 6px solid ${theme.colors.white};
    border-radius: ${theme.variables.borderRadiusLarge};
    overflow: hidden;   
    @media(max-width: ${theme.screens.desktop}) {
    width: ${TABLET_AVATAR_SIZE};
    height: ${TABLET_AVATAR_SIZE};
    border: 3px solid ${theme.colors.white};
    border-radius: ${theme.variables.borderRadiusSmallMedium};
  } 
  @media(max-width: ${theme.screens.mobile}) {
    width: ${MOBILE_AVATAR_SIZE};
    height: ${MOBILE_AVATAR_SIZE};
  }                                
  }
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: -60px;
    margin-left: ${theme.offsets.lg};
  } 
  @media(max-width: ${theme.screens.desktop}) {
    margin-top: -50px;
    margin-left: 0;
  } 
`

export const OpenMediaPopupBtn = styled('div')`
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  svg{
    cursor: pointer;
    width: 80px;
    height: 80px;
    @media(max-width: ${theme.screens.tablet}) {
      width: 65px;
      height: 65px;
    }
    :hover{
      opacity: 0.9;
  }
  }
`

export const FullGalleryBtn = styled('div')<{isDesktop: boolean}>`
  display: flex;
  align-items: center;
  border-radius: ${(props)=>props.isDesktop ? theme.variables.borderRadiusSmallMedium : theme.variables.borderRadiusSmall };
  padding: ${(props)=>props.isDesktop ? theme.offsets.lg: '10px'};
  background-color: rgba(57, 67, 77, 0.9);
  color: ${theme.colors.white};
  position: absolute;
  right: ${(props)=>props.isDesktop ? theme.offsets.xxl : theme.offsets.lg};
  top: ${(props)=>props.isDesktop ? theme.offsets.xxl : theme.offsets.lg};
  z-index: 10;
  cursor: pointer;
  font-size: ${(props)=>props.isDesktop ? theme.fontSizes.lg : '14px'};
  line-height: ${(props)=>props.isDesktop ? theme.fontSizes.lg : '14px'};
  svg{
    width: 20px;
    height: 20px;
    margin-right: ${theme.offsets.sm};
  }
  :hover{
    background-color: rgba(57, 67, 77);
  }
`

export const OrganizationName = styled('p')`
  margin-top: ${theme.offsets.lg};
  font-size: ${theme.fontSizes.xxl};
  text-transform: capitalize;
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.sm};
    font-size: ${theme.fontSizes.xl};
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
    font-size: 18px;
  }
`

export const OrganizationIndustry = styled('p')`
  font-size: ${theme.fontSizes.lg};
  color: rgba(57, 67, 77, 0.7);
  @media(max-width: ${theme.screens.tablet}) {
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    margin-top: ${theme.offsets.xs};
    font-size: ${theme.fontSizes.md};
  }
`

export const AddCoverContainer = styled('div')`
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  background: ${theme.colors.white};
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.005);
  max-width: 177px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 115px;
  }
  p{
    width: 100%;
    padding: ${theme.offsets.lg};
    text-align: center;
    @media(max-width: ${theme.screens.tablet}) {
      padding: ${theme.offsets.md};
  }
  }
  :hover{
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.085);
  }
`