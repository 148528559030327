import { theme } from '../../../../../theme';
import styled from 'styled-components';

const PHOTO_SIZE = "128px";
const SPINNER_SIZE = "24px";
const REMOVE_ICON_SIZE = "20px";


export const Photo = styled('div')<{url?: string}>`
  width: ${PHOTO_SIZE};
  height: ${PHOTO_SIZE};
  border-radius: ${theme.variables.borderRadiusLarge};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FA;
  background-image: url('${props => props.url ? props.url : "none"}');
  background-size: cover;
  background-position: 50% 50%;
`

export const RemoveIcon = styled('button')`
  width: ${REMOVE_ICON_SIZE};
  height: ${REMOVE_ICON_SIZE};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${theme.offsets.sm};
  right: ${theme.offsets.sm};
  border-radius: 50%;
  border: 1px solid ${theme.colors.black};
  background: ${theme.colors.white};
  
  img {
    width: 8px;
    height: 8px;
  }
`

export const Spinner = styled('img')`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  user-select: none;
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
`
