import { useRootStore } from "../../../../../state/stores/storeContext";
import { OrganizationCard } from "../../../../common/Cards/OrganizationCard";
import { PhotoUploadGallery } from "../../../../common/PhotoUploadGallery/PhotoUploadGallery";
import { MainSectionProps } from "../../Organization.props";
import uploadIcon from "../../../../../assets/img/uploadImg.svg";
import { Wrapper } from "./PhotoGallery.presents";
import { observer } from "mobx-react-lite";
import { HelperTextTitle } from "../../Organization.presents";
import { HelperText } from "../../../../common/HelperText";

export const PhotoGallery = observer((props: MainSectionProps) => {
  const { componentRef, isDesktop } = props;

  const { organizationStore } = useRootStore();

  const UploadGalleryDto = {
    photoFiles: organizationStore.photoGallery,
    saveGalleryPhoto: organizationStore.saveGalleryFile,
    setGalleryPhoto: organizationStore.setGalleryPhoto,
    deleteGalleryPhoto: organizationStore.deleteGalleryFile,
    unsetGalleryPhoto: organizationStore.unsetGalleryPhoto,
    showButton: false,
    btnText: 'Upload',
    icon: uploadIcon
  }

  const isGalleryEmpty = !organizationStore.photoGallery || organizationStore.photoGallery.length === 0;
  const helperText = 'Uploading images make your organization profile more attractive. Maximum size for an image cannot exceed 25MB. Up to 30 images can be uploaded.'
  const TitleRender = () => <HelperTextTitle>
    <span>{'Images'}</span>
    <HelperText text={helperText} />
  </HelperTextTitle>

  return <div id='orgGallery'>
    <OrganizationCard title={<TitleRender />} componentRef={componentRef} isDesktop={isDesktop} width='100%' >
      <Wrapper isGalleryEmpty={isGalleryEmpty} >
        <PhotoUploadGallery {...UploadGalleryDto} />
      </Wrapper>
    </OrganizationCard>
  </div>
})