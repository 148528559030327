import { handleSubmitType } from './StartStep.props'
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const validationScheme = (t: TFunction<"translation">) => Yup.object().shape({
  jobKind: Yup.string()
    .required(t('validation.required')),
  locations: Yup.array()
    .of(
      Yup.object().shape({
        city: Yup.string().required(t('validation.required')),
        territory: Yup.string().required(t('validation.required')),
        zipCode: Yup.string()
          .required(t('validation.required'))
          .matches(/^[a-zA-Z0-9-]+$/, t('validation.zipCode'))
          .max(16, t('validation.max', { characters: 16 })),
        timeZone: Yup.string().required(t('validation.required')),
      })
    ),
  doneRemotely: Yup.string()
    .required(t('validation.required'))
})

export const handleSubmit = async ({ values, createDependentsForStep, stepModel, updatingUrl, saveAnswers }: handleSubmitType) => {
  const dependents = createDependentsForStep(values, stepModel);
  const arrayWithDependents = Array.from(dependents);
  const stepInfo = {
    title: 'start',
    nextStep: 'job',
    done: 0
  };
  saveAnswers({ values, stepInfo, arrayWithDependents, updatingUrl });
}

export const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}