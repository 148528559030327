import { theme } from './../../../../theme/theme';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import checkedIcon from '../../../../assets/img/checked.svg';

export const SwitcherWrap = styled('div')`
  padding: ${theme.offsets.xxl};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
  display: none;
}

`

export const TitleWrap = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  line-height: ${theme.fontSizes.big};
  margin-bottom: ${theme.offsets.xxl};
`

export const ColorsWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${theme.offsets.lg};
  border-bottom: 1px solid ${theme.colors.lightGray};
  margin-bottom: ${theme.offsets.xxl};
`

export const ColorCircleWrap = styled('div') <{ isActive: boolean, disabledCustomizer: boolean }>`
  border-radius: 100%;
  padding: 2px;
  border: 2px solid ${(props) => props.isActive ? theme.colors.lightOrange : theme.colors.white};
  cursor: pointer;
  &:hover{
    border: 2px solid ${(props) => props.disabledCustomizer ? theme.colors.lightGray : theme.colors.lightOrange};
  }  
`

export const ColorItemWrap = styled('div')`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-right: ${theme.offsets.xl};
`

export const ColorItem = styled('div') < { background: string } >`
  width: 40px;
  height: 40px;
  background: ${(props) => props.background};
  border-radius: 100%;
  padding: 2px;
`

export const ColorTitle = styled('p')`
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xl};
  margin-top: ${theme.offsets.lg};
`

export const ImagesWrap = styled(Container)`
  padding-right: 0px;
  padding-left: 0px;
  max-width: 500px;
`

export const ImageCardWrap = styled(Col) <{ isActive: boolean, disabledCustomizer: boolean }>`
padding-right: ${theme.offsets.md};
padding-left: ${theme.offsets.md};
margin-bottom: ${theme.offsets.xxl};
cursor: pointer;
& .img-wrap{
  position: relative;
  ::before{
    content: '';
    display: ${(props) => props.isActive ? 'block' : 'none'};
    position: absolute;
    width: 24px;
    height: 24px;
    background: ${(props) => props.disabledCustomizer ? theme.colors.lightGray : theme.colors.lightOrange};
    border-radius: 100%;
    top: 8px;
    right: 8px;
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
    z-index: 99;
  }
}

& img{
  width: 100%;
  border-radius: ${theme.variables.borderRadiusSmall};
  object-fit: cover;
  border-radius: ${theme.variables.borderRadiusSmall};
  border: 2px solid ${(props) => props.isActive ? theme.colors.lightOrange : theme.colors.white}; 
  position: relative;
  display: block;
}

&:hover img{
  border: 2px solid ${(props) => props.disabledCustomizer ? theme.colors.lightGray : theme.colors.lightOrange};
}

&:hover .img-wrap::before{
  display: block;
}
`

export const ImageCardTitle = styled('p')`
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xl};
  margin-top: ${theme.offsets.sm};
`

export const ImagesCardRow = styled(Row)`
  margin-right: -${theme.fontSizes.md};
  margin-left: -${theme.fontSizes.md};
`

export const ButtonWrap = styled('div')`
  margin-bottom: 0;
  margin-top: auto;
  padding: 16px;
  box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.05);
`

export const PreloaderWrap = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
`