import styled from 'styled-components'

export const StyleForWrap = styled('div')`
display: flex;
flex-direction: column;
height: 100%;
flex: 1;
  .header{
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 100px;
  }
  .content{
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    .title{
      margin-top: 64px;
      font-family: Recoleta;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 44px;
    }
    .text{
      margin-top: 24px;
      font-size: 24px;
      line-height: 28px;
    }
    .brn-wrap{
      width: 260px;
      margin-top: 24px;
    }
  }
`