import { Field, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { passwordValidation } from "../../../services/validationSchemas";
import { Input } from "../../common/Input";
import { ErrorMsg, FieldWrap, SubmitButtonWrap } from "./Profile.presents";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { useState } from "react";
import { useRootStore } from "../../../state/stores/storeContext";
import { observer } from "mobx-react-lite";
import { onReauthenticate, onUpdatePassword } from "../../../services/firebase/auth";
import { CandidateFormCard } from "../../common/Cards/CandidateFormCard/CandidateFormCard";

export const PasswordForm = observer(({
  isDesktop,
  componentRef,
  setResponseResult,
  setModalOpen,
  setResponseResultText,
}: {
  isDesktop: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
  setResponseResult: Function;
  setModalOpen: Function;
  setResponseResultText: Function;
}) => {
  const { t } = useTranslation();
  const { candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onConfirmPassword = async (oldPassword: string, newPassword: string) => {
    try {
      const firebaseResponse: any = await onReauthenticate(candidateStore.email, oldPassword);
      if (firebaseResponse.message) {
        setResponseResult('error');
        setResponseResultText('Old password is incorrect');
      } else {
        await onUpdatePassword(candidateStore.email, newPassword);
        setResponseResult('successfully');
      }
      setModalOpen(true);
    } catch (e) {
      setResponseResult('error');
      setResponseResultText('Old password is incorrect');
      setModalOpen(true);
    }
  }

  return <CandidateFormCard title={'Password'} isDesktop={isDesktop} componentRef={componentRef}>
    <Formik
      validateOnChange={isFirstSubmitComplete}
      validateOnBlur={false}
      initialValues={{ oldPassword: '', password: '', repeatedPassword: '' }}
      validationSchema={Yup.object().shape({
        oldPassword: passwordValidation(t),
        password: passwordValidation(t)
          .notOneOf([Yup.ref("oldPassword"), null], t('validation.mustBeDifferent')),
        repeatedPassword: Yup.string().required()
          .oneOf([Yup.ref("password"), null], t('validation.mustMatch'))
      })}
      onSubmit={(values, { resetForm }) => {
        setIsLoading(true);
        onConfirmPassword(values.oldPassword, values.repeatedPassword);
        setIsLoading(false);
        resetForm();
      }
      }>
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className='d-flex flex-column'>
          <FieldWrap>
            <Field
              as={Input}
              disabled={!!candidateStore.isRegisteredBySocMedia}
              name={'oldPassword'}
              type={'password'}
              value={values.oldPassword}
              onChange={handleChange}
              labelText={t('label.oldPassword')}
              error={!!errors.oldPassword}
            />
            <ErrorMsg>{errors.oldPassword}</ErrorMsg>
          </FieldWrap>

          <FieldWrap>
            <Field
              as={Input}
              disabled={!!candidateStore.isRegisteredBySocMedia}
              name={'password'}
              type={'password'}
              value={values.password}
              onChange={handleChange}
              labelText={t('label.newPassword')}
              error={!!errors.password}
            />
            <ErrorMsg>{errors.password}</ErrorMsg>
          </FieldWrap>

          <FieldWrap>
            <Field
              as={Input}
              disabled={!!candidateStore.isRegisteredBySocMedia}
              name={'repeatedPassword'}
              type={'password'}
              value={values.repeatedPassword}
              onChange={handleChange}
              labelText={t('label.confirmPassword')}
              error={!!errors.repeatedPassword}
            />
            <ErrorMsg>{errors.repeatedPassword}</ErrorMsg>
          </FieldWrap>
          {!candidateStore.isRegisteredBySocMedia && <SubmitButtonWrap>
            <SPSecondaryButton width="100%" type="submit" onClick={() => setIsFirstSubmitComplete(true)} disabled={isLoading}>
              Update Password
            </SPSecondaryButton>
          </SubmitButtonWrap>}

        </form>
      )}
    </Formik>
  </CandidateFormCard>
}) 