import { propTypes } from "react-bootstrap/esm/Image";
import styled from "styled-components";
import { theme } from "../../../theme";


export const Background = styled('div')`
  background: #F0F3F7;

`;

export const Wrapper = styled('div')<{isDesktop: boolean}>`
  display: flex;
  justify-content: center;
  margin: ${theme.offsets.lg} ${theme.offsets.extraBig};
  margin-top: ${theme.offsets.xxl};
  ${(props)=>!props.isDesktop && `
    flex-wrap: wrap;
    margin: ${theme.offsets.lg};
  `}
`;

export const Title = styled('p')`
  font-family: 'Recoleta';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.big};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xxl};
  };
  @media(max-width: ${theme.screens.mobile}) {
    font-size: ${theme.fontSizes.xl};
  };
`;

export const Subtitle = styled('p')`
  font-family: 'Apercu Pro';
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.xl};
  margin: ${theme.offsets.lg} 0;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.lg};
  }
`;
