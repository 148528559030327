import { theme } from './../../../theme/theme';
import styled from 'styled-components'
import { mainStyleType } from '../../../themes/mainStylesType'
import selectedIcon from '../../../assets/img/selected.svg'

export const StyleForSelect = styled("button") <mainStyleType>`
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  width: 100%;
  border: 1px solid #D1D7E3;
  
`

export const StyleForLabel = styled("label")`
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom:8px;
  text-transform: capitalize;
  width: 100%;
  color: ${theme.colors.black};
`

export const SelectWrap = styled("div")`
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  position: relative;
`

export const SelectStyle = styled.div<{ disabled?: boolean, isMulti?: boolean, error?: boolean }>`
  width: 100%;
  input{
    height: auto;
  }
  .css-1fhf3k1-control{
    background-color: #fff!important;
  }
  .css-26l3qy-menu{
    box-shadow: none; 
  }
  .bZJBoj .cl-select__menu .cl-select__menu-list{
    padding: 0;
  }
  .css-1wa3eu0-placeholder{
    color: ${(props) => props.disabled ? 'rgba(67,62,58,0.3)' : theme.colors.gray};
    font-size: 14px;
  }
  .cl-select__control {
    border-radius: 10px;
    border: 1px solid ${(props) => props.error ? theme.colors.lightRed : theme.colors.lightGray}!important;
    cursor: pointer;
    box-shadow: none;
    height: 52px;
    ${(props) => props.isMulti && 'min-height: 52px; height: auto;'};
    @media(max-width: ${theme.screens.tablet}) {
    height: 44px;
    border-radius: ${theme.variables.borderRadiusSmall};
    }
    @media(max-width: ${theme.screens.mobile}) {
      height: 40px;
    }

    .cl-select__value-container {
      font-size: 14px;
      color: #433E3A;
      @media(max-width: ${theme.screens.tablet}) {
      //height: 44px;
    }
      //flex-direction: row-reverse;
    }
    .cl-select__single-value{
      position: relative;
      transform: translateY(0%);
    }

    .cl-select__indicators {
      .cl-select__indicator-separator {
        display: none;
      }
      .cl-select__dropdown-indicator{
        display: ${(props) => props.isMulti ? 'none' : 'block'};
      }

      .cl-select__indicator {
        color: #433E3A;
        opacity:${(props) => props.disabled ? '0.3' : '1'};
      }
    }
  }

  .cl-select__multi-value__label{
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-right: 3px;
  }
  .css-1rhbuit-multiValue{
    background: ${(props) => props.disabled ? '#D1D7E3' : '#E4954D'};
    border-radius: 6px;
    padding: 0 10px;
    height: 36px;
  }
  .cl-select__clear-indicator{
    align-self: flex-start;
    padding: 14px 8px;
    display: ${(props) => props.isMulti ? 'block' : 'none' };
  }
  .cl-select__multi-value__remove svg{
    color: #fff;
  }
  .cl-select__multi-value__remove:hover{
    background-color: #E4954D;
  }
  
  .cl-select__control--menu-is-open {
    border-radius: 10px 10px 0 0;
    .cl-select__indicators {
      .cl-select__indicator {
        transform: rotate(180deg);
      }
    }
  }

  .cl-select__menu {
    border-radius: 10px;
    border: 1px solid #D1D7E3;
    margin: 0;
    border-radius: 0 0 10px 10px !important;
    border-top: none!important;
    //TODO: need to check for another indexes
    z-index: 3;

    .cl-select__menu-list {
      padding: 0 16px;
      
      .cl-select__option {
        padding: 16px 0;
        border-top: 1px solid #D1D7E3;
        font-size: 14px;
        line-height: 16px;
        color: #433E3A;
        cursor: pointer;
      }
      .cl-select__option:first-child{
        border-top: none;
      }
      .cl-select__option--is-focused {
        background: transparent;
        color: #EC923C;
      }
      // .cl-select__option--is-focused::after {
      //   content: url(${selectedIcon});
      //   position: absolute;
      //   right: 16px;
      // }

      .cl-select__option--is-selected {
        background: transparent;
        position: relative;
        color: ${theme.colors.lightOrange}
      }

      .cl-select__option--is-disabled {
        color: ${theme.colors.lightOrange};
        cursor: not-allowed;
      }
      
      }
    
    }
`