import { Modal } from 'react-bootstrap';
import styled from 'styled-components'
import { theme } from '../../../../theme';

export const ModalWrap = styled(Modal)`
padding-right: ${theme.offsets.lg};
@media(max-width: ${theme.screens.tablet}) { 
  padding-right: 0;
}
  .modal-dialog{
    max-width: 703px;  
    @media(max-width: ${theme.screens.tablet}) { 
      margin: 0 ${theme.offsets.lg};
    }
    h1{
      text-align: left;
    }
  }
  
  .modal-content{
    padding: ${theme.offsets.major};
    min-height: 351px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: ${theme.variables.borderRadiusXLarge};
    @media(max-width: ${theme.screens.tablet}) { 
    padding: ${theme.offsets.xl};
    min-height: 250px;
    }
    @media(max-width: ${theme.screens.mobile}) { 
    padding: ${theme.offsets.lg};
    }
  };
  img.close-btn{
    position: absolute;
    right: ${theme.offsets.major};
    top: ${theme.offsets.extraMajor};
    cursor: pointer;
    @media(max-width: ${theme.screens.tablet}) { 
      right: ${theme.fontSizes.lg};
      top: ${theme.fontSizes.lg};
  }
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    font-size: ${theme.fontSizes.big};
    @media(max-width: ${theme.screens.tablet}) { 
      font-size: ${theme.fontSizes.lg};
  }
    @media(max-width: ${theme.screens.mobile}) { 
      font-size: 14px;
    }
    img{
      width:52px;
      margin-bottom: ${theme.offsets.xxl};
      @media(max-width: ${theme.screens.tablet}) { 
        width: 40px;
        margin-bottom: ${theme.offsets.lg};
  }
    }
  }
  .content h1{
    text-align: left;
  }
  .media-uploader-wrap{
    margin: 0 0 ${theme.offsets.major};
  }
  .media-uploader-wrap .second-field{
    border-bottom: 1px solid ${theme.colors.lightGray};
    padding-bottom: ${theme.offsets.xxl};
    margin-top: ${theme.offsets.lg};
  }
  .title{
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.fontSizes.xxl};
    letter-spacing: -0.01em;
    color: ${theme.colors.black};
    @media(max-width: ${theme.screens.tablet}) { 
      font-size: ${theme.fontSizes.lg};
  }
    @media(max-width: ${theme.screens.mobile}) { 
      font-size: 14px;
    }
  }
`

export const BtsWrap = styled('div')`
display: flex;
align-items: center;
align-self: flex-end;
.add-btn, .close-btn{
  height: 52px;
  width: 191px;
  border-radius: ${theme.variables.borderRadiusSmall};
  border: none;
  padding: ${theme.offsets.lg};
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.lg};
  @media(max-width: ${theme.screens.tablet}) { 
    width: auto;
    height: auto;
    padding:  ${theme.offsets.md} ${theme.offsets.major};
  }
  @media(max-width: ${theme.screens.mobile}) { 
    padding:  ${theme.offsets.md} ${theme.offsets.xxl};
  }
}
.add-btn{
  color: ${theme.colors.white};
  justify-content: center;
  background: ${theme.colors.lightOrange};
  box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
  font-weight: 500;
}
.add-btn img{
  width: 18px;
  height: 12px;
}
.close-btn{
  background: ${theme.colors.white};
  color: ${theme.colors.black}
  font-weight: 500;
  border: 1px solid${theme.colors.lightGray};
  box-sizing: border-box;
  filter: drop-shadow(8px 8px 16px rgba(95, 120, 172, 0.05));
  border-radius: ${theme.variables.borderRadiusSmall};
  justify-content: center;
  margin-right: ${theme.offsets.xxl};
}
`