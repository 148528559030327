export enum JobStatus {
  'draft',
  'active',
  'inactive',
  'archived',
  'delete'
}

export type MobileJobItemProps = {
  handleOpenMenu: Function,
  handleOpenModal: Function,
  handleShareJob: Function,
  handleEditJob: Function,
  activeActionBtn: number | null,
  isDesktop: boolean,
  createJob: Function
  handleMobileSorting: Function
  mobileSortList: Array<{ title: string, data: { type: string, value: string }, isChecked: boolean }>
  selectedIndex: number | null,
  history: any
  isSpinnerOpen: boolean
}