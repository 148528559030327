import { StyleForWrap } from './NoteFound.presents';
import logoIcon from '../../../assets/img/logo.svg';
import errorBanner from '../../../assets/img/errorBanner.svg';
import { Button } from '../../common/Button';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const history = useHistory();

  const redirectToHomePage = () => {
    history.push('/sign-in');
  }

  return <StyleForWrap>
    <div className='header'>
      <Link to='/sign-in'>
        <img src={logoIcon} alt="logo" />
      </Link>
    </div>
    <div className='content'>
      <img src={errorBanner} alt="banner" />
      <p className='title'>Ooops!</p>
      <p className='text'>The page you are looking for does not exist!</p>
      <div className='brn-wrap'>
        <Button buttonText={'Go back home'} type='button' onClick={redirectToHomePage} />
      </div>
    </div>
  </StyleForWrap>
}