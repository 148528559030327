import styled from "styled-components";
import { theme } from "../../../../theme";
import { Modal } from 'react-bootstrap';

export const ModalWrap = styled(Modal) <{ fullscreen: boolean, width?: string }>`
padding-left: ${(props) => props.fullscreen ? 0 : theme.offsets.xxl}!important;
padding-right: ${(props) => props.fullscreen ? 0 : theme.offsets.xxl}!important;
display: flex!important;
align-items: center;
justify-content: center;
  .modal-dialog{
    max-width: 1306px;
    width: 100%;
    ${(props) => props.fullscreen && `
      margin: 0px;
      padding-right: 0px!important;
      max-width: 100%;
    `}
    .modal-content{
      padding:40px;
      height: ${(props) => props.fullscreen ? '90vh' : '95vh'};
      max-height: ${(props) => props.fullscreen ? '100vh' : '900px'}; ;
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: ${(props) => props.fullscreen ? '0' : '20px'};
      @media(max-width: ${theme.screens.desktopXXL}) {
        padding:  ${theme.offsets.extraBig};
        ${(props) => props.fullscreen && `
          padding: 0;
        `}
      }
    };
    margin: 0;
    padding: 0;
  }
`
export const CloseWindowBtn = styled('button') <{ isDesktop: boolean }>`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 40px;
  top: 45px;
  ${props => !props.isDesktop && `
    border: 1px solid ${theme.colors.gray};
    border-radius: 100%;
    background-color: ${theme.colors.white};
    z-index: 9;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: ${theme.offsets.lg};
    top: ${theme.offsets.lg};
  `}
`

export const WindowTitle = styled('p') <{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: 22px;
  text-align: left;
  ${props => !props.isDesktop && `
    position: absolute;
    left: ${theme.offsets.lg};
    top: ${theme.offsets.lg};
    color: ${theme.colors.white};
    z-index: 9;
    font-size: ${theme.fontSizes.xl};
  `}
`

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  //max-height: 97%;
`

export const VideoPreview = styled('div') <{ isDesktop: boolean }>`
  width: 100%;
  flex: 1;
  object-fit: contain;
  background-color: #000;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  overflow: hidden;
  margin-top: ${theme.offsets.xxl};
  ${props => !props.isDesktop && `
    margin-top: 0px;
    border-radius: 0px;
  `}
  
  video{
    object-fit: contain;
  }
`

const RecordingButtonStyles = `
  z-index: 2;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const StartButton = styled('button')`
  ${RecordingButtonStyles}
  border: 3px solid ${theme.colors.white};
  background-color: transparent;
  ::before{
    content: '';
    background-color: ${theme.colors.white};
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    position: absolute;
  }
`

export const StopButton = styled('button')`
  ${RecordingButtonStyles}
  border: 3px solid #F74646;
  background-color: #F74646;
  ::before{
    content: '';
    background-color: ${theme.colors.white};
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    position: absolute;
  }
`

export const ActionsWrapper = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${theme.offsets.extraBig};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TimerWrapper = styled('div') <{ isRecording?: boolean }>`
@keyframes dotRecording {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  ::before{
    content: '';
    display: ${props => props.isRecording ? 'block' : 'none'};
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #CE2F2F;
    margin-right: ${theme.offsets.lg};
    animation: dotRecording 1s infinite;
  }
`
export const ActionButtons = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  position: absolute;
  top: ${theme.offsets.xxl};
  right: ${theme.offsets.xxl};
  ${props => !props.isDesktop && `
    top: auto;
    right: auto;
    bottom: 9%;
    transform: translate(-50%, 0);
    button{
      width: 150px;
      height: 44px;
      padding: 0;
      font-size: 14px;
      line-height: 14px;
      border-radius: ${theme.variables.borderRadiusSmall};
      border: none;
      font-weight: 400;
      margin: 0 10px;
      @media(max-width: ${theme.screens.mobile}) {
        margin: 0 ${theme.offsets.xs};
      }
      svg{
        margin-right: ${theme.offsets.sm};
        width: 14px;
        height: 14px;
      }
    }
  `}
`

export const RemoveVideoBtn = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  border: none;
  border-radius: ${theme.variables.borderRadiusSmallMedium};
  padding: ${theme.offsets.lg} ${theme.offsets.extraBig};
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  color: ${theme.colors.black};
  svg{
    width: 16px;
    height: 16px;
    margin-right: ${theme.offsets.lg};
  }
`

export const ActionWrap = styled('div')`
  z-index: 9;
`

export const InfoBlock = styled('div') <{ isDesktop: boolean, isResumeBuilder?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.offsets.xxl};
  button{
    width: 131px;
    margin-left: auto;
    margin-right: 0;
    &:disabled{
      color: ${theme.colors.blue};
      background-color: #EEF6FE;
      border: none;
    }
    ${props => props.isResumeBuilder && `
      background-color: ${theme.colors.lightOrange};
      color: ${theme.colors.white};
      border-color: ${theme.colors.lightOrange};
      &:hover{
        border-color: ${theme.colors.lightOrange};
        background-color: ${theme.colors.lightOrange};
        opacity: 0.9;
      }
      &:disabled{
        background-color: #EAAD72;
        color: ${theme.colors.white};
      }
    `}
  }
  ${props => !props.isDesktop && `
    position: absolute;
    top: 0;
    margin-top: 0;
    color: ${theme.colors.white};
    top: ${theme.offsets.xl};
    left: 50%;
    transform: translate(-50%, 0);
    @media(max-width: ${theme.screens.mobile}) {
      transform: translate(0);
      left: ${theme.offsets.lg};
      top: ${theme.offsets.extraMajor};
    }
  `}
`

export const Circle = styled('div')`
  width: 100px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
`

export const TutorialInfoBlock = styled('div') <{ isDesktop: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.offsets.xxl};
  z-index: 88;
  button{
    max-width: 151px;
    margin-left: auto;
    margin-right: 0;
    z-index: 99;
  }
  ${props => !props.isDesktop && `
    position: absolute;
    bottom: 15%;
    margin-top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    @media(max-width: ${theme.screens.mobile}) {
      width: 100%;
      button{
        max-width: 80%;
        margin: 0 auto;
      }
    }
  `}
`
