import { ReactNode } from "react";
import { OrganizationCardContainer, OrganizationCardContent, Title, TitleWrap, CloseButton, Subtitle } from "./CandidateFormCard.presents";
import { ReactComponent as CloseIcon } from "../../../../assets/img/close.svg";

export const CandidateFormCard = ({
  title,
  isDesktop,
  children,
  closeCardCallback,
  hasCloseButton,
  subtitle,
  componentRef,
  fullScreen,
}: {
  title?: string | ReactNode;
  isDesktop: boolean;
  children: ReactNode;
  closeCardCallback?: () => void;
  hasCloseButton?: boolean;
  subtitle?: string;
  componentRef?: React.RefObject<HTMLDivElement>
  fullScreen?: boolean;
}) => {
  return (
    <OrganizationCardContainer isDesktop={isDesktop} ref={componentRef} fullScreen={fullScreen} >
      <TitleWrap>
        {title && <Title>{title}</Title>}
        {hasCloseButton && <CloseButton type="button" onClick={closeCardCallback}>
          <CloseIcon />
        </CloseButton>}
      </TitleWrap>
      {!!subtitle && <Subtitle>
        {subtitle}
      </Subtitle>}

      <OrganizationCardContent isDesktop={isDesktop} hasTitle={!!title}>
        {children}
      </OrganizationCardContent>
    </OrganizationCardContainer>
  )
}