import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, isAuthenticated, redirect, ...restOfProps }: any) => {

  useEffect(() => {
    if (!isAuthenticated) {
      window.localStorage.setItem('redirectPath', restOfProps.path)
    }
  }, [])

  return <Route
    {...restOfProps}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to={redirect ? redirect : "/sign-in"} />
    }
  />
}