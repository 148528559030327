import { types, flow } from "mobx-state-tree";
import { onCandiateSignIn, onCandiateSignUp, onSetPassword } from '../../services/firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { Candidate, FirebaseUser } from '../../services/firebase/firebase.type';
import { ChangeEvent } from "react";
import {
  onCreateSocialMediaAccount,
  onCreateUserPreferences,
  onUpgradeUser,
  onChangePersonalInfo,
  onUploadPhoto,
  onRemovePhoto,
  onUpdateUserAttributes,
  onChangeTutorialProgress,
  onCreateUserExpertises
} from "../../services/firebase/firestore/writes";
import { onGetUser, onGetUserViaEmail } from "../../services/firebase/firestore/reads";
import { sendWelcomeMessage } from "../../services/api/candidates/candidates.domains";

export const candidate = types
  .model("candidateStore", {
    isAuthenticated: types.maybeNull(types.optional(types.boolean, false)),
    hideWelcomePage: false,
    hideVideoCVPage: false,
    isVerified: false,
    type: 'Candidate',
    preferencesSet: false,
    passwordSet: false,
    isExpertiseSet: false,
    isBackToPreferences: false,
    isAnonymous: true,
    email: '',
    uid: '',
    firstName: '',
    lastName: '',
    logo: types.maybeNull(types.model({
      url: types.string,
      name: types.string
    })),
    phone: '',
    isRegisteredBySocMedia: types.optional(types.boolean, false),
    cv: types.maybeNull(types.string),
    isEmailVerified: types.maybeNull(types.optional(types.boolean, false)),
    isTutorialFinished: types.maybeNull(types.optional(types.boolean, false))
  })
  //Setters
  .actions(self => {
    const setMainInformation = (data: Candidate) => {
      self.isAnonymous = false;
      self.isAuthenticated = true;
      self.email = data.email;
      self.uid = data.uid;
      self.preferencesSet = data.preferencesSet
      self.passwordSet = data.passwordSet
      self.logo = (data.logo && data.logo.url) ? data.logo : null;
      self.firstName = data.firstName || '';
      self.lastName = data.lastName || '';
      self.phone = data.phone || '';
      self.cv = data.cv || null;
      self.hideWelcomePage = data.hideWelcomePage ? data.hideWelcomePage : false
      self.hideVideoCVPage = data.hideVideoCVPage ? data.hideVideoCVPage : false
      self.isEmailVerified = data.isEmailVerified;
      self.isTutorialFinished = data.isTutorialFinished;
    }
    return {
      setMainInformation
    }
  })
  //Actions to modify
  .actions(self => {
    const saveCandidateInfo = (data: Candidate) => {
      self.isAuthenticated = true;
      self.email = data.email;
      self.uid = data.uid;
      self.preferencesSet = data.preferencesSet;
      self.passwordSet = data.passwordSet;
    }

    const clearCandidateInfo = () => {
      self.isAuthenticated = false;
      self.isExpertiseSet = false;
      self.isVerified = false;
      self.hideWelcomePage = false;
      self.hideVideoCVPage = false;
      self.isAnonymous = true;
      self.isRegisteredBySocMedia = false;
      self.isEmailVerified = false;
      self.email = '';
      self.uid = '';
      self.logo = null;
      self.firstName = '';
      self.lastName = '';
      self.phone = '';
      self.cv = null;
      self.isTutorialFinished = false;
      self.preferencesSet = false;
      self.passwordSet = false;
    }

    const setIsPreferences = (state: boolean) => {
      self.preferencesSet = state;
    }

    const setIsBackToPreferences = (state: boolean) => {
      self.isBackToPreferences = state;
    }

    const login = flow(function* (data) {
      try {
        const firebaseResponse = yield onCandiateSignIn(data.email, data.password)
        if (firebaseResponse.message) {
          return { err: 'Email or password is incorrect' }
        }
        saveCandidateInfo(firebaseResponse.user)
        return { err: null }
      } catch (e) {
        return { err: 'Email or password is incorrect' }
      }
    })

    const signUp = flow(function* (candidate) {
      try {
        const firebaseResponse = yield onCandiateSignUp(candidate.email, candidate.state)
        if (firebaseResponse.message) {
          return { err: 'Email exists' }
        }
        self.email = candidate.email;
        self.uid = firebaseResponse.user.uid
        self.isAuthenticated = true;
        self.isAnonymous = true;
        return { err: null }
      } catch (e) {
        return { err: 'Email or password is incorrect' }
      }
    })

    const getCandidate = flow(function* () {
      try {
        const promiseResult = new Promise((resolve, reject) => {
          onAuthStateChanged(auth, async (user: FirebaseUser) => {
            if (user && !user.isAnonymous) {
              const candidateData = await onGetUser(user.uid) as Candidate;
              if (candidateData) {
                await self.setMainInformation(candidateData);
                const HAS_ERROR = false;
                resolve(HAS_ERROR);
              }
            } else {
              const HAS_ERROR = true;
              reject(HAS_ERROR);
            }
          });
        });
        return { err: yield promiseResult }
      } catch {
        return { err: 'auth error' }
      }
    })

    const setPreferences = flow(function* (isPanel, isAnonymous, uid, preferences) {
      try {
        yield onCreateUserPreferences(isPanel, isAnonymous, uid, preferences)
        self.preferencesSet = true
        return { err: null }
      } catch (e) {
        return { err: 'Failed to set preferences' }
      }
    })

    const setExpertises = flow(function* (isPanel, isAnonymous, uid, expertises) {
      try {
        yield onCreateUserExpertises(isPanel, isAnonymous, uid, expertises)
        self.isExpertiseSet = true
        return { err: null }
      } catch (e) {
        return { err: 'Failed to set expertises' }
      }
    })

    const setPassword = flow(function* (password) {
      try {
        yield onSetPassword(self.email, password)
        self.passwordSet = true
        self.isAnonymous = false
        return { err: null }
      } catch (e) {
        return { err: 'Failed to set password' }
      }
    })

    const linkSocialMediaAccount = flow(function* (user) {
      try {
        yield onUpgradeUser(user)
        self.passwordSet = true
        self.isAnonymous = false
        self.isRegisteredBySocMedia = true
        self.isEmailVerified = true
      } catch (e) {
        return { err: 'Failed to link social media account' }
      }
    })

    const loginWithSocialMedia = flow(function* (user) {
      try {
        self.email = user.email
        self.isRegisteredBySocMedia = true
        const candidate = yield onGetUserViaEmail(user.email)
        if (candidate.length > 0) {
          self.setMainInformation(candidate[0]);
        }
        else {
          yield onCreateSocialMediaAccount(user)
          let url = `${window.location.host}/candidate/resume-builder`
          if (window.location.hostname === 'localhost') {
            url = `staging-app.sidepost.com/candidate/resume-builder`
          }
          sendWelcomeMessage({
            email: user.email,
            url:  url
          });
          self.passwordSet = true
          self.isAnonymous = false
          getCandidate()
        }
        self.isEmailVerified = true
        return { err: null }
      } catch (e) {
        return { err: 'Failed to login with social media account' }
      }
    })


    const signOutUser = flow(function* () {
      clearCandidateInfo()
      signOut(auth).then(() => {
        return { err: '' }
      }).catch((error) => {
        return { err: 'error' }
      });
    })

    const uploadUserLogo = flow(function* (event: ChangeEvent<HTMLInputElement>) {
      if (event.target.files) {
        yield removeUserLogo();
        const serverResponse: any = yield onUploadPhoto(event.target.files[0], self.uid);
        self.logo = serverResponse;
      }
    })

    const removeUserLogo = flow(function* () {
      if (self.logo) {
        const serverResponse: any = yield onRemovePhoto(self.logo.name, self.uid);
        if (!serverResponse.error) self.logo = null;
      }
    })

    const updatePersonalInfo = flow(function* (data) {
      const responseValue = yield onChangePersonalInfo(self.uid, data);
      if (!responseValue.error) {
        self.firstName = data.firstName;
        self.lastName = data.lastName;
        self.email = data.email;
        self.phone = data.phone;
      }
      return responseValue
    })

    const redirectFromWelcomePage = flow(function* () {
      try {
        yield onUpdateUserAttributes(self.uid, { hideWelcomePage: true })
        self.hideWelcomePage = true
        return { err: null }
      } catch (e) {
        return { err: 'Failed to hide welcome page' }
      }
    })

    const redirectFromVideoCVPage = flow(function* () {
      try {
        yield onUpdateUserAttributes(self.uid, { hideVideoCVPage: true })
        self.hideVideoCVPage = true
        return { err: null }
      } catch (e) {
        return { err: 'Failed to hide Video CV page' }
      }
    })

    const changeTutorialProgress = flow(function* (value) {
      const responseValue = yield onChangeTutorialProgress(self.uid, value);
      if (!responseValue.error) {
        self.isTutorialFinished = value;
      }
    })

    const setVerficationID = flow(function* (verification_id) {
      try {
        yield onUpdateUserAttributes(self.uid, { verification_id: verification_id })
        return { err: null }
      } catch (e) {
        return { err: 'Failed to set verification_id' }
      }
    })

    const validateCandidateEmail = flow(function* (uid, verification_id) {
      try {
        const candidateData = yield onGetUser(uid)
        if (candidateData.verification_id === verification_id){
          self.isEmailVerified = true
          yield onUpdateUserAttributes(self.uid, { isEmailVerified: true })
        }
        return { err: 'Invalid varification id' }
      } catch (e) {
        return { err: 'Failed to set verification_id' }
      }
    })

    return {
      login,
      signUp,
      signOutUser,
      getCandidate,
      saveCandidateInfo,
      clearCandidateInfo,
      setPreferences,
      setExpertises,
      setPassword,
      uploadUserLogo,
      updatePersonalInfo,
      loginWithSocialMedia,
      linkSocialMediaAccount,
      removeUserLogo,
      redirectFromWelcomePage,
      redirectFromVideoCVPage,
      changeTutorialProgress,
      setVerficationID,
      validateCandidateEmail,
      setIsPreferences,
      setIsBackToPreferences
    }
  })
