import {
  SkillsSubtitle,
  SkillItem,
  SkillsRow,
  SkillsSeparator,
  EducationList,
  EducationType,
  EducationContent,
  ResponsibilitiesList,
  ResponsibilitiesItem,
  MainLanguage,
  LanguageLevel
} from './JobSkills.presents';
import { PropsType } from './JobSkills.props';
import { observer } from 'mobx-react-lite';
import chexboxIcon from '../../../../assets/img/jobStory/chex-box.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { JobStoryCard } from '../../../common/Cards/JobStoryCard';

const SkillsList = [
  { title: 'Must have knowledge, skills and abilities:', keyName: 'mustSkills', color: '#D2F0E3' },
  { title: 'Nice-to-have knowledge, skills and abilities:', keyName: 'niceSkills', color: '#FFE9D5' },
  { title: 'Minimum requirements to be successfull at this job:', keyName: 'requirements', color: '#FFE7F0' },
  { title: 'Professional certifications:', keyName: 'certificationType', color: '#EEF6FE' },
  { title: 'Level of education:', keyName: 'educationLevel', color: '#D2F0E3' },
  { title: 'Preferred majors and subjects:', keyName: 'majorsJob', color: '#EEF6FE' }
]

export const JobSkills = observer(({ jobData, componentRef, bannersUrl }: PropsType) => {
  const { t } = useTranslation();
  const answers = jobData.descriptionStep;

  return <JobStoryCard title='Perfect candiadate has' imageUrl={bannersUrl.skillsBanner} componentRef={componentRef}>
    {SkillsList.map((listItem, index) => {
      const showSection = answers[listItem.keyName] && answers[listItem.keyName].length > 0;
      return showSection ? <div key={index}>
        <SkillsSubtitle>{listItem.title}</SkillsSubtitle>
        <Container fluid>
          <SkillsRow>
            {jobData.descriptionStep[listItem.keyName].map((item: string) => <SkillItem xs={'auto'} color={listItem.color}>{item}</SkillItem>)}
          </SkillsRow>
        </Container>
        <SkillsSeparator />
      </div> : ''
    })}
    <SkillsSubtitle>Essential duties and responsibilities:</SkillsSubtitle>
    <ResponsibilitiesList>
      {jobData.descriptionStep.responsibilities.map((item: string) => <ResponsibilitiesItem><img src={chexboxIcon} alt="-" />{item}</ResponsibilitiesItem>)}
    </ResponsibilitiesList>
    <SkillsSeparator />
    <SkillsSubtitle>Experience and education:</SkillsSubtitle>
    <Row>
      <EducationList fluid className='px-0'>
        {jobData.descriptionStep.jobExperience !== 'no' ? <Col md={4}>
          <EducationType>Years of experience</EducationType>
          <EducationContent>From {jobData.descriptionStep.experienceYearsFrom} to {jobData.descriptionStep.experienceYearsTo} </EducationContent>
        </Col> : ''}
        <Col md={4}>
          <EducationType>Level of experience</EducationType>
          <EducationContent>{t(`jobStory.experience.${jobData.descriptionStep.experience}`)}</EducationContent>
        </Col>
      </EducationList>
    </Row>
    <SkillsSeparator />
    <SkillsSubtitle>Languages:</SkillsSubtitle>
    <Row>
      <EducationList fluid className='px-0'>
        {jobData.descriptionStep.languages.map((item: any) => {
          const isMainLanguage = item.isItmainLanguage.includes('yes');
          return <Col md={4}>
            <EducationType>{item.language}</EducationType>
            <EducationContent>
              <LanguageLevel>{t(`jobStory.languageLevel.${item.level}`)}</LanguageLevel>
              {isMainLanguage && <MainLanguage>(Main language)</MainLanguage>}
            </EducationContent>
          </Col>
        })}
      </EducationList>
    </Row>
  </JobStoryCard>
})