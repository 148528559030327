import styled from 'styled-components';
import { theme } from '../../theme';

export const PageWrap = styled('div')<{isDesktop: boolean}>`
  width: 100%;
  max-width: 545px;
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  form{
    width: 100%;
  }
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 360px;
  }

  @media(max-width: ${theme.screens.mobile}) {
    max-width: 290px;
  }
`

export const Wrapper = styled('div')`
  display: flex;
`

export const PageContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`