import React from 'react';
import { router } from './router.store';
import { user } from './user.store';
import { jobBuilder } from './jobStoryBuilder.store';
import { jobs } from './jobStory.store';
import { candidates } from './candidates.store';
import { organization } from './organization';
import { candidate } from './candidate.store';
import { Instance } from "mobx-state-tree";
import { resumeBuilderModel } from './resumeBuilder';

// TODO: added types to stores
type RootStoreContextValue = {
  userStore: any
  routerStore: any
  jobStoryBuilder: any
  jobStory: any
  candidatesStore: any
  organizationStore: any
  candidateStore: any
  resumeBuilderStore: Instance<typeof resumeBuilderModel>
}

const RootStoreContext = React.createContext<RootStoreContextValue>({} as RootStoreContextValue)

const userStore = user.create()
const routerStore = router.create()
const jobStoryBuilder = jobBuilder.create()
const jobStory = jobs.create()
const candidatesStore = candidates.create()
const organizationStore = organization.create()
const candidateStore = candidate.create()
const resumeBuilderStore = resumeBuilderModel.create()

export const RootStoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <RootStoreContext.Provider value={{
    userStore,
    routerStore,
    jobStoryBuilder,
    jobStory,
    candidatesStore,
    organizationStore,
    candidateStore,
    resumeBuilderStore
  }}>{children}</RootStoreContext.Provider>
}
export const useRootStore = () => React.useContext(RootStoreContext)