import { useEffect, useState } from 'react';
import { generateSignedUrl, s3upload } from '../../../../services/api/jobStoreBuilder/jobStoreBuilder.domains';
import { useRootStore } from '../../../../state/stores/storeContext';
import { PhotoItemContainer, PhotosList } from './PhotoUploadStep.presets';
import { PhotoPreview } from './PhotoPreview/PhotoPreview';
import { UploadButton } from './UploadButton/UploadButton';
import { observer } from 'mobx-react-lite';
import { checkFileSizeInMB } from '../../../../services/helper';


export const PhotoUploadStep = observer(() => {
  const { jobStoryBuilder } = useRootStore();
  const [isLoading, setIsLoading] = useState(false)
  const [photos, setPhotos] = useState<Array<any>>([]);

  const MAX_PHOTO_SIZE = 10
  const MAX_PHOTOS_COUNT = 20
  const UPLOAD_FOLDER = "images"

  useEffect(() => {
    if (jobStoryBuilder.answers.contactStep.teamPhotosFile && !isLoading) {
      const mappedPhotos = jobStoryBuilder.answers.contactStep.teamPhotosFile.map((item: any) => {
        return { url: item.url, key: item.key, isLoading: false }
      })
      setPhotos(mappedPhotos)
    }
  }, [jobStoryBuilder.answers.contactStep.teamPhotosFile, jobStoryBuilder.answers.contactStep.teamPhotosFile.length, isLoading])

  const uploadFile = async (event: any) => {
    if(!event.target.files) return

    let uploadFiles = event.target.files
    uploadFiles = [...uploadFiles].filter((file:any) => checkFileSizeInMB(file.size, MAX_PHOTO_SIZE))

    const uploadLimit = MAX_PHOTOS_COUNT - photos.length
    if (uploadFiles.length > uploadLimit) uploadFiles.length = uploadLimit

    const placeholders = uploadFiles.map(() => { return { url: "", isLoading: true } })
    const initialPhotos = [...photos]
    setPhotos([...photos, ...placeholders])
    setIsLoading(true)
    for (const [i, file] of uploadFiles.entries()) {
      const resp = await generateSignedUrl({filename: file.name, filetype: file.type, folder: UPLOAD_FOLDER});
      if (!resp.data.err) {
        try {
          await s3upload(resp.data.data.url, file)
          const addPhotoDto = {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
            jobId: jobStoryBuilder.jobId
          }
          await jobStoryBuilder.saveGalleryPhoto(addPhotoDto)
          jobStoryBuilder.setGalleryPhoto(addPhotoDto.key, addPhotoDto.url)
          placeholders[i] = { key: resp.data.data.key, url: resp.data.data.fileUrl, isLoading: false }
          setPhotos(() => ([...initialPhotos, ...placeholders]))
        } catch (error) {
          setPhotos([...photos])
        }
      }
    }
    event.target.value = null; // reset input value
    setIsLoading(false)
  }


  const removePhoto = async (fileIndex: number, file: any) => {
    if(isLoading) return
    setIsLoading(true)
    const copiedPhotos = [...photos];
    copiedPhotos[fileIndex].isLoading = true
    setPhotos(copiedPhotos);
    const resp = await jobStoryBuilder.deleteGalleryPhoto(file.key)
    jobStoryBuilder.unsetGalleryPhoto(file.key)
    if (!resp.data.err) {
      copiedPhotos.splice(fileIndex, 1);
    } else {
      copiedPhotos[fileIndex].isLoading = false
    }
    setPhotos([...copiedPhotos]);
    setIsLoading(false)
  }


  return (
    <>
      <PhotosList> 
      {
        !!photos.length && photos.map((item: any, index: number) => 
          <PhotoItemContainer key={index}>
            <PhotoPreview url={item.url} onRemove={()=> removePhoto(index, item)} isLoading={item.isLoading} />
          </PhotoItemContainer>
        )
      }
      </PhotosList>
      {
        photos.length < 20
          ? <UploadButton
              text="Upload Images"
              multiple
              onChange={uploadFile}
              isLoading={isLoading}
              fileTypes="image/*"
            />
        : null
      }
    </>
  )
})
