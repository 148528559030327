import { StyleForWrap } from './Tabs.presents';
import { observer } from 'mobx-react-lite';
import { PropsType } from './Tabs.props';
import { useRootStore } from '../../../../state/stores/storeContext';

export const Tabs = observer((props: PropsType) => {
  const { jobStoryBuilder } = useRootStore();
  const jobAnswers = jobStoryBuilder.answers;
  const isGalleryShow = jobAnswers.contactStep.teamPhotosFile && !!jobAnswers.contactStep.teamPhotosFile.length;

  const tabsList = [
    { title: 'Snapshot', show: true, refLink: 'SnapshotsRef' },
    { title: 'The Job', show: true, refLink: 'JobDescriptionRef' },
    { title: 'Requirements', show: true, refLink: 'JobSkillsRef' },
    { title: 'Benefits & Perks', show: props.isBenefitsShow, refLink: 'JobBenefitsRef' },
    { title: 'The Company', show: true, refLink: 'AboutCompanyRef' },
    { title: 'Photos', show: isGalleryShow, refLink: 'GalleryRef' },
  ]
  return <StyleForWrap preview={props.preview}>
    <div className='tabs-wrap'>
      {
        tabsList.map(item => <span onClick={() => { props.executeScroll(item.refLink) }} className={`listitem ${!item.show && 'd-none'}`}>
          {item.title}
        </span>
        )
      }
    </div>
    <hr />
  </StyleForWrap>
})