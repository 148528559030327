import { Field, Formik } from "formik";
import { useState } from "react";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard";
import { SelectCom } from "../../../../common/Select";
import { ContentWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import genderList from "../../../../../assets/staticData/genderList.json";
import pronounsList from "../../../../../assets/staticData/pronounsList.json";
import ethnicityList from "../../../../../assets/staticData/ethnicityList.json";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { initialDemographicalInfo } from "../../../../../state/stores/resumeBuilder";
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';

export const DemographicalBlock = ({
  isDesktop,
  setDemographicalOpen
}: {
  isDesktop: boolean;
  setDemographicalOpen: (value: boolean) => void;
}) => {
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);

  const { resumeBuilderStore, candidateStore } = useRootStore();
  const cardSubtitle = "SidePost is building the next-gen inclusive recruitment platform. DEI (Diversity, Equity and Inclusion) sits at the heart of this platform. For that we ask these completely optional questions. The information here will not be shown on your resume.";

  return <CandidateFormCard
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    isDesktop={isDesktop}
    title={'Demographical Information'}
    subtitle={cardSubtitle}
  >
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={resumeBuilderStore.demographicalInfo}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveDemographicalInfo({ demographicalInfo: values }, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          errors,
          handleSubmit,
          handleReset
        }) => <form onSubmit={handleSubmit}>

            <FieldWrap>
              <Field
                labelText={'Gender Identity'}
                options={genderList}
                component={SelectCom}
                name={'gender'}
                onChange={() => setShowSaveBtn(true)}
                error={errors.gender}
              />
              <ErrorMsg>{errors.gender}</ErrorMsg>
            </FieldWrap>

            <FieldWrap>
              <Field
                labelText={'Pronouns'}
                options={pronounsList}
                component={SelectCom}
                name={'pronouns'}
                onChange={() => setShowSaveBtn(true)}
                error={errors.pronouns}
              />
              <ErrorMsg>{errors.pronouns}</ErrorMsg>
            </FieldWrap>

            <FieldWrap>
              <Field
                labelText={'Ethnicity'}
                options={ethnicityList}
                component={SelectCom}
                name={'ethnicity'}
                onChange={() => setShowSaveBtn(true)}
                error={errors.ethnicity}
              />
              <ErrorMsg>{errors.ethnicity}</ErrorMsg>
            </FieldWrap>
            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey/>
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveDemographicalInfo({ demographicalInfo: initialDemographicalInfo }, candidateStore.uid);
                await resumeBuilderStore.setDemographicalInfo(initialDemographicalInfo);
                handleReset();
                setDemographicalOpen(false);
              }}
            />}
          </form>
        }
      </Formik>
    </ContentWrap>
  </CandidateFormCard>
}