import { useCallback, useEffect, useState } from 'react'
import { FirstStep } from '../Steps/FirstStep'
import { SecondStep } from '../Steps/SecondStep'
import { ThirdStep } from '../Steps/ThirdStep'
import { PropsType } from './SignUp.props'
import { FourthStep } from '../Steps/FourthStep'
import { useRootStore } from '../../../../../state/stores/storeContext'
import { useHistory } from 'react-router'

export const SignUp = (props: PropsType) => {
  const { routerStore } = useRootStore()
  const history = useHistory();
  const [step, setStep] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState('')

  const nextStep = useCallback(
    () => {
      let newStep = step + 1
      if (newStep > 6) {
        newStep = 1
      }
      setStep(newStep)
    },[step]
  )

  const previousStep = () => {
    const newStep = step - 1;
    setStep(newStep)
  }

  useEffect(() => {
    routerStore.setLocationPath(history.location.pathname)
  }, [history.location.pathname, routerStore])

  const [stepComponent, setStepComponent] = useState(<FirstStep setPhoneNumber={setPhoneNumber} setStep={setStep} />)

  useEffect(() => {

    switch (step) {
      case 1:
        setStepComponent(<FirstStep setPhoneNumber={setPhoneNumber} setStep={setStep} />)
        break
      case 2:
        setStepComponent(<SecondStep nextStep={nextStep} previousStep={previousStep} phoneNumber={phoneNumber} />)
        break
      case 3:
        setStepComponent(<ThirdStep nextStep={nextStep} />)
        break
      case 4:
        setStepComponent(<FourthStep  previousStep={previousStep}/>)
        break
      default:
        setStepComponent(<FirstStep setPhoneNumber={setPhoneNumber} setStep={setStep} />)
    }
  }, [step, nextStep, phoneNumber])

  return stepComponent
}