export const NightIcon = ({ active }: { active?: boolean }) => {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M45 17C45.5523 17 46 17.4477 46 18V28C46 28.5523 45.5523 29 45 29C44.4477 29 44 28.5523 44 28V18C44 17.4477 44.4477 17 45 17Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M39 23C39 22.4477 39.4477 22 40 22H50C50.5523 22 51 22.4477 51 23C51 23.5523 50.5523 24 50 24H40C39.4477 24 39 23.5523 39 23Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M35 9C35.5523 9 36 9.44772 36 10V16C36 16.5523 35.5523 17 35 17C34.4477 17 34 16.5523 34 16V10C34 9.44772 34.4477 9 35 9Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M31 13C31 12.4477 31.4477 12 32 12H38C38.5523 12 39 12.4477 39 13C39 13.5523 38.5523 14 38 14H32C31.4477 14 31 13.5523 31 13Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M47.9999 33.2943C44.6527 34.0621 41.1653 33.9671 37.8649 33.0182C34.5644 32.0693 31.5591 30.2975 29.1308 27.8692C26.7025 25.4409 24.9307 22.4356 23.9818 19.1351C23.0329 15.8347 22.9379 12.3473 23.7057 9.00006L23.7054 9C20.1815 9.8129 16.9393 11.5543 14.3157 14.0434C11.692 16.5325 9.78248 19.6785 8.78531 23.1548C7.78815 26.6312 7.73973 30.311 8.64507 33.8124C9.55041 37.3137 11.3765 40.5089 13.9338 43.0662C16.491 45.6235 19.6862 47.4496 23.1876 48.3549C26.6889 49.2603 30.3688 49.2118 33.8451 48.2147C37.3215 47.2175 40.4675 45.308 42.9566 42.6844C45.4457 40.0607 47.1871 36.8186 48 33.2946L47.9999 33.2943Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.4807 8.02559C23.628 7.9916 23.7812 7.99147 23.9286 8.02521L23.9289 8.02528C24.1875 8.08447 24.4119 8.24401 24.5529 8.46876C24.6938 8.69351 24.7397 8.96506 24.6804 9.22364C23.9505 12.4058 24.0408 15.7212 24.9429 18.8588C25.845 21.9965 27.5293 24.8536 29.8379 27.1621C32.1464 29.4707 35.0035 31.155 38.1412 32.0571C41.2788 32.9592 44.5942 33.0495 47.7764 32.3196C48.0403 32.2591 48.3175 32.3082 48.5446 32.4556C48.7717 32.6031 48.9292 32.8364 48.9813 33.1021L48.9813 33.1024C49.0084 33.2403 49.006 33.3824 48.9744 33.5194C48.1214 37.2173 46.294 40.6195 43.682 43.3726C41.0701 46.1258 37.7688 48.1296 34.1209 49.1759C30.4729 50.2223 26.6114 50.2731 22.9372 49.3231C19.263 48.373 15.9101 46.4568 13.2267 43.7733C10.5432 41.0898 8.62694 37.7369 7.67691 34.0627C6.72688 30.3885 6.7777 26.527 7.82408 22.8791C8.87046 19.2312 10.8743 15.9299 13.6274 13.3179C16.3806 10.706 19.7828 8.87862 23.4807 8.02559ZM22.4265 10.386C19.6594 11.2653 17.1201 12.7613 15.0039 14.7689C12.5098 17.1351 10.6945 20.1258 9.74655 23.4306C8.79861 26.7353 8.75258 30.2335 9.61323 33.5621C10.4739 36.8906 12.2098 39.9281 14.6409 42.3591C17.0719 44.7901 20.1094 46.5261 23.4379 47.3868C26.7664 48.2474 30.2647 48.2014 33.5694 47.2535C36.8741 46.3055 39.8649 44.4902 42.2311 41.9961C44.2387 39.88 45.7347 37.3406 46.614 34.5735C43.604 35.0237 40.5257 34.8237 37.5885 33.9792C34.1253 32.9835 30.9717 31.1244 28.4237 28.5763C25.8756 26.0283 24.0165 22.8747 23.0208 19.4114C22.1763 16.4743 21.9763 13.396 22.4265 10.386Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
