export const UnpaidIcon = ({ active }: { active?: boolean }) => {
  return <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28 50C40.1503 50 50 40.1503 50 28C50 15.8497 40.1503 6 28 6C15.8497 6 6 15.8497 6 28C6 40.1503 15.8497 50 28 50ZM28 52C41.2548 52 52 41.2548 52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M27.4951 17.8499V18.6732C24.6067 18.8326 22.5636 20.851 22.5636 23.5866C22.5636 26.3753 24.09 27.836 27.5656 28.7656V35.4319C25.9452 35.2194 24.6536 34.4492 23.409 33.254C23.2916 33.1478 23.1037 33.0416 22.8689 33.0416C22.3992 33.0416 22 33.4665 22 33.9977C22 34.3695 22.1409 34.6351 22.3992 34.8476C23.9256 36.2021 25.5695 37.0254 27.4951 37.2113V39.1501C27.4951 39.6016 27.8474 40 28.2466 40C28.6458 40 28.998 39.6016 28.998 39.1501V37.2644C31.9335 37.052 34 35.0866 34 32.2448C34 29.5624 32.4971 27.9954 28.9276 27.0924V20.5323C29.9843 20.7182 30.9941 21.2229 32.0274 22.0727C32.2153 22.2321 32.3796 22.2587 32.544 22.2587C33.0372 22.2587 33.4364 21.8603 33.4364 21.3025C33.4364 20.9042 33.225 20.6386 32.9667 20.4261C31.7691 19.4965 30.5479 18.9122 28.998 18.7263V17.8499C28.998 17.3984 28.6458 17 28.2466 17C27.8474 17 27.4951 17.3984 27.4951 17.8499ZM32.1918 32.3776C32.1918 34.1305 30.9472 35.3788 28.9276 35.5115V29.1109C31.4873 29.8545 32.1918 30.8106 32.1918 32.3776ZM24.3718 23.4538C24.3718 21.8337 25.5695 20.5589 27.5656 20.4527V26.7206C25.0059 25.9503 24.3718 24.9942 24.3718 23.4538Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path d="M54 44C54 49.5228 49.5228 54 44 54C38.4772 54 34 49.5228 34 44C34 38.4772 38.4772 34 44 34C49.5228 34 54 38.4772 54 44Z" fill="#F8DDC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M44 52C48.4183 52 52 48.4183 52 44C52 39.5817 48.4183 36 44 36C39.5817 36 36 39.5817 36 44C36 48.4183 39.5817 52 44 52ZM44 54C49.5228 54 54 49.5228 54 44C54 38.4772 49.5228 34 44 34C38.4772 34 34 38.4772 34 44C34 49.5228 38.4772 54 44 54Z" fill={active ? "#E4954D" : "#433E3A"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M40.2929 40.2929C40.6834 39.9024 41.3166 39.9024 41.7071 40.2929L44 42.5858L46.2929 40.2929C46.6834 39.9024 47.3166 39.9024 47.7071 40.2929C48.0976 40.6834 48.0976 41.3166 47.7071 41.7071L45.4142 44L47.7071 46.2929C48.0976 46.6834 48.0976 47.3166 47.7071 47.7071C47.3166 48.0976 46.6834 48.0976 46.2929 47.7071L44 45.4142L41.7071 47.7071C41.3166 48.0976 40.6834 48.0976 40.2929 47.7071C39.9024 47.3166 39.9024 46.6834 40.2929 46.2929L42.5858 44L40.2929 41.7071C39.9024 41.3166 39.9024 40.6834 40.2929 40.2929Z" fill={active ? "#E4954D" : "#433E3A"} />
  </svg>
}
