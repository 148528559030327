import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SPPrimaryButton } from "../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { Input } from "../../../common/Input";
import { SocialNetworksAuth } from "../BasicComponents/SocialNetworks/SocialNetworksAuth";
import { Wrapper, Title, Separator, ErrorText, ButtonWrap, WrapperForNotification } from "./CandidateSignIn.presents";
import * as Yup from 'yup';
import { useHistory, useParams } from "react-router-dom";
import { SPWhiteButton } from "../../../common/buttons/SPWhiteButton/SPWhiteButton";
import { onCandiateSignIn } from "../../../../services/firebase/auth";
import { Text } from "../../../common/Text";
import {useEffect, useRef, useState} from "react";
import { useRootStore } from "../../../../state/stores/storeContext";
import { HelperState } from "./CandidateSignIn.props";
import { StyleForHelperText } from "../../Employer/SignIn/SignIn.presents";
import { LinkBtn } from "../../../common/Links";

export const CandidateSignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState('')
  const { candidateStore } = useRootStore();
  const locationState = history.location.state as HelperState
  const profileId = localStorage.getItem('profileId');
  const wrapperRef: any = useRef(null);


  const redirectToForgotPass = () => {
    history.push('/candidate/forgot-password')
  }

  const initialValues = { email: "", password: "" }

  const signIn = async (values: typeof initialValues) => {
    const res = await candidateStore.login(values);
    if (res.err) {
      setError(res.err)
    }else{
      await candidateStore.saveCandidateInfo(res);
      history.push('/candidate/profile');
    }
  }

  useEffect(() => {
    if (candidateStore.isAuthenticated){
      profileId ? history.push(`/profile/${profileId}`) : history.push('/candidate/resume-builder');
      localStorage.removeItem('profileId');
    }
  }, [candidateStore.isAuthenticated])

  useEffect(() => {
    if (locationState?.prev && wrapperRef) {
      setTimeout(() => wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 500);
    }
  }, [locationState, wrapperRef ])

  

  return <Wrapper ref={wrapperRef}>
    <Title>Sign in to Sidepost</Title>
    {locationState?.helperText ?
      <WrapperForNotification>
        <StyleForHelperText>
          {locationState.helperText}
        </StyleForHelperText>
      </WrapperForNotification>
      :
      <>
        <SocialNetworksAuth />
        <Separator>Or</Separator>
      </>
    }
    
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
        password: Yup.string().min(8, t('validation.min', { characters: 8 })).max(50, t('validation.max', { characters: 50 })).required(t('validation.required'))
      })}
      onSubmit={signIn}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>

          <Field
            as={Input}
            labelText={'Email'}
            type={'text'}
            name={'email'}
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
          />
          <ErrorText>{errors.email}</ErrorText>

          <Field
            as={Input}
            labelText={'Password'}
            type={'password'}
            name={'password'}
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
          />
          <ErrorText>{errors.password}</ErrorText>

          <ButtonWrap>
            <LinkBtn path={"/candidate/forgot-password"} text={t('signIn.forgotPassword')} className="d-flex"></LinkBtn>
          </ButtonWrap>

          <SPPrimaryButton
            width={'100%'}
            type="submit">
            {t('signIn.signInBtn')}
          </SPPrimaryButton>

        </form>
      )}
    </Formik>
    <Text m={'32px 0px'} text={error} error />
  </Wrapper>
}
