export const Teamwork = ({ color }: { color: string; }) => {
  return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="44" height="44" rx="8" fill={color}/>
  <path d="M27.5291 17.77C27.4591 17.76 27.3891 17.76 27.3191 17.77C25.7691 17.72 24.5391 16.45 24.5391 14.89C24.5391 13.3 25.8291 12 27.4291 12C29.0191 12 30.3191 13.29 30.3191 14.89C30.3091 16.45 29.0791 17.72 27.5291 17.77Z" fill="white"/>
  <path d="M30.7916 24.7004C29.6716 25.4504 28.1016 25.7304 26.6516 25.5404C27.0316 24.7204 27.2316 23.8104 27.2416 22.8504C27.2416 21.8504 27.0216 20.9004 26.6016 20.0704C28.0816 19.8704 29.6516 20.1504 30.7816 20.9004C32.3616 21.9404 32.3616 23.6504 30.7916 24.7004Z" fill="white"/>
  <path d="M16.4402 17.77C16.5102 17.76 16.5802 17.76 16.6502 17.77C18.2002 17.72 19.4302 16.45 19.4302 14.89C19.4302 13.29 18.1402 12 16.5402 12C14.9502 12 13.6602 13.29 13.6602 14.89C13.6602 16.45 14.8902 17.72 16.4402 17.77Z" fill="white"/>
  <path d="M16.5511 22.8506C16.5511 23.8206 16.7611 24.7406 17.1411 25.5706C15.7311 25.7206 14.2611 25.4206 13.1811 24.7106C11.6011 23.6606 11.6011 21.9506 13.1811 20.9006C14.2511 20.1806 15.7611 19.8906 17.1811 20.0506C16.7711 20.8906 16.5511 21.8406 16.5511 22.8506Z" fill="white"/>
  <path d="M22.1208 25.87C22.0408 25.86 21.9508 25.86 21.8608 25.87C20.0208 25.81 18.5508 24.3 18.5508 22.44C18.5608 20.54 20.0908 19 22.0008 19C23.9008 19 25.4408 20.54 25.4408 22.44C25.4308 24.3 23.9708 25.81 22.1208 25.87Z" fill="white"/>
  <path d="M18.8708 27.9406C17.3608 28.9506 17.3608 30.6106 18.8708 31.6106C20.5908 32.7606 23.4108 32.7606 25.1308 31.6106C26.6408 30.6006 26.6408 28.9406 25.1308 27.9406C23.4208 26.7906 20.6008 26.7906 18.8708 27.9406Z" fill="white"/>
  </svg>
  
}