import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ErrorText, Title, Wrapper, Description, HelperText } from "./IntroForm.presents";
import * as Yup from 'yup';
import { Input } from "../../../../../common/Input";
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { SelectCom } from "../../../../../common/Select";
import { isHaveJobOptionList } from "./helper";
import { useState } from "react";
import { useRootStore } from "../../../../../../state/stores/storeContext";

export const IntroForm = () => {
  const { t } = useTranslation();
  const initialValues = { email: "", state: "" }
  const { candidateStore } = useRootStore();
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const signUp = async (values: typeof initialValues) => {
    setIsLoading(true);
    const res = await candidateStore.signUp(values);
    if (res.err) {
      setError(res.err)
    }
    window.scrollTo(0, 0);
    setIsLoading(false);
  }

  return <Wrapper>
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ email: '', state: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
        state: Yup.string().required(t('validation.required'))
      })}
      onSubmit={signUp}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Title>
            Create a video resume that gets you hired quickly
          </Title>
          <Description>
            We've revolutionized the traditional text-only resume, giving all job seekers the chance to create an online video resume through our innovative digital platform.
          </Description>
          <Field
            component={SelectCom}
            labelText={'Do you currently have a job?'}
            options={isHaveJobOptionList}
            name={'state'}
            value={values.state}
            error={!!errors.state}
            placeholder='Choose an option'
          />
          <ErrorText>{errors.state}</ErrorText>

          <Field
            as={Input}
            labelText={'Email address'}
            type={'text'}
            name={'email'}
            value={values.email}
            onChange={handleChange}
            error={!!errors.email || error}
            placeholder='Email'
          />
          <ErrorText>{errors.email || error}</ErrorText>

            <SPPrimaryButton
              isLoading={isLoading}
              width={'100%'}
              type="submit">
              Continue
            </SPPrimaryButton>
          <HelperText>Pitch your qualifications and expertise on video</HelperText>
        </form>
      )}
    </Formik>
  </Wrapper>
}
