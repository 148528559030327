import { Center } from '../../common/Wraps/Wraps.presents';
import { Text } from '../../common/Text';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { ReactComponent as GalleryIcon } from "../../../assets/img/organization/gallery.svg";
import { HashLink as Link } from 'react-router-hash-link';
import { WrapForLink } from '../Organization.presents';

export const EmptyGallery = (() => {

    return (
        <>
            <Center>
                <GalleryIcon />
            </Center>

            <Text center fs={16} color='black' m='20px 100px' text={"Your gallery is empty"} />
            <Text center fs={14} lh={1.2} m='20px 100px' text={" Upload videos, images and voice to make your profile more attractive"} />

            <Center>
                <WrapForLink>
                    <Link to='/my-organization#orgGallery'>
                        <SPWhiteButton width={'100%'} >
                            Add Media
                        </SPWhiteButton>
                    </Link>
                </WrapForLink>
            </Center>
        </>
    )
})
