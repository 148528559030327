export const WorkplaceCulture = ({ color }: { color: string; }) => {
  return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="44" height="44" rx="8" fill={color}/>
  <path d="M32 29V32H12V29C12 28.45 12.45 28 13 28H31C31.55 28 32 28.45 32 29Z" fill="white" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 21H15V28H17V21Z" fill="white"/>
  <path d="M21 21H19V28H21V21Z" fill="white"/>
  <path d="M25 21H23V28H25V21Z" fill="white"/>
  <path d="M29 21H27V28H29V21Z" fill="white"/>
  <path d="M33 32.75H11C10.59 32.75 10.25 32.41 10.25 32C10.25 31.59 10.59 31.25 11 31.25H33C33.41 31.25 33.75 31.59 33.75 32C33.75 32.41 33.41 32.75 33 32.75Z" fill="white"/>
  <path d="M31.37 15.7498L22.37 12.1498C22.17 12.0698 21.83 12.0698 21.63 12.1498L12.63 15.7498C12.28 15.8898 12 16.2998 12 16.6798V19.9998C12 20.5498 12.45 20.9998 13 20.9998H31C31.55 20.9998 32 20.5498 32 19.9998V16.6798C32 16.2998 31.72 15.8898 31.37 15.7498ZM22 18.4998C21.17 18.4998 20.5 17.8298 20.5 16.9998C20.5 16.1698 21.17 15.4998 22 15.4998C22.83 15.4998 23.5 16.1698 23.5 16.9998C23.5 17.8298 22.83 18.4998 22 18.4998Z" fill="white"/>
  </svg>
  
}