import { types } from "mobx-state-tree";

const stepStart = [
  {
    name: 'jobKind',
    id: 'step1_jobKind',
    index: 1,
    optional: false,
    title: 'What kind of job are you posting?',
    display: true,
    componentType: 'radio',
    haveDependent: true,
    isItDependent: false,
    answers: [
      {
        title: 'Permanent (FTE)',
        value: 'directHire',
        dependent: [
          'step2_customerFacing',
          'step2_traveling',
          'step4_compensationDirect',
          'step4_equityIncluded',
          'step4_needResume',
          'step4_healthcareOffered',
          'step4_savingsOffered',
          'step4_retirementSavingsOffered',
          'step4_vacationOffered',
          'step4_learningOpportunitiesOffered',
          'step4_officeOffered'
        ]
      },
      {
        title: 'Contract to hire',
        value: 'contractHire',
        dependent: [
          'step2_customerFacing',
          'step2_traveling',
          'step4_compensationDirect',
          'step4_equityIncluded',
          'step4_learningOpportunitiesOffered',
          'step4_needResume',
          'step4_contractLength',
        ]
      },
      {
        title: 'Contract',
        value: 'contract',
        dependent: [
          'step2_customerFacing',
          'step2_traveling',
          'step2_customerFacing',
          'step2_traveling',
          'step4_contractExtendible',
          'step4_compensationDirect',
          'step4_equityIncluded',
          'step4_learningOpportunitiesOffered',
          'step4_needResume',
          'step4_contractLength'
        ]
      },
      {
        title: 'Internship',
        value: 'internship',
        dependent: [
          'step4_contractLength',
          'step4_equityIncluded',
          'step4_needResume',
          'step4_learningOpportunitiesOffered',
          'step4_internshipType',
          'step4_compensationInternship'
        ]
      },
      {
        title: 'Temporary',
        value: 'temporary',
        dependent: [
          'step4_workGoingToBe',
          'step4_compensationTemporary',
          'step4_equityIncluded',
          'step4_needResume',
        ]
      },
      {
        title: 'Project based',
        value: 'projectBased',
        dependent: [
          'step4_workGoingToBe',
          'step4_compensationTemporary',
          'step4_equityIncluded',
          'step4_needResume',
          'step4_fixedBudget',
          'step4_fixedBudgetFrom',
          'step4_fixedBudgetTo',
          'step4_fixedBudgetSelect',
        ]
      },
      {
        title: 'Volunteer',
        value: 'volunteer',
        dependent: [
          'step4_workGoingToBe',
          'step4_internshipType',
          'step4_equityIncluded',
          'step4_needResume',
          'step4_learningOpportunitiesOffered',
          'step4_volunteerAbout'
        ]
      },
      {
        title: 'Apprenticeship',
        value: 'apprenticeship',
        dependent: [
          'step4_contractLength',
          'step4_internshipType',
          'step4_equityIncluded',
          'step4_needResume',
          'step4_whatCandidateNeedLearn',
          //'step4_learningOpportunitiesOffered',
          'step4_salaryRateFrom'
        ]
      },
    ],
  },
  {
    name: 'locations',
    id: 'step1_location',
    index: 2,
    helperText: 'Specify at least one location for this job, even if this job is completely remote or virtual.',
    title: 'Where is the primary location of this job?',
    display: true,
    haveDependent: false,
    isItDependent: false,
    componentType: 'location',
    answers: [
      {
        country: '',
        address: '',
        suite: '',
        city: '',
        territory: '',
        zipCode: '',
        timeZone: ''
      }
    ],
  },
  {
    name: 'doneRemotely',
    id: 'step1_doneRemotely',
    index: 3,
    title: 'Can this work be done remotely?',
    display: true,
    haveDependent: true,
    isItDependent: false,
    componentType: 'radio',
    answers: [
      {
        title: 'Yes',
        value: 'yes',
        dependent: ['step2_hiringLocations']
      },
      {
        title: 'No',
        value: 'no',
        dependent: ['step2_relocationPack']
      },
      {
        title: 'Some remote work is allowed',
        value: 'someRemote',
        dependent: ['step2_relocationPack', 'step2_hiringLocations']
      },
      {
        title: 'Temporary remote',
        value: 'temporaryRemote',
        dependent: ['step2_relocationPack', 'step2_hiringLocations']
      },
      {
        title: 'Temporary remote (COVID-19)',
        value: 'covidRemote',
        dependent: ['step2_relocationPack', 'step2_hiringLocations']
      },
    ],
  }
]

const stepJob = [
  {
    name: 'workShift',
    id: 'step2_workShift',
    index: 1,
    title: 'During which work-shift is this job performed?',
    display: true,
    componentType: 'radio-long',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        title: 'Regular day shift',
        subtitle: '8:00 am - 6:00 pm',
        value: 'regular',
        dependent: [],
        description: ''
      },
      {
        title: 'Morning shift',
        subtitle: '4:00 am - Noon',
        value: 'morning',
        dependent: [],
        description: ''
      },
      {
        title: 'Afternoon shift',
        subtitle: 'Noon - 8:00 pm',
        value: 'afternoon',
        dependent: [],
        description: ''
      },
      {
        title: 'Night shift',
        subtitle: '8:00 pm to 4 am',
        value: 'night',
        dependent: [],
        description: ''
      },
      {
        title: 'Mixed shift',
        subtitle: 'Shifts rotate frequently',
        value: 'mixed',
        dependent: [],
        description: ''
      },
      {
        title: 'Any shift',
        subtitle: 'Flexible hours',
        value: 'any',
        dependent: [],
        description: ''
      },
    ]
  },
  {
    name: 'otherShiftInf',
    id: 'step2_otherShiftInf',
    index: 2,
    title: 'Other Shift Information',
    display: true,
    componentType: 'check-box',
    haveDependent: false,
    isItDependent: false,
    optional: true,
    answers: [
      {
        title: '0-2 hour shift ',
        value: 'two',
        dependent: []
      },
      {
        title: '2-4 hour shift',
        value: 'four',
        dependent: []
      },
      {
        title: '4-6 hour shift',
        value: 'six',
        dependent: []
      },
      {
        title: '6-8 hour shift',
        value: 'eight',
        dependent: []
      },
      {
        title: '8-10 hour shift',
        value: 'ten',
        dependent: []
      },
      {
        title: '10-12 hour shift',
        value: 'twelve',
        dependent: []
      },
      {
        title: '12 + hour shift ',
        value: 'plus',
        dependent: []
      }
    ]
  },
  {
    name: 'weeklyInfo',
    id: 'step2_weeklyInfo',
    index: 3,
    title: 'Weekly Schedule Information',
    display: true,
    componentType: 'check-box',
    haveDependent: false,
    isItDependent: false,
    optional: true,
    answers: [
      {
        title: 'Once a week',
        value: 'onceAWeek',
        dependent: []
      },
      {
        title: 'Twice a week',
        value: 'twiceAWeek',
        dependent: []
      },
      {
        title: 'Three times a week',
        value: 'treeAWeek',
        dependent: []
      },
      {
        title: 'Four times a week',
        value: 'fourAWeek',
        dependent: []
      }
      ,
      {
        title: 'Five times a week',
        value: 'fiveAWeek',
        dependent: []
      }
      ,
      {
        title: 'Six times a week',
        value: 'sixAWeek',
        dependent: []
      },
      {
        title: 'Every day',
        value: 'everyDay',
        dependent: []
      }
    ]
  },
  {
    name: 'sideJob',
    id: 'step2_sideJob',
    index: 4,
    title: 'Can this work be done as a side-job?',
    display: true,
    componentType: 'radio',
    haveDependent: false,
    isItDependent: false,
    helperText: "Side-job is a job undertaken in addition to one's main occupation, as a supplementary source of income. By selecting this you allow the candidates to keep their current job.",
    answers: [
      {
        title: 'Yes',
        value: 'yes',
        dependent: [],
      },
      {
        title: 'No',
        value: 'no',
        dependent: [],
      },
    ]
  },
  {
    name: 'jobStart',
    id: 'step2_jobStart',
    index: 5,
    title: 'When does this job start?',
    display: true,
    componentType: 'radio-long',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        title: 'Interviewing now',
        value: 'now',
        dependent: [],
      },
      {
        title: 'Hiring urgently',
        value: 'hiring',
        dependent: [],
      },
      {
        title: 'In two to three weeks',
        value: '3weeks',
        dependent: [],
      },
      {
        title: 'In four to eight weeks',
        value: '8weeks',
        dependent: [],
      },
      {
        title: 'Next quarter',
        value: 'nextQuarter',
        dependent: [],
      },
      {
        title: 'No rush until the right candidate is found',
        value: 'noRush',
        dependent: [],
      },
    ]
  },
  {
    name: 'traveling',
    id: 'step2_traveling',
    index: 6,
    title: 'Does this job require traveling?',
    display: false,
    componentType: 'traveling',
    haveDependent: false,
    isItDependent: true,
    answers: [
      {
        title: 'International traveling',
        value: 'international',
        dependent: [],
      },
      {
        title: 'Domestic traveling',
        value: 'domestic',
        dependent: [],
      },
      {
        title: 'Road trips',
        value: 'short',
        dependent: [],
      },
    ]
  },
  {
    name: 'customerFacing',
    id: 'step2_customerFacing',
    index: 7,
    title: 'Is this a customer-facing job?',
    display: false,
    componentType: 'radio',
    haveDependent: false,
    isItDependent: true,
    answers: [
      {
        title: 'Yes',
        value: 'yes',
        dependent: [],
      },
      {
        title: 'No',
        value: 'no',
        dependent: [],
      },
    ]
  },
  {
    name: 'workVisa',
    id: 'step2_workVisa',
    index: 8,
    title: 'Does this job provide work visa sponsorship?',
    display: true,
    componentType: 'radio',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        title: 'Yes',
        value: 'yes',
        dependent: [],
      },
      {
        title: 'No',
        value: 'no',
        dependent: [],
      },
    ]
  },
  {
    name: 'relocationPack',
    id: 'step2_relocationPack',
    index: 6,
    title: 'Does this job provide a paid relocation package?',
    display: false,
    componentType: 'radio',
    haveDependent: false,
    isItDependent: true,
    answers: [
      {
        title: 'Full relocation package',
        value: 'full',
        dependent: [],
      },
      {
        title: 'Particial relocation package',
        value: 'particial',
        dependent: [],
      },
      {
        title: 'No',
        value: 'no',
        dependent: [],
      },
    ]
  },
  {
    name: 'hiringLocations',
    id: 'step2_hiringLocations',
    index: 2,
    helperText: "Specify the locations and time zones where you'll accept candidates to create a fully distributed team.",
    title: 'Where are you hiring for this remote job?',
    display: false,
    haveDependent: false,
    isItDependent: true,
    componentType: 'locations',
    answers: [
      {
        country: '',
        territory: '',
        city: [''],
      }
    ],
  },
]

const stepDescription = [
  {
    id: 'step3_jobTitle',
    name: 'jobTitle',
    index: 1,
    title: 'What is the job title?',
    display: true,
    componentType: 'input',
    haveDependent: false,
    isItDependent: false,
    type: 'text',
    answers: [
      {
        labelTitle: 'Job title',
      },
    ]
  },
  {
    id: 'step3_jobTrailer',
    name: 'jobTrailer',
    index: 2,
    title: 'Write a Job Trailer',
    display: true,
    componentType: 'input',
    helperText: 'Write a very short statement about this job in maximum 140 characters.',
    subtitle: 'It will be shown in the job search',
    haveDependent: false,
    isItDependent: false,
    type: 'text',
    maxLength: 140,
    answers: [
      {
        labelTitle: 'A few words about this job',
        labelSubtitle: 'Up to 140 characters',
      },
    ]
  },
  {
    id: 'step3_jobSummary',
    name: 'jobSummary',
    index: 3,
    title: 'What is the job summary?',
    display: true,
    componentType: 'input',
    subtitle: 'Use short and succinct sentences',
    haveDependent: false,
    isItDependent: false,
    type: 'textarea',
    maxLength: 500,
    answers: [
      {
        labelTitle: 'What is this job about?',
        labelSubtitle: 'Up to 500 characters',
      },
    ]
  },
  {
    id: 'step3_keyAboutJob',
    name: 'keyAboutJob',
    index: 4,
    title: 'A few keywords about this job',
    display: false,
    componentType: 'tags',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        labelTitle: 'Keywords',
        labelSubtitle: 'Optional ( Up to 20 )',
      },
    ]
  },
  {
    id: 'step3_jobExperience',
    name: 'jobExperience',
    index: 1,
    title: 'Is work experience required for this job?',
    display: true,
    componentType: 'radio-inputs',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        name: 'experienceYearsFrom',
        labelTitle: 'Years of experience',
        type: 'number',
        placeholder: 'From:'
      },
      {
        name: 'experienceYearsTo',
        labelTitle: ' ',
        type: 'number',
        placeholder: 'To:'
      },
    ]
  },
  {
    name: 'experience',
    id: 'experience',
    index: 5,
    title: 'What level of experience is required for this job?',
    display: true,
    componentType: 'radio-long',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        title: 'Entry level',
        value: 'entry',
        dependent: [],
      },
      {
        title: 'Junior level',
        value: 'junior',
        dependent: [],
      },
      {
        title: 'Mid level',
        value: 'mid',
        dependent: [],
      },
      {
        title: 'Senior level',
        value: 'senior',
        dependent: [],
      },
    ]
  },
  {
    id: 'step3_educationForJob',
    name: 'educationForJob',
    index: 1,
    title: 'Is specific type of education required for this job?',
    display: true,
    componentType: 'radio-inputs',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        name: 'educationLevel',
        labelTitle: 'Level of education',
        type: 'select',
        placeholder: 'From:',
        options: [
          { label: 'Any level of education', value: 'any' },
          { label: 'Some level of education', value: 'some' }
        ]
      },
      {
        name: 'educationDegree',
        labelTitle: 'Any education level and degrees are acceptable',
      },
      {
        name: 'majorsJob',
        labelTitle: 'Preferred majors and subjects for this job',
        type: 'tags',
        placeholder: 'Press enter after each keyword',
        labelSubtitle: 'optional',
        options: [
          { label: 'Any level of education', value: 'any' },
          { label: 'Some level of education', value: 'some' }
        ]
      },
      {
        name: 'educationMajor',
        labelTitle: 'Any education major, minor and subjects are acceptable ',
      },
    ]
  },
  {
    id: 'step3_certificationsForJob',
    name: 'certificationsForJob',
    index: 1,
    title: 'Are professional certifications required for this job?',
    display: true,
    componentType: 'radio-inputs',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        name: 'certificationType',
        labelTitle: 'Names of certifications',
        labelSubtitle: 'Optional',
        placeholder: 'Press enter after each keyword',
        type: 'tags',
      },
    ]
  },
  {
    id: 'step3_languages',
    name: 'languages',
    index: 9,
    title: 'Which languages are required by this job?',
    display: true,
    componentType: 'languages',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        language: '',
        level: '',
        isItmainLanguage: []
      },
    ]
  },
  {
    id: 'step3_skills',
    name: 'skills',
    index: 10,
    title: 'Required knowledge, skills and abilities',
    subtitle: 'Press enter after each keyword',
    display: true,
    componentType: 'tags',
    haveDependent: false,
    isItDependent: false,
    answers: [
      {
        name: 'mustSkills',
        labelTitle: 'Must have skills',
        labelSubtitle: 'Up to 20',
      },
      {
        name: 'niceSkills',
        labelTitle: 'Nice-to-have skills',
        labelSubtitle: 'Optional ( Up to 20 )',
      },
    ]
  },
  {
    id: 'step3_responsibilities',
    name: 'responsibilities',
    index: 10,
    title: 'Essential duties and responsibilities',
    subtitle: 'Press enter after each keyword',
    display: true,
    componentType: 'tags',
    haveDependent: false,
    isItDependent: false,
    optional: true,
    answers: [
      {
        labelTitle: 'Top essential duties',
        labelSubtitle: 'Up to 20',
      },
    ]
  },
  {
    id: 'step3_requirements',
    name: 'requirements',
    index: 10,
    title: 'Minimum requirements to be successful at this job',
    subtitle: 'Press enter after each keyword',
    display: true,
    componentType: 'tags',
    haveDependent: false,
    isItDependent: false,
    optional: true,
    answers: [
      {
        labelTitle: 'Requirements, success factors, capabilities, know-hows & expertise',
        labelSubtitle: 'Up to 20',
      },
    ]
  },
  {
    id: 'step3_startup',
    name: 'startup',
    index: 1,
    title: 'Is this job with a startup company?',
    display: true,
    componentType: 'radio-inputs',
    haveDependent: false,
    isItDependent: false,
  },
  {
    id: 'step3_aboutJob',
    index: 3,
    name: 'aboutJob',
    title: 'What is special about this job?',
    display: true,
    componentType: 'input',
    subtitle: 'Why someone should apply for this job? Make it interesting!',
    haveDependent: false,
    isItDependent: false,
    type: 'textarea',
    maxLength: 2000,
    optional: true,
    answers: [
      {
        labelTitle: ' ',
        labelSubtitle: 'Up to 2000 Characters',
      },
    ]
  },
  {
    id: 'step3_aboutTeam',
    index: 3,
    name: 'aboutTeam',
    title: 'Briefly write about the team and the career path',
    display: true,
    componentType: 'input',
    subtitle: 'Why someone should apply for this job? Make it interesting!',
    haveDependent: false,
    isItDependent: false,
    type: 'textarea',
    maxLength: 2000,
    optional: true,
    answers: [
      {
        labelTitle: ' ',
        labelSubtitle: 'Up to 2000 Characters',
      },
    ]
  },
  {
    id: 'step3_companyCulture',
    index: 3,
    name: 'companyCulture',
    title: 'Briefly write about the company and culture',
    display: true,
    componentType: 'input',
    subtitle: 'Why someone should apply for this job? Make it interesting!',
    haveDependent: false,
    isItDependent: false,
    type: 'textarea',
    maxLength: 2000,
    optional: true,
    answers: [
      {
        labelTitle: ' ',
        labelSubtitle: 'Up to 2000 Characters'
      },
    ]
  },
  {
    id: 'step3_additionalInfo',
    index: 3,
    name: 'additionalInfo',
    title: 'Additional information',
    display: true,
    componentType: 'input',
    haveDependent: false,
    isItDependent: false,
    type: 'textarea',
    maxLength: 5000,
    optional: true,
    answers: [
      {
        labelTitle: 'A space to include additional information that was not asked previously',
        labelSubtitle: 'Up to 5000 Characters',
      },
    ]
  },
  // {
  //   id: 'step3_mediaFile',
  //   index: 1,
  //   name: 'mediaFile',
  //   title: 'Would you like to add video or voice trailer for this job?',
  //   helperText: 'Record and add a short  video or voice to this Job Story as a Job Trailer to garb attention and increase traction. Maximum length allowed is 60-seconds. This section is just for Job Trailer content. You can add additional video and voice content in a separate section.',
  //   subtitle: 'You can always add it later',
  //   labelTitle: 'Upload media',
  //   display: true,
  //   componentType: 'file',
  //   haveDependent: false,
  //   isItDependent: false,
  //   optional: true,
  //   answers: ''
  // },
  {
    id: 'step3_descriptionFile',
    index: 1,
    name: 'descriptionFile',
    title: 'Would you like to attach the full job description?',
    helperText: 'Attach your version of job description file to this Job Story.',
    subtitle: 'Candidates will be able to download this file',
    labelTitle: 'Attach a file',
    display: true,
    componentType: 'file',
    haveDependent: false,
    isItDependent: false,
    optional: true,
    answers: ''
  },
]

const stepSpecification = [
  {
    name: 'internshipType',
    id: 'step4_internshipType',
    title: 'Is this a paid or unpaid position?',
    display: false,
    componentType: 'internship-type',
    isItDependent: true,
    answers: [
      {
        title: 'Paid',
        value: 'paidInternship',
      },
      {
        title: 'Unpaid',
        value: 'unpaidInternship',
      },
    ]
  },
  {
    name: 'workGoingToBe',
    id: 'step4_workGoingToBe',
    index: 1,
    title: 'How long is this work is going to be?',
    display: false,
    componentType: 'period',
    isItDependent: true,
    helperText: '',
    optional: false,
    answers: [
      {
        name: 'workGoingToBeFrom',
        labelTitle: 'Work duration',
        placeholder: 'From:',
        type: 'number'
      },
      {
        name: 'workGoingToBeTo',
        placeholder: 'To:',
        type: 'number'
      },
      {
        name: 'periodWorkGoingToBe',
        labelTitle: 'Period of time',
        type: 'select',
        options: [
          { value: 'hours', label: 'Hours' },
          { value: 'days', label: 'Days' },
          { value: 'weeks', label: 'Weeks' },
          { value: 'months', label: 'Months' },
          { value: 'years', label: 'Years' },
        ]
      },
    ]
  },
  {
    name: 'contractLength',
    id: 'step4_contractLength',
    title: 'What is the length of this engagement?',
    display: false,
    componentType: 'radio-empty',
    isItDependent: true,
    answers: [
      {
        title: '1-3 months',
        value: '1months',
      },
      {
        title: '3-6 months',
        value: '3months',
      },
      {
        title: '6-9 months',
        value: '6months',
      },
      {
        title: '9-12 months',
        value: '9months',
      },
      {
        title: 'More than 1 year',
        value: '12months',
      },
      {
        title: 'Custom',
        value: 'customContractLength',
      },
    ]
  },
  {
    name: 'fixedBudget',
    id: 'step4_fixedBudget',
    title: 'Does this project have a fixed budget?',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Yes',
        value: 'fixedBudgetYes',
      },
      {
        title: 'No',
        value: 'no',
      },
    ]
  },
  // {
  //   name: 'fixedBudgetFrom',
  //   id: 'step4_fixedBudgetFrom',
  //   display: false,
  //   answers: null
  // },
  // {
  //   name: 'fixedBudgetTo',
  //   id: 'step4_fixedBudgetTo',
  //   display: false,
  //   answers: null
  // },
  // {
  //   name: 'fixedBudgetSelect',
  //   id: 'step4_fixedBudgetSelect',
  //   display: false,
  //   answers: ''
  // },
  {
    name: 'compensation',
    id: 'step4_compensationDirect',
    title: 'How is the compensation offered by this job?',
    subtitle: 'Choose all that apply',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Salary',
        value: 'annualSalary',
      },
      {
        title: 'Hourly rate',
        value: 'hourlyWage',
      },
    ]
  },
  {
    name: 'compensation',
    id: 'step4_compensationInternship',
    title: 'How is the compensation offered by this job?',
    subtitle: 'Choose all that apply',
    display: false,
    componentType: 'internship-type',
    isItDependent: true,
    answers: [
      {
        title: 'Salary',
        value: 'annualSalary',
      },
      {
        title: 'Hourly rate',
        value: 'hourlyWage',
      },
      {
        title: 'Commission only',
        value: 'commission',
      },
    ]
  },
  {
    name: 'compensation',
    id: 'step4_compensationTemporary',
    title: 'How is the compensation offered by this job?',
    subtitle: 'Choose all that apply',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Hourly rate',
        value: 'hourlyWage',
      },
      {
        title: 'Commissions only',
        value: 'commission',
      },
    ]
  },
  {
    name: 'equityIncluded',
    id: 'step4_equityIncluded',
    title: 'Is equity included?',
    optional: true,
    display: false,
    componentType: 'radio',
    isItDependent: true,
    helperText: 'Specify equity offering in number of shares and stocks, or a percentage of ownership.',
    answers: [
      {
        title: 'Yes',
        value: 'yesEquity',
      },
      {
        title: 'No',
        value: 'no',
      },
    ]
  },
  {
    name: 'contractExtendible',
    id: 'step4_contractExtendible',
    title: 'Is this contract extendible?',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Yes',
        value: 'yes',
        subtitle: 'It is extendible if more work is required'
      },
      {
        title: 'No',
        value: 'no',
      },
    ]
  },
  {
    name: 'needResume',
    id: 'step4_needResume',
    index: 1,
    title: 'Would you like a resume for this job?',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Yes',
        value: 'yes',
      },
      {
        title: 'Optional',
        value: 'optional',
      },
      {
        title: 'No',
        value: 'no',
      },
    ]
  },
  {
    name: 'commisionOnly',
    id: 'step4_commisionOnly',
    index: 1,
    title: 'Is this a commision-only job?',
    display: false,
    componentType: 'radio',
    isItDependent: true,
    answers: [
      {
        title: 'Yes',
        value: 'yes',
      },
      {
        title: 'No',
        value: 'no',
      },
    ]
  },
  {
    id: 'step4_whatCandidateNeedLearn',
    name: 'whatCandidateNeedLearn',
    title: 'What does the job candidate learn in this position?',
    display: false,
    componentType: 'tags',
    haveDependent: false,
    isItDependent: true,
    labelTitle: 'Skills',
  },
  {
    name: 'healthcareOffered',
    id: 'step4_healthcareOffered',
    index: 3,
    title: 'What healthcare and wellness insurance benefits are offered in this job? ',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'Health insurance',
        value: 'healthInsurance',
      },
      {
        title: 'Dental insurance',
        value: 'dentalInsurance',
      },
      {
        title: 'Eye Vision insurance',
        value: 'visionInsurance',
      },
      {
        title: 'Employee disability insurance',
        value: 'disabilityInsurance',
      },
      {
        title: 'Life insurance',
        value: 'lifeInsurance',
      },
      {
        title: 'Pet insurance',
        value: 'petInsurance',
      },
      {
        title: 'Accidental death dismemberment (AD&D) insurance',
        value: 'ADInsurance',
      },
    ]
  },
  {
    name: 'savingsOffered',
    id: 'step4_savingsOffered',
    index: 3,
    title: 'What healthcare and wellness savings and perks are offered in this job?',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'Health insurance premium reimbursement',
        value: 'healthReimbursement',
      },
      {
        title: 'Healthcare flexible spending accounts (FSA)',
        value: 'FSA',
      },
      {
        title: 'Mental health benefits',
        value: 'mentalBenefits',
      },
      {
        title: 'Healthcare saving accounts (HSA)',
        value: 'HSA',
      },
      {
        title: 'Onsite gym',
        value: 'gym',
      },
      {
        title: 'Gym membership reimbursement',
        value: 'gymReimbursement',
      },
      {
        title: 'Gym membership Discount Program',
        value: 'gymDiscount',
      },
      {
        title: 'Onsite massage and chiropractic benefits',
        value: 'onsiteMassage',
      }
    ]
  },
  {
    name: 'retirementSavingsOffered',
    id: 'step4_retirementSavingsOffered',
    index: 3,
    title: 'What retirement savings benefits are offered in this job?',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: '401K',
        value: '401K',
      },
      {
        title: 'Roth 401K',
        value: 'roth401K',
      },
      {
        title: '401K Matching',
        value: '401KMatching',
      },
      {
        title: '401K Catch-up plan',
        value: '401KCatchipPlan',
      },
      {
        title: 'Stock options plan',
        value: 'StockOptionsPlan',
      },
      {
        title: 'Stock purchase plan',
        value: 'stockPurchasePlan',
      },
      {
        title: 'Company equity plan',
        value: 'companyEquityPlan',
      },
      {
        title: '529 College savings account',
        value: '529CollegeSavingsAccount',
      },
      {
        title: 'Free financial advice program',
        value: 'FreeFinancialProgram',
      },
    ],
    additionalAnswers: [
      {
        title: 'Retirement saving plans',
        value: 'Retirement saving plans',
      },
      {
        title: 'Pension saving plans',
        value: 'Pension saving plans',
      },
      {
        title: 'Retirement wealth planning',
        value: 'Retirement wealth planning',
      },
      {
        title: 'Tax-deferred retirement plans',
        value: 'Tax-deferred retirement plans',
      },
      {
        title: 'Tax-free retirement plans',
        value: 'Tax-free retirement plans',
      },
      {
        title: 'Retirement saving program',
        value: 'Retirement saving program',
      },
      {
        title: 'The Super (superannuation guarantee/SG)',
        value: 'The Super (superannuation guarantee/SG)',
      },
      {
        title: 'Registered retirement savings plan (RRSP)',
        value: 'Registered retirement savings plan (RRSP)',
      },
      {
        title: 'Group registered retirement savings plan (GRSP)',
        value: 'Group registered retirement savings plan (GRSP)',
      },
      {
        title: 'Tax free savings account (TFSA)',
        value: 'Tax free savings account (TFSA)',
      },
      {
        title: 'Registered retirement income fund (RRIF)',
        value: 'Registered retirement income fund (RRIF)',
      },
      {
        title: 'Private personal pension',
        value: 'Private personal pension',
      },
      {
        title: 'Public personal pension',
        value: 'Public personal pension',
      },
      {
        title: 'State personal pension',
        value: 'State personal pension',
      },
      {
        title: 'City personal pension',
        value: 'City personal pension',
      },
      {
        title: 'Tax-exempt savings schemes (TESSA)',
        value: 'Tax-exempt savings schemes (TESSA)',
      },
      {
        title: 'Individual savings accounts (ISA)',
        value: 'Individual savings accounts (ISA)',
      },
      {
        title: 'Self-invested personal pension (SIPP)',
        value: 'Self-invested personal pension (SIPP)',
      },
      {
        title: 'Employee Provident Fund (EPF)',
        value: 'Employee Provident Fund (EPF)',
      },
      {
        title: 'Superannuation public pension system',
        value: 'Superannuation public pension system',
      }
    ]
  },
  {
    name: 'vacationOffered',
    id: 'step4_vacationOffered',
    index: 3,
    title: 'What vacation and paid leave benefits are offered in this job?',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'Paid time off (PTO)',
        value: 'PTO',
      },
      {
        title: 'Paid sick days off',
        value: 'paidSickDaysOff',
      },
      {
        title: 'Paid holidays',
        value: 'paidHolidays',
      },
      {
        title: 'Paid emergency days off',
        value: 'paidEmergencyDaysOff',
      },
      {
        title: 'Paid volunteer time',
        value: 'paidVolunteerTime',
      },
      {
        title: 'Unlimited vacation plan',
        value: 'unlimitedVacationPlan',
      },
      {
        title: 'Parental leave',
        value: 'parentalLeave',
      },
      {
        title: 'Family medical leave',
        value: 'familyMedicalLeave',
      },
      {
        title: 'Flexible remote work',
        value: 'flexibleRemoteWork',
      },
      {
        title: 'Flexible work hours',
        value: 'flexibleWorkHours',
      }
    ]
  },
  {
    name: 'learningOpportunitiesOffered',
    id: 'step4_learningOpportunitiesOffered',
    index: 3,
    title: 'Are there any learning opportunities or career development benefits?',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'On the job trainings',
        value: 'jobTrainings',
      },
      {
        title: 'Paid trainings',
        value: 'paidTrainings',
      },
      {
        title: 'Free access to online courses',
        value: 'freeOnlineCourses',
      },
      {
        title: 'Free webinars and conferences',
        value: 'freeWebinars',
      },
      {
        title: 'Certification exams reimbursement',
        value: 'certificationExams',
      },
      {
        title: 'College/University tuition reimbursement',
        value: 'universityTuition',
      },
      {
        title: 'Mentorship program',
        value: 'mentorshipProgram',
      },
      {
        title: 'Salary raise structure',
        value: 'salaryRaise',
      },
      {
        title: 'Career promotion program',
        value: 'careerPromotion',
      },
      {
        title: 'Fast track leadership program',
        value: 'fastTrackLeadership',
      },
    ]
  },
  {
    name: 'volunteerAbout',
    id: 'step4_volunteerAbout',
    index: 3,
    title: 'What is this volunteer job about?',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'Capacity building and training',
        value: 'capacityBuilding',
      },
      {
        title: 'Emergency food and shelter',
        value: 'emergencyFood',
      },
      {
        title: 'Logistics and transport',
        value: 'logistics',
      },
      {
        title: 'Public health',
        value: 'publicHealth',
      },
      {
        title: 'Education',
        value: 'education',
      },
      {
        title: 'Teaching',
        value: 'teaching',
      },
      {
        title: 'Helping children',
        value: 'helpingChildren',
      },
      {
        title: 'Helping refugees',
        value: 'helpingRefugees',
      },
      {
        title: 'Protecting environment',
        value: 'protectingEnvironment',
      },
      {
        title: 'Religious charity',
        value: 'religiousCharity',
      },
      {
        title: 'Sports',
        value: 'sports',
      },
      {
        title: 'Technology assistance',
        value: 'technologyAssistance',
      },
      {
        title: 'Government policies',
        value: 'governmentPolicies',
      },
      {
        title: 'Wildlife conservation',
        value: 'wildlifeConservation',
      },
      {
        title: 'Marine conservation',
        value: 'marineConservation',
      },
      {
        title: 'Animal care',
        value: 'animalCare',
      },
    ]
  },
  {
    name: 'officeOffered',
    id: 'step4_officeOffered',
    index: 3,
    title: 'Office, Commute and Travel benefits and Perks',
    subtitle: 'Note: Choose all that apply',
    display: false,
    componentType: 'check-box',
    isItDependent: true,
    optional: true,
    answers: [
      {
        title: 'Stocked pantry and kitchen',
        value: 'pantryAndKitchen',
      },
      {
        title: 'Free meals',
        value: 'freeMeals',
      },
      {
        title: 'Company car',
        value: 'companyCar',
      },
      {
        title: 'Some free meals',
        value: 'someFreeMeals',
      },
      {
        title: 'Free drinks',
        value: 'freeDrinks',
      },
      {
        title: 'Paid international trips',
        value: 'paidInternationalTrips',
      },
      {
        title: 'Paid trips to conferences',
        value: 'paidTripsConferences',
      },
      {
        title: 'Weekly travel food stipend',
        value: 'weeklyTravel',
      },
      {
        title: 'Monthly transit stipend',
        value: 'monthlyTransitStipend',
      },
      {
        title: 'Commuter benefits',
        value: 'commuterBenefits',
      },
    ]
  },
]

const stepContact = [
  {
    name: 'mainInfo',
    id: 'step4_mainInfo',
    title: 'Who is hiring?',
    display: true,
    componentType: 'main-info',
    isItDependent: false,
  },
  {
    name: 'contactedForJob',
    id: 'step4_contactedForJob',
    title: 'Who should be contacted for this job?',
    helperText: 'The information below is necessary to route applicants to you. You can choose to make any section invisible from the public eyes. The email in this area will be used to forward applicants information and CV/resumes as soon as jobseekers apply for this job.',
    display: true,
    componentType: 'contacted-for-job',
    isItDependent: false,
  },
  {
    id: 'step5_teamPhotosFile',
    name: 'teamPhotosFile',
    title: 'Would you like to add photos of the team, workplace and the organization?',
    subtitle: 'Make your Job Story more attractive! Max file size is 10 MB',
    labelTitle: 'Upload photos',
    display: true,
    componentType: 'photo-gallery',
    filesType: 'image',
    isItDependent: false,
    optional: true,
    answers: ''
  },
  {
    id: 'step5_additionalVideoFile',
    name: 'additionalVideoFile',
    title: 'Upload Video or Voice',
    subtitle: 'The Maximum supported length of video or voice is 120 seconds, and the max file size is 150 megabyte.',
    helperText: 'Add videos and voice content to make this Job Story attractive and enticing. Job Stories with videos and voice content tend to increase your chances of hiring the greatest talent in the market. Sell your job, and tell a story about this position by adding interactive content and hire the best.',
    labelTitle: 'Upload media',
    display: true,
    componentType: 'media-gallery',
    filesType: 'media',
    isItDependent: false,
    optional: true,
    answers: ''
  },
]

export const jobStory = {
  stepStart,
  stepJob,
  stepDescription,
  stepSpecification,
  stepContact
}

const startStepModel = {
  jobKind: '',
  locations: types.array(types.model({
    country: '',
    address: '',
    suite: '',
    city: '',
    territory: '',
    zipCode: types.union(types.string, types.number),
    timeZone: ''
  })
  ),
  doneRemotely: ''
}

const jobStepModel = {
  workShift: '',
  otherShiftInf: types.maybe(types.string),
  weeklyInfo: types.maybe(types.string),
  canDoneOver: types.array(types.string),
  sideJob: '',
  jobStart: '',
  traveling: types.union(types.array(types.string), types.string, types.undefined),
  customerFacing: '',
  workVisa: '',
  relocationPack: '',
  mixshiftData: types.maybe(types.array(types.frozen())),
  hiringLocations: types.optional(types.array(types.model({
    country: '',
    territory: '',
    city: types.array(types.string),
    acceptRemoteWorkers: types.array(types.string)
  }))
    , [{
      country: 'US',
      territory: '',
      city: [],
      acceptRemoteWorkers: []
    }]
  ),
  acceptRemoteWorkersWorld: types.array(types.string)
}

const descriptionStepModel = {
  jobTitle: '',
  jobTrailer: '',
  jobSummary: '',
  keyAboutJob: types.array(types.string),
  jobExperience: '',
  experienceYearsFrom: types.maybeNull(types.union(types.string, types.number)),
  experienceYearsTo: types.maybeNull(types.union(types.string, types.number)),
  experience: '',
  educationForJob: '',
  educationLevel: types.array(types.string),
  educationDegree: types.array(types.string),
  educationMajor: types.array(types.string),
  majorsJob: types.array(types.string),
  certificationsForJob: '',
  certificationType: types.array(types.string),
  languages: types.optional(types.array(types.model({
    language: '',
    level: '',
    isItmainLanguage: types.array(types.maybe(types.string))
  })), [
    {
      language: 'English',
      level: '',
      isItmainLanguage: []
    }
  ]),
  mustSkills: types.array(types.string),
  mediaFile: types.maybe(types.model({ id: '', filename: '' })),
  descriptionFile: types.maybeNull(types.frozen()),
  niceSkills: types.array(types.string),
  responsibilities: types.array(types.string),
  requirements: types.array(types.string),
  startup: '',
  aboutJob: '',
  aboutTeam: '',
  companyCulture: '',
  additionalInfo: ''
}

const specificationStepModel = {
  compensation: '',
  contractLength: '',
  contractLengthInput: types.maybeNull(types.union(types.string, types.number)),
  contractLengthSelect: '',
  salaryRateFrom: types.maybeNull(types.union(types.string, types.number)),
  salaryRateTo: types.maybeNull(types.union(types.string, types.number)),
  salaryFromSelect: '',
  salaryToSelect: '',
  salaryPeriodSelect: '',
  salaryPrefer: types.array(types.string),
  hourlyPrefer: types.array(types.string),
  hourlyFromSelect: '',
  hourlyToSelect: '',
  fixedBudget: '',
  fixedBudgetFrom: types.maybeNull(types.union(types.string, types.number)),
  fixedBudgetTo: types.maybeNull(types.union(types.string, types.number)),
  budgetFromSelect: '',
  budgetToSelect: '',
  commisionRateFrom: types.maybeNull(types.union(types.string, types.number)),
  commisionRateTo: types.maybeNull(types.union(types.string, types.number)),
  commisionRateSelect: '',
  bonusAmountFrom: types.maybeNull(types.union(types.string, types.number)),
  bonusAmountTo: types.maybeNull(types.union(types.string, types.number)),
  bonusAmountSelect: '',
  signInBonusSelect: '',
  signInBonusFrom: types.maybeNull(types.union(types.string, types.number)),
  signInBonusTo: types.maybeNull(types.union(types.string, types.number)),
  overtimeIsIncluded: types.array(types.string),
  hourlyRateFrom: types.maybeNull(types.union(types.string, types.number)),
  hourlyRateTo: types.maybeNull(types.union(types.string, types.number)),
  equityIncluded: '',
  equityFrom: types.maybeNull(types.union(types.string, types.number)),
  equitySelect: '',
  equityTo: types.maybeNull(types.union(types.string, types.number)),
  workGoingToBeFrom: types.maybeNull(types.union(types.string, types.number)),
  workGoingToBeTo: types.maybeNull(types.union(types.string, types.number)),
  periodWorkGoingToBe: '',
  equityToSelect: '',
  vestingSchedule: '',
  needResume: '',
  internshipType: '',
  contractExtendible: '',
  whatCandidateNeedLearn: types.array(types.string),
  healthcareOffered: types.array(types.string),
  savingsOffered: types.array(types.string),
  retirementSavingsOffered: types.array(types.string),
  vacationOffered: types.array(types.string),
  learningOpportunitiesOffered: types.array(types.string),
  officeOffered: types.array(types.string),
  volunteerAbout: types.array(types.string)
}

const contactStepModel = {
  directEmployerLogo: types.optional(types.model({
    fileUrl: '',
    filename: '',
    id: ''
  }), {
    fileUrl: '',
    filename: '',
    id: ''
  }),
  organizationName: '',
  industry: '',
  organizationSize: '',
  contactWebsite: '',
  contactLinkedIn: '',
  contactFacebook: '',
  contactYoutube: '',
  contactTwitter: '',
  contactInstagram: '',
  confidentialForEmployer: types.array(types.string),
  staffingName: '',
  isAnEmployer: types.array(types.string),
  employerOrgName: '',
  organizationIndustry: '',
  confidentialForFirms: types.array(types.string),
  fullName: '',
  fullNameVisible: types.array(types.string),
  email: '',
  emailVisible: types.array(types.string),
  phone: '',
  phoneVisible: types.array(types.string),
  teamPhotosFile: types.optional(types.array(types.frozen()), []),
  additionalVideoFile: types.optional(types.array(types.frozen()), [])
}

export const initialModels = {
  steps: {},
  startStep: {
    locations: [{
      country: 'United States',
      address: '',
      suite: '',
      city: '',
      territory: '',
      zipCode: '',
      timeZone: ''
    }]
  },
  jobStep: {
    hiringLocations: [{
      country: 'United States',
      territory: '',
      city: [],
      acceptRemoteWorkers: []
    }],
    acceptRemoteWorkersWorld: []
  },
  descriptionStep: {
    languages: [{
      language: 'English',
      level: 'advanced',
      isItmainLanguage: []
    }
    ],
    mediaFile: { id: '', filename: '', fileUrl: '' },
    descriptionFile: null
  },
  specificationStep: {
    equityTo: null,
    workGoingToBeFrom: null,
    workGoingToBeTo: null,
    salaryRateFrom: null,
    salaryRateTo: null,
    fixedBudgetFrom: null,
    fixedBudgetTo: null,
    commisionRateFrom: null,
    commisionRateTo: null,
    bonusAmountFrom: null,
    bonusAmountTo: null,
    signInBonusFrom: null,
    signInBonusTo: null,
    hourlyRateFrom: null,
    hourlyRateTo: null,
    equityFrom: null,
  },
  contactStep: {}
}

export const jobModele = types
  .model("jobStoryBuilder", {
    dependent: types.array(types.string),//types.optional(types.array(types.string), ['']),
    jobId: '',
    themeId: '',
    jobStatus: '',
    files: types.optional(types.frozen(), []),
    customBenefits: types.optional(types.frozen(), {}),
    steps: types.optional(types.frozen(), {}),
    allQuestions: types.optional(types.frozen(), {}),
    answers: types.optional(types.model({
      startStep: types.model(startStepModel),
      jobStep: types.model(jobStepModel),
      descriptionStep: types.model(descriptionStepModel),
      specificationStep: types.model(specificationStepModel),
      contactStep: types.model(contactStepModel)
    }), initialModels),
    dropdownQuestions: types.frozen({})
  })