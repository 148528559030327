import { doc, updateDoc, getDoc,deleteField } from "firebase/firestore";
import { initialPersonalInfo } from "../../../../state/stores/resumeBuilder";
import { db } from "../../index";
import { removeFile, uploadFile } from "../../storage";

export const onGetResume = async (uid: string) => {
  try {
    const resumeRef = doc(db, "users", uid, "job", "resume");
    const querySnapshot = await (await getDoc(resumeRef)).data();
    return querySnapshot

  } catch (error) {
    return error
  }
}

export const onChangeResumeBuilderItem = async (uid: string | undefined, data: any) => {
  try {
    if (!uid) return { error: 'auth error' };
    const resumeRef = doc(db, "users", uid, "job", "resume");
    await updateDoc(resumeRef, data);
    return { error: null };
  } catch (error) {
    return { error }
  }
};

export const onUploadFile = async (file: File, uid: string) => {
  try {
    const path = "candidates/" + uid + "/files/" + file.name
    const uploadedLogo = await uploadFile(file, path);
    const logo = {
      url: uploadedLogo,
      name: file.name,
      isUploaded: true
    };
    return logo;
  } catch (error) {
    return error
  }
}

export const onRemoveFile = async (fileName: string, uid: string) => {
  try {
    const path = "candidates/" + uid + "/files/" + fileName;
    const uploadedLogo = await removeFile(path)
    return uploadedLogo;
  } catch (error) {
    return { error }
  }
}

export const onDeleteVideoResume = async (uid: string) => {
  try {
    const resumeRef = doc(db, "users", uid, "job", "resume");
    await updateDoc(resumeRef, {
      videoResume: deleteField()
    });
  } catch (error) {
    return { error }
  }
}

export const onUploadResumePhoto = async (file: File, uid: string) => {
  try {
    const path = "candidates/" + uid + "/img/" + file.name + "resume"
    const uploadedLogo = await uploadFile(file, path)
    const logo = {
      url: uploadedLogo,
      name: file.name
    };
    onChangeResumeBuilderItem(uid, {logo})
    return logo;
  } catch (error) {
    return error
  }
}

export const onRemoveResumePhoto = async (fileName: string, uid: string) => {
  try {
    const path = "candidates/" + uid + "/img/" + fileName + "resume";
    const uploadedLogo = await removeFile(path)
    onChangeResumeBuilderItem(uid, {logo: null})
    return uploadedLogo;
  } catch (error) {
    return { error }
  }
}
