import { Content, Center } from '../../common/Wraps/Wraps.presents';
import { observer } from 'mobx-react-lite';
import { SPWhiteButton } from '../../common/buttons/SPWhiteButton/SPWhiteButton';
import { ReactComponent as JobsIcon } from "../../../assets/img/organization/jobs.svg";
import { useHistory } from 'react-router-dom';
import { OrganizationPreviewCard } from '../../common/Cards/OrganizationPreviewCard';
import { Subtitle, AddJobButton } from './Jobs.presents';

export const DefaultJobsCard = observer(({ isDesktop }: { isDesktop: boolean }) => {
    const history = useHistory();
    const goToAdminPanel = () => {
        history.push('/my-jobs')
    }
    return (<OrganizationPreviewCard title='Jobs:' isDesktop={isDesktop}>
        <Content>

            <Center>
                <JobsIcon />
            </Center>

            <Subtitle>You have no active jobs yet</Subtitle>
            <AddJobButton>
                <SPWhiteButton width={'100%'} onClick={goToAdminPanel}>
                    Add Your First Job
                </SPWhiteButton>
            </AddJobButton>
        </Content>

    </OrganizationPreviewCard>)
})