import { theme } from './../../../theme/theme';
import { colors } from './../../../constants/colors';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const StyleForModal = styled(Modal) <{ fullscreen: boolean, width?: string }>`
  .modal-dialog{
    max-width:${(props) => {
      const maxWidth = props.width || '703px';
      return props.fullscreen ? '100%' : maxWidth;
  }}; 
    ${(props) => props.fullscreen && `
      margin: 0px;
      padding-right: 0px!important;
    `}
    .modal-content{
      padding:40px;
      min-height: ${(props) => props.fullscreen ? '100vh' : '351px'};
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: ${(props) => props.fullscreen ? '0' : '20px'};
      @media(max-width: ${theme.screens.tablet}) {
        padding:  ${theme.offsets.lg};
      }
    };
    img.close-btn{
      position: absolute;
      right: 40px;
      top: 45px;
      cursor: pointer;
      @media(max-width: ${theme.screens.tablet}) {
        right: ${theme.offsets.lg};
        top: ${theme.offsets.lg};
      }
    }
    .title{
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: ${colors.dark};
      text-align: left;
    } 
    
  }
`

export const WindowText = styled('p')`
  font-size: ${theme.fontSizes.xxl};
  line-height: ${theme.fontSizes.big};
  margin-top: ${theme.offsets.xxl};
  text-align: center;
`

export const WindowAdditionalText = styled('p')`
  font-size: ${theme.fontSizes.lg};
  line-height: ${theme.fontSizes.xl};
  margin-top: ${theme.offsets.lg};
  text-align: center;
`

export const WindowContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`