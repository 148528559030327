import { PropsType } from "./ThirdStep.props";
import 'react-app-polyfill/ie11';
import { Formik } from 'formik';
import { Title } from "../../../../../common/Title";
import { Input } from "../../../../../common/Input";
import { StyleForText, StyleRadioWrap, RadioWrapTitle, RadioItem, Checkmark } from "./ThirdStep.presents";
import { LinkBtn } from "../../../../../common/Links";
import { useState } from "react";
import { Text } from "../../../../../common/Text";
import { useRootStore } from "../../../../../../state/stores/storeContext";
import * as Yup from 'yup';
import { StyleForWrap } from "./ThirdStep.presents";
import { useTranslation } from "react-i18next";
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { ButtonWrap } from "../FirstStep/FirstStep.presents";
import { passwordValidation, userNameValidation } from "../../../../../../services/validationSchemas";

export const ThirdStep = (props: PropsType) => {
  const [serverError, setServerError] = useState('')
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { userStore } = useRootStore()
  const { t } = useTranslation()

  const initialValues = {
    firstName: userStore.firstName,
    lastName: userStore.lastName,
    email: userStore.email,
    organizationName: userStore.organizationName,
    password: '',
    orgType: userStore.orgType
  }

  return <StyleForWrap>
    <Title text={t('signUp.thirdStepTitle')} />
    <Formik
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstName: userNameValidation(t),
        lastName: userNameValidation(t),
        organizationName: Yup.string().required(t('validation.required')),
        email: Yup.string().email(t('validation.email')).required(t('validation.required')),
        password: passwordValidation(t)
      })}
      onSubmit={async (
        values: typeof initialValues
      ) => {
        setIsLoading(true);
        const dataFromServer = await userStore.saveUserMainInfo({ verificationId: userStore.verificationId, ...values })
        setServerError(dataFromServer.err)
        if (!dataFromServer.err) {
          props.nextStep()
        }
        setIsLoading(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Input
            m={'48px 0 8px'}
            name={'firstName'}
            type={'text'}
            value={values.firstName}
            onChange={handleChange}
            labelText={t('label.firstName')}
            error={errors.firstName ? true : false}
          />
          <Text text={errors.firstName ? errors.firstName : ''} error />
          <Input
            m={'12px 0 8px'}
            name={'lastName'}
            type={'text'}
            value={values.lastName}
            onChange={handleChange}
            labelText={t('label.lastName')}
            error={errors.lastName ? true : false}
          />
          <Text text={errors.lastName ? errors.lastName : ''} error />
          <Input
            m={'12px 0 8px'}
            name={'email'}
            type={'text'}
            value={values.email}
            onChange={handleChange}
            labelText={t('label.emailMain')}
            error={errors.email ? true : false}
          />
          <Text text={errors.email ? errors.email : ''} error />
          <Input
            m={'12px 0 8px'}
            name={'organizationName'}
            type={'text'}
            value={values.organizationName}
            onChange={handleChange}
            labelText={t('label.organizationName')}
            error={errors.organizationName ? true : false}
          />
          <Text text={errors.organizationName ? errors.organizationName : ''} error />
          <Input
            m={'12px 0 8px'}
            name={'password'}
            type={'password'}
            value={values.password}
            onChange={handleChange}
            labelText={<div className='d-flex justify-content-between'><span>{t('label.password')}</span><span className='additional'>{t('label.leastCharacters')}</span></div>}
            error={errors.password ? true : false}
          />
          <Text text={errors.password ? errors.password : ''} error />
          <StyleRadioWrap>
            <RadioWrapTitle>What is your organization type?</RadioWrapTitle>
            <RadioItem>
              <label>
                <span>Employer: posting jobs directly</span>
                <input onChange={handleChange} type="radio" name='orgType' value='employer' checked={values.orgType === 'employer'} />
                <Checkmark />
              </label>
            </RadioItem>
            <RadioItem>
              <label>
                <span>Recruitment Firm: posting jobs on behalf of employers</span>
                <input onChange={handleChange} type="radio" name='orgType' value='reqruitment' checked={values.orgType === 'reqruitment'} />
                <Checkmark />
              </label>
            </RadioItem>
          </StyleRadioWrap>
          <StyleForText>
            {t('signUp.privacyPolicyText')}
            <LinkBtn fs={14} lh={16} text={t('signUp.terms')} path={{ pathname: 'https://sidepost.com/terms-of-service' }} target="_blank" />
            {t('signUp.and')}
            <LinkBtn fs={14} lh={16} text={t('signUp.privacyPolicy')} path={{ pathname: 'https://sidepost.com/privacy-policy' }} target="_blank" />
          </StyleForText>
          <ButtonWrap>
            <SPPrimaryButton disabled={isLoading} width={'100%'} type="submit" onClick={() => setFirstSubmitComplete(true)}>{t('signUp.mainBtn')}</SPPrimaryButton>
          </ButtonWrap>
        </form>
      )}
    </Formik>
    <Text text={serverError} error />
  </StyleForWrap>
}