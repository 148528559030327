import { PaginationProps } from "./Pagination.props";
import { ListLengthNavigation } from "./helper";
import { ReactComponent as ArrowIcon } from "../../../assets/img/arrowNextDark.svg";
import { NavigationWrap, PrevPage, NextPage } from "./Pagination.presents";


export const Pagination = (props: PaginationProps) => {
  const { activePage, navigatePage, isDesktop, listLength } = props;

  const pageSize = 10;
  const isNotLastPage = listLength > pageSize && listLength - (activePage + 1) * pageSize > 0;

  return listLength ? <NavigationWrap isDesktop={isDesktop}>
    {activePage > 0 ? <PrevPage isDesktop={isDesktop} onClick={() => navigatePage('prev')}>
      <ArrowIcon/>
    </PrevPage> : ''}
    {listLength && <ListLengthNavigation listLength={listLength} activePage={activePage} />}
    {isNotLastPage && <NextPage isDesktop={isDesktop} onClick={() => navigatePage('next')}>
      <ArrowIcon/>
    </NextPage>}
  </NavigationWrap> : null
}