import { OrganizationCardContainer, OrganizationCardContent, Title } from "./OrganizationCard.presets"
import { OrganizationCardProps } from "./OrganizationCard.props"

export const OrganizationCard = (props: OrganizationCardProps) => {
  return (
    <OrganizationCardContainer ref={props.componentRef} isDesktop={props.isDesktop}>
      <Title>{props.title}</Title>
      <OrganizationCardContent isDesktop={props.isDesktop} width={props.width}>
        {props.children}
      </OrganizationCardContent>
    </OrganizationCardContainer>
  )
}
