import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { mainStyleType } from '../../../themes/mainStylesType'
import plus from '../../../assets/img/plus.svg'
import { theme } from '../../../theme';

export const StyleForLink = styled(Link) <mainStyleType>`
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  font-weight: 500;
  color: ${theme.colors.blue};
  font-size: ${(props: mainStyleType) => props.fs ? props.fs : 18}px;
  line-height: ${(props: mainStyleType) => props.lh ? props.lh : 24}px; 
  align-items: center;
  white-space: nowrap;
  @media(max-width: ${theme.screens.desktop}) {
    font-size: 14px;
    line-height: 16px;
  }

  ${(props: mainStyleType) => props.styled === 'main' && `
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background: #EC923C;
    box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
    border-radius: 8px;
    padding: 16px 30px;
    &:hover{
      background: #E18935;
      color: #FFFFFF;
    }
  `}
  ${(props: mainStyleType) => props.styled === 'transparent' && `
    border: 1px solid #E2E3E5;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: Apercu Pro;
    display: flex;
    color: #3F474F;
    width: 156px;
    height: 56px;
    justify-content: center;
  `}
  ${(props: mainStyleType) => props.styled === 'warning' && `
    background: #EC923C;
    box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
    border-radius: 8px;
    font-family: Apercu Pro;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 16px;
    display: flex;
    ::after{ 
      content: "";
      background-image: url('${plus}');
      background-repeat: no-repeat;
      background-size: 100%;
      height: 20px;
      width: 20px;
      display: block; 
      margin-left: 50px;
    }
    :hover{
      color: #FFFFFF;
    }
  `}
`