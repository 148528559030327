import styled from 'styled-components'
import { theme } from '../../../theme'

const AVATAR_SIZE = "120px";
const TABLET_AVATAR_SIZE = "80px";
const MOBILE_AVATAR_SIZE = "65px";
const SPINNER_SIZE = "24px";
const CONTACT_SIZE = "56px";

export const UploadContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const AvatarContainer = styled('div')`
  position: relative;
  z-index: 1;
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(209, 215, 227, 0.2);
  border: 1px solid ${theme.colors.lightGray};
  border-radius: ${theme.variables.borderRadiusLarge};
  overflow: hidden;
  @media(max-width: ${theme.screens.tablet}) {
    width: ${TABLET_AVATAR_SIZE};
    height: ${TABLET_AVATAR_SIZE};
  } 
  @media(max-width: ${theme.screens.mobile}) {
    width: ${MOBILE_AVATAR_SIZE};
    height: ${MOBILE_AVATAR_SIZE};
  } 
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
  }
  input[type=file]::-webkit-file-upload-button {
    display: none;
}
`

export const AvatarImage = styled('img')<{showPlaceHolder: boolean}>`
  height: 100%;
  width: 100%;
  object-fit: cover; 
  ${(props)=>props.showPlaceHolder && `
  max-width: 52px;
  max-height: 52px;
  `}

`

export const DeleteButton = styled('button')`
  z-index: 2;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  img {
    width: 8px;
    height: 8px;
  }
  @media(max-width: ${theme.screens.tablet}) {
    top: 0px;
    right: 2px;
  } 
  @media(max-width: ${theme.screens.mobile}) {
    top: 0px;
    right: 2px;
  }
`

export const UploadButton = styled('button')`
  position: relative;
  height: 52px;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: ${theme.variables.borderRadiusSmall};
  padding: ${theme.offsets.lg};
  margin-left: ${theme.offsets.xxl};
  background-color: transparent;
  transition: 300ms all ease-in-out;
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.lg};
  font-weight: 500;
  @media(max-width: ${theme.screens.tablet}) {
    height: 42px;
    padding: ${theme.offsets.md} ${theme.offsets.xxl};
    font-size: 14px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    height: 38px;
    padding: ${theme.offsets.md} ${theme.offsets.lg};
  }
  input{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    padding:0;
    cursor: pointer;
    width: fill-available;
    font-size: 0;
}
input[type=file]::-webkit-file-upload-button {
    display: none;
}
  &:hover {
    opacity: 0.8;
  }
`

export const Spinner = styled('img')`
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  user-select: none;
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
`
export const ContactImage = styled('img')`
  width: ${CONTACT_SIZE};
  height: ${CONTACT_SIZE};
  border-radius: ${theme.variables.borderRadiusLarge};
  object-fit: cover;
  background: rgba(209, 215, 227, 0.2);
`