import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../../../../theme';

export const Wrap = styled('div')<{ isOpen: boolean }>`
  position: fixed;
  left: -100%;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.blueGray};
  z-index: 10;
  transition: all 0.4s ease 0s;
  transform: ${(props) => props.isOpen ? 'translateX(100%)' : 'translateX(0)'};
  z-index: 99;
`

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
`

export const ListBox = styled('div')`
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled(Link)`
  padding: ${theme.offsets.extraBig};
  display: flex;
  align-items: center;
  border-bottom: 1px solid RGBA(255,255,255,0.25);
  border-left: 3px solid ${theme.colors.blueGray};
  &:hover{
    border-left: 3px solid ${theme.colors.white};
    background-color: rgba(255, 255, 255, 0.15);
  }
  @media(max-width: ${theme.screens.mobile}) { 
    padding: ${theme.offsets.lg} ${theme.offsets.md};
    font-size: ${theme.fontSizes.lg};
  }
`

export const Icon = styled('img')`
  margin-right: ${theme.offsets.md};
  max-height: 20px;
  object-fit: contain;
`

export const Title = styled('span')`
  color: ${theme.colors.white};
  font-size: 18px;
  font-weight: 500;
`

export const Logo = styled('img')`
  max-width: 121px;
  object-fit: contain;
  margin: ${theme.offsets.md} auto 83px;
`

export const ExitBtn = styled('div')`
  padding: ${theme.offsets.extraBig};
  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  font-size: 18px;
  font-weight: 500;
  &:hover{
    border-left: 3px solid ${theme.colors.white};
    background-color: rgba(255, 255, 255, 0.15);
  }
  @media(max-width: ${theme.screens.mobile}) { 
    padding: ${theme.offsets.lg} ${theme.offsets.md};
    font-size: ${theme.fontSizes.lg};
  }
`