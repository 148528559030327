import styled from 'styled-components'
import { theme } from '../../../theme';
import { Link } from 'react-router-dom';

const LIST_LENGTH_BLOCK_SIZE = '20px';
const TAB_ICON_SIZE = '20px';

export const StyleForWrap = styled('div')`
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.05);
  width: 100%;
  z-index: 9;
`

export const NavigationBlock = styled('div')`
  max-width: 1720px;
  margin: 0 auto; 
  padding: 18px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const TitleWrap = styled('div')`

`

export const Title = styled('p')`
  font-size: ${theme.fontSizes.extraBig};
  margin: ${theme.offsets.lg} 0 ${theme.offsets.xxl};
  text-transform: capitalize;
`

export const BackToListBtn = styled(Link)`
  font-weight: 500;
  font-size: ${theme.fontSizes.lg};
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  :hover{
    opacity: 0.75;
    color: ${theme.colors.black};
  }
  svg{
    margin-right: ${theme.offsets.sm};
  }
`

export const TabsWrap = styled('div')`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 0;
`

export const TabItem = styled('div') <{ isActive: boolean }>`
  font-size: ${theme.fontSizes.lg};
  padding: 0 ${theme.offsets.lg} ${theme.offsets.lg};
  cursor: pointer;
  border-bottom: 2px solid ${theme.colors.white};
  position: relative;
  display: flex;
  ${(props) => props.isActive && `
  border-bottom: 2px solid ${theme.colors.lightOrange};
  color: ${theme.colors.lightOrange};
  `}
  &:hover{
    border-bottom: 2px solid ${theme.colors.lightOrange};
  }
  svg{
      margin-right: ${theme.offsets.sm};
      filter: drop-shadow(8px 8px 16px rgba(236, 146, 60, 1));
  }
`

export const ItemIcon = styled('div') <{ isActive: boolean, imgUrl: string }>`
  width: ${TAB_ICON_SIZE};
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-image: url(${(props) => props.imgUrl});
  background-color: ${(props) => props.isActive ? theme.colors.lightOrange : theme.colors.black};
  margin-right: ${theme.offsets.sm};  
`

export const ListLengthBlock = styled('div')`
  padding-top: 3px;
  position: absolute;
  border-radius: 100%;
  border: 1px solid ${theme.colors.gray};
  width: ${LIST_LENGTH_BLOCK_SIZE};
  height: ${LIST_LENGTH_BLOCK_SIZE};
  display: flex;
  justify-content: center;
  align-items: center;
  right: -${theme.offsets.md};
  top: 0;
  font-weight: 500;
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.black};
`

export const PreviewBtns = styled('div')`
  display: flex;
`