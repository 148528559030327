import * as React from "react"
import { SPHeading2 } from "../../../../theme/typography"
import { JobStoryCardContainer, JobStoryCardContent, JobStoryCardImage, JobStoryCardImageWrapper, JobStoryCardTitleWrapper } from "./JobStoryCard.presets"
import { JobStoryCardProps } from "./JobStoryCard.props"

export const JobStoryCard: React.FC<JobStoryCardProps> = (props) => {
  return (
    <JobStoryCardContainer ref={props.componentRef}>
      {
        props.imageUrl && <JobStoryCardImageWrapper>
          <JobStoryCardImage imageUrl={props.imageUrl} />
        </JobStoryCardImageWrapper>
      }
      <JobStoryCardContent>
        <JobStoryCardTitleWrapper>
          <SPHeading2>{props.title}</SPHeading2>
        </JobStoryCardTitleWrapper>
        {props.children}
      </JobStoryCardContent>
    </JobStoryCardContainer>
  )
}
