import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

export const passwordValidation = (t: any) => Yup.string()
  .min(8, t('validation.minPassword', { characters: 8 }))
  .max(50, t('validation.max', { characters: 50 }))
  .minLowercase(1, t('validation.minLowercase'))
  .minUppercase(1, t('validation.minUppercase'))
  .minNumbers(1, t('validation.minNumbers'))
  .minSymbols(1, t('validation.minSymbols'))
  .required(t('validation.required'));


export const userNameValidation = (t: any) => Yup.string()
  .min(2, t('validation.min', { characters: 2 }))
  .max(50, t('validation.max', { characters: 50 }))
  .matches(/^[A-Za-z `-]+$/, "Only alphabets are allowed for this field ")
  .required('Required');
