import { useEffect, useState } from 'react';
import { PlayerWrapper } from './VideoPlayer.presents';
import {
  ControlGroup,
  Controls,
  FullscreenControl, LoadingScreen,
  PlaybackControl,
  Player,
  ScrubberControl,
  Ui,
  Video, VolumeControl
} from "@vime/react";

export const VideoPlayer = ({ dataSrc }: { dataSrc: string }) => {
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector('vm-player')?.shadowRoot;
      el && el.querySelector('.player.video')?.setAttribute('style', 'height: 100%;')
    }, )
  }, [])

  return <PlayerWrapper>
      <Player>
        <Video>
          <source
              data-src={dataSrc}
          />
        </Video>
        <Ui>
          <LoadingScreen/>
          <Controls onClick={() => setShowControls(true)} hideOnMouseLeave={true} justify="center" pin="center">
            <PlaybackControl hideTooltip/>
          </Controls>

          {showControls && <Controls fullWidth  hideOnMouseLeave={true}>

            <ControlGroup style={{ justifyContent: "space-between", paddingBottom: 10}}>
              <VolumeControl hideTooltip/>
              <FullscreenControl hideTooltip/>
            </ControlGroup>

            <ControlGroup>
              <ScrubberControl />
            </ControlGroup>

          </Controls>}
        </Ui>
      </Player>
  </PlayerWrapper>
}
