import { AvatarContainer, AvatarImage, UploadContainer, UploadButton, DeleteButton, Spinner } from "./Avatar.presents";
import { AvatarProps } from './Avatar.props';
import closeIcon from '../../../assets/img/close.svg';
import { useState } from "react";
import spinnerIcon from '../../../assets/img/spinner.svg';


export const Avatar = (props: AvatarProps) => {
  const [isLoading, setLoading] = useState(false);

  const uploadFile = async (event: any) => {

    const files = event.target.files;
    if (files && files.length > 0) {
      setLoading(true)
      await props.onUpload(event)
      setLoading(false)
    } else {
      // User cancelled the file selection
      setLoading(false)
    }
  }

  const removeFile = async () => {
    setLoading(true)
    await props.onDelete()
    setLoading(false)
  }

  const UploadButtonTitle = isLoading
    ? "Updating..."
    : props.url ? `Change ${props.btnText}` : `Upload ${props.btnText}`

  return (
    <UploadContainer>
      <AvatarContainer>
        {isLoading ? <Spinner src={spinnerIcon} /> 
        : <><input onChange={props.onUpload} type="file" accept={"image/*"} disabled={isLoading} />
            <AvatarImage
              showPlaceHolder={!props.url}
              src={props.url || props.placeholder}
              alt="img" />
          </>}
        {props.url && !isLoading &&
          <DeleteButton onClick={() => removeFile()}>
            <img src={closeIcon} alt="Delete avatar" />
          </DeleteButton>}
      </AvatarContainer>
      <UploadButton>
        <input onChange={uploadFile} type="file" accept={"image/*"} disabled={isLoading} />
        {UploadButtonTitle}
      </UploadButton>
    </UploadContainer>
  )
}