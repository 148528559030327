import { useState } from "react"

export const TableMessage = (props: { message: string }) => {
  const [openMessage, setOpenMessage] = useState(props.message.length < 120 ? true : false)

  const sliceMessage = (lengt: number, message: string) => {
    return message.substr(0, lengt) + '...'
  }
  return !openMessage ?
    <div>
      {sliceMessage(120, props.message)}
      <button className='read-more' onClick={() => setOpenMessage(true)}>Read More</button>
    </div> :
    <p> {props.message} </p>
}