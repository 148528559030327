import { theme } from './../../../theme/theme';
import { colors } from './../../../constants/colors';
import styled from 'styled-components'
import { mainStyleType } from '../../../themes/mainStylesType'

export const StyleForButton = styled("button") <mainStyleType>`
  width: 100%;
  height: 56px;
  background: ${colors.blue};
  border-radius: 12px;
  border:none;
  font-family: Apercu Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  ${(props: mainStyleType) => props.styled === 'transparent' && `
    color: #3F474F;
    width:fit-content;
    border: 1px solid #E2E3E5;
    background: none;
    padding: 0 48px;
  `}
  ${(props: mainStyleType) => props.styled === 'warning' && `
    background: #EC923C;
    box-shadow: 8px 8px 16px rgba(95, 120, 172, 0.05);
    border-radius: 8px;
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    height: 52px;
  `}
  &:disabled{
    background-color: ${theme.colors.lightGray};
  } 
`