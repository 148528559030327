import { Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { SPSecondaryButton } from "../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { CandidateFormCard } from "../../../../common/Cards/CandidateFormCard/CandidateFormCard"
import { ContentWrap, LabelWrap, MultiField, MultiFieldWrap, SaveButtonWrap } from "../../ResumeBuilder.presents";
import { ReactComponent as PlusIcon } from "../../../../../assets/img/plusBlack.svg"
import { ArrayItemTitle, ButtonWrap, FieldHeader, RemoveBtn } from "../PersonalInfo/PersonalInfo.presents";
import { ErrorMsg, FieldWrap } from "../../../../../theme/typography";
import { Datepicker } from "../../../../common/DatePicker";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { initialWorkAuthorization } from "../../../../../state/stores/resumeBuilder";
import { ReactComponent as Spinner } from '../../../../../assets/img/simpleSpinner.svg';
import { RemoveModalWindow } from "../RemoveWindow/RemoveWindow";
import { ReactComponent as CheckedGrey } from '../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { getPlaces } from "../../../../../services/api/outsideResources/outsideResources.domains";
import { SelectCom } from "../../../../common/Select";

export const WorkAuthorization = ({
  isDesktop,
  closeBlockHandler
}: {
  isDesktop: boolean;
  closeBlockHandler: () => void;
}) => {
  const { resumeBuilderStore, candidateStore } = useRootStore();
  const [isFirstSubmitComplete, setIsFirstSubmitComplete] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isRemoveWindowOpen, setIsRemoveWindowOpen] = useState(false);
  const [countryList, setCountryList] = useState<Array<{ label: string, value: string }>>([]);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const placeResponse = await getPlaces('country');
      const countryList = placeResponse.data.data;
      setCountryList(countryList);
    })()
  }, []);

  const initialValues = { workAuthorization: !!resumeBuilderStore.workAuthorization.length ? resumeBuilderStore.workAuthorization : [initialWorkAuthorization] };

  return <CandidateFormCard
    isDesktop={isDesktop}
    hasCloseButton
    closeCardCallback={() => setIsRemoveWindowOpen(true)}
    title={'Work Authorization'}>
    <ContentWrap>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={isFirstSubmitComplete}
        validateOnBlur={false}
        validationSchema={
          Yup.object().shape({
            workAuthorization: Yup.array()
              .of(
                Yup.object().shape({
                  country: Yup.string().required(t('validation.required')),
                })
              ),
          })
        }
        onSubmit={async values => {
          setIsLoading(true);
          const result = await resumeBuilderStore.saveWorkAuthorization(values, candidateStore.uid);
          setIsLoading(false);
          if (!result.error) {
            setIsSaved(true);
            setTimeout(() => {
              setShowSaveBtn(false);
              setIsSaved(false);
            }, 3000);
          };
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset
        }) => <form onSubmit={handleSubmit}>
            <FieldArray
              name="workAuthorization">
              {({ remove, push }) => {
                const onChangeHandler = (value: any) => {
                  setShowSaveBtn(true);
                  handleChange(value);
                };

                const setFieldValueHandler = (key: string, value: any) => {
                  setShowSaveBtn(true);
                  setFieldValue(key, value);
                };

                return <div>
                  {values.workAuthorization.map((item: any, index: number) => {
                    const arrayErrors: any = errors.workAuthorization
                    const mainValidationCondition = (fieldName: string) => arrayErrors && arrayErrors[index] && arrayErrors[index][fieldName];
                    return <div key={`workAuthorization${index}`}>
                      {index > 0 && <FieldHeader>
                        <ArrayItemTitle>Additional work authorization</ArrayItemTitle>
                        <RemoveBtn onClick={() => remove(index)}>Delete</RemoveBtn>
                      </FieldHeader>}

                      <FieldWrap>
                        <Field
                          labelText={'Country'}
                          options={countryList}
                          component={SelectCom}
                          name={`workAuthorization.${index}.country`}
                          onChange={() => setShowSaveBtn(true)}
                          error={mainValidationCondition('country')}
                        />
                        <ErrorMsg>{mainValidationCondition('country') ? arrayErrors[index].country : ''}</ErrorMsg>
                      </FieldWrap>

                      <MultiFieldWrap>
                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`workAuthorization.${index}.startDate`}
                              as={Datepicker}
                              value={values.workAuthorization[index].startDate ? new Date(values.workAuthorization[index].startDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`workAuthorization.${index}.startDate`, `${value}`)}
                              label={<LabelWrap>Start Date<span>optional</span></LabelWrap>}
                              maxDate={new Date()}
                              showMonthYearPicker
                            />
                            <ErrorMsg>{mainValidationCondition('startDate') ? arrayErrors[index].startDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                        <MultiField>
                          <FieldWrap>
                            <Field
                              name={`workAuthorization.${index}.endDate`}
                              as={Datepicker}
                              value={values.workAuthorization[index].endDate ? new Date(values.workAuthorization[index].endDate) : ''}
                              setValue={(value: Date) => setFieldValueHandler(`workAuthorization.${index}.endDate`, `${value}`)}
                              label={<LabelWrap>End Date<span>optional</span></LabelWrap>}
                              maxDate={new Date()}
                              minDate={values.workAuthorization[index].startDate && new Date(values.workAuthorization[index].startDate)}
                              showMonthYearPicker
                            />
                            <ErrorMsg>{mainValidationCondition('endDate') ? arrayErrors[index].endDate : ''}</ErrorMsg>
                          </FieldWrap>
                        </MultiField>

                      </MultiFieldWrap>

                    </div>
                  })}

                  <ButtonWrap>
                    <SPSecondaryButton isLight width="100%" type={'button'} onClick={() => push(initialWorkAuthorization)}>
                      Add Another Work Authorization
                      <PlusIcon />
                    </SPSecondaryButton>
                  </ButtonWrap>
                </div>
              }}
            </FieldArray>

            {showSaveBtn && <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
              <SPSecondaryButton onClick={() => setIsFirstSubmitComplete(true)} width="100%" type={'submit'} isLight={isSaved}>
                {isLoading && <Spinner />}
                {isSaved && <div>
                  Saved <CheckedGrey />
                </div>}
                {(!isLoading && !isSaved) && 'Save'}
              </SPSecondaryButton>
            </SaveButtonWrap>}

            {isRemoveWindowOpen && <RemoveModalWindow
              isRemoveWindowOpen={isRemoveWindowOpen}
              setIsRemoveWindowOpen={setIsRemoveWindowOpen}
              removeDataHandler={async () => {
                await resumeBuilderStore.saveWorkAuthorization({ workAuthorization: [] }, candidateStore.uid);
                await resumeBuilderStore.setWorkAuthorization([]);
                handleReset();
                closeBlockHandler();
              }}
            />}
          </form>}
      </Formik>

    </ContentWrap>
  </CandidateFormCard>
}