import { Link } from "react-router-dom"
import { SignUpMobileLink } from "./Footer.presents"

export const Footer = ({ isSignUpPage, isCandidateAuth }: { isSignUpPage?: boolean, isCandidateAuth?: boolean }) => {
  return <SignUpMobileLink>
    {
      isSignUpPage ?
        // Login CTA 
        <>
          Already have an account?
          <Link to={isCandidateAuth ? 'candidate/sign-in' : 'sign-in'}>
            Sign In
          </Link>
        </> :
        // Register CTA 
        <>
          Don’t have an account?
          <Link to={isCandidateAuth ? 'candidate/sign-up' : 'sign-up'}>
            Sign Up
          </Link>
        </>
    }
  </SignUpMobileLink>
}