import { useRef, useState } from "react";
import { useRootStore } from "../../../state/stores/storeContext";
import { ModalForResults } from "../../common/Modal/ModalForResults";
import { PropsType } from "./Organization.props";
import { Menu } from "../../common/Menu/Menu";
import { Container, BtnContainer, FormWrap, Wrapper, PreviewBtnContainer, TitleBlock, Title, BtnsWrap } from "./Organization.presents";
import { SPSecondaryButton } from "../../common/buttons/SPSecondaryButton/SPSecondaryButton";
import { MainInfoBlock } from "./Components/MainInfo/MainInfo";
import { Formik } from "formik";
import { Benefins } from "./Components/Benefins/Benefins";
import { ContactsBlock } from "./Components/Contacts/Contacts";
import { SocialNetworks } from "./Components/SocialNetworks/SocialNetworks";
import { PhotoGallery } from "./Components/PhotoGallery/PhotoGallery";
import { MediaGallery } from "./Components/MediaGallery/MediaGallery";
import { Audio } from "./Components/Audio/Audio";
import { observer } from "mobx-react-lite";
import { DesktopHeader } from "../../common/Headers/AdminHeader/Desktop";
import { MobileAdminHeader } from "../../common/Headers/AdminHeader/Mobile/MobileAdminHeader";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from '../../../assets/img/eyeOpen.svg';
import { useHistory } from "react-router-dom";
import { SPLightButton } from "../../common/buttons/SPLightButton/SPLightButton";
import { ReactComponent as PublicView } from '../../../assets/img/public-view.svg';
import { checkUrlName } from "../../../services/api/organization/organization.domains";
import { MenuList } from "./helper";

export const Organization = observer((props: PropsType) => {
  const { organizationStore } = useRootStore();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [responseResult, setResponseResult] = useState<'error' | 'successfully'>('successfully');
  const [isLoading, setIsLoading] = useState(false);
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [publicPreviewUrlError, setPublicPreviewUrlError] = useState(false);
  const history = useHistory();

  const publicPreviewUrl = organizationStore.publicPreviewUrl;

  const componentRef: any = {
    mainInformation: useRef(null),
    contactInformation: useRef(null),
    socialNetworks: useRef(null),
    commonBenefits: useRef(null),
    images: useRef(null),
    videos: useRef(null),
    audios: useRef(null),
  }

  const menuProps = { componentRef, isDesktop: props.isDesktop, menuList: MenuList };
  const selectedNetworks = organizationStore.initialValues.socialNetworks.map((item: { title: string, link: string }) => item.title);
  const foundationYear = organizationStore.foundationYear ? Date.parse(organizationStore.foundationYear) : new Date();
  const initialValues = {
    ...organizationStore.initialValues,
    selectedNetworks,
    foundationYear,
    logoUrl: organizationStore.logoUrl,
    logoFileId: organizationStore.logoFileId,
    videoGallery: organizationStore.videoGallery,
    mainVideoKey: organizationStore.mainVideoKey,
    audioUrl: organizationStore.audioUrl,
    audioFileId: organizationStore.audioFileId,
    audioName: organizationStore.audioName,
    publicPreviewUrl: organizationStore.publicPreviewUrl
  }

  const handleSubmit = async (values: any) => {
    setFirstSubmitComplete(true)
    setIsLoading(true);
    const isUrlNameIsUniq = (await checkUrlName(values.publicPreviewUrl)).data;
    if (!isUrlNameIsUniq.err) {
      const audio = {
        url: values.audioUrl,
        key: values.audioFileId,
        fileName: values.audioName,
      }
      const foundationYear = new Date(values.foundationYear);
      const serverResponse = await organizationStore.updateOrganizationData({ ...values, audio, foundationYear });
      setResponseResult(serverResponse.err ? 'error' : 'successfully');
    } else {
      setResponseResult('error');
    }
    setModalOpen(true);
    setIsLoading(false);
  }


  const openOrganizationPreview = () => {
    history.push('/organization-preview')
  }

  const openPublicOrganizationPreview = () => {
    window.open(`/organization/${publicPreviewUrl}`, "_blank");
  }

  const isSaveBtnDisable = isLoading || publicPreviewUrlError;


  return <div>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      validationSchema={
        Yup.object().shape({
          name: Yup.string().required(t('validation.required')).max(49, t('validation.max', { characters: 50 })),
          publicPreviewUrl: Yup.string().max(49, t('validation.max', { characters: 50 })).required(t('validation.required')),
          locations: Yup.array()
            .of(
              Yup.object().shape({
                country: Yup.string().required(t('validation.required')),
                territory: Yup.string().required(t('validation.required')),
                city: Yup.string().required(t('validation.required')),
                zipCode: Yup.string().required(t('validation.required')),
                timeZone: Yup.string().required(t('validation.required')),
              })),
          contactPerson: Yup.array()
            .of(
              Yup.object().shape({
                fullName: Yup.string().required(t('validation.required')).max(59, t('validation.max', { characters: 60 })),
                email: Yup.string().email(t('validation.email')).required(t('validation.required')),
                phoneNumber: Yup.string().max(18, t('validation.max', { characters: 18 })),
              })
            ),
        })
      }
      onSubmit={values => handleSubmit(values)}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError
      }) => {
        const mainProps = {
          values,
          errors,
          handleChange,
          isDesktop: props.isDesktop,
          setFieldValue
        }
        const headerProps = {
          isSaveBtnDisable: isSaveBtnDisable, 
          isOrganizationPage: true,
          saveOrganizationChanges: () => handleSubmit(values)
        }
        return <Wrapper>
          {props.isDesktop ? <DesktopHeader {...headerProps} /> : <MobileAdminHeader {...headerProps}/>}
          {!props.isDesktop && <TitleBlock>
            <Title>Organization</Title>
            <BtnsWrap>
            <PreviewBtnContainer>
              <SPLightButton disabled={!publicPreviewUrl} onClick={openPublicOrganizationPreview} width={'100%'}>
                <PublicView />
                Public View
              </SPLightButton>
            </PreviewBtnContainer>
            <PreviewBtnContainer>
              <SPLightButton onClick={openOrganizationPreview} width={'100%'}>
                <EyeIcon />
                Preview
              </SPLightButton>
            </PreviewBtnContainer>
            </BtnsWrap>
          </TitleBlock>}
          <Container isDesktop={props.isDesktop}>
            <Menu {...menuProps} />
            <FormWrap isDesktop={props.isDesktop} onSubmit={handleSubmit}>
              <MainInfoBlock {...mainProps} componentRef={componentRef.mainInformation} setFieldError={setFieldError} setPublicPreviewUrlError={setPublicPreviewUrlError}/>
              <ContactsBlock {...mainProps} componentRef={componentRef.contactInformation} />
              <SocialNetworks {...mainProps} componentRef={componentRef.socialNetworks} />
              <Benefins {...mainProps} componentRef={componentRef.commonBenefits} />
              <PhotoGallery {...mainProps} componentRef={componentRef.images} />
              <MediaGallery  {...mainProps} componentRef={componentRef.videos} />
              <Audio {...mainProps} componentRef={componentRef.audios} />
              <BtnContainer>
                <SPSecondaryButton type={'submit'} onClick={() => setFirstSubmitComplete(true)} width={'100%'} disabled={isSaveBtnDisable}>
                  Save Changes
                </SPSecondaryButton>
              </BtnContainer>
            </FormWrap >
          </Container>
        </Wrapper>

      }}</Formik >
    <ModalForResults
      result={responseResult}
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      text={responseResult === 'successfully' ? 'Your changes have been successfully saved!' : 'Your changes haven`t been updated!'}
    />
  </div>
})