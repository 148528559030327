import closeIcon from '../../../../../assets/img/close.svg'
import spinnerIcon from '../../../../../assets/img/spinner.svg'
import { Photo, Spinner, RemoveIcon } from './PhotoPreview.presets'

interface PhotoPreviewProps {
  url: string
  onRemove: any
  isLoading?: boolean
}

export const PhotoPreview = (props: PhotoPreviewProps) => {
  return (
    <Photo url={ props.isLoading ? "" : props.url }>
      { props.isLoading && <Spinner src={ spinnerIcon } /> }
      {
        props.url && !props.isLoading
          ? <RemoveIcon type="button" onClick={props.onRemove}><img src={closeIcon} alt=""/></RemoveIcon>
          : null
      }
    </Photo>
  )
}
