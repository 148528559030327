import { Field } from "formik";
import { useEffect } from "react";
import { SPLightButton } from "../../../../../common/buttons/SPLightButton/SPLightButton";
import { SPPrimaryButton } from "../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton";
import { ModalWindow } from "../../../../../common/Modal";
import { preferenceMap, SetModalWindowContent } from "./helper";
import { SPPrimarySelect } from "../../../../../common/Select/SPPrimarySelect/SPPrimarySelect";
import { WindowContentWrap, ButtonsWrap } from "./PreferencesForm.presents";
import { initialValuesType, WindowContentType, WindowType } from "./PreferencesForm.props";
import { SPSecondaryButton } from "../../../../../common/buttons/SPSecondaryButton/SPSecondaryButton";

export const PreferencesModal = (props: WindowType) => {
  const { closeWindow, isWindowOpen, windowContent, setFieldValue, middlewareValue, formValues, updateListCallback, isPanel } = props;

  const contentData = SetModalWindowContent(windowContent);
  const fieldValue = formValues[contentData.fieldName as keyof initialValuesType];

  const closeWindowHandler = () => {
    closeWindow();
    setFieldValue('middlewareValue', []);
  }

  useEffect(() => {
    setFieldValue('middlewareValue', fieldValue);
  }, [])

  const updateListHandler = () => {
    const optionList = windowContent === WindowContentType.countries ? preferenceMap.preferenceCountry : preferenceMap.languages;
    const oldList = new Set(optionList);
    for (let item of middlewareValue) {
      oldList.add(item)
    }
    const updatedList = Array.from(oldList);
    updateListCallback(updatedList);
    setFieldValue(contentData.fieldName, middlewareValue);
    setFieldValue('middlewareValue', []);
    closeWindow();
  };

  return <ModalWindow
    closeWindow={closeWindowHandler}
    openWindow={isWindowOpen}
    title={contentData.title}>
    <WindowContentWrap>
      <Field
        component={SPPrimarySelect}
        name={'middlewareValue'}
        options={contentData.optionList}
        value={fieldValue}
        disabled={false}
        isPanel={isPanel}
      />
      <ButtonsWrap>
        <SPLightButton
          type="button"
          onClick={closeWindowHandler}
          width="100%">
          Close
        </SPLightButton>
        {isPanel ?
          <SPSecondaryButton
            type="button"
            onClick={updateListHandler}
            width="100%">
            Save
          </SPSecondaryButton>
          :
          <SPPrimaryButton
            type="button"
            onClick={updateListHandler}
            width="100%">
            Save
          </SPPrimaryButton>
        }
      </ButtonsWrap>
    </WindowContentWrap>
  </ModalWindow>
}