import { BannerWrap, UploaderBlock, UploadButton, Wrapper, BannerImg, DeleteButton } from "./Banner.presents";
import { ReactComponent as UploadImg } from '../../../../../assets/img/uploadImg.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/close.svg';
import { useState } from "react";
import { BannerProps } from "./Banner.props";
import { Preloader } from "../../../../common/Preloader";
import { generateSignedUrl, s3upload } from "../../../../../services/api/fileManagement/fileManagement.domains";
import { useRootStore } from "../../../../../state/stores/storeContext";
import { checkFileSizeInMB } from "../../../../../services/helper";

export const Banner = (props: BannerProps) => {
  const { organizationStore } = useRootStore();
  const [isLoading, setLoading] = useState(false);

  const MAX_PHOTO_SIZE = 25;
  const UPLOAD_FOLDER = "images";
  const { bannerUrl, bannerFileId } = organizationStore;

  const uploadFile = async (event: any) => {
    if (!event.target.files) return

    let uploadFile = event.target.files[0];
    if (!checkFileSizeInMB(uploadFile.size, MAX_PHOTO_SIZE)) {
      event.target.value = null;
      return alert(`Maximum avatar size is ${MAX_PHOTO_SIZE} megabytes`)
    }

    setLoading(true)
    const resp = await generateSignedUrl({ filename: uploadFile.name, filetype: uploadFile.type, folder: UPLOAD_FOLDER });
    if (!resp.data.err) {
      try {
        await s3upload(resp.data.data.url, uploadFile)
        const addBannerDto = {
          banner: {
            key: resp.data.data.key,
            url: resp.data.data.fileUrl,
          }
        }
        await organizationStore.saveSingleFile(addBannerDto)
        organizationStore.setPhotoBanner(addBannerDto.banner.key, addBannerDto.banner.url)
      } catch (error) {
        console.log(error)
      }
    }
    event.target.value = null; // reset input value
    setLoading(false)
  }

  const removeFile = async () => {
    setLoading(true)

    const removeFileDto = {
      dataForUpdate: {
        banner: null
      },
      bucketFolder: UPLOAD_FOLDER,
      fileKey: bannerFileId
    }

    await organizationStore.deleteSingleFile(removeFileDto)
    organizationStore.unsetPhotoBanner()
    setLoading(false)
  }

  const UploadButtonTitle = isLoading ? "Updating..." : 'Upload Cover Image';
  const UploadImgContent = isLoading ? <Preloader /> : <UploadImg />;

  return <Wrapper id='banner'>
    {props.label && props.label}
    <BannerWrap isUploaded={bannerUrl ? true : false}>
      {bannerUrl &&
        <DeleteButton type='button' onClick={() => removeFile()}>
          <CloseIcon />
        </DeleteButton>}
      {bannerUrl && !isLoading ?
        <BannerImg src={bannerUrl} alt='Banner' />
        : <UploaderBlock>
          {UploadImgContent}
          <UploadButton>
            <input onChange={uploadFile} type="file" accept={"image/*"} disabled={isLoading} />
            {UploadButtonTitle}
          </UploadButton>
        </UploaderBlock>}
    </BannerWrap>
  </Wrapper>
} 