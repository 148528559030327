import { Col, Container, Row } from "react-bootstrap"
import { ForgotPass } from '../../components/Auth/Employer/ForgotPass/ForgotPassWrap'
import { Header } from "../../components/Auth/BaseComponents/Header"
import { Banner } from "../../components/Auth/Employer/SignUp/Banner"
import useGetDevice from "../../hooks/useGetDevice"
import { PageContent, PageWrap, Wrapper } from "./ForgotPass.presents"
import { PropsType } from "./ForgotPass.props"

export const ForgotPassPage = (props: PropsType) => {
  const isDesktop = useGetDevice();

  return <Wrapper>
    {isDesktop && <Banner />}
    <PageContent>
      <Header showLogo={!isDesktop} />
      <PageWrap isDesktop={isDesktop}>
        <ForgotPass />
      </PageWrap>
    </PageContent>
  </Wrapper>
}