import styled from "styled-components";
import { theme } from "../../../theme";

export const Subtitle = styled('p')`
  font-size: ${theme.fontSizes.lg};
  text-align: center;
  margin-top: ${theme.offsets.xl};
`

export const AddJobButton = styled('div')`
  max-width: 196px;
  margin: 0 auto;
  margin-top: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 178px;
    margin-top: ${theme.offsets.xxl};
  }
`

export const MainDescriptionCard = styled('div')<{color?: string, bgColor?: string }>`
  padding: 10px;
  background-color: ${(props)=>props.bgColor ? props.bgColor : '#EEF6FE' };
  color: ${(props)=>props.color ? props.color : theme.colors.black };
  border-radius: ${theme.variables.borderRadiusSmall};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.md};
  width: fit-content;
  display: flex;
  align-items: center;
  margin-right: ${theme.offsets.sm};
  margin-bottom:  ${theme.offsets.sm};
  span{
    margin-top: ${theme.offsets.xs};
  }
  svg{
    margin-right: ${theme.offsets.sm};
  }
`

export const ListItemCard = styled('div')`
  padding: 10px;
  background-color: #F3F1F1;
  color: rgba(45, 53, 61, 0.7);
  border-radius: ${theme.variables.borderRadiusSmall};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.md};
  width: fit-content;
  margin-right: ${theme.offsets.sm};
  margin-bottom:  ${theme.offsets.sm};
`

export const BenefitsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
`

export const MainBonusesWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.offsets.xxl};
  @media(max-width: ${theme.screens.mobile}) {
    margin-top: ${theme.offsets.lg};
  }
`

export const JobTitle = styled('p')`
  font-size: ${theme.fontSizes.big};
  font-family: 'Recoleta';
  text-transform: capitalize;
  margin-top: ${theme.offsets.sm};
  cursor: pointer;
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.xxl};
  }
`

export const JobDescription = styled('p')`
  font-size: 14px;
  margin-top: ${theme.offsets.sm};
`

export const Wrapper = styled('div')`
  position: relative;
`

export const SnapshotsWrap = styled('div')`
  display: flex;
  flex-wrap: wrap;
`

export const SnapshotsSection = styled('div')`
  display: flex;
  margin-top: ${theme.offsets.extraBig};
  justify-content: space-between;
  align-items: flex-end;
  @media(max-width: ${theme.screens.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ApplyBtnWrap = styled('div')`
  max-width: 173px;
  width: 100%;
  margin-bottom:  ${theme.offsets.sm};
  margin-left: ${theme.offsets.lg};
  @media(max-width: ${theme.screens.tablet}) {
    max-width: 131px;
  }
  @media(max-width: ${theme.screens.mobile}) {
    max-width: 100%;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: ${theme.offsets.xl};
  }
`

export const JobUpdating = styled('span')`
  position: absolute;
  color: rgba(45, 53, 61, 0.7);
  font-size: 14px;
  top: ${theme.offsets.extraBig};
  right: ${theme.offsets.extraBig};
  @media(max-width: ${theme.screens.tablet}) {
    font-size: ${theme.fontSizes.md};
    top: ${theme.offsets.xxl};
    right: ${theme.offsets.xxl};
  }
  @media(max-width: ${theme.screens.mobile}) {
    display: none;
  }
`