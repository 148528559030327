import { colors } from '../../../constants/colors';
import styled from 'styled-components'
import { mainStyleType } from '../../../themes/mainStylesType'
import { FileBtnPropsType } from './FileBtn.props'

export const StyleForFileBtn = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`
export const StyleForLogoWrap = styled('div')`
.title{
  font-size: 14px;
  line-height: 18px;
  color: ${colors.dark};
  margin-bottom: 8px;
}
.photo-block{
  background: #F6F8FA;
  border: 1px solid #D1D7E3;
  border-radius: 16px;
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  & .delete-file{
    background: #fff;
    z-index: 3;
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    border: 1px #433E3A solid;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 8px;
    top: 8px;
  }
  & .delete-file img{
    width: 7px;
    height: 7px;
  };
}
.photo-block img{
  width: 32px;
  height: 32px;
  object-fit: cover;
}
label{
  width: 100%;
  height: 38px;
  padding: 0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
}
.upload-btn div{
  width:auto!important;
}
.photo-block img.spinner-logo{
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.photo-block img.logo-img{
  height: 100%;
  width: 100%;
}
`

export const StyleForLabel = styled("label") <FileBtnPropsType>`
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
  width: 186px;
  height: 52px;
  background: #F8DDC3;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.dark};
  position: relative;
  display: flex;
  justify-content: ${(props) => props.icon ? 'space-between' : 'center'};
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  ${(props) => props.componentType === 'logoComponent' && `
    width: 100%;
    height: 38px;
    border-radius: 8px;
    font-size: 12px;
    padding: 0;
    font-weight: 600;
    & .file-content{
      width: auto!important;
    };
  `}
  &:hover{
    background: #EFC093;
  }
  &.uploaded{
    background: #F6F8FA;
    .media-title{
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
      }
    }
    .delete-file{
      border:none;
      background: none;
      z-index: 999;
    }
  }
.spinner{
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
`

export const FileBtnWrap = styled("div")`
  margin: ${(props: mainStyleType) => props.m ? props.m : '0px'};
  display: flex;
  justify-content: center;
`