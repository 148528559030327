import { Field, FieldArray, Formik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import {initialValues, preferenceMap} from './helper';
import { PreferenceField } from './PreferenceField/PreferenceField';
import {
  FieldBox,
  FieldsWrap,
  OpenWindowButton,
  Separator,
  Subtitle,
  Title,
  Wrapper,
  SubmitButtonWrap,
  ErrorText,
  MainText, WrapperSection
} from './PreferencesForm.presents';
import { WindowContentType } from './PreferencesForm.props';
import { PreferencesModal } from './PreferencesModal';
import { SPPrimaryButton } from '../../../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../../../state/stores/storeContext';
import { observer } from "mobx-react-lite";
import { SPSecondaryButton } from '../../../../../common/buttons/SPSecondaryButton/SPSecondaryButton';
import {
  onGetAnonymousUserPreferences,
  onGetCandidateExpertises,
  onGetCandidatePreferences
} from '../../../../../../services/firebase/firestore/reads';
import { SaveButtonWrap } from '../../../../../CandidateAdminPanel/ResumeBuilder/ResumeBuilder.presents';
import { ReactComponent as Spinner } from '../../../../../../assets/img/simpleSpinner.svg';
import { ReactComponent as CheckedGrey } from '../../../../../../assets/img/resumeBuilder/checkedGrey.svg';
import { ExpertiseField } from '../../Steps/Expertise/ExpertiseField';
import { AddMoreButton } from '../../Steps/Expertise/ExpertiseStep.presents';
import candidateOccupationAreas from "../../../../../../assets/staticData/candidateOccupationAreas.json"
import {FocusError} from "../../../../../JobStoryBuilder/BasicComponents/FocusError/FocusError";


export const PreferencesForm = observer(({ isPanel }: { isPanel: boolean }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowContent, setWindowContent] = useState(WindowContentType.countries);
  const [languagesList, setLanguagesList] = useState(preferenceMap.languages);
  const [countriesList, setCountriesList] = useState(preferenceMap.preferenceCountry);

  const requiredStringRule = Yup.string().required(t('validation.required'));
  const requiredArrayRule = (minRequired: number = 1) => Yup.array().min(minRequired, t('validation.required'));
  const { candidateStore, resumeBuilderStore } = useRootStore();
  const [preferences, setPreferences] = useState<any>({})
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);
  const [areasOptionsList, setAreasOptionsList] = useState(candidateOccupationAreas.map(item => { return { ...item, disable: false } }));

  const DEFAULT_ANY_VALUE = 'I don’t have any preferences';

  useEffect(() => {
    (async () => {
      const _preferences = candidateStore.isBackToPreferences ? await onGetAnonymousUserPreferences(candidateStore.uid) : await onGetCandidatePreferences(candidateStore.uid)
      const _expertises = await onGetCandidateExpertises(candidateStore.uid)
      setPreferences(isPanel ? {..._preferences, ..._expertises} : _preferences);
      setIsLoaded(true)
    })()
  }, [])


  const validationSchema = () => {
    return Yup.object().shape({
      jobKind: requiredStringRule,
      includesOvertime: requiredStringRule,
      jobTraveling: requiredStringRule,
      jobStart: requiredStringRule,
      jobTime: requiredArrayRule(),
      workShift: requiredArrayRule(),
      jobType: requiredArrayRule(),
      workplaceBenefits: requiredArrayRule(4),
      preferenceCountry: requiredArrayRule(),
      languages: requiredArrayRule(),
      levelSkills: requiredArrayRule(),
      expertiseAreas: isPanel ? Yup.array().of(
        Yup.object().shape({
          area: Yup.string().required('Area of Expertise is required'),
          skills: Yup.mixed().when('area', {
            is: (val: string) => val !== 'Other',
            then: Yup.array().min(1, 'Skills are required').of(Yup.string()).min(2, 'Skills should contain at least 2 items').max(6, 'Skills should contain maximum 6 items'),
            otherwise: Yup.mixed(),
          })
        })
      ) : Yup.mixed().notRequired(),
    })
  };

  const openWindowHandler = (type: WindowContentType) => {
    setIsModalOpen(true);
    setWindowContent(type);
  };

  const updateListCallback = () => {
    switch (windowContent) {
      case WindowContentType.countries:
        return setCountriesList;
      case WindowContentType.languages:
        return setLanguagesList;
      default:
        return setCountriesList;
    }
  }

  const updatePreferences = async (values: typeof initialValues) => {
    setIsLoading(true);
    resumeBuilderStore.setPreferences(values);
    resumeBuilderStore.setExpertises({expertiseAreas: values.expertiseAreas});
    const resultPreferences = await candidateStore.setPreferences(isPanel, candidateStore.isAnonymous, candidateStore.uid, values);
    let resultExpertises = false;
    if (isPanel) {
      resumeBuilderStore.setExpertises({expertiseAreas: values.expertiseAreas});
      resultExpertises = await candidateStore.setExpertises(isPanel, candidateStore.isAnonymous, candidateStore.uid, {expertiseAreas: values.expertiseAreas});
    }
    candidateStore.setIsBackToPreferences(false);
    setIsLoading(false);
    if (!resultPreferences.error && resultExpertises) {
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 3000);
    };
  }
  return <>
    {isLoaded &&
      <Formik
        validateOnChange={firstSubmitComplete}
        validateOnBlur={false}
        initialValues={preferences ? preferences : initialValues}
        validationSchema={validationSchema}
        onSubmit={updatePreferences}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (<form onSubmit={handleSubmit}>
        <FocusError/>
          {isPanel && <WrapperSection isPanel={isPanel}>
            <Title>Select Your Work Preferences</Title>
            <MainText>You can select your work preferences, such as the type of jobs, work schedule, workstyle, workplace model, and the values and activities you enjoy, as well as identifying the environments in which you work best. </MainText>
          </WrapperSection>}

          <Wrapper isPanel={isPanel}>
            <Title>How would you like to work?</Title>
            <FieldsWrap>
              {
                preferenceMap.jobKind.map((item, index) => <FieldBox key={`jobKind_${index}`}>
                  <Field
                    component={PreferenceField}
                    inputType='radio'
                    fieldName='jobKind'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.jobKind}</ErrorText>
              <Separator />
            </FieldsWrap>
            <Title>What are your preferred work hours?</Title>
            <Subtitle>Choose up to 2</Subtitle>
            <FieldsWrap>
              {
                preferenceMap.workShift.map((item, index) => <FieldBox key={`workShift${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='workShift'
                    fieldValue={item}
                    labelText={item}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (values.workShift[0] === DEFAULT_ANY_VALUE) {
                        setFieldValue('workShift', [event.target.value]);
                      } else {
                        handleChange(event);
                      }
                    }}
                    valueMaxLength={2}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <FieldBox>
                <Field
                  component={PreferenceField}
                  fieldName='workShift'
                  fieldValue={DEFAULT_ANY_VALUE}
                  labelText={DEFAULT_ANY_VALUE}
                  isAnyValue
                  onChange={() => {
                    if (values.workShift[0] === DEFAULT_ANY_VALUE) {
                      setFieldValue('workShift', []);
                    } else {
                      setFieldValue('workShift', [DEFAULT_ANY_VALUE])
                    }
                  }}
                  isPanel={isPanel}
                />
              </FieldBox>
              <ErrorText>{errors.workShift}</ErrorText>
              <Separator />
            </FieldsWrap>
            <Title>What are your preferred weekly schedules?</Title>
            <Subtitle>Choose up to 3</Subtitle>
            <FieldsWrap>
              {
                preferenceMap.jobTime.map((item, index) => <FieldBox key={`jobTime${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='jobTime'
                    fieldValue={item}
                    labelText={item}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (values.jobTime[0] === DEFAULT_ANY_VALUE) {
                        setFieldValue('jobTime', [event.target.value]);
                      } else {
                        handleChange(event);
                      }
                    }}
                    valueMaxLength={3}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <FieldBox>
                <Field
                  component={PreferenceField}
                  fieldName='jobTime'
                  fieldValue={DEFAULT_ANY_VALUE}
                  labelText={DEFAULT_ANY_VALUE}
                  isAnyValue
                  onChange={() => {
                    if (values.jobTime[0] === DEFAULT_ANY_VALUE) {
                      setFieldValue('jobTime', []);
                    } else {
                      setFieldValue('jobTime', [DEFAULT_ANY_VALUE])
                    }
                  }}
                  isPanel={isPanel}
                />
              </FieldBox>
              <ErrorText>{errors.jobTime}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>Are you open to work over weekends or after regular business hours?</Title>
            <FieldsWrap>
              {
                preferenceMap.includesOvertime.map((item, index) => <FieldBox key={`includesOvertime${index}`}>
                  <Field
                    component={PreferenceField}
                    inputType='radio'
                    fieldName='includesOvertime'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.includesOvertime}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>Would you like jobs with traveling?</Title>
            <FieldsWrap>
              {
                preferenceMap.jobTraveling.map((item, index) => <FieldBox key={`jobTraveling${index}`}>
                  <Field
                    component={PreferenceField}
                    inputType='radio'
                    fieldName='jobTraveling'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.jobTraveling}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>What types of jobs are you interested in?</Title>
            <Subtitle>Choose up to 3</Subtitle>
            <FieldsWrap>
              {
                preferenceMap.jobType.map((item, index) => <FieldBox key={`jobType${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='jobType'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    valueMaxLength={3}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.jobType}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>What are the top 4 important things you’d like to see in your new workplace?</Title>
            <Subtitle>Must choose 4</Subtitle>
            <FieldsWrap>
              {
                preferenceMap.workplaceBenefits.map((item, index) => <FieldBox key={`workplaceBenefits${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='workplaceBenefits'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    valueMaxLength={4}
                    valueMinLength={4}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.workplaceBenefits}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>Where would you like to work?</Title>
            <Subtitle>Choose up to 10</Subtitle>
            <FieldsWrap>
              {
                countriesList.map((item, index) => <FieldBox key={`preferenceCountry${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='preferenceCountry'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    valueMaxLength={10}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
            </FieldsWrap>
            <OpenWindowButton isPanel={isPanel} onClick={() => openWindowHandler(WindowContentType.countries)} >+ More Countries</OpenWindowButton>
            <ErrorText>{errors.preferenceCountry}</ErrorText>
            <Separator />

            <Title>When are you looking to start your new job?</Title>
            <FieldsWrap>
              {
                preferenceMap.jobStart.map((item, index) => <FieldBox key={`jobStart${index}`}>
                  <Field
                    component={PreferenceField}
                    inputType='radio'
                    fieldName='jobStart'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
              <ErrorText>{errors.jobStart}</ErrorText>
              <Separator />
            </FieldsWrap>

            <Title>In which languages can you work?</Title>
            <Subtitle>Choose up to 10</Subtitle>
            <FieldsWrap>
              {
                languagesList.map((item, index) => <FieldBox key={`languages${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='languages'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    valueMaxLength={10}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
            </FieldsWrap>
            <OpenWindowButton isPanel={isPanel} onClick={() => openWindowHandler(WindowContentType.languages)}>+ More Languages</OpenWindowButton>
            <ErrorText>{errors.languages}</ErrorText>
            <Separator />

            <Title>What level of jobs are you looking for?</Title>
            <Subtitle>Choose up to 3</Subtitle>
            <FieldsWrap>
              {
                preferenceMap.levelSkills.map((item, index) => <FieldBox key={`levelSkills${index}`}>
                  <Field
                    component={PreferenceField}
                    fieldName='levelSkills'
                    fieldValue={item}
                    labelText={item}
                    onChange={handleChange}
                    valueMaxLength={3}
                    isPanel={isPanel}
                  />
                </FieldBox>)
              }
            </FieldsWrap>
            <ErrorText>{errors.levelSkills}</ErrorText>

            {isPanel && <>
              <Separator />

              <Title>What are your areas of expertise?</Title>
              <Subtitle>You can also add your areas of expertise that can be leveraged completely remotely for jobs that are fully virtual. These options are limited to certain skills that can be performed remotely. Not all skills are listed here. Keep in mind that if you cannot find your areas of expertise or skills in this section, you should choose "other". Then navigate to the 'My Resume' section and add your skills under the 'Technical Skills' or 'Social and Business Skills' sections.</Subtitle>
              <FieldArray
                name="expertiseAreas">
                {({ remove, push }) => {
                  return <>
                    {values?.expertiseAreas && values.expertiseAreas.map((item, index) => <ExpertiseField
                      isPanel={isPanel}
                      setFieldValue={setFieldValue}
                      options={areasOptionsList}
                      setOption={setAreasOptionsList}
                      handleChange={handleChange}
                      values={values.expertiseAreas}
                      fieldIndex={index}
                      remove={remove}
                      errors={errors}
                    />
                    )}
                    <AddMoreButton isPanel={isPanel} onClick={() => push({ area: "", skills: [] })}>+ Add more Areas of Expertise</AddMoreButton>
                  </>
                }}
              </FieldArray>
            </>}

            {
              isModalOpen && <PreferencesModal
                windowContent={windowContent}
                isWindowOpen={isModalOpen}
                closeWindow={() => setIsModalOpen(false)}
                formValues={values}
                setFieldValue={setFieldValue}
                middlewareValue={values.middlewareValue}
                updateListCallback={updateListCallback()}
                isPanel={isPanel}
              />
            }
          </Wrapper>

          {isPanel ?
            <>
              <SaveButtonWrap isLoading={isLoading} isSaved={isSaved}>
                <SPSecondaryButton width="100%" type={'submit'} isLight={isSaved}>
                  {isLoading && <Spinner />}
                  {isSaved && <div>
                    Saved <CheckedGrey />
                  </div>}
                  {(!isLoading && !isSaved) && 'Save'}
                </SPSecondaryButton>
              </SaveButtonWrap>
            </>
            :
            <SubmitButtonWrap>
              <SPPrimaryButton isLoading={isLoading} width='100%' type='submit' onClick={() => setFirstSubmitComplete(true)}>
                Next Step
              </SPPrimaryButton>
            </SubmitButtonWrap>
          }

        </form>
        )}
      </Formik>
    }
  </>
})
