//@ts-ignore
import Button from 'react-video-recorder/lib/defaults/button';
//@ts-ignore
import Countdown from 'react-video-recorder/lib/defaults/countdown';
import { ActionButtons, ActionsWrapper, ActionWrap, Circle, RemoveVideoBtn, StartButton, StopButton } from './VideoRecord.presents';
import { ReactComponent as CloseIcon } from '../../../../assets/img/close.svg'
import { SPPrimaryButton } from '../../../common/buttons/SPPrimaryButton/SPPrimaryButton';
import { useEffect, useState } from 'react';
import { SPSecondaryButton } from '../../../common/buttons/SPSecondaryButton/SPSecondaryButton';

export const Actions = ({
  t,
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStopReplaying,
  onConfirm,
  isDesktop,
  recordingRef,
  saveVideoHandler,
  isResumeBuilder
}: PropsTypes) => {

  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null
    }

    if (isRecording) {
      return (
        <StopButton
          type='button'
          onClick={() => onStopRecording()}
        />
      )
    }

    if (isCameraOn && streamIsReady) {
      return (
        <StartButton
          type='button'
          onClick={() => onStartRecording()}
        />
      )
    }

  }

  return (
    <ActionWrap>
      {isReplayingVideo && <ActionButtons isDesktop={isDesktop}>
        <RemoveVideoBtn
          type='button'
          onClick={() => onStopReplaying()}>
          <CloseIcon />
          Delete video
        </RemoveVideoBtn>
        {(!isDesktop && !isResumeBuilder) && <SPPrimaryButton type='button' onClick={() => saveVideoHandler()} width='100%'>
          Save video
        </SPPrimaryButton>}
        {(!isDesktop && isResumeBuilder) && <SPSecondaryButton type='button' onClick={() => saveVideoHandler()} width='100%'>
          Save video
        </SPSecondaryButton>}
      </ActionButtons>
      }
      {/* {(isReplayingVideo && showBtn) && <Circle onClick={playVideoHandler} />} */}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </ActionWrap>
  )
}

type PropsTypes = {
  t: Function,
  isVideoInputSupported: boolean,
  isInlineRecordingSupported: boolean,
  thereWasAnError: boolean,
  isRecording: boolean,
  isCameraOn: boolean,
  streamIsReady: boolean,
  isConnecting: boolean,
  isRunningCountdown: boolean,
  countdownTime: number,
  timeLimit: number,
  showReplayControls: boolean,
  replayVideoAutoplayAndLoopOff: boolean,
  isReplayingVideo: boolean,
  useVideoInput: boolean,

  onTurnOnCamera: Function,
  onTurnOffCamera: Function,
  onOpenVideoInput: Function,
  onStartRecording: Function,
  onStopRecording: Function,
  onPauseRecording: Function,
  onResumeRecording: Function,
  onStopReplaying: Function,
  onConfirm: Function,
  isDesktop: boolean,
  recordingRef: any,
  saveVideoHandler: Function,
  isResumeBuilder?: boolean
}