import { flow, types, cast } from "mobx-state-tree";
import {
  onChangeResumeBuilderItem,
  onDeleteVideoResume,
  onGetResume,
  onRemoveFile,
  onUploadResumePhoto,
  onRemoveResumePhoto
} from "../../services/firebase/firestore/ResumeBuilder/writes";
import {ChangeEvent} from "react";

export const initialVideoResume = {
  name: '',
  url: ''
};

export const initialPersonalInfo = {
  jobTitle: '',
  firstName: '',
  lastName: '',
  email: '',
  profileEmail: '',
  phone: '',
  summary: ''
};

export const initialLocation = {
  country: '',
  state: '',
  city: '',
  zipCode: ''
};

export const initialDemographicalInfo = {
  gender: '',
  pronouns: '',
  ethnicity: ''
};

export const initialInterests = { interests: '' };

export const initialAboutCandidate = { question: '', answer: '' };

export const initialEmploymentHistory = {
  position: '',
  employerName: '',
  employmentType: '',
  startDate: '',
  endDate: '',
  isCurrentJob: false,
  description: ''
};

export const initialEducation = {
  institutionName: '',
  degree: '',
  studyField: '',
  startDate: '',
  endDate: '',
  isStillStudy: false,
  description: ''
};

export const initialCertifications = {
  title: '',
  institution: '',
  date: '',
  description: '',
  file: {
    url: '',
    name: '',
    isUploaded: false
  }
};

export const initialReferences = {
  fullName: '',
  company: '',
  email: '',
  phone: '',
};

export const initialLanguages = {
  language: '',
  level: '',
};

export const initialTrainings = {
  title: '',
  name: '',
  credentialID: '',
  startDate: '',
  endDate: '',
  isStillStudy: false,
  description: '',
  credentialUrl: ''
};

export const initialNonprofit = {
  employer: '',
  position: '',
  startDate: '',
  endDate: '',
  isCurrentJob: false,
  description: '',
};

export const initialWorkAuthorization = {
  country: '',
  startDate: '',
  endDate: '',
};

export const initialMembership = {
  organizationName: '',
  position: '',
  startDate: '',
  endDate: '',
  isMembershipOngoing: false,
  description: '',
};

export const initialHonors = {
  title: '',
  institution: '',
  date: '',
  awardURL: '',
};

export const initialPortfolio = {
  title: '',
  url: '',
  description: '',
  file: {
    url: '',
    name: '',
    isUploaded: false
  }
};

export const initialPublication = {
  title: '',
  institution: '',
  date: '',
  url: '',
  description: '',
};

export const initialSocialNetworks = [
  {
    title: 'Website',
    url: ''
  },
  {
    title: 'LinkedIn',
    url: ''
  },
  {
    title: 'Instagram',
    url: ''
  },
  {
    title: 'Twitter',
    url: ''
  },
  {
    title: 'Facebook',
    url: ''
  },
  {
    title: 'Whatsapp',
    url: ''
  },
];

export const resumeBuilderModel = types
  .model("resumeBuilderStore", {
    uid: types.optional(types.string, ""),
    isDataUpdated: types.optional(types.boolean, false),
    aboutCandidateMainAnswer: types.optional(types.maybeNull(types.string), null),
    videoResume: types.optional(types.model(initialVideoResume), initialVideoResume),
    personalInfo: types.optional(types.model(initialPersonalInfo), initialPersonalInfo),
    logo: types.maybeNull(types.model({
      url: types.string,
      name: types.string
    })),
    location: types.optional(types.model(initialLocation), initialLocation),
    demographicalInfo: types.optional(types.model(initialDemographicalInfo), initialDemographicalInfo),
    interests: types.optional(types.string, ""),
    aboutCandidate: types.optional(types.array(types.model(initialAboutCandidate)), []),
    technicalSkills: types.optional(types.array(types.string), []),
    socialSkills: types.optional(types.array(types.string), []),
    employmentHistory: types.optional(types.array(types.model(initialEmploymentHistory)), []),
    education: types.optional(types.array(types.model(initialEducation)), []),
    certifications: types.optional(types.array(types.model(
      {
        ...initialCertifications, file: types.model({
          url: types.string,
          name: types.string,
          isUploaded: types.boolean
        })
      })), []),
    references: types.optional(types.array(types.model(initialReferences)), []),
    languages: types.optional(types.array(types.model(initialLanguages)), []),
    trainings: types.optional(types.array(types.model(initialTrainings)), []),
    nonprofit: types.optional(types.array(types.model(initialNonprofit)), []),
    workAuthorization: types.optional(types.array(types.model(initialWorkAuthorization)), []),
    membership: types.optional(types.array(types.model(initialMembership)), []),
    honors: types.optional(types.array(types.model(initialHonors)), []),
    portfolio: types.optional(types.array(types.model({
      ...initialPortfolio,
      file: types.model({
        url: types.string,
        name: types.string,
        isUploaded: types.boolean
      })
    })), []),
    publication: types.optional(types.array(types.model(initialPublication)), []),
    socialNetworks: types.optional(types.array(types.model({
      title: types.string,
      url: types.string
    })), initialSocialNetworks),
    isResumeOpen: types.optional(types.boolean, false),
    preferences: types.frozen(),
    expertises: types.frozen(),
    isResumeVisible: types.maybeNull(types.optional(types.boolean, true)),
    profileEmail: types.maybeNull(types.string)
  })
  //Setters
  .actions(self => {

    const setVideoResume = (data: typeof initialVideoResume) => {
      self.videoResume = cast(data);
    };

    const setPersonalInfo = (data: typeof initialPersonalInfo) => {
      self.personalInfo = cast(data);
    };

    const setLocation = (data: typeof initialLocation) => {
      self.location = cast(data);
    };

    const setDemographicalInfo = (data: typeof initialDemographicalInfo) => {
      self.demographicalInfo = cast(data);
    };

    const setInterests = (data: string) => {
      self.interests = cast(data);
    };

    const setAboutCandidate = (data: Array<typeof initialAboutCandidate>) => {
      self.aboutCandidate = cast(data);
    };

    const setTechnicalSkills = (data: Array<string> | []) => {
      self.technicalSkills = cast(data);
    };

    const setSocialSkills = (data: Array<string> | []) => {
      self.socialSkills = cast(data);
    };

    const setEmploymentHistory = (data: Array<typeof initialEmploymentHistory>) => {
      self.employmentHistory = cast(data);
    };

    const setEducation = (data: Array<typeof initialEducation>) => {
      self.education = cast(data);
    };

    const setCertifications = (data: Array<typeof initialCertifications>) => {
      self.certifications = cast(data);
    };

    const setReferences = (data: Array<typeof initialReferences>) => {
      self.references = cast(data);
    };

    const setLanguages = (data: Array<typeof initialLanguages>) => {
      self.languages = cast(data);
    };

    const setNonprofit = (data: Array<typeof initialNonprofit>) => {
      self.nonprofit = cast(data);
    };

    const setWorkAuthorization = (data: Array<typeof initialWorkAuthorization>) => {
      self.workAuthorization = cast(data);
    };

    const setMembership = (data: Array<typeof initialMembership>) => {
      self.membership = cast(data);
    };

    const setHonors = (data: Array<typeof initialHonors>) => {
      self.honors = cast(data);
    };

    const setTrainings = (data: Array<typeof initialTrainings>) => {
      self.trainings = cast(data);
    };

    const setPortfolio = (data: Array<typeof initialPortfolio>) => {
      self.portfolio = cast(data);
    };

    const setPublication = (data: Array<typeof initialPublication>) => {
      self.publication = cast(data);
    };

    const setSocialNetworks = (data: Array<{
      url: string,
      title: string,
    }>) => {
      self.socialNetworks = cast(data);
    };

    const setAboutCandidateMainAnswer = (data: string) => {
      self.aboutCandidateMainAnswer = cast(data);
    };

    const showResumePreview = (value: boolean) => {
      self.isResumeOpen = value;
    };

    const setPreferences = (value: any) => {
      self.preferences = value;
    };

    const setExpertises = (value: any) => {
      self.expertises = value;
    };

    const setIsResumeVisible = (value: boolean) => {
      self.isResumeVisible = value;
    };

    return {
      setLocation,
      setDemographicalInfo,
      setInterests,
      setAboutCandidate,
      setEmploymentHistory,
      setEducation,
      setCertifications,
      setReferences,
      setLanguages,
      setNonprofit,
      setWorkAuthorization,
      setMembership,
      setHonors,
      setPortfolio,
      setPublication,
      setSocialNetworks,
      setPersonalInfo,
      setVideoResume,
      setTechnicalSkills,
      setSocialSkills,
      setTrainings,
      showResumePreview,
      setAboutCandidateMainAnswer,
      setPreferences,
      setExpertises,
      setIsResumeVisible,
    }
  })
  //Actions to modify
  .actions(self => {
    const setResumeData = flow(function* (uid: string) {
      try {
        const data: any = yield onGetResume(uid);
        self.uid = uid;
        self.isDataUpdated = true;
        self.logo = (data.logo && data.logo.url) ? data.logo : null;
        self.profileEmail = data.profileEmail;
        self.setPersonalInfo(data.personalInfo);
        self.setVideoResume(data.videoResume);
        self.setLocation(data.location);
        self.setDemographicalInfo(data.demographicalInfo);
        self.setInterests(data.interests);
        self.setAboutCandidate(data.aboutCandidate);
        self.setTechnicalSkills(data.technicalSkills);
        self.setSocialSkills(data.socialSkills);
        self.setEmploymentHistory(data.employmentHistory);
        self.setEducation(data.education);
        self.setCertifications(data.certifications);
        self.setSocialNetworks(data.socialNetworks);
        self.setReferences(data.references);
        self.setLanguages(data.languages);
        self.setNonprofit(data.nonprofit);
        self.setWorkAuthorization(data.workAuthorization);
        self.setHonors(data.honors);
        self.setTrainings(data.trainings);
        self.setPortfolio(data.portfolio);
        self.setPublication(data.publication);
        self.setMembership(data.membership);
        self.setAboutCandidateMainAnswer(data.aboutCandidateMainAnswer);
        self.setIsResumeVisible(data.isResumeVisible);
      } catch (e) {
        console.log(e)
        return { error: 'Failed to save personal info' }
      }
    });

    const clearResumeBuilder = () => {
      self.isDataUpdated = false;
      self.logo = null;
      self.setPersonalInfo(initialPersonalInfo);
      self.setVideoResume(initialVideoResume);
      self.setLocation(initialLocation);
      self.setDemographicalInfo(initialDemographicalInfo);
      self.setInterests('');
      self.setAboutCandidate([initialAboutCandidate]);
      self.setTechnicalSkills([]);
      self.setSocialSkills([]);
      self.setEmploymentHistory([initialEmploymentHistory]);
      self.setEducation([initialEducation]);
      self.setCertifications([initialCertifications]);
      self.setSocialNetworks([]);
      self.setReferences([initialReferences]);
      self.setLanguages([initialLanguages]);
      self.setNonprofit([initialNonprofit]);
      self.setWorkAuthorization([initialWorkAuthorization]);
      self.setHonors([initialHonors]);
      self.setTrainings([initialTrainings]);
      self.setPortfolio([initialPortfolio]);
      self.setPublication([initialPublication]);
      self.setMembership([initialMembership]);
      self.setAboutCandidateMainAnswer('');
      self.setIsResumeVisible(false);
    }

    const uploadResumeLogo = flow(function* (event: ChangeEvent<HTMLInputElement>) {
      if (event.target.files) {
        yield removeResumeLogo();
        const serverResponse: any = yield onUploadResumePhoto(event.target.files[0], self.uid);
        self.logo = serverResponse;
      }
    })

    const removeResumeLogo = flow(function* () {
      if (self.logo) {
        const serverResponse: any = yield onRemoveResumePhoto(self.logo.name, self.uid);
        if (!serverResponse.error) self.logo = null;
      }
    })


    const saveVideoResume = flow(function* (data: { videoResume: typeof initialVideoResume }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setVideoResume(data.videoResume)
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save video resume' }
      }
    });

    const removeVideoResume = flow(function* (uid) {
      try {
        const resume = yield onGetResume(uid);
        const videoResume = resume.videoResume
        yield onDeleteVideoResume(uid)
        const serverResult = yield onRemoveFile(videoResume.name, uid)
        self.videoResume = initialVideoResume
        return serverResult;
      } catch (e) {
        return { error: 'Failed to remove video resume' }
      }
    });


    const savePersonalInfo = flow(function* (data: { personalInfo: typeof initialPersonalInfo }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setPersonalInfo(data.personalInfo);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save personal info' }
      }
    });

    const saveLocation = flow(function* (data: { location: typeof initialLocation }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save location info' }
      }
    });

    const saveDemographicalInfo = flow(function* (data: { demographicalInfo: typeof initialDemographicalInfo }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save demographical info' }
      }
    });

    const saveInterests = flow(function* (data: typeof initialInterests, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setInterests(data.interests);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save interests info' }
      }
    });

    const saveAboutCandidate = flow(function* (data: { aboutCandidate: Array<typeof initialAboutCandidate> | [] }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setAboutCandidate(data.aboutCandidate);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save about candidate  info' }
      }
    });

    const saveTechnicalSkills = flow(function* (data: { technicalSkills: Array<string> | [] }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setTechnicalSkills(data.technicalSkills);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save about candidate  Technical Skills' }
      }
    });

    const saveSocialSkills = flow(function* (data: { socialSkills: Array<string> | [] }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setSocialSkills(data.socialSkills);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save about candidate  Social Skills' }
      }
    });

    const saveEmploymentHistory = flow(function* (data: { employmentHistory: Array<typeof initialEmploymentHistory> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setEmploymentHistory(data.employmentHistory);
        return serverResult;
      } catch (e) {
        return { error: 'Failed to save employment history' }
      }
    });

    const saveEducation = flow(function* (data: { education: Array<typeof initialEducation> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setEducation(data.education);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save education' }
      }
    });

    const saveCertifications = flow(function* (data: { certifications: Array<typeof initialCertifications> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setCertifications(data.certifications);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save certifications' }
      }
    });

    const saveReferences = flow(function* (data: { references: Array<typeof initialReferences> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setReferences(data.references);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save references' }
      }
    });

    const saveLanguages = flow(function* (data: { languages: Array<typeof initialLanguages> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save languages' }
      }
    });

    const saveTrainings = flow(function* (data: { trainings: Array<typeof initialTrainings> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setTrainings(data.trainings);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save trainings ' }
      }
    });

    const saveNonprofit = flow(function* (data: { nonprofit: Array<typeof initialNonprofit> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setNonprofit(data.nonprofit)
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save nonprofits' }
      }
    });

    const saveWorkAuthorization = flow(function* (data: { workAuthorization: Array<typeof initialWorkAuthorization> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save work authorization' }
      }
    });

    const saveMembership = flow(function* (data: { membership: Array<typeof initialMembership> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setMembership(data.membership);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save membership' }
      }
    });

    const saveHonors = flow(function* (data: { honors: Array<typeof initialHonors> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save honors' }
      }
    });

    const savePortfolio = flow(function* (data: { portfolio: Array<typeof initialPortfolio> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setPortfolio(data.portfolio);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save honors' }
      }
    });

    const savePublication = flow(function* (data: { publication: Array<typeof initialPublication> }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setPublication(data.publication);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save publication' }
      }
    });

    const saveSocialNetworks = flow(function* (data: {
      socialNetworks: Array<{
        title: string,
        url: string
      }> | []
    }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setSocialNetworks(data.socialNetworks);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to save social networks' }
      }
    });

    const saveIsResumeVisible = flow(function* (data: {
      isResumeVisible: boolean
    }, uid) {
      try {
        const serverResult = yield onChangeResumeBuilderItem(uid, data);
        self.setIsResumeVisible(data.isResumeVisible);
        return serverResult;
      } catch (e) {
        return { err: 'Failed to update visibility' }
      }
    });

    return {
      setResumeData,
      clearResumeBuilder,
      saveVideoResume,
      removeVideoResume,
      uploadResumeLogo,
      removeResumeLogo,
      savePersonalInfo,
      saveLocation,
      saveDemographicalInfo,
      saveInterests,
      saveAboutCandidate,
      saveEmploymentHistory,
      saveEducation,
      saveCertifications,
      saveReferences,
      saveLanguages,
      saveNonprofit,
      saveWorkAuthorization,
      saveMembership,
      saveHonors,
      savePortfolio,
      savePublication,
      saveSocialNetworks,
      saveTechnicalSkills,
      saveSocialSkills,
      saveTrainings,
      saveIsResumeVisible,
    }
  })