import { RadioBtn } from '../../BasicComponents/RadioBtn'
import { SchemePropsType } from './SpecificationStep.props'
import { ActiveIcon } from './SpecificationStep.icons'
import { Input } from '../../../common/Input'
import { Field } from 'formik'
import { SelectCom } from '../../../common/Select'
import { Col } from 'react-bootstrap'
import { Benefits } from '../../../common/BenefitSection/Benefits'
import { DropdownQuestions } from '../../BasicComponents/DropdownQuestions'
import { Tags } from '../../BasicComponents/Tags'

export const SelectComponent = (data: SchemePropsType) => {
  switch (data.type) {
    case 'radio':
    case 'radio-empty':
      return <div className="d-flex flex-wrap justify-content-center answer-wrap">
        {data.item.answers.map((answer: any, index: number) => <div className='d-flex' key={index + 'answer' + answer.value}>
          <RadioBtn
            empty={data.type === 'radio-empty' ? true : false}
            text={answer.title}
            subtitle={answer.subtitle ? answer.subtitle : ''}
            activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[data.item.name] ? true : false} />}
            name={data.item.name}
            value={answer.value}
            checked={answer.value === data.values[data.item.name] ? true : false}
            handleChange={data.handleChange}
            dropdownQuestions={data.dropdownQuestions}
            openDropdownQuestions={data.openDropdownQuestions}
          />
        </div>
        )}
        {data.item.answers.map((answer: any, index: number) => <DropdownQuestions errors={data.errors} values={data.values} key={index} answerValue={answer.value} dropdownQuestions={data.dropdownQuestions} handleChange={data.handleChange} setFieldTouched={data.setFieldTouched} />)}
      </div>
    case 'period':
      return <div className="d-flex flex-wrap justify-content-center inputs-wrap align-items-end">
        {data.item.answers.map((element: any, index: number) => <Col key={index + 'answer' + element.name} md={4} className='pl-0'>
          {
            (element.type === 'text' || element.type === 'number') ? <Field
              as={Input}
              name={element.name}
              type={element.type}
              className={index === 1 ? 'job-experience' : ''}
              placeholder={element.placeholder}
              value={data.values[element.name]}
              onChange={data.handleChange}
              setFieldTouched={data.setFieldTouched}
              error={data.errors && data.errors[element.name] ? true : false}
              labelText={<div className='d-flex justify-content-between'>
                <span>{element.labelTitle}</span>
                <span className='optional'>{element.labelSubtitle}</span>
              </div>} />
              : element.type === 'select' ?
                <Field
                  name={element.name}
                  options={element.options ? element.options : [{ value: '', label: '' }]}
                  labelText={<div className='d-flex justify-content-between'>
                    <span></span>
                    <span>{element.labelTitle}</span>
                  </div>}
                  placeholder={'Select period...'}
                  component={SelectCom}
                  setFieldTouched={data.setFieldTouched}
                  error={data.errors && data.errors[element.name] ? true : false}
                />
                : ''
          }
        </Col>)}
      </div>
    case 'check-box':
      const isPrimaryLocationUSA = data.primaryLocation.findIndex((item: any) => item.country !== 'United States') >= 0;
      let benefitList = data.item.answers;

      if (data.item.name === 'retirementSavingsOffered' && isPrimaryLocationUSA){
        benefitList = [...benefitList, ...data.item.additionalAnswers];
      }

      const benefitsProps = {
        benefitList: benefitList,
        fieldName: data.item.name,
        handleChange: data.handleChange,
        title: 'Popular benefits:',
        initialValues: data.values,
      }
      return <Benefits withCustomBenefits {...benefitsProps} />
    case 'internship-type':
      const compensationForInternship = data.allQuestions.find(item => item.id === 'step4_compensationInternship');
      const isIntershipPaid = data.dropdownQuestions.internshipType.paidInternship;
      return data.item.name !== 'compensation' ? <div className="d-flex flex-wrap justify-content-center answer-wrap">
        {data.item ? data.item.answers.map((answer: any, index: number) => <div className='d-flex' key={index + 'answer' + answer.value}>
          <RadioBtn
            text={answer.title}
            subtitle={answer.subtitle ? answer.subtitle : ''}
            activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[data.item.name] ? true : false} />}
            name={data.item.name}
            value={answer.value}
            checked={answer.value === data.values[data.item.name] ? true : false}
            handleChange={data.handleChange}
            dropdownQuestions={data.dropdownQuestions}
            openDropdownQuestions={data.openDropdownQuestions}
          />
        </div>
        ) : ''}
        <div className="d-flex flex-wrap justify-content-center answer-wrap">
          {isIntershipPaid && compensationForInternship.answers.map((answer: any, index: number) => <div className='d-flex' key={index + 'answer' + answer.value}>
            <RadioBtn
              text={answer.title}
              subtitle={answer.subtitle ? answer.subtitle : ''}
              activeIcon={<ActiveIcon name={answer.value} checked={answer.value === data.values[compensationForInternship.name] ? true : false} />}
              name={compensationForInternship.name}
              value={answer.value}
              checked={answer.value === data.values[compensationForInternship.name] ? true : false}
              handleChange={data.handleChange}
              dropdownQuestions={data.dropdownQuestions}
              openDropdownQuestions={data.openDropdownQuestions}
            />
          </div>
          )}
        </div>
        {isIntershipPaid && compensationForInternship.answers.map((answer: any, index: number) => <DropdownQuestions values={data.values} key={index} answerValue={answer.value} dropdownQuestions={data.dropdownQuestions} handleChange={data.handleChange} errors={data.errors} setFieldTouched={data.setFieldTouched} />)}
      </div> : null
    case 'tags':
      return <Field
        m={'40px auto 0'}
        as={Tags}
        name={data.item.name}
        tags={data.values[data.item.name] || []}
        setTegs={(tags: Array<string>) => { data.setFieldValue(data.item.name, tags || []) }}
        error={data.errors && data.touched[data.item.name] && data.errors[data.item.name] ? true : false}
        setFieldTouched={data.setFieldTouched}
        labelText={data.item.labelTitle} />
    default:
  }
}