import { theme } from './../../theme/theme';
import styled from 'styled-components';

export const StyleForWrap = styled('div')`
  margin: 0 auto;
  width:100%;
  padding: 0 ${theme.offsets.lg};
`

export const MainInformationWrapper = styled('div')`
  width:100%;
  max-width: 1112px;
  margin: 0 auto;
  .row{
    margin-right: -15px;
    margin-left: -15px;
  }
`

