import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../../theme";

export const Wrapper = styled("div")`
  display: flex;
  padding: ${theme.offsets.md} ${theme.offsets.lg};
  border-bottom: 1px solid ${theme.colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 60px;
  background-color: ${theme.colors.white};
  @media(max-width: ${theme.screens.mobile}) { 
    min-height: 44px;
    padding: ${theme.offsets.sm} ${theme.offsets.lg};
  }
`;

export const BurgerMenu = styled("div") <{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 16px;
  z-index: 100;
  ${(props) => props.isOpen && `
  span:nth-child(1) {
    transform: rotate(-40deg) translate(-2px, 4px);
    background-color: ${theme.colors.white};
  };
  span:nth-child(2) {
    display: none;
  };
  span:nth-child(3) {
    transform: rotate(40deg) translate(0px, -1px);
    background-color: ${theme.colors.white};
  }
  position: fixed;
  `
  } 
`

export const LogoWrap = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 120px;
  display: flex;
  @media(max-width: ${theme.screens.mobile}) { 
    max-width: 95px; 
  }
`

export const MenuLine = styled("span")`
  display: block;
  width: 100%;
  height: 2px;
  margin-bottom: 3px;
  border-radius: 1px;
  background-color: ${theme.colors.black};
  transition: 0.2s;
`

export const FeedbackBtn = styled(Link)`
border: 1px solid ${theme.colors.lightGray};
border-radius: ${theme.variables.borderRadiusSmall};
padding: ${theme.offsets.md} ${theme.offsets.lg};
display: flex;
align-items: center;
color: #5D7A8C;
  svg{
    margin-right: 6px;
  }
@media(max-width: ${theme.screens.mobile}) { 
  display: none;
}
`

export const OrganizationBtns = styled('div')`
  display: flex;
  align-items: center;
  @media(max-width: ${theme.screens.mobile}) { 
  display: none;
}
`

export const BtnContainer = styled('div')`
  width: 116px;
  margin-left: ${theme.offsets.md};
`