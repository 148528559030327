import * as React from "react"
import { SPBaseButtonProps } from "../SPBaseButton/SPBaseButton.props"
import { PrimaryButton } from "./SPPrimaryButton.presets"
import { ReactComponent as Spinner } from '../../../../assets/img/simpleSpinner.svg';

export const SPPrimaryButton: React.FC<SPBaseButtonProps> = (props) => {
  const clickHandler = (event: any) => {
    props.disablePropagation && event.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <PrimaryButton
      isLoading={props.isLoading}
      data-id={props.testID}
      type={props.type}
      onClick={clickHandler}
      onKeyDown={e => props.disablePropagation && e.stopPropagation()}
      width={props.width}
      mobileHeight={props.mobileHeight}
      tabIndex={props.tabIndex || 0}
      aria-label={props.label}
      disabled={props.disabled || props.isLoading}>
      {props.isLoading ? <Spinner /> : props.children}
    </PrimaryButton>
  )
}
