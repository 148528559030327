import { StyleForQuestion, StyleForWrap, StyleForTitle, StyleForSubtitle } from '../Steps.presents';
import { Container, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { NavBtn } from '../../BasicComponents/NavBtn';
import { HelperText } from '../../../common/HelperText';
import { useRootStore } from '../../../../state/stores/storeContext';
import { PropsType } from './StartStep.props';
import { jobStory } from '../../../../state/models/job.model';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { SelectComponent } from './StartStep.scheme';
import { Text } from '../../../common/Text';
import { handleSubmit, onKeyDown, validationScheme } from './StartStep.helper';
import { FocusError } from '../../BasicComponents/FocusError/FocusError';
import { useTranslation } from 'react-i18next';


export const StartStep = observer(({ jobId, createDependentsForStep, saveAnswers }: PropsType) => {
  const { t } = useTranslation();
  const { jobStoryBuilder, organizationStore } = useRootStore();
  const [firstSubmitComplete, setFirstSubmitComplete] = useState(false);

  const savedLocation = jobStoryBuilder.answers.startStep.locations;
  const locationsTest = savedLocation.length > 0 && savedLocation[0].territory ? savedLocation : [...organizationStore.initialValues.locations];
  const updatingUrl = `/job-story/job`;

  return <StyleForWrap>
    <Formik
      enableReinitialize
      initialValues={{ ...jobStoryBuilder.answers.startStep, locations: locationsTest }}
      validateOnChange={firstSubmitComplete}
      validateOnBlur={false}
      validationSchema={validationScheme(t)}
      onSubmit={values => {
        const submitData = {
          stepModel: jobStoryBuilder.steps.stepStart,
          updatingUrl,
          values,
          createDependentsForStep,
          saveAnswers
        }
        handleSubmit(submitData);
      }
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched
      }) => (
        <>
          <FocusError />
          <Form onKeyDown={onKeyDown} onSubmit={handleSubmit} className='d-flex flex-column'>
            <Container fluid className="my-auto px-0">
              {
                jobStoryBuilder.steps.stepStart.map((item: any, index: number) => {
                  const propsForScheme = {
                    type: item.componentType,
                    index,
                    item,
                    touched,
                    handleChange,
                    setFieldTouched,
                    setFieldValue,
                    values,
                    errors
                  }
                  return <Row key={index + 'question'} className="justify-content-center">
                    <StyleForQuestion className={errors[item.name] && touched[item.name] ? 'error' : ''}>
                      <StyleForSubtitle>
                        {`Question ${index + 1}/${jobStory.stepStart.length}`}
                        {item.optional ? <span className='optional'>Optional</span> : <Text fs={16} text={errors[item.name] && touched[item.name] && typeof errors[item.name] === 'string' ? errors[item.name] : ''} error />}
                      </StyleForSubtitle>
                      <StyleForTitle>{item.title}{item.helperText && <HelperText autoChangePosition text={item.helperText} />}</StyleForTitle>
                      {SelectComponent(propsForScheme)}
                    </StyleForQuestion>
                  </Row>
                })}
            </Container>
            <NavBtn next callbackFunction={() => setFirstSubmitComplete(true)} />
          </Form>
        </>
      )}
    </Formik>
  </StyleForWrap>
})